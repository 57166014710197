<template>
  <page-content>
    <page-section>
      <content-column>
        <standard-heading :size="1">Terms of Service</standard-heading>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Last updated December 2021</standard-heading>
          <p>
            Welcome to Scott's Bass Lessons! We're excited you've chosen us to take you on your
            learning journey. Our sole aim is to support you to become the best bass player you can
            be and we want you to love the experience. The following document is designed to help
            you by explaining in clear English, our guidelines and respective responsibilities. We
            don't want anything to get in the way of an amazing experience!
          </p>
          <p>
            Scott's Bass Lessons (SBL) provides music students and enthusiasts with educational
            products and services. By joining SBL, a property of Devine Music Limited, and using any
            of our educational products, services or learning tools, you agree to all of the
            following Terms of Service (TOS) and any future updates we may make to them.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Services</standard-heading>
          <p>SBL offers services in two categories:</p>
          <p>
            <strong>Academy Membership</strong> - When you subscribe to the Academy Membership, you
            will receive access to a huge range of educational content including but not limited to
            the Players Path grading system, educational courses, artist interviews, live events,
            learning tools and the Campus (a discussion forum). We are constantly adding new
            courses, services, and tools to the Academy.
          </p>
          <p>
            <strong>External courses</strong> - Each year we also offer a small number of courses
            external to the Academy, e.g. Fretboard Accelerator, Practice Accelerator and Technique
            Accelerator. These are separate, one-off purchases unrelated to the Membership in the
            Academy, even if you are a Lifetime Member. We do, however, provide Academy Members with
            discounts to access these courses and these discounts are an important benefit of being
            an Academy Member.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Membership</standard-heading>
          <p>There are different types of Academy Membership as follows:</p>
          <p><strong>Monthly Academy Member:</strong> $32 per month</p>
          <p><strong>Yearly Academy Member:</strong> $199 per year</p>
          <p><strong>Lifetime Academy Member:</strong> $997 one off payment</p>
          <p>
            From time to time we may offer discounts on the price of Academy Membership. Prices for
            our products and services are subject to change without notice. We reserve the right to
            modify or discontinue services, products or learning tools without prior notice.
          </p>
          <p>
            Respecting the Children's Online Privacy Protection Act, we cannot allow children under
            the age of 13 to open an account with SBL. Children between the ages of 13 and 18 must
            have a parent or guardian present when using SBL.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Lifetime membership</standard-heading>
          <p>
            Lifetime Academy Membership means access to the Academy for the lifetime of the Service.
            If for any reason, SBL should dissolve or cease to exist, then your access to the
            Service terminates.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Guarantee and refunds</standard-heading>
          <p>
            Your satisfaction with our products and services is our primary concern. All of our
            products and services are covered by our 100% Satisfaction Guarantee and our 30-Day
            Price Pledge. Refunds will be made to your credit/debit card or PayPal account (if this
            payment method was originally used) only. You will be refunded within a maximum of two
            weeks from your request, made within the 30-day money-back guarantee timeframe.
          </p>
          <p>
            We are so confident that Scott's Bass Lessons will help you to become a better bass
            player that we're offering an enhanced guarantee if you purchase an Annual or Monthly
            Membership with SBL and you decide within 30 days of purchase that it's not for you. We
            will refund your purchase price and pay up to $500 for you to join another online bass
            school of your choice for a maximum of one year. We call this our
            <b> Guarantee of Awesomeness </b>.
          </p>
          <p>
            The conditions below apply to the Guarantee of Awesomeness (GoA). These conditions are
            in place to prevent misuse of this guarantee and not to make it more difficult to use.
            If you're really not getting what you need from your SBL membership, we want you to take
            advantage of this guarantee and tell us how you think we can improve SBL. The conditions
            are as follows:
          </p>
          <ol class="list-decimal list-inside space-y-4 max-w-txt">
            <li>
              The GoA only applies to your first purchase of an Annual or Monthly Membership of SBL,
              It does not apply to renewals, lifetime memberships, coaching products, external
              courses or any other product or service we offer.
            </li>
            <li>
              The GoA is valid for exactly 30 days following the time and date you pay for a monthly
              or annual subscription with SBL.
            </li>
            <li>The GoA does not apply to cancellations during the free trial period.</li>
            <li>
              The GoA is only to be used once per person. To prevent individuals from using multiple
              email addresses to obtain multiple subscriptions, you will need to send a digital
              image of an official document, e.g. utility bill, that shows your name and home
              address. This information will not be used for any other purpose than to validate your
              identity and will not be shared with any other company or be used for marketing
              purposes.
            </li>
            <li>
              We reserve the right to refuse to honour the GoA if we have a reasonable suspicion
              that the applicant has previously used the guarantee.
            </li>
            <li>
              Under the GoA we will pay up to $500 for you to join the annual membership of another
              online bass school of your choice for one year. We can't offer you an unlimited choice
              because anyone could set up an online bass school and claim $500 so it must be an
              online school that offers a substantial body of content focused on bass, with an
              annual membership option that's more than two years old. When you contact us to claim
              your guarantee, state which online school you'd like to try and we'll confirm that
              it's acceptable or offer you alternatives.
            </li>
            <li>
              The GoA cannot be used to purchase one-off courses, lifetime memberships or any other
              service or product provided by the schools listed above. We cannot guarantee the
              performance of a third-party provider and so, once we sign you up, the service you
              receive is the responsibility of that service provider alone.
            </li>
            <li>
              We will pay your chosen provider directly for an annual membership for one year and at
              no time will you be directly involved in any financial transaction apart from the
              refund of your original SBL subscription. The payment mechanism we use to pay for the
              annual membership will not support further payments, however, if you cancel the
              service during a provider's guarantee period, the money may be returned to us via the
              payment method we used to fund the purchase.
            </li>
            <li>
              To benefit from this guarantee, following your cancellation request, we will ask you
              for 15 minutes of your time via a Zoom call so you can tell us how we can improve!
              Don't worry - no sales tactics, no difficult questions and no guilt trip. We simply
              want to know where we're going wrong and if you have thoughts about how we could be
              better.
            </li>
            <li>
              Following the video chat, we'll use the email address we hold for you to set up a
              personal account with your chosen school and we will pay for the annual subscription
              on your behalf. We will then email you the sign-in details.
            </li>
          </ol>
          <p>
            If you wish to benefit from either our Standard Guarantee or our Guarantee of
            Awesomeness, then please email <b>support@scottsbasslessons.com</b>.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Fees, charges, and billing</standard-heading>
          <p>
            SBL offers discounted memberships, options to purchase individual educational products
            and services, and options to purchase subscriptions to certain educational products and
            services. By submitting your credit card, debit card, or PayPal information, and
            associating that information with your SBL account, you authorise SBL to process your
            purchase accordingly.
          </p>
          <p>
            By starting a 14-Day free trial you agree to be billed upon completion unless cancelled
            within the trial period.
          </p>
          <p>
            If you purchase a monthly or yearly subscription for a product or service, you
            understand and authorise SBL to automatically renew your subscription until cancelled.
            You can easily and quickly cancel your subscription in one click by accessing your
            profile page or by contacting us via email:
            <strong>support@scottsbasslessons.com</strong>.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">SBL username and password</standard-heading>
          <p>
            You agree to be the sole user of the username and password you created when joining SBL,
            and to reasonably protect that username and password, and to notify SBL if you believe
            it has been breached or otherwise compromised.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Privacy policy</standard-heading>
          <p>
            SBL does not sell, share, or rent any personal information to third parties for any
            reason whatsoever. SBL uses your name, address, and credit card information solely for
            billing purposes. SBL uses your email address for delivering administrative and
            promotional information, which communications you can control and/or unsubscribe from.
            View our entire Privacy Policy
            <a href="/privacy" class="text-lightest hover:text-white underline">here</a>.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Support</standard-heading>
          <p>
            We provide support free of charge by emailing us at
            <b>support@scottsbasslessons.com</b>. Our customer support team will usually respond to
            queries and issues within 24 hours.
          </p>
          <p>
            If your query or issue is of a technical nature, we may need to refer you to our
            Technical Support Team. On average, our Technical Support Team will reply within 2
            working days.
          </p>
          <p>
            From time to time, our wait times may be longer than usual. If you're already an Academy
            Member, you may be able to find answers to your questions in the Campus as well. Many
            areas of SBL also have FAQ (Frequently Asked Questions) which may help to resolve common
            issues and queries.
          </p>
          <p>
            Please note that SBL is a web-delivered service and in order to access it effectively,
            you will need to possess a suitable device with a modern browser installed and a fast
            Internet connection. We cannot support every version of every browser and therefore we
            encourage you to use your trial and money-back guarantee periods to help you evaluate
            SBL to your satisfaction.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Intellectual property rights</standard-heading>
          <p>
            The staff at SBL, and all of the artists and educators that we are privileged to
            collaborate with, work very hard to author and produce the content that comprises the
            educational products and services available on SBL. All of our educational products,
            services and learning tools are protected by United Kingdom and International
            copyrights, patents, and/or third-party licenses. You agree that you will not share,
            publish, copy, distribute, re-sell, or otherwise exploit our logos, branding,
            educational products and services, learning tools, and self-publishing tools or any
            other form of our intellectual property, for any personal, promotional and/or commercial
            use, without our express written permission.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">User generated content</standard-heading>
          <p>
            Various sections on the SBL website, and various products and services, allow for
            user-generated content such as comments, videos, lesson material, audio files, and other
            related user-generated content. By posting and/or uploading content to SBL, you warrant
            and represent that you own the rights to the content or are otherwise authorised to post
            and/or upload such content. Furthermore, you agree to not post and/or upload any
            inappropriate, profane, vulgar, defamatory, infringing, obscene, tortious, indecent,
            unlawful, offensive, immoral or otherwise objectionable material or information.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Agreement updates</standard-heading>
          <p>
            We reserve the right to add, delete and/or modify any of the terms and conditions
            contained in this Agreement. SBL reserves the right to update, revise, or otherwise
            alter the terms and conditions set forth in this agreement, at any time, with notice
            provided in your SBL account. Your continued use of SBL and its products and services
            following posting of a change notice or new agreement will constitute your binding
            acceptance of the changes.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Indemnity</standard-heading>
          <p>
            You agree to indemnify, defend, and hold harmless SBL, its educators, affiliates,
            officers, directors, employees, agents, and representatives from any and all third party
            claims, losses, liability, damages, and/or costs (including reasonable attorney fees and
            costs) arising from your access to or use of the SBL website or its products, services,
            and learning tools, your violation of these Terms of Service, or your infringement, or
            infringement by any other user of your account, of any intellectual property or other
            right of any person or entity.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Governing law and dispute resolution</standard-heading>
          <p>
            If a dispute arises between you and SBL, we strongly encourage you to first contact us
            directly to seek a resolution by contacting Customer Support. Our Customer Service is
            available free of charge for every user to submit complaints and other inquiries. We
            will consider reasonable requests to resolve the dispute through alternative dispute
            resolution procedures, such as mediation or arbitration, as alternatives to litigation.
            Any claim, dispute or matter arising under or in connection with this Terms of Service
            shall be governed and construed in all respects by the laws of England and Wales. You
            and SBL both agree to submit to the non-exclusive jurisdiction of the English courts. In
            simple terms, 'non-exclusive jurisdiction of the English courts' means that if you were
            able to bring a claim arising from or in connection with this User Agreement against us
            in court, an acceptable court would be a court located in England, but you may also
            elect to bring a claim in the court of another country instead. English law will apply
            in all cases.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Termination</standard-heading>
          <p>
            SBL reserves the right to terminate your account, subscription, and/or access at any
            time, and for any reason at our sole discretion.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Disclaimers</standard-heading>
          <p>
            To the maximum extent permitted by applicable law, SBL is not responsible for and makes
            no representations or warranties or endorsements of any kind whatsoever, express or
            implied, as to: (a) SBL educational products and services, learning tools, and
            self-publishing tools; (b) the SBL content on or provided through SBL educational
            products and services; (c) the content or user content (including the accuracy and
            reliability thereof); (d) any third party content; (e) the linked services; (f) the
            functions made accessible on or through the SBL educational products, services and
            learning tools; (g) any products, services or instructions offered or referenced in the
            SBL website, educational products, services and learning tools; and/or (h) security
            associated with the transmission of information transmitted to or from SBL or others via
            the SBL website, educational products and services, learning tools, and self-publishing
            tools.
          </p>
          <p>
            In addition and to the maximum extent permitted by applicable law, SBL is not
            responsible for any damage, injury or loss caused by users of the SBL website,
            educational products and services, learning tools, and self-publishing tools; or by any
            of the equipment or programming associated with or utilised in the SBL website,
            educational products and services, learning tools, and self-publishing tools services;
            or linked services.
          </p>
          <p>
            SBL assumes no responsibility for any error, omission, interruption, deletion, defect,
            delay in operation or transmission, communications line failure, theft or destruction or
            unauthorised access to, or alteration of, any user or member communication.
          </p>
          <p>
            SBL is not responsible for any problems or technical malfunction of any telephone
            network or lines, computer online systems, servers or providers, computer equipment,
            software, failure of any email or players due to technical problems or traffic
            congestion on the internet or on any of the SBL website, educational products and
            services, learning tools, and self-publishing tools services; services or linked
            services or combination thereof, including, without limitation, any injury or damage to
            users or to any person's computer related to or resulting from participation or
            downloading materials in connection with the SBL website, educational products and
            services, learning tools, and self-publishing tools services; or linked services.
          </p>
          <p>
            Under no circumstances shall SBL be responsible for any loss or damage, including,
            without limitation, personal injury or death, resulting from use of the SBL educational
            products and services, learning tools, and self-publishing tools, or linked services,
            attendance at a SBL event, from any user content posted on or through the SBL website or
            linked services, or from the conduct of any users of SBL, whether online or offline.
          </p>
          <p>
            SBL educational products, services and learning tools, and linked services are provided
            "as-is" and "as available" and "with all faults" basis and SBL expressly disclaims all
            warranties, express or implied, including, without limitation, the warranties of
            merchantability, fitness for a particular purpose, non-infringement, title, custom,
            trade, quiet enjoyment, system integration and freedom from computer virus. SBL cannot
            guarantee and does not promise any specific results from use of SBL educational products
            and services, learning tools, and self-publishing tools, or linked services.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Limitation of liability</standard-heading>
          <p>
            In no event shall SBL be liable to you or any third party for any indirect,
            consequential, exemplary, incidental, special or punitive damages, including, without
            limitation, lost profit damages arising from: (a) your use or inability to use the SBL
            educational products and services, learning tools, and self-publishing tools, or linked
            services, or the performance of the SBL educational products and services, learning
            tools, and self-publishing tools, or linked services, (b) any action taken in connection
            with an investigation by SBL or law enforcement authorities regarding your access to or
            use of the SBL services, (c) any action taken in connection with copyright or other
            intellectual property owners or other rights owners, (d) any errors or omissions in the
            SBL services' technical operation, or (e) any damage to a user's computer, hardware,
            software, modem, or other equipment or technology, including damage from any security
            breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect,
            delay in operation or transmission, computer line, or network failure or any other
            technical or other malfunction, including losses or damages in the form of lost profits,
            loss of goodwill, loss of data, work stoppage, accuracy of results, or equipment failure
            or malfunction. notwithstanding anything to the contrary contained herein, SBL's
            liability to you for any cause whatsoever and regardless of the form of the action, will
            at all times be limited to the amount paid, if any, by you to SBL for the SBL services
            during the term of membership. the foregoing limitations of liability will apply even if
            any of the foregoing events or circumstances were foreseeable and even if SBL was
            advised of or should have known of the possibility of such losses or damages, regardless
            of whether you bring an action based in contract, negligence, strict liability, or tort
            (including whether caused, in whole or in part, by negligence, acts of god,
            telecommunications failure, or destruction of the SBL educational products and services,
            learning tools, and self-publishing tools).
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Acknowledgement</standard-heading>
          <p>
            By using SBL educational products, services and learning tools, or accessing the SBL
            website, you acknowledge that you have read these terms of use and agree to be bound by
            them.
          </p>
        </flow>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import PageContent from '../../core/page/PageContent.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    PageSection,
    StandardHeading,
    Flow,
    PageContent,
    ContentColumn
  },
  props: {},
  setup() {
    return {};
  }
});
</script>
