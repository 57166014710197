import {Ref, ref, watch} from 'vue';
import {useDeviceType} from '../device-type/device-type';

export type ElementBoundsEventType = 'scroll' | 'resize' | 'screen';

export type ElementBoundsComposition = {
  bounds: Ref<DOMRect | undefined>;
  update: () => void;
};

export type OnBoundsUpdatedFunc = (bounds: Readonly<DOMRect> | undefined) => void;

export function getBounds(
  element: Ref<InstanceType<typeof HTMLDivElement> | null>
): Readonly<DOMRect | undefined> {
  if (element.value !== null) {
    return element.value.getBoundingClientRect();
  }
  return undefined;
}

/**
 * Return a Vue composition that contains a reactive bounding box for the
 * specified element.
 *
 * NOTE: The rect is only updated automatically on window scroll and
 * resize events, and when the device type screen size changes! If you
 * need to enforce an update, call the update() function in the composition.
 *
 * NOTE: If you use this composition in several components on the same page,
 * the order in which the bounds are updated is undefined.
 */
export function useElementBounds(
  element: Ref<InstanceType<typeof HTMLDivElement> | null>,
  events: ReadonlyArray<ElementBoundsEventType>,
  onUpdated?: OnBoundsUpdatedFunc
): Readonly<ElementBoundsComposition> {
  const bounds = ref<DOMRect | undefined>(undefined);

  const update = () => {
    bounds.value = getBounds(element);
    if (onUpdated) {
      onUpdated(bounds.value);
    }
  };

  /*
    ### If this composition is used a lot, we'll get many event listeners.
    This could be optimised by refactoring this to use a manager+listeners 
    kind of pattern.
  */
  if (events.includes('resize')) {
    window.addEventListener('resize', _ => {
      update();
    });
  }
  if (events.includes('scroll')) {
    window.addEventListener('scroll', _ => {
      update();
    });
  }

  if (events.includes('screen')) {
    const deviceType = useDeviceType();
    watch(deviceType.screen, () => {
      update();
    });
  }

  update();

  return {
    bounds,
    update
  };
}
