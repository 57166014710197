import {computed, ref} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {BookmarkNode} from '../../user-pages/bookmarks/types';
import {
  addBookmarkGroup,
  buildBookmarkCollection,
  createNewBookmarkInGroup,
  moveBookmarkItem
} from '../../user-pages/bookmarks/utils';
import {useFullScreenLoader, useLoader} from '../loader/loader';
import {useModalDialog} from '../modal-dialog/modal-dialog';
import {
  DEFAULT_NOTIFICATION_POPUP_DISPLAY_TIME,
  standardNotification,
  useNotificationPopup
} from '../notification-popup/notification-popup';
import AddBookmarkDialog from '../../user-pages/bookmarks/partials/AddBookmarkDialog.vue';
import MoveBookmarkDialog from '../../user-pages/bookmarks/partials/MoveBookmarkDialog.vue';
import {
  getAllBookmarks,
  getBookmark,
  getSingleBookmark
} from '../../../backend/bookmark/bookmark-query';
import {Bookmark} from '../../../backend/bookmark/bookmark-types';
import {CraftId} from '../../../backend/craft/craft-types';
import {AddBookmarkDialogData, BookmarkComposition, MoveBookmarkDialogData} from './types';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';

export function useBookmark(
  guest: string,
  bookmarkId?: CraftId,
  loadEntries: boolean = false
): Readonly<BookmarkComposition> {
  const loader = useLoader();
  const fullscreenLoader = useFullScreenLoader();

  const bookmarks = ref<ReadonlyArray<Bookmark>>([]);
  const groups = ref<ReadonlyArray<BookmarkNode>>([]);
  const nonDefaultGroup = ref<ReadonlyArray<Bookmark>>([]);
  let selectedGroup: CraftId | undefined = undefined;

  const readOnly = bookmarkId !== undefined ? true : false;

  const bookmarkedEntries = computed(() => {
    const entryIds = bookmarks.value.map(bookmark => bookmark.entryId);
    const result = entryIds.filter(entryId => entryId !== null) as ReadonlyArray<CraftId>;
    return result;
  });

  const reload = async (bookmarkId?: Readonly<CraftId>) => {
    if (!guest) {
      loader.setLoading(true);

      /**
       * If the URL contains a group or bookmark ID, then it's a share link and the bookmarks
       * section is in read-only mode.
       *
       * The passed bookmarkId is used to get the corresponding parentId, which is used to fetch
       * the parent bookmark entry. This is passed to the buildBookmarkCollection function as
       * nonDefaultGroup for use in the logic to generate the correct groups.
       */
      if (readOnly && bookmarkId !== undefined) {
        bookmarks.value = await getBookmark(bookmarkId);

        /* 
          Populate nonDefaultGroup with with bookmark parent group in order to pass as parameter
          to buildBookmarkCollection() function.
        */
        if (bookmarks.value[0].parentId !== undefined && bookmarks.value[0].parentId !== null) {
          nonDefaultGroup.value = await getSingleBookmark(
            bookmarks.value[0].parentId ? bookmarks.value[0].parentId : ''
          );
        }
      } else {
        bookmarks.value = await getAllBookmarks();
      }

      groups.value = await buildBookmarkCollection(
        loadEntries,
        bookmarks.value,
        nonDefaultGroup.value,
        readOnly,
        selectedGroup
      );

      loader.setLoading(false);
    }
  };

  const init = async (bookmarkId?: Readonly<CraftId>) => {
    await reload(bookmarkId);
  };

  const notification = useNotificationPopup();
  const addBookmark = async (data?: Readonly<AddBookmarkDialogData>) => {
    if (data !== undefined) {
      fullscreenLoader.setLoading(true, 'Updating your bookmarks');
      await createNewBookmarkInGroup(data.contentEntry, data.group);
      await reload();
      fullscreenLoader.setLoading(false);
      notification.notify({
        displayTimeSec: DEFAULT_NOTIFICATION_POPUP_DISPLAY_TIME,
        title: 'Bookmark added.',
        icon: faCheckCircle,
        iconColor: 'text-pp2',
        url: '/bookmarks',
        urlText: 'My Bookmarks'
      });
    }
  };
  const addBookmarkDialog = useModalDialog(AddBookmarkDialog);
  const showAddBookmarkDialog = (contentEntry: Readonly<ContentEntry>) => {
    if (!guest) {
      addBookmarkDialog.show(
        addBookmark,
        () => {},
        {
          contentEntry,
          groups,
          reload,
          addBookmark: async (name: string) => {
            fullscreenLoader.setLoading(true, 'Updating your bookmarks');
            const group = await addBookmarkGroup(name);
            selectedGroup = group.id !== null ? group.id : undefined;
            await reload();
            fullscreenLoader.setLoading(false);
            return group.id;
          }
        },
        true,
        'Add Bookmark'
      );
    }
  };

  const moveBookmark = async (data?: Readonly<MoveBookmarkDialogData>) => {
    if (data !== undefined) {
      fullscreenLoader.setLoading(true, 'Updating your bookmarks');
      await moveBookmarkItem(data.bookmarkNode, data.group);
      await reload();
      fullscreenLoader.setLoading(false);
      notification.notify(standardNotification('Bookmark moved.'));
    }
  };
  const moveBookmarkDialog = useModalDialog(MoveBookmarkDialog);
  const showMoveBookmarkDialog = (
    bookmarkNode: Readonly<BookmarkNode>,
    currentGroup: Readonly<BookmarkNode>
  ) => {
    if (!guest) {
      moveBookmarkDialog.show(
        moveBookmark,
        () => {},
        {bookmarkNode, groups: groups.value, group: currentGroup},
        true,
        'Move'
      );
    }
  };

  return {
    init,
    groups,
    bookmarkedEntries,
    showAddBookmarkDialog,
    showMoveBookmarkDialog
  };
}
