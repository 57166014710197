<template>
  <!-- https://vuejs.org/api/built-in-special-attributes.html#ref -->
  <div v-for="i in [...Array(wizardComposition._numberOfSteps).keys()]" :key="i" class="w-full">
    <!-- 
      We need to use v-show here instead of v-if since the elements must be in the DOM
      in order for the <slot> functionality to work properly.
    -->
    <div v-show="wizardComposition.visible(i)" class="w-full">
      <slot :name="i" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {WizardComposition} from '../../vue-composition/wizard/wizard';

/**
 * This component is a utility for creating a sequence of elements that
 * the user can step through by initiating an action at each step.
 * It is used in conjunction with the 'stepped-sequence' Vue composition.
 */
export default defineComponent({
  components: {},
  props: {
    wizardComposition: {
      type: Object as PropType<Readonly<WizardComposition>>,
      required: true
    }
  },
  setup() {}
});
</script>
