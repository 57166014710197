<template>
  <beginner-journey-pill v-if="visible" />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import BeginnerJourneyPill from '../../../../content-pages/beginner-journey/partials/BeginnerJourneyPill.vue';
import {BreadcrumbTrailComposition} from '../../../../vue-composition/breadcrumb-trail/types';
import {trailIsInBeginnerJourney} from '../../../../content-pages/beginner-journey/utils';

export default defineComponent({
  components: {
    BeginnerJourneyPill
  },
  props: {
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true}
  },
  setup(props) {
    const visible = computed(() => {
      return trailIsInBeginnerJourney(props.trails);
    });
    return {
      visible
    };
  }
});
</script>
