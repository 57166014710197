<template>
  <div :id="id">
    <card :url="url">
      <template #hero>
        <card-hero-shimmer v-if="contentEntry === undefined" />
        <card-hero-text
          v-else-if="responsiveImageConfig === undefined"
          :ribbon-icon="ribbonIcon"
          :inset-text="durationText"
        />
        <card-hero-image
          v-else
          :config="responsiveImageConfig"
          :greyscale="greyscale"
          :ribbon-icon="ribbonIcon"
          :inset-text="durationText"
        />
      </template>

      <template #center>
        <card-progress-bar :progress="progress" :url="url" :spot-color="spotColor" />
      </template>

      <template #body>
        <div v-if="contentEntry !== undefined">
          <card-body-title :title="contentEntry.title" />
          <card-body-tutor-list :content-entry="contentEntry" />
        </div>
      </template>
      <template #buttons>
        <div v-if="contentEntry !== undefined" class="flex flex-col pr-fl-xs">
          <card-body-bookmark :guest="guest" :content-entry="contentEntry" :bookmark="bookmark" />
        </div>
      </template>
    </card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {HMSToString, secToHMS} from '../../../../utils/duration';
import Card from '../../../generic/card/Card.vue';
import CardProgressBar from '../../../generic/card/partials/CardProgressBar.vue';
import CardBodyTitle from '../../../generic/card/partials/body/CardBodyTitle.vue';
import CardBodyTutorList from '../../../generic/card/partials/body/CardBodyTutorList.vue';
import CardBodyBookmark from '../../../generic/card/partials/body/CardBodyBookmark.vue';
import CardHeroShimmer from '../../../generic/card/partials/hero/CardHeroShimmer.vue';
import CardHeroText from '../../../generic/card/partials/hero/CardHeroText.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import {ColorConfig} from '../../../core/color-config';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    Card,
    CardHeroShimmer,
    CardHeroText,
    CardHeroImage,
    CardProgressBar,
    CardBodyTitle,
    CardBodyTutorList,
    CardBodyBookmark
  },
  props: {
    id: {type: String, default: undefined},
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, default: undefined},
    duration: {type: Number, default: undefined}, // Seconds
    completed: {type: Boolean, default: undefined},
    progress: {type: Number, default: undefined}, // [0,100]
    unlocked: {type: Boolean, default: undefined},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: undefined}
  },
  setup(props) {
    const completed = computed(() => {
      if (props.completed !== undefined) {
        return props.completed;
      }
      if (props.progress !== undefined) {
        return props.progress === 100;
      }
      return undefined;
    });

    const greyscale = computed(() => {
      return completed.value;
    });

    const responsiveImageConfig = computed(() => {
      if (props.contentEntry === undefined) {
        return undefined;
      }
      const cfg = getImageConfig(
        props.contentEntry.imageCollection,
        ['cover'],
        props.contentEntry.title,
        props.cardImageWidth,
        props.imgixParams
      );
      return cfg;
    });

    const durationText = computed(() => {
      if (props.duration !== undefined) {
        return `${HMSToString(secToHMS(props.duration))}`;
      }
      return undefined;
    });

    const url = computed(() => {
      if (props.contentEntry !== undefined) {
        return props.contentEntry.url;
      }
      return undefined;
    });

    const ribbonIcon = computed(() => {
      if (props.guest) {
        return undefined;
      }
      if (completed.value !== undefined && completed.value === true) {
        return faCheck;
      }
      if (props.unlocked !== undefined && !props.unlocked) {
        return faLock;
      }
      return undefined;
    });

    return {
      ribbonIcon,
      greyscale,
      responsiveImageConfig,
      durationText,
      url
    };
  }
});
</script>
