<template>
  <variants>
    <template #narrow>
      <a :href="items[0].url" class="block text-mid hover:text-white overflow-x-hidden truncate">
        <span>
          <font-awesome-icon
            v-if="items.length === 1"
            :icon="icon"
            class="pr-fl-xs"
          ></font-awesome-icon>
        </span>
        {{ items[0].title }}
      </a>
    </template>
    <template #sm+>
      <cluster :gap="'gap-fl-xs'" :wrap="false" class="overflow-x-hidden">
        <a
          v-for="(item, index) in items"
          :key="index"
          :href="item.url"
          :class="`block text-mid hover:text-white ${item.overflow}`"
        >
          <span>
            <font-awesome-icon
              v-if="items.length === 1"
              :icon="icon"
              class="pr-fl-xs"
            ></font-awesome-icon>
          </span>
          {{ item.title }}
        </a>
      </cluster>
    </template>
  </variants>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {faArrowLeft} from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {NavigationPath} from '../../../vue-composition/breadcrumb-trail/types';

export default {
  components: {
    Variants,
    Cluster,
    FontAwesomeIcon
  },
  props: {
    path: {
      type: Object as PropType<Readonly<NavigationPath>>,
      required: true
    }
  },
  setup(props) {
    const deviceType = useDeviceType();

    const items = computed(() => {
      const items: Array<any> = [];
      // On narrow screens, only include the last item in the path.
      if (deviceType.screen.value === 'narrow') {
        items.push(props.path[props.path.length - 1]);
      } else {
        props.path.forEach((item, index) => {
          items.push({
            ...item,
            overflow: index !== 0 ? 'truncate' : ''
          });
          if (index !== props.path.length - 1) {
            items.push({
              title: '>',
              url: undefined,
              overflow: ''
            });
          }
        });
      }
      return items;
    });

    const icon = computed(() => faArrowLeft);

    return {
      items,
      icon,
      deviceType
    };
  }
};
</script>
