<template>
  <div class="flex justify-center">
    <div class="w-full max-w-[1920px] h-full flex">
      <div class="py-fl-5xl w-full flex justify-center items-center">
        <login-form :remembered-user-name="rememberedUserName" class="w-[70%]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import LoginForm from '../partials/LoginForm.vue';

export default defineComponent({
  components: {
    LoginForm
  },
  props: {
    rememberedUserName: {type: String, default: ''}
  },
  setup() {}
});
</script>
