<template>
  <page-section :margin-top="'lg'">
    <page-section-header v-if="piecesCompleted < 3" :heading="nonCompleteHeading" />
    <page-section-header v-else :heading="completeHeading" />
  </page-section>

  <page-section :margin-top="'xs'">
    <content-column>
      <repel>
        <div v-if="piecesCompleted < 3" class="max-w-[520px]">
          {{ nonCompleteSummary }}
        </div>
        <div v-else class="max-w-[520px]">
          {{ completeSummary }}
        </div>
        <div :class="`flex gap-fl-md`">
          <font-awesome-icon
            v-for="(color, index) in checkmarks"
            :key="index"
            :icon="iconCheck"
            :class="`text-fl-10xl ${color}`"
          ></font-awesome-icon>
        </div>
      </repel>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Repel from '../../../core/compositions/Repel.vue';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {ColorConfig} from '../../../core/color-config';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentColumn,
    Repel,
    FontAwesomeIcon
  },
  props: {
    piecesCompleted: {type: Number, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true},
    nonCompleteHeading: {type: String, default: 'Complete 3 Study Pieces'},
    completeHeading: {type: String, default: 'Study Pieces'},
    nonCompleteSummary: {
      type: String,
      default:
        "Your goal is to learn and master at least 3 of the following study pieces. Once completed, you'll unlock the Level Assessment to move on to the next level."
    },
    completeSummary: {
      type: String,
      default: 'Try to maintain a daily practice and aim to feel 100% comfortable with each piece.'
    }
  },
  setup(props) {
    const iconCheck = computed(() => faCheck);
    const checkmarks = computed(() => {
      return [1, 2, 3].map(n => {
        return n <= props.piecesCompleted ? props.spotColor.text : 'text-mid';
      });
    });

    return {
      iconCheck,
      checkmarks
    };
  }
});
</script>
