<template>
  <button
    :class="`w-full flex flex-col justify-start items-start border ${border} rounded-[0.5em] ${bg} text-left`"
    @click="click"
  >
    <div :class="`p-fl-sm flex flex-col ${textColor}`">
      <div class="text-fl-xl font-semibold">{{ heading }}</div>
      <div class="text-fl-lg">{{ text }}</div>
    </div>
  </button>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import {ColorConfig} from '../../../../../../core/color-config';

export default defineComponent({
  props: {
    id: {type: Number, required: true},
    selected: {type: Boolean, required: true},
    color: {type: Object as PropType<ColorConfig>, required: true},
    heading: {type: String, required: true},
    text: {type: String, required: true},
    onClick: {type: Function as PropType<(level: number) => void>, required: true}
  },
  setup(props) {
    const border = computed(() => {
      return props.selected ? `${props.color.border}` : 'border-white';
    });
    const bg = computed(() => {
      return props.selected ? `${props.color.bg}` : 'border-transparent';
    });
    const textColor = computed(() => {
      return props.selected ? `text-black` : 'text-lightest';
    });
    const click = () => {
      props.onClick(props.id);
    };
    return {
      click,
      border,
      bg,
      textColor
    };
  }
});
</script>
