<template>
  <div ref="anchor" class="relative">
    <icon-button :size="'2xl'" :icon="icon" :tooltip="'Options'" :on-click="onShow" />
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, ref, onMounted, nextTick, PropType} from 'vue';
import IconButton from '../../core/button/IconButton.vue';
import PopupMenu from './partials/PopupMenu.vue';
import {faEllipsis} from '@fortawesome/pro-solid-svg-icons/faEllipsis';
import {useElementBounds} from '../../vue-composition/element-bounds/element-bounds';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {useModalOverlay} from '../../vue-composition/modal-overlay/modal-overlay';
import {PopupMenuItem} from './types';

/**
 * A button that displays a popup menu.
 */
export default defineComponent({
  components: {
    IconButton
  },
  props: {
    items: {type: Array as PropType<ReadonlyArray<PopupMenuItem>>, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const icon = computed(() => faEllipsis);

    // Keep track of the position of the anchor point of the menu (= the button with the ellipsis icon).
    const anchor = ref<InstanceType<typeof HTMLDivElement> | null>(null);
    const anchorBounds = useElementBounds(anchor, ['resize', 'screen', 'scroll']);
    onMounted(() => {
      anchorBounds.update();
    });

    // Keep track of the size of the menu (it is reported back here from the PopupMenu component).
    const menuWidth = ref(0);
    const menuHeight = ref(0);
    const updateDimensions = (width: number, height: number) => {
      menuWidth.value = width;
      menuHeight.value = height;
    };

    /*
      Compute a position for the menu; if it extends beyond the right or bottom
      edges of the viewport, move it inside the viewport (with margins).
    */
    const position = computed(() => {
      if (anchorBounds.bounds.value !== undefined) {
        let m = 10; // Margin between menu and viewport edges

        let x = anchorBounds.bounds.value.left;
        let y = anchorBounds.bounds.value.top;

        const vw = deviceType.viewportWidth.value;
        const vh = deviceType.viewportHeight.value;

        const mw = menuWidth.value;
        const mh = menuHeight.value;

        if (x + mw + m > vw) {
          x = vw - mw - m;
        }
        if (y + mh + m > vh) {
          y = vh - mh - m;
        }

        return `left:${x}px;top:${y}px`;
      }
      return '';
    });

    // The menu is displayed on top of the modal overlay (so it can be clicked away).
    const modalOverlay = useModalOverlay(PopupMenu);
    /*
      When an item is selected in the popup menu, deactivate the modal overlay.
      We then have to wait one Vue tick before calling the item's action function;
      the reason is that the action function may activate another modal overlay,
      and we must allow Vue to unmount the current overlay first.
    */
    const onSelected = async (item: Readonly<PopupMenuItem>) => {
      modalOverlay.deactivate();
      await nextTick();
      await item.onSelected();
    };
    const onShow = async () => {
      modalOverlay.activate(
        {
          items: props.items,
          position,
          updateDimensions,
          onSelected
        },
        () => {},
        false
      );
      /*
        Once the menu has become visible, force-update the anchor point so the menu can 
        be positioned correctly.
      */
      await nextTick();
      anchorBounds.update();
    };

    return {
      anchor,
      icon,
      onShow,
      onSelected
    };
  }
});
</script>
