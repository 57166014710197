<template>
  <div v-for="(piece, index) in pieces" :key="piece.id">
    <content-column v-if="index === 0">
      <standard-divider class="border-dark" />
    </content-column>

    <page-section :margin-top="'sm'" :margin-bottom="'sm'">
      <content-entry-item
        :id="`players-path-piece-${index}`"
        :key="piece.id"
        :guest="guest"
        :content-entry="piece"
        :progress="progress[index]"
        :spot-color="spotColor"
        :bookmark="bookmark"
        :text-size="'text-fl-3xl'"
      >
        <template #buttons>
          <content-area class="flex flex-wrap justify-end items-center gap-x-fl-md-xl gap-y-fl-sm">
            <audio-button
              v-if="audioUrl(piece) && !preview && !guest"
              :url="audioUrl(piece)"
              :label="'Preview'"
              :icon-only="deviceType.smallerThanOrEqualTo('sm')"
            />
            <content-entry-header-bookmark
              v-if="displayConfig.bookmark"
              :guest="guest"
              :bookmark="bookmark"
              :content-entry="piece"
              :display="deviceType.smallerThanOrEqualTo('sm') ? 'icon' : 'linkIcon'"
            />
          </content-area>
        </template>
      </content-entry-item>
    </page-section>

    <content-column>
      <standard-divider class="border-dark" />
    </content-column>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PlayersPathPiece} from '../../../../backend/content/players-path/players-path-types';
import {QuizCollectionStatus} from '../../../../backend/content/quiz/quiz-types';
import {DEFAULT_DISPLAY_CONFIG_ITEM} from '../../../content-entry/collections/types';
import {ColorConfig} from '../../../core/color-config';
import AudioButton from '../../../generic/audio-button/AudioButton.vue';
import ContentEntryItem from '../../../content-entry/representations/item/ContentEntryItem.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    AudioButton,
    PageSection,
    ContentColumn,
    ContentArea,
    StandardDivider,
    ContentEntryItem,
    ContentEntryHeaderBookmark
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    pieces: {type: Array as PropType<ReadonlyArray<PlayersPathPiece>>, required: true},
    quizStatuses: {type: Object as PropType<Readonly<QuizCollectionStatus>>, default: undefined},
    previewLevel: {type: Boolean, default: false},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const audioUrl = (piece: Readonly<PlayersPathPiece>) => {
      const songs = piece.songs;
      if (songs !== undefined) {
        const song = songs[0];
        if (song !== undefined) {
          const variation = song.songSpeedVariations.find(variation => {
            return (
              variation.speedVariationTempo === '100' &&
              variation.speedVariationStemType === 'withBass'
            );
          });
          if (variation !== undefined) {
            return variation.speedVariationMp3Url;
          }
        }
      }
      return '';
    };

    const progress = computed(() => {
      return props.pieces.map(piece => {
        if (props.guest || props.preview || props.previewLevel) {
          return 0;
        } else {
          const quiz = piece.quizzes[0];
          let completed = false;
          if (quiz !== undefined) {
            const quizId = quiz.id;
            completed = props.quizStatuses ? props.quizStatuses[quizId] : false;
          }
          return completed ? 100 : 0;
        }
      });
    });

    const displayConfig = computed(() => {
      return {...DEFAULT_DISPLAY_CONFIG_ITEM, summary: true};
    });

    const deviceType = useDeviceType();
    const buttonsAsIcon = computed(() => deviceType.screen.value === 'narrow');

    return {
      deviceType,
      buttonsAsIcon,
      audioUrl,
      progress,
      displayConfig
    };
  }
});
</script>
