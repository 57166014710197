<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <div class="flex justify-center gap-fl-xl">
          <link-button :label="'Profile'" :color="'white'" :url="'/profile'" />
          <link-button :label="'Email'" :color="'mid'" :url="'/profile/email'" />
          <link-button :label="'Password'" :color="'mid'" :url="'/profile/password'" />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <content-entry-header :center="true">Edit my Profile</content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <standard-heading :size="2">Personal Details</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'" :margin-bottom="'xl'">
      <content-column>
        <div class="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-fl-sm">
          <div class="w-full flex flex-col gap-fl-sm">
            <label for="firstName" class="text-mid text-fl-lg">First Name</label>
            <input
              id="firstName"
              v-model="firstNameValue"
              class="w-full p-fl-sm bg-transparent text-fl-xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
              type="text"
            />
          </div>
          <div class="w-full flex flex-col gap-fl-sm">
            <label for="lastName" class="text-mid text-fl-lg">Last Name</label>
            <input
              id="lastName"
              v-model="lastNameValue"
              class="w-full p-fl-sm bg-transparent text-fl-xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
              type="text"
            />
          </div>
        </div>
      </content-column>
    </page-section>

    <user-skill-level :selected-level="selectedLevelNumber" :on-select="onSelect" />

    <page-section>
      <content-column>
        <standard-button
          :disabled="saveDisabled"
          :on-click="saveProfile"
          :border="true"
          :label="'Update Profile'"
        />
      </content-column>
    </page-section>
    <page-section :margin-top="'xs'">
      <content-column>
        <div v-if="error !== undefined" class="text-primary">
          {{ error }}
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardHeading from '../../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import LinkButton from '../../../../core/button/LinkButton.vue';
import UserSkillLevel from './user-skill-level/UserSkillLevel.vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {
  useNotificationPopup,
  standardNotification
} from '../../../../vue-composition/notification-popup/notification-popup';
import {updateProfile} from '../../edit-profile-utils';
import {redirectNoHistory} from '../../../../../utils/url';
import {
  clearSkillLevelForCurrentUser,
  getSkillLevelOfCurrentUser,
  setSkillLevelForCurrentUser
} from '../../../../../backend/user/user-query';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    StandardHeading,
    StandardButton,
    LinkButton,
    UserSkillLevel
  },
  props: {
    result: {type: String, required: true},
    firstName: {type: String, default: ''},
    lastName: {type: String, default: ''},
    userId: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const error = ref(undefined as string | undefined);
    const firstNameValue = ref(props.firstName);
    const lastNameValue = ref(props.lastName);

    const currentLevel = ref<string | null>(null);
    const selectedLevelNumber = ref<number | undefined>(undefined);
    const levelSelected = ref(false);

    const saveDisabled = computed(() => {
      const nameIsDifferent =
        firstNameValue.value !== props.firstName || lastNameValue.value !== props.lastName;
      return !(nameIsDifferent || levelSelected.value);
    });

    onMounted(async () => {
      if (props.result === 'success') {
        notification.notify(standardNotification('Profile updated.'));
      }

      /* 
        Load current skill level; it can be null, 'noChoice', 'skippedSurvey', 
        or the level number.
      */
      loader.setLoading(true);
      currentLevel.value = await getSkillLevelOfCurrentUser();
      if (
        currentLevel.value !== null &&
        currentLevel.value !== 'noChoice' &&
        currentLevel.value !== 'skippedSurvey'
      ) {
        selectedLevelNumber.value = parseInt(currentLevel.value) - 1;
      }
      loader.setLoading(false);
    });

    const saveProfile = async () => {
      const profile = {
        userId: props.userId,
        firstName: firstNameValue.value,
        lastName: lastNameValue.value
      };
      loader.setLoading(true, 'Updating your profile');
      const success = await updateProfile(profile).catch((_error: Readonly<Error>) => {
        loader.setLoading(false);
        error.value = _error.message;
        return;
      });

      if (levelSelected.value) {
        if (selectedLevelNumber.value === undefined) {
          await clearSkillLevelForCurrentUser('noChoice');
        } else {
          await setSkillLevelForCurrentUser(selectedLevelNumber.value + 1);
        }
      }

      if (success) {
        redirectNoHistory('/profile?result=success');
      }
    };

    const onSelect = (level: number) => {
      levelSelected.value = true;
      if (level === selectedLevelNumber.value) {
        selectedLevelNumber.value = undefined;
      } else {
        selectedLevelNumber.value = level;
      }
    };

    return {
      saveProfile,
      saveDisabled,
      onSelect,
      selectedLevelNumber,
      notification,
      firstNameValue,
      lastNameValue,
      error
    };
  }
});
</script>
