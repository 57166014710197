<template>
  <div v-if="tutors.length > 0" :class="`${textColor} text-fl-base`">{{ tutors }}</div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';

export default defineComponent({
  components: {},
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, default: undefined},
    textColor: {type: String, default: 'text-mid'},
    portraits: {type: Boolean, default: false}
  },
  setup(props) {
    const tutors = computed(() => {
      if (props.contentEntry === undefined) {
        return [' '];
      }
      return props.contentEntry.tutors.map(t => t.title).join(', ');
    });
    return {tutors};
  }
});
</script>
