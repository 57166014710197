<template>
  <div
    v-if="
      level !== undefined &&
      trails !== undefined &&
      levelNumber !== undefined &&
      spotColor !== undefined &&
      levelInfoLoaded
    "
  >
    <players-path-level-preview
      v-if="guest || preview || levelEntryQuiz !== undefined"
      :preview="preview"
      :guest="guest"
      :level="level"
      :level-number="levelNumber"
      :spot-color="spotColor"
      :entry-quiz="levelEntryQuiz"
      :trails="trails"
      :bookmark="bookmark"
    />
    <players-path-level-unlocked
      v-else
      :preview="preview"
      :guest="guest"
      :level="level"
      :level-number="levelNumber"
      :spot-color="spotColor"
      :level-complete="levelComplete"
      :piece-quiz-statuses="pieceQuizStatuses"
      :pieces-completed="piecesCompleted"
      :next-level="nextLevel"
      :trails="trails"
      :bookmark="bookmark"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {PlayersPathLevel} from '../../../backend/content/players-path/players-path-types';
import {getPlayersPathLevelBySlug} from '../../../backend/content/players-path/players-path-query';
import PlayersPathLevelPreview from './variants/PlayersPathLevelPreview.vue';
import PlayersPathLevelUnlocked from './variants/PlayersPathLevelUnlocked.vue';
import {areQuizzesComplete} from '../../../backend/content/quiz/quiz-query';
import {numberOfCompletedPieces} from '../../../backend/content/players-path/players-path-query-utils';
import {Quiz, QuizCollectionStatus} from '../../../backend/content/quiz/quiz-types';
import {getNextPlayersPathLevel} from '../../../backend/content/players-path/players-path-query';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {getPlayersPathLevelNumberFromSlug, playersPathLevelSpotColor} from './utils';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';

export default defineComponent({
  components: {
    PlayersPathLevelPreview,
    PlayersPathLevelUnlocked
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);

    const level = ref(undefined as Readonly<PlayersPathLevel> | undefined);
    const piecesCompleted = ref(0);
    const pieceQuizStatuses = ref({} as QuizCollectionStatus);
    const nextLevel = ref(undefined as Readonly<PlayersPathLevel> | undefined);
    const levelComplete = ref(false);
    const levelEntryQuiz = ref(undefined as Readonly<Quiz> | undefined);
    const levelInfoLoaded = ref(false);

    const levelNumber = computed(() => {
      if (level.value !== undefined) {
        const number = getPlayersPathLevelNumberFromSlug(level.value.slug);
        return number;
      }
      return undefined;
    });

    const spotColor = computed(() => {
      if (levelNumber.value !== undefined) {
        return playersPathLevelSpotColor(levelNumber.value);
      }
      return undefined;
    });

    const bookmark = useBookmark(props.guest);

    const preview = computed(() => {
      if (level.value === undefined) {
        return true;
      }
      return level.value.preview;
    });

    onMounted(async () => {
      loader.setLoading(true);

      level.value = await getPlayersPathLevelBySlug(props.slug);
      if (level.value !== undefined) {
        trails.value = await useBreadcrumbTrails(level.value, props.entryBreadcrumbTrails);

        if (props.guest) {
          loader.setLoading(false);
          levelInfoLoaded.value = true;
          return;
        }

        pieceQuizStatuses.value = await areQuizzesComplete(level.value.quizzes);

        /*
          Check if the user has completed the prerequisite quiz for this level;
          If not, display a preview of this level.
        */
        const entryQuizComplete = pieceQuizStatuses.value[level.value.quizzes[0].id];
        if (!entryQuizComplete) {
          levelEntryQuiz.value = level.value.quizzes[0];
        }

        piecesCompleted.value = numberOfCompletedPieces(level.value, pieceQuizStatuses.value);
        // ### We *should* fetch the prerequisites requirements for the entry quiz of the _next_ level.
        levelComplete.value = piecesCompleted.value >= 3;

        nextLevel.value = await getNextPlayersPathLevel(level.value);

        await bookmark.init();
      }
      levelInfoLoaded.value = true;
      loader.setLoading(false);
    });

    return {
      preview,
      level,
      levelNumber,
      spotColor,
      piecesCompleted,
      levelComplete,
      pieceQuizStatuses,
      nextLevel,
      levelEntryQuiz,
      levelInfoLoaded,
      trails,
      bookmark
    };
  }
});
</script>
