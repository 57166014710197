export function lerp(a: number, b: number, t: number) {
  return a * (1.0 - t) + b * t;
}

export function unlerp(a: number, b: number, t: number) {
  return (t - a) / (b - a);
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
