<template>
  <div
    class="flex flex-col items-center gap-fl-lg bg-darkest rounded-lg select-none px-fl-md-xl py-fl-md-2xl"
  >
    <div class="flex justify-between items-center">
      <!-- Pause/Play -->
      <div class="basis-1/3 flex flex-nowrap items-end mr-fl-lg">
        <song-player-play :song="song" :player="player" />
        <song-player-reset :song="song" :player="player" class="width-1/3" />
      </div>

      <!--  Bar/Beat -->
      <div class="basis-2/3 flex flex-nowrap gap-fl-xs">
        <song-player-bar
          :player="player"
          :label="false"
          :text-size="'text-fl-10xl'"
          class="flex-auto basis-3/4"
        />
        <song-player-beat
          :player="player"
          :label="false"
          :text-size="'text-fl-10xl'"
          class="flex-auto basis-1/4"
        />
      </div>
    </div>

    <!-- Loop range -->
    <div class="w-full flex gap-fl-md">
      <song-player-range-selector
        :type="'start'"
        :disabled="disabled"
        :title="'Start Bar'"
        :range-selection="range"
        :padding="'p-fl-2xs-sm'"
        :gap="'gap-fl-3xs'"
        :font-size="fontSize"
      />
      <song-player-range-selector
        :type="'end'"
        :disabled="disabled"
        :title="'End Bar'"
        :range-selection="range"
        :padding="'p-fl-2xs-sm'"
        :gap="'gap-fl-3xs'"
        :font-size="fontSize"
      />
    </div>

    <!-- Options -->
    <div class="flex justify-center">
      <link-button
        :size="'sm'"
        :label="optionsLabel"
        :tooltip="'Toggle Options'"
        :color-enabled="'text-light'"
        :on-click="onToggleOptions"
      />
    </div>
    <collapser :status="options" :init="init">
      <template #visible>
        <div class="w-full flex flex-col flex-nowrap gap-fl-xs">
          <song-player-playback-speed :disabled="disabled" :variation-config="variationConfig" />
          <standard-divider class="border-dark" />
          <song-player-repeat :disabled="disabled" :player="player" />
          <standard-divider class="border-dark" />
          <song-player-stem :disabled="disabled" :variation-config="variationConfig" />
          <standard-divider class="border-dark" />
          <song-player-precount :disabled="disabled" :variation-config="variationConfig" />
        </div>
      </template>
    </collapser>
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import Collapser from '../../../generic/collapser/Collapser.vue';
import SongPlayerRangeSelector from '../partials/SongPlayerRangeSelector.vue';
import SongPlayerPlay from '../partials/SongPlayerPlay.vue';
import SongPlayerReset from '../partials/SongPlayerReset.vue';
import SongPlayerBar from '../partials/SongPlayerBar.vue';
import SongPlayerBeat from '../partials/SongPlayerBeat.vue';
import SongPlayerPlaybackSpeed from '../partials/SongPlayerPlaybackSpeed.vue';
import SongPlayerStem from '../partials/SongPlayerStem.vue';
import SongPlayerRepeat from '../partials/SongPlayerRepeat.vue';
import SongPlayerPrecount from '../partials/SongPlayerPrecount.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import {VariationConfig} from '../internals/utils';
import {Song} from '../../../../backend/song/song-types';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import {useRangeSelection} from '../internals/bar-select';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {lerp} from '../../../../utils/math';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';

export default {
  components: {
    StandardDivider,
    Collapser,
    LinkButton,
    SongPlayerRangeSelector,
    SongPlayerPlay,
    SongPlayerReset,
    SongPlayerBar,
    SongPlayerBeat,
    SongPlayerPlaybackSpeed,
    SongPlayerStem,
    SongPlayerRepeat,
    SongPlayerPrecount
  },
  props: {
    song: {type: Object as PropType<Readonly<Song>>, required: true},
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, required: true}
  },
  setup(props) {
    const range = useRangeSelection(props.song, props.player);
    const deviceType = useDeviceType();
    const fontSize = computed(() => {
      const size = lerp(35, 60, deviceType.interpolations.narrow.value);
      return `font-size:${size}px`;
    });

    const localStorage = useLocalStorageFlag('song-player-options', true);
    const init = localStorage.get();
    const options = computed(() => {
      return localStorage.get();
    });
    const onToggleOptions = () => {
      localStorage.toggle();
    };
    const optionsLabel = computed(() => (options.value ? 'HIDE OPTIONS' : 'SHOW OPTIONS'));

    return {
      range,
      fontSize,
      options,
      init,
      optionsLabel,
      onToggleOptions
    };
  }
};
</script>
