<template>
  <div class="w-full flex flex-col group">
    <a :href="url" aria-hidden="true" tabindex="-1">
      <slot name="hero" />
    </a>
    <div class="w-full">
      <slot name="center" />
    </div>
    <div v-if="bodyLink === false" class="w-full flex justify-between items-start gap-fl-md">
      <slot name="body" />
    </div>
    <div v-else class="w-full flex justify-between items-start gap-fl-md">
      <a :href="url" class="grow group">
        <slot name="body" />
      </a>
      <slot name="buttons" />
    </div>
  </div>
</template>

<script lang="ts">
/**
 * Generic card component with a hero image, a center area (where you can display,
 * e.g., a progress bar), and a body area. The body area is divided into two sections:
 * one where you can display things like title, duration, etc., and one where you
 * can display buttons, e.g., bookmark button.
 */
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  props: {
    url: {type: String, default: undefined},
    /** Note: The bodylink prop controls whether the body section (title & summary) is a link.
     * Defaults to true, set to false in parent component if the body section should not be a link.
     * This use case first arose for the member-discount page – the discount codes are difficult to copy if the summary text functions as a link.
     */
    bodyLink: {type: Boolean, default: true}
  },
  setup() {}
});
</script>
