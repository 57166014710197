<template>
  <cluster :justify="'justify-end'" :gap="'gap-fl-sm'" :wrap="false" :class="color">
    <cluster :gap="'gap-fl-2xs'" :wrap="false">
      <div :class="`${fontSize} ${color} uppercase`">Count:</div>
      <div :class="`${fontSize}`">
        {{ variationConfig.preCountDisplay.value }}
      </div>
    </cluster>
    <up-down-button-combo
      :gap="'gap-fl-sm'"
      :disabled="disabled"
      :color="'lightest'"
      :font-size="buttonSize"
      :on-up="variationConfig.preCountBarsUp"
      :on-down="variationConfig.preCountBarsDown"
      :tooltip-up="'Increase the number of precount bars'"
      :tooltip-down="'Decrease the number of precount bars'"
    />
  </cluster>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import UpDownButtonCombo from '../../../core/button/UpDownButtonCombo.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faTimer} from '@fortawesome/pro-regular-svg-icons/faTimer';
import {VariationConfig} from '../internals/utils';

export default {
  components: {
    UpDownButtonCombo,
    Cluster
  },
  props: {
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, default: false},
    fontSize: {type: String, default: 'text-fl-base'},
    buttonSize: {type: String, default: 'text-fl-3xl'}
  },
  setup(props) {
    const iconTimer = computed(() => faTimer);

    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));

    return {
      color,
      iconTimer
    };
  }
};
</script>
