import {DateTime} from 'luxon';
import {getAccessPassesForCurrentUser} from '../access-pass/access-pass-query';
import {AccessPassStatus} from '../access-pass/access-pass-types';
import {getAllAddOnOffers} from './addon-offer-query';
import {AddOnOffer} from './addon-offer-types';
import {evaluateConditions} from './conditions/offer-conditions';
import {CraftId} from '../craft/craft-types';
import {OfferConditionsContextValue} from './offer-conditions-types';

function addOnOfferIsActive(
  offer: Readonly<AddOnOffer>,
  guest: boolean,
  context: OfferConditionsContextValue,
  userSignupDateUTC: number | undefined,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  if (!offer.offerIsActive) {
    return false;
  }

  const destinationPass = offer.offerDestination[0];
  if (destinationPass === undefined) {
    return false;
  }

  // Cannot purchase a pass that is active, pending, past due, or in trial.
  const status = userAccessPasses.find(p => p.id === destinationPass.id);
  if (status !== undefined) {
    if (['active', 'pending', 'trial', 'pastDue'].includes(status.status)) {
      return false;
    }
  }

  // Check the other offer conditions. They must ALL be true.
  return evaluateConditions<AddOnOffer>(
    offer,
    guest,
    context,
    userSignupDateUTC,
    serverDateUTC,
    userAccessPasses
  );
}

/**
 * Return a list of available add-on offers for the current user.
 */
export async function getAddOnOffersForCurrentUser(
  guest: boolean,
  contexts: ReadonlyArray<OfferConditionsContextValue>,
  userDateCreated: string | undefined,
  serverDate: string,
  offerIds: ReadonlyArray<CraftId> = []
): Promise<ReadonlyArray<AddOnOffer>> {
  const userDateUTC = userDateCreated ? DateTime.fromISO(userDateCreated).valueOf() : undefined;
  const serverDateUTC = DateTime.fromISO(serverDate).valueOf();

  let userAccessPasses: ReadonlyArray<AccessPassStatus> = [];
  if (!guest) {
    userAccessPasses = await getAccessPassesForCurrentUser();
  }

  const allOffers = await getAllAddOnOffers();
  const activeOffers = allOffers.filter(offer => {
    const active = contexts.find(context => {
      const activeInContext = addOnOfferIsActive(
        offer,
        guest,
        context,
        userDateUTC,
        serverDateUTC,
        userAccessPasses
      );
      return activeInContext;
    });
    const included = offerIds.length === 0 ? true : offerIds.includes(offer.id);
    return active !== undefined && included;
  });

  return activeOffers;
}
