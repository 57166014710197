<template>
  <signup-light />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import SignupLight from './pages/light/SignupLight.vue';

export default defineComponent({
  components: {
    SignupLight
  },
  props: {variant: {type: String, required: true}},
  setup() {}
});
</script>
