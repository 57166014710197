<template>
  <cluster :gap="'gap-fl-sm'" :wrap="false" :class="'text-lightest'">
    <div :class="`${fontSize} uppercase`">Repeat:</div>
    <link-button
      :color="'lightest'"
      :on-click="audioPlayer.toggleRepeat"
      :tooltip="'Toggle Repeat'"
      :label="audioPlayer.repeatLabel.value"
      :font-size="`${fontSize}`"
    />
  </cluster>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faRepeat} from '@fortawesome/pro-solid-svg-icons/faRepeat';
import {AudioPlayerComposition} from '../internals/utils';

export default {
  components: {
    LinkButton,
    Cluster
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true},
    fontSize: {type: String, default: 'text-fl-base'}
  },
  setup() {
    const iconRepeat = computed(() => faRepeat);
    return {
      iconRepeat
    };
  }
};
</script>
