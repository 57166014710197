<template>
  <page-content>
    <page-section>
      <content-column :width="'narrow'">
        <standard-heading :center="true">What Do You Want To Learn Today?</standard-heading>
      </content-column>
    </page-section>

    <page-section v-if="curations.length > 0" :margin-top="'md'">
      <tabbed-panel
        :tabs="tabs"
        :button-size="'xl'"
        :justify="'justify-center'"
        :items="'items-center'"
      >
        <template v-for="(section, index) in curations" :key="index" #[`${index}`]>
          <content-column :width="'normal'" class="mt-fl-md-xl">
            <div class="flex flex-wrap justify-center gap-fl-xs">
              <a
                v-for="(topic, topicIndex) in section.curationSection"
                :key="topic.curationTopicTitle"
                :href="`${section.url}?topic=${topicIndex}`"
                class="py-fl-2xs px-fl-md text-fl-sm whitespace-nowrap text-center text-light hover:text-white border-light border rounded-full hover:border-white"
              >
                {{ topic.curationTopicTitle }}
              </a>
            </div>
          </content-column>
        </template>
      </tabbed-panel>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';

import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import TabbedPanel from '../../generic/tabbed-panel/TabbedPanel.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {getAllCurations} from '../../../backend/curation/curation-query';
import {Curation} from '../../../backend/curation/curation-types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    TabbedPanel
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {
    const loader = useFullScreenLoader();

    const curations = ref<ReadonlyArray<Curation>>([]);
    const tabs = computed(() => {
      return curations.value.map(curation => curation.title);
    });

    onMounted(async () => {
      loader.setLoading(true);
      curations.value = await getAllCurations();
      loader.setLoading(false);
    });
    return {
      curations,
      tabs
    };
  }
});
</script>
