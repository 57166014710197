<template>
  <content-column :width="width">
    <div :class="`grid ${cols}`" :style="style">
      <learning-pathway-card
        v-for="ce in contentEntries"
        :key="ce.id"
        :content-entry="ce"
        :progress="progress[ce.id]"
        :image-width="imageWidth"
        class="pb-4"
      />
    </div>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import LearningPathwayCard from './LearningPathwayCard.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {deviceTypeSwitch, useDeviceType} from '../../../vue-composition/device-type/device-type';
import {useColumnLayout} from '../../../vue-composition/column-layout/column-layout';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    ContentColumn,
    LearningPathwayCard
  },
  props: {
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const columnLayout = useColumnLayout(deviceType);

    const imageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 700,
      md: 450,
      lg: 360,
      xl: 440,
      '2xl': 440
    };

    const cols = computed(() => {
      if (props.contentEntries.length % 3 === 0) {
        return deviceTypeSwitch<string>({
          narrow: 'grid-cols-2',
          md: 'grid-cols-3'
        }).value;
      }
      // ### We currently have Learning Pathway collections of size 3 and 5. Update this code when that changes.
      return deviceTypeSwitch<string>({
        narrow: 'grid-cols-2',
        md: 'grid-cols-3',
        // xl: 'grid-cols-5'
        xl: 'grid-cols-3'
      }).value;
    });

    const style = computed(() => {
      return `gap:${columnLayout.config.value.gutterWidth}px`;
    });

    return {
      cols,
      style,
      imageWidth
    };
  }
});
</script>
