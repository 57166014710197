<template>
  <page-content>
    <page-section :margin-top="'md'">
      <content-entry-section-header
        :width="'wide'"
        :title-width="'wide'"
        :center="centerTitle"
        :title="title"
        :section-handle="sectionHandle"
        :title-image="titleImage"
        :info-button="infoButton"
        :on-info-button-clicked="onInfoButtonClicked"
      />
    </page-section>
    <slot />
    <page-section
      :margin-top="'sm'"
      :margin-bottom="'xs'"
      :class="'sticky left-0 top-[80px] self-start z-10'"
    >
      <content-entry-collection-mode-bar :collection="collection" />
    </page-section>
    <content-entry-section-list
      :guest="guest"
      :sections="collection.searchSortFilterOutput.value"
      :completed="completed"
      :progress="progress"
      :bookmark="bookmark"
      :display-post-date="displayPostDate"
      :layout="collection.layout.value"
      :width="'wide'"
      :disabled="disabled"
      :card-image-width="cardImageWidth"
    />
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentEntrySectionList from '../collections/variants/ContentEntrySectionList.vue';
import ContentEntryCollectionModeBar from '..//collections/utilities/ContentEntryCollectionModeBar.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {getProductSectionWithHandle} from '../../../backend/product/product-query';
import ContentEntrySectionHeader from '../partials/section/ContentEntrySectionHeader.vue';
import {useProgressMap} from '../../vue-composition/progress/progress';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {ContentEntrySortScheme} from '../../vue-composition/sort/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useCollection} from '../collections/utilities/collection/collection';
import {AccessPassStatus} from '../../../backend/access-pass/access-pass-types';
import {getAccessPassesForCurrentUser} from '../../../backend/access-pass/access-pass-query';
import {ImgixImageConfig, ImgixImageResponsiveWidth} from '../../core/imgix-image/types';
import {usePaginatedContentEntryFetch} from '../../vue-composition/paginated-fetch/paginated-content-entry-fetch';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

/**
 * Content entry index page.
 *
 * This component implements a common pattern in the SBL website design:
 * a page that presents an index of content entries.
 *
 * More specifically, this component renders a complete page with a header
 * and an index of content entries that can be filtered, sorted, and searched.
 * The index can be switched between different visual representations,
 * such as card grid and list.
 *
 * The component is intended to be rendered directly from a Craft template.
 */
export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentEntrySectionList,
    ContentEntrySectionHeader,
    ContentEntryCollectionModeBar
  },
  props: {
    guest: {type: String, required: true},
    title: {type: String, default: undefined},
    centerTitle: {type: Boolean, default: false},
    sectionHandle: {type: String, required: true},
    displayPostDate: {type: Boolean, default: false},
    sortSchemes: {
      type: Array as PropType<ReadonlyArray<ContentEntrySortScheme>>,
      required: true
    },

    // URL of optional image that replaces the title (e.g., learning pathways logotype)
    titleImage: {type: Object as PropType<ImgixImageConfig>, default: undefined},
    // If defined, display an info icon that switches to the landing page for the section.
    landingPageSlug: {type: String as PropType<CraftSlug>, default: undefined},
    // If true, flip cards to 3:4 aspect ratio (rather than default 16:9)
    flip: {type: Boolean, default: false}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const bookmark = useBookmark(props.guest);
    const landingPage = props.landingPageSlug ? useLandingPage(props.landingPageSlug) : undefined;
    const sectionTitle = ref('');

    const infoButton = computed(() => {
      return props.landingPageSlug !== undefined;
    });
    const onInfoButtonClicked = () => {
      if (landingPage !== undefined) {
        landingPage.toggle();
      }
    };

    const paginatedFetch = usePaginatedContentEntryFetch();
    // We have one (anonymous) section where we collect the fetched results.
    const sections = computed(() => {
      return [
        {
          section: {
            title: '',
            handle: '',
            type: '',
            url: undefined
          },
          contentEntries: paginatedFetch.contentEntries.value
        }
      ];
    });

    const progress = useProgressMap(props.guest, paginatedFetch.contentEntries);

    const accessPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);

    const collection = useCollection(
      sections,
      progress.progressMap,
      accessPasses,
      props.sortSchemes,
      `idx-${props.sectionHandle}`
    );

    onMounted(async () => {
      loader.setLoading(true);

      const section = await getProductSectionWithHandle(props.sectionHandle);
      if (section !== undefined) {
        sectionTitle.value = props.title ? props.title : section.title;
        await bookmark.init();
        accessPasses.value = await getAccessPassesForCurrentUser();
        await collection.init();
        paginatedFetch.start(contentEntries().section([props.sectionHandle]));
      } else {
        throw new Error(`${props.sectionHandle} is not a product section`);
      }

      loader.setLoading(false);
    });

    const disabled = computed(() => paginatedFetch.contentEntries.value.length === 0);

    const cardImageWidth: ImgixImageResponsiveWidth = {
      narrow: 300,
      sm: 340,
      md: 350,
      lg: 300,
      xl: 330,
      '2xl': 400
    };

    return {
      sectionTitle,
      infoButton,
      onInfoButtonClicked,
      progress: progress ? progress.progressMap : undefined,
      completed: progress ? progress.completedMap : undefined,
      bookmark,
      collection,
      disabled,
      cardImageWidth
    };
  }
});
</script>
