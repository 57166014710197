<template>
  <button
    :class="disabled ? 'nextbutton-disabled' : 'nextbutton'"
    :disabled="disabled"
    :title="'Next Step'"
    @click.prevent="click"
  >
    {{ label }}
  </button>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
export default defineComponent({
  props: {
    id: {type: String, default: undefined},
    label: {type: String, required: true},
    disabled: {type: Boolean, required: true},
    onClick: {type: Function as PropType<(id: string | undefined) => void>, required: true}
  },
  setup(props) {
    const click = () => {
      props.onClick(props.id);
    };
    return {click};
  }
});
</script>

<style scoped>
.nextbutton {
  padding-top: clamp(20px, 1.5625vw, 30px);
  padding-right: clamp(20px, 1.71875vw, 33px);
  padding-bottom: clamp(20px, 1.5625vw, 30px);
  padding-left: clamp(20px, 1.71875vw, 33px);
  font-weight: 600;
  font-size: clamp(16px, 1.04166667vw, 20px);
  text-align: center;
  color: #1f1f1f;
  line-height: 0.8;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #6fffde 0%,
    #ffe245 20%,
    #ff5057 40%,
    #ffe245 60%,
    #6fffde 80%,
    #ffe245 100%
  );
  background-size: 600%;
  animation: swoonRight 4s infinite linear;
}
.nextbutton::after {
  content: '›';
  padding-left: 0;
  opacity: 0;
  font-weight: 1000;
  transition: all 0.2s;
}
.nextbutton:hover::after {
  padding-left: 0.7em;
  opacity: 1;
}
@keyframes swoonRight {
  0% {
    background-position-x: 99%;
  }
  100% {
    background-position-x: 0%;
  }
}

.nextbutton-disabled {
  min-width: clamp(170px, 13.02083333vw, 250px);
  padding-top: clamp(20px, 1.5625vw, 30px);
  padding-right: clamp(20px, 1.71875vw, 33px);
  padding-bottom: clamp(20px, 1.5625vw, 30px);
  padding-left: clamp(20px, 1.71875vw, 33px);
  font-weight: 600;
  font-size: clamp(16px, 1.04166667vw, 20px);
  text-align: center;
  color: #1f1f1f;
  background-color: #c9c9c9;
  line-height: 0.8;
  border-radius: 5px;
}
</style>
