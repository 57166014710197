<template>
  <page-section :margin-top="marginTop">
    <content-column :width="width">
      <variants>
        <template #sm->
          <div class="p-fl-md flex flex-col gap-fl-lg border rounded-xl border-[rgb(124,255,174)]">
            <imgix-image :config="imageConfig" class="w-full rounded-[0.5rem]" />
            <div class="flex flex-col justify-center">
              <div class="text-center text-white text-fl-4xl font-semibold mb-fl-2xs">
                Join a New Member Huddle!
              </div>
              <div class="text-center text-[rgb(124,255,174)] text-fl-4xl font-semibold mb-fl-sm">
                Every Monday at 4PM UK time
              </div>
              <div class="text-center text-light text-fl-lg mb-fl-xl">
                Hang out with the team, ask questions, meet other new members, and get helpful
                practice and study tips. It's a great way for us to meet you, and to help you get
                the most out of your membership.
              </div>
              <div class="flex flex-col gap-fl-md justify-center items-center">
                <standard-button :size="'sm'" :label="'Join via Zoom'" :url="huddleUrl" />
                <link-button
                  :size="'sm'"
                  :on-click="onHide"
                  :label="'Don\'t show me this again'"
                  :underline="true"
                />
              </div>
            </div>
          </div>
        </template>
        <template #md+>
          <div class="p-fl-md flex border rounded-xl border-[rgb(124,255,174)]">
            <div class="basis-1/2 pr-fl-3xs-3xl flex flex-col justify-center">
              <imgix-image :config="imageConfig" class="rounded-[0.5rem]" />
            </div>
            <div class="grow-0 basis-1/2 flex flex-col justify-center">
              <div class="text-white text-fl-4xl font-semibold mb-fl-2xs">
                Join a New Member Huddle!
              </div>
              <div class="text-[rgb(124,255,174)] text-fl-4xl font-semibold mb-fl-sm">
                Every Monday at 4PM UK time
              </div>
              <div class="text-light text-fl-lg mb-fl-xl">
                Hang out with the team, ask questions, meet other new members, and get helpful
                practice and study tips. It's a great way for us to meet you, and to help you get
                the most out of your membership.
              </div>
              <div v-if="onHide !== undefined" class="flex gap-fl-xl items-center">
                <standard-button :size="'sm'" :label="'Join via Zoom'" :url="huddleUrl" />
                <link-button
                  :size="'sm'"
                  :on-click="onHide"
                  :label="'Don\'t show me this again'"
                  :underline="true"
                />
              </div>
            </div>
          </div>
        </template>
      </variants>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {NEW_MEMBER_HUDDLE_URL} from '../../../content-pages/orientation/new-member-huddle-url';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {PageSectionMargin} from '../../../core/page/PageSection.vue';
import {ImgixImageConfig} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    Variants,
    PageSection,
    ContentColumn,
    StandardButton,
    LinkButton,
    ImgixImage
  },
  props: {
    onHide: {type: Function as PropType<ButtonClickCallback>, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'medium'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'xl'}
  },
  setup() {
    const imageConfig: Readonly<ImgixImageConfig> = {
      path: 'content/new-member-huddle.png',
      alt: 'New Member Huddle',
      width: {
        narrow: 470,
        sm: 650,
        md: 390,
        lg: 500,
        xl: 600,
        '2xl': 560
      },
      imgixParams: {
        fit: 'crop',
        aspectRatio: '15:9'
      }
    };
    const huddleUrl = computed(() => NEW_MEMBER_HUDDLE_URL);

    return {
      huddleUrl,
      imageConfig
    };
  }
});
</script>
