<template>
  <div v-if="!contentEntry.preview && !guest && contentEntry.resources.length > 0">
    <page-section :margin-top="marginTop">
      <page-section-header :width="width" :justify="justify" :heading="heading" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column :width="width" :justify="justify">
        <ul>
          <li v-for="(item, i) in items" :key="item.id">
            <standard-divider v-if="i === 0" class="border-dark" />
            <link-icon-button
              :size="'md'"
              :icon="item.icon"
              :url="item.url"
              :new-tab="true"
              :label="item.title"
              :gap="'gap-4'"
              :wrap="true"
              :expand="true"
              :color="'lightest'"
              :justify="'justify-start'"
              class="py-fl-lg"
            />
            <standard-divider class="border-dark" />
          </li>
        </ul>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {faLink} from '@fortawesome/pro-regular-svg-icons/faLink';
import {faBook} from '@fortawesome/pro-light-svg-icons/faBook';
import {faFileZipper} from '@fortawesome/pro-light-svg-icons/faFileZipper';
import {faFileAudio} from '@fortawesome/pro-light-svg-icons/faFileAudio';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentColumn,
    LinkIconButton,
    StandardDivider
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    heading: {type: String, default: 'Downloads'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: undefined}
  },
  setup(props) {
    const items = props.contentEntry.resources.map(resource => {
      let icon: IconDefinition = faLink;
      const url = resource.resourceUrl;
      if (url !== null) {
        const ext = url.substring(url.length - 4).toLowerCase();
        if (ext === '.pdf') {
          icon = faBook;
        }
        if (ext === '.zip') {
          icon = faFileZipper;
        }
        if (ext === '.mp3') {
          icon = faFileAudio;
        }
      }
      return {
        id: resource.resourceTitle,
        title: resource.resourceTitle,
        url,
        icon
      };
    });
    return {items};
  }
});
</script>
