<template>
  <div>
    <page-section :margin-top="'md'">
      <content-column :width="'wide'">
        <div class="flex justify-between items-end flex-wrap gap-x-fl-2xl gap-y-fl-md">
          <standard-heading :size="1">Welcome!</standard-heading>
          <cluster class="pb-1">
            <link-button :size="'md'" :url="'/live-class'" :color="'mid'" :label="'LiveClass'" />
            <link-button
              v-if="!guest"
              :size="'md'"
              :url="'https://campus.scottsbasslessons.com'"
              :new-tab="true"
              :color="'mid'"
              :label="'Campus'"
            />
            <link-button
              :size="'md'"
              :url="'/groove-trainer'"
              :color="'mid'"
              :label="'Groove Trainer'"
            />
            <link-button :size="'md'" :url="'/contact'" :color="'mid'" :label="'Help & Support'" />
          </cluster>
        </div>
      </content-column>
    </page-section>
    <page-section :margin-top="'md'">
      <content-column :width="'wide'">
        <standard-divider class="border-mid" />
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    Cluster,
    StandardDivider,
    LinkButton
  },
  props: {
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true}
  },
  setup() {}
});
</script>
