/**
 * Pre-count timer (for visual "clicks" during pre-count). This isn't very precise,
 * but probably good enough for at least a visual pre-count.
 */
export class PrecountManager {
  // Pre-count state
  public currentPreCountBeat: number = 0;
  public currentPreCountBar: number = 0;

  private intervalId: number | undefined = undefined;

  // Webaudio context & click sound buffer
  private audioContext: AudioContext | undefined = undefined;
  private buffer: AudioBuffer | undefined = undefined;
  private gainNode: GainNode | undefined = undefined;

  constructor() {
    /*
      Create an audio buffer and fill it with a sine-wave "click".
      See https://blog.paul.cx/post/metronome/
    */
    this.audioContext = new AudioContext();
    this.buffer = this.audioContext.createBuffer(
      1,
      this.audioContext.sampleRate * 2,
      this.audioContext.sampleRate
    );
    const channel = this.buffer.getChannelData(0);

    let phase = 0;
    let amp = 1;
    const duration_frames = this.audioContext.sampleRate / 50;
    const f = 440;
    for (let i = 0; i < duration_frames; i++) {
      channel[i] = Math.sin(phase) * amp;
      phase += (2 * Math.PI * f) / this.audioContext.sampleRate;
      if (phase > 2 * Math.PI) {
        phase -= 2 * Math.PI;
      }
      amp -= 1 / duration_frames;
    }

    // Insert a gain node into the chain so we can control the output volume of the click.
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
    this.gainNode.gain.value = 0.6;
  }

  public play(
    preCountBars: number,
    beatsPerBar: number,
    secondsPerBeat: number,
    onEnd: () => void
  ) {
    let source: AudioBufferSourceNode | undefined = undefined;
    if (
      this.audioContext !== undefined &&
      this.buffer !== undefined &&
      this.gainNode !== undefined
    ) {
      source = this.audioContext.createBufferSource();
      source.buffer = this.buffer;
      source.connect(this.gainNode);
      source.loop = true;
      source.loopEnd = secondsPerBeat;
      source.start(0, 0, preCountBars * beatsPerBar * secondsPerBeat);
    }

    // Display beat; callback on loop end
    this.currentPreCountBar = -preCountBars;
    this.currentPreCountBeat = 0;
    this.intervalId = window.setInterval(() => {
      this.currentPreCountBeat = (this.currentPreCountBeat + 1) % beatsPerBar;
      if (this.currentPreCountBeat === 0) {
        // Beat has wrapped back to 0, so we have a new bar.
        this.currentPreCountBar += 1;
        if (this.currentPreCountBar === 0) {
          // We've reached the first bar of the song; disable pre-count and go to play state.
          if (source !== undefined) {
            source.stop();
          }
          window.clearInterval(this.intervalId);
          this.intervalId = undefined;
          onEnd();
          return;
        }
      }
    }, secondsPerBeat * 1000);
  }

  public stop() {
    if (this.intervalId !== undefined) {
      window.clearInterval(this.intervalId);
    }
  }
}
