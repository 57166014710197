<template>
  <cluster :gap="'gap-fl-2xs'" :wrap="false" :class="color">
    <div :class="`${fontSize} ${color} uppercase`">Bass:</div>

    <link-button
      :disabled="disabled"
      :color="'lightest'"
      :size="buttonSize"
      :font-size="fontSize"
      :on-click="variationConfig.onToggleStem"
      :tooltip="'Toggle Bass'"
      :label="variationConfig.stemDisplay.value"
    />
  </cluster>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {VariationConfig} from '../internals/utils';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faMusic} from '@fortawesome/pro-solid-svg-icons/faMusic';
import {ButtonSize} from '../../../core/button/implementation/utils';

export default {
  components: {
    LinkButton,
    Cluster
  },
  props: {
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, default: false},
    fontSize: {type: String, default: 'text-fl-base'},
    buttonSize: {type: String as PropType<ButtonSize>, default: 'sm'}
  },
  setup(props) {
    const iconMusic = computed(() => faMusic);
    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));
    return {
      color,
      iconMusic
    };
  }
};
</script>
