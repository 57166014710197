<template>
  <margins>
    <content-entry-inline-card
      :guest="guest"
      :content-entry="historyItem.contentEntry"
      :card-image-width="cardImageWidth"
    >
      <template #buttons>
        <icon-button :icon="icon" :size="'lg'" :tooltip="'Delete'" :on-click="onDeleteCallback" />
      </template>
      <template #body>
        <history-card-body-access :history-item="historyItem" />
      </template>
    </content-entry-inline-card>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {tutorList} from '../../../../backend/content/content-entry/content-entry-query-utils';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentEntryInlineCard from '../inline-card/ContentEntryInlineCard.vue';
import HistoryCardBodyAccess from './HistoryCardBodyAccess.vue';
import IconButton from '../../../core/button/IconButton.vue';
import {HistoryItem} from '../../../user-pages/history/history-utils';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {faTrashCan} from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    ContentEntryInlineCard,
    HistoryCardBodyAccess,
    IconButton,
    Margins
  },
  props: {
    guest: {type: String, required: true},
    historyItem: {type: Object as PropType<Readonly<HistoryItem>>, required: true},
    onDelete: {type: Function as PropType<(item: Readonly<HistoryItem>) => void>, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const tutors = computed(() => {
      const tutors = tutorList(props.historyItem.contentEntry);
      return tutors;
    });

    const url = computed(() => {
      return props.historyItem.contentEntry.url;
    });

    const onDeleteCallback = () => {
      props.onDelete(props.historyItem);
    };

    const summary = computed(() => {
      const summary = props.historyItem.contentEntry.summary;
      if (summary === undefined || summary === null) {
        return undefined;
      }
      return summary;
    });

    const icon = computed(() => faTrashCan);

    return {
      icon,
      deviceType,
      onDeleteCallback,
      url,
      tutors,
      summary
    };
  }
});
</script>
