import {Course} from './course-types';
import {getLessonLengthSec} from '../lesson/lesson-utils';
import {CraftId, CraftSectionHandle, CraftSlug} from '../../craft/craft-types';
import {fetchContentEntries} from '../content-entry/content-entry-query';
import {LESSON_FIELDS} from '../lesson/lesson-query';
import {RICH_TEXT_FIELD} from '../../rich-text/rich-text-query';
import * as ContentEntryQueryBuilder from '../content-entry/content-entry-query-builder';

export const COURSE_FIELDS = [
  ContentEntryQueryBuilder.contentEntries('childEntries').fields(LESSON_FIELDS),
  RICH_TEXT_FIELD
];

export async function getCourseBySlug(
  slug: CraftSlug,
  sectionHandle: CraftSectionHandle
): Promise<Course | undefined> {
  try {
    const courses = await fetchContentEntries<Course>(
      ContentEntryQueryBuilder.contentEntries()
        .section([sectionHandle])
        .slug([slug])
        .fields(COURSE_FIELDS)
    );
    const course = courses[0];
    if (course === undefined) {
      return undefined;
    }
    return course;
  } catch (error) {
    throw new Error(`Could not fetch course: ${error}`);
  }
}

export async function getCoursesById(
  entryIds: ReadonlyArray<CraftId>,
  sections: ReadonlyArray<CraftSectionHandle>
): Promise<ReadonlyArray<Course>> {
  try {
    const courses = await fetchContentEntries<Course>(
      ContentEntryQueryBuilder.contentEntries().section(sections).id(entryIds).fields(COURSE_FIELDS)
    );
    return courses;
  } catch (error) {
    throw new Error(`Could not fetch courses: ${error}`);
  }
}

export function getCourseLengthSec(course: Readonly<Course>): number {
  return course.childEntries.reduce((lessonTotal, lesson) => {
    return lessonTotal + getLessonLengthSec(lesson);
  }, 0);
}
