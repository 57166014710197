import Player from '@vimeo/player';
import {nextTick, Ref} from 'vue';

/**
 * Internal VimeoFrame state; It contains the Vimeo Player instance,
 * together with other data needed to manage video playback.
 */
export interface Config {
  player: Player | undefined;
  videoId: string | undefined;
  videoHasPlayed: boolean;
  videoLengthSec: number | undefined;
}

/**
 * Return a function that returns the video playback position.
 */
export function makeGetPlayPosCallback(config: Readonly<Config>) {
  return async () => {
    if (config.player !== undefined) {
      return config.player.getCurrentTime();
    }
    return undefined;
  };
}

/**
 * Return a function that pauses video playback.
 */
export function makePauseCallback(config: Readonly<Config>) {
  return async () => {
    if (config.player !== undefined) {
      await config.player.pause();
    }
    return undefined;
  };
}

/**
 * Return a function that returns the video duration.
 */
export function makeGetDurationCallback(config: Readonly<Config>) {
  return async () => {
    return config.videoLengthSec;
  };
}

/**
 * Return a function that loads a new video.
 */
export function makeCueVideoCallback(
  config: Config,
  url: Ref, // out
  iframe: Ref // out
) {
  /*
   * @param {string} videoId The Vimeo id of the video to load.
   * @param {function} playCallback Call this function when playback starts. (Optional)
   * @param {function} pauseCallback Call this function when playback is paused. (Optional)
   * @param {function} endCallback Call this function when playback ends. (Optional)
   * @param {function} timeUpdateCallback Call this function at regular interval while the video plays. (Optional)
   */
  return async (
    videoId: string,
    playbackPos: number | undefined,
    playCallback: (() => void) | undefined,
    pauseCallback: (() => void) | undefined,
    endCallback: (() => void) | undefined,
    timeUpdateCallback: ((playpos: number, duration: number) => void) | undefined
  ) => {
    let hasSeeked = false;

    config.videoId = videoId;
    config.videoHasPlayed = false;

    /* 
      When loading a new video, unmount the old DOM
      element and mount a new element (with the new src
      URL). We cannot re-use the old element due to this
      bug/problem in the Vimeo Player API:
      https://github.com/vimeo/player.js/issues/7
    */
    url.value = undefined;
    config.player = undefined;
    await nextTick();
    url.value = `https://player.vimeo.com/video/${videoId}`;
    await nextTick();

    // Now that we have a new DOM element, attach a Vimeo Player instance to it.
    config.player = new Player(iframe.value);

    // Remember if the user seeks (= moves the playhead) before playing the video.
    config.player.on('seeked', () => {
      hasSeeked = true;
    });

    // Register Vimeo Player callbacks.
    if (playCallback) {
      config.player.on('play', async () => {
        if (
          !config.videoHasPlayed &&
          playbackPos !== undefined &&
          playbackPos > 0 &&
          config.player !== undefined &&
          !hasSeeked
        ) {
          await config.player.setCurrentTime(playbackPos);
        }
        config.videoHasPlayed = true;
        playCallback();
      });
    }
    if (pauseCallback) {
      config.player.on('pause', () => {
        pauseCallback();
      });
    }
    if (endCallback) {
      config.player.on('ended', () => {
        endCallback();
      });
    }
    if (timeUpdateCallback) {
      config.player.on('timeupdate', data => {
        timeUpdateCallback(data.seconds, data.duration);
      });
    }

    /* 
      We need to wait for the new video to load before returning control
      to the caller. Otherwise, the caller may begin to play the video
      before it has finished loading.
    */
    await new Promise<void>(resolve => {
      if (config.player !== undefined) {
        config.player.on('loaded', () => {
          resolve();
        });
      }
    });

    // Store the length of the video.
    config.videoLengthSec = await config.player.getDuration();

    // Set the initial playhead position.
    if (
      !config.videoHasPlayed &&
      playbackPos !== undefined &&
      playbackPos > 0 &&
      config.player !== undefined
    ) {
      await config.player.setCurrentTime(playbackPos);
    }
  };
}
