<template>
  <content-entry-index-page
    :guest="guest"
    :section-handle="'podcasts'"
    :sort-schemes="['newest', 'oldest']"
    :center-title="false"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ContentEntryIndexPage from '../../content-entry/pages/ContentEntryIndexPage.vue';

export default defineComponent({
  components: {
    ContentEntryIndexPage
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {}
});
</script>
