import {DateTime} from 'luxon';
import {AccessPassStatus} from '../access-pass/access-pass-types';
import {
  Promotion,
  PromotionConditionsBlock,
  PromotionConditionsServerDateIntervalBlock,
  PromotionConditionsSignupDateIntervalBlock,
  PromotionConditionsVisitorStatusBlock,
  PromotionConditionsAccessPassesBlock,
  PromotionGroup
} from './promotion-types';
import {CraftId} from '../craft/craft-types';

function evaluateSignupDate(
  condition: Readonly<PromotionConditionsServerDateIntervalBlock>,
  userSignupDateUTC: number
) {
  if (condition.startDate !== null) {
    const date = DateTime.fromISO(condition.startDate).valueOf();
    if (userSignupDateUTC < date) {
      return false;
    }
  }
  if (condition.endDate !== null) {
    const date = DateTime.fromISO(condition.endDate).valueOf();
    if (userSignupDateUTC > date) {
      return false;
    }
  }
  return true;
}

function evaluateServerDate(
  condition: Readonly<PromotionConditionsSignupDateIntervalBlock>,
  serverDateUTC: number
) {
  if (condition.startDate !== null) {
    const date = DateTime.fromISO(condition.startDate).valueOf();
    if (serverDateUTC < date) {
      return false;
    }
  }
  if (condition.endDate !== null) {
    const date = DateTime.fromISO(condition.endDate).valueOf();
    if (serverDateUTC > date) {
      return false;
    }
  }
  return true;
}

function evaluateVisitorStatus(
  condition: Readonly<PromotionConditionsVisitorStatusBlock>,
  guest: string
): boolean {
  if (condition.visitorStatus === 'guest') {
    return guest.length > 0;
  }
  return guest.length === 0;
}

function evaluateAccessPasses(
  condition: Readonly<PromotionConditionsAccessPassesBlock>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const toBeConsidered = userAccessPasses
    .filter(p => {
      if (condition.accessPassStatus === 'active') {
        return p.status === 'active' || p.status === 'pending' || p.status === 'cancelling';
      }
      return p.status === condition.accessPassStatus;
    })
    .map(p => p.slug);
  for (let i = 0; i < condition.accessPasses.length; i += 1) {
    const pass = condition.accessPasses[i].slug;
    if (toBeConsidered.includes(pass)) {
      return true;
    }
  }
  return false;
}

function promotionIsActive(
  promotion: Readonly<Promotion>,
  guest: string,
  userDateCreatedUTC: number | undefined,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const conditions = promotion.promotionConditions.reduce(
    (status: boolean, condition: Readonly<PromotionConditionsBlock>) => {
      if (!status) {
        return false;
      }

      switch (condition.typeHandle) {
        case 'serverDateInterval':
          return evaluateServerDate(
            condition as Readonly<PromotionConditionsServerDateIntervalBlock>,
            serverDateUTC
          );
        case 'signupDateInterval': {
          if (userDateCreatedUTC === undefined) {
            return false;
          }
          return evaluateSignupDate(
            condition as Readonly<PromotionConditionsSignupDateIntervalBlock>,
            userDateCreatedUTC
          );
        }
        case 'visitorStatus':
          return evaluateVisitorStatus(
            condition as Readonly<PromotionConditionsVisitorStatusBlock>,
            guest
          );
        case 'accessPasses':
          return evaluateAccessPasses(
            condition as Readonly<PromotionConditionsAccessPassesBlock>,
            userAccessPasses
          );
        default:
          throw new Error('Unknown promotion conditions block type');
      }
    },
    true
  );
  return conditions;
}

export function getActivePromotions(
  promotions: ReadonlyArray<Promotion>,
  promotionGroups: ReadonlyArray<PromotionGroup>,
  guest: string,
  userDateCreated: string,
  serverDate: string,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const userDateUTC = !guest ? DateTime.fromISO(userDateCreated).valueOf() : undefined;
  const serverDateUTC = DateTime.fromISO(serverDate).valueOf();

  // Get the set of all promotions that are in active promo groups...
  const groupPromoIds = promotionGroups.reduce((promos: Set<CraftId>, group: PromotionGroup) => {
    if (!group.active) {
      return promos;
    }
    group.promotions.forEach(p => {
      promos.add(p.id);
    });
    return promos;
  }, new Set<CraftId>());

  const groupPromos = Array.from(groupPromoIds).map(id => {
    const found = promotions.find(p => p.id === id);
    if (found === undefined) {
      throw new Error('Internal error');
    }
    return found;
  });

  // ...and check each promo to see if its conditions are all true.
  return groupPromos.filter(p => {
    return promotionIsActive(p, guest, userDateUTC, serverDateUTC, userAccessPasses);
  });
}
