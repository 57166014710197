<template>
  <page-section :margin-top="'sm'">
    <content-column :width="width" :justify="justify">
      <ul
        v-if="listIcon === undefined"
        :class="`list-disc list-inside ${listTextSize} ${listTextColor} space-y-fl-sm`"
      >
        <rich-text-list-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :body-link-primary="bodyLinkPrimary"
        />
      </ul>
      <ul v-else :class="`list-inside ${listTextSize} ${listTextColor} space-y-fl-sm`">
        <rich-text-list-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :body-link-primary="bodyLinkPrimary"
          :list-icon="listIcon"
        />
      </ul>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {RichTextBlock, RichTextList} from '../../../../backend/rich-text/rich-text-types';
import RichTextListItem from './partials/RichTextListItem.vue';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    RichTextListItem,
    PageSection,
    ContentColumn
  },
  props: {
    richTextList: {type: Object as PropType<RichTextBlock>, required: true},
    listTextSize: {type: String, default: 'text-fl-lg'},
    listTextColor: {type: String, default: 'text-light'},
    listIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    bodyLinkPrimary: {type: Boolean, default: false},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const list = computed(() => {
      return props.richTextList as RichTextList;
    });
    const items = computed(() => {
      return list.value.richTextListItems.split('\n');
    });
    return {
      items
    };
  }
});
</script>
