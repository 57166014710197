import {ScreenWidth} from '../../vue-composition/device-type/device-type';
import {ColumnLayoutConfig, LayoutSystemColumns} from './types';

/**
 * Return settings for column width, gutter width, and margin width (in pixels).
 */
export function getColumnLayoutConfig(
  numberOfColumns: LayoutSystemColumns,
  screenWidth: ScreenWidth | undefined
): Readonly<ColumnLayoutConfig> {
  /*
    IMPORTANT! Do not tweak these settings unless you know what you are doing!
    Modifying them will impact the look and feel of the entire SBL website!
  */

  if (numberOfColumns === 4) {
    return {
      layoutSystemColumns: 4,
      maxColumnWidth: 122,
      maxGutterWidth: 10,
      minMarginWidth: 20
    };
  }

  switch (screenWidth) {
    case undefined:
    case 'sm':
    default:
      return {
        layoutSystemColumns: 12,
        maxColumnWidth: 122,
        maxGutterWidth: 10,
        minMarginWidth: 30
      };
    case 'md':
      return {
        layoutSystemColumns: 12,
        maxColumnWidth: 122,
        maxGutterWidth: 10,
        minMarginWidth: 40
      };
    case 'lg':
      return {
        layoutSystemColumns: 12,
        maxColumnWidth: 122,
        maxGutterWidth: 16,
        minMarginWidth: 50
      };
    case 'xl':
      return {
        layoutSystemColumns: 12,
        maxColumnWidth: 122,
        maxGutterWidth: 16,
        minMarginWidth: 55
      };
    case '2xl':
      return {
        layoutSystemColumns: 12,
        maxColumnWidth: 122,
        maxGutterWidth: 16,
        minMarginWidth: 55
      };
  }
}
