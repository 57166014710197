<template>
  <div>
    <standard-heading>{{ title }}</standard-heading>
    <p class="mt-fl-lg">{{ summary }}</p>
    <standard-button :url="redirectURL" :border="true" :label="redirectTitle" class="mt-fl-2xl" />
  </div>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import {Quiz, QuizOutcomeType} from '../../../../backend/content/quiz/quiz-types';
import {getNumberOfQuestions} from '../../../../backend/content/quiz/quiz-query';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../core/button/StandardButton.vue';

export default {
  components: {
    StandardHeading,
    StandardButton
  },
  props: {
    quiz: {type: Object as PropType<Quiz>, required: true},
    status: {type: String as PropType<QuizOutcomeType>, required: true},
    numberOfCorrectAnswers: {type: Number, required: true}
  },
  setup(props) {
    const outcome = computed(() => {
      return props.quiz.quizOutcomes.find(outcome => {
        return outcome.quizOutcomeType === props.status;
      });
    });
    const title = computed(() => {
      if (outcome.value !== undefined) {
        return outcome.value.quizOutcomeTitle;
      }
      return undefined;
    });
    const summary = computed(() => {
      if (outcome.value !== undefined) {
        const summary = outcome.value.quizOutcomeSummary;
        // If the summary string contains '{{count}}' or '{{correct}}', replace them with the corresponding numbers.
        const count = `${getNumberOfQuestions(props.quiz)}`;
        const correct = `${props.numberOfCorrectAnswers}`;
        return summary.replace('{{count}}', count).replace('{{correct}}', correct);
      }
      return undefined;
    });
    const redirectURL = computed(() => {
      if (outcome.value !== undefined) {
        /*
          To facilitate actions upon success/failure of a quiz (e.g., display
          level complete page in Players Path), we add the quiz entry ID as a
          query param to the redirect URL.
        */
        return `${outcome.value.quizOutcomeRedirect[0].url}`;
      }
      return undefined;
    });
    const redirectTitle = computed(() => {
      if (outcome.value !== undefined) {
        const title = outcome.value.quizOutcomeRedirectTitle;
        if (title !== null && title !== undefined) {
          return title;
        }
      }
      return 'Continue';
    });
    return {
      title,
      summary,
      redirectURL,
      redirectTitle
    };
  }
};
</script>
