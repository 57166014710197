<template>
  <page-content v-if="contentEntry !== undefined">
    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-header-breadcrumb-trail
          v-if="trails !== undefined"
          :path="trails.navigationPath"
        />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header>
          {{ contentEntry.title }}
        </content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="contentEntry" :size="'md'" />
          <content-entry-header-post-date :content-entry="contentEntry" />
          <content-entry-header-levels-and-subjects :content-entry="contentEntry" />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="contentEntry"
            :bookmark="bookmark"
            :display="'linkIcon'"
          />
          <content-entry-header-campus
            :guest="guest"
            :content-entry="contentEntry"
            :display="'linkIcon'"
          />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column>
        <content-entry-video
          v-if="recordingExists"
          :guest="guest"
          :video-type="'content'"
          :content-entry="contentEntry"
        />
        <div v-else class="w-full aspect-video flex flex-col justify-center bg-darkest">
          <div class="text-fl-2xl text-white text-center font-semibold">Recording coming soon!</div>
        </div>
      </content-column>
    </page-section>

    <content-entry-summary :margin-top="'lg'" :content-entry="contentEntry" :heading="''" />

    <content-entry-resource-list
      :guest="guest"
      :content-entry="contentEntry"
      :heading="'Resources'"
    />

    <content-entry-recommendations
      :guest="guest"
      :content-entry="contentEntry"
      :bookmark="bookmark"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import ContentEntryHeader from '../../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderPostDate from '../../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderLevelsAndSubjects from '../../../content-entry/partials/header/metadata/ContentEntryHeaderLevelsAndSubjects.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryVideo from '../../../content-entry/partials/body/ContentEntryVideo.vue';
import ContentEntrySummary from '../../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryResourceList from '../../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../../../content-entry/partials/body/ContentEntryRecommendations.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';
import {getFirstVideoOfType} from '../../../../backend/video/video-query';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Cluster,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeader,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderLevelsAndSubjects,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    ContentEntryHeaderTutors,
    ContentEntryVideo,
    ContentEntrySummary,
    ContentEntryResourceList,
    ContentEntryRecommendations
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const recordingExists = getFirstVideoOfType(props.contentEntry.videos, 'content') !== undefined;
    return {
      recordingExists
    };
  }
});
</script>
