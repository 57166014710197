<template>
  <content-entry-index-page
    :guest="guest"
    :display-progress="true"
    :section-handle="'courses'"
    :center-title="false"
    :sort-schemes="['default', 'newest', 'oldest']"
  >
    <new-courses-reel :margin-top="'md'" :hide-link="true" :card-size="'2xs'" />

    <promo-box :location="'coursesIndex'" :promotions="promotions" :margin-top="'lg'" />

    <page-section :margin-top="'xl'">
      <page-section-header :width="'wide'" :heading="'All Courses'" />
    </page-section>
  </content-entry-index-page>
</template>

<script lang="ts">
import ContentEntryIndexPage from '../../content-entry/pages/ContentEntryIndexPage.vue';
import NewCoursesReel from './partials/NewCoursesReel.vue';
import PageSection from '../../core/page/PageSection.vue';
import PageSectionHeader from '../../core/page/PageSectionHeader.vue';
import PromoBox from '../../promotions/components/promo-box/PromoBox.vue';
import {onMounted} from 'vue';
import {usePromotions} from '../../vue-composition/promotion/promotion';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

export default {
  components: {
    PageSection,
    PageSectionHeader,
    ContentEntryIndexPage,
    NewCoursesReel,
    PromoBox
  },
  props: {
    guest: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);
    const loader = useFullScreenLoader();

    onMounted(async () => {
      loader.setLoading(true);
      await promotions.init();
      loader.setLoading(false);
    });

    return {
      promotions
    };
  }
};
</script>
