import * as Joi from 'joi';
import {CraftId, VALIDATION_CRAFT_ID} from '../craft/craft-types';
import {VALIDATION_NON_EMPTY_STRING} from '../validation';

export type Progress = number;

export type EntryProgress = {
  entryId: CraftId;
  progress: number;
};

export type ProgressMap = {[entryId: string]: Progress};

export const PROGRESS_VALIDATION_SCHEMA = Joi.number().required();
export const PROGRESS_ARRAY_VALIDATION_SCHEMA = Joi.array()
  .items(PROGRESS_VALIDATION_SCHEMA)
  .required();
// ??? This doesn't do what I thought! Fix!
export const PROGRESS_MAP_VALIDATION_SCHEMA = Joi.object().pattern(
  VALIDATION_CRAFT_ID,
  VALIDATION_NON_EMPTY_STRING // Craft returns float values from the database as strings.
);
export const ENTRY_PROGRESS_VALIDATION_SCHEMA = Joi.object({
  entryId: VALIDATION_CRAFT_ID,
  progress: PROGRESS_VALIDATION_SCHEMA
});
export const ENTRY_PROGRESS_ARRAY_VALIDATION_SCHEMA = Joi.array().items(
  ENTRY_PROGRESS_VALIDATION_SCHEMA
);
