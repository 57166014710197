import {DurationMap} from '../../../components/content-entry/collections/types';
import {fetchContentEntries} from '../content-entry/content-entry-query';
import {COURSE_FIELDS, getCourseLengthSec} from '../course/course-query';
import {Course} from '../course/course-types';
import {LearningPathway} from './learning-pathways-types';
import * as QueryBuilder from '../content-entry/content-entry-query-builder';
import {CraftId} from '../../craft/craft-types';

/**
 * Return all learning pathways (that are visible to the currently
 * logged in user). NOTE: No child entries are fetched.
 */
export async function getAllLearningPathways(): Promise<Array<LearningPathway>> {
  try {
    const contentEntries = await fetchContentEntries<LearningPathway>(
      QueryBuilder.contentEntries().section(['learningPathways'])
    );
    return contentEntries;
  } catch (error) {
    throw new Error(`Could not fetch learning pathways: ${error}`);
  }
}

/**
 * Return the specified learning pathway, including its child courses and their lessons.
 */
export async function getLearningPathwayBySlug(slug: string): Promise<LearningPathway | undefined> {
  try {
    const pathways = await fetchContentEntries<LearningPathway>(
      QueryBuilder.contentEntries()
        .section(['learningPathways'])
        .slug([slug])
        .fields([QueryBuilder.contentEntries('childEntries').fields(COURSE_FIELDS)])
    );
    const pathway = pathways[0];
    if (pathway !== undefined) {
      const duration: DurationMap = {};
      pathway.childEntries.forEach(course => {
        duration[course.id] = getCourseLengthSec(course as Course);
      });
      return {
        ...pathway,
        duration
      };
    }
    return undefined;
  } catch (error) {
    throw new Error(`Could not fetch learning pathway: ${error}`);
  }
}

/**
 * Return the specified learning pathways, including its child courses and their lessons.
 */
export async function getLearningPathwaysById(
  entryIds: ReadonlyArray<CraftId>
): Promise<ReadonlyArray<LearningPathway>> {
  try {
    const pathways = await fetchContentEntries<LearningPathway>(
      QueryBuilder.contentEntries()
        .section(['learningPathways'])
        .id(entryIds)
        .fields([QueryBuilder.contentEntries('childEntries').fields(COURSE_FIELDS)])
    );
    return pathways;
  } catch (error) {
    throw new Error(`Could not fetch learning pathways: ${error}`);
  }
}
