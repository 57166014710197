<template>
  <div class="flex justify-center">
    <div class="w-full max-w-[600px]">
      <div class="mb-fl-lg">
        <div class="mb-fl-2xs text-primary text-fl-5xl leading-[1.25] font-bold text-center">
          Create Your Account
        </div>
        <div class="text-fl-lg text-[#67707f] leading-[1.4] font-normal text-center">
          Let’s set up your account to unlock your free trial.
        </div>
      </div>
      <div id="sign-up" autocomplete="off">
        <div class="w-full mb-fl-lg">
          <input
            id="account-username"
            ref="usernameRef"
            v-model="username"
            type="text"
            name="userName"
            placeholder="Choose a Username"
            required
            autocomplete="off"
            aria-label="Username"
            class="w-full p-fl-sm text-fl-lg text-black font-normal border border-black rounded-md bg-full-white"
            @keyup.enter="usernameEntered"
          />
          <div v-if="!usernameIsValid" class="text-primary text-sm">
            Please enter a valid username (must not contain whitespace characters)
          </div>
        </div>
        <div class="w-full mb-fl-lg">
          <input
            id="account-email"
            ref="emailRef"
            v-model="email"
            type="email"
            name="email"
            placeholder="Your Email Address"
            required
            autocomplete="off"
            aria-label="Email"
            class="w-full p-fl-sm text-fl-lg text-black font-normal border border-black rounded-md bg-full-white"
            @keyup.enter="emailEntered"
          />
          <div v-if="!emailIsValid" class="text-primary text-sm">
            Please enter a valid email address
          </div>
        </div>
        <div class="w-full mb-fl-lg">
          <input
            id="account-password"
            ref="passwordRef"
            v-model="password"
            type="password"
            name="password"
            placeholder="Password"
            required
            autocomplete="off"
            aria-label="Password"
            class="w-full p-fl-sm text-fl-lg text-black font-normal border border-black rounded-md bg-full-white"
            @keyup.enter="passwordEntered"
          />
          <div class="text-fl-base text-light font-normal">Minimum of six characters</div>
          <div v-if="!passwordIsValid" class="text-primary text-sm">
            Please enter a valid password (must be at least 6 characters)
          </div>
        </div>
        <div class="w-full mb-fl-lg text-fl-base text-black font-normal">
          Already have an account? <a class="text-primary underline" href="/login">Sign In</a>
        </div>
        <div id="credentials-next-button" class="pt-fl-md pb-fl-lg flex justify-center">
          <rainbow-button
            :label="'Next'"
            class="min-w-[clamp(170px,13.02083333vw,250px)]"
            :on-click="submit"
            :disabled="submitDisabled"
          />
        </div>
        <div class="flex justify-center">
          <backend-error :error="error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref, watch} from 'vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {canCreateAccount, signupStep} from '../../../../../backend/signup/checkout-query';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import RainbowButton from '../partials/RainbowButton.vue';
import BackendError from '../partials/BackendError.vue';

export default defineComponent({
  components: {
    RainbowButton,
    BackendError
  },
  props: {
    disabled: {type: Boolean, required: true},
    onComplete: {
      type: Function as PropType<
        (
          email: string,
          username: string,
          password: string,
          recaptchaSore: number | undefined
        ) => void
      >,
      required: true
    }
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const error = useBackendError(true);

    const usernameRef = ref<InstanceType<typeof HTMLInputElement> | null>(null);
    const emailRef = ref<InstanceType<typeof HTMLInputElement> | null>(null);
    const passwordRef = ref<InstanceType<typeof HTMLInputElement> | null>(null);

    //
    // USERNAME
    //

    const username = ref<string>('');

    // Set keyboard focus to the username input field when it appears in the DOM.
    watch(usernameRef, _ => {
      if (usernameRef.value !== null) {
        usernameRef.value.focus();
      }
    });

    const usernameIsValid = computed(() => {
      if (username.value.length === 0) {
        return true;
      }
      const regex = /\s/gm; // Matches whitespace characters.
      const match = username.value.match(regex);
      return username.value.length > 0 && match === null;
    });
    const usernameEntered = () => {
      if (emailRef.value !== null) {
        emailRef.value.focus();
      }
    };

    //
    // EMAIL
    //

    const email = ref<string>('');
    const emailIsValid = computed(() => {
      if (email.value.length === 0) {
        return true;
      }
      /*
        ### We should ensure that this matches the default regexp used by Yii
        (so that we catch format errors as early as possible). See
        https://www.yiiframework.com/doc/api/2.0/yii-validators-emailvalidator
      */
      // https://www.regular-expressions.info/email.html
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return email.value.length > 0 && regex.test(email.value);
    });
    const emailEntered = () => {
      if (passwordRef.value !== null) {
        passwordRef.value.focus();
      }
    };

    //
    // PASSWORD & FORM SUBMIT
    //

    const password = ref<string>('');
    const passwordHelpText =
      'To help keep your account secure, your password must be at least 6 characters long.';
    const passwordIsValid = computed(() => {
      if (password.value.length === 0) {
        return true;
      }
      /*
        ### We should introduce a stronger password policy.
        Craft only enforces passwords to be between 6 and 160 characters.
        https://github.com/craftcms/cms/blob/develop/src/validators/UserPasswordValidator.php

        There is a plugin we might consider using; but it doesn't seem to consider Unicode.
        https://plugins.craftcms.com/password-policy
      */
      return password.value.length >= 6 && password.value.length <= 160;
    });

    const submit = async () => {
      error.clear();

      loader.setLoading(true);
      const canCreate = await canCreateAccount(email.value, username.value).catch(
        (_error: Error) => {
          error.set(_error);
          return false;
        }
      );
      loader.setLoading(false);
      if (!canCreate) {
        if (error.message.value) {
          error.setMessage(error.message.value);
        } else {
          error.setMessage('Username or email already exists.');
        }
        return;
      }

      /*
        ??? TODO: Decide if this should happen before or after we check 
        if account can be created. If after, then change behaviour in old
        cart too!
      */
      loader.setLoading(true);
      await signupStep('academy', email.value, 'profile');
      loader.setLoading(false);

      props.onComplete(email.value, username.value, password.value, undefined);
    };

    const passwordEntered = async () => {
      if (!submitDisabled.value) {
        await submit();
      }
    };

    const submitDisabled = computed(() => {
      return (
        !emailIsValid.value ||
        email.value.length === 0 ||
        !usernameIsValid.value ||
        username.value.length === 0 ||
        !password.value ||
        password.value.length < 6
      );
    });

    return {
      error,
      username,
      usernameRef,
      usernameIsValid,
      usernameEntered,
      emailRef,
      email,
      emailIsValid,
      emailEntered,
      passwordRef,
      password,
      passwordHelpText,
      passwordIsValid,
      passwordEntered,
      submit,
      submitDisabled
    };
  }
});
</script>
