import {ref} from 'vue';
import {LoopManager} from './internals/loop-manager';
import {LoopPlayerConfig, LoopPlayerMode, LoopPlayerOnPlayCallback} from './loop-player-types';
import {PrecountManager} from './internals/precount-manager';
import {getLoopStartAndDuration, getSecondsPerBeat} from './internals/utils';

/**
 * An audio player that is capable of seamlessly looping between two song bars/measures.
 */
export class LoopPlayer {
  //
  // PUBLIC STATE
  //

  /** The current mode of the loop player. */
  public mode = ref<LoopPlayerMode>('reset');
  /** The bar where the playhead is currently. Zero-based. */
  public bar = ref(0);
  /** The beat (inside the current bar) where the playhead is currently. Zero-based. */
  public beat = ref(0);

  //
  // INTERNALS
  //

  private loopManager = new LoopManager();
  private precountManager = new PrecountManager();

  // Modes/state
  private loop: boolean = true;
  private preCount: boolean = true;
  private loopStartBar: number = 0;
  private loopEndBar: number = 0;
  private preCountBars: number = 1;

  // Audio specific state
  private secondsPerBeat: number = 0;
  private secondsPerBar: number = 0;
  private beatsPerBar: number = 0;
  private audioFilePrecountBars: number = 0;

  private onPlayCallback: LoopPlayerOnPlayCallback | undefined = undefined;

  private intervalTimerId: number | undefined = undefined;

  /**
   * Construct a new loop player.
   * @param onPlayCallback Optional function to call whenever playback starts.
   */
  constructor(onPlayCallback?: LoopPlayerOnPlayCallback) {
    this.onPlayCallback = onPlayCallback;
  }

  /**
   * Load a new audio loop.
   * @param config The loop to load, with configuration parameters.
   */
  public async load(config: Readonly<LoopPlayerConfig>) {
    this.mode.value = 'loading';

    this.secondsPerBeat = getSecondsPerBeat(config);
    this.secondsPerBar = this.secondsPerBeat * config.beatsPerBar;
    this.beatsPerBar = config.beatsPerBar;
    this.audioFilePrecountBars = config.audioFilePrecountBars;

    const audioHasLoaded = this.loopManager.audioIsLoaded();

    try {
      await this.loopManager.load(config.sourceId, config.source, () => {
        // When playback reaches the end of the audio file, stop playback if we're not in loop-enabled mode.
        if (!this.loop) {
          this.loopManager.stop();
          this.precountManager.stop();
          this.doReset();
        }
      });
      if (!audioHasLoaded) {
        this.setLoop(0, config.bars);
      } else {
        this.setLoop(this.loopStartBar, this.loopEndBar);
      }
      this.mode.value = 'reset';
    } catch (error) {
      this.mode.value = 'error';
    }
  }

  /**
   * Toggle between play and pause. After pausing, playback resumes at that
   * position in the audio file. If you want to reset back to the beginning
   * of the current loop, call the stop() method.
   */
  public onPlayPause() {
    if (this.mode.value === 'error') {
      return;
    }

    if (this.mode.value === 'paused' || this.mode.value === 'reset') {
      if (this.onPlayCallback !== undefined) {
        this.onPlayCallback();
      }
      if (this.mode.value === 'paused') {
        this.doPlay();
        return;
      }
      if (this.mode.value === 'reset') {
        this.doPreCount();
        return;
      }
    }

    if (this.mode.value === 'playing') {
      this.doPause();
      return;
    }
  }

  /**
   * Reset the playhead back to the beginning of the loop. If audio is
   * playing, this will restart playback (with pre-count, if applicable).
   * To stop playback completely (and reset the playhead back to the start
   * of the loop), call the stop() method.
   */
  public onReset() {
    if (this.mode.value === 'error') {
      return;
    }

    if (this.mode.value === 'playing') {
      if (this.preCount) {
        this.doReset();
        this.doPreCount();
      } else {
        this.doReset();
        this.doPlay();
      }
      return;
    }
    if (this.mode.value === 'paused') {
      this.doReset();
      return;
    }
  }

  /**
   * Stop all all playback return the playhead back to the start of the loop.
   * Call the onPlayPause() method to restart playback (with pre-count, if
   * applicable).
   */
  public onStop() {
    this.doReset();
  }

  /**
   * Set the loop points. The loop will start at startBar, and end just before the
   * start point of endBar. That is, the interval is [startBar, endBar).
   *
   * NOTE: The bar numbers are zero-based!
   *
   * @param startBar The first bar of the loop (zero-based).
   * @param endBar The loop ends just before the start of this bar (zero-based).
   */
  public setLoop(startBar: number, endBar: number) {
    this.onReset();
    this.loopStartBar = startBar;
    this.loopEndBar = endBar;
    const loopDefinition = getLoopStartAndDuration(
      this.secondsPerBar,
      this.audioFilePrecountBars,
      this.loopStartBar,
      this.loopEndBar
    );
    this.loopManager.setLoop(loopDefinition);
  }

  /**
   * Enable or disable looping.
   */
  public setLoopState(status: boolean) {
    this.loop = status;
  }

  /**
   * Return true if looping is enabled; false otherwise.
   */
  public getLoopState() {
    return this.loop;
  }

  /**
   * Enable or disable pre-count.
   */
  public setPreCountState(status: boolean) {
    this.preCount = status;
  }

  /**
   * Set the number of pre-count bars to play.
   */
  public setPreCountBars(bars: number) {
    this.preCountBars = bars;
  }

  //
  // INTERNALS
  //

  private doPlay() {
    this.loopManager.play();
    this.mode.value = 'playing';
    this.enableStateUpdate();
  }

  private doPause() {
    this.loopManager.pause();
    this.mode.value = 'paused';
    this.disableStateUpdate();
  }

  private doReset() {
    this.loopManager.stop();
    this.precountManager.stop();
    this.mode.value = 'reset';
    this.disableStateUpdate();
  }

  private doPreCount() {
    // If pre-count is disabled, move straight on to the play state.
    if (!this.preCount) {
      this.doPlay();
      return;
    }

    this.mode.value = 'pre-count';
    this.enableStateUpdate();
    this.precountManager.play(this.preCountBars, this.beatsPerBar, this.secondsPerBeat, () => {
      this.doPlay();
    });
  }

  private disableStateUpdate() {
    if (this.intervalTimerId !== undefined) {
      window.clearInterval(this.intervalTimerId);
    }
    this.intervalTimerId = undefined;
  }

  private enableStateUpdate() {
    this.intervalTimerId = window.setInterval(() => {
      if (this.loopManager === undefined) {
        throw new Error('No loop manager');
      }

      if (!this.loopManager.audioIsLoaded()) {
        this.mode.value = 'loading';
        this.bar.value = 0;
        this.beat.value = 0;
      }

      if (this.mode.value === 'reset') {
        this.bar.value = this.preCount ? -this.preCountBars : this.loopStartBar;
        this.beat.value = 0;
      }

      if (this.mode.value === 'paused' || this.mode.value === 'playing') {
        const timeSec = this.loopManager.getPlayheadPos();
        if (timeSec !== undefined) {
          this.beat.value = Math.floor(timeSec / this.secondsPerBeat) % this.beatsPerBar;
          this.bar.value = Math.floor(timeSec / this.secondsPerBar) - this.audioFilePrecountBars;
        } else {
          // No audio is playing
          this.bar.value = this.preCount ? -this.preCountBars : this.loopStartBar;
          this.beat.value = 0;
        }
      }

      if (this.mode.value === 'pre-count') {
        this.bar.value = this.precountManager.currentPreCountBar;
        this.beat.value = this.precountManager.currentPreCountBeat;
      }
    }, 50);
  }
}
