/**
 * Create a variation of the specified function that only "activates" at most
 * once per the specified time interval.
 * NOTE: The last call to the throttled function is not guaranteed to succeed.
 * For alternatives, see
 * https://stackoverflow.com/questions/27078285/simple-throttle-in-javascript
 *
 * @param func Function to throttle.
 * @param interval Minimum allowed time interval between calls to func.
 *
 * @returns Throttled variation of the input function.
 */
export function throttle<Params extends any[]>(func: (...args: Params) => any, interval: number) {
  let last = Date.now();
  return (...args: Params) => {
    const now = Date.now();
    if (now - last > interval) {
      func(...args);
      last = now;
    }
  };
}
