<template>
  <div id="countdown">{{ countdown.display.value }}</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {useCountdown} from '../../vue-composition/countdown/countdown';

export default defineComponent({
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    redirectOnLive: {type: Boolean, default: false}
  },
  setup(props) {
    const countdown = useCountdown(props.contentEntry, props.redirectOnLive);
    return {
      countdown
    };
  }
});
</script>
