<template>
  <content-column v-if="text !== undefined && url !== undefined && ctaText !== undefined">
    <div
      class="flex justify-center items-center mt-fl-md-lg p-3 border border-solid border-yellow-400 rounded-lg"
    >
      <div class="flex justify-center items-center text-xs text-center font-semibold text-white">
        <p>
          {{ text }}
          <a :href="url" :target="target" class="text-yellow-400 underline">{{ ctaText }}</a>
        </p>
      </div>
    </div>
  </content-column>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import {usePromotions} from '../../../vue-composition/promotion/promotion';
import ContentColumn from '../../compositions/ContentColumn.vue';
import {Promotion, PromotionCopyHeading} from '../../../../backend/promotion/promotion-types';

export default defineComponent({
  components: {
    ContentColumn
  },
  props: {
    guest: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true},
    segment: {type: String, default: undefined}
  },
  setup(props) {
    const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);
    const promo = ref<Readonly<Promotion> | undefined>(undefined);

    onMounted(async () => {
      await promotions.init();
      promo.value = promotions.get('headerBanner');
    });

    const showBanner = computed(() => {
      if (promo.value === undefined) {
        return false;
      }

      /* 
        If logged in only display on the dashboard. For guests, allow display on
        all pages, except for pages with a media player or the login page.
      */
      if (props.guest) {
        switch (props.segment) {
          case 'lessons':
          case 'players-path-pieces':
          case 'login':
            return false;
          default:
            return true;
        }
      } else {
        return props.segment === 'dashboard' ? true : false;
      }
    });

    const cta = computed(() => {
      if (promo.value === undefined || showBanner.value === false) {
        return undefined;
      }
      return promo.value.promotionCallToAction[0];
    });

    const url = computed(() => {
      if (cta.value !== undefined && showBanner.value) {
        return cta.value.promotionCtaUrl;
      }
      return undefined;
    });
    const ctaText = computed(() => {
      if (cta.value !== undefined && showBanner.value) {
        return cta.value.promotionCtaText;
      }
      return undefined;
    });

    const text = computed(() => {
      if (promo.value !== undefined && showBanner.value) {
        const firstHeading = promo.value.promotionCopy.find(
          block => block.typeHandle === 'promotionCopyHeading'
        );
        if (firstHeading === undefined) {
          return undefined;
        }
        return (firstHeading as Readonly<PromotionCopyHeading>).promotionCopyHeadingText;
      }
      return undefined;
    });

    const target = computed(() => {
      if (cta.value === undefined) {
        return undefined;
      }
      if (cta.value.promotionCtaOpenInNewTab) {
        return '_blank';
      }
      return undefined;
    });

    return {
      url,
      text,
      ctaText,
      target
    };
  }
});
</script>
