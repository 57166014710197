<template>
  <content-entry-rich-text
    v-if="course.richText.length > 0"
    :guest="guest"
    :heading="'Summary'"
    :rich-text="course.richText"
    :body-text-size="'text-fl-xl'"
    :list-text-size="'text-fl-xl'"
    :list-icon="listIcon"
    :width="'text'"
  />
  <content-entry-summary
    v-else-if="course.summary !== null"
    :content-entry="course"
    class="text-fl-xl"
  />
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import ContentEntrySummary from '../../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryRichText from '../../../content-entry/partials/body/ContentEntryRichText.vue';
import {Course} from '../../../../backend/content/course/course-types';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';

export default defineComponent({
  components: {
    ContentEntrySummary,
    ContentEntryRichText
  },
  props: {
    guest: {type: String, required: true},
    course: {type: Object as PropType<Readonly<Course>>, required: true}
  },
  setup() {
    const listIcon = computed(() => faCheck);
    return {
      listIcon
    };
  }
});
</script>
