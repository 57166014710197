<template>
  <div v-for="(block, index) in promotionCopy" :key="index">
    <promotion-copy-heading
      v-if="block.typeHandle === 'promotionCopyHeading'"
      :promotion-copy-heading="block"
      :width="width"
      :justify="justify"
      :margin-top="index === 0 ? 'none' : 'sm'"
    />
    <promotion-copy-body
      v-if="block.typeHandle === 'promotionCopyBody'"
      :promotion-copy-body="block"
      :body-text-size="bodyTextSize"
      :width="width"
      :justify="justify"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../core/column-layout/utils';
import {PromotionCopyBlock} from '../../../backend/promotion/promotion-types';
import PromotionCopyBody from './blocks/PromotionCopyBody.vue';
import PromotionCopyHeading from './blocks/PromotionCopyHeading.vue';

export default defineComponent({
  components: {
    PromotionCopyHeading,
    PromotionCopyBody
  },
  props: {
    promotionCopy: {type: Array as PropType<ReadonlyArray<PromotionCopyBlock>>, required: true},
    bodyTextSize: {type: String, default: 'text-fl-base'},
    listTextSize: {type: String, default: 'text-fl-base'},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  }
});
</script>
