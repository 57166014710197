<template>
  <content-column :width="width">
    <cluster
      :justify="justify"
      :items="items"
      :gap="'gap-x-fl-sm-xl gap-y-fl-sm'"
      :class="radioButtonPadding"
    >
      <div v-for="button in buttonList" :key="button.id">
        <link-icon-button
          v-if="button.icon !== undefined"
          :id="button.id"
          :size="buttonSize"
          :label="button.label"
          :icon="button.icon"
          :color="button.color"
          :border="true"
          :on-click="onButtonClicked"
        />
        <link-button
          v-else
          :id="button.id"
          :size="buttonSize"
          :label="button.label"
          :color="button.color"
          :border="true"
          :on-click="onButtonClicked"
        />
      </div>
      <slot />
    </cluster>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from 'vue';
import {ButtonColor, ButtonSize} from '../../core/button/implementation/utils';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import LinkIconButton from '../../core/button/LinkIconButton.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {RadioButton, RadioButtonCallback} from './radio-button-types';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';

export default defineComponent({
  components: {
    ContentColumn,
    Cluster,
    LinkIconButton,
    LinkButton
  },
  props: {
    buttons: {type: Array as PropType<ReadonlyArray<RadioButton>>, required: true},
    buttonSize: {type: String as PropType<ButtonSize>, default: undefined},
    justify: {type: String, default: 'justify-start'},
    radioButtonPadding: {type: String, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'},
    items: {type: String, default: 'items-start'},
    onSelect: {type: Function as PropType<RadioButtonCallback>, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const currentButton = ref(props.buttons.length > 0 ? props.buttons[0].id : undefined);
    const selectButton = (id?: number | string) => {
      if (id !== undefined) {
        currentButton.value = id as number;
        props.onSelect(id as number);
      }
    };
    const onButtonClicked = (id?: number | string) => {
      selectButton(id);
    };

    const buttonList = computed(() => {
      return props.buttons.map(button => {
        let icon = undefined;
        if (button.id === currentButton.value) {
          if (button.iconSelected !== undefined) {
            icon = button.iconSelected;
          }
        } else {
          if (button.icon !== undefined) {
            icon = button.icon;
          }
        }
        const color: ButtonColor = button.id === currentButton.value ? 'white' : 'mid';
        return {
          id: button.id,
          icon,
          label: button.label,
          color
        };
      });
    });
    return {
      deviceType,
      onButtonClicked,
      selectButton,
      buttonList
    };
  }
});
</script>
