<template>
  <div v-if="visible" class="overflow-hidden w-full">
    <div ref="slot" class="w-full" :style="style">
      <slot name="visible"></slot>
    </div>
  </div>
  <slot v-else name="invisible"></slot>
</template>

<script lang="ts">
import {defineComponent, computed, watch, ref} from 'vue';
import {useAnimatedToggle} from '../../vue-composition/animation/linear';
export default defineComponent({
  props: {
    status: {type: Boolean, required: true},
    init: {type: Boolean, required: true},
    speed: {type: Number, default: 0.25}
  },
  setup(props) {
    const visible = ref(props.init);
    const toggle = useAnimatedToggle(props.speed, props.init, 60);
    const slot = ref<any>(null);

    watch(
      () => props.status,
      status => {
        if (status) {
          visible.value = true;
        }
        toggle.set(status, () => {
          visible.value = status;
        });
      }
    );

    const height = ref<number>(0);

    watch(
      () => slot.value,
      value => {
        if (value !== null) {
          height.value = value.clientHeight;
        } else {
          height.value = 0;
        }
      }
    );

    const style = computed(() => {
      const h = -(1.0 - toggle.easeInOut.value) * height.value;
      return `margin-top:${h}px`;
    });

    return {
      slot,
      style,
      visible
    };
  }
});
</script>
