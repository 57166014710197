<template>
  <card :url="contentEntry.url">
    <template #hero>
      <card-hero-image
        v-if="coverImage !== undefined"
        :config="coverImage"
        :greyscale="greyscale"
        :ribbon-icon="ribbonIcon"
      />
      <card-hero-text v-else />
    </template>

    <template #center>
      <card-progress-bar :progress="progress" :url="url" />
    </template>

    <template #body>
      <div class="grow group flex gap-fl-md">
        <div class="text-fl-6xl text-lightest font-semibold group-hover:text-white">
          {{ number }}
        </div>
        <standard-divider :orientation="'vertical'" class="border-dark" />
        <div class="flex flex-col gap-fl-3xs">
          <card-body-title :title="contentEntry.title" />
          <card-body-duration :duration="duration" class="italic" />
        </div>
      </div>
    </template>
    <template #buttons>
      <div class="flex flex-col pr-fl-xs">
        <card-body-bookmark :guest="guest" :content-entry="contentEntry" :bookmark="bookmark" />
      </div>
    </template>
  </card>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import CardHeroText from '../../../generic/card/partials/hero/CardHeroText.vue';
import CardProgressBar from '../../../generic/card/partials/CardProgressBar.vue';
import CardBodyTitle from '../../../generic/card/partials/body/CardBodyTitle.vue';
import CardBodyDuration from '../../../generic/card/partials/body/CardBodyDuration.vue';
import CardBodyBookmark from '../../../generic/card/partials/body/CardBodyBookmark.vue';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {
  getImageConfig,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    StandardDivider,
    Card,
    CardProgressBar,
    CardHeroText,
    CardHeroImage,
    CardBodyTitle,
    CardBodyDuration,
    CardBodyBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    courseNumber: {type: Number, required: true},
    duration: {type: Number, default: undefined}, // Seconds
    completed: {type: Boolean, default: undefined},
    progress: {type: Number, default: undefined}, // [0,100]
    unlocked: {type: Boolean, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    imageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    width: {type: String, default: 'w-full'}
  },
  setup(props) {
    const completed = computed(() => {
      if (props.completed !== undefined) {
        return props.completed;
      }
      if (props.progress !== undefined) {
        return props.progress === 100;
      }
      return undefined;
    });

    const greyscale = computed(() => {
      return completed.value;
    });

    const coverImage = computed(() => {
      return getImageConfig(
        props.contentEntry.imageCollection,
        ['cover'],
        props.contentEntry.title,
        props.imageWidth
      );
    });

    const tutors = computed(() => {
      return tutorList(props.contentEntry);
    });

    const url = computed(() => {
      return props.contentEntry.url;
    });

    const ribbonIcon = computed(() => {
      if (props.guest) {
        return undefined;
      }
      if (completed.value !== undefined && completed.value === true) {
        return faCheck;
      }
      if (props.unlocked !== undefined && !props.unlocked) {
        return faLock;
      }
      return undefined;
    });

    const number = computed(() => `${props.courseNumber}`.padStart(2, '0'));

    return {
      ribbonIcon,
      greyscale,
      coverImage,
      url,
      tutors,
      number
    };
  }
});
</script>
