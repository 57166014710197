<template>
  <page-content>
    <page-section v-if="!guest && !preview" :margin-top="'xl'">
      <content-column :width="'narrow'">
        <div v-if="quizStatus === 'completed'">
          <flow>
            <p>You have already completed this quiz!</p>
            <standard-button v-if="continueUrl" :url="continueUrl" :label="'Continue'" />
          </flow>
        </div>
        <div v-if="quizStatus === 'prerequisites_incomplete'">
          <flow>
            <p>You haven't completed the prerequisites for this quiz.</p>
            <standard-button v-if="continueUrl" :url="continueUrl" :label="'Continue'" />
          </flow>
        </div>
        <div v-if="quizStatus === 'quiz'">
          <quiz-question-page v-if="quiz !== undefined" :quiz="quiz" :on-completed="onCompleted" />
        </div>
        <div v-if="quizStatus === 'success' || quizStatus === 'failure'">
          <quiz-result-page
            v-if="quiz !== undefined && numberOfCorrectAnswers !== undefined"
            :quiz="quiz"
            :status="quizStatus"
            :number-of-correct-answers="numberOfCorrectAnswers"
          />
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {ref, onMounted, computed} from 'vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {Quiz, QuizOutcomeType} from '../../../backend/content/quiz/quiz-types';
import {
  getQuizBySlug,
  isQuizComplete,
  setQuizComplete
} from '../../../backend/content/quiz/quiz-query';
import QuizQuestionPage from './quiz-question-page/QuizQuestionPage.vue';
import QuizResultPage from './quiz-result-page/QuizResultPage.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import Flow from '../../core/compositions/Flow.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {prerequisitesCompleted, getSuccessOutcome, getFailureOutcome} from './utils';
import {useInstrumentation} from '../../vue-composition/instrumentation/instrumentation';

type QuizStatus = 'completed' | 'prerequisites_incomplete' | 'quiz' | QuizOutcomeType;

export default {
  components: {
    ContentColumn,
    PageContent,
    PageSection,
    Flow,
    StandardButton,
    QuizQuestionPage,
    QuizResultPage
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String, required: true}
  },
  setup(props) {
    const quiz = ref(undefined as Quiz | undefined);
    const loader = useFullScreenLoader();
    const quizStatus = ref('quiz' as QuizStatus);
    const continueUrl = ref(undefined as CraftUrl | undefined);
    const numberOfCorrectAnswers = ref(undefined as number | undefined);
    const instrumentation = useInstrumentation();

    const preview = computed(() => {
      if (quiz.value === undefined) {
        return true;
      }
      return quiz.value.preview;
    });

    const onCompleted = async (success: boolean, correct: number) => {
      if (quiz.value !== undefined) {
        loader.setLoading(true, 'Updating your quiz results');
        if (success) {
          // Update user's quiz status in the database.
          await setQuizComplete(quiz.value);
          // Send event to instrumentation system.
          await instrumentation.sendEvent({
            type: 'quiz',
            properties: {
              quizId: quiz.value.id,
              quizSlug: quiz.value.slug,
              success: true
            }
          });
        }
        quizStatus.value = success ? 'success' : 'failure';
        numberOfCorrectAnswers.value = correct;
        loader.setLoading(false);
      }
    };

    onMounted(async () => {
      // Quizzes can only be accessed by members.
      if (props.guest) {
        return;
      }

      loader.setLoading(true);
      quiz.value = await getQuizBySlug(props.slug);
      if (quiz.value !== undefined) {
        const completed = await isQuizComplete(quiz.value);
        if (completed) {
          quizStatus.value = 'completed';
          const outcome = getSuccessOutcome(quiz.value);
          if (outcome !== undefined) {
            continueUrl.value = outcome.quizOutcomeRedirect[0].url;
          }
        }
        const prereq = await prerequisitesCompleted(quiz.value);
        if (!prereq) {
          quizStatus.value = 'prerequisites_incomplete';
          const outcome = getFailureOutcome(quiz.value);
          if (outcome !== undefined) {
            continueUrl.value = outcome.quizOutcomeRedirect[0].url;
          }
        }
      }
      loader.setLoading(false);
    });
    return {
      preview,
      quiz,
      onCompleted,
      numberOfCorrectAnswers,
      quizStatus,
      continueUrl
    };
  }
};
</script>
