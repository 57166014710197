<template>
  <page-content>
    <!-- About page header -->
    <div class="max-w-[1640px] relative mx-auto">
      <div
        :class="`relative ${fittedTextPadding} w-full sm:w-1/2 md:w-4/12 py-16 px-fl-md-xl 2xl:px-0 z-10`"
      >
        <fitted-text
          :text-class="'fill-white stroke-0 font-semibold'"
          :text-spans="['MUSIC', 'IS LIFE', 'BASS', 'IS THE', 'HEART.']"
          text-span-dy="0.9em"
          :text-x="0"
          :text-y="0"
          :viewbox-x="0"
          :viewbox-y="2"
          :viewbox-width="57.5"
          :viewbox-height="71"
        />
      </div>
      <img
        class="invisible md:visible left-[40%] top-[20%] aspect-square w-[15%] absolute z-20"
        :src="'https://scottsbasslessons.imgix.net/content/SBL_Tag_Badge_OR.png'"
      />
      <img
        src="https://scottsbasslessons.imgix.net/header-backgrounds/052-c0415-sbl_grey_wide.jpg?auto=format"
        alt=""
        class="absolute z-0 md:opacity-100 contrast-125 opacity-50 right-0 bottom-0 w-full h-full object-cover"
      />
    </div>

    <!-- SBL team -->
    <variants>
      <template #sm->
        <sbl-team-narrow />
      </template>
      <template #md+>
        <sbl-team-large />
      </template>
    </variants>

    <!-- Values, vision & mission, roots -->
    <variants>
      <template #sm->
        <values-narrow />
      </template>
      <template #md+>
        <values-large />
      </template>
    </variants>

    <!-- SBL timeline-->
    <variants>
      <template #sm->
        <timeline-narrow />
      </template>
      <template #md+>
        <timeline-large />
      </template>
    </variants>

    <!-- Student feedback -->
    <variants>
      <template #md->
        <student-feedback-medium />
      </template>
      <template #lg+>
        <student-feedback-large />
      </template>
    </variants>

    <!-- Join our team -->
    <page-section :margin-top="'lg'">
      <content-column :width="'narrow'">
        <standard-heading :size="2" :center="true">Join our team</standard-heading>
        <div class="flex justify-center">
          <standard-button
            :label="'Join Our Team'"
            :url="'/careers'"
            :color="'primary'"
            class="mt-8"
          />
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import PageContent from '../../core/page/PageContent.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import FittedText from '../../core/fitted-text/FittedText.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import Variants from '../../core/column-layout/Variants.vue';
import SblTeamNarrow from './variations/SblTeamNarrow.vue';
import SblTeamLarge from './variations/SblTeamLarge.vue';
import StudentFeedbackMedium from './variations/StudentFeedbackMedium.vue';
import StudentFeedbackLarge from './variations/StudentFeedbackLarge.vue';
import TimelineNarrow from './variations/TimelineNarrow.vue';
import TimelineLarge from './variations/TimelineLarge.vue';
import ValuesNarrow from './variations/ValuesNarrow.vue';
import ValuesLarge from './variations/ValuesLarge.vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    StandardHeading,
    PageSection,
    PageContent,
    ContentColumn,
    FittedText,
    StandardButton,
    Variants,
    SblTeamNarrow,
    SblTeamLarge,
    StudentFeedbackMedium,
    StudentFeedbackLarge,
    TimelineNarrow,
    TimelineLarge,
    ValuesNarrow,
    ValuesLarge
  },
  props: {},
  setup() {
    const deviceType = useDeviceType();

    const fittedTextPadding = computed(() => (deviceType.largerThan('2xl') ? 'px-0' : 'px-fl-lg'));

    const spotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-white',
      borderHover: 'hover:border-white'
    };

    const buttonSpotColor = {
      bg: 'bg-primary',
      bgHover: 'bg-primary',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-primary',
      borderHover: 'hover:border-primary'
    };

    return {
      spotColor,
      buttonSpotColor,
      deviceType,
      fittedTextPadding
    };
  }
});
</script>
