<template>
  <!-- Student feedback -->
  <page-section
    :padding-top="'lg'"
    :padding-bottom="'xl'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="bg-white text-darkest"
  >
    <margins>
      <content-area :start="3" :span="8" :width="'narrow'">
        <standard-heading
          class="mb-fl-lg"
          :size="4"
          :center="true"
          :spot-color="spotColor"
          :class="'uppercase'"
        >
          {{ feedbackHeading1 }}
        </standard-heading>
        <standard-heading :size="1" :center="true" :spot-color="spotColor">
          {{ feedbackHeading2 }}
        </standard-heading>
      </content-area>
      <content-area :start="3" :span="8">
        <img
          src="https://scottsbasslessons.imgix.net/content/students-banner.jpg?auto=format"
          alt=""
          aria-hidden="true"
          class="mt-fl-lg"
        />
      </content-area>
      <content-area class="mt-fl-lg text-center text-fl-3xl" :start="4" :span="6">
        <p>
          {{ feedbackSummary }}
        </p>
      </content-area>
      <content-area class="mt-fl-lg" :start="3" :span="1">
        <fitted-text
          :text-class="'fill-black stroke-0 font-semibold'"
          :text-spans="['&#8220;']"
          text-span-dy="1.0em"
          :text-x="0"
          :text-y="0"
          :viewbox-x="0"
          :viewbox-y="3"
          :viewbox-width="7"
          :viewbox-height="8"
        />
      </content-area>
      <student-quotes />
    </margins>
  </page-section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StudentQuotes from '../partials/StudentQuotes.vue';
import FittedText from '../../../core/fitted-text/FittedText.vue';
import {feedbackHeading1, feedbackHeading2, feedbackSummary} from '../utils/utils';

export default defineComponent({
  components: {
    PageSection,
    Margins,
    ContentArea,
    StandardHeading,
    StudentQuotes,
    FittedText
  },
  setup() {
    const spotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-white',
      borderHover: 'hover:border-white'
    };

    return {spotColor, feedbackHeading1, feedbackHeading2, feedbackSummary};
  }
});
</script>
