<template>
  <div
    v-if="complete"
    :class="`w-fl-2xl h-fl-2xl flex justify-center items-center border-4 ${color.border} ${color.bg} rounded-full`"
  >
    <font-awesome-icon :icon="iconCheck" class="text-fl-5xl text-black"></font-awesome-icon>
  </div>
  <div
    v-else
    :class="`w-fl-2xl h-fl-2xl flex justify-center items-center border-4 ${color.border} rounded-full`"
  >
    <div :class="`text-fl-5xl ${color.text} font-semibold text-center`">{{ number + 1 }}</div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../../../../core/color-config';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {type: Boolean, required: true},
    number: {type: Number, required: true},
    complete: {type: Boolean, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true}
  },
  setup(props) {
    const iconCheck = computed(() => faCheck);
    const color = computed(() => {
      return props.disabled
        ? {
            border: 'border-mid',
            bg: 'bg-mid',
            text: 'text-mid'
          }
        : {
            border: props.spotColor.border,
            bg: props.spotColor.bg,
            text: props.spotColor.text
          };
    });
    return {
      iconCheck,
      color
    };
  }
});
</script>
