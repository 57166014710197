<template>
  <!-- Players path level group -->
  <variants>
    <template #xl+>
      <margins :row-gap="'gap-y-fl-xl-4xl'">
        <content-area :span="3">
          <standard-heading :size="3">{{ group.title }}</standard-heading>
        </content-area>
        <content-area :start="4" :span="2">
          <players-path-level-card :level="group.levels[0]" />
        </content-area>
        <content-area :start="7" :span="2">
          <players-path-level-card :level="group.levels[1]" />
        </content-area>
        <content-area :start="10" :span="2">
          <players-path-level-card :level="group.levels[2]" />
        </content-area>
      </margins>
    </template>
    <template #lg>
      <margins :row-gap="'gap-y-fl-xl-4xl'">
        <content-area>
          <centered-column>
            <standard-heading :size="3">{{ group.title }}</standard-heading>
          </centered-column>
        </content-area>
        <content-area :start="1" :span="3">
          <players-path-level-card :level="group.levels[0]" />
        </content-area>
        <content-area :start="5" :span="3">
          <players-path-level-card :level="group.levels[1]" />
        </content-area>
        <content-area :start="9" :span="3">
          <players-path-level-card :level="group.levels[2]" />
        </content-area>
      </margins>
    </template>
    <template #md>
      <margins :row-gap="'gap-y-fl-xl-4xl'">
        <content-area>
          <centered-column>
            <standard-heading :size="3">{{ group.title }}</standard-heading>
          </centered-column>
        </content-area>
        <content-area :start="1" :span="3">
          <players-path-level-card :level="group.levels[0]" />
        </content-area>
        <content-area :start="5" :span="3">
          <players-path-level-card :level="group.levels[1]" />
        </content-area>
        <content-area :start="9" :span="3">
          <players-path-level-card :level="group.levels[2]" />
        </content-area>
      </margins>
    </template>
    <template #sm->
      <margins :row-gap="'gap-y-fl-xl-4xl'">
        <content-area>
          <centered-column>
            <standard-heading :size="3">{{ group.title }}</standard-heading>
          </centered-column>
        </content-area>
        <content-area>
          <div class="flex justify-around gap-fl-md-2xl">
            <players-path-level-card :level="group.levels[0]" />
            <players-path-level-card :level="group.levels[1]" />
            <players-path-level-card :level="group.levels[2]" />
          </div>
        </content-area>
      </margins>
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {PlayersPathLevelGroup} from '../utils';
import Variants from '../../../core/column-layout/Variants.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import PlayersPathLevelCard from './PlayersPathLevelCard.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    Margins,
    Variants,
    ContentArea,
    StandardHeading,
    CenteredColumn,
    PlayersPathLevelCard
  },
  props: {
    group: {type: Object as PropType<Readonly<PlayersPathLevelGroup>>, required: true}
  },
  setup() {
    const deviceType = useDeviceType();
    return {
      deviceType
    };
  }
});
</script>
