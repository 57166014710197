<template>
  <div :id="offer.slug" class="py-fl-lg-2xl flex flex-col gap-fl-lg">
    <standard-heading :size="2">{{ offer.offerTitle }}</standard-heading>
    <div v-if="offer.summary" class="text-fl-2xl">{{ offer.summary }}</div>
    <standard-button :on-click="onResubscribe" :label="label" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../../../core/button/StandardButton.vue';
import {redirectWithHistory} from '../../../../../../utils/url';
import {ResubscribeOffer} from '../../../../../../backend/offer/resubscribe-offer-types';

export default defineComponent({
  components: {
    StandardHeading,
    StandardButton
  },
  props: {
    offer: {type: Object as PropType<Readonly<ResubscribeOffer>>, required: true}
  },
  setup(props) {
    const onResubscribe = () => {
      redirectWithHistory(`/account/resubscribe?offer=${props.offer.id}`);
    };
    const label = computed(() => {
      if (
        props.offer.offerSelectionLabel !== undefined &&
        props.offer.offerSelectionLabel !== null
      ) {
        return props.offer.offerSelectionLabel;
      }
      return 'Resubscribe';
    });
    return {
      onResubscribe,
      label
    };
  }
});
</script>
