<template>
  <page-section v-if="heading" :margin-top="marginTop">
    <page-section-header :width="'wide'" :heading="heading">
      <slot />
    </page-section-header>
  </page-section>
  <page-section :margin-top="'sm'">
    <content-entry-reel
      :width="'wide'"
      :guest="guest"
      :content-entries="contentEntries"
      :bookmark="bookmark"
    />
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentEntryReel from '../../../content-entry/collections/variants/ContentEntryReel.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentEntryReel
  },
  props: {
    guest: {type: String, required: true},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'md'},
    heading: {type: String, default: undefined},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<BookmarkComposition>, required: true}
  },
  setup() {}
});
</script>
