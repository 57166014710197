<template>
  <div>
    <page-section :margin-top="marginTop">
      <groove-trainer-card :width="width" />
    </page-section>

    <page-section v-if="!guest" :margin-top="'xs'">
      <feature-card-pair :width="width">
        <template #left>
          <campus-card />
        </template>
        <template #right>
          <new-member-huddle-card />
        </template>
      </feature-card-pair>
    </page-section>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import FeatureCardPair from '../../../generic/feature-card/FeatureCardPair.vue';
import GrooveTrainerCard from './GrooveTrainerCard.vue';
import CampusCard from './CampusCard.vue';
import NewMemberHuddleCard from './NewMemberHuddleCard.vue';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    FeatureCardPair,
    GrooveTrainerCard,
    CampusCard,
    NewMemberHuddleCard
  },
  props: {
    guest: {type: String, required: true},
    marginTop: {type: String as PropType<PageSectionMargin>, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'}
  },
  setup() {}
});
</script>
