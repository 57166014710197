import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {SectionPromoConfig} from '../partials/types';
import {fetchContentEntries} from '../../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../../backend/content/content-entry/content-entry-query-builder';

/**
 * Return a list of the N most "popular" courses. This *actually* returns
 * the N first courses, as ordered in the Craft backend.
 */
export async function getPopularCourses(n: number): Promise<ReadonlyArray<ContentEntry>> {
  try {
    // ### Replace with fetchContentEntriesUntil(); see MentorSeminarsMain.vue
    const result = await fetchContentEntries(contentEntries().section(['courses']).limit(n));
    return result;
  } catch (error) {
    throw new Error(`Could not fetch popular courses: ${error}`);
  }
}

export function getSectionPromos(): ReadonlyArray<SectionPromoConfig> {
  const cards: ReadonlyArray<SectionPromoConfig> = [
    {
      title: 'Courses',
      titleImage: 'https://scottsbasslessons.imgix.net/content/courses-logo.svg',
      summary:
        'Dive into our huge library of extensive courses for new and experienced bass players.',
      url: '/courses',
      newTab: false,
      buttonLabel: 'Check it Out',
      membersOnly: false
    },
    {
      title: 'Learning Pathways',
      titleImage: 'https://scottsbasslessons.imgix.net/content/learning-pathways.svg',
      summary:
        "Learning Pathways offer sequential deep dives into a particular genre or skill. If you're a beginner, the Beginner Pathway is a great place to start.",
      url: '/learning-pathways',
      newTab: false,
      buttonLabel: 'Check it Out',
      membersOnly: false
    },
    {
      title: 'Players Path',
      titleImage: 'https://scottsbasslessons.imgix.net/content/players-path-logo.svg',
      summary:
        'Players Path is a performance-based grading system that will help you propel your playing from the level you are today, to the level you always wanted to be.',
      url: '/players-path',
      newTab: false,
      buttonLabel: 'Check it Out',
      membersOnly: false
    },
    {
      title: 'Mentors',
      titleImage: 'https://scottsbasslessons.imgix.net/content/mentors-logo.svg',
      summary:
        "Get expert guidance from some of the world's greatest bass players, with our weekly live Mentor seminars.",
      url: '/mentors',
      newTab: false,
      buttonLabel: 'Check it Out',
      bgColor: 'bg-almost-black',
      textColor: 'text-mid',
      borderColor: 'border-dark',
      membersOnly: false
    },
    {
      title: 'Student Focus',
      titleImage: 'https://scottsbasslessons.imgix.net/content/student-focus-logo.svg',
      summary:
        'Get help, direction, or feedback on your playing! Twice a month, we do a heap of student video reviews - these are for everyone, and cover all skill levels and abilities.',
      url: 'https://campus.scottsbasslessons.com/topic/12349-submit-a-video-for-the-student-review-here/',
      newTab: true,
      buttonLabel: 'Check it Out',
      bgColor: 'bg-almost-black',
      textColor: 'text-mid',
      borderColor: 'border-dark',
      membersOnly: true
    },
    {
      title: 'Community Campus',
      titleImage: 'https://scottsbasslessons.imgix.net/content/campus-logo.svg',
      summary:
        "The Community Campus provides inspiration, support, and accountability to your Bass studies. It's also a great place to show off your bass gear!",
      url: 'https://campus.scottsbasslessons.com/',
      newTab: true,
      buttonLabel: 'Check it Out',
      bgColor: 'bg-almost-black',
      textColor: 'text-mid',
      borderColor: 'border-dark',
      membersOnly: true
    }
  ];
  return cards;
}
