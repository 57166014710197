<template>
  <!-- Media browser -->
  <div class="flex justify-center">
    <div :class="`w-full max-w-[1920px] grid overflow-hidden bg-[rgb(20,20,20)]`" :style="style">
      <video-player
        :guest="guest"
        :preview="contentEntry.preview"
        :video="video"
        :on-play="playCallback"
        :on-end="endCallback"
        :on-progress="onProgress"
        :resume-at-last-timestamp="resumeAtLastTimestamp"
        :override-playback-pos="overridePlaybackPos"
      ></video-player>

      <div
        v-if="deviceType.largerThan('lg')"
        class="w-full flex flex-col justify-between flex-nowrap bg-darkest"
      >
        <media-browser-sidebar
          v-if="items !== undefined"
          :items="items"
          :title="sidebarTitle"
          :sidebar-icon-type="sidebarIconType"
          :on-item-selected="onItemSelected"
        />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {MediaBrowserItem, MediaBrowserItemSelectedCallback} from './media-browser-types';
import VideoPlayer, {
  EndCallback,
  PlayCallback,
  ProgressCallback
} from '../video-player/VideoPlayer.vue';
import {Video} from '../../../backend/video/video-types';
import {addOrUpdateHistory} from '../../../backend/history/history-query';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import MediaBrowserSidebar from './sidebar/MediaBrowserSidebar.vue';

export default defineComponent({
  components: {
    MediaBrowserSidebar,
    VideoPlayer
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    video: {type: Object as PropType<Video>, required: true},
    overridePlaybackPos: {type: Number, default: undefined},
    onPlay: {type: Function as PropType<PlayCallback>, default: undefined},
    onEnd: {type: Function as PropType<EndCallback>, default: undefined},
    onProgress: {type: Function as PropType<ProgressCallback>, default: undefined},
    resumeAtLastTimestamp: {type: Boolean, default: true},
    items: {type: Array as PropType<ReadonlyArray<MediaBrowserItem>>, default: undefined},
    onItemSelected: {
      type: Function as PropType<MediaBrowserItemSelectedCallback>,
      default: undefined
    },
    sidebarTitle: {type: String, default: undefined},
    sidebarIconType: {
      type: String as PropType<'play' | 'check'>,
      default: 'check'
    }
  },
  setup(props) {
    const deviceType = useDeviceType();

    /*
      Compute the width of the sidebar and the height of the media browser.
      The logic and settings here are based on the original XD design document
      for SBL, where the media browser is shown in a viewport that is 1920
      pixels wide. The sidebar, in that configuration, is 550 pixels wide,
      which gives us an aspect ration between video and sidebar of about 3.5.
      The sidebar and video should have the same height, and the height should
      be set to maximise the area of the video. Because we can assume that
      all videos have the same aspect ratio (16:9), we can compute the height
      in pixels.

      As the viewport width is reduced, however, the forced 3.5 aspect ratio
      between video and sidebar will cause the video (and sidebar) to become
      "too small". At that point, the sidebar is hidden.
    */
    const style = computed(() => {
      if (deviceType.largerThan('lg')) {
        const viewportWidth = Math.min(1920, deviceType.viewportWidth.value);
        const sidebarWidth = (550 / 1920) * viewportWidth;
        const videoWidth = viewportWidth - sidebarWidth;
        const height = videoWidth * (9 / 16);
        return `grid-template-columns: 1fr minmax(0, ${sidebarWidth}px); grid-template-rows:${height}px`;
      }
      return 'grid-template-columns: 1fr';
    });

    const playCallback = async () => {
      if (props.onPlay !== undefined) {
        props.onPlay();
      }
      if (props.video.videoType !== 'trailer' && !props.guest && !props.contentEntry.preview) {
        await addOrUpdateHistory(props.contentEntry.id);
      }
    };
    const endCallback = () => {
      if (props.onEnd !== undefined) {
        props.onEnd();
      }
    };

    return {
      deviceType,
      style,
      playCallback,
      endCallback
    };
  }
});
</script>
