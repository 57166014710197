import {CraftSlug} from '../../../backend/craft/craft-types';
import {useLocalStorageFlag} from '../local-storage-flag/local-storage-flag';
import {Ref, ref} from 'vue';

export type LandingPageComposition = {
  slug: CraftSlug;
  visible: Ref<boolean>;
  toggle: () => void;
};

/**
 * This Vue composition is passed to the LandingPageSection component.
 * It is also used by the parent component to show the LandingPageSection
 * (e.g., when the user clicks an info button).
 *
 * The landing page show/hide status is saved in the browser's local storage;
 * the landing page is shown if the flag does not exist (so that landing pages
 * are displayed by default the first time the user navigates to the section).
 *
 * Call the 'show()' function in the returned composition to display the
 * landing page section again.
 *
 * @param slug The slug of the Landing Page entry to display, e.g.,
 * 'players-path-landing'.
 */
export function useLandingPage(slug: CraftSlug): Readonly<LandingPageComposition> {
  const key = `${slug}-status`;
  const cookie = useLocalStorageFlag(key, true);
  const visible = ref<boolean>(cookie.get());

  const toggle = () => {
    const status = !cookie.get();
    cookie.set(status);
    visible.value = status;
  };

  return {
    slug,
    visible,
    toggle
  };
}
