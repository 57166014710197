import {DateTime} from 'luxon';
import {
  areQuizzesComplete,
  getQuizCompletionDates,
  isQuizComplete,
  setQuizComplete
} from '../quiz/quiz-query';
import {Quiz, QuizCollectionStatus, QuizCompletionDates} from '../quiz/quiz-types';
import {getAllPlayersPathLevels, getPlayersPathLevelBySlug} from './players-path-query';
import {PlayersPathLevel} from './players-path-types';

export async function getNextPlayersPathLevelQuiz(
  nextLevel: Readonly<PlayersPathLevel>
): Promise<Readonly<Quiz> | undefined> {
  const quiz = nextLevel.quizzes[0];
  if (quiz !== undefined) {
    const completed = await isQuizComplete(quiz);
    if (!completed) {
      return quiz;
    }
    return undefined;
  }
  return undefined;
}

export async function playersPathLevelIsUnlocked(
  level: Readonly<PlayersPathLevel>
): Promise<boolean> {
  const entryQuiz = level.quizzes[0];
  if (entryQuiz === undefined) {
    return false;
  }
  const unlocked = await isQuizComplete(entryQuiz);
  return unlocked;
}

export async function getPlayersPathLevelByNumber(
  levelNumber: number
): Promise<Readonly<PlayersPathLevel> | undefined> {
  const level = await getPlayersPathLevelBySlug(`players-path-level-${levelNumber}`);
  return level;
}

export interface LevelUnlockedCompleted {
  unlocked: boolean;
  numberOfCompletedPieces: number;
  completed: boolean;
}

export function numberOfCompletedPieces(
  level: Readonly<PlayersPathLevel>,
  quizStatus: Readonly<QuizCollectionStatus>
) {
  const quizzes = level.quizzes;
  const numCompleted: number = quizzes.reduce((count: number, quiz: Quiz, index: number) => {
    // We assume that the first quiz in the array of quizzes is the level entry quiz.
    if (index > 0) {
      const status = quizStatus[quiz.id];
      if (status !== undefined && status) {
        return count + 1;
      }
    }
    return count;
  }, 0);

  return numCompleted;
}

export async function getPlayersPathLevelsUnlockedAndComplete(
  playersPathLevels: ReadonlyArray<PlayersPathLevel>
): Promise<ReadonlyArray<LevelUnlockedCompleted>> {
  // Fetch quiz statues for all level quizzes. This includes both entry quizzes and piece quizzes.
  const quizIds = playersPathLevels.reduce((accum, level) => {
    return accum.concat(level.quizzes);
  }, [] as Array<Quiz>);
  const quizzesCompleted = await areQuizzesComplete(quizIds);

  // For each level, get the unlocked + completed status.
  const result = playersPathLevels.map(level => {
    const unlocked = quizzesCompleted[level.quizzes[0].id];
    const n = numberOfCompletedPieces(level, quizzesCompleted);
    // ### We *should* fetch the prerequisites requirements for the entry quiz of the _next_ level.
    const completed = n >= 3;
    return {
      unlocked,
      numberOfCompletedPieces: n,
      completed
    };
  });

  return result;
}

function getLatestQuizCompletionDate(
  completionDates: Readonly<QuizCompletionDates>,
  level: Readonly<PlayersPathLevel>
): number | undefined {
  const dates: Array<number> = [];
  level.quizzes.forEach(quiz => {
    const completionDate = completionDates[quiz.id];
    if (completionDate !== undefined) {
      const utc = DateTime.fromSQL(completionDate).valueOf();
      dates.push(utc);
    }
  });
  dates.sort((a, b) => b - a);
  return dates[0];
}

export async function getPlayersPathContinueLevel(
  guest: string
): Promise<Readonly<PlayersPathLevel> | undefined> {
  if (guest) {
    return undefined;
  }
  const levels = await getAllPlayersPathLevels(guest);
  const quizzes = levels.reduce((quizzes: Array<Quiz>, level: Readonly<PlayersPathLevel>) => {
    return quizzes.concat(level.quizzes);
  }, []);
  const quizCompletionDates = await getQuizCompletionDates(quizzes);
  /*
    Find all levels that are applicable, i.e., are unlocked and not completed,
    and get the most recent date a quiz was completed in each level. (Including
    the level unlock quiz.)
  */
  const candidates = levels
    .filter(level => {
      // Check if the level is unlocked.
      if (quizCompletionDates[level.quizzes[0].id] === undefined) {
        return false;
      }
      // Check if the level is complete.
      const numberOfCompletedPieces = level.quizzes
        .slice(1)
        .reduce((n: number, quiz: Readonly<Quiz>) => {
          if (quizCompletionDates[quiz.id] !== undefined) {
            return n + 1;
          }
          return n;
        }, 0);
      if (numberOfCompletedPieces >= 3) {
        return false;
      }
      // Unlocked, but not complete, so it's a candidate.
      return true;
    })
    .map((level: Readonly<PlayersPathLevel>) => {
      return {
        level,
        date: getLatestQuizCompletionDate(quizCompletionDates, level)
      };
    })
    .filter(level => level.date !== undefined);

  // If we have no candidates, then we don't have a PP continue level.
  if (candidates.length === 0) {
    return undefined;
  }

  // Get the most recent candidate.
  candidates.sort((a, b) => {
    return (b.date as number) - (a.date as number);
  });
  return candidates[0].level;
}

export async function unlockPlayersPathLevels(slugs: ReadonlyArray<string>): Promise<void> {
  const levels = await getAllPlayersPathLevels('');
  const levelsToUnlock = levels.filter(level => slugs.includes(level.slug));
  for (let i = 0; i < levelsToUnlock.length; i++) {
    const entryQuiz = levelsToUnlock[i].quizzes[0];
    await setQuizComplete(entryQuiz);
  }
}
