import {DateTime} from 'luxon';
import {getAccessPassesForCurrentUser} from '../access-pass/access-pass-query';
import {AccessPassStatus} from '../access-pass/access-pass-types';
import {evaluateConditions} from './conditions/offer-conditions';
import {getAllResubscribeOffers} from './resubscribe-offer-query';
import {ResubscribeOffer} from './resubscribe-offer-types';
import {OfferConditionsContextValue} from './offer-conditions-types';
import {CraftId} from '../craft/craft-types';

export function resubscribeOfferIsActive(
  offer: Readonly<ResubscribeOffer>,
  context: OfferConditionsContextValue,
  userSignupDateUTC: number,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  if (!offer.offerIsActive) {
    return false;
  }

  const destinationPass = offer.offerDestination[0];
  if (destinationPass === undefined) {
    return false;
  }

  // Cannot resubscribe to a pass that is active, pending, past due, or in trial.
  const status = userAccessPasses.find(p => p.id === destinationPass.id);
  if (status !== undefined) {
    if (['active', 'pending', 'trial', 'pastDue'].includes(status.status)) {
      return false;
    }
  }

  return evaluateConditions<ResubscribeOffer>(
    offer,
    false,
    context,
    userSignupDateUTC,
    serverDateUTC,
    userAccessPasses
  );
}

/**
 * Return a list of available resubscribe offers for the current user.
 */
export async function getResubscribeOffersForCurrentUser(
  contexts: ReadonlyArray<OfferConditionsContextValue>,
  userDateCreated: string,
  serverDate: string,
  offerIds: ReadonlyArray<CraftId> = []
): Promise<ReadonlyArray<ResubscribeOffer>> {
  const userDateUTC = DateTime.fromISO(userDateCreated).valueOf();
  const serverDateUTC = DateTime.fromISO(serverDate).valueOf();
  const userAccessPasses = await getAccessPassesForCurrentUser();
  const allOffers = await getAllResubscribeOffers();

  const activeOffers = allOffers.filter(offer => {
    const active = contexts.find(context => {
      const activeInContext = resubscribeOfferIsActive(
        offer,
        context,
        userDateUTC,
        serverDateUTC,
        userAccessPasses
      );
      return activeInContext;
    });
    const included = offerIds.length === 0 ? true : offerIds.includes(offer.id);
    return active !== undefined && included;
  });

  return activeOffers;
}
