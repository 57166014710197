import {Ref, computed} from 'vue';
import {DeviceTypeComposition} from '../../vue-composition/device-type/device-type';
import {ColumnArea} from './types';

export type StandardPageAreaWidth =
  | 'wide'
  | 'medium'
  | 'normal'
  | 'narrow'
  | 'extra-narrow'
  | 'text';
export type StandardPageAreaJustify = 'center' | 'left';

export type StandardPageAreaComposition = {
  area: Ref<ColumnArea>;
};

export function useStandardPageArea(
  deviceType: Readonly<DeviceTypeComposition>,
  width: StandardPageAreaWidth,
  justify: StandardPageAreaJustify = 'center'
): Readonly<StandardPageAreaComposition> {
  /* eslint-disable-next-line vue/return-in-computed-property */
  const area = computed(() => {
    const screen = deviceType.screen.value;
    if (screen === 'narrow' || screen === undefined) {
      return {start: 1, span: 4};
    }
    switch (width) {
      case 'wide':
        return {start: 1, span: 12};
      case 'medium':
        switch (screen) {
          case 'sm':
          case 'md':
          case 'lg':
          case 'xl':
            return {start: 1, span: 12};
          case '2xl':
            return justify === 'center' ? {start: 2, span: 10} : {start: 1, span: 10};
        }
      case 'normal':
        switch (screen) {
          case 'sm':
          case 'md':
          case 'lg':
            return {start: 1, span: 12};
          case 'xl':
            return justify === 'center' ? {start: 2, span: 10} : {start: 1, span: 10};
          case '2xl':
            return justify === 'center' ? {start: 3, span: 8} : {start: 1, span: 8};
        }
      case 'narrow':
        switch (screen) {
          case 'sm':
          case 'md':
            return {start: 1, span: 12};
          case 'lg':
            return justify === 'center' ? {start: 2, span: 10} : {start: 1, span: 10};
          case 'xl':
            return justify === 'center' ? {start: 3, span: 8} : {start: 1, span: 8};
          case '2xl':
            return justify === 'center' ? {start: 4, span: 6} : {start: 1, span: 6};
        }
      case 'extra-narrow':
        switch (screen) {
          case 'sm':
          case 'md':
            return {start: 1, span: 12};
          case 'lg':
            return justify === 'center' ? {start: 3, span: 8} : {start: 1, span: 8};
          case 'xl':
            return justify === 'center' ? {start: 4, span: 6} : {start: 1, span: 6};
          case '2xl':
            return justify === 'center' ? {start: 5, span: 4} : {start: 1, span: 4};
        }
      case 'text':
        switch (screen) {
          case 'sm':
          case 'md':
            return {start: 1, span: 12};
          case 'lg':
            return justify === 'center' ? {start: 1, span: 8} : {start: 1, span: 8};
          case 'xl':
            return justify === 'center' ? {start: 2, span: 6} : {start: 1, span: 6};
          case '2xl':
            return justify === 'center' ? {start: 3, span: 6} : {start: 1, span: 6};
        }
    }
  });
  return {
    area
  };
}

export type SplitBasis = '3/4' | '2/3' | '1/2' | '1/3' | '1/4';

export function split(area: ColumnArea, at: SplitBasis): ReadonlyArray<ColumnArea> {
  const FACTORS = {
    '3/4': 3 / 4,
    '2/3': 2 / 3,
    '1/2': 2,
    '1/3': 1 / 3,
    '1/4': 1 / 4
  };
  const t = FACTORS[at];
  const first = Math.round(area.span * t);
  const second = area.span - first;
  const result = [
    {
      start: area.start,
      span: first
    },
    {
      start: area.start + first,
      span: second
    }
  ];
  return result;
}
