<template>
  <div class="flex flex-col justify-start items-start gap-fl-xs">
    <div v-if="label" class="text-fl-base text-mid uppercase">Beat</div>
    <fitted-text
      :class="text"
      :viewbox-x="0.8"
      :viewbox-width="10.25"
      :viewbox-height="14"
      :text-y="13"
      :text="beatDisplay"
    />
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import FittedText from '../../../core/fitted-text/FittedText.vue';

export default {
  components: {
    FittedText
  },
  props: {
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    label: {type: Boolean, default: true}
  },
  setup(props) {
    const beatDisplay = computed(() => {
      if (props.player.mode.value !== 'reset') {
        return `${props.player.beat.value + 1}`;
      }
      return '0';
    });
    const text = computed(() => {
      return `tracking-tight leading-[0.8] tabular-nums whitespace-pre font-semibold fill-mid`;
    });

    return {
      text,
      beatDisplay
    };
  }
};
</script>
