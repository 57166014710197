import * as Joi from 'joi';
import {cachedValidatedHttpGet} from '../http';
import {VALIDATION_CRAFT_OPTIONAL_URL} from '../craft/craft-types';
import {VALIDATION_NON_EMPTY_STRING} from '../validation';
import {craftUrl} from '../../utils/url';
import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {CraftSection} from '../craft/section/section-types';

const PRODUCT_SECTION_VALIDATION_SCHEMA = Joi.object({
  handle: VALIDATION_NON_EMPTY_STRING,
  title: VALIDATION_NON_EMPTY_STRING,
  type: VALIDATION_NON_EMPTY_STRING,
  url: VALIDATION_CRAFT_OPTIONAL_URL
});

const PRODUCT_SECTION_ARRAY_VALIDATION_SCHEMA = Joi.array().items(
  PRODUCT_SECTION_VALIDATION_SCHEMA
);

/* Because fetching the product sections can happen many times on a page, we cache them. */
export type ProductSections = ReadonlyArray<CraftSection>;
const productSectionsCache = new ThreadSyncCache<ProductSections>();

/**
 * Return the handles and titles of the sections that can be
 * associated with products. NOTE: These sections are defined
 * in the category group called "Product sections".
 */
export async function getProductSections(): Promise<ProductSections> {
  // ### Replace cachedValidatedHttpGet() with cachedPostAction()
  const url = craftUrl('/actions/sbl-module/products/get-product-sections');
  try {
    const result = await cachedValidatedHttpGet<ProductSections>(
      url,
      PRODUCT_SECTION_ARRAY_VALIDATION_SCHEMA,
      productSectionsCache,
      'productSections'
    );
    return result;
  } catch (error) {
    throw new Error(`Could not get product sections: ${error}`);
  }
}

export type ProductSectionMap = {[handle: string]: CraftSection};

/**
 * Utility function that returns a map from product section handle to
 * product section name.
 */
export async function getProductSectionMap(): Promise<Readonly<ProductSectionMap>> {
  const productSections = await getProductSections();
  const productSectionMap: ProductSectionMap = {};
  productSections.forEach(ps => {
    productSectionMap[ps.handle] = ps;
  });
  return productSectionMap;
}

/**
 * Return the details of a specific product section.
 */
export async function getProductSectionWithHandle(
  handle: string
): Promise<CraftSection | undefined> {
  const sections = await getProductSections();
  return sections.find(section => {
    return section.handle === handle;
  });
}
