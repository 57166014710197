<template>
  <standard-button
    :size="'lg'"
    :padding="'py-fl-sm px-fl-lg-3xl'"
    :on-click="onClick"
    :label="'Tips and Tricks'"
  />
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import VideoDialog from '../../../generic/video-dialog/VideoDialog.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {useModalDialog} from '../../../vue-composition/modal-dialog/modal-dialog';
import {Video} from '../../../../backend/video/video-types';
export default defineComponent({
  components: {
    StandardButton
  },
  setup() {
    const video: Readonly<Video> = {
      videoServiceProvider: 'vimeo',
      videoId: '847321585',
      videoType: 'trailer',
      videoDuration: ''
    };

    const modalDialog = useModalDialog(VideoDialog);
    const onClick = () => {
      modalDialog.show(
        () => {},
        () => {},
        {
          heading: 'Tips & Tricks',
          video
        },
        false
      );
    };
    return {onClick};
  }
});
</script>
