<template>
  <page-section>
    <centered-column>
      <standard-button
        v-if="config._okLabel !== undefined"
        :size="'md'"
        :color="'invert'"
        :on-click="config._onOk"
        :label="config._okLabel"
      />
      <link-button
        v-if="config._showCancel && config._cancelLabel !== undefined"
        :size="'xs'"
        :on-click="config._onCancel"
        :color="'mid'"
        :label="config._cancelLabel"
      />
    </centered-column>
  </page-section>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import {ModalDialogConfig} from '../../../vue-composition/modal-dialog/types';
import PageSection from '../../../core/page/PageSection.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';

export default defineComponent({
  components: {
    PageSection,
    CenteredColumn,
    StandardButton,
    LinkButton
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true}
  },
  setup() {}
});
</script>
