<template>
  <iframe
    v-if="!preview && !guest && src != undefined"
    :src="src"
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    allowtransparency="true"
    class="w-full min-h-[400px]"
  ></iframe>
</template>

<script lang="ts">
import {MD5} from 'crypto-js';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  components: {},
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    userIsAdmin: {type: String, required: true},
    userId: {type: String, required: true},
    userName: {type: String, required: true},
    chatrollId: {type: String, required: true},
    chatrollSecretKey: {type: String, required: true}
  },
  setup(props) {
    /*
      Construct a URL for the Chatroll iframe. For info on what these parameters are
      and how to construct the URL, see https://support.chatroll.com/developers/sso/chatroll-sso-api
    */
    const src = computed(() => {
      const userId = props.userId;
      const userName = props.userName.substring(0, 64);
      const modStatus = props.userIsAdmin ? '1' : '0';
      const secretKey = props.chatrollSecretKey;

      const uid = `&uid=${encodeURIComponent(userId)}`;
      const uname = `&uname=${encodeURIComponent(userName)}`;
      const ismod = `&ismod=${modStatus}`;
      const md5 = MD5(`${userId}${userName}${modStatus}${secretKey}`);
      const sig = `&sig=${md5}`;

      const params = `${uid}${uname}${ismod}${sig}`;
      const url = `https://chatroll.com/embed/chat/sbl-academy?id=${props.chatrollId}&platform=php${params}`;
      return url;
    });

    return {
      src
    };
  }
});
</script>
