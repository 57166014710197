<template>
  <button :aria-label="'Reset'" :class="width" @click="audioPlayer.onReset">
    <font-awesome-icon :icon="iconBackwardFast" class="w-full h-full text-light" />
  </button>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faBackwardFast} from '@fortawesome/pro-solid-svg-icons/faBackwardFast';
import {AudioPlayerComposition} from '../internals/utils';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    audioPlayer: {type: Object as PropType<Readonly<AudioPlayerComposition>>, required: true},
    width: {type: String, default: 'w-1/3'}
  },
  setup() {
    const iconBackwardFast = computed(() => faBackwardFast);
    return {
      iconBackwardFast
    };
  }
};
</script>
