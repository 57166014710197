/**
 * Utility function that divides a lengthy action into a number of batches
 * and carries them out (in order) in the background.
 */
export async function paginatedAction(
  total: number,
  itemsPerPage: number,
  action: (offset: number, limit: number) => Promise<void>,
  onFinished?: () => void
) {
  /*
    Divide an action into batches. We do not await on the outer Promise because 
    we want the fetching to continue in the background.
  */
  new Promise(async _ => {
    const n = Math.ceil(total / itemsPerPage);
    for (let i = 0; i <= n; i += 1) {
      await action(i * itemsPerPage, itemsPerPage);
    }
    if (onFinished) {
      onFinished();
    }
  });
}
