<template>
  <!-- ??? We use the CSS aspect-ratio property here, which became available in 2021. Is that OK? -->
  <div class="relative w-full aspect-video bg-darkest">
    <font-awesome-icon
      class="absolute w-1/3 h-1/3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary"
      :icon="icon"
    ></font-awesome-icon>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  setup() {
    const icon = computed(() => faLock);
    return {icon};
  }
});
</script>
