<template>
  <page-content>
    <!-- Page header -->

    <page-section :margin-top="'sm'" :margin-bottom="'none'" :padding-bottom="'none'">
      <content-column :width="'narrow'">
        <div class="mt-fl-md text-fl-base text-center">Learning Pathways</div>
      </content-column>
    </page-section>

    <content-entry-section-summary
      :center="true"
      :landing-page="landingPage"
      :width="'medium'"
      :title="'Beginner Journey'"
      :info-button="true"
      :on-info-button-clicked="landingPage.toggle"
      :margin-top="'xs'"
    />

    <!-- Beginner Learning Pathways section -->

    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-heading :size="2">The Journey</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'wide'">
        <div class="flex flex-wrap justify-between gap-x-fl-sm gap-y-fl-lg">
          <div class="text-fl-xl max-w-[800px]">
            Whether you are new to bass or seeking to refine your skills, the Beginner Journey has
            everything you need to build a strong foundation and stay focused. We’ve included the
            key tools you will need. The “Beginners Learning Pathway" is a step-by-step lesson guide
            to bass guitar. “Players Path” is a collection of study songs that progress with you as
            you learn. Pick some songs from each level to master. Take your time and sit with these
            lessons to get the most value. This course can take weeks to complete so don’t rush!
          </div>
          <div class="flex gap-fl-md">
            <content-entry-header-campus
              :guest="guest"
              :url="'https://campus.scottsbasslessons.com/forum/205-beginner-journey/'"
              :label="'Campus'"
            />
            <content-entry-header-groove-trainer
              :guest="guest"
              :label="'Groove Trainer'"
              :url="'/groove-trainer'"
            />
            <content-entry-header-new-member-huddle
              :guest="guest"
              :label="'Member Huddle'"
              :url="'#new-member-huddle-card'"
            />
          </div>
        </div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'" :margin-bottom="'sm'">
      <content-column :width="'wide'">
        <standard-divider class="border-dark" />
      </content-column>
    </page-section>

    <page-section :margin-top="'none'">
      <content-entry-inline-cards
        :guest="guest"
        :completed="completed"
        :progress="progress"
        :bookmark="bookmark"
        :display-post-date="false"
        :pagination="pagination"
        :width="'wide'"
        :justify="'center'"
        :card-image-width="cardImageWidth"
        :imgix-params="{ar: '16:9', fit: 'crop'}"
      />
    </page-section>

    <page-section :margin-top="'sm'" :margin-bottom="'sm'">
      <content-column :width="'wide'">
        <standard-divider class="border-dark" />
      </content-column>
    </page-section>

    <!-- Additional Tools section -->

    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-heading class="mb-fl-md" :size="2">Additional Tools</standard-heading>
      </content-column>
    </page-section>

    <additional-tools-cards :guest="guest" />
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';

import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntrySectionSummary from '../../content-entry/partials/section/ContentEntrySectionSummary.vue';
import ContentEntryInlineCards from '../../content-entry/collections/variants/ContentEntryInlineCards.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderGrooveTrainer from '../../content-entry/partials/header/actions/ContentEntryHeaderGrooveTrainer.vue';
import ContentEntryHeaderNewMemberHuddle from '../../content-entry/partials/header/actions/ContentEntryHeaderMemberHuddle.vue';
import StandardDivider from '../../core/standard-divider/StandardDivider.vue';
import AdditionalToolsCards from './partials/AdditionalToolsCards.vue';
import {usePagination} from '../../vue-composition/pagination/pagination';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useProgressMap} from '../../vue-composition/progress/progress';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {ImgixImageResponsiveWidth} from '../../core/imgix-image/types';
import {getBeginnerJourneyLearningPathwaysEntries} from './utils';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryInlineCards,
    ContentEntrySectionSummary,
    ContentEntryHeaderCampus,
    ContentEntryHeaderGrooveTrainer,
    ContentEntryHeaderNewMemberHuddle,
    AdditionalToolsCards,
    StandardDivider,
    StandardHeading
  },
  props: {
    guest: {type: String, required: true},
    landingPageSlug: {type: String as PropType<CraftSlug>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const bookmark = useBookmark(props.guest);
    const landingPage = useLandingPage(props.landingPageSlug);
    const contentEntries = ref<ReadonlyArray<ContentEntry>>([]);
    const progress = useProgressMap(props.guest, contentEntries);

    onMounted(async () => {
      loader.setLoading(true);
      contentEntries.value = await getBeginnerJourneyLearningPathwaysEntries();
      await bookmark.init();
      loader.setLoading(false);
    });

    const pagination = usePagination<ContentEntry>(
      computed(() => {
        if (contentEntries.value !== undefined) {
          return contentEntries.value;
        }
        return [];
      })
    );

    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 300,
      sm: 340,
      md: 350,
      lg: 300,
      xl: 330,
      '2xl': 400
    };

    return {
      landingPage,
      progress: computed(() => progress.progressMap.value),
      completed: computed(() => progress.completedMap.value),
      bookmark,
      cardImageWidth,
      pagination
    };
  }
});
</script>
