<template>
  <a
    class="inline-block w-fit h-fit min-w-[4em] px-[1em] py-[0.4em] m-0 uppercase leading-none no-underline font-normal bg-gradient-to-r from-pp1 to-pp3 rounded-[2em] text-fl-xs text-black"
    :aria-label="'To Beginner Journey'"
    :title="'To Beginner Journey'"
    :href="'/beginner-journey'"
  >
    Beginner Journey
  </a>
</template>
