<template>
  <page-content>
    <content-entry-section-summary
      :landing-page="landingPage"
      :title-image-max-width="'max-w-[550px]'"
      :title-image-max-width-summary="'max-w-[550px]'"
      :width="'wide'"
      :title-image="'https://scottsbasslessons.imgix.net/content/learning-pathways.svg'"
      :info-button="true"
      :on-info-button-clicked="landingPage.toggle"
    />

    <beginner-journey-card :guest="guest" :margin-top="'lg'" :width="'wide'" />

    <page-section :margin-top="'lg'">
      <learning-pathway-card-grid
        v-if="progress !== undefined"
        :content-entries="fudgedContentEntries"
        :progress="progress"
        :width="'medium'"
      />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';

import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentEntrySectionSummary from '../../content-entry/partials/section/ContentEntrySectionSummary.vue';
import LearningPathwayCardGrid from './partials/LearningPathwayCardGrid.vue';
import BeginnerJourneyCard from '../beginner-journey/partials/BeginnerJourneyCard.vue';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {getProductSectionWithHandle} from '../../../backend/product/product-query';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useProgressMap} from '../../vue-composition/progress/progress';
import {entryIsInBeginnerJourney, getBeginnerJourneyContentEntry} from '../beginner-journey/utils';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export default defineComponent({
  components: {
    PageContent,
    ContentEntrySectionSummary,
    LearningPathwayCardGrid,
    BeginnerJourneyCard,
    PageSection
  },
  props: {
    guest: {type: String, required: true},
    landingPageSlug: {type: String as PropType<CraftSlug>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const bookmark = useBookmark(props.guest);
    const landingPage = useLandingPage(props.landingPageSlug);
    const sectionTitle = ref('');
    const entries = ref([] as Array<ContentEntry>);
    const progress = useProgressMap(props.guest, entries);

    onMounted(async () => {
      loader.setLoading(true);

      const section = await getProductSectionWithHandle('learningPathways');
      if (section !== undefined) {
        sectionTitle.value = 'Learning Pathways';
        entries.value = await fetchContentEntries(contentEntries().section(['learningPathways']));
        await bookmark.init();
      } else {
        throw new Error(`'learningPathways' is not a product section`);
      }
      loader.setLoading(false);
    });

    /*
      ??? This is a hack that artificially combines the three Beginner
      learning pathways into a single card and calls it "Beginner Journey".
      In the future, we should combine the three pathways into one, or
      create a "Journey" structure that sits above Learning Pathways in
      the content hierarchy.
    */
    const fudgedContentEntries = computed(() => {
      return [getBeginnerJourneyContentEntry()].concat(
        entries.value.filter(e => !entryIsInBeginnerJourney(e))
      );
    });

    const ready = computed(() => {
      return entries.value.length !== 0;
    });

    return {
      landingPage,
      ready,
      sectionTitle,
      fudgedContentEntries,
      progress: computed(() => progress.progressMap.value),
      bookmark
    };
  }
});
</script>
