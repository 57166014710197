<template>
  <feature-card-full :width="width">
    <template #left>
      <imgix-image :config="imageConfig" class="w-full h-full bg-dark object-cover" />
    </template>
    <template #right>
      <div class="p-fl-lg">
        <div class="mb-fl-md text-fl-5xl text-white text-center font-semibold uppercase italic">
          Groove Trainer
        </div>
        <div class="mb-fl-sm text-fl-xl text-white text-center font-semibold">
          Metronome / drum loops / tuner
        </div>
        <div class="mb-fl-md text-fl-xl text-light text-center">
          The Groove Trainer is an indispensable bass tool to support your learning. Hone your
          groove in multiple tempos and styles, refine your timing, and always stay in tune!
        </div>
        <div class="flex justify-center">
          <link-button
            :url="'/groove-trainer'"
            :new-tab="true"
            :label="'Launch Groove Trainer'"
            :color="'lightest'"
          />
        </div>
      </div>
    </template>
  </feature-card-full>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import FeatureCardFull from '../../../generic/feature-card/FeatureCardFull.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {ImgixImageConfig} from '../../../core/imgix-image/types';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    LinkButton,
    FeatureCardFull,
    ImgixImage
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'}
  },
  setup() {
    const imageConfig: Readonly<ImgixImageConfig> = {
      path: 'content/beginner-groovetrainer.jpg',
      alt: 'SBL Campus',
      width: {
        narrow: 470,
        sm: 650,
        md: 390,
        lg: 500,
        xl: 600,
        '2xl': 560
      }
    };
    return {
      imageConfig
    };
  }
});
</script>
