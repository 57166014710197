<template>
  <repel :gap="'gap-0'" :wrap="false" :class="color">
    <cluster :gap="'gap-fl-xs'" :wrap="false">
      <font-awesome-icon class="text-fl-3xl" :icon="iconTimer"></font-awesome-icon>
      <div class="text-fl-lg uppercase">Count</div>
    </cluster>
    <cluster :gap="'gap-fl-sm'" :wrap="false">
      <div class="text-fl-lg">
        {{ variationConfig.preCountDisplay.value }}
      </div>
      <up-down-button-combo
        :gap="'gap-fl-sm'"
        :disabled="disabled"
        :color="'lightest'"
        :on-up="variationConfig.preCountBarsUp"
        :on-down="variationConfig.preCountBarsDown"
        :tooltip-up="'Increase the number of precount bars'"
        :tooltip-down="'Decrease the number of precount bars'"
      />
    </cluster>
  </repel>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import UpDownButtonCombo from '../../../core/button/UpDownButtonCombo.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faTimer} from '@fortawesome/pro-regular-svg-icons/faTimer';
import {VariationConfig} from '../internals/utils';

export default {
  components: {
    UpDownButtonCombo,
    Repel,
    Cluster,
    FontAwesomeIcon
  },
  props: {
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, default: false}
  },
  setup(props) {
    const iconTimer = computed(() => faTimer);
    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));

    return {
      color,
      iconTimer
    };
  }
};
</script>
