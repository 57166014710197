<template>
  <div class="flex flex-col justify-start items-start gap-fl-xs">
    <div v-if="label" class="text-fl-base text-mid uppercase">Current bar</div>
    <fitted-text
      :class="text"
      :viewbox-x="0.8"
      :viewbox-width="31.2"
      :viewbox-height="14"
      :text-y="13"
      :text="barDisplay"
    />
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import FittedText from '../../../core/fitted-text/FittedText.vue';
import {formatBarNumber} from '../internals/utils';

export default {
  components: {
    FittedText
  },
  props: {
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    label: {type: Boolean, default: true}
  },
  setup(props) {
    const barDisplay = computed(() => {
      return formatBarNumber(props.player.bar.value);
    });
    const text = computed(() => {
      return `tracking-tight leading-[0.8] tabular-nums whitespace-pre font-semibold fill-white`;
    });

    return {
      text,
      barDisplay
    };
  }
};
</script>
