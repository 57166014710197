import {CraftEntryStatus, CraftId, CraftSectionHandle, CraftSlug} from '../craft-types';
import {
  CraftQueryBuilder,
  CraftQueryBuilderCategories,
  CraftQueryBuilderEntries,
  CraftQueryBuilderGlobalSet,
  CraftQueryBuilderMatrix,
  CraftQueryBuilderMatrixBlock,
  CraftQueryBuilderValue
} from './craft-query-builder-types';
import {
  CraftQueryCategoriesField,
  CraftQueryDateField,
  CraftQueryDropdownField,
  CraftQueryEntriesField,
  CraftQueryField,
  CraftQueryFieldHandle,
  CraftQueryFilter,
  CraftQueryGlobalSet,
  CraftQueryLightswitchField,
  CraftQueryMatrixField,
  CraftQueryMatrixFieldBlock,
  CraftQueryMultiselectField,
  CraftQueryNumberField,
  CraftQueryPlainTextField,
  CraftQueryUrlField
} from './implementation/craft-query-types';

function addFields(
  existing: ReadonlyArray<CraftQueryBuilder> | undefined,
  incoming: ReadonlyArray<CraftQueryBuilder>
) {
  if (existing === undefined) {
    return incoming;
  } else {
    // Remove existing fields that are exist in the incoming field list.
    const incomingHandles = incoming
      .map(f => {
        return f._handle();
      })
      .filter(h => h !== undefined);
    const uniqueExisting = existing.filter(f => {
      const existingHandle = f._handle();
      if (existingHandle === undefined) {
        return true;
      }
      return !incomingHandles.includes(existingHandle);
    });
    return uniqueExisting.concat(incoming);
  }
}

function validateMatrixField(field: Readonly<CraftQueryMatrixField>) {
  const handles = new Set<string>();
  field.blocks.forEach(b => {
    if (handles.has(b.handle)) {
      throw new Error(`Duplicate matrix block: ${b.handle}`);
    }
    handles.add(b.handle);
    validateFields(b.fields);
  });
}

function validateFields(fields: ReadonlyArray<CraftQueryField> | undefined) {
  const handles = new Set<string>();
  if (fields !== undefined) {
    fields.forEach(f => {
      if (f.handle === undefined) {
        throw new Error('Field has no handle');
      }
      const handle = f.handle;
      if (handles.has(handle)) {
        throw new Error(`Duplicate field: ${handle}`);
      }
      handles.add(handle);
      switch (f.type) {
        case 'matrix':
          validateMatrixField(f);
          break;
        case 'categories':
        case 'entries':
        case 'globalSet':
          validateFields(f.fields);
          break;
      }
    });
  }
}

/**
 * Create a plaintext field query component.
 *
 * @param handle The handle of the field.
 */
export function plainText(handle: CraftQueryFieldHandle): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryPlainTextField> {
      return {
        type: 'plainText',
        required: _required,
        handle,
        customData: _data
      };
    }
  };
}

/**
 * Create a number field query component.
 *
 * @param handle The handle of the field.
 */
export function number(handle: CraftQueryFieldHandle): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryNumberField> {
      return {
        type: 'number',
        required: _required,
        handle,
        customData: _data
      };
    }
  };
}

/**
 * Create a url field query component.
 *
 * @param handle The handle of the field.
 */
export function url(handle: CraftQueryFieldHandle): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryUrlField> {
      return {
        type: 'url',
        required: _required,
        handle,
        customData: _data
      };
    }
  };
}

/**
 * Create a lightswitch field query component.
 *
 * @param handle The handle of the field.
 */
export function lightswitch(handle: CraftQueryFieldHandle): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryLightswitchField> {
      return {
        type: 'lightswitch',
        required: _required,
        handle,
        customData: _data
      };
    }
  };
}

/**
 * Create a date field query component. NOTE: Dates are returned in ISO format.
 *
 * @param handle The handle of the field.
 */
export function date(handle: CraftQueryFieldHandle): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryDateField> {
      return {
        type: 'date',
        required: _required,
        handle,
        customData: _data
      };
    }
  };
}

/**
 * Create a dropdown field query component.
 *
 * @param handle The handle of the field.
 * @param allowedOptions A list of allowed option values (not labels).
 */
export function dropdown(
  handle: CraftQueryFieldHandle,
  allowedOptions: ReadonlyArray<string>
): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryDropdownField> {
      return {
        type: 'dropdown',
        required: _required,
        handle,
        customData: _data,
        allowedOptions
      };
    }
  };
}

/**
 * Create a multiselect field query component.
 *
 * @param handle The handle of the field.
 * @param allowedOptions A list of allowed option values (not labels).
 */
export function multiselect(
  handle: CraftQueryFieldHandle,
  allowedOptions: ReadonlyArray<string>
): CraftQueryBuilderValue {
  let _required: boolean = false;
  let _data: any | undefined = undefined;
  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryMultiselectField> {
      return {
        type: 'multiselect',
        required: _required,
        handle,
        customData: _data,
        allowedOptions
      };
    }
  };
}

/**
 * Create a matrix block query component. NOTE: The field list for
 * the matrix block may not be empty.
 *
 * @param handle The handle of the matrix block.
 */
export function block(handle: CraftQueryFieldHandle): CraftQueryBuilderMatrixBlock {
  let _fields: ReadonlyArray<CraftQueryBuilder> | undefined = undefined;
  return {
    fields(fields: ReadonlyArray<CraftQueryBuilder>) {
      _fields = addFields(_fields, fields);
      return this;
    },
    _build(): Readonly<CraftQueryMatrixFieldBlock> {
      if (_fields === undefined || _fields.length === 0) {
        throw new Error('No field definitions in matrix block');
      }
      return {
        handle,
        fields: _fields.map(f => f._build())
      };
    }
  };
}

/**
 * Create a matrix field query component. NOTE: The matrix field must have
 * at least one block.
 *
 * @param handle The handle of the field.
 */
export function matrix(handle: CraftQueryFieldHandle): CraftQueryBuilderMatrix {
  let _required: boolean = false;
  let _blocks: ReadonlyArray<CraftQueryBuilderMatrixBlock> | undefined = undefined;
  let _data: any | undefined = undefined;

  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    blocks(blocks: ReadonlyArray<CraftQueryBuilderMatrixBlock>) {
      _blocks = blocks;
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryMatrixField> {
      if (_blocks === undefined) {
        throw new Error('Matrix field has no block definitions');
      }
      return {
        type: 'matrix',
        required: _required,
        handle,
        customData: _data,
        blocks: _blocks.map(b => b._build())
      };
    }
  };
}

/**
 * Create a categories field query component.
 *
 * @param handle Handle of this field, if it is a child of another field.
 * If this is a top-level categories query, leave this argument undefined.
 */
export function categories(handle?: CraftQueryFieldHandle): CraftQueryBuilderCategories {
  let _required: boolean = false;
  let _group: CraftSectionHandle | undefined = undefined;
  let _id: ReadonlyArray<CraftId> | undefined = undefined;
  let _slug: ReadonlyArray<CraftSlug> | undefined = undefined;
  let _limit: number | undefined = undefined;
  let _offset: number | undefined = undefined;
  let _filter: Readonly<CraftQueryFilter> | undefined = undefined;
  let _status: ReadonlyArray<CraftEntryStatus> | undefined = undefined;
  let _fields: ReadonlyArray<CraftQueryBuilder> | undefined = undefined;
  let _data: any | undefined = undefined;

  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    group(group: CraftSectionHandle) {
      _group = group;
      return this;
    },
    status(status: ReadonlyArray<CraftEntryStatus>) {
      _status = status;
      return this;
    },
    id(id: ReadonlyArray<CraftId>) {
      _id = id;
      return this;
    },
    slug(slug: ReadonlyArray<CraftSlug>) {
      _slug = slug;
      return this;
    },
    filter(filter: Readonly<CraftQueryFilter>) {
      _filter = filter;
      return this;
    },
    limit(limit: number) {
      _limit = limit;
      return this;
    },
    offset(offset: number) {
      _offset = offset;
      return this;
    },
    fields(fields: ReadonlyArray<CraftQueryBuilder>) {
      _fields = addFields(_fields, fields);
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryCategoriesField> {
      const build = _fields ? _fields.map(f => f._build()) : undefined;
      if (build !== undefined) {
        validateFields(build);
      }
      return {
        type: 'categories',
        required: _required,
        group: _group,
        handle: handle,
        customData: _data,
        status: _status,
        id: _id,
        slug: _slug,
        filter: _filter,
        limit: _limit,
        offset: _offset,
        fields: build
      };
    }
  };
}

/**
 * Create an entries field query component.
 *
 * @param handle Handle of this field, if it is a child of another field.
 * If this is a top-level entries query, leave this argument undefined.
 */
export function entries(handle?: CraftQueryFieldHandle): CraftQueryBuilderEntries {
  let _section: ReadonlyArray<CraftQueryFieldHandle> | undefined = undefined;
  let _required: boolean = false;
  let _id: ReadonlyArray<CraftId> | undefined = undefined;
  let _slug: ReadonlyArray<CraftSlug> | undefined = undefined;
  let _orderBy: string | undefined = undefined;
  let _limit: number | undefined = undefined;
  let _offset: number | undefined = undefined;
  let _search: string | undefined = undefined;
  let _relatedTo: CraftId | undefined = undefined;
  let _filter: Readonly<CraftQueryFilter> | undefined = undefined;
  let _status: ReadonlyArray<CraftEntryStatus> | undefined = undefined;
  let _fields: ReadonlyArray<CraftQueryBuilder> | undefined = undefined;
  let _data: any | undefined = undefined;

  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    section(section: ReadonlyArray<CraftSectionHandle>) {
      _section = section;
      return this;
    },
    status(status: ReadonlyArray<CraftEntryStatus>) {
      _status = status;
      return this;
    },
    id(id: ReadonlyArray<CraftId>) {
      _id = id;
      return this;
    },
    slug(slug: ReadonlyArray<CraftSlug>) {
      _slug = slug;
      return this;
    },
    search(search: string) {
      _search = search;
      return this;
    },
    relatedTo(id: CraftId) {
      _relatedTo = id;
      return this;
    },
    filter(filter: Readonly<CraftQueryFilter>) {
      _filter = filter;
      return this;
    },
    orderBy(orderBy: string) {
      _orderBy = orderBy;
      return this;
    },
    limit(limit: number) {
      _limit = limit;
      return this;
    },
    offset(offset: number) {
      _offset = offset;
      return this;
    },
    fields(fields: ReadonlyArray<CraftQueryBuilder>) {
      _fields = addFields(_fields, fields);
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryEntriesField> {
      const build = _fields ? _fields.map(f => f._build()) : undefined;
      if (build !== undefined) {
        validateFields(build);
      }
      return {
        type: 'entries',
        required: _required,
        handle,
        customData: _data,
        section: _section,
        status: _status,
        id: _id,
        slug: _slug,
        search: _search,
        relatedTo: _relatedTo,
        filter: _filter,
        orderBy: _orderBy,
        limit: _limit,
        offset: _offset,
        fields: build
      };
    }
  };
}

/**
 * Create a global set query component.
 *
 * @param handle Handle of the global set.
 */
export function globalSet(handle: CraftQueryFieldHandle): CraftQueryBuilderGlobalSet {
  let _required: boolean = false;
  let _fields: ReadonlyArray<CraftQueryBuilder> | undefined = undefined;
  let _data: any | undefined = undefined;

  return {
    required() {
      _required = true;
      return this;
    },
    customData(data: any) {
      _data = data;
      return this;
    },
    fields(fields: ReadonlyArray<CraftQueryBuilder>) {
      if (_fields === undefined) {
        _fields = fields;
      } else {
        _fields = _fields.concat(fields);
      }
      return this;
    },
    _handle() {
      return handle;
    },
    _build(): Readonly<CraftQueryGlobalSet> {
      if (_fields === undefined || _fields.length === 0) {
        throw new Error('Global set has no field definitions');
      }
      const build = _fields.map(f => f._build());
      if (build !== undefined) {
        validateFields(build);
      }
      return {
        type: 'globalSet',
        required: _required,
        handle,
        customData: _data,
        fields: build
      };
    }
  };
}
