import {ContentEntry, CraftQueryBuilderContentEntries} from './content-entry-types';
import {Entry} from '../../craft/entry/entry-types';
import {getAccessPassesForCurrentUser} from '../../access-pass/access-pass-query';
import {AccessPassStatus} from '../../access-pass/access-pass-types';
import {countEntries, fetchEntries} from '../../craft/entry/entry-query';
import {Category} from '../../craft/category/category-types';
import {ResultFilter, ResultTransform} from '../../craft/query/craft-query-builder-types';
import {entryUrlWithStatus} from '../../craft/entry/entry-utils';
import {getAllProductDefinitions} from '../../product/product-definition-query';
import {ProductDefinition} from '../../product/product-definition-types';
import {useContentEntryPreview} from '../../../components/vue-composition/content-entry-preview/content-entry-preview';

interface ContentEntryWithProductSystemFields extends ContentEntry {
  publicAccess: boolean;
  parentProducts: Array<Category>;
  hideWhenNotOwned: boolean;
  previewInTrial: boolean;
}

function getAccessPassesForProduct(
  product: Readonly<Category>,
  productDefinitions: ReadonlyArray<ProductDefinition>
): ReadonlyArray<Category> {
  const productDefinition = productDefinitions.find(pd => pd.id === product.id);
  if (productDefinition === undefined) {
    throw new Error('Unknown product');
  }
  return productDefinition.accessPasses;
}

function getAccessPassesForEntry(
  contentEntry: Readonly<ContentEntryWithProductSystemFields>,
  productDefinitions: ReadonlyArray<ProductDefinition>
): ReadonlyArray<Category> {
  return contentEntry.parentProducts.reduce((accessPasses: Array<Category>, product: Category) => {
    return accessPasses.concat(getAccessPassesForProduct(product, productDefinitions));
  }, []);
}

function isContentEntryInPreviewMode(
  contentEntry: Readonly<ContentEntryWithProductSystemFields>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>,
  productDefinitions: ReadonlyArray<ProductDefinition>
): boolean {
  // Public access entries are never in preview mode.
  if (contentEntry.publicAccess) {
    return false;
  }

  // Grab the passes that give access to the specified content entry...
  const entryPasses = getAccessPassesForEntry(contentEntry, productDefinitions);

  // ...and get the passes the user owns among those.
  const passes = userAccessPasses.filter(userAccessPass => {
    return entryPasses.some(contentEntryAccessPass => {
      return userAccessPass.id === contentEntryAccessPass.id;
    });
  });

  // Then check if any of the user passes has a vaild status.
  const anyPassIsActive = passes.some(pass => {
    if (contentEntry.previewInTrial) {
      return pass.status === 'active' || pass.status === 'pending' || pass.status === 'cancelling';
    }
    return (
      pass.status === 'active' ||
      pass.status === 'pending' ||
      pass.status === 'trial' ||
      pass.status === 'cancelling'
    );
  });

  return !anyPassIsActive;
}

/**
 * Fetch content entries from the Craft backend.
 *
 * "Content entry" is an SBL concept. A content entry is a Craft entry with a
 * pre-specified set of fields that are necessary to represent "content" on the
 * SBL website. Examples of such fields are subjects, summary, recommendations,
 * downloadable resources, tutors, etc.
 *
 * All content entries belong to at least one "product". Users are given access
 * to products via "access passes" (which can be free, single purchases, or
 * subscriptions). A content entry for which the user does not own an active
 * access pass is said to be in "preview mode". A content entry can optionally
 * be hidden from users for which it is in preview mode. A content entry
 * is automatically in preview mode if the current user is a guest; however,
 * a content entry can be optionally set to be "public access" in which case
 * it is never in preview mode, even for guests.
 *
 * This function assigns a `preview` flag to each content entry. It also filters
 * out content entries that should be hidden from the current user from the query
 * result. The preview flag and filtering is applied recursively to sub-fields.
 *
 * IMPORTANT: The "limit" and "offset" parameters in the `config` argument refer
 * to the total number of entries in the Craft database (in the specified section/s).
 * They do NOT refer to the number of entries that the current user is allowed to
 * see. Therefore, this function may return fewer results than the specified limit.
 *
 * The query result entries are type widened ("upcast") to the specified type. This
 * allows you to query for content entries that contain fields in addition to the
 * standard content entry fields. Note that you are responsible for ensuring that
 * the Typescript type and the query match each other.
 *
 * @param sections The section/s to fetch entries from. Set to undefined if you
 * want to fetch entries from all sections (but note that you must narrow the
 * query in some other way, e.g., via entry id:s).
 */
export async function fetchContentEntries<TYPE extends Entry = ContentEntry>(
  query: Readonly<CraftQueryBuilderContentEntries>
): Promise<Array<TYPE>> {
  const contentEntryPreview = useContentEntryPreview();
  try {
    const productDefinitions = await getAllProductDefinitions();
    const accessPassStatuses = await getAccessPassesForCurrentUser();

    // Query result transform that adds the 'preview' flag to content entries.
    const map: ResultTransform = (customData, queryResultData) => {
      if (customData === 'contentEntries') {
        const ce = queryResultData as Readonly<ContentEntryWithProductSystemFields>;
        const preview = isContentEntryInPreviewMode(ce, accessPassStatuses, productDefinitions);
        const accessPasses = getAccessPassesForEntry(ce, productDefinitions);
        const url = entryUrlWithStatus(ce);
        return {...ce, url, preview, accessPasses};
      }
      return queryResultData;
    };
    // Query result filter that removes content entries that should be hidden.
    const filter: ResultFilter = (customData, queryResultData) => {
      if (customData === 'contentEntries') {
        const ce = queryResultData as Readonly<ContentEntryWithProductSystemFields>;
        const isHidden = ce.hideWhenNotOwned && ce.preview;
        return !isHidden;
      }
      return true;
    };

    const contentEntries = await fetchEntries<ContentEntryWithProductSystemFields>(
      query,
      map,
      filter
    );

    // Disseminate entry preview flag to other components.
    contentEntries.forEach(ce => contentEntryPreview.setStatus(ce.id, ce.preview, ce.accessPasses));

    return contentEntries as unknown as Array<TYPE>;
  } catch (error) {
    throw new Error(`Could not fetch content entries: ${error}`);
  }
}

/**
 * Count the number of content entries that would be fetched by the
 * specified query config.
 *
 * IMPORTANT: The count *includes* entries that would be hidden from
 * the current user. (This function should only be used to implement
 * behind-the-scenes pagination, to improve page response times.)
 */
export async function countContentEntries(
  query: Readonly<CraftQueryBuilderContentEntries>
): Promise<number> {
  try {
    const result = await countEntries(query);
    return result;
  } catch (error) {
    throw new Error(`Could not count content entries: ${error}`);
  }
}
