<template>
  <div class="flex flex-col">
    <plan-price-cell :offer="offer" :force-borders="true" />

    <div
      class="px-fl-sm pt-fl-xl pb-fl-xs border-x border-x-primary text-fl-lg text-primary font-medium"
    >
      You're About to Unlock
    </div>

    <!-- Feature matrix -->
    <div v-for="(cell, i) in featureCells" :key="`feature-${i}`" :class="`${cell.color}`">
      <div class="grid grid-cols-[1fr,minmax(20px,50px)] items-center">
        <div class="pl-fl-sm py-fl-2xs text-fl-lg text-black leading-[1.4]">{{ cell.feature }}</div>
        <div class="h-[50px] justify-center flex items-center">
          <checkmark v-if="cell.check" class="text-primary text-fl-6xl text-bold" />
        </div>
      </div>
    </div>
    <!-- End feature matrix -->

    <plan-select-cell :offer="offer" :submit="submit" :force-borders="true" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {Feature} from '../utils';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';
import PlanPriceCell from './PlanPriceCell.vue';
import PlanSelectCell from './PlanSelectCell.vue';
import Checkmark from './Checkmark.vue';

export default defineComponent({
  components: {
    PlanPriceCell,
    PlanSelectCell,
    Checkmark
  },
  props: {
    offer: {
      type: Object as PropType<Readonly<SignupOffer> | Readonly<AddOnOffer>>,
      required: true
    },
    features: {
      type: Array as PropType<ReadonlyArray<Feature>>,
      required: true
    },
    onComplete: {
      type: Function as PropType<(offer: Readonly<SignupOffer> | Readonly<AddOnOffer>) => void>,
      required: true
    }
  },
  setup(props) {
    const submit = () => {
      props.onComplete(props.offer);
    };
    const featureCells = computed(() => {
      // ### Simplify this!
      /*
        This narrow version of the feature matrix only displays one offer
        at a time, so we need to combine the feature name cells with the 
        checkmark info cells. The `features` prop is assumed to contain
        feature matrix cells only for the offer that is currently on display.
      */
      return (
        props.features
          /* 
            Extract feature names and checkmark info; this will result in
            alternating array items of string and boolean.
          */
          .reduce((result: Array<string | boolean>, cell) => {
            if (cell.feature !== undefined) {
              result.push(cell.feature);
            } else {
              if (cell.offer !== undefined) {
                result.push(cell.check);
              }
            }
            return result;
          }, [])
          // Combine the alternating feature and checkmark items into one object.
          .reduce((result: Array<any>, row: any, i, rows) => {
            if (i % 2 === 1) {
              result.push({feature: rows[i - 1] as string, check: row as boolean});
            }
            return result;
          }, [])
          // Add the color for the feature matrix row.
          .map((row, i) => {
            return {
              ...row,
              color:
                i % 2 === 0
                  ? 'bg-[#e9e9e9] border-x border-x-primary'
                  : 'bg-full-white border-x border-x-primary '
            };
          })
      );
    });
    return {
      submit,
      featureCells
    };
  }
});
</script>
