import {DateTime} from 'luxon';
import {SortKey, SortProperty} from './types';

/**
 * A function that compares two things.
 *
 * It returns either 0, a positive, or a negative number,
 * in the same way as the `compareFn(a, b)` required by
 * Javascript's Array `sort()` function.
 */
export type ComparatorFunc = (a: any, b: any) => number;

/**
 * A comparator function that compares two ISO-8601 dates.
 */
export function dateComparator(a: Readonly<SortProperty>, b: Readonly<SortProperty>) {
  const aVal = DateTime.fromISO(a as string).valueOf();
  const bVal = DateTime.fromISO(b as string).valueOf();
  return aVal - bVal;
}

/**
 * A comparator function that compares two strings using
 * Javascript's `localeCompare()` function.
 */
export function stringComparator(a: Readonly<SortProperty>, b: Readonly<SortProperty>) {
  return (a as string).localeCompare(b as string);
}

/**
 * A comparator function that compares two numbers.
 */
export function numberComparator(a: number, b: number) {
  return a - b;
}

/**
 * Comparator functions for our (supported) sort property types.
 */
const COMPARATORS: {[type: string]: ComparatorFunc} = {
  string: stringComparator,
  date: dateComparator,
  number: numberComparator
};

/**
 * Compare two sort values, taking into account that one or both of
 * the values may be `undefined` or `null`. Undefined values are always
 * sorted after defined values. Two undefined values are considered to be equal.
 */
export function compare(
  a: SortProperty | undefined | null,
  b: SortProperty | undefined | null,
  key: Readonly<SortKey>
): number {
  const comparator = COMPARATORS[key.type];
  if (comparator === undefined) {
    throw new Error(`Unknown sort property type: ${key.type}`);
  }

  // Simplify the checks below. (For the purposes of sorting, null is equivalent to undefined.)
  const aval = a === null ? undefined : a;
  const bval = b === null ? undefined : b;

  /*
    Check if any or both of the values are undefined. Defined values
    are always sorted ahead of undefined values, and two undefined
    values keep their order.
  */
  if (aval === undefined && bval !== undefined) {
    return 1;
  }
  if (aval !== undefined && bval === undefined) {
    return -1;
  }
  if (aval === undefined && bval === undefined) {
    return 0;
  }

  /* 
    We know that both `aval` and `bval` are not undefined
    at this point, but the TypeScript compiler isn't smart
    enough to figure this out. Hence this if statement.
  */
  if (aval !== undefined && bval !== undefined) {
    let result = comparator(aval, bval);
    if (key.order === 'descending') {
      result = -result;
    }
    return result;
  }
  // We'll never end up here.
  return 0;
}
