export interface LocalStorageFlagStoreState {
  flags: {[name: string]: boolean};
}

export const LocalStorageFlagStore = {
  namespaced: true,
  state: {
    flags: {}
  } as LocalStorageFlagStoreState,
  mutations: {
    setFlag(state: LocalStorageFlagStoreState, payload: {key: string; status: boolean}) {
      localStorage.setItem(payload.key, payload.status ? '1' : '0');
      state.flags[payload.key] = payload.status;
    }
  },
  actions: {
    init({commit}: any, payload: {key: string; initStatus: boolean}) {
      const value: string | null = localStorage.getItem(payload.key);
      let status: boolean = false;
      if (value === null) {
        status = payload.initStatus;
      } else {
        status = value === '1';
      }
      commit('setFlag', {key: payload.key, status});
    },
    setFlag({commit}: any, payload: {key: string; status: boolean}) {
      commit('setFlag', payload);
    }
  },
  getters: {
    flag: (state: LocalStorageFlagStoreState) => (key: string) => {
      return state.flags[key];
    }
  }
};
