<template>
  <!-- ??? We use the CSS aspect-ratio property here, which became available in 2021. Is that OK? -->
  <div
    class="w-full p-fl-md aspect-video flex flex-col justify-center items-center text-center text-white text-fl-3xl"
  >
    <div v-if="preview || guest">You need an SBL membership to join.</div>
    <div
      v-else-if="hostUrl !== undefined"
      class="flex flex-col gap-fl-md justify-center items-center"
    >
      <div class="flex flex-col gap-fl-md justify-center items-center">
        <div>This session is hosted outside SBL.</div>
        <standard-button
          :disabled="!webcastOnAir"
          :size="'lg'"
          :color="'primary'"
          :label="'Join here'"
          :url="hostUrl"
          :new-tab="true"
        />
        <div v-if="!webcastOnAir" class="text-fl-sm">
          {{ pendingText }}
        </div>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="instructions !== undefined" class="text-fl-sm" v-html="instructions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {WebcastComposition} from '../../vue-composition/webcast/types';

export default defineComponent({
  components: {
    StandardButton
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    webcast: {type: Object as PropType<Readonly<WebcastComposition>>, required: true}
  },
  setup(props) {
    const hostUrl = computed(() => {
      if (props.webcast.webcast.value !== undefined) {
        const url = props.webcast.webcast.value.webcastExternalLink;
        if (url !== null && url.length > 0) {
          return url;
        }
      }
      return undefined;
    });
    const instructions = computed(() => {
      if (props.webcast.webcast.value !== undefined) {
        const html = props.webcast.webcast.value.webcastExternalLinkInstructions;
        if (html !== null && html.length > 0) {
          return html;
        }
      }
      return undefined;
    });
    const pendingText = computed(() => {
      const DEFAULT_MESSAGE = 'We are getting ready. Please hang on.';
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return DEFAULT_MESSAGE;
      }
      const pendingText = webcast.webcastPendingText;
      if (pendingText !== null && pendingText.length > 0) {
        return pendingText;
      }
      return DEFAULT_MESSAGE;
    });
    const webcastOnAir = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return false;
      }
      return webcast.webcastOnAir;
    });

    return {
      webcastOnAir,
      pendingText,
      hostUrl,
      instructions
    };
  }
});
</script>
