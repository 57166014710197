import {CraftUrl} from '../../backend/craft/craft-types';
import {
  InstrumentationEntry,
  InstrumentationUser
} from '../../backend/instrumentation/instrumentation-types';

export type InstrumentationStoreState = {
  user: InstrumentationUser | undefined;
  pageUrl: CraftUrl | undefined;
  referrer: CraftUrl | undefined;
  entry: InstrumentationEntry | undefined;
};

/**
 * This Vuex store module keeps track of the current user, page, and entry
 * for the instrumentation system. By storing this info in Vuex we avoid
 * having to pass it through properties throughout the entire Vue component
 * hierarchy.
 *
 * Normally, no client code should have to access this store module directly.
 * To send an instrumentation event, use the instrumentation Vue composition
 * which can be found in the `/src/components/vue-composition/instrumentation`
 * folder.
 */
export const InstrumentationStore = {
  namespaced: true,
  state: {
    user: undefined,
    pageUrl: undefined,
    entry: undefined
  } as InstrumentationStoreState,
  mutations: {
    setUser(state: InstrumentationStoreState, user: Readonly<InstrumentationUser>) {
      state.user = user;
    },
    setEntry(state: InstrumentationStoreState, entry: Readonly<InstrumentationEntry>) {
      state.entry = entry;
    },
    setPageUrl(state: InstrumentationStoreState, url: CraftUrl) {
      state.pageUrl = url;
    },
    setReferrer(state: InstrumentationStoreState, url: CraftUrl) {
      state.referrer = url;
    }
  },
  actions: {
    setUser({commit}: any, user: Readonly<InstrumentationUser> | undefined) {
      commit('setUser', user);
    },
    setEntry({commit}: any, entry: Readonly<InstrumentationEntry>) {
      commit('setEntry', entry);
    },
    setPageUrl({commit}: any, url: CraftUrl) {
      commit('setPageUrl', url);
    },
    setReferrer({commit}: any, url: CraftUrl) {
      commit('setReferrer', url);
    }
  },
  getters: {
    user(state: InstrumentationStoreState) {
      return state.user;
    },
    entry(state: InstrumentationStoreState) {
      return state.entry;
    },
    pageUrl(state: InstrumentationStoreState) {
      return state.pageUrl;
    },
    referrer(state: InstrumentationStoreState) {
      return state.referrer;
    }
  }
};
