import {fetchContentEntries} from '../content/content-entry/content-entry-query';
import {contentEntries} from '../content/content-entry/content-entry-query-builder';
import {CraftSlug} from '../craft/craft-types';
import {RICH_TEXT_FIELD} from '../rich-text/rich-text-query';
import {ToolkitLesson} from './toolkit-types';

export async function getToolkit(): Promise<ReadonlyArray<ToolkitLesson>> {
  try {
    const entries = await fetchContentEntries<ToolkitLesson>(
      contentEntries().section(['toolkit']).fields([RICH_TEXT_FIELD])
    );
    return entries;
  } catch (error) {
    throw new Error(`Could not fetch toolkit: ${error}`);
  }
}

export async function getToolkitLesson(slug: CraftSlug): Promise<Readonly<ToolkitLesson>> {
  try {
    const entries = await fetchContentEntries<ToolkitLesson>(
      contentEntries().section(['toolkit']).slug([slug]).fields([RICH_TEXT_FIELD])
    );
    const lesson = entries[0];
    lesson.preview = false; // Ensure SBL Toolkit lessons are always public.
    return lesson;
  } catch (error) {
    throw new Error(`Could not fetch toolkit lesson: ${error}`);
  }
}
