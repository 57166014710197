<template>
  <button
    :key="player.beat.value"
    :disabled="player.mode.value === 'pre-count' || player.mode.value === 'error'"
    :aria-label="'Reset'"
    :class="width"
    @click="onResetClicked"
  >
    <font-awesome-icon :icon="iconBackwardFast" class="w-full h-full text-light" />
  </button>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faBackwardFast} from '@fortawesome/pro-solid-svg-icons/faBackwardFast';
import {Song} from '../../../../backend/song/song-types';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    song: {type: Object as PropType<Readonly<Song>>, required: true},
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    width: {type: String, default: 'w-1/3'}
  },
  setup(props) {
    const iconBackwardFast = computed(() => faBackwardFast);
    const onResetClicked = () => {
      props.player.onReset();
    };

    return {
      iconBackwardFast,
      onResetClicked
    };
  }
};
</script>
