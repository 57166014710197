<template>
  <content-column :width="width">
    <div
      class="w-full grid grid-cols-[repeat(auto-fit,minmax(225px,1fr))] bg-darkest rounded-[0.5em] overflow-hidden"
    >
      <slot name="left"></slot>
      <slot name="right"></slot>
    </div>
  </content-column>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';

export default defineComponent({
  components: {
    ContentColumn
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined}
  },
  setup() {}
});
</script>
