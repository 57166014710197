<template>
  <cluster
    v-if="durationString !== undefined && durationString.length > 0"
    :gap="'gap-1'"
    :wrap="false"
    class="text-mid text-sm"
  >
    <font-awesome-icon class="mr-3xs" :icon="icon"></font-awesome-icon>
    <div>{{ durationString }}</div>
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import {HMSToString, secToHMS} from '../../../../../utils/duration';
import Cluster from '../../../../core/compositions/Cluster.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faClock} from '@fortawesome/pro-regular-svg-icons/faClock';

export default defineComponent({
  components: {
    Cluster,
    FontAwesomeIcon
  },
  props: {
    duration: {type: Number, default: undefined}
  },
  setup(props) {
    const durationString = computed(() => {
      if (props.duration !== undefined) {
        return HMSToString(secToHMS(props.duration));
      }
      return '';
    });
    const icon = computed(() => faClock);
    return {
      durationString,
      icon
    };
  }
});
</script>
<style scoped>
.card-body-duration {
  color: var(--color-mid-shade);
  user-select: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
