<template>
  <button
    v-if="onClick !== undefined || scrollTo !== undefined"
    :disabled="disabled"
    :class="`flex justify-center items-center flex-nowrap group`"
    :title="tooltip"
    :aria-label="tooltip"
    @click.prevent="clicked"
  >
    <div class="flex flex-col justify-center items-center gap-fl-xs">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        aria-hidden="true"
        :class="`text-fl-6xl ${cfg} select-none`"
      ></font-awesome-icon>
      <div
        :class="`max-w-[10ch] text-wrap text-fl-xs text-center ${labelCfg} uppercase select-none`"
      >
        {{ label }}
      </div>
    </div>
  </button>
  <a
    v-if="url !== undefined"
    :href="url"
    :class="`flex justify-center items-center flex-nowrap group`"
    :title="tooltip"
    :aria-label="tooltip"
    :target="target"
  >
    <div class="flex flex-col justify-center items-center gap-fl-xs">
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        aria-hidden="true"
        :class="`text-fl-6xl ${cfg} select-none`"
      ></font-awesome-icon>
      <div
        :class="`max-w-[10ch] text-wrap text-fl-xs text-center ${labelCfg} uppercase select-none`"
      >
        {{ label }}
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {
  ButtonClickCallback,
  ButtonColor,
  getClickFunc,
  getLinkColorGroupHover
} from './implementation/utils';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {type: Boolean, default: false},

    color: {type: String as PropType<ButtonColor>, default: 'light'},
    colorLabel: {type: String as PropType<ButtonColor>, default: 'mid'},

    label: {type: String, required: true},
    tooltip: {type: String, required: true},

    /** FontAwesome icon (positioned left of the label). */
    icon: {type: Object as PropType<Readonly<IconDefinition>>, required: true},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: Number, default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},
    /** If defined, scroll to the HTML element with this id when the button is clicked */
    scrollTo: {type: String, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined},
    /** If true, open the URL in a new tab. */
    newTab: {type: Boolean, default: false}
  },
  setup(props) {
    const clicked = () => {
      if (props.scrollTo !== undefined) {
        const element = document.getElementById(props.scrollTo);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      } else {
        const func = getClickFunc(props.onClick, props.id, props.url);
        func();
      }
    };
    const color = computed(() => getLinkColorGroupHover(props.disabled, props.color));
    const colorLabel = computed(() => getLinkColorGroupHover(props.disabled, props.colorLabel));

    const cfg = computed(() => {
      return `${color.value.textColor} ${color.value.bgColor}`;
    });
    const labelCfg = computed(() => {
      return `${colorLabel.value.textColor} ${colorLabel.value.bgColor}`;
    });

    const target = computed(() => {
      return props.newTab ? '_blank' : undefined;
    });

    return {
      cfg,
      labelCfg,
      clicked,
      target
    };
  }
});
</script>
