<template>
  <page-content>
    <page-section>
      <content-entry-section-header :width="'wide'" :center="false" :title="'History'" />
    </page-section>

    <page-section v-if="items.length === 0">
      <content-column :width="'wide'">
        <p class="text-fl-3xl">
          This page helps you remember what you have seen. All SBL videos you watch will be added
          here.
        </p>
      </content-column>
    </page-section>

    <page-section v-if="items.length > 0">
      <content-column :width="'wide'">
        <link-button :on-click="onClearHistory" :border="true" :label="'Clear history'" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <div v-for="item in pagination.items.value" :key="item.historyId">
        <content-column :width="'wide'">
          <standard-divider class="border-dark" />
        </content-column>
        <history-card
          :guest="guest"
          :history-item="item"
          :on-delete="onDeleteHistory"
          :card-image-width="cardImageWidth"
          class="py-fl-xl"
        />
        <content-column :width="'wide'">
          <standard-divider class="border-dark" />
        </content-column>
      </div>
      <content-column :width="'wide'" class="pt-fl-xl">
        <div v-if="pagination.more.value">
          <standard-button :label="'Show More'" :on-click="pagination.onMore" />
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {onMounted, ref, computed} from 'vue';
import {clearHistory, deleteHistory, getHistory} from '../../../backend/history/history-query';
import {HistoryItem, historyToHistoryItems} from './history-utils';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import HistoryCard from '../../content-entry/representations/history-card/HistoryCard.vue';
import ContentEntrySectionHeader from '../../content-entry/partials/section/ContentEntrySectionHeader.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../core/standard-divider/StandardDivider.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import {usePagination} from '../../vue-composition/pagination/pagination';
import {ImgixImageResponsiveWidth} from '../../core/imgix-image/types';

export default {
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardDivider,
    ContentEntrySectionHeader,
    HistoryCard,
    StandardButton,
    LinkButton
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {
    const items = ref([] as ReadonlyArray<HistoryItem>);
    const loader = useFullScreenLoader();

    const load = async () => {
      loader.setLoading(true);
      const historyEntries = await getHistory();
      items.value = await historyToHistoryItems(historyEntries);
      loader.setLoading(false);
    };
    onMounted(async () => {
      await load();
    });

    const pagination = usePagination<HistoryItem>(computed(() => items.value));

    const onClearHistory = async () => {
      const ok = confirm('Clear your entire history - Are you sure?');
      if (ok) {
        await clearHistory();
        await load();
      }
    };
    const onDeleteHistory = async (item: Readonly<HistoryItem>) => {
      if (item !== undefined) {
        const ok = confirm(`Delete "${item.contentEntry.title}" from your history - Are you sure?`);
        if (ok) {
          await deleteHistory(item.historyId);
          await load();
        }
      }
    };

    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 690,
      md: 350,
      lg: 350,
      xl: 310,
      '2xl': 370
    };

    return {
      items,
      onClearHistory,
      onDeleteHistory,
      pagination,
      cardImageWidth
    };
  }
};
</script>
