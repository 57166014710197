import {computed, Ref, ref} from 'vue';
import {FilterProperty} from '../../../utils/filter/types';
import {
  FilterPropertyFromFilter,
  FilterSetConfig,
  FilterSetComposition,
  FilterSetSelection,
  OnFilterSetSelectionChangeCallback
} from './types';
import {applyFilterSet} from '../../../utils/filter/filter-set';
import {FilterSetFilter} from './types';

/**
 * Return a Vue composition that implements a set of filters (applied in series).
 *
 * To initialise the selections of the filter with a pre-defined set of properties,
 * call the 'add' function on the returned composition.
 *
 * @param config The filter set configuration.
 * @param objects The input objects for the filter.
 */
export function useFilterSet(
  config: ReadonlyArray<FilterSetConfig>,
  objects: Ref<ReadonlyArray<any>>,
  initSelection: Readonly<FilterSetSelection> = {},
  onSelectionChange?: OnFilterSetSelectionChangeCallback
): Readonly<FilterSetComposition> {
  const filters = ref<ReadonlyArray<FilterSetFilter>>([]);

  // Initialisation; create storage for selection for each filter.
  const states: Array<FilterSetFilter> = [];
  config.forEach(cfg => {
    const init = initSelection[cfg.name];
    states.push({
      name: cfg.name,
      selection: new Set<FilterProperty>(init),
      config: cfg.config
    });
  });
  filters.value = states;

  // Store the output of the filters.
  const output = computed(() => {
    const result = applyFilterSet(
      filters.value.map(f => {
        return {selection: f.selection, config: f.config};
      }),
      objects.value
    );
    return result;
  });

  // List of filtered objects.
  const filterOutput = computed(() => {
    return output.value.objects;
  });

  const callback = () => {
    if (onSelectionChange === undefined) {
      return;
    }
    const selection: FilterSetSelection = {};
    filters.value.forEach(f => {
      if (f.selection.size > 0) {
        selection[f.name] = Array.from(f.selection.values());
      }
    });
    onSelectionChange(selection);
  };

  const add = (filterName: string, property: Readonly<FilterProperty>) => {
    const filter = filters.value.find(f => f.name === filterName);
    if (filter === undefined) {
      throw new Error('Unknown filter');
    }
    filter.selection.add(property);
    callback();
  };

  const remove = (filterName: string, property: Readonly<FilterProperty>) => {
    const filter = filters.value.find(f => f.name === filterName);
    if (filter === undefined) {
      throw new Error('Unknown filter');
    }
    filter.selection.delete(property);
    callback();
  };

  const clear = (filterName: string) => {
    const filter = filters.value.find(f => f.name === filterName);
    if (filter === undefined) {
      throw new Error('Unknown filter');
    }
    filter.selection.clear();
    callback();
  };

  const clearAll = () => {
    filters.value.forEach(f => {
      f.selection.clear();
    });
    callback();
  };

  const selection = (filterName: string) => {
    return computed(() => {
      const filter = filters.value.find(f => f.name === filterName);
      if (filter === undefined) {
        return [];
      }
      return Array.from(filter.selection.values()).sort(filter.config.comparator);
    });
  };

  const remainingProperties = (filterName: string) => {
    return computed(() => {
      const idx = filters.value.findIndex(f => f.name === filterName);
      if (idx === -1) {
        return [];
      }
      return output.value.remainingProperties[idx].sort(filters.value[idx].config.comparator);
    });
  };

  const allProperties = (filterName: string) => {
    return computed(() => {
      const idx = filters.value.findIndex(f => f.name === filterName);
      if (idx === -1) {
        return [];
      }
      return output.value.allProperties[idx].sort(filters.value[idx].config.comparator);
    });
  };

  const numberOfSelectedProperties = computed(() => {
    return filters.value.reduce((accum, f) => {
      return accum + f.selection.size;
    }, 0);
  });

  const allSelectedProperties = computed(() => {
    return filters.value.reduce(
      (accum: Array<FilterPropertyFromFilter>, filter: Readonly<FilterSetFilter>) => {
        const properties = Array.from(filter.selection.values()).sort(filter.config.comparator);
        properties.forEach(p => {
          accum.push({
            name: filter.name,
            property: p
          });
        });
        return accum;
      },
      []
    );
  });

  return {
    filters,
    allProperties,
    selection,
    add,
    remove,
    clear,
    clearAll,
    remainingProperties,
    numberOfSelectedProperties,
    allSelectedProperties,
    filterOutput
  };
}
