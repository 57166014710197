<template>
  <!-- Student feedback -->
  <page-section
    :padding-top="'lg'"
    :padding-bottom="'xl'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="bg-white text-darkest"
  >
    <content-column>
      <standard-heading
        class="mb-fl-lg uppercase"
        :size="4"
        :center="true"
        :spot-color="spotColor"
        >{{ feedbackHeading1 }}</standard-heading
      >
      <standard-heading
        v-if="deviceType.screen.value === 'narrow'"
        :size="2"
        :center="true"
        :spot-color="spotColor"
      >
        {{ feedbackHeading2 }}
      </standard-heading>
      <standard-heading v-else :size="1" :center="true" :spot-color="spotColor">
        {{ feedbackHeading2 }}
      </standard-heading>
      <img
        src="https://scottsbasslessons.imgix.net/content/students-banner.jpg?auto=format"
        alt=""
        aria-hidden="true"
        class="mt-fl-lg"
      />
      <div class="flex justify-center">
        <p class="text-center text-fl-3xl w-2/3 my-fl-lg">
          {{ feedbackSummary }}
        </p>
      </div>
      <div class="flex justify-start">
        <div class="w-1/12 mb-fl-sm">
          <fitted-text
            :text-class="'fill-black stroke-0 font-semibold'"
            :text-spans="['&#8220;']"
            text-span-dy="1.0em"
            :text-x="0"
            :text-y="0"
            :viewbox-x="0"
            :viewbox-y="3"
            :viewbox-width="7"
            :viewbox-height="6"
          />
        </div>
      </div>
    </content-column>
    <content-column :class="'text-center'">
      <centered-column>
        <div :class="'md:w-2/3'">
          <flow :gap="7"><student-quotes /></flow>
        </div>
      </centered-column>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import Flow from '../../../core/compositions/Flow.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StudentQuotes from '../partials/StudentQuotes.vue';
import FittedText from '../../../core/fitted-text/FittedText.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {feedbackHeading1, feedbackHeading2, feedbackSummary} from '../utils/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    CenteredColumn,
    Flow,
    StandardHeading,
    StudentQuotes,
    FittedText
  },
  setup() {
    const deviceType = useDeviceType();
    const spotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-white',
      borderHover: 'hover:border-white'
    };

    return {deviceType, spotColor, feedbackHeading1, feedbackHeading2, feedbackSummary};
  }
});
</script>
