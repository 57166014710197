import {Lesson} from './lesson-types';
import {fetchContentEntries} from '../content-entry/content-entry-query';
import {RICH_TEXT_FIELD} from '../../rich-text/rich-text-query';
import {SONG_FIELDS} from '../../song/song-query';
import * as QueryBuilder from '../../craft/query/craft-query-builder';
import {CraftQueryBuilder} from '../../craft/query/craft-query-builder-types';
import {contentEntries} from '../content-entry/content-entry-query-builder';

const LESSON_MODULE_FIELD = QueryBuilder.plainText('lessonModule');
const LESSON_SONGS_FIELD = QueryBuilder.entries('songs').fields(SONG_FIELDS);
export const LESSON_FIELDS: Array<CraftQueryBuilder> = [
  RICH_TEXT_FIELD,
  LESSON_MODULE_FIELD,
  LESSON_SONGS_FIELD
];

export async function getLessonBySlug(slug: string): Promise<Lesson | undefined> {
  try {
    const lessons = await fetchContentEntries<Lesson>(
      contentEntries().section(['lessons']).slug([slug]).fields(LESSON_FIELDS)
    );
    return lessons[0];
  } catch (error) {
    throw new Error(`Could not fetch lesson: ${error}`);
  }
}
