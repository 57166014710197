<template>
  <div></div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import {useStore} from '../../../../store/store';
import {HelpScoutBeaconData} from '../../../../store/modules/help-scout';
import {HelpScoutBeaconConfig} from '../../../../store/modules/help-scout';

/**
  This component is part of the mechanism that presents a HelpScout beacon
  on pages on the SBL website.
*/
export default defineComponent({
  props: {
    guest: {type: String, required: true},
    userId: {type: String, required: true},
    userFriendlyName: {type: String, required: true},
    userEmail: {type: String, required: true},
    beacon: {type: String, required: true}
  },
  setup(props) {
    const store = useStore();

    onMounted(async () => {
      if (props.beacon.length > 0) {
        const beacon = JSON.parse(props.beacon) as Readonly<HelpScoutBeaconConfig>;
        const data: HelpScoutBeaconData = {
          beacon,
          userId: props.userId,
          userFriendlyName: props.userFriendlyName,
          userEmail: props.userEmail,
          guest: props.guest
        };
        store.dispatch('helpScout/setData', data);
        if (beacon.show) {
          store.dispatch('helpScout/show');
        }
      }
    });
  }
});
</script>
