<template>
  <page-content v-if="post !== undefined">
    <page-section v-if="trails">
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header :id="'post-title'">{{ post.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="post" />
          <content-entry-header-post-date :content-entry="post" />
          <content-entry-header-bookmark
            :display="'linkIcon'"
            :guest="guest"
            :content-entry="post"
            :bookmark="bookmark"
          />
          <content-entry-header-campus :guest="guest" :content-entry="post" />
        </cluster>
      </content-column>
    </page-section>

    <page-section>
      <rich-text
        :guest="guest"
        :rich-text="post.richText"
        :width="'narrow'"
        :body-link-primary="true"
        :body-text-color="'text-lightest'"
        :list-text-color="'text-lightest'"
      />
    </page-section>

    <content-entry-resource-list :guest="guest" :content-entry="post" />

    <page-section v-if="guest" :margin-top="'xl'">
      <content-column :width="'normal'">
        <bass-toolkit-cta />
      </content-column>
    </page-section>

    <content-entry-recommendations
      :margin-top="'xl'"
      :guest="guest"
      :content-entry="post"
      :bookmark="bookmark"
      :heading="'Recommended Content'"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType} from 'vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import BassToolkitCta from './BassToolkitCta.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderPostDate from '../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryResourceList from '../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../../content-entry/partials/body/ContentEntryRecommendations.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {Post} from '../../../backend/content/post/post-types';
import {getPostBySlug} from '../../../backend/content/post/post-query';
import RichText from '../../generic/rich-text/RichText.vue';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Cluster,
    BassToolkitCta,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeader,
    ContentEntryHeaderTutors,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    RichText,
    ContentEntryResourceList,
    ContentEntryRecommendations
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const post = ref(undefined as Readonly<Post> | undefined);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);
      post.value = await getPostBySlug(props.slug);
      if (post.value !== undefined) {
        trails.value = await useBreadcrumbTrails(post.value, props.entryBreadcrumbTrails);
        await bookmark.init();
      }
      loader.setLoading(false);
    });
    return {
      post,
      trails,
      bookmark
    };
  }
});
</script>
