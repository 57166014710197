<template>
  <div class="text-fl-2xl text-lightest font-semibold select-none">
    <!-- Display a horizontal rule above the item, if specified. -->
    <hr v-if="displayItem(item) && item.divider === 'above'" class="border-mid my-fl-lg" />

    <!-- If this is the root menu item, just display all children. -->
    <div v-if="displayItem(item) && item.parent === undefined">
      <hierarchical-menu-item
        v-for="(c, i) in item.children"
        :key="i"
        :guest="guest"
        :item="c"
        :on-expand="onExpand"
        :expanded-item="expandedItem"
      />
    </div>

    <!-- This is not the root menu item. -->
    <div v-else-if="displayItem(item)" class="my-fl-sm">
      <!-- If the item has a URL, display it as a title (with link). -->
      <repel v-if="itemUrl !== undefined" class="group">
        <a v-if="item.newTab" :href="itemUrl" target="_blank" class="group-hover:text-primary">{{
          item.title
        }}</a>
        <a v-else :href="itemUrl" class="group-hover:text-primary">{{ item.title }}</a>
        <font-awesome-icon
          v-if="item.external && item.newTab"
          :icon="iconExternal"
          class="group-hover:text-primary"
        ></font-awesome-icon>
      </repel>
      <!-- If not, display the item as a sub-menu selector; also include its children if expanded -->
      <div
        v-else-if="displayItem(item)"
        class="flex justify-between group"
        role="button"
        tabindex="0"
        @click="onClick"
        @keypress.prevent="onClick"
      >
        <div class="group-hover:text-primary">{{ item.title }}</div>
        <div class="group-hover:text-primary">
          <font-awesome-icon :icon="expandedIcon" class="text-mid"></font-awesome-icon>
        </div>
      </div>
      <collapser :status="expanded" :init="false">
        <template #visible>
          <hierarchical-menu-item
            v-for="(c, i) in item.children"
            :key="i"
            :guest="guest"
            :item="c"
            :on-expand="onExpand"
            :expanded-item="expandedItem"
          />
        </template>
      </collapser>
    </div>

    <!-- Display a horizontal rule below the item, if specified. -->
    <hr v-if="displayItem(item) && item.divider === 'below'" class="border-mid my-fl-lg" />
  </div>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import {HierarchicalMenuItem} from './hierarchical-menu-types';
import Repel from '../../core/compositions/Repel.vue';
import Collapser from '../collapser/Collapser.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';

export default {
  components: {
    Repel,
    Collapser,
    FontAwesomeIcon
  },
  props: {
    guest: {type: String, required: true},
    item: {type: Object as PropType<HierarchicalMenuItem>, required: true},

    onExpand: {type: Function, required: true},
    expandedItem: {type: Object as PropType<HierarchicalMenuItem>, default: undefined}
  },
  setup(props) {
    const expanded = computed(() => {
      if (props.item.parent === undefined) {
        // This is the root menu item; it should always be expanded.
        return true;
      }
      if (props.expandedItem === undefined) {
        // If no item is expanded, then this item shouldn't be either.
        return false;
      }
      // Check if we are the currently expanded item?
      // ### This assumes that all titles in the menu are different; maybe replace with some sort of ID check?
      return props.item.title === props.expandedItem.title;
    });

    const expandedIcon = computed(() => {
      return expanded.value ? faAngleUp : faAngleDown;
    });
    const iconExternal = computed(() => faArrowUpRightFromSquare);

    // The user wants to expand this sub-menu; let the hierarchical menu component know.
    const onClick = () => {
      props.onExpand(expanded.value ? undefined : props.item);
    };

    const displayItem = (item: Readonly<HierarchicalMenuItem>) => {
      if (item.display === 'everyone') {
        return true;
      }
      if (props.guest) {
        return item.display === 'guest';
      }
      return item.display === 'loggedIn';
    };

    const itemUrl = computed(() => {
      if (props.item.url === undefined) {
        return undefined;
      }
      if (props.item.addRedirectToUrl !== undefined) {
        return props.item.addRedirectToUrl
          ? `${props.item.url}?redirect=${window.location.href}`
          : props.item.url;
      }
      return props.item.url;
    });

    return {
      expandedIcon,
      iconExternal,
      onClick,
      displayItem,
      expanded,
      itemUrl
    };
  }
};
</script>
