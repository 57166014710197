<template>
  <button
    :key="player.beat.value"
    :disabled="player.mode.value === 'pre-count' || player.mode.value === 'error'"
    :aria-label="player.mode.value === 'playing' ? 'Pause' : 'Play'"
    :title="player.mode.value === 'playing' ? 'Pause' : 'Play'"
    class="w-full h-full"
    @click="onPlayPauseClicked"
  >
    <svg viewBox="0 0 30 30" class="w-full h-full">
      <g>
        <!-- play -->
        <polygon
          v-if="player.mode.value !== 'playing'"
          fill="white"
          stroke="white"
          stroke-width="6"
          stroke-linejoin="round"
          stroke-linecap="round"
          points="3,3 3,27 25,15"
        ></polygon>
        <g v-else>
          <!-- pause -->
          <polygon
            fill="white"
            stroke="white"
            stroke-width="6"
            points="6,3 6,27 9,27 9,3"
            stroke-linejoin="round"
            stroke-linecap="round"
          ></polygon>
          <polygon
            fill="white"
            stroke="white"
            stroke-width="6"
            points="21,3 21,27 24,27 24,3"
            stroke-linejoin="round"
            stroke-linecap="round"
          ></polygon>
        </g>
      </g>
    </svg>
  </button>
</template>

<script lang="ts">
import {PropType, computed, onBeforeUnmount} from 'vue';
import {faPause} from '@fortawesome/pro-solid-svg-icons/faPause';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {useSync} from '../../../vue-composition/sync/sync';
import {Song} from '../../../../backend/song/song-types';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';

export default {
  components: {},
  props: {
    song: {type: Object as PropType<Readonly<Song>>, required: true},
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true}
  },
  setup(props) {
    const playPauseButtonIcon = computed(() => {
      return props.player.mode.value === 'playing' ? faPause : faPlay;
    });

    const sync = useSync('media', props.song.id, async () => {
      props.player.onStop();
    });
    const onPlayPauseClicked = () => {
      sync.lock();
      props.player.onPlayPause();
    };
    onBeforeUnmount(() => {
      sync.onBeforeUnmount();
    });

    return {
      playPauseButtonIcon,
      onPlayPauseClicked
    };
  }
};
</script>
