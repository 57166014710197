import {INSTRUMENTATION_API_KEY, INSTRUMENTATION_URL} from '../../settings';
import {CraftUrl} from '../craft/craft-types';
import {INSTRUMENTATION_FORMAT_VERSION} from './instrumentation-format-version';
import {
  InstrumentationEntry,
  InstrumentationEvent,
  InstrumentationUser
} from './instrumentation-types';

/**
 * This function sends an instrumentation event to our data backend via a POST request.
 *
 * NOTE: Do not call this function directly to send events! Instead, do the following:
 *
 * First, note that events can only be sent from Vue components. When the component is
 * initialised (`setup()` or `onMounted()`), call `useInstrumentation()` (in the
 * `src/components/vue-composition/instrumentation` folder) to get hold of the
 * instrumentation Vue composition. Then use the functionality in that composition to
 * send the event.
 *
 * This method assumes that the INSTRUMENTATION_API_KEY and INSTRUMENTATION_URL
 * values are set in the .env file. If any of them are not set, calling this
 * function has no effect.
 */
export async function instrumentationEvent(
  user: Readonly<InstrumentationUser> | undefined,
  entry: Readonly<InstrumentationEntry> | undefined,
  pageUrl: CraftUrl,
  referrer: CraftUrl | undefined,
  event: Readonly<InstrumentationEvent>
): Promise<void> {
  /*
    Allow the frontend code to read values from the .env file.
    See https://www.npmjs.com/package/dotenv.
  */
  const key = INSTRUMENTATION_API_KEY;
  const url = INSTRUMENTATION_URL;
  if (key === undefined || url === undefined) {
    return;
  }

  /*
    ### This payload is formatted according to (sort of) what Amplitude wants,
    but it's not great that the event properties are flattened inside an "eventProperties"
    object, since that might overwrite other properties in there (e.g., referrer or page
    URL). We should re-examine this at some point and change into a more appropriate
    format (that can also be verified against a TypeScript type).
  */
  const payload = {
    formatVersion: INSTRUMENTATION_FORMAT_VERSION,

    userId: user ? user.userId : undefined,
    eventType: event.type,

    userProperties: {
      userIsGuest: user === undefined,
      userIsAdmin: user ? user.userIsAdmin : undefined,
      username: user ? user.username : undefined,
      userAccessPasses: user ? user.accessPasses : undefined
    },

    timestamp: Date.now(),

    eventProperties: {
      pageUrl,
      referrer,

      entryId: entry ? entry.entryId : undefined,
      entrySlug: entry ? entry.entrySlug : undefined,
      entrySectionHandle: entry ? entry.entrySectionHandle : undefined,
      entryBreadcrumbTrails: entry ? entry.entryBreadcrumbTrails : undefined,

      data: event.properties
    }
  };

  // Send event through HTTP POST request
  const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': key,
    Accept: 'application/json'
  };
  const method = 'POST';
  const body = JSON.stringify(payload);
  const fetchOptions = {method, headers, body};
  try {
    await fetch(url, fetchOptions);
  } catch {
    // ??? Do we care about the error? We *could* store info about errors and pass that
    // to a separate endpoint, to track instrumentation errors. But it's just as likely
    // that we'd spot such errors by observing dips in the incoming data.
  }
}
