export interface FullScreenLoaderStoreState {
  activeLoaders: number;
  message: string | undefined;
}

export const FullScreenLoaderStore = {
  namespaced: true,
  state: {
    activeLoaders: 0,
    message: undefined
  } as FullScreenLoaderStoreState,
  mutations: {
    setLoading(state: any, payload: {status: boolean; message?: string}) {
      if (payload.status) {
        state.activeLoaders = state.activeLoaders + 1;
        state.message = payload.message;
      } else {
        if (state.activeLoaders > 0) {
          state.activeLoaders = state.activeLoaders - 1;
        }
      }
    }
  },
  actions: {
    setLoading({commit}: any, payload: {status: boolean; message?: string}) {
      commit('setLoading', payload);
    }
  },
  getters: {
    loading(state: any) {
      return state.activeLoaders > 0;
    },
    message(state: any) {
      return state.message;
    }
  }
};
