import {CraftId} from '../craft/craft-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {ThankYouPage} from './thank-you-page-types';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {RICH_TEXT_FIELD} from '../rich-text/rich-text-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const THANK_YOU_PAGE_FIELDS = [
  QueryBuilder.plainText('thankYouPageTitle'),
  QueryBuilder.plainText('thankYouPageContinueButtonLabel'),
  QueryBuilder.url('thankYouPageContinueUrl'),
  IMAGE_COLLECTION_FIELD,
  RICH_TEXT_FIELD
];

export async function getThankYouPageBySlug(
  slug: string
): Promise<Readonly<ThankYouPage> | undefined> {
  const result = await fetchEntries<ThankYouPage>(
    QueryBuilder.entries().section(['thankYouPages']).slug([slug]).fields(THANK_YOU_PAGE_FIELDS)
  );
  return result[0];
}

export async function getThankYouPageById(
  entryId: CraftId
): Promise<Readonly<ThankYouPage> | undefined> {
  const result = await fetchEntries<ThankYouPage>(
    QueryBuilder.entries().section(['thankYouPages']).id([entryId]).fields(THANK_YOU_PAGE_FIELDS)
  );
  return result[0];
}
