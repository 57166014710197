<template>
  <content-entry-grid
    v-if="layout === 'cards'"
    :guest="guest"
    :completed="completed"
    :progress="progress"
    :duration="duration"
    :unlocked="unlocked"
    :spot-color="spotColor"
    :bookmark="bookmark"
    :pagination="pagination"
    :disabled="disabled"
    :card-image-width="cardImageWidth"
    :imgix-params="imgixParams"
  />
  <content-entry-inline-cards
    v-else
    :guest="guest"
    :completed="completed"
    :progress="progress"
    :duration="duration"
    :unlocked="unlocked"
    :spot-color="spotColor"
    :bookmark="bookmark"
    :display-post-date="displayPostDate"
    :pagination="pagination"
    :width="width"
    :justify="justify"
    :disabled="disabled"
    :card-image-width="cardImageWidth"
    :imgix-params="imgixParams"
  />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ContentEntryInlineCards from './ContentEntryInlineCards.vue';
import ContentEntryGrid from './ContentEntryGrid.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, DisplayConfig, DurationMap, UnlockedMap} from './../types';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {usePagination} from '../../../vue-composition/pagination/pagination';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    ContentEntryGrid,
    ContentEntryInlineCards
  },
  props: {
    guest: {type: String, required: true},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, default: undefined},
    config: {type: Object as PropType<Readonly<DisplayConfig> | undefined>, default: undefined},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    layout: {
      type: String as PropType<'cards' | 'list'>,
      default: 'cards'
    },
    displayPostDate: {type: Boolean, default: false},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    textSize: {type: String, default: undefined},
    iconSize: {type: String, default: undefined},
    paginate: {type: Boolean, default: false},
    disabled: {type: Boolean, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: undefined}
  },
  setup(props) {
    const pagination = usePagination<ContentEntry>(
      computed(() => {
        if (props.contentEntries !== undefined) {
          return props.contentEntries;
        }
        return [];
      })
    );
    return {pagination};
  }
});
</script>
