export type LocalStorageTimestampComposition = {
  /**
   * Store the current time (Date.now()) as the local storage timestamp.
   */
  set: () => void;
  /**
   * Return the local storage timestamp (Unix Epoch), or undefined if it does not exist.
   */
  get: () => number | undefined;

  /**
   * Return the difference (in ms) between the timestamp in local storage and the current
   * browser time (Date.now()), or undefined if there is no timestamp in the local storage.
   */
  diff: () => number | undefined;
};

/**
 * This composition is a utility for storing a timestamp in the browser's local storage.
 * NOTE: The functions in the composition are NOT reactive.
 *
 * @param key The local storage key - must be unique!
 */
export function useLocalStorageTimestamp(key: string): Readonly<LocalStorageTimestampComposition> {
  const set = () => {
    const now = Date.now();
    window.localStorage.setItem(key, now.toString());
  };
  const get = () => {
    const timestampStr = window.localStorage.getItem(key);
    if (timestampStr !== null) {
      return parseInt(timestampStr);
    }
    return undefined;
  };
  const diff = () => {
    const timestamp = get();
    if (timestamp === undefined) {
      return undefined;
    }
    const now = Date.now();
    return now - timestamp;
  };

  return {
    set,
    get,
    diff
  };
}
