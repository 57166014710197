<template>
  <div v-if="mostRecentStudentFocus.length > 0">
    <page-section :margin-top="'xl'">
      <page-section-header :width="'wide'" :heading="'Latest Student Focus Sessions'" />
      <content-column :width="'wide'" class="mt-fl-3xs">
        <repel>
          <p class="text-fl-3xl text-mid font-semibold">
            Get help, direction & feedback on your playing.
          </p>
          <cluster :gap="'gap-x-fl-xl'">
            <link-icon-button
              v-if="!guest"
              :url="'https://campus.scottsbasslessons.com/topic/12349-submit-a-video-for-the-student-review-here/'"
              :new-tab="true"
              :size="'lg'"
              :color="'light'"
              :label="'Submit a Video'"
              :icon="iconArrowRight"
              :flip="true"
            />
            <link-button :size="'lg'" :label="'View All'" :url="'/shows/student-focus'" />
          </cluster>
        </repel>
      </content-column>
    </page-section>
    <dashboard-reel :guest="guest" :bookmark="bookmark" :content-entries="mostRecentStudentFocus" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import DashboardReel from './DashboardReel.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentColumn,
    LinkIconButton,
    LinkButton,
    Repel,
    Cluster,
    DashboardReel
  },
  props: {
    guest: {type: String, required: true},
    mostRecentStudentFocus: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<BookmarkComposition>, required: true}
  },
  setup() {
    const iconArrowRight = computed(() => faArrowRight);
    return {
      iconArrowRight
    };
  }
});
</script>
