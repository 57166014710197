<template>
  <a v-if="onClick === undefined" :href="url" class="relative w-full h-full">
    <imgix-image
      class="w-full h-full rounded-full opacity-80 hover:opacity-100"
      :config="config"
      aria-hidden="true"
    />
  </a>
  <button
    v-else
    class="relative w-full h-full"
    :title="tooltip"
    :aria-label="tooltip"
    @click="clicked"
  >
    <imgix-image
      class="w-full h-full rounded-full opacity-80 hover:opacity-100"
      :config="config"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {redirectWithHistory} from '../../../utils/url';
import ImgixImage from '../../core/imgix-image/ImgixImage.vue';
import {ImgixImageConfig} from '../imgix-image/types';

export default defineComponent({
  components: {
    ImgixImage
  },
  props: {
    tooltip: {type: String, required: true},
    config: {type: Object as PropType<ImgixImageConfig>, required: true},
    onClick: {type: Function as PropType<() => void>, default: undefined},
    url: {type: String as PropType<CraftUrl>, default: undefined}
  },
  setup(props) {
    const clicked = () => {
      if (props.onClick !== undefined) {
        props.onClick();
      }
      if (props.url !== undefined && props.url !== null) {
        redirectWithHistory(props.url);
      }
    };

    return {
      clicked
    };
  }
});
</script>
