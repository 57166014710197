<template>
  <!-- Values -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'">
    <content-column :width="contentColumnWidth">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <standard-heading :size="1" :center="true" v-html="valuesHeading"></standard-heading>
    </content-column>
  </page-section>

  <page-section :padding-top="'lg'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <margins>
      <content-area :start="contentStart1" :span="contentSpan1">
        <img
          src="https://scottsbasslessons.imgix.net/content/ambient-bass-player-bw.jpg?auto=format"
          alt=""
          aria-hidden="true"
          loading="lazy"
        />
      </content-area>
      <content-area :start="8" :span="contentSpan2" :class="'text-fl-3xl'">
        <flow>
          <p>
            {{ valuesText1 }}
          </p>
          <p>
            {{ valuesText2 }}
          </p>
        </flow>
      </content-area>
    </margins>
  </page-section>

  <!-- Vision & mission -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <margins>
      <content-area :start="contentStart1" :span="contentSpan1">
        <standard-heading :size="4" class="mb-fl-sm">{{ visionSubHeading }}</standard-heading>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <standard-heading :size="1" v-html="visionHeading"></standard-heading>
      </content-area>
      <content-area :start="8" :span="contentSpan2">
        <mission-list />
      </content-area>
    </margins>
  </page-section>

  <!-- Roots -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <margins>
      <content-area :start="contentStart1">
        <standard-heading :size="4" class="mb-fl-sm">{{ rootsSubHeading }}</standard-heading>
        <standard-heading :size="1">{{ rootsHeading }}</standard-heading>
      </content-area>
    </margins>
  </page-section>

  <page-section
    :padding-top="'lg'"
    :padding-bottom="'lg'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="text-white"
  >
    <margins>
      <content-area :start="contentStart1" :span="contentSpan1">
        <img
          src="https://scottsbasslessons.imgix.net/content/scott2010-2.jpg?auto=format"
          alt=""
          aria-hidden="true"
          loading="lazy"
        />
      </content-area>
      <content-area :start="8" :span="contentSpan2" :class="'text-fl-3xl'">
        <flow>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="rootsText1"></p>
          <p>
            {{ rootsText2 }}
          </p>
          <p>
            {{ rootsText3 }}
          </p>
        </flow>
      </content-area>
    </margins>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import Flow from '../../../core/compositions/Flow.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import MissionList from '../partials/MissionList.vue';
import {
  valuesHeading,
  valuesText1,
  valuesText2,
  visionSubHeading,
  visionHeading,
  rootsSubHeading,
  rootsHeading,
  rootsText1,
  rootsText2,
  rootsText3
} from '../utils/utils';

export default defineComponent({
  components: {
    ContentArea,
    PageSection,
    ContentColumn,
    Flow,
    Margins,
    StandardHeading,
    MissionList
  },
  setup() {
    const deviceType = useDeviceType();
    const contentColumnWidth = computed(() => {
      return deviceType.largerThanOrEqualTo('xl') ? 'normal' : 'medium';
    });
    const contentStart1 = computed(() => {
      return deviceType.largerThanOrEqualTo('xl') ? 2 : 1;
    });
    const contentSpan1 = computed(() => {
      return deviceType.largerThanOrEqualTo('xl') ? 5 : 6;
    });
    const contentSpan2 = computed(() => {
      return deviceType.largerThanOrEqualTo('xl') ? 4 : 5;
    });

    return {
      contentColumnWidth,
      contentStart1,
      contentSpan1,
      contentSpan2,
      valuesHeading,
      valuesText1,
      valuesText2,
      visionSubHeading,
      visionHeading,
      rootsSubHeading,
      rootsHeading,
      rootsText1,
      rootsText2,
      rootsText3
    };
  }
});
</script>
