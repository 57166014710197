export type HelpScoutBeaconConfig = {
  beaconId: string;
  show: boolean;
  signature: string;
};

export type HelpScoutBeaconData = {
  beacon: HelpScoutBeaconConfig;
  userId: string;
  userFriendlyName: string;
  userEmail: string;
  guest: string;
};

export interface HelpScoutStoreState {
  data: HelpScoutBeaconData | undefined;
  ready: boolean;
}

export const HelpScoutStore = {
  namespaced: true,
  state: {
    data: undefined,
    ready: false
  } as HelpScoutStoreState,
  mutations: {
    setData(state: any, beacon: Readonly<HelpScoutBeaconData>) {
      state.data = beacon;
    },
    setReady(state: any, status: boolean) {
      state.ready = status;
    }
  },
  actions: {
    setData({commit}: any, data: Readonly<HelpScoutBeaconData>) {
      commit('setData', data);
    },
    async show({state, commit}: any) {
      const data = state.data as Readonly<HelpScoutBeaconData>;
      Beacon('init', data.beacon.beaconId);
      Beacon('on', 'ready', () => {
        if (state.data !== undefined) {
          if (!data.guest) {
            Beacon('identify', {
              userId: data.userId,
              name: data.userFriendlyName,
              email: data.userEmail,
              signature: data.beacon.signature
            });
          }
          commit('setReady', true);
        }
      });
    }
  },
  getters: {
    data(state: Readonly<HelpScoutStoreState>) {
      return state.data;
    },
    ready(state: Readonly<HelpScoutStoreState>) {
      return state.ready;
    }
  }
};
