<template>
  <page-content v-if="offer !== undefined">
    <page-section>
      <content-column :width="'narrow'">
        <div>Resubscribe to</div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'narrow'">
        <content-entry-header>{{ offer.offerTitle }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'narrow'">
        <div class="text-fl-3xl text-pp1 font-semibold">{{ price }}</div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'narrow'">
        <ul class="list-disc list-inside">
          <li v-for="feature in features" :key="feature">{{ feature }}</li>
        </ul>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'narrow'">
        <div class="text-mid">All prices are in USD.</div>
      </content-column>
    </page-section>

    <payment
      v-if="amount !== undefined"
      :width="'narrow'"
      :error="error"
      :email="userEmail"
      :amount="amount"
      :purchase-label="'Resubscribe'"
      :on-cancel="onCancel"
      :on-purchase="onPurchase"
      :recaptcha-action="'payment_resubscribe'"
    />
    <backend-error :width="'narrow'" :error="error" />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import Payment from '../../../../offer/payment/Payment.vue';
import BackendError from '../../../../generic/backend-error/BackendError.vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {
  getCustomerLifetimeEventData,
  redirectNoHistory,
  redirectWithHistory
} from '../../../../../utils/url';
import {AccessPassStatus} from '../../../../../backend/access-pass/access-pass-types';
import {resubscribe} from '../../../../../backend/signup/checkout-query';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import {ResubscribeOffer} from '../../../../../backend/offer/resubscribe-offer-types';
import {getResubscribeOfferById} from '../../../../../backend/offer/resubscribe-offer-query';
import {useInstrumentation} from '../../../../vue-composition/instrumentation/instrumentation';
import {getResubscribeOffersForCurrentUser} from '../../../../../backend/offer/resubscribe-offer-utils';
import {getAccessPassesForCurrentUser} from '../../../../../backend/access-pass/access-pass-query';
import {BraintreeCustomer, BraintreeNonce} from '../../../../offer/payment/types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    Payment,
    BackendError
  },
  props: {
    offerId: {type: String, required: true},
    userEmail: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const error = useBackendError(true);
    const loader = useFullScreenLoader();
    const instrumentation = useInstrumentation();

    const offer = ref<Readonly<ResubscribeOffer> | undefined>(undefined);
    const destination = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerDestination[0];
    });
    const amount = computed(() => {
      if (destination.value === undefined) {
        return undefined;
      }
      return destination.value.accessPassAmount;
    });
    const userPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);
    const features = computed(() => {
      if (offer.value === undefined) {
        return [];
      }
      if (offer.value.offerFeatures !== null && offer.value.offerFeatures.length > 0) {
        return offer.value.offerFeatures.split('\n');
      }
      return [];
    });
    const price = computed(() => {
      if (destination.value === undefined) {
        return undefined;
      }
      const amt = destination.value.accessPassAmount;
      if (destination.value.accessPassType === 'subscription') {
        if (destination.value.accessPassRecurrence === 'annual') {
          const m = (amt / 12).toFixed(2);
          return `$${m} per month (billed as $${amt})`;
        }
        return `$${amt} per month`;
      }
      if (destination.value.accessPassType === 'singlePurchase') {
        return `$${amt}`;
      }
      return 'Free!';
    });

    onMounted(async () => {
      loader.setLoading(true, 'Loading offer details');
      offer.value = await getResubscribeOfferById(props.offerId);
      if (offer.value === undefined) {
        redirectNoHistory('/account');
        return;
      }
      loader.setLoading(false);

      loader.setLoading(true, 'Loading your subscription');
      userPasses.value = await getAccessPassesForCurrentUser();
      loader.setLoading(false);

      loader.setLoading(true, 'Loading your subscription');
      const offers = await getResubscribeOffersForCurrentUser(
        ['account', 'paymentPage'],
        props.userDateCreated,
        props.serverDate,
        [props.offerId]
      );
      loader.setLoading(false);
      if (offers.length !== 1) {
        loader.setLoading(true);
        redirectNoHistory('/account');
        return;
      }
    });

    const onPurchase = async (nonce: Readonly<BraintreeNonce>, _: Readonly<BraintreeCustomer>) => {
      if (
        offer.value === undefined ||
        destination.value === undefined ||
        userPasses.value === undefined
      ) {
        return;
      }

      // Carry out the upgrade.
      loader.setLoading(true, 'Updating your account. Please do not navigate away from this page.');
      await resubscribe(
        destination.value.slug,
        offer.value.offerDeletePasses.map(pass => pass.slug),
        nonce.nonce,
        nonce.deviceData,
        getCustomerLifetimeEventData()
      ).catch(error.catcher);
      loader.setLoading(false);

      if (!error.error()) {
        loader.setLoading(true);
        await instrumentation.sendEvent({
          type: 'user',
          properties: {
            action: 'resubscribe',
            email: props.userEmail,
            accessPass: destination.value.slug
          }
        });
        redirectWithHistory('/account?result=resubscribeSuccess');
      }
    };

    const onCancel = () => {
      redirectWithHistory('/account');
    };

    return {
      error,
      loader,
      onPurchase,
      onCancel,
      offer,
      features,
      price,
      amount
    };
  }
});
</script>
