<template>
  <standard-heading :size="size" class="mb-fl-xs">
    {{ heading.promotionCopyHeadingText }}
  </standard-heading>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PageSectionMargin} from '../../../core/page/PageSection.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {
  PromotionCopyBlock,
  PromotionCopyHeading
} from '../../../../backend/promotion/promotion-types';

export default defineComponent({
  components: {
    StandardHeading
  },
  props: {
    promotionCopyHeading: {type: Object as PropType<PromotionCopyBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'sm'}
  },
  setup(props) {
    const heading = computed(() => {
      return props.promotionCopyHeading as Readonly<PromotionCopyHeading>;
    });
    const size = computed(() => {
      switch (heading.value.promotionCopyHeadingSize) {
        default:
        case 'large':
          return 2;
        case 'small':
          return 3;
      }
    });
    return {
      heading,
      size
    };
  }
});
</script>
