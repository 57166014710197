<template>
  <content-column :width="'wide'">
    <div class="relative w-full box-border h-20 flex flex-col justify-center">
      <repel :wrap="false" :gap="'gap-x-fl-lg'">
        <!-- Navigation menu -->
        <cluster class="cursor-pointer" :wrap="false" :gap="'gap-fl-sm'">
          <div class="relative w-8 h-8 flex justify-center items-center">
            <icon-button
              :icon="iconBars"
              :on-click="showNavigationMenu"
              :tooltip="'Show menu'"
              :expand="true"
              :color="'white'"
            />
          </div>

          <!-- SBL logo -->
          <div class="flex justify-center items-center gap-x-1 flex-nowrap">
            <div class="relative w-14 h-14">
              <a href="/">
                <logo :display-type="'solid'" />
              </a>
            </div>
          </div>
        </cluster>

        <cluster :gap="'gap-x-fl-lg'" class="justify-self-end">
          <!-- Login button -->
          <link-button
            v-if="deviceType.viewportWidth.value > 450"
            :url="loginUrl"
            :label="'Login'"
          />

          <!-- Trial button -->
          <trial-button :font-size="'text-base'" />
        </cluster>
      </repel>
    </div>
  </content-column>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import IconButton from '../../../core/button/IconButton.vue';
import Logo from '../../../generic/logo/Logo.vue';
import TrialButton from '../trial-button/TrialButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import {faBars} from '@fortawesome/pro-thin-svg-icons/faBars';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    ContentColumn,
    Repel,
    Cluster,
    IconButton,
    LinkButton,
    Logo,
    TrialButton
  },
  props: {
    showNavigationMenu: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup() {
    const iconBars = computed(() => faBars);
    const iconArrow = computed(() => faArrowRight);
    const deviceType = useDeviceType();
    const loginUrl = computed(() => `/login?redirect=${window.location.href}`);
    return {
      iconBars,
      iconArrow,
      deviceType,
      loginUrl
    };
  }
});
</script>
