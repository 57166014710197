<template>
  <div>
    <page-section :margin-top="'xs'">
      <margins>
        <content-area class="flex flex-col items-center gap-fl-sm">
          <standard-heading :size="3">{{ lesson.title }}</standard-heading>
          <cluster>
            <content-entry-header-tutors :content-entry="lesson" :size="'sm'" />
            <content-entry-header-completed
              :progress="progress.progress.value"
              :spot-color="spotColor"
            />
            <content-entry-header-beginner-journey :trails="trails" />
          </cluster>
        </content-area>
      </margins>
    </page-section>

    <page-section :margin-top="'sm'" :margin-bottom="'sm'">
      <standard-divider :dashed="false" class="border-dark" />
    </page-section>

    <page-section :margin-top="'xs'">
      <margins>
        <content-area>
          <repel :gap="'gap-fl-lg'">
            <content-entry-header-campus :guest="guest" :content-entry="lesson" />
            <content-entry-header-bookmark
              :guest="guest"
              :content-entry="lesson"
              :icon-only="true"
              :bookmark="bookmark"
            />
            <standard-divider :orientation="'vertical'" class="border-dark" />
            <content-entry-header-progress-toggle
              :guest="guest"
              :content-entry="lesson"
              :progress="progress"
              :color="progress.progress.value === 100 ? 'dark' : 'lightest'"
            />
          </repel>
        </content-area>
      </margins>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-column>
        <div class="flex justify-between gap-x-fl-lg">
          <lesson-nav-button
            v-if="previousUrl !== undefined"
            :direction="'prev'"
            :url="previousUrl"
            :type="'button'"
            :size="'2xl'"
          />
          <div v-else class="w-full"></div>
          <lesson-nav-button
            v-if="nextUrl !== undefined"
            :direction="'next'"
            :url="nextUrl"
            :type="'button'"
            :size="'2xl'"
          />
          <div v-else class="w-full"></div>
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import PageSection from '../../../core/page/PageSection.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderCompleted from '../../../content-entry/partials/header/metadata/ContentEntryHeaderCompleted.vue';
import ContentEntryHeaderProgressToggle from '../../../content-entry/partials/header/actions/ContentEntryHeaderProgressToggle.vue';
import LessonNavButton from './LessonNavButton.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {ColorConfig} from '../../../core/color-config';
import {Lesson} from '../../../../backend/content/lesson/lesson-types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';

export default defineComponent({
  components: {
    Margins,
    ContentArea,
    PageSection,
    ContentColumn,
    Repel,
    Cluster,
    StandardHeading,
    StandardDivider,
    ContentEntryHeaderTutors,
    ContentEntryHeaderBeginnerJourney,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    ContentEntryHeaderProgressToggle,
    ContentEntryHeaderCompleted,
    LessonNavButton
  },
  props: {
    guest: {type: String, required: true},
    lesson: {type: Object as PropType<Readonly<Lesson>>, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true},
    previousUrl: {type: String as PropType<CraftUrl>, default: undefined},
    nextUrl: {type: String as PropType<CraftUrl>, default: undefined}
  },
  setup() {}
});
</script>
