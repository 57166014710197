<template>
  <div class="bg-darkest rounded-[0.5em] overflow-hidden">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  setup() {}
});
</script>
