<template>
  <div id="resume-button">
    <standard-button
      v-if="!guest && !preview && progress.progress.value < 100 && resume.url.value !== undefined"
      :label="resume.label.value"
      :url="resume.url.value"
      :size="size"
      :expand="expand"
    />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {ProgressComposition} from '../../../../vue-composition/progress/types';
import {useResume} from '../../../../vue-composition/progress/progress';
import {ButtonSize} from '../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true},
    label: {type: String, required: true},
    size: {type: String as PropType<ButtonSize>, required: true},
    expand: {type: Boolean, default: false}
  },
  setup(props) {
    const resume = useResume(props.progress, props.contentEntry, props.label);
    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });
    return {
      resume,
      preview
    };
  }
});
</script>
