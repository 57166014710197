import * as QueryBuilder from '../craft/query/craft-query-builder';
import {CraftQueryBuilder} from '../craft/query/craft-query-builder-types';

const SONG_BARS_FIELD = QueryBuilder.plainText('songBars').required();
const SONG_TEMPO_FIELD = QueryBuilder.plainText('songTempo').required();
const SONG_BEATS_PER_BAR_FIELD = QueryBuilder.plainText('songBeatsPerBar').required();
const SONG_PRECOUNT_BARS_FIELD = QueryBuilder.plainText('songPrecountBars').required();
const SONG_SPEED_VARIATIONS_FIELD = QueryBuilder.matrix('songSpeedVariations').blocks([
  QueryBuilder.block('speedVariation').fields([
    QueryBuilder.dropdown('speedVariationTempo', ['100', '90', '80', '70', '60']),
    QueryBuilder.dropdown('speedVariationStemType', ['withBass', 'withoutBass']),
    QueryBuilder.url('speedVariationWebmUrl'),
    QueryBuilder.url('speedVariationMp3Url')
  ])
]);

export const SONG_FIELDS: ReadonlyArray<CraftQueryBuilder> = [
  SONG_BARS_FIELD,
  SONG_TEMPO_FIELD,
  SONG_BEATS_PER_BAR_FIELD,
  SONG_PRECOUNT_BARS_FIELD,
  SONG_SPEED_VARIATIONS_FIELD
];
