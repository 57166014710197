import {fetchEntries} from '../craft/entry/entry-query';
import {AddOnOffer} from './addon-offer-types';
import {ACCESS_PASS_FIELDS} from '../access-pass/access-pass-query';
import {OFFER_CONDITIONS_FIELD} from './offer-conditions-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const ADD_ON_OFFER_FIELDS = [
  QueryBuilder.plainText('offerTitle'),
  QueryBuilder.lightswitch('offerIsActive').required(),
  QueryBuilder.categories('offerDestination').required().fields(ACCESS_PASS_FIELDS),
  QueryBuilder.categories('offerBonuses').fields(ACCESS_PASS_FIELDS),
  QueryBuilder.number('offerTrialDuration').required(),
  QueryBuilder.plainText('offerFeatures'),
  QueryBuilder.lightswitch('offerHighlight'),
  QueryBuilder.plainText('offerSelectionLabel'),
  QueryBuilder.entries('offerThankYouPage'),
  QueryBuilder.url('offerRejectUrl'),
  OFFER_CONDITIONS_FIELD,
  IMAGE_COLLECTION_FIELD
];

export async function getAllAddOnOffers(): Promise<ReadonlyArray<AddOnOffer>> {
  const result = await fetchEntries<AddOnOffer>(
    QueryBuilder.entries().section(['addOnOffers']).fields(ADD_ON_OFFER_FIELDS)
  );
  return result;
}
