<template>
  <cluster :wrap="false" class="w-full">
    <font-awesome-icon class="mr-fl-2xs text-fl-4xl text-light" :icon="icon"></font-awesome-icon>
    <input
      :id="id"
      ref="inputElement"
      v-model="value"
      class="w-full p-fl-sm bg-transparent text-light placeholder:text-light border border-light rounded"
      type="search"
      :placeholder="placeholder"
      :aria-label="label"
      :title="tooltip"
      @keyup.enter="onEnter"
    />
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent, ref, onMounted} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import Cluster from '../../core/compositions/Cluster.vue';

export default defineComponent({
  components: {
    FontAwesomeIcon,
    Cluster
  },
  props: {
    id: {type: String, default: undefined},
    placeholder: {type: String, default: undefined},
    initValue: {type: String, default: undefined},
    label: {type: String, default: undefined},
    tooltip: {type: String, default: undefined},
    onSubmit: {type: Function, required: true}
  },
  setup(props) {
    const inputElement = ref(null as InstanceType<typeof HTMLInputElement> | null);
    const value = ref(props.initValue);
    const onEnter = () => {
      props.onSubmit(value.value);
    };
    const icon = computed(() => faSearch);
    onMounted(() => {
      if (inputElement.value !== null) {
        inputElement.value.focus();
      }
    });
    return {
      icon,
      value,
      inputElement,
      onEnter
    };
  }
});
</script>
