<template>
  <div>
    <div class="px-fl-lg-xl pt-fl-md-xl flex justify-center">
      <img
        class="w-[clamp(140px,10.41666667vw,200px)]"
        src="https://scottsbasslessons.imgix.net/content/signup-sbl-logo.svg"
      />
    </div>
    <div class="px-fl-lg-xl pt-fl-2xl-3xl">
      <div class="text-center ml-auto mr-auto">
        <div class="mb-fl-md text-primary text-fl-10xl font-bold">Join Us!</div>
        <h1
          class="mb-fl-md text-full-white text-fl-6xl sm:text-fl-8xl leading-[1.25] font-extrabold"
        >
          Start Your <span class="text-primary">FREE</span> 14-Day Trial Below...
        </h1>
      </div>
    </div>
    <div
      class="relative py-fl-md-2xl w-full z-0 bg-full-white flex justify-center after:absolute after:top-[-1px] after:left-0 after:bg-black after:w-full after:h-full after:clip-path-polygon-[0_0,100%_0,100%_25%,0_50%]"
    >
      <div
        class="mx-fl-sm-xl w-full max-w-[1300px] z-10 flex justify-center bg-full-white rounded-md border-[rgba(103,112,127,0.5)] border"
      >
        <div ref="anchor" class="px-fl-md pt-fl-xl pb-fl-lg w-full">
          <div class="pb-fl-xl w-full">
            <steps :step="wizardComposition._currentStep.value" />
          </div>
          <wizard :wizard-composition="wizardComposition">
            <template #0>
              <credentials
                :disabled="wizardComposition.disabled(0)"
                :on-complete="setCredentials"
              />
            </template>
            <template #1>
              <plan-selection
                v-if="signupOffers !== undefined"
                :disabled="wizardComposition.disabled(1)"
                :offers="signupOffers"
                :on-complete="setSignupOffer"
              />
            </template>
            <template #2>
              <payment
                v-if="
                  email !== undefined &&
                  username !== undefined &&
                  password !== undefined &&
                  selectedSignupAccessPass !== undefined
                "
                :error="error"
                :email="email"
                :amount="selectedSignupAccessPass.accessPassAmount"
                :on-purchase="chargePaymentMethod"
                :recaptcha-action="'payment_signup'"
              />
            </template>
          </wizard>
        </div>
      </div>
    </div>
    <div class="px-fl-lg-xl py-fl-xl-3xl">
      <div class="pb-fl-md text-center text-fl-base text-bright uppercase tracking-[0.13em]">
        &copy; {{ currentYear }} Scott’s Bass Lessons
      </div>
      <div class="text-center text-fl-base text-bright uppercase tracking-[0.13em]">
        By signing up you agree to our
        <a class="text-primary underline" href="/terms-of-service">Terms of Service</a> and
        <a class="text-primary underline" href="/privacy-policy">Privacy Policy</a>.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import Steps from './partials/Steps.vue';
import Credentials from './components/Credentials.vue';
import PlanSelection from './components/plan-selection/PlanSelection.vue';
import Payment from './components/Payment.vue';
import Wizard from '../../../generic/wizard/Wizard.vue';
import {useWizard} from '../../../vue-composition/wizard/wizard';
import {AccessPass} from '../../../../backend/access-pass/access-pass-types';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {SignupOffer} from '../../../../backend/offer/signup-offer-types';
import {signup, signupStep} from '../../../../backend/signup/checkout-query';
import {getCustomerLifetimeEventData, redirectNoHistory} from '../../../../utils/url';
import {AddOnOffer} from '../../../../backend/offer/addon-offer-types';
import {getSignupOffersForCurrentUser} from '../../../../backend/offer/signup-offer-utils';
import {OfferCardData} from '../../../offer/offer-card/types';
import {useBackendError} from '../../../vue-composition/backend-error/backend-error';
import {BraintreeCustomer, BraintreeNonce} from '../../../offer/payment/types';

export default defineComponent({
  components: {
    Steps,
    Wizard,
    Credentials,
    PlanSelection,
    Payment
  },
  setup() {
    const loader = useFullScreenLoader();
    const wizardComposition = useWizard(3);
    const anchor = ref(null as InstanceType<typeof HTMLDivElement> | null);
    const error = useBackendError();

    const signupOffers = ref<ReadonlyArray<SignupOffer> | undefined>(undefined);
    const addOnOffers = ref<ReadonlyArray<AddOnOffer> | undefined>(undefined);

    const email = ref<string | undefined>(undefined);
    const username = ref<string | undefined>(undefined);
    const password = ref<string | undefined>(undefined);
    const customer = ref<Readonly<BraintreeCustomer | undefined>>(undefined);

    const setCredentials = (
      _email: string,
      _username: string,
      _password: string,
      _recaptcha: number | undefined
    ) => {
      email.value = _email;
      username.value = _username;
      password.value = _password;
      wizardComposition.next();
      if (anchor.value !== null) {
        anchor.value.scrollIntoView({behavior: 'smooth'});
      }
    };

    const selectedSignupAccessPass = ref<Readonly<AccessPass> | undefined>(undefined);
    const selectedSignupTrialDuration = ref<number | undefined>(undefined);
    const selectedSignupBonusPasses = ref<Array<AccessPass>>([]);
    const selectedSignupOffer = ref<Readonly<SignupOffer> | undefined>(undefined);
    const setSignupOffer = async (card: Readonly<OfferCardData>) => {
      // Let the backend know which signup step we're in.
      if (email.value === undefined) {
        return;
      }
      signupStep('academy', email.value, 'plans');

      selectedSignupOffer.value = card.offer as Readonly<SignupOffer>;
      selectedSignupAccessPass.value = card.accessPass;
      selectedSignupBonusPasses.value = card.offer.offerBonuses;
      if (!('offerTrialDuration' in card.offer) || card.offer.offerTrialDuration === null) {
        throw new Error('No offer trial duration');
      }
      selectedSignupTrialDuration.value = card.offer.offerTrialDuration;

      wizardComposition.next();
      if (anchor.value !== null) {
        anchor.value.scrollIntoView({behavior: 'smooth'});
      }
    };
    const chargePaymentMethod = async (
      nonce: Readonly<BraintreeNonce>,
      customer: Readonly<BraintreeCustomer>
    ) => {
      if (
        username.value !== undefined &&
        password.value !== undefined &&
        selectedSignupAccessPass.value !== undefined &&
        selectedSignupBonusPasses.value !== undefined &&
        selectedSignupTrialDuration.value !== undefined
      ) {
        try {
          loader.setLoading(
            true,
            'Confirming your payment method. Please do not navigate away from this page.'
          );
          await signup(
            selectedSignupAccessPass.value.slug,
            selectedSignupBonusPasses.value.map(bp => bp.slug),
            nonce.nonce,
            customer,
            selectedSignupTrialDuration.value,
            nonce.deviceData,
            username.value,
            password.value,
            getCustomerLifetimeEventData(),
            true
          );
          signupComplete();
        } catch (e) {
          loader.setLoading(false);
          error.catcher(e);
        }
      }
    };
    const signupComplete = async () => {
      /*
        If we have add-ons or upgrades for the selected signup offer, redirect either
        directly to /sign-up/add-on (or /sign-up/upgrade), or to an external sales page
        (which is then assumed to redirect to /sign-up/add-on or /sign-up/upgrade).
      */
      if (selectedSignupOffer.value !== undefined) {
        if (selectedSignupOffer.value.offerAddOns.length > 0) {
          const offerIds = selectedSignupOffer.value.offerAddOns.map(ao => ao.id).join(',');
          const salesPageUrl = selectedSignupOffer.value.offerAddOnsSalesPageUrl;
          const url =
            salesPageUrl === null
              ? `/sign-up/add-on?offer=${offerIds}`
              : `${salesPageUrl}?offer=${offerIds}`;
          loader.setLoading(true);
          redirectNoHistory(url);
          return;
        }

        if (selectedSignupOffer.value.offerUpgrades.length > 0) {
          const offerIds = selectedSignupOffer.value.offerUpgrades.map(ao => ao.id).join(',');
          const salesPageUrl = selectedSignupOffer.value.offerAddOnsSalesPageUrl;
          const url =
            salesPageUrl === null
              ? `/sign-up/upgrade?offer=${offerIds}`
              : `${salesPageUrl}?offer=${offerIds}`;
          loader.setLoading(true);
          redirectNoHistory(url);
          return;
        }

        // We don't have any add-ons, so check of the signup offer has a thank you page.
        const thankYouPage = selectedSignupOffer.value.offerThankYouPage[0];
        if (thankYouPage !== undefined) {
          loader.setLoading(true);
          redirectNoHistory(thankYouPage.url);
          return;
        }
      }

      // We don't have any add-ons, so redirect to signup survey.
      loader.setLoading(true);
      redirectNoHistory('/sign-up/onboarding-survey');
    };

    const currentYear = computed(() => {
      return new Date().getFullYear();
    });

    onMounted(async () => {
      loader.setLoading(true);
      signupOffers.value = await getSignupOffersForCurrentUser().catch(error.catcher);
      loader.setLoading(false);
    });

    return {
      wizardComposition,
      anchor,
      signupOffers,
      addOnOffers,
      email,
      username,
      password,
      customer,
      setCredentials,
      setSignupOffer,
      selectedSignupOffer,
      selectedSignupAccessPass,
      selectedSignupTrialDuration,
      selectedSignupBonusPasses,
      chargePaymentMethod,
      signupComplete,
      error,
      currentYear
    };
  }
});
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
