<template>
  <div :class="cfg">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';

export default defineComponent({
  props: {
    gap: {type: String, default: 'gap-fl-md'},
    wrap: {type: Boolean, default: true},
    justify: {type: String, default: 'justify-start'},
    items: {type: String, default: 'items-center'}
  },
  setup(props) {
    const cfg = computed(() => {
      const wrap = props.wrap ? 'flex-wrap' : '';
      return `flex ${wrap} ${props.gap} ${props.justify} ${props.items}`;
    });
    return {
      cfg
    };
  }
});
</script>
