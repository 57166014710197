<template>
  <div :class="`flex flex-col ${background} overflow-hidden`">
    <div v-if="title !== undefined" class="px-fl-lg py-fl-xl text-fl-3xl font-semibold text-white">
      {{ title }}
    </div>
    <div v-if="items !== undefined" class="flex flex-col overflow-y-auto">
      <media-browser-sidebar-item
        v-for="(item, i) in items"
        :key="item.id"
        :on-click="onItemSelected"
        :item="item"
        :sidebar-icon-type="sidebarIconType"
        :divider="i === 0"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import MediaBrowserSidebarItem from './MediaBrowserSidebarItem.vue';
import {MediaBrowserItem} from '../media-browser-types';

export default defineComponent({
  components: {
    MediaBrowserSidebarItem
  },
  props: {
    onItemSelected: {type: Function, default: undefined},
    items: {type: Array as PropType<ReadonlyArray<MediaBrowserItem>>, required: true},
    title: {type: String, default: undefined},
    sidebarIconType: {
      type: String as PropType<'play' | 'check'>,
      default: 'check'
    },
    background: {type: String, default: 'bg-darkest'}
  }
});
</script>
