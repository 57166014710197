import {fetchContentEntries} from '../content-entry/content-entry-query';
import {Podcast} from './podcast-types';
import * as QueryBuilder from '../../craft/query/craft-query-builder';
import {contentEntries} from '../content-entry/content-entry-query-builder';

const PODCAST_FIELDS = [QueryBuilder.plainText('podcastTopics')];

export async function getAllPodcasts(): Promise<Array<Podcast>> {
  try {
    const result = await fetchContentEntries<Podcast>(
      contentEntries().section(['podcasts']).fields(PODCAST_FIELDS)
    );
    return result;
  } catch (error) {
    throw new Error(`Could not fetch podcasts: ${error}`);
  }
}

export async function getPodcastBySlug(slug: string): Promise<Readonly<Podcast | undefined>> {
  try {
    const result = await fetchContentEntries<Podcast>(
      contentEntries().section(['podcasts']).slug([slug]).fields(PODCAST_FIELDS)
    );
    return result[0];
  } catch (error) {
    throw new Error(`Could not fetch podcast: ${error}`);
  }
}
