import {ThreadSyncCache} from '../../../utils/thread-sync-cache';
import {cachedPostAction, ControllerResponse, postAction} from '../../craft-action';
import {CraftQueryBuilderGlobalSet} from '../query/craft-query-builder-types';
import {CraftQueryGlobalSet} from '../query/implementation/craft-query-types';
import {buildValidationSchema} from '../query/implementation/craft-query-validation';

export async function fetchGlobalSet<T>(
  query: Readonly<CraftQueryBuilderGlobalSet>,
  cache?: ThreadSyncCache<ControllerResponse<ReadonlyArray<T>>>,
  cacheKey?: string | number
): Promise<Readonly<T>> {
  /*
    ### This code is more or less a repeat of the code in `fetchEntries` and
    `fetchCategories`, so it is possible to generalise them into a single
    `fetchElements` function.
  */
  const field = query._build() as CraftQueryGlobalSet;
  const validationSchema = buildValidationSchema(field);
  const url = '/actions/sbl-module/element-query/query';
  try {
    let result: ReadonlyArray<T> = [];
    if (cache !== undefined && cacheKey !== undefined) {
      result = await cachedPostAction<ReadonlyArray<T>>(
        url,
        field,
        validationSchema,
        cache,
        cacheKey
      );
    } else {
      result = await postAction<ReadonlyArray<T>>(url, field, validationSchema);
    }
    const globalSet = result[0] as Readonly<T>;
    return globalSet;
  } catch (error) {
    throw new Error(`Could not fetch global set: ${error}`);
  }
}
