import {ResultTransform} from '../craft-query-builder-types';
import {
  CraftQueryCategoriesField,
  CraftQueryEntriesField,
  CraftQueryField,
  CraftQueryGlobalSet,
  CraftQueryMatrixField,
  CraftQueryMatrixFieldBlock
} from './craft-query-types';

function mapElements(
  field: Readonly<CraftQueryEntriesField | CraftQueryCategoriesField | CraftQueryGlobalSet>,
  transform: ResultTransform,
  data: any
) {
  const elements = data as Array<any>;
  const fields = field.fields;
  if (fields === undefined || fields.length === 0) {
    return elements.map(e => transform(field.customData, e));
  }
  return elements.map(e => {
    const result: any = {...e};
    if (fields === undefined || fields.length === 0) {
      return result;
    }
    fields.forEach(subfield => {
      const handle = subfield.handle;
      if (handle === undefined) {
        throw new Error('Field has no handle');
      }
      const fieldData = e[handle];
      result[handle] = mapField(subfield, transform, fieldData);
    });
    return transform(field.customData, result);
  });
}

function mapBlock(
  block: Readonly<CraftQueryMatrixFieldBlock>,
  transform: ResultTransform,
  data: Readonly<any>
): Readonly<any> {
  const fields = block.fields;
  const result: any = {...data};
  fields.forEach(field => {
    const handle = field.handle;
    if (handle === undefined) {
      throw new Error('Matrix block field has no handle');
    }
    const fieldData = data[handle];
    result[handle] = mapField(field, transform, fieldData);
  });
  return result;
}

function mapMatrix(
  field: Readonly<CraftQueryMatrixField>,
  transform: ResultTransform,
  data: Readonly<any>
): Readonly<any> {
  const blockInstances = data as Array<any>;
  const result = blockInstances.map(blockInstance => {
    const blockInstanceHandle = blockInstance['typeHandle'];
    if (blockInstanceHandle === undefined) {
      throw new Error('Matrix block data has no type handle');
    }
    const block = field.blocks.find(b => b.handle === blockInstanceHandle);
    if (block === undefined) {
      throw new Error(`Matrix field definition has no block named ${blockInstanceHandle}`);
    }
    return mapBlock(block, transform, blockInstance);
  });
  return result;
}

/**
 * Similar to the Array map() prototype function, but for Craft query results.
 * The transform function is applied recursively to sub-fields.
 *
 * @param field Definition of the field that contains the query result data to map.
 * @param transform The transform function to apply to the result data.
 * @param data The query result data
 * @returns Transformed query result data.
 */
export function mapField(
  field: Readonly<CraftQueryField>,
  transform: ResultTransform,
  data: Readonly<any>
): Readonly<any> {
  switch (field.type) {
    case 'matrix':
      return mapMatrix(field, transform, data);
    case 'entries':
    case 'categories':
    case 'globalSet':
      return mapElements(field, transform, data);
    default:
      return transform(field.customData, data);
  }
}
