import {computed, ComputedRef, Ref, ref, watch} from 'vue';
import {Song, SongSpeedVariationTempo} from '../../../../backend/song/song-types';
import {useLoader} from '../../../vue-composition/loader/loader';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import {LoopPlayerConfig, LoopPlayerSource} from '../../../../utils/loop-player/loop-player-types';

export function getLoopConfig(
  song: Readonly<Song>,
  variationIndex: number
): Readonly<LoopPlayerConfig> {
  const variation = song.songSpeedVariations[variationIndex];
  const source: LoopPlayerSource = {
    urlMp3: variation.speedVariationMp3Url,
    urlWebm: variation.speedVariationWebmUrl
  };
  const config: LoopPlayerConfig = {
    source,
    sourceId: variationIndex,
    rate: variation.speedVariationTempo,
    bars: Number.parseInt(song.songBars, 10),
    bpm: Number.parseInt(song.songTempo, 10),
    beatsPerBar: Number.parseInt(song.songBeatsPerBar, 10),
    audioFilePrecountBars: Number.parseInt(song.songPrecountBars, 10)
  };
  return config;
}

export function formatBarNumber(bar: number) {
  if (bar >= 0) {
    return `${bar + 1}`.padStart(3, '0');
  }
  return `${bar}`;
}

export interface Variation {
  speed: SongSpeedVariationTempo;
  withBass: number | undefined;
  withoutBass: number | undefined;
}

function getVariations(song: Readonly<Song>): ReadonlyArray<Variation> {
  const map: {[speed: string]: Variation} = {};
  song.songSpeedVariations.forEach((v, index) => {
    if (map[v.speedVariationTempo] === undefined) {
      map[v.speedVariationTempo] = {
        speed: v.speedVariationTempo,
        withBass: undefined,
        withoutBass: undefined
      };
    }
    map[v.speedVariationTempo][v.speedVariationStemType] = index;
  });
  return Object.values(map)
    .sort((a, b) => {
      return parseInt(a.speed) - parseInt(b.speed);
    })
    .reverse();
}

export type VariationConfig = {
  load: () => Promise<void>;
  loaded: Ref<boolean>;
  speedDisplay: ComputedRef<string>;
  speedUp: () => void;
  speedDown: () => void;
  stemDisplay: ComputedRef<string>;
  onToggleStem: () => void;
  preCountBars: Ref<number>;
  preCountDisplay: ComputedRef<string>;
  preCountBarsUp: () => void;
  preCountBarsDown: () => void;
};

/**
 * Internal utility that returns a Vue composition that contains
 * information about song speed variations and song stems, as well
 * as methods to change them.
 *
 * The reason we need this is that speed variations and stems are
 * collected together in the Song object, whereas the Song Player
 * Vue component allows the stem and speed to be selected independently.
 * That is, this composition splits one single reactive property
 * (the variation index) into two (stem + speed), and also solves the
 * problem of async-loading the correct variation when one of those
 * two properties change.
 */
export function getVariationConfig(
  song: Readonly<Song>,
  player: Readonly<LoopPlayer>
): Readonly<VariationConfig> {
  const loader = useLoader();
  const variations = getVariations(song);
  const speed = ref(0);
  const stem = ref('withoutBass' as 'withBass' | 'withoutBass');
  const loaded = ref(false);
  const preCountBars = ref(1);

  const load = async () => {
    player.onStop();
    const variation = variations[speed.value];
    if (variation !== undefined) {
      const index = variation[stem.value];
      if (index !== undefined) {
        loader.setLoading(true);
        const config = getLoopConfig(song, index);
        await player.load(config);
        loader.setLoading(false);
      }
    }
    loaded.value = true;
  };

  watch(speed, async _ => {
    await load();
  });
  watch(stem, async _ => {
    await load();
  });

  const speedUp = () => {
    // Variations are sorted from 100 down.
    if (speed.value > 0) {
      speed.value -= 1;
    }
  };
  const speedDown = () => {
    // Variations are sorted from 100 down.
    if (speed.value < variations.length - 1) {
      speed.value += 1;
    }
  };
  const speedDisplay = computed(() => {
    return `${variations[speed.value].speed}%`;
  });

  const stemDisplay = computed(() => {
    return stem.value === 'withBass' ? 'ON' : 'OFF';
  });
  const onToggleStem = () => {
    if (stem.value === 'withBass') {
      if (variations[speed.value].withoutBass !== undefined) {
        stem.value = 'withoutBass';
      }
    } else {
      if (variations[speed.value].withBass !== undefined) {
        stem.value = 'withBass';
      }
    }
  };

  const preCountDisplay = computed(() => {
    return preCountBars.value > 0 ? `${preCountBars.value}` : 'OFF';
  });
  player.setPreCountBars(preCountBars.value);
  const preCountBarsUp = () => {
    if (preCountBars.value < 4) {
      preCountBars.value += 1;
      player.setPreCountBars(preCountBars.value);
      player.setPreCountState(true);
    }
  };
  const preCountBarsDown = () => {
    if (preCountBars.value > 0) {
      preCountBars.value -= 1;
      player.setPreCountBars(preCountBars.value);
      if (preCountBars.value === 0) {
        player.setPreCountState(false);
      }
    }
  };

  return {
    load,
    loaded,
    speedDisplay,
    speedUp,
    speedDown,
    stemDisplay,
    onToggleStem,
    preCountBars,
    preCountDisplay,
    preCountBarsUp,
    preCountBarsDown
  };
}
