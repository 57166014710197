<template>
  <!--
    Note that we use text inputs here rather than number inputs.
    See https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
  -->
  <div
    :class="`${padding} w-full flex flex-col justify-start items-start ${gap} border border-mid rounded-md`"
  >
    <label
      v-if="label"
      for="start-input"
      class="text-fl-base text-mid uppercase whitespace-nowrap"
      >{{ title }}</label
    >
    <input
      id="start-input"
      v-model="model"
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      size="3"
      :disabled="disabled"
      :min="rangeSelection.min"
      :max="rangeSelection.max"
      :class="`w-full p-0 font-sans font-bold border-none bg-darkest text-light disabled:opacity-75`"
      :style="fontSize"
      @change.prevent="onChange"
    />
  </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {RangeSelectorComposition} from '../internals/bar-select';

export type RangeSelectorChangeCallback = (start: number, end: number) => void;

export default {
  components: {},
  props: {
    title: {type: String, required: true},
    rangeSelection: {type: Object as PropType<RangeSelectorComposition>, required: true},
    type: {type: String as PropType<'start' | 'end'>, required: true},
    disabled: {type: Boolean, default: false},
    fontSize: {type: String, default: 'text-fl-8xl'},
    padding: {type: String, default: 'p-fl-xs'},
    gap: {type: String, default: 'gap-fl-2xs'},
    label: {type: Boolean, default: true}
  },
  setup(props) {
    const model = props.type === 'start' ? props.rangeSelection.start : props.rangeSelection.end;
    const onChange =
      props.type === 'start'
        ? props.rangeSelection.onStartChanged
        : props.rangeSelection.onEndChanged;
    return {
      model,
      onChange
    };
  }
};
</script>
