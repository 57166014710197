<template>
  <page-content>
    <page-section :margin-top="'none'" :padding-top="'md'">
      <centered-column>
        <div class="w-20 h-20">
          <logo :display-type="'solid'" />
        </div>
      </centered-column>
    </page-section>

    <upgrade-purchase
      v-if="offer !== undefined"
      :offer="offer"
      :email="email"
      :on-cancel="onCancel"
      :on-complete="onComplete"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import CenteredColumn from '../../core/compositions/CenteredColumn.vue';
import Logo from '../../generic/logo/Logo.vue';
import UpgradePurchase from './partials/UpgradePurchase.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {redirectWithHistory} from '../../../utils/url';
import {getUpgradeOffersForCurrentUser} from '../../../backend/offer/upgrade-offer-utils';
import {UpgradeOffer} from '../../../backend/offer/upgrade-offer-types';

/**
 * This component is used during signups; it presents the specified
 * upgrade offer to the currently logged in student and allows them
 * to purchase the corresponding access pass.
 */
/*
  ??? This component is almost verbatim the same as SignupAddOn.vue
  (in this folder), the only difference is the type of purchase being
  made (add-on vs upgrade). We should refactor the cart/purchase
  system so that the presentation of offers is independent from the
  mechanism required to purchase them. ALSO, the main difference
  between this component and the corresponding Upgrade.vue component
  in /user-pages/account/pages/upgrade is that this redirects to
  the next step in the signup flow, whereas that component redirects
  to the student's account page.
*/
export default defineComponent({
  components: {
    PageContent,
    PageSection,
    CenteredColumn,
    Logo,
    UpgradePurchase
  },
  props: {
    serverDate: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    email: {type: String, required: true},
    offerId: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const offer = ref<Readonly<UpgradeOffer> | undefined>(undefined);

    const goToSurvey = () => {
      loader.setLoading(true);
      redirectWithHistory('/sign-up/onboarding-survey');
    };

    const onComplete = async () => {
      // ??? Add ability to display a thank you page here?
      goToSurvey();
    };
    const onCancel = async () => {
      goToSurvey();
    };

    onMounted(async () => {
      loader.setLoading(true);
      const allOffers = await getUpgradeOffersForCurrentUser(
        ['signup'],
        props.serverDate,
        props.userDateCreated,
        [props.offerId]
      );
      loader.setLoading(false);
      if (allOffers.length !== 1) {
        goToSurvey();
        return;
      }
      offer.value = allOffers[0];

      /*
        ??? Do we want to let ActiveCampaign know that the student was presented
        with the upgrade offer? For signup add-ons the current code messes 
        with LiveClass-related fields in Active Campaign; this is probably 
        not what we want. But we cannot simply change it as AC automations
        and/or reporting may rely on the current behaviour.
      */
    });

    return {
      offer,
      onComplete,
      onCancel
    };
  }
});
</script>
