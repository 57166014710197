<template>
  <!-- Progress bar -->
  <div class="relative h-fl-3xs-2xs">
    <div v-if="level.unlocked" class="relative h-full w-full bg-mid rounded">
      <div
        :class="`absolute top-0 left-0 h-full rounded ${spotColor.bg}`"
        :style="progressWidth"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {playersPathLevelSpotColor} from '../utils';

export default defineComponent({
  components: {},
  props: {
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true}
  },
  setup(props) {
    const levelNumber = computed(() => {
      return parseInt(props.level.slug.substring(19));
    });

    const spotColor = computed(() => {
      return playersPathLevelSpotColor(levelNumber.value);
    });

    const progressWidth = computed(() => {
      const progress = 100 * Math.min(props.level.numberOfCompletedPieces / 3, 1.0);
      return `width: ${progress}%`;
    });

    return {
      spotColor,
      progressWidth
    };
  }
});
</script>
