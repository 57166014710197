<template>
  <page-section :margin-top="'sm'">
    <content-column :width="width" :justify="justify">
      <div>
        <span class="inline italic">''{{ quote.richTextQuotePrimary }}''</span>
        &#8212; {{ quote.richTextQuoteSecondary }}
      </div>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {RichTextBlock, RichTextQuote} from '../../../../backend/rich-text/rich-text-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn
  },
  props: {
    richTextQuote: {type: Object as PropType<RichTextBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const quote = computed(() => {
      return props.richTextQuote as RichTextQuote;
    });
    return {
      quote
    };
  }
});
</script>
