<template>
  <div>
    <page-section :margin-top="'xl'">
      <content-column>
        <standard-heading :size="3"
          >{{ course.childEntries.length }} Lessons in this Course</standard-heading
        >
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-entry-items
        v-if="modules.length === 1"
        :guest="guest"
        :content-entries="modules[0].lessons"
        :duration="lessonDuration"
        :progress="progress.childProgress.value"
        :bookmark="bookmark"
        :paginate="true"
        :text-size="'text-fl-lg'"
        :icon-size="'text-fl-4xl'"
      />

      <flow v-for="mod in modules" v-else :key="mod.title">
        <content-column>
          <standard-heading :size="4" class="mt-6">{{ mod.title }}</standard-heading>
        </content-column>
        <content-entry-items
          :guest="guest"
          :content-entries="mod.lessons"
          :duration="lessonDuration"
          :progress="progress.childProgress.value"
          :bookmark="bookmark"
          :paginate="true"
          :text-size="'text-fl-lg'"
          :icon-size="'text-fl-4xl'"
        />
      </flow>
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {Course} from '../../../../backend/content/course/course-types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {getModules, lessonDurations} from '../utils/utils';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Flow from '../../../core/compositions/Flow.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import ContentEntryItems from '../../../content-entry/collections/variants/ContentEntryItems.vue';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Flow,
    StandardHeading,
    ContentEntryItems
  },
  props: {
    guest: {type: String, required: true},
    course: {type: Object as PropType<Readonly<Course>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true}
  },
  setup(props) {
    const modules = computed(() => {
      return getModules(props.course);
    });
    const lessonDuration = computed(() => {
      return lessonDurations(props.course);
    });

    return {
      modules,
      lessonDuration
    };
  }
});
</script>
