<template>
  <standard-button
    :url="'/sign-up'"
    :label="'Join us! FREE Trial'"
    :spot-color="spotColor"
    class="font-semibold"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {ColorConfig} from '../../core/color-config';
export default defineComponent({
  components: {
    StandardButton
  },
  setup() {
    const spotColor: Readonly<ColorConfig> = {
      text: 'text-primary',
      textHover: 'hover:text-black',
      bg: 'bg-transparent',
      bgHover: 'hover:bg-primary',
      border: 'border-primary',
      borderHover: 'hover:border-primary'
    };
    return {spotColor};
  }
});
</script>
