<template>
  <button
    v-if="onClick !== undefined"
    :disabled="disabled"
    :class="`flex items-center flex-nowrap ${cfg} cursor-pointer select-none`"
    :title="labelConfig.tooltipText.value"
    :aria-label="labelConfig.ariaLabel.value"
    @click.prevent="clicked"
  >
    <font-awesome-icon
      v-if="icon && !flip"
      :icon="icon"
      aria-hidden="true"
      :class="iconSize"
    ></font-awesome-icon>
    <div :class="`${textWrap} text-left`">{{ labelConfig.labelText }}</div>
    <font-awesome-icon
      v-if="icon && flip"
      :icon="icon"
      aria-hidden="true"
      :class="iconSize"
    ></font-awesome-icon>
  </button>
  <a
    v-else
    :href="url"
    :target="target"
    :class="`flex items-center flex-nowrap ${cfg} cursor-pointer select-none`"
  >
    <font-awesome-icon
      v-if="icon && !flip"
      :icon="icon"
      aria-hidden="true"
      :class="iconSize"
    ></font-awesome-icon>
    <div :class="`${textWrap} text-left`">{{ labelConfig.labelText }}</div>
    <font-awesome-icon
      v-if="icon && flip"
      :icon="icon"
      aria-hidden="true"
      :class="iconSize"
    ></font-awesome-icon>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {
  getClickFunc,
  getLinkColor,
  getLabel,
  ButtonColor,
  ButtonSize,
  getLinkIconDimensions,
  ButtonClickCallback
} from './implementation/utils';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {type: Boolean, default: false},

    size: {type: String as PropType<ButtonSize>, default: 'md'},

    /** If true, set the width of the button to 100%. */
    expand: {type: Boolean, default: false},

    color: {type: String as PropType<ButtonColor>, default: 'mid'},

    label: {type: String, default: undefined},
    tooltip: {type: String, default: undefined},

    /** FontAwesome icon (positioned left or right of the label). */
    icon: {type: Object as PropType<Readonly<IconDefinition>>, required: true},
    /** If true, position the icon to the right of the label. */
    flip: {type: Boolean, default: false},

    /** The gap between the icon and the label. */
    gap: {type: String, default: 'gap-2'},
    /** Set this to override the flex justification. */
    justify: {type: String, default: 'justify-center'},
    /** Allow text to wrap */
    wrap: {type: Boolean, default: false},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: Number, default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined},
    /** If true, open the URL in a new tab. */
    newTab: {type: Boolean, default: false}
  },
  setup(props) {
    const clicked = getClickFunc(props.onClick, props.id, props.url);
    const color = computed(() => getLinkColor(props.disabled, props.color));
    const labelConfig = computed(() => getLabel(props.label, props.tooltip));
    const textSize = computed(() => getLinkIconDimensions(props.size, props.expand));
    const iconSize = computed(() => {
      switch (props.size) {
        case 'xs':
          return `text-fl-base`;
        case 'sm':
          return `text-fl-lg`;
        case 'md':
        default:
          return `text-fl-lg`;
        case 'lg':
          return `text-fl-2xl`;
        case 'xl':
          return `text-fl-3xl`;
      }
    });

    const cfg = computed(() => {
      return `${props.justify} ${props.gap} ${textSize.value} ${color.value.textColor} ${color.value.bgColor}`;
    });
    const textWrap = computed(() => {
      return props.wrap ? '' : 'whitespace-nowrap';
    });
    const target = computed(() => {
      return props.newTab ? '_blank' : undefined;
    });

    return {
      cfg,
      iconSize,
      textWrap,
      labelConfig,
      clicked,
      target
    };
  }
});
</script>
