<template>
  <sidebar-menu
    :guest="guest"
    :menu="menu"
    :on-close="onClose"
    :title="title"
    :orientation="orientation"
  >
    <a
      v-if="guest"
      id="trinity-trial-button"
      class="py-fl-xs px-fl-sm text-fl-base font-semibold text-center whitespace-nowrap text-black hover:text-primary bg-primary hover:bg-transparent border-2 border-primary rounded-md flex flex-nowrap justify-center"
      href="/sign-up"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="ctaTitle"></div>
    </a>
  </sidebar-menu>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import SidebarMenu from '../../../generic/sidebar-menu/SidebarMenu.vue';
import {HierarchicalMenuItem} from '../../../generic/hierarchical-menu/hierarchical-menu-types';

export default defineComponent({
  components: {
    SidebarMenu
  },
  props: {
    guest: {type: String, required: true},
    menu: {type: Object as PropType<Readonly<HierarchicalMenuItem>>, required: true},
    onClose: {type: Function as PropType<() => void>, required: true},
    title: {type: String, default: undefined},
    ctaTitle: {type: String, required: true},
    orientation: {type: String as PropType<'left' | 'right'>, default: 'left'}
  },
  setup() {}
});
</script>
