<template>
  <page-content v-if="lesson !== undefined">
    <page-section :margin-top="'xl'">
      <content-entry-section-header :center="false" :title="lesson.title" />
    </page-section>

    <page-section>
      <content-column>
        <cluster>
          <call-to-action-button />
          <standard-button :url="'/dG9vbGtpdA=='" :label="'Back to the Toolkit'" />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-bottom="'lg'">
      <content-column>
        <content-entry-video
          :guest="'1'"
          :content-entry="lesson"
          :video-type="'content'"
          :video-unavailable-text="''"
        />
      </content-column>
    </page-section>

    <rich-text :guest="'1'" :rich-text="lesson.richText" />

    <content-entry-resource-list :guest="''" :content-entry="lesson" />

    <content-entry-recommendations :guest="'1'" :content-entry="lesson" :bookmark="bookmark" />

    <feature-grid />

    <page-section>
      <content-column :width="'wide'">
        <call-to-action-button />
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType} from 'vue';
import ContentEntrySectionHeader from '../content-entry/partials/section/ContentEntrySectionHeader.vue';
import ContentEntryResourceList from '../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntryVideo from '../content-entry/partials/body/ContentEntryVideo.vue';
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import Cluster from '../core/compositions/Cluster.vue';
import RichText from '../generic/rich-text/RichText.vue';
import StandardButton from '../core/button/StandardButton.vue';
import FeatureGrid from './partials/FeatureGrid.vue';
import CallToActionButton from './partials/CallToActionButton.vue';
import {useFullScreenLoader} from '../vue-composition/loader/loader';
import {useBookmark} from '../vue-composition/bookmark/bookmark';
import {getToolkitLesson} from '../../backend/toolkit/toolkit-query';
import {ToolkitLesson} from '../../backend/toolkit/toolkit-types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Cluster,
    StandardButton,
    ContentEntrySectionHeader,
    FeatureGrid,
    CallToActionButton,
    RichText,
    ContentEntryVideo,
    ContentEntryResourceList,
    ContentEntryRecommendations
  },
  props: {
    slug: {type: String as PropType<string>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const lesson = ref(undefined as Readonly<ToolkitLesson> | undefined);
    const bookmark = useBookmark('1');

    onMounted(async () => {
      loader.setLoading(true);
      lesson.value = await getToolkitLesson(props.slug);
      loader.setLoading(false);
    });
    return {
      lesson,
      bookmark
    };
  }
});
</script>
