import * as Joi from 'joi';

export type VideoServiceProvider = 'vimeo' | 'youtube';
export type VideoId = string;
export type VideoType = 'trailer' | 'content';
export type VideoDuration = string;
export type VideoTimestamp = number;

export interface Video {
  videoServiceProvider: VideoServiceProvider;
  videoId: VideoId;
  videoType: VideoType;
  videoDuration: VideoDuration;
}

export const VIDEO_TIMESTAMP_VALIDATION_SCHEMA = Joi.number().integer().min(0);
