<template>
  <page-content v-if="curation !== undefined">
    <page-section>
      <content-entry-section-header
        :width="'wide'"
        :center="false"
        :title="topicTitle"
        :title-width="'wide'"
      />
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <standard-button :label="'Back to Dashboard'" :url="'/'" />
      </content-column>
    </page-section>

    <page-section>
      <content-entry-inline-cards
        :guest="guest"
        :width="'wide'"
        :content-entries="contentEntries"
        :progress="progress.progressMap.value"
        :bookmark="bookmark"
        :pagination="pagination"
        :card-image-width="cardImageWidth"
      />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {ref, onMounted, computed} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import ContentEntrySectionHeader from '../../content-entry/partials/section/ContentEntrySectionHeader.vue';
import ContentEntryInlineCards from '../../content-entry/collections/variants/ContentEntryInlineCards.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {Curation} from '../../../backend/curation/curation-types';
import {getCurationBySlug} from '../../../backend/curation/curation-query';
import {getUrlQueryParams} from '../../../utils/url';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {useProgressMap} from '../../vue-composition/progress/progress';
import {usePagination} from '../../vue-composition/pagination/pagination';
import {ImgixImageResponsiveWidth} from '../../core/imgix-image/types';

export default {
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardButton,
    ContentEntrySectionHeader,
    ContentEntryInlineCards
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const curation = ref(undefined as Curation | undefined);
    const topicTitle = ref('');
    const contentEntries = ref<ReadonlyArray<ContentEntry>>([]);
    const pagination = usePagination<ContentEntry>(computed(() => contentEntries.value));

    const bookmark = useBookmark(props.guest);
    const progress = useProgressMap(props.guest, contentEntries);
    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 690,
      md: 360,
      lg: 350,
      xl: 310,
      '2xl': 370
    };

    onMounted(async () => {
      loader.setLoading(true);
      await bookmark.init();
      curation.value = await getCurationBySlug(props.slug);
      if (curation.value !== undefined) {
        const params = getUrlQueryParams();
        const topicStr = params.get('topic');
        if (topicStr !== null) {
          const topic = parseInt(topicStr);
          if (!Number.isNaN(topic)) {
            const section = curation.value.curationSection[topic];
            topicTitle.value = section.curationTopicTitle;
            contentEntries.value = section.curationTopicEntries;
          }
        }
      }
      loader.setLoading(false);
    });

    return {
      curation,
      topicTitle,
      contentEntries,
      bookmark,
      progress,
      pagination,
      cardImageWidth
    };
  }
};
</script>
