<template>
  <div>
    <div class="mb-fl-lg">
      <div class="mb-fl-2xs text-primary text-fl-5xl leading-[1.25] font-bold text-center">
        Choose Your Plan
      </div>
      <div class="text-fl-lg text-[#67707f] leading-[1.4] font-normal text-center">
        You’re in control — select the plan that’s right for you if you continue after your free
        trial.
      </div>
    </div>
    <variants>
      <template #md+>
        <feature-matrix-large :offers="offers" :features="features" :on-complete="submit" />
      </template>
      <template #narrow-sm>
        <plan-tab :offers="offers" :features="features" :on-complete="submit" />
      </template>
    </variants>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {AddOnOffer} from '../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../backend/offer/signup-offer-types';
import {OfferCardData} from '../../../../../offer/offer-card/types';
import Variants from '../../../../../core/column-layout/Variants.vue';
import FeatureMatrixLarge from './partials/FeatureMatrixLarge.vue';
import PlanTab from './partials/PlanTab.vue';
import {Feature, getFeatureMatrix} from './utils';

export default defineComponent({
  components: {
    Variants,
    FeatureMatrixLarge,
    PlanTab
  },
  props: {
    offers: {
      type: Array as PropType<ReadonlyArray<SignupOffer> | ReadonlyArray<AddOnOffer>>,
      required: true
    },
    onComplete: {
      type: Function as PropType<(card: Readonly<OfferCardData>) => void>,
      required: true
    }
  },
  setup(props) {
    const features = computed<Array<Feature>>(() => {
      return getFeatureMatrix(props.offers);
    });

    const border = computed(() => {
      return props.offers.map(offer => {
        if (offer.offerHighlight) {
          return 'border-x border-x-primary';
        }
        return '';
      });
    });
    const borders = computed(() => {
      return features.value.map(f =>
        f.offer !== undefined && f.offer.offerHighlight ? 'border-x border-x-primary' : ''
      );
    });

    const submit = (offer: Readonly<SignupOffer> | Readonly<AddOnOffer>) => {
      const accessPass = offer.offerDestination[0];
      if (accessPass === undefined) {
        throw new Error('Unknown access pass');
      }
      const data: OfferCardData = {
        offer,
        accessPass,
        disabled: false
      };
      props.onComplete(data);
    };

    return {
      border,
      borders,
      features,
      submit
    };
  }
});
</script>
