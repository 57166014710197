<template>
  <cluster v-if="tutors.length > 0" :gap="gap">
    <cluster :wrap="false" :gap="gap">
      <div v-for="(tutor, index) in tutors" :key="index" :class="portraitSize">
        <portrait-button
          :url="tutor.url"
          :config="tutor.config"
          :tooltip="tutor.tooltip"
          tabindex="-1"
        />
      </div>
    </cluster>
    <cluster :wrap="false" :gap="gap">
      <link-button
        v-for="tutor in tutors"
        :key="tutor.key"
        :size="buttonSize"
        :url="tutor.url"
        :tooltip="tutor.tooltip"
        :label="tutor.title"
      />
    </cluster>
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import Cluster from '../../../../core/compositions/Cluster.vue';
import PortraitButton from '../../../../core/button/PortraitButton.vue';
import LinkButton from '../../../../core/button/LinkButton.vue';
import {deviceTypeSwitch} from '../../../../vue-composition/device-type/device-type';
import {getAvatarImage} from '../../../../../backend/content/content-entry/content-entry-query-utils';
import {ButtonSize} from '../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    Cluster,
    PortraitButton,
    LinkButton
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    size: {type: String as PropType<'xs' | 'sm' | 'md'>, default: 'md'}
  },
  setup(props) {
    const tutors = computed(() => {
      return props.contentEntry.tutors.map((tutor, index, array) => {
        const last = index === array.length - 1;
        let config = getAvatarImage(tutor.imageCollection, tutor.title, {
          narrow: 26,
          md: 46
        });
        if (config === undefined) {
          throw new Error('No tutor avatar');
        }
        return {
          config,
          url: tutor.url,
          key: tutor.id,
          tooltip: `Explore content by ${tutor.title}`,
          title: last ? tutor.title : `${tutor.title}, `
        };
      });
    });
    const portraitSize = deviceTypeSwitch({
      narrow: 'w-[26px] h-[26px]',
      md: 'w-[46px] h-[46px]'
    });
    const buttonSize = deviceTypeSwitch<ButtonSize>({
      narrow: 'xs',
      md: 'md'
    });
    const gap = deviceTypeSwitch({
      narrow: 'gap-fl-xs',
      sm: 'gap-fl-sm'
    });

    return {
      tutors,
      portraitSize,
      buttonSize,
      gap
    };
  }
});
</script>
