<template>
  <content-column :width="'narrow'">
    <div class="px-fl-lg-xl py-fl-lg-xl border-2 border-mid rounded-xl">
      <standard-heading :size="3" :class="'mb-fl-xl'">In this episode</standard-heading>
      <ul class="list-disc list-inside text-fl-lg space-y-6">
        <li v-for="topic in topics" :key="topic">{{ topic }}</li>
      </ul>
    </div>
  </content-column>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    StandardHeading,
    ContentColumn
  },
  props: {
    topics: {type: Array as PropType<ReadonlyArray<string>>, required: true}
  }
});
</script>
