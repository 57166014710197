import * as Joi from 'joi';
import {VALIDATION_NON_EMPTY_STRING} from '../validation';
import {postAction} from '../craft-action';

export type BraintreeClientToken = {
  customerId: string;
  clientToken: string;
};
const BRAINTREE_CLIENT_TOKEN_VALIDATION = Joi.object({
  customerId: VALIDATION_NON_EMPTY_STRING,
  clientToken: VALIDATION_NON_EMPTY_STRING
});

/**
 * Create a new Braintree customer record for the specified email address
 * (if necessary) and return the Braintree client token that represents
 * that customers' vaulted payment methods.
 *
 * !!! IMPORTANT !!! Never assume that there is only one Braintree customer
 * record for each Craft user! The Braintree customer record to use for a
 * specific user is the one that is stored in their Craft User element.
 */
export async function getBraintreeVault(
  email: string,
  recaptchaToken: any
): Promise<BraintreeClientToken> {
  const url = '/actions/sbl-module/checkout/get-vault';
  if (window.Craft === undefined) {
    throw new Error('Craft not initialised');
  }
  const throttlingToken = window.Craft.throttlingToken;
  if (throttlingToken === undefined) {
    throw new Error('No throttling token');
  }

  const result = await postAction<BraintreeClientToken>(
    url,
    {email, recaptchaToken, throttlingToken},
    BRAINTREE_CLIENT_TOKEN_VALIDATION
  );
  return result;
}
