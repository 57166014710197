import {ResultFilter} from '../craft-query-builder-types';
import {
  CraftQueryCategoriesField,
  CraftQueryEntriesField,
  CraftQueryField,
  CraftQueryGlobalSet,
  CraftQueryMatrixField,
  CraftQueryMatrixFieldBlock
} from './craft-query-types';

function filterElements(
  field: Readonly<CraftQueryEntriesField | CraftQueryCategoriesField | CraftQueryGlobalSet>,
  filter: ResultFilter,
  data: any
) {
  const elements = data as Array<any>;
  const fields = field.fields;
  if (fields === undefined || fields.length === 0) {
    return elements.filter(e => filter(field.customData, e));
  }
  return elements
    .map(e => {
      const result: any = {...e};
      if (fields === undefined || fields.length === 0) {
        return result;
      }
      fields.forEach(subfield => {
        const handle = subfield.handle;
        if (handle === undefined) {
          throw new Error('Field has no handle');
        }
        const fieldData = e[handle];
        result[handle] = filterField(subfield, filter, fieldData);
      });
      return result;
    })
    .filter(e => filter(field.customData, e));
}

function filterBlock(
  block: Readonly<CraftQueryMatrixFieldBlock>,
  filter: ResultFilter,
  data: Readonly<any>
): Readonly<any> {
  const fields = block.fields;
  const result: any = {...data};
  fields.forEach(field => {
    const handle = field.handle;
    if (handle === undefined) {
      throw new Error('Matrix block field has no handle');
    }
    const fieldData = data[handle];
    result[handle] = filterField(field, filter, fieldData);
  });
  return result;
}

function filterMatrix(
  field: Readonly<CraftQueryMatrixField>,
  filter: ResultFilter,
  data: Readonly<any>
): Readonly<any> {
  const blockInstances = data as Array<any>;
  const result = blockInstances.map(blockInstance => {
    const blockInstanceHandle = blockInstance['typeHandle'];
    if (blockInstanceHandle === undefined) {
      throw new Error('Matrix block data has no type handle');
    }
    const block = field.blocks.find(b => b.handle === blockInstanceHandle);
    if (block === undefined) {
      throw new Error(`Matrix field definition has no block named ${blockInstanceHandle}`);
    }
    return filterBlock(block, filter, blockInstance);
  });
  return result;
}

/**
 * Similar to the Array filter() prototype function, but for Craft query results.
 * The filter is applied recursively to sub-fields.
 *
 * @param field Definition of the field that contains the query result data to filter.
 * @param filter The filter function (returns true/false) to apply to the result data.
 * @param data The query result data
 * @returns Filtered query result data.
 */
export function filterField(
  field: Readonly<CraftQueryField>,
  filter: ResultFilter,
  data: Readonly<any>
): Readonly<any> {
  switch (field.type) {
    case 'matrix':
      return filterMatrix(field, filter, data);
    case 'entries':
    case 'categories':
    case 'globalSet':
      return filterElements(field, filter, data);
    default:
      return data;
  }
}
