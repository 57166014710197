<template>
  <button
    :class="`relative w-[1.2em] h-[1.2em] border-solid border rounded ${borderColor} ${color} ${backgroundColor}`"
    :title="tooltip"
    :data-checked="checked"
    :data-muted="muted"
    @click="onClicked"
  >
    <font-awesome-icon
      v-if="checked"
      class="absolute top-1/2 left-1/2 w-[80%] h-[80%] -translate-x-1/2 -translate-y-1/2"
      :icon="icon"
      aria-hidden="true"
    ></font-awesome-icon>
  </button>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    tooltip: {type: String, required: true},
    checked: {type: Boolean, required: true},
    muted: {type: Boolean, default: false},
    onClicked: {type: Function as PropType<() => void>, required: true}
  },
  setup(props) {
    const borderColor = computed(() => {
      if (props.muted) {
        return 'border-mid hover:border-white';
      }
      if (props.checked) {
        return 'border-white';
      }
      return 'border-mid hover:border-white';
    });

    const backgroundColor = computed(() => {
      if (props.muted) {
        return 'bg-transparent';
      }
      if (props.checked) {
        return 'bg-white';
      }
      return 'bg-transparent';
    });

    const color = computed(() => {
      if (props.muted) {
        return 'text-mid hover:text-white';
      }
      if (props.checked) {
        return 'text-dark hover:text-white';
      }
      return 'text-mid hover:text-white';
    });

    const icon = computed(() => faCheck);

    return {
      backgroundColor,
      borderColor,
      color,
      icon
    };
  }
});
</script>
