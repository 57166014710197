<template>
  <content-column :width="width" :justify="justify">
    <repel>
      <standard-heading :size="3">{{ heading }}</standard-heading>
      <slot />
    </repel>
  </content-column>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ContentColumn from '../compositions/ContentColumn.vue';
import Repel from '../compositions/Repel.vue';
import StandardHeading from '../standard-heading/StandardHeading.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../column-layout/utils';

export default defineComponent({
  components: {
    ContentColumn,
    Repel,
    StandardHeading
  },
  props: {
    heading: {type: String, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup() {}
});
</script>
