<template>
  <page-content v-if="groups.length > 0">
    <content-entry-section-summary
      :landing-page="landingPage"
      :title-image-max-width="'max-w-[550px]'"
      :title-image-max-width-summary="'max-w-[550px]'"
      :title-image="'https://scottsbasslessons.imgix.net/content/players-path-logo.svg'"
      :on-info-button-clicked="landingPage.toggle"
    />

    <page-section v-if="anythingUnlocked && !guest" class="my-fl-3xl">
      <players-path-resume
        v-if="continueLevel !== undefined"
        :continue-level="continueLevel"
        :guest="guest"
      />
    </page-section>

    <page-section
      v-if="!anythingUnlocked"
      :margin-top="'xs'"
      :margin-bottom="'lg'"
      class="text-center text-white"
    >
      <content-column :width="'narrow'">
        <flow :gap="1" :class="'text-fl-lg'">
          <div>Choose a starting level that best describes where your bass</div>
          <div>playing is at right now! You can switch to a different level at any time.</div>
        </flow>
      </content-column>
    </page-section>

    <page-section :margin-bottom="'xl'">
      <margins>
        <content-area>
          <standard-divider class="border-mid" />
        </content-area>
      </margins>
    </page-section>

    <div v-for="(group, index) in groups" :key="group.title">
      <page-section>
        <players-path-level-group :group="group" />
      </page-section>
      <page-section :margin-bottom="'md'">
        <margins>
          <content-area>
            <standard-divider v-if="index !== 2" class="border-mid" />
          </content-area>
        </margins>
      </page-section>
    </div>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import Margins from '../../core/column-layout/Margins.vue';
import ContentArea from '../../core/column-layout/ContentArea.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import Flow from '../../core/compositions/Flow.vue';
import StandardDivider from '../../core/standard-divider/StandardDivider.vue';
import ContentEntrySectionSummary from '../../content-entry/partials/section/ContentEntrySectionSummary.vue';
import PlayersPathResume from './partials/PlayersPathResume.vue';
import PlayersPathLevelGroup from './partials/PlayersPathLevelGroup.vue';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {getAllPlayersPathLevels} from '../../../backend/content/players-path/players-path-query';
import {getPlayersPathContinueLevel} from '../../../backend/content/players-path/players-path-query-utils';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {PlayersPathLevel} from '../../../backend/content/players-path/players-path-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentEntrySectionSummary,
    Margins,
    ContentArea,
    ContentColumn,
    Flow,
    StandardDivider,
    PlayersPathResume,
    PlayersPathLevelGroup
  },
  props: {
    guest: {type: String, required: true},
    landingPageSlug: {type: String as PropType<CraftSlug>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const deviceType = useDeviceType();

    const playersPathLevels = ref([] as ReadonlyArray<PlayersPathLevel>);
    const landingPage = useLandingPage(props.landingPageSlug);
    const anythingUnlocked = ref(false);
    const continueLevel = ref<PlayersPathLevel | undefined>(undefined);

    const spotColor = (level: Readonly<ContentEntry>) => {
      return `text-pp-level-${level.slug.substring(19)} bg-pp-level-${level.slug.substring(19)} `;
    };

    onMounted(async () => {
      loader.setLoading(true);

      playersPathLevels.value = await getAllPlayersPathLevels(props.guest);

      anythingUnlocked.value = playersPathLevels.value.reduce(
        (status: boolean, level: PlayersPathLevel) => {
          if (level.unlocked) {
            return true;
          }
          return status;
        },
        false
      );

      // ### We might be able to optimise this, since we've already fetched the relevant level data.
      continueLevel.value = await getPlayersPathContinueLevel(props.guest);

      loader.setLoading(false);
    });

    const groups = computed(() => {
      if (playersPathLevels.value.length === 0) {
        return [];
      }
      return [
        {
          title: 'Beginner',
          levels: playersPathLevels.value.slice(0, 3)
        },
        {
          title: 'Intermediate',
          levels: playersPathLevels.value.slice(3, 6)
        },
        {
          title: 'Advanced',
          levels: playersPathLevels.value.slice(6, 9)
        }
      ];
    });

    return {
      deviceType,
      groups,
      spotColor,
      landingPage,
      anythingUnlocked,
      continueLevel
    };
  }
});
</script>
