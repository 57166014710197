import {fetchContentEntries} from '../content-entry/content-entry-query';
import {MemberDiscount} from './member-discount-types';
import {shuffleArrayInPlace} from '../../../utils/array';
import * as QueryBuilder from '../../craft/query/craft-query-builder';
import {contentEntries} from '../content-entry/content-entry-query-builder';

export async function getMemberDiscounts(): Promise<Array<MemberDiscount>> {
  try {
    const result = await fetchContentEntries<MemberDiscount>(
      contentEntries()
        .section(['memberDiscounts'])
        .fields([
          QueryBuilder.plainText('memberDiscountCode'),
          QueryBuilder.plainText('memberDiscountRestrictions'),
          QueryBuilder.url('memberDiscountUrl').required()
        ])
    );
    shuffleArrayInPlace([...result]);
    return result;
  } catch (error) {
    throw new Error(`Could not fetch member discounts: ${error}`);
  }
}
