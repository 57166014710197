<template>
  <content-column :width="'wide'">
    <div
      class="relative w-full box-border h-20 grid grid-cols-[2fr_1fr_2fr] items-center select-none"
    >
      <!-- Navigation menu -->
      <div class="relative w-8 h-8 flex justify-center items-center">
        <icon-button
          :icon="iconBars"
          :on-click="showNavigationMenu"
          :tooltip="'Show menu'"
          :expand="true"
          :color="'white'"
        />
      </div>

      <!-- SBL logo -->
      <div class="flex justify-center items-center gap-x-1 flex-nowrap">
        <div class="relative w-14 h-14">
          <a href="/">
            <logo :display-type="'solid'" />
          </a>
        </div>
      </div>

      <!-- User menu -->
      <user-menu :font-size="'text-base'" class="justify-self-end" />
    </div>
  </content-column>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import {faBars} from '@fortawesome/pro-thin-svg-icons/faBars';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import IconButton from '../../../core/button/IconButton.vue';
import Logo from '../../../generic/logo/Logo.vue';
import UserMenu from '../user-menu/UserMenu.vue';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    ContentColumn,
    IconButton,
    Logo,
    UserMenu
  },
  props: {
    showNavigationMenu: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup() {
    const iconBars = computed(() => faBars);
    return {
      iconBars
    };
  }
});
</script>
