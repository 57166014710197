<template>
  <page-content>
    <page-section>
      <content-column :width="'narrow'">
        <div class="_form_1473"></div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {onMounted, defineComponent} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn
  },
  setup() {
    onMounted(() => {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://scottsbasslessons.activehosted.com/f/embed.php?id=1473');
      document.head.appendChild(script);
    });
  }
});
</script>
