<template>
  <content-column :width="'wide'">
    <div :class="`grid ${cols}`" :style="style">
      <slot />
    </div>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {deviceTypeSwitch, useDeviceType} from '../../../vue-composition/device-type/device-type';
import {useColumnLayout} from '../../../vue-composition/column-layout/column-layout';

/**
 * CSS grid (for cards, primarily) that aligns with the design system's
 * 12 column layout. The number of cards per row switches dynamically
 * as appropriate.
 */
export default defineComponent({
  components: {
    ContentColumn
  },
  setup() {
    const deviceType = useDeviceType();
    const columnLayout = useColumnLayout(deviceType);

    /*
      Set the number of columns (in the 12 column layout system) that each
      card should cover. For example, if we want the cards to cover 4 columns,
      we would use 'grid-cols-3' (12 columns / 4 columns per card = 3).
    */
    const cols = deviceTypeSwitch<string>({
      narrow: 'grid-cols-2',
      sm: 'grid-cols-2',
      md: 'grid-cols-3',
      lg: 'grid-cols-3',
      xl: 'grid-cols-6',
      '2xl': 'grid-cols-6'
    });

    /*
      Return the gutter between the cards; this is the current gutter width
      in the 12 column design system.
    */
    const style = computed(() => {
      return `gap:${columnLayout.config.value.gutterWidth}px`;
    });
    return {
      cols,
      style
    };
  }
});
</script>
