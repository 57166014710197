/*
  This file contains functions for communicating with the Craft controller
  that allows us to sign up customers in the Braintree backend, and create
  subsctiptions, etc. for them. For more information, see, e.g.,

  https://developer.paypal.com/braintree/docs/guides/hosted-fields/setup-and-integration#braintree.hostedfields.create
*/
import {CheckoutSignupStep, CheckoutSignupStepRecurrence} from './checkout-types';
import {VALIDATION_BOOLEAN, VALIDATION_POSITIVE_INTEGER} from '../validation';
import {CraftSlug} from '../craft/craft-types';
import {postAction} from '../craft-action';
import {BraintreeCustomer} from '../../components/offer/payment/types';
import {AccessPassPaymentProviderId} from '../access-pass/access-pass-types';

/**
 * Check if a new Craft user account can be created with the speified
 * email and username. NOTE: Calling this function does not actually
 * create the account, it just verifies that it can be created.
 */
export async function canCreateAccount(email: string, username: string): Promise<boolean> {
  const url = `/actions/sbl-module/checkout/can-create-account`;
  const result = await postAction<boolean>(url, {email, username}, VALIDATION_BOOLEAN);
  return result;
}

/**
 * Let the backend know of the current signup step.
 *
 * @param email The email of the user who is signing up.
 * @param step The current step in the signup flow.
 */
export async function signupStep(
  target: 'academy' | 'liveClass',
  email: string,
  step: CheckoutSignupStep,
  recurrence?: CheckoutSignupStepRecurrence
): Promise<void> {
  const url = `/actions/sbl-module/checkout/signup-step`;
  // We don't really care about the response; we ignore errors.
  await postAction<boolean>(url, {email, step, recurrence, target}, VALIDATION_BOOLEAN);
}

export async function signup(
  accessPassSlug: CraftSlug,
  bonusPassSlugs: ReadonlyArray<CraftSlug>,
  nonce: string,
  customer: Readonly<BraintreeCustomer>,
  trialDuration: number | undefined,
  deviceData: string,
  username: string,
  password: string,
  lifetimeEventData?: string | undefined,
  addActiveCampaignTag?: boolean | undefined
): Promise<void> {
  const url = `/actions/sbl-module/checkout/signup`;
  const body = {
    accessPass: accessPassSlug,
    bonusPasses: bonusPassSlugs,
    nonce,
    customerId: customer.customerId,
    trialDuration,
    deviceData,
    email: customer.email,
    username,
    password,
    lifetimeEventData,
    addActiveCampaignTag
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

export async function addOn(
  accessPassSlug: CraftSlug,
  bonusPassSlugs: ReadonlyArray<CraftSlug>,
  nonce: string,
  customer: Readonly<BraintreeCustomer>,
  trialDuration: number | undefined,
  deviceData: string,
  lifetimeEventData?: string | undefined
): Promise<void> {
  const url = `/actions/sbl-module/checkout/add-on`;
  const body = {
    accessPass: accessPassSlug,
    bonusPasses: bonusPassSlugs,
    nonce,
    customerId: customer.customerId,
    trialDuration,
    deviceData,
    email: customer.email,
    lifetimeEventData
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

export async function resubscribe(
  accessPassSlug: CraftSlug,
  removeSlugs: ReadonlyArray<CraftSlug>,
  nonce: string,
  deviceData: string,
  lifetimeEventData?: string | undefined
): Promise<void> {
  const url = `/actions/sbl-module/checkout/resubscribe`;
  const body = {
    accessPass: accessPassSlug,
    remove: removeSlugs,
    nonce,
    deviceData,
    lifetimeEventData
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

export async function upgrade(
  sourcePassSlug: CraftSlug | undefined,
  destinationPassSlug: CraftSlug,
  endSourceNow: boolean,
  startDestinationNow: boolean,
  bonusPassSlugs: ReadonlyArray<CraftSlug>,
  nonce: string,
  deviceData: string,
  lifetimeEventData?: string | undefined
): Promise<void> {
  const url = `/actions/sbl-module/checkout/upgrade`;
  const body = {
    source: sourcePassSlug,
    destination: destinationPassSlug,
    endSourceNow,
    startDestinationNow,
    bonusPasses: bonusPassSlugs,
    nonce,
    deviceData,
    lifetimeEventData
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

/**
 * Update the specified subscription for the currently logged in user,
 * and the specified access pass, to use the specified Braintree payment
 * method.
 */
export async function update(accessPassId: string, paymentMethodNonce: string): Promise<void> {
  const url = `/actions/sbl-module/checkout/update`;
  const body = {
    accessPass: accessPassId,
    nonce: paymentMethodNonce
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

/**
 * Cancel the specified access pass and subscription for the currently logged
 * in user.
 */
export async function cancel(
  accessPassSlug: string,
  lifetimeEventData?: string | undefined
): Promise<void> {
  const url = `/actions/sbl-module/checkout/cancel`;
  const body = {
    accessPass: accessPassSlug,
    lifetimeEventData
  };
  await postAction<boolean>(url, body, VALIDATION_BOOLEAN);
}

/**
 * Find the default Braintree plan for the specified access pass and
 * return the number of trial days in its configuration.
 */
export async function getTrialDuration(
  planId: AccessPassPaymentProviderId
): Promise<number | null> {
  if (planId === null) {
    throw new Error('Plan id is undefined');
  }

  const url = '/actions/sbl-module/checkout/get-trial-duration';
  const result = await postAction<number>(url, {planId}, VALIDATION_POSITIVE_INTEGER);
  return result;
}
