import {ImgixImageResponsiveWidth} from '../../core/imgix-image/types';
import {getImageSizes} from './implementation/carousel-utils';

/**
 * This setting controls the number of layout system columns the cards will span
 * at various viewport widths.
 */
export type CarouselCardSize = 'lg' | 'sm' | 'xs' | '2xs';

/** Card aspect ratio in the format 'w:h' where w is the width and h is the height. */
export type CarouselCardAspectRatio = string;

/**
 * Given a carousel card size, return the corresponding responsive image widths.
 */
export function getCarouselImageWidths(
  cardSize: CarouselCardSize
): Readonly<ImgixImageResponsiveWidth> {
  return getImageSizes(cardSize);
}
