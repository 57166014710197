<template>
  <card v-if="title !== undefined && title !== null" :url="url" :body-link="false">
    <template #hero>
      <card-hero-image v-if="image !== undefined" :config="image" />
    </template>
    <template #body>
      <div class="pt-fl-2xs">
        <div class="w-full font-semibold text-light text-base">
          {{ title }}
        </div>
        <div class="text-mid text-sm">
          {{ summary }}
        </div>
      </div>
    </template>
  </card>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {MemberDiscount} from '../../../../backend/content/member-discounts/member-discount-types';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default {
  components: {
    Card,
    CardHeroImage
  },
  props: {
    memberDiscount: {type: Object as PropType<MemberDiscount>, required: true},
    studentCode: {type: String, default: undefined},
    preview: {type: Boolean, required: true},
    imageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true}
  },
  setup(props) {
    const image = getImageConfig(
      props.memberDiscount.imageCollection,
      ['cover'],
      props.memberDiscount.title,
      props.imageWidth
    );

    const title = computed(() => {
      let title = props.memberDiscount.summary;
      const restrictions = props.memberDiscount.memberDiscountRestrictions;
      if (restrictions !== undefined && restrictions !== null && restrictions.length > 0) {
        title += ` (${restrictions})`;
      }
      return title;
    });

    const summary = computed(() => {
      let summary = '';

      if (props.preview) {
        return 'Member only discount';
      }

      const code = props.memberDiscount.memberDiscountCode;
      if (code !== undefined && code !== null && code.length > 0) {
        summary += `Partner code: ${code}`;
      } else {
        summary += `Student code: ${props.studentCode}`;
      }
      return summary;
    });

    const url = computed(() => {
      if (props.preview) {
        return undefined;
      }
      return props.memberDiscount.memberDiscountUrl;
    });

    return {image, summary, title, url};
  }
};
</script>
