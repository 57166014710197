import {computed, ComputedRef, Ref, ref} from 'vue';
import MiniSearch from 'minisearch';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';

export interface ContentEntrySearchComposition {
  /** The string of terms to search for. Mutate this to update the search result. */
  searchTerm: Ref<string | undefined>;

  /** The result of applying the search. */
  searchOutput: ComputedRef<ReadonlyArray<ContentEntry>>;

  /** Utility function that clears the search term. */
  clear: () => void;
}

/**
 * Return a Vue composition that filters the specified list of content entries
 * based on text search. Titles and summaries are searched.
 */
export function useSearch(
  contentEntries: Ref<ReadonlyArray<ContentEntry>>
): Readonly<ContentEntrySearchComposition> {
  /*
    We use a package called 'MiniSearch' as the search engine; see

    https://github.com/lucaong/minisearch

    MiniSearch needs a "document database" to search, so we need to
    rebuild it whenever the set of input content entries change.
  */
  const miniSearch = computed(() => {
    // ### Search other fields too? E.g., tutor?
    const ms = new MiniSearch({
      fields: ['title', 'summary'], // The fields to index.
      storeFields: ['id'] // The field/s to return in search results.
    });

    ms.addAll(
      contentEntries.value.map(ce => {
        return {
          id: ce.id,
          title: ce.title,
          summary: ce.summary
        };
      })
    );
    return ms;
  });

  // The string that contains the search term.
  const searchTerm = ref(undefined as string | undefined);

  /*
    The output from MiniSearch. This is in its own computed property
    so we could use it for somehting else in the future, e.g., implement
    higlighting of the search results.
  */
  /* ### Implement highlighting? */
  const miniSearchResults = computed(() => {
    if (searchTerm.value === undefined || searchTerm.value === '') {
      return undefined;
    }
    return miniSearch.value.search(searchTerm.value, {prefix: true});
  });

  /* The content entries that match the search. */
  const searchOutput = computed(() => {
    // If no search term, just return all input entries.
    if (
      searchTerm.value === undefined ||
      searchTerm.value === '' ||
      miniSearchResults.value === undefined
    ) {
      return contentEntries.value;
    }

    const result = miniSearchResults.value;
    const ids = result.map(r => r.id);
    return contentEntries.value.filter(ce => {
      return ids.includes(ce.id);
    });
  });

  const clear = () => {
    searchTerm.value = undefined;
  };

  return {
    searchTerm,
    searchOutput,
    clear
  };
}
