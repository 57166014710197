<template>
  <page-section :margin-top="'xs'">
    <div class="ml-auto mr-auto max-w-[1920px] flex justify-center items-start">
      <lesson-nav-button
        v-if="previousUrl !== undefined"
        :direction="'prev'"
        :url="previousUrl"
        :size="'2xl'"
        :type="'angle'"
        class="px-fl-sm-2xl pt-fl-sm"
      />
      <div v-else class="w-full max-w-[140px]"></div>

      <repel
        :gap="'gap-x-fl-2xl'"
        :items="'items-start'"
        :wrap="false"
        class="w-full max-w-[1640px]"
      >
        <div class="flex flex-col gap-fl-xs items-start">
          <standard-heading :size="3">{{ lesson.title }}</standard-heading>
          <cluster :gap="'gap-y-fl-xs'">
            <content-entry-header-tutors :content-entry="lesson" :size="'sm'" class="mr-fl-lg" />
            <content-entry-header-completed
              :progress="progress.progress.value"
              :spot-color="spotColor"
              class="mr-fl-lg"
            />
            <content-entry-header-levels :content-entry="lesson" />
            <content-entry-header-subjects :content-entry="lesson" />
            <content-entry-header-beginner-journey :trails="trails" />
          </cluster>
        </div>

        <cluster
          :wrap="false"
          :justify="'justify-end'"
          :items="'items-start'"
          :gap="'gap-fl-lg'"
          class="pt-fl-xs"
        >
          <content-entry-header-campus :guest="guest" :content-entry="lesson" />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="lesson"
            :icon-only="true"
            :bookmark="bookmark"
          />
          <content-entry-header-progress-toggle
            :guest="guest"
            :content-entry="lesson"
            :progress="progress"
            :color="progress.progress.value === 100 ? 'dark' : 'lightest'"
          />
        </cluster>
      </repel>

      <lesson-nav-button
        v-if="nextUrl !== undefined"
        :direction="'next'"
        :url="nextUrl"
        :size="'2xl'"
        :type="'angle'"
        class="px-fl-sm-2xl pt-fl-sm"
      />
      <div v-else class="w-full max-w-[140px]"></div>
    </div>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderLevels from '../../../content-entry/partials/header/metadata/ContentEntryHeaderLevels.vue';
import ContentEntryHeaderSubjects from '../../../content-entry/partials/header/metadata/ContentEntryHeaderSubjects.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderCompleted from '../../../content-entry/partials/header/metadata/ContentEntryHeaderCompleted.vue';
import ContentEntryHeaderProgressToggle from '../../../content-entry/partials/header/actions/ContentEntryHeaderProgressToggle.vue';
import LessonNavButton from './LessonNavButton.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {ColorConfig} from '../../../core/color-config';
import {Lesson} from '../../../../backend/content/lesson/lesson-types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';

export default defineComponent({
  components: {
    PageSection,
    Repel,
    Cluster,
    StandardHeading,
    ContentEntryHeaderTutors,
    ContentEntryHeaderLevels,
    ContentEntryHeaderSubjects,
    ContentEntryHeaderBeginnerJourney,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    ContentEntryHeaderProgressToggle,
    ContentEntryHeaderCompleted,
    LessonNavButton
  },
  props: {
    guest: {type: String, required: true},
    lesson: {type: Object as PropType<Readonly<Lesson>>, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true},
    previousUrl: {type: String as PropType<CraftUrl>, default: undefined},
    nextUrl: {type: String as PropType<CraftUrl>, default: undefined}
  },
  setup() {}
});
</script>
