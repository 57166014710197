<template>
  <variants>
    <template #narrow>
      <content-column>
        <div class="flex flex-col gap-fl-xl">
          <players-path-level-tips-and-tricks v-if="!level.preview && !guest" />
          <content-entry-header-unlock
            v-else-if="guest"
            :guest="guest"
            :content-entry="level"
            :size="'lg'"
            :expand="true"
          />
          <div class="flex gap-fl-xl items-center">
            <content-entry-header-bookmark
              v-if="bookmark !== undefined"
              :guest="guest"
              :content-entry="level"
              :bookmark="bookmark"
            />
            <content-entry-header-campus :guest="guest" :content-entry="level" />
            <standard-divider
              v-if="!level.preview && !guest"
              :orientation="'vertical'"
              class="border-dark"
            />
            <icon-label-button
              :on-click="resetLevel"
              :icon="iconReset"
              :label="'Reset'"
              :color="'dark'"
              :color-label="'dark'"
              :tooltip="'Reset Level'"
            />
          </div>
        </div>
      </content-column>
    </template>
    <template #sm+>
      <content-column>
        <div class="flex gap-fl-xl items-center">
          <players-path-level-tips-and-tricks v-if="!level.preview && !guest" />
          <content-entry-header-unlock
            v-else-if="guest"
            :guest="guest"
            :content-entry="level"
            :size="'md'"
          />
          <content-entry-header-bookmark
            v-if="bookmark !== undefined"
            :guest="guest"
            :content-entry="level"
            :bookmark="bookmark"
          />
          <content-entry-header-campus :guest="guest" :content-entry="level" />
          <standard-divider
            v-if="!level.preview && !guest"
            :orientation="'vertical'"
            class="border-dark"
          />
          <icon-label-button
            :on-click="resetLevel"
            :icon="iconReset"
            :label="'Reset'"
            :color="'dark'"
            :color-label="'dark'"
            :tooltip="'Reset Level'"
          />
        </div>
      </content-column>
    </template>
  </variants>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {useModalDialog} from '../../../vue-composition/modal-dialog/modal-dialog';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {setQuizIncomplete} from '../../../../backend/content/quiz/quiz-query';
import {redirectWithHistory} from '../../../../utils/url';
import {faArrowRotateLeft} from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import PlayersPathResetLevelDialog from './PlayersPathResetLevelDialog.vue';
import Variants from '../../../core/column-layout/Variants.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import IconLabelButton from '../../../core/button/IconLabelButton.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import PlayersPathLevelTipsAndTricks from './PlayersPathLevelTipsAndTricks.vue';
import ContentEntryHeaderUnlock from '../../../content-entry/partials/header/actions/ContentEntryHeaderUnlock.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';

export default defineComponent({
  components: {
    Variants,
    ContentColumn,
    IconLabelButton,
    StandardDivider,
    PlayersPathLevelTipsAndTricks,
    ContentEntryHeaderUnlock,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus
  },
  props: {
    guest: {type: String, required: true},
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, default: undefined}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const resetDialog = useModalDialog(PlayersPathResetLevelDialog);
    const resetLevel = () => {
      resetDialog.show(
        async () => {
          loader.setLoading(true);
          for (let i = 0; i < props.level.quizzes.length; i += 1) {
            await setQuizIncomplete(props.level.quizzes[i]);
          }
          redirectWithHistory('/players-path');
        },
        () => {}
      );
    };
    const iconReset = computed(() => faArrowRotateLeft);

    return {
      resetLevel,
      iconReset
    };
  }
});
</script>
