import * as Joi from 'joi';
import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {craftUrl} from '../../utils/url';
import {CraftSection} from '../craft/section/section-types';
import {cachedValidatedHttpGet} from '../http';
import {VALIDATION_NON_EMPTY_STRING} from '../validation';

const SEARCH_SECTION_VALIDATION_SCHEMA = Joi.object({
  handle: VALIDATION_NON_EMPTY_STRING,
  title: VALIDATION_NON_EMPTY_STRING,
  type: VALIDATION_NON_EMPTY_STRING
});

const SEARCH_SECTION_ARRAY_VALIDATION_SCHEMA = Joi.array().items(SEARCH_SECTION_VALIDATION_SCHEMA);

/* Because fetching the search sections can happen several times on a page, we cache them. */
export type SearchSections = ReadonlyArray<CraftSection>;
const searchSectionsCache = new ThreadSyncCache<SearchSections>();

/**
 * Return the handles and titles of the sections that should be included
 * when the user searches for entries. NOTE: These sections are defined
 * in the category group called "Search sections".
 */
export async function getSearchSections(): Promise<Readonly<SearchSections>> {
  // ### Replace cachedValidatedHttpGet() with cachedPostAction()
  const url = craftUrl('/actions/sbl-module/search/get-search-sections');
  try {
    const result = await cachedValidatedHttpGet<SearchSections>(
      url,
      SEARCH_SECTION_ARRAY_VALIDATION_SCHEMA,
      searchSectionsCache,
      'searchSections'
    );
    return result;
  } catch (error) {
    throw new Error(`Could not get search sections: ${error}`);
  }
}
