import {Ref} from 'vue';
import {useSort} from '../../../../../vue-composition/sort/sort';
import {ContentEntry} from '../../../../../../backend/content/content-entry/content-entry-types';
import {ContentEntrySortScheme} from '../../../../../vue-composition/sort/types';
import {getItem, setItem} from '../../../../../../utils/compressed-local-storage';

export function getCollectionSort(
  contentEntries: Ref<ReadonlyArray<ContentEntry>>,
  schemes: ReadonlyArray<ContentEntrySortScheme>,
  localStorageKey?: string
) {
  let init: ContentEntrySortScheme | undefined = undefined;
  if (localStorageKey !== undefined) {
    const value = getItem(`sbl-srt-${localStorageKey}`);
    if (value !== null) {
      init = value as ContentEntrySortScheme;
    }
  }
  const onChange = (scheme: ContentEntrySortScheme) => {
    if (localStorageKey !== undefined) {
      setItem(`sbl-srt-${localStorageKey}`, scheme);
    }
  };
  return useSort(contentEntries, schemes, init, onChange);
}
