<template>
  <page-content v-if="course !== undefined && trails !== undefined">
    <page-section>
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header>{{ course.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="course" />
          <content-entry-header-duration v-if="duration !== undefined" :duration="duration" />
          <content-entry-header-levels-and-subjects :content-entry="course" />
          <content-entry-header-beginner-journey :trails="trails" />
        </cluster>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <content-entry-header-progress :guest="guest" :progress="progress" />
      </content-column>
    </page-section>

    <page-section>
      <content-entry-header-actions
        :guest="guest"
        :content-entry="course"
        :label="'Course'"
        :progress="progress"
        :bookmark="bookmark"
      />
    </page-section>

    <course-modules :guest="guest" :course="course" :progress="progress" :bookmark="bookmark" />

    <content-entry-resource-list :guest="guest" :content-entry="course" :margin-top="'xl'" />

    <course-tutors :course="course" />

    <content-entry-trailer
      v-if="course.videos[0] !== undefined"
      :guest="guest"
      :content-entry="course"
      :heading="'Trailer'"
    />

    <page-section v-if="course.summary !== null || course.richText.length > 0" :margin-top="'xl'">
      <course-summary :guest="guest" :course="course" />
    </page-section>

    <page-section v-if="course.recommendations.length > 0" :margin-top="'xl'">
      <content-column>
        <standard-divider :class="'border-dark'" />
      </content-column>
    </page-section>

    <content-entry-recommendations :guest="guest" :content-entry="course" :bookmark="bookmark" />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Course} from '../../../../backend/content/course/course-types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeader from '../../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderDuration from '../../../content-entry/partials/header/metadata/ContentEntryHeaderDuration.vue';
import ContentEntryHeaderLevelsAndSubjects from '../../../content-entry/partials/header/metadata/ContentEntryHeaderLevelsAndSubjects.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryHeaderActions from '../../../content-entry/partials/header/ContentEntryHeaderActions.vue';
import ContentEntryHeaderProgress from '../../../content-entry/partials/header/metadata/ContentEntryHeaderProgress.vue';
import ContentEntryTrailer from '../../../content-entry/partials/body/ContentEntryTrailer.vue';
import ContentEntryResourceList from '../../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../../../content-entry/partials/body/ContentEntryRecommendations.vue';
import CourseModules from '../partials/CourseModules.vue';
import CourseSummary from '../partials/CourseSummary.vue';
import CourseTutors from '../partials/CourseTutors.vue';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Cluster,
    StandardDivider,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeader,
    ContentEntryHeaderTutors,
    ContentEntryHeaderDuration,
    ContentEntryHeaderLevelsAndSubjects,
    ContentEntryHeaderBeginnerJourney,
    ContentEntryHeaderActions,
    ContentEntryHeaderProgress,
    ContentEntryTrailer,
    ContentEntryResourceList,
    ContentEntryRecommendations,
    CourseModules,
    CourseSummary,
    CourseTutors
  },
  props: {
    guest: {type: String, required: true},
    course: {type: Object as PropType<Readonly<Course>>, required: true},
    duration: {type: Number, default: undefined},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup() {}
});
</script>
