<template>
  <page-content v-if="interview !== undefined">
    <page-section v-if="trails">
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header :id="'interview-title'">{{ interview.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="interview" />
          <content-entry-header-post-date :content-entry="interview" />
          <content-entry-header-bookmark
            :display="'linkIcon'"
            :guest="guest"
            :content-entry="interview"
            :bookmark="bookmark"
          />
          <content-entry-header-campus
            :guest="guest"
            :content-entry="interview"
            :display="'linkIcon'"
          />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column>
        <content-entry-video :guest="guest" :content-entry="interview" :video-type="'content'" />
      </content-column>
    </page-section>

    <page-section v-if="audioUrl !== undefined" :margin-top="'md'">
      <content-column>
        <iframe
          :src="audioUrl"
          loading="lazy"
          width="100%"
          height="200"
          frameborder="0"
          scrolling="no"
          :title="interview.title"
        ></iframe>
      </content-column>
    </page-section>

    <content-entry-summary :width="'narrow'" :content-entry="interview" />

    <content-entry-recommendations
      :width="'narrow'"
      :margin-top="'xl'"
      :heading="'Recommended Content'"
      :guest="guest"
      :content-entry="interview"
      :bookmark="bookmark"
    />
  </page-content>
</template>

<script lang="ts">
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import {getInterviewBySlug} from '../../../backend/content/interview/interview-query';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {Video} from '../../../backend/video/video-types';
import Cluster from '../../core/compositions/Cluster.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderPostDate from '../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntrySummary from '../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryRecommendations from '../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntryVideo from '../../content-entry/partials/body/ContentEntryVideo.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';

export default defineComponent({
  components: {
    Cluster,
    ContentColumn,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeaderCampus,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderTutors,
    ContentEntryHeader,
    ContentEntrySummary,
    ContentEntryRecommendations,
    ContentEntryVideo,
    PageContent,
    PageSection
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const interview = ref(undefined as Readonly<ContentEntry> | undefined);
    const video = ref(undefined as Readonly<Video> | undefined);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);

    const bookmark = useBookmark(props.guest);

    const audioUrl = computed(() => {
      if (interview.value === undefined) {
        return undefined;
      }
      const resource = interview.value.resources[0];
      if (resource === undefined) {
        return undefined;
      }
      const url = interview.value.resources[0].resourceUrl;
      const dropExtension = url.replace(/\.[^/.]+$/, '');
      return `${dropExtension}?client_source=small_player&iframe=true`;
    });

    onMounted(async () => {
      loader.setLoading(true);
      interview.value = await getInterviewBySlug(props.slug);
      if (interview.value !== undefined) {
        trails.value = await useBreadcrumbTrails(interview.value, props.entryBreadcrumbTrails);
        video.value = interview.value.videos[0];
        await bookmark.init();
      }
      loader.setLoading(false);
    });

    return {
      bookmark,
      interview,
      video,
      audioUrl,
      trails
    };
  }
});
</script>
