<template>
  <button
    :title="tooltip"
    :aria-label="tooltip"
    class="w-full h-full"
    @click="audioPlayer.togglePlay"
  >
    <svg viewBox="0 0 30 30" class="w-full h-full">
      <g>
        <!-- play -->
        <polygon
          v-if="!audioPlayer.isPlaying.value"
          fill="white"
          stroke="white"
          stroke-width="6"
          stroke-linejoin="round"
          stroke-linecap="round"
          points="3,3 3,27 25,15"
        ></polygon>
        <g v-else>
          <!-- pause -->
          <polygon
            fill="white"
            stroke="white"
            stroke-width="6"
            points="6,3 6,27 9,27 9,3"
            stroke-linejoin="round"
            stroke-linecap="round"
          ></polygon>
          <polygon
            fill="white"
            stroke="white"
            stroke-width="6"
            points="21,3 21,27 24,27 24,3"
            stroke-linejoin="round"
            stroke-linecap="round"
          ></polygon>
        </g>
      </g>
    </svg>
  </button>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {AudioPlayerComposition} from '../internals/utils';

export default {
  components: {},
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true}
  },
  setup(props) {
    const tooltip = computed(() => (props.audioPlayer.isPlaying.value ? 'Pause' : 'Play'));
    return {
      tooltip
    };
  }
};
</script>
