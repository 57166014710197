<template>
  <div>
    <page-section :margin-top="'sm'">
      <standard-heading :size="2">Login to SBL</standard-heading>
    </page-section>
    <page-section :margin-top="'md'">
      <input
        id="loginName"
        v-model="loginName"
        placeholder="Username or Email"
        class="mb-fl-md w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
        type="text"
        :autofocus="loginName === undefined || loginName === null || loginName.length === 0"
        @keyup.enter="keyup"
      />

      <input
        id="password"
        v-model="password"
        placeholder="Password"
        class="mb-fl-md w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
        type="password"
        :autofocus="loginName !== undefined && loginName !== null && loginName.length > 0"
        @keyup.enter="keyup"
      />

      <cluster class="mb-fl-xl" :gap="'gap-fl-sm'">
        <checkbox
          id="rememberMe"
          :tooltip="'Remember me'"
          :checked="rememberMe"
          :on-clicked="toggleRememberMe"
          class="text-fl-4xl"
        />
        <label for="rememberMe" class="text-fl-xl text-white font-semibold select-none"
          >Remember me</label
        >
      </cluster>

      <div id="loginButton">
        <standard-button
          :id="'loginButton'"
          :size="'lg'"
          :expand="true"
          :color="'invert-primary'"
          :on-click="submit"
          :label="'Login'"
          class="mb-fl-sm"
        />
      </div>
      <centered-column>
        <link-button
          :url="'/reset-password'"
          :label="'Forgot your password?'"
          :underline="true"
          class="mb-fl-lg"
        />
      </centered-column>

      <div v-if="errorMessage !== undefined" class="pb-fl-sm text-primary">
        {{ errorMessage }}
      </div>
    </page-section>

    <page-section :margin-top="'none'" :margin-bottom="'none'">
      <standard-divider class="border-[rgb(112,112,112)]" :dashed="true" />
    </page-section>

    <page-section :margin-top="'sm'">
      <div class="flex flex-col items-center">
        <div class="text-fl-5xl text-white font-semibold text-center">
          Not a member yet? Join us!
        </div>
        <div class="pt-fl-lg">
          <standard-button
            :url="'/sign-up'"
            :size="'sm'"
            :spot-color="spotColor"
            :label="'Grab a Free Trial'"
          />
        </div>
      </div>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {httpPost} from '../../../../backend/http';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {getUrlQueryParams, redirectNoHistory} from '../../../../utils/url';
import PageSection from '../../../core/page/PageSection.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import Checkbox from '../../../core/button/Checkbox.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {ColorConfig} from '../../../core/color-config';

export default defineComponent({
  components: {
    PageSection,
    Cluster,
    LinkButton,
    StandardButton,
    Checkbox,
    CenteredColumn,
    StandardHeading,
    StandardDivider
  },
  props: {
    rememberedUserName: {type: String, default: ''}
  },
  setup(props) {
    const errorMessage = ref(undefined as string | undefined);
    const loader = useFullScreenLoader();
    const loginName = ref(props.rememberedUserName);
    const password = ref('');
    const rememberMe = ref(true);

    const keyup = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        await submit();
      }
    };

    const submit = async () => {
      loader.setLoading(true);
      const url = 'actions/users/login';
      const params = {
        loginName: loginName.value,
        password: password.value,
        rememberMe: rememberMe.value ? '1' : '0'
      };
      // ### This should be in a stand-alone function.
      try {
        const {ok, bodyJson} = await httpPost(url, params);
        if (ok) {
          // Login is successful; clear any error message.
          errorMessage.value = undefined;

          // If we've been given a page to redirect to, go there. Otherwise, go to the dashboard.
          const params = getUrlQueryParams();
          const redirect = params.get('redirect');
          if (redirect !== undefined && redirect !== null) {
            redirectNoHistory(redirect);
          } else {
            redirectNoHistory('/');
          }
        } else {
          loader.setLoading(false);

          interface LoginActionRequestResponse {
            message: string | unknown;
          }
          const response = bodyJson as LoginActionRequestResponse;
          // Login is unsuccessful. Set an error message to let the user know what the problem is.
          if (response.message !== undefined) {
            errorMessage.value = `Login failed: ${response.message}`;
          } else {
            errorMessage.value = 'Login failed';
          }
        }
      } catch (error) {
        loader.setLoading(false);

        // The HTTP request failed, or Craft didn't return JSON. Display a generic error messsage.
        console.error(error);
        errorMessage.value = 'Login failed';
      }
    };

    const toggleRememberMe = () => {
      rememberMe.value = !rememberMe.value;
    };

    const spotColor: Readonly<ColorConfig> = {
      text: 'text-primary' /* E.g., 'text-red' */,
      textHover: 'hover:text-black' /* E.g., 'hover:text-red' */,

      bg: 'bg-black' /* E.g., 'bg-red' */,
      bgHover: 'hover:bg-primary' /* E.g.m 'hover:bg-red' */,

      border: 'border-primary' /* E.g., 'border-red' */,
      borderHover: 'hover:border-primary' /* E.g., 'hover:border-red' */
    };

    return {
      keyup,
      submit,
      loginName,
      password,
      rememberMe,
      toggleRememberMe,
      errorMessage,
      spotColor
    };
  }
});
</script>
