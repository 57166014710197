<template>
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'">
    <content-column :width="'wide'">
      <standard-heading v-if="deviceType.screen.value === 'narrow'" :size="2">{{
        timelineHeading
      }}</standard-heading>
      <standard-heading v-else :size="1">{{ timelineHeading }}</standard-heading>
    </content-column>
  </page-section>

  <page-section
    :padding-top="'md'"
    :padding-bottom="'xl'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="text-white"
  >
    <content-column :width="'wide'" :class="'text-fl-3xl'">
      <standard-heading :size="3">{{ timelineYear1 }}</standard-heading>
      <p class="mb-fl-lg">
        {{ timelineText1 }}
      </p>

      <standard-heading :size="3" :spot-color="yearSpotColor">{{ timelineYear2 }}</standard-heading>
      <p class="mb-fl-lg text-light">{{ timelineText2 }}</p>

      <standard-heading :size="3">{{ timelineYear3 }}</standard-heading>
      <p class="mb-fl-lg">
        {{ timelineText3 }}
      </p>

      <standard-heading :size="3" :spot-color="yearSpotColor">{{ timelineYear4 }}</standard-heading>
      <p class="text-light mb-fl-lg">{{ timelineText4 }}</p>

      <standard-heading :size="3">{{ timelineYear5 }}</standard-heading>
      <p class="mb-fl-lg">
        {{ timelineText5 }}
      </p>

      <standard-heading :size="3" :spot-color="yearSpotColor">{{ timelineYear6 }}</standard-heading>
      <p class="text-light mb-fl-lg">
        {{ timelineText6 }}
      </p>

      <standard-heading :size="3">{{ timelineYear7 }}</standard-heading>
      <p class="mb-fl-lg">{{ timelineText7 }}</p>

      <standard-heading :size="3" :spot-color="yearSpotColor">{{ timelineYear8 }}</standard-heading>
      <p class="text-light mb-fl-lg">
        {{ timelineText8 }}
      </p>

      <standard-heading :size="3">{{ timelineYear9 }}</standard-heading>
      <p class="mb-fl-lg">
        {{ timelineText9 }}
      </p>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {
  timelineHeading,
  timelineYear1,
  timelineYear2,
  timelineYear3,
  timelineYear4,
  timelineYear5,
  timelineYear6,
  timelineYear7,
  timelineYear8,
  timelineYear9,
  timelineText1,
  timelineText2,
  timelineText3,
  timelineText4,
  timelineText5,
  timelineText6,
  timelineText7,
  timelineText8,
  timelineText9
} from '../utils/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading
  },

  setup() {
    const deviceType = useDeviceType();
    const yearSpotColor = {
      bg: 'bg-black',
      bgHover: 'bg-black',
      text: 'text-light',
      textHover: 'hover:text-light',
      border: 'border-black',
      borderHover: 'hover:border-black'
    };

    return {
      deviceType,
      yearSpotColor,
      timelineHeading,
      timelineYear1,
      timelineYear2,
      timelineYear3,
      timelineYear4,
      timelineYear5,
      timelineYear6,
      timelineYear7,
      timelineYear8,
      timelineYear9,
      timelineText1,
      timelineText2,
      timelineText3,
      timelineText4,
      timelineText5,
      timelineText6,
      timelineText7,
      timelineText8,
      timelineText9
    };
  }
});
</script>
