import {CraftSlug} from '../craft/craft-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {Curation} from './curation-types';
import * as ContentEntryQueryBuilder from '../content/content-entry/content-entry-query-builder';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const CURATION_FIELDS = [
  QueryBuilder.matrix('curationSection')
    .required()
    .blocks([
      QueryBuilder.block('curationTopic').fields([
        QueryBuilder.plainText('curationTopicTitle').required(),
        ContentEntryQueryBuilder.contentEntries('curationTopicEntries')
      ])
    ])
];

export async function getAllCurations(): Promise<ReadonlyArray<Curation>> {
  const result = await fetchEntries<Curation>(
    QueryBuilder.entries().section(['curation']).fields(CURATION_FIELDS)
  );
  return result;
}

export async function getCurationBySlug(slug: CraftSlug): Promise<Readonly<Curation> | undefined> {
  const result = await fetchEntries<Curation>(
    QueryBuilder.entries().section(['curation']).slug([slug]).fields(CURATION_FIELDS)
  );
  const entry = result[0];
  return entry;
}
