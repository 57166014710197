<template>
  <content-column :width="width">
    <variants>
      <template #narrow-sm>
        <div class="flex flex-col justify-center gap-fl-sm-md">
          <div class="bg-darkest rounded-[0.5em] overflow-hidden">
            <slot name="left"></slot>
          </div>
          <div class="bg-darkest rounded-[0.5em] overflow-hidden">
            <slot name="right"></slot>
          </div>
        </div>
      </template>
      <template #md+>
        <div class="flex justify-center gap-fl-sm-md">
          <div class="basis-1/2 bg-darkest rounded-[0.5em] overflow-hidden">
            <slot name="left"></slot>
          </div>
          <div class="basis-1/2 bg-darkest rounded-[0.5em] overflow-hidden">
            <slot name="right"></slot>
          </div>
        </div>
      </template>
    </variants>
  </content-column>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';
import Variants from '../../core/column-layout/Variants.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    Variants,
    ContentColumn
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'}
  },
  setup() {
    return {};
  }
});
</script>
