<template>
  <page-section :margin-top="'sm'">
    <content-column :width="width" :justify="justify">
      <div :class="imageJustify">
        <figure>
          <img :class="imageWidth" :src="src" :style="widthStyle" />
          <figcaption v-if="caption !== undefined">{{ caption }}</figcaption>
        </figure>
      </div>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {RichTextBlock, RichTextImage} from '../../../../backend/rich-text/rich-text-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn
  },
  props: {
    richTextImage: {type: Object as PropType<RichTextBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const img = computed(() => {
      return props.richTextImage as RichTextImage;
    });
    // ### Use responsive image here (srcset, <imgix-image>, etc.).
    const src = computed(() => {
      const url = `https://scottsbasslessons.imgix.net/${img.value.richTextImageFilename}?auto=format`;
      return url;
    });
    const caption = img.value.richTextImageCaption;
    const imageWidth = computed(() => {
      if (img.value.richTextImageWidth === 'full') {
        return 'w-full';
      } else {
        return 'object-scale-down';
      }
    });
    const imageJustify = computed(() => {
      if (img.value.richTextImageCenter) {
        return 'flex justify-center';
      }
      return undefined;
    });
    const widthStyle = computed(() => {
      if (img.value.richTextImageMaxWidth !== null) {
        return `max-width:${img.value.richTextImageMaxWidth}px;`;
      }
      return undefined;
    });
    return {
      src,
      caption,
      imageWidth,
      imageJustify,
      widthStyle
    };
  }
});
</script>
