<template>
  <variants>
    <template #narrow-sm>
      <div class="p-fl-lg border-2 border-primary rounded-[0.5em]">
        <div class="flex flex-col justify-items-center content-center items-center gap-y-fl-lg">
          <div class="flex flex-col justify-center gap-fl-sm">
            <div class="text-fl-3xl text-white font-semibold">
              Don't miss your FREE Bass Toolkit
            </div>
            <div class="text-fl-xl text-lightest">
              Unlock this collection of must-have secrets to level up your bass play. My gift to
              you!
            </div>
          </div>
          <div class="w-full flex justify-center">
            <standard-button
              :url="'https://play.scottsbasslessons.com/free-bass-toolkit/'"
              :expand="true"
              :size="'md'"
              :label="'Get my toolkit →'"
              :color="'primary'"
            />
          </div>
        </div>
      </div>
    </template>
    <template #md>
      <div class="px-fl-2xl py-fl-lg border-2 border-primary rounded-xl">
        <div class="flex flex-wrap justify-items-center content-center items-center gap-y-fl-lg">
          <div class="basis-1/2 grow flex flex-col justify-center gap-fl-sm">
            <div class="text-fl-3xl text-white font-semibold">
              Don't miss your FREE Bass Toolkit
            </div>
            <div class="text-fl-xl text-lightest">
              Unlock this collection of must-have secrets to level up your bass play. My gift to
              you!
            </div>
          </div>
          <div class="basis-1/2 grow flex justify-end">
            <standard-button
              :url="'https://play.scottsbasslessons.com/free-bass-toolkit/'"
              :label="'Get my FREE toolkit →'"
              :color="'primary'"
            />
          </div>
        </div>
      </div>
    </template>
    <template #lg-xl>
      <div class="px-fl-2xl py-fl-lg border-2 border-primary rounded-xl">
        <div class="flex flex-wrap justify-items-center content-center items-center gap-y-fl-lg">
          <div class="basis-1/2 grow flex flex-col justify-center gap-fl-sm">
            <div class="text-fl-3xl text-white font-semibold">
              Don't miss your FREE Bass Toolkit
            </div>
            <div class="text-fl-xl text-lightest">
              Unlock this collection of must-have secrets to level up your bass play. My gift to
              you!
            </div>
          </div>
          <div class="basis-1/2 grow flex justify-end">
            <standard-button
              :url="'https://play.scottsbasslessons.com/free-bass-toolkit/'"
              :size="'lg'"
              :label="'Get my FREE toolkit →'"
              :color="'primary'"
            />
          </div>
        </div>
      </div>
    </template>
    <template #2xl>
      <div class="px-fl-2xl py-fl-lg border-2 border-primary rounded-xl">
        <div class="flex flex-wrap justify-items-center content-center items-center gap-y-fl-lg">
          <div class="basis-1/2 grow flex flex-col justify-center gap-fl-sm">
            <div class="text-fl-3xl text-white font-semibold">
              Don't miss your FREE Bass Toolkit
            </div>
            <div class="text-fl-xl text-lightest">
              Unlock this collection of must-have secrets to level up your bass play. My gift to
              you!
            </div>
          </div>
          <div class="basis-1/2 grow flex justify-end">
            <standard-button
              :url="'https://play.scottsbasslessons.com/free-bass-toolkit/'"
              :size="'md'"
              :label="'Get my FREE toolkit →'"
              :color="'primary'"
            />
          </div>
        </div>
      </div>
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Variants from '../../core/column-layout/Variants.vue';
import StandardButton from '../../core/button/StandardButton.vue';

export default defineComponent({
  components: {
    Variants,
    StandardButton
  },
  setup() {}
});
</script>
