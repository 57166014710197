import {fetchContentEntries} from '../content/content-entry/content-entry-query';
import {contentEntries} from '../content/content-entry/content-entry-query-builder';
import {ContentEntry} from '../content/content-entry/content-entry-types';
import {CraftId, CraftSectionHandle} from '../craft/craft-types';
import {entryIsPast} from '../craft/entry/entry-utils';
import {getWebcasts} from './webcast-query';
import {Webcast} from './webcast-types';

export async function getCurrentWebcastContentEntriesInSection(
  sectionHandle: CraftSectionHandle
): Promise<ReadonlyArray<ContentEntry>> {
  const webcasts = await getWebcasts();
  const entryIds: Array<CraftId> = [];
  webcasts.forEach(webcast => {
    const entry = webcast.webcastEntry[0];
    if (entry !== undefined) {
      entryIds.push(entry.id);
    }
  });
  const result = await fetchContentEntries(contentEntries().section([sectionHandle]).id(entryIds));
  return result;
}

export async function getWebcastForEntry(entryId: CraftId): Promise<Readonly<Webcast> | undefined> {
  const onAir = await getWebcasts();
  const webcast = onAir.find(webcast => {
    if (webcast.webcastEntry[0] === undefined) {
      return false;
    }
    return webcast.webcastEntry[0].id === entryId;
  });
  return webcast;
}

/**
 * Return all currently active webcasts in the specified section.
 * These are entries in the list of webcasts that have post dates
 * in the past.
 */
export async function getActiveWebcasts(sectionHandle: CraftSectionHandle) {
  const webcasts = await getWebcasts();
  const entryIds = webcasts
    .filter(webcast => {
      const entry = webcast.webcastEntry[0];
      if (entry === undefined) {
        return false;
      }
      if (entry.sectionHandle !== sectionHandle) {
        return false;
      }
      return entryIsPast(entry);
    })
    .map(webcast => webcast.webcastEntry[0].id);
  const result = await fetchContentEntries(contentEntries().section([sectionHandle]).id(entryIds));
  return result;
}
