<template>
  <ol class="space-y-4 text-fl-3xl">
    <li><strong>Serve a global community</strong> of bass players and advance the art of bass.</li>
    <li><strong>Delight our audiences</strong> with an experience that blows their minds.</li>
    <li>
      <strong>Educate and inspire</strong> our members with best-in-class tools that build and
      reinforce their skills, at every stage of their musical lives and help them celebrate their
      accomplishments.
    </li>
    <li>
      <strong>Provide the confidence</strong> to help bass players embrace their talents. Write a
      song, join a band, make an album, play for a live audience &amp; achieve their ambitions.
    </li>
  </ol>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({});
</script>
