<template>
  <content-column :width="'wide'">
    <div :class="`grid ${cols}`">
      <slot />
    </div>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ContentColumn from './ContentColumn.vue';
import {deviceTypeSwitch} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    ContentColumn
  },
  props: {
    size: {type: String as PropType<'large' | 'small'>, default: 'large'}
  },
  setup(props) {
    const cols = computed(() => {
      if (props.size === 'large') {
        return deviceTypeSwitch<string>({
          narrow: 'grid-cols-[repeat(auto-fit,minmax(12rem,1fr))] gap-x-2 gap-y-4',
          sm: 'grid-cols-2 gap-x-2 gap-y-12',
          md: 'grid-cols-3 gap-x-2 gap-y-12',
          lg: 'grid-cols-4 gap-x-2 gap-y-12',
          xl: 'grid-cols-5 gap-x-2 gap-y-12'
        }).value;
      } else {
        return deviceTypeSwitch<string>({
          narrow: 'grid-cols-[repeat(auto-fit,minmax(12rem,1fr))] gap-x-2 gap-y-4',
          sm: 'grid-cols-2 gap-x-2 gap-y-12',
          md: 'grid-cols-4 gap-x-2 gap-y-12',
          lg: 'grid-cols-5 gap-x-2 gap-y-12',
          xl: 'grid-cols-6 gap-x-2 gap-y-12'
        }).value;
      }
    });
    return {
      cols
    };
  }
});
</script>
