<template>
  <page-content v-if="(preview || guest) && trails !== undefined">
    <page-section :margin-top="'2xs'">
      <content-column :width="'wide'">
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <p>Join SBL to access this Study Piece!</p>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <standard-button :url="'/players-path'" :label="'Back to Players Path'" />
      </content-column>
    </page-section>
  </page-content>

  <page-content
    v-else-if="
      trails !== undefined &&
      piece &&
      currentVideo !== undefined &&
      levelEntryQuiz &&
      level &&
      pieceQuiz &&
      spotColor
    "
  >
    <hr class="w-full border-dark" />

    <page-section :margin-top="'2xs'">
      <content-column :width="'wide'">
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section v-if="mediaBrowserItems.length > 0" :margin-top="'2xs'">
      <media-browser
        :guest="guest"
        :content-entry="piece"
        :video="currentVideo"
        :items="mediaBrowserItems"
        :sidebar-title="sidebarTitle"
        :sidebar-icon-type="'play'"
        :resume-at-last-timestamp="resumeAtLastTimestamp"
        :on-item-selected="onMediaBrowserItemSelected"
      >
        <players-path-media-browser-sidebar-button
          :level-quiz="levelEntryQuiz"
          :level-title="level.title"
          :piece-quiz="pieceQuiz"
          :preview-level="previewLevel"
          :spot-color="spotColor"
          :completed="completed"
        />
      </media-browser>
    </page-section>

    <page-section :margin-top="'sm'">
      <players-path-piece-title-bar
        :guest="guest"
        :piece="piece"
        :level="level"
        :heading="currentVideoTitle"
        :complete="completed"
        :spot-color="spotColor"
        :bookmark="bookmark"
        :trails="trails"
      />
    </page-section>

    <page-section v-if="previewLevel">
      <content-column :width="'wide'">
        <div class="text-center">
          This Study Piece belongs to a Players Path Level that you have not unlocked.
        </div>
      </content-column>
      <content-column :width="'wide'">
        <players-path-media-browser-sidebar-button
          :level-quiz="levelEntryQuiz"
          :level-title="level.title"
          :piece-quiz="pieceQuiz"
          :preview-level="previewLevel"
          :spot-color="spotColor"
          :completed="completed"
        />
      </content-column>
    </page-section>

    <variants v-if="mediaBrowserItems.length > 0">
      <template #lg->
        <page-section :margin-top="'sm'">
          <media-browser-navigator
            :items="mediaBrowserItems"
            :on-item-selected="onMediaBrowserItemSelected"
            :title="'Videos'"
            :sidebar-icon-type="'play'"
          >
            <players-path-media-browser-sidebar-button
              :level-quiz="levelEntryQuiz"
              :level-title="level.title"
              :piece-quiz="pieceQuiz"
              :preview-level="previewLevel"
              :spot-color="spotColor"
              :completed="completed"
            />
          </media-browser-navigator>
        </page-section>
      </template>
    </variants>

    <page-section :margin-top="'lg'">
      <content-entry-practice-tools
        v-if="songs !== undefined && !previewLevel"
        id="practiceTools"
        class="scroll-my-20"
        :preview="preview"
        :guest="guest"
        :preview-level="previewLevel"
        :songs="songs"
        :audio-url="piece.playersPathPieceBackingTrackUrl"
      >
        <variants>
          <template #lg+>
            <standard-heading id="resources" :size="3" class="scroll-my-20"
              >Downloads</standard-heading
            >
            <content-entry-resource-list-standalone
              class="mt-fl-md-lg"
              :preview="preview"
              :guest="guest"
              :content-entry="piece"
            >
            </content-entry-resource-list-standalone>
          </template>
        </variants>
      </content-entry-practice-tools>
    </page-section>

    <content-entry-summary
      id="summary"
      class="scroll-my-20"
      :margin-top="'xl'"
      :width="'narrow'"
      :justify="'left'"
      :content-entry="piece"
    />

    <content-entry-resource-list
      v-if="songs === undefined && !previewLevel"
      id="resources"
      class="scroll-my-20"
      :margin-top="'xl'"
      :guest="guest"
      :width="'narrow'"
      :justify="'left'"
      :content-entry="piece"
    />
    <variants v-else>
      <template #md->
        <content-entry-resource-list
          v-if="!previewLevel"
          id="resources"
          class="scroll-my-20"
          :margin-top="'xl'"
          :guest="guest"
          :width="'narrow'"
          :justify="'left'"
          :content-entry="piece"
        />
      </template>
    </variants>

    <content-entry-recommendations
      :guest="guest"
      :content-entry="piece"
      :bookmark="bookmark"
      :justify="'left'"
      :margin-top="'xl'"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {
  PlayersPathLevel,
  PlayersPathPiece
} from '../../../backend/content/players-path/players-path-types';
import {getPlayersPathPieceBySlug} from '../../../backend/content/players-path/players-path-query';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntrySummary from '../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryResourceList from '../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryResourceListStandalone from '../../content-entry/partials/body/ContentEntryResourceListStandalone.vue';
import ContentEntryRecommendations from '../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntryPracticeTools from '../../content-entry/partials/body/ContentEntryPracticeTools.vue';
import PlayersPathPieceTitleBar from './partials/PlayersPathPieceTitleBar.vue';
import PlayersPathMediaBrowserSidebarButton from './partials/PlayersPathMediaBrowserSidebarButton.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import Variants from '../../core/column-layout/Variants.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import MediaBrowser from '../../generic/media-browser/MediaBrowser.vue';
import MediaBrowserNavigator from '../../generic/media-browser/navigator/MediaBrowserNavigator.vue';
import {Video} from '../../../backend/video/video-types';
import {
  getAllVideosOfTypeDifferentThan,
  getFirstVideoOfType
} from '../../../backend/video/video-query';
import {CraftId} from '../../../backend/craft/craft-types';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {getPlayersPathLevelNumber, playersPathLevelSpotColor} from './utils';
import {
  getPlayersPathLevelByNumber,
  playersPathLevelIsUnlocked
} from '../../../backend/content/players-path/players-path-query-utils';
import {isQuizComplete} from '../../../backend/content/quiz/quiz-query';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {Quiz} from '../../../backend/content/quiz/quiz-types';

export default defineComponent({
  components: {
    Variants,
    ContentColumn,
    PageContent,
    PageSection,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryRecommendations,
    ContentEntryResourceList,
    ContentEntryResourceListStandalone,
    ContentEntrySummary,
    ContentEntryPracticeTools,
    PlayersPathPieceTitleBar,
    PlayersPathMediaBrowserSidebarButton,
    StandardHeading,
    StandardButton,
    MediaBrowser,
    MediaBrowserNavigator
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    /*
      ??? This component shares a lot of similarities with the Lesson component.
      We should refactor this to extract what's common into its own component(s).
      At the moment, it's very easy to tweak somehting here and forget to make the
      same change on the Lesson page (and vice versa).
    */

    const loader = useFullScreenLoader();

    const piece = ref(undefined as Readonly<PlayersPathPiece> | undefined);
    const trailer = ref(undefined as Readonly<Video> | undefined);
    const videos = ref([] as ReadonlyArray<Video>);
    const currentVideoIndex = ref(0);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);

    const songs = computed(() => {
      if (piece.value === undefined) {
        return undefined;
      }
      return piece.value.songs;
    });

    const previewLevel = ref(true);
    const completed = ref(false);
    const levelEntryQuiz = ref(undefined as Readonly<Quiz> | undefined);
    const pieceQuiz = ref(undefined as Readonly<Quiz> | undefined);
    const level = ref<Readonly<PlayersPathLevel> | undefined>(undefined);

    const preview = computed(() => {
      if (piece.value === undefined) {
        return true;
      }
      return piece.value.preview;
    });

    const sidebarTitle = computed(() => {
      if (preview.value || props.guest) {
        return '';
      }
      return previewLevel.value ? 'Play-through' : 'Videos';
    });
    const resumeAtLastTimestamp = computed(() => {
      return !(preview.value || props.guest || previewLevel.value);
    });

    const TITLES = ['Play-through', 'Navigation', 'Performance Tips', 'Performance Tips (2)'];

    const currentVideo = computed(() => {
      if (piece.value === undefined) {
        return undefined;
      }
      if (preview.value || props.guest || previewLevel.value) {
        return getFirstVideoOfType(piece.value.videos, 'trailer');
      }
      const video = videos.value[currentVideoIndex.value];
      return video;
    });

    const currentVideoTitle = computed(() => {
      if (piece.value === undefined) {
        return '';
      }
      if (preview.value || props.guest) {
        return piece.value.title;
      }
      if (previewLevel.value) {
        return `${piece.value.title} (Preview)`;
      }
      return `${piece.value.title} - ${TITLES[currentVideoIndex.value]}`;
    });

    const onMediaBrowserItemSelected = (id: CraftId) => {
      const video = videos.value.findIndex(video => video.videoId === id);
      currentVideoIndex.value = video;
    };

    const mediaBrowserItems = computed(() => {
      if (preview.value || props.guest || previewLevel.value) {
        return [];
      }
      return videos.value.map((video, index) => {
        return {
          id: video.videoId,
          title: TITLES[index],
          complete: false,
          highlight: index === currentVideoIndex.value
        };
      });
    });

    const levelNumber = ref(undefined as undefined | number);
    const spotColor = computed(() => {
      if (levelNumber.value !== undefined) {
        return playersPathLevelSpotColor(levelNumber.value);
      }
      return undefined;
    });

    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);
      piece.value = await getPlayersPathPieceBySlug(props.slug);
      if (piece.value !== undefined) {
        trails.value = await useBreadcrumbTrails(piece.value, props.entryBreadcrumbTrails);
        trailer.value = getFirstVideoOfType(piece.value.videos, 'trailer');
        videos.value = getAllVideosOfTypeDifferentThan(piece.value.videos, 'trailer');
        await bookmark.init();

        if (!props.guest) {
          // Figure out if the level to which this piece belongs is unlocked, or if it is in preview mode.
          levelNumber.value = getPlayersPathLevelNumber(trails.value);
          level.value = await getPlayersPathLevelByNumber(levelNumber.value);
          if (level.value !== undefined) {
            const unlocked = await playersPathLevelIsUnlocked(level.value);
            previewLevel.value = !unlocked;
            levelEntryQuiz.value = level.value.quizzes[0];
          }

          // See if the user has completed the piece.
          pieceQuiz.value = piece.value.quizzes[0];
          if (pieceQuiz.value !== undefined) {
            completed.value = await isQuizComplete(pieceQuiz.value);
          }
        }
      }
      loader.setLoading(false);
    });

    const iconCheckCircle = computed(() => faCheckCircle);
    const iconCheck = computed(() => faCheck);

    return {
      preview,
      piece,
      trails,
      previewLevel,
      level,
      levelEntryQuiz,
      pieceQuiz,
      completed,
      spotColor,
      songs,
      trailer,
      mediaBrowserItems,
      currentVideo,
      currentVideoTitle,
      iconCheck,
      iconCheckCircle,
      onMediaBrowserItemSelected,
      bookmark,
      sidebarTitle,
      resumeAtLastTimestamp
    };
  }
});
</script>
