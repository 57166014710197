<template>
  <div>
    <div class="mx-fl-sm mb-fl-2xl flex justify-center items-center">
      <div
        v-for="(cfg, i) in buttonConfigs"
        :key="`tab-${i}`"
        class="w-full max-w-[150px] px-fl-2xs"
      >
        <button :class="cfg.class" @click="cfg.onClick">
          {{ cfg.title }}
        </button>
      </div>
    </div>
    <variants>
      <template #narrow>
        <feature-matrix-narrow
          :features="featuresForSelected"
          :offer="offer"
          :on-complete="onComplete"
        />
      </template>
      <template #sm+>
        <feature-matrix-small
          :features="featuresForSelected"
          :offer="offer"
          :on-complete="onComplete"
        />
      </template>
    </variants>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, computed} from 'vue';
import Variants from '../../../../../../core/column-layout/Variants.vue';
import FeatureMatrixNarrow from './FeatureMatrixNarrow.vue';
import FeatureMatrixSmall from './FeatureMatrixSmall.vue';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';
import {Feature} from '../utils';

export default defineComponent({
  components: {
    Variants,
    FeatureMatrixNarrow,
    FeatureMatrixSmall
  },
  props: {
    offers: {
      type: Array as PropType<ReadonlyArray<SignupOffer> | ReadonlyArray<AddOnOffer>>,
      required: true
    },
    features: {
      type: Array as PropType<ReadonlyArray<Feature>>,
      required: true
    },
    onComplete: {
      type: Function as PropType<(offer: Readonly<SignupOffer> | Readonly<AddOnOffer>) => void>,
      required: true
    }
  },
  setup(props) {
    const selectedOffer = ref(1);
    const offer = computed(() => {
      return props.offers[selectedOffer.value];
    });
    const featuresForSelected = computed(() => {
      return props.features.filter(cell => {
        if (cell.feature !== undefined) {
          return true;
        }
        if (cell.offer === undefined) {
          return false;
        }
        return cell.offer.id === offer.value.id;
      });
    });
    const buttonConfigs = computed(() => {
      return props.offers.map((offer, i) => {
        const title =
          offer.offerDestination[0].accessPassRecurrence === 'annual' ? 'Annual' : 'Monthly';
        const border = selectedOffer.value === i ? 'border-black' : 'border-white';
        const onClick = () => {
          selectedOffer.value = i;
        };
        return {
          title,
          class: `${border} w-full p-fl-xs text-fl-lg border-2 rounded-full text-black text-center font-semibold transition`,
          onClick
        };
      });
    });
    return {
      buttonConfigs,
      selectedOffer,
      featuresForSelected,
      offer
    };
  }
});
</script>
