import {fetchEntries} from '../craft/entry/entry-query';
import {SignupOffer} from './signup-offer-types';
import {ACCESS_PASS_FIELDS} from '../access-pass/access-pass-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {ADD_ON_OFFER_FIELDS} from './addon-offer-query';
import {UPGRADE_OFFER_FIELDS} from './upgrade-offer-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const SIGNUP_OFFER_FIELDS = [
  QueryBuilder.lightswitch('offerIsActive').required(),
  QueryBuilder.lightswitch('offerDefault').required(),
  QueryBuilder.plainText('offerTitle'),
  QueryBuilder.categories('offerDestination').required().fields(ACCESS_PASS_FIELDS),
  QueryBuilder.categories('offerBonuses').fields(ACCESS_PASS_FIELDS),
  QueryBuilder.number('offerTrialDuration').required(),
  QueryBuilder.plainText('offerFeatures'),
  QueryBuilder.lightswitch('offerHighlight'),
  QueryBuilder.plainText('offerSelectionLabel'),
  QueryBuilder.entries('offerAddOns').fields(ADD_ON_OFFER_FIELDS),
  QueryBuilder.entries('offerUpgrades').fields(UPGRADE_OFFER_FIELDS),
  QueryBuilder.url('offerAddOnsSalesPageUrl'),
  QueryBuilder.entries('offerThankYouPage'),
  IMAGE_COLLECTION_FIELD
];

export async function getAllSignupOffers(): Promise<ReadonlyArray<SignupOffer>> {
  const result = await fetchEntries<SignupOffer>(
    QueryBuilder.entries().section(['signupOffers']).fields(SIGNUP_OFFER_FIELDS)
  );
  return result;
}
