<template>
  <div v-if="!disabled">
    <div v-for="(section, index) in activeSections" :key="section.section.title">
      <page-section :margin-top="index === 0 ? 'none' : 'lg'">
        <page-section-header v-if="displayHeader" :heading="section.section.title" :width="width" />
      </page-section>
      <page-section :margin-top="'xs'">
        <content-entry-grid-or-inline-cards
          :width="width"
          :guest="guest"
          :content-entries="section.contentEntries"
          :layout="layout"
          :completed="completed"
          :progress="progress"
          :duration="duration"
          :disabled="disabled"
          :bookmark="bookmark"
          :display-post-date="displayPostDate"
          :card-image-width="cardImageWidth"
          :imgix-params="imgixParams"
        />
      </page-section>
    </div>
  </div>
  <div v-else>
    <page-section :margin-top="'none'">
      <page-section-header :heading="''" :width="width" />
    </page-section>
    <page-section :margin-top="'xs'">
      <content-entry-grid-or-inline-cards
        :width="width"
        :guest="guest"
        :content-entries="undefined"
        :layout="layout"
        :completed="completed"
        :progress="progress"
        :duration="duration"
        :bookmark="bookmark"
        :display-post-date="displayPostDate"
        :disabled="disabled"
        :card-image-width="cardImageWidth"
        :imgix-params="imgixParams"
      />
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import {CompletedMap, DurationMap, UnlockedMap} from '../types';
import {ContentEntrySection} from '../../../../backend/content/content-entry/content-entry-utils';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import ContentEntryGridOrInlineCards from './ContentEntryGridOrInlineCards.vue';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentEntryGridOrInlineCards
  },
  props: {
    guest: {type: String, required: true},
    sections: {type: Array as PropType<ReadonlyArray<ContentEntrySection>>, required: true},
    completed: {type: Object as PropType<CompletedMap>, default: () => ({})},
    progress: {type: Object as PropType<ProgressMap>, default: () => ({})},
    duration: {type: Object as PropType<DurationMap>, default: () => ({})},
    unlocked: {type: Object as PropType<UnlockedMap>, default: () => ({})},
    displayPostDate: {type: Boolean, default: false},
    layout: {
      type: String as PropType<'cards' | 'list'>,
      default: 'cards'
    },
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    disabled: {type: Boolean, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: undefined}
  },
  setup(props) {
    const displayHeader = computed(() => {
      const nonEmptyCount = props.sections.reduce((accum, section) => {
        if (section.contentEntries.length > 0) {
          return accum + 1;
        }
        return accum;
      }, 0);
      return nonEmptyCount > 1;
    });

    const activeSections = computed(() => {
      return props.sections.filter(section => {
        return section.contentEntries.length > 0;
      });
    });

    return {
      displayHeader,
      activeSections
    };
  }
});
</script>
../../../../backend/content/content-entry/content-entry-utils
