<template>
  <margins :class="fontSize">
    <content-area :area="portraitArea">
      <a :id="id" :href="contentEntry.url" class="group">
        <cluster :gap="'gap-fl-lg-xl'" :wrap="false">
          <div :class="avatarSize">
            <portrait-button
              v-if="avatar !== undefined"
              :config="avatar"
              :tooltip="'To seminar'"
              :on-click="goToSeminar"
            />
          </div>
          <div class="flex flex-col">
            <div class="font-semibold text-lightest group-hover:text-white break-words">
              {{ contentEntry.title }}
            </div>
            <div class="text-mid group-hover:text-white font-semibold break-words">
              {{ tutors }}
            </div>
          </div>
        </cluster>
      </a>
    </content-area>
    <content-area :area="dateArea" class="self-center">
      <repel>
        <div class="text-primary font-semibold">{{ postDate }}</div>
        <content-entry-header-bookmark
          :guest="guest"
          :bookmark="bookmark"
          :content-entry="contentEntry"
          :display="'standard'"
          :color="'light'"
        />
      </repel>
    </content-area>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import PortraitButton from '../../../core/button/PortraitButton.vue';
import ContentEntryHeaderBookmark from '../../partials/header/actions/ContentEntryHeaderBookmark.vue';
import {
  getAvatarImage,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {redirectWithHistory} from '../../../../utils/url';
import {postDateToLocale} from '../../../../backend/craft/entry/entry-utils';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {ColumnArea} from '../../../core/column-layout/types';

export default defineComponent({
  components: {
    Margins,
    ContentArea,
    Cluster,
    Repel,
    PortraitButton,
    ContentEntryHeaderBookmark
  },
  props: {
    id: {type: String, default: undefined},
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    live: {type: Boolean, default: undefined}
  },
  setup(props) {
    const avatar = computed(() => {
      const tutor = props.contentEntry.tutors[0];
      if (tutor !== undefined) {
        return getAvatarImage(tutor.imageCollection, tutor.title, {narrow: 80, md: 100, xl: 130});
      }
      return undefined;
    });

    const tutors = computed(() => {
      return tutorList(props.contentEntry);
    });

    const goToSeminar = () => {
      if (props.contentEntry.url !== null) {
        redirectWithHistory(props.contentEntry.url);
      }
    };

    const postDate = computed(() => postDateToLocale(props.contentEntry.postDate, 'long'));

    const portraitArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      lg: {start: 1, span: 5},
      '2xl': {start: 1, span: 4}
    });
    const dateArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      lg: {start: 7, span: -1}
    });
    const avatarSize = deviceTypeSwitch<string>({
      narrow: 'min-w-[80px] w-[80px] h-[80px]',
      md: 'min-w-[100px] w-[100px] h-[100px]',
      xl: 'min-w-[130px] w-[130px] h-[130px]'
    });
    const fontSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-lg',
      sm: 'text-fl-xl',
      md: 'text-fl-2xl',
      lg: 'text-fl-3xl'
    });

    return {
      avatar,
      avatarSize,
      fontSize,
      tutors,
      goToSeminar,
      postDate,
      portraitArea,
      dateArea
    };
  }
});
</script>
