import {Entry} from './entry-types';
import {CraftQueryEntriesField} from '../query/implementation/craft-query-types';
import {cachedPostAction, ControllerResponse, postAction} from '../../craft-action';
import {VALIDATION_POSITIVE_INTEGER} from '../../validation';
import {buildValidationSchema} from '../query/implementation/craft-query-validation';
import {
  CraftQueryBuilderEntries,
  ResultFilter,
  ResultTransform
} from '../query/craft-query-builder-types';
import {mapField} from '../query/implementation/craft-query-map';
import {filterField} from '../query/implementation/craft-query-filter';
import {removeDuplicates} from './entry-utils';
import {ThreadSyncCache} from '../../../utils/thread-sync-cache';

/**
 * Fetch entries from a specified Craft section.
 *
 * !! IMPORTANT !! This function does NOT check whether the entries are hidden or in preview mode.
 * To fetch content entries (i.e., entries can be in hidden/preview mode), use the
 * `fetchContentEntries()` function.
 */
export async function fetchEntries<T extends Entry>(
  query: Readonly<CraftQueryBuilderEntries>,
  map?: ResultTransform,
  filter?: ResultFilter,
  cache?: ThreadSyncCache<ControllerResponse<ReadonlyArray<T>>>,
  cacheKey?: string | number
): Promise<ReadonlyArray<T>> {
  /*
    ### This code is more or less a repeat of the code in `fetchCategories` and
    `fetchGlobalSet`, so it is possible to generalise them into a single
    `fetchElements` function.
  */
  const field = query._build() as CraftQueryEntriesField;
  const validationSchema = buildValidationSchema(field);
  const url = '/actions/sbl-module/element-query/query';
  try {
    let result: ReadonlyArray<T> = [];
    if (cache !== undefined && cacheKey !== undefined) {
      result = await cachedPostAction<ReadonlyArray<T>>(
        url,
        field,
        validationSchema,
        cache,
        cacheKey
      );
    } else {
      result = await postAction<ReadonlyArray<T>>(url, field, validationSchema);
    }

    /*
      ??? Craft will sometimes return multiple copies of the same element
      (try, e.g., to query for all entries related to Scott Devine, including
      Learning Pathways; there will be multiple copies of the "Walking Bass"
      pathway). We should try to understand why!

      As a workaround, we filter out duplicated elements here. NOTE: The pruning
      is recursive.
    */
    result = removeDuplicates(field, result);

    // Apply custom transform to all elements (recursively).
    if (map) {
      result = mapField(field, map, result) as ReadonlyArray<T>;
    }

    // Apply custom filter to all elements (recursively).
    if (filter) {
      result = filterField(field, filter, result) as ReadonlyArray<T>;
    }

    return result;
  } catch (error) {
    throw new Error(`Could not fetch entries: ${error}`);
  }
}

/**
 * Return the number of entries that match the specified
 * query config in the specified entries field.
 *
 * IMPORTANT! This function does NOT check whether the entries are
 * hidden or in preview mode.
 */
export async function countEntries(query: Readonly<CraftQueryBuilderEntries>): Promise<number> {
  try {
    const field = query._build() as CraftQueryEntriesField;
    const result = await postAction<number>(
      '/actions/sbl-module/element-query/count',
      field,
      VALIDATION_POSITIVE_INTEGER
    );
    return result;
  } catch (error) {
    throw new Error(`Could not count entries: ${error}`);
  }
}
