/**
 * Polyfills. From Babel 7.4.0 onwards, @babel/polyfill is deprecated.
 * Instead, it is recommended to include the core-js npm package in the
 * project and import it here. The regenerator-runtime import is needed to
 * allow async mounted() functions in Vue components. (It is part of the
 * core-js npm package.)
 *
 * For details, see
 *
 * https://babeljs.io/docs/en/babel-polyfill
 * https://github.com/zloirock/core-js#usage
 * https://alexbogovich.com/blog/core-js/
 */

/** CL: ### `import 'regenerator-runtime/runtime';` is required for core-js to properly work with babel. When setting `useBuiltIns: 'usage'`, polyfills are automatically imported when needed.
 * When `useBuiltIns:` is set to `usage`, direct import of `core-js` is unneccessary. If `import 'core-js';` is included in this file use `useBuiltIns: 'entry'` instead.
 *
 * Intelligent loading of polyfills depends on the targets set in `"browserslist":` config in package.json.
 *
 * NOTE: `npx browserslist@latest --update-db` updates caniuse-lite version in your npm, yarn or pnpm lock file. See the following for details: https:*github.com/browserslist/browserslist
 * FYI: Run 'npx browserslist' in your project directory to see project’s target browsers. This CLI tool is built-in and available in any project with Autoprefixer.
 */
// import 'core-js/stable';
// import regeneratorRuntime from "regenerator-runtime";

import './css/global.css';

// CL: ### Async component loading
// CL: NOTE: 'webpackChunkName:' is a MAGIC COMMENT used for setting async component chunk name.
// See: https://webpack.js.org/api/module-methods/
/*
import {defineAsyncComponent} from 'vue';
const MyComponent = defineAsyncComponent(
  () => import(webpackChunkName: "MyComponent" './components/mycomponent/MyComponent.vue')
);
*/

// Standard import works too!
import {createVueApp} from './utils/vue';
import About from './components/content-pages/about/About.vue';
import Account from './components/user-pages/account/pages/account/Account.vue';
import AccountAddOn from './components/offer/add-on/AccountAddOn.vue';
import AllBookmarks from './components/user-pages/bookmarks/AllBookmarks.vue';
import AllCareers from './components/content-pages/careers/AllCareers.vue';
import AllCourses from './components/content-pages/courses/AllCourses.vue';
import AllCuration from './components/content-pages/curation/AllCuration.vue';
import AllInterviews from './components/content-pages/interviews/AllInterviews.vue';
import AllLearningPathways from './components/content-pages/learning-pathways/AllLearningPathways.vue';
import AllMemberDiscounts from './components/content-pages/member-discounts/AllMemberDiscounts.vue';
import AllMentorSeminars from './components/content-pages/mentor-seminars/AllMentorSeminars.vue';
import AllPodcasts from './components/content-pages/podcasts/AllPodcasts.vue';
import AllPosts from './components/content-pages/posts/AllPosts.vue';
import AllShows from './components/content-pages/shows/AllShows.vue';
import AllTutors from './components/content-pages/tutors/AllTutors.vue';
import BeginnerJourney from './components/content-pages/beginner-journey/BeginnerJourney.vue';
import BrowserCompatibility from './components/user-pages/terms-of-service/BrowserCompatibility.vue';
import Cancel from './components/user-pages/account/pages/cancel/Cancel.vue';
import Career from './components/content-pages/careers/Career.vue';
import CareersHeader from './components/content-pages/careers/partials/CareersHeader.vue';
import Contact from './components/user-pages/contact/Contact.vue';
import Course from './components/content-pages/courses/Course.vue';
import Curation from './components/content-pages/curation/Curation.vue';
import Dashboard from './components/user-pages/dashboard/Dashboard.vue';
import Email from './components/user-pages/profile/pages/email/Email.vue';
import EmailPreferences from './components/user-pages/email-preferences/EmailPreferences.vue';
import ErrorReporter from './components/core/error-reporter/ErrorReporter.vue';
import FullScreenLoader from './components/generic/loader/FullScreenLoader.vue';
import GlobalSearch from './components/global-search/GlobalSearch.vue';
import GrooveTrainer from './components/content-pages/groove-trainer/GrooveTrainer.vue';
import GrooveTrainerCard from './components/content-pages/beginner-journey/partials/GrooveTrainerCard.vue';
import HelpScout from './components/core/site-wrapper/help-scout/HelpScout.vue';
import History from './components/user-pages/history/History.vue';
import Instrumentation from './components/core/site-wrapper/instrumentation/Instrumentation.vue';
import Interview from './components/content-pages/interviews/Interview.vue';
import LearningPathway from './components/content-pages/learning-pathways/LearningPathway.vue';
import LearningPathwayCard from './components/content-pages/learning-pathways/partials/LearningPathwayCard.vue';
import Lesson from './components/content-pages/lessons/Lesson.vue';
import LiveClass from './components/live-class/LiveClass.vue';
import Login from './components/user-pages/login/Login.vue';
import MentorSeminar from './components/content-pages/mentor-seminars/MentorSeminar.vue';
import MentorSeminarsMain from './components/content-pages/mentor-seminars/MentorSeminarsMain.vue';
import MissionList from './components/content-pages/about/partials/MissionList.vue';
import NewMemberHuddleCard from './components/content-pages/beginner-journey/partials/NewMemberHuddleCard.vue';
import NotFound from './components/not-found/NotFound.vue';
import NotificationPopup from './components/generic/notification-popup/NotificationPopup.vue';
import Orientation from './components/content-pages/orientation/Orientation.vue';
import Password from './components/user-pages/profile/pages/password/Password.vue';
import PlayersPathLevel from './components/content-pages/players-path/PlayersPathLevel.vue';
import PlayersPathMain from './components/content-pages/players-path/PlayersPathMain.vue';
import PlayersPathPiece from './components/content-pages/players-path/PlayersPathPiece.vue';
import Podcast from './components/content-pages/podcasts/Podcast.vue';
import Post from './components/content-pages/posts/Post.vue';
import PrivacyPolicy from './components/user-pages/privacy-policy/PrivacyPolicy.vue';
import Profile from './components/user-pages/profile/pages/profile/Profile.vue';
import Quiz from './components/content-pages/quiz/Quiz.vue';
import ResetPassword from './components/user-pages/reset-password/ResetPassword.vue';
import Resubscribe from './components/user-pages/account/pages/resubscribe/Resubscribe.vue';
import SblTeamLarge from './components/content-pages/about/variations/SblTeamLarge.vue';
import SblTeamNarrow from './components/content-pages/about/variations/SblTeamNarrow.vue';
import SetPassword from './components/user-pages/set-password/SetPassword.vue';
import Show from './components/content-pages/shows/Show.vue';
import Sidebar from './components/core/site-wrapper/sidebar/Sidebar.vue';
import Signup from './components/signup/Signup.vue';
import SignupAddOn from './components/offer/add-on/SignupAddOn.vue';
import SignupUpgrade from './components/offer/add-on/SignupUpgrade.vue';
import SiteFooter from './components/site-footer/SiteFooter.vue';
import SiteHeader from './components/site-header/SiteHeader.vue';
import SiteWrapper from './components/core/site-wrapper/SiteWrapper.vue';
import StudentFeedbackLarge from './components/content-pages/about/variations/StudentFeedbackLarge.vue';
import StudentFeedbackMedium from './components/content-pages/about/variations/StudentFeedbackMedium.vue';
import StudentQuotes from './components/content-pages/about/partials/StudentQuotes.vue';
import Survey from './components/user-pages/survey/Survey.vue';
import TermsOfService from './components/user-pages/terms-of-service/TermsOfService.vue';
import ThankYouPage from './components/offer/thank-you-page/ThankYouPage.vue';
import TimelineLarge from './components/content-pages/about/variations/TimelineLarge.vue';
import TimelineNarrow from './components/content-pages/about/variations/TimelineNarrow.vue';
import Toolkit from './components/toolkit/Toolkit.vue';
import ToolkitLesson from './components/toolkit/ToolkitLesson.vue';
import Tutor from './components/content-pages/tutors/Tutor.vue';
import UpcomingEntry from './components/content-pages/upcoming-entry/UpcomingEntry.vue';
import Update from './components/user-pages/account/pages/update/Update.vue';
import Upgrade from './components/user-pages/account/pages/upgrade/Upgrade.vue';
import ValuesLarge from './components/content-pages/about/variations/ValuesLarge.vue';
import ValuesNarrow from './components/content-pages/about/variations/ValuesNarrow.vue';
import VideoPlayer from './components/generic/video-player/VideoPlayer.vue';

/*
  Let TypeScript know of the HelpScout beacon type.
*/
declare global {
  // ### Get rid of this when/if the HelpScout API supports TypeScript.
  const Beacon: any;

  /*
    ### reCAPTCHA defines a global 'grecaptcha' object when it's loaded; 
    Unfortunately it doesn't look like there is an official TS type package.
    When/if Google decides to release one, we should use that instead.
    
    For info on what these objects do, see
    https://developers.google.com/recaptcha/docs/loading
  */
  interface Window {
    grecaptcha: any | undefined;
    ___grecaptcha_cfg: any | undefined;
  }
}

const app = createVueApp({
  /**
   * These are the Vue components that should be possible to
   * access directly in Craft templates. There is no need to list
   * sub-components (i.e., components that are used by the components
   * in this list but not directly in templates).
   */
  components: {
    About,
    Account,
    AccountAddOn,
    AllBookmarks,
    AllCareers,
    AllCourses,
    AllCuration,
    AllInterviews,
    AllLearningPathways,
    AllMemberDiscounts,
    AllMentorSeminars,
    AllPodcasts,
    AllPosts,
    AllShows,
    AllTutors,
    BeginnerJourney,
    BrowserCompatibility,
    Cancel,
    Career,
    CareersHeader,
    Contact,
    Course,
    Curation,
    Dashboard,
    Email,
    EmailPreferences,
    ErrorReporter,
    FullScreenLoader,
    GlobalSearch,
    GrooveTrainer,
    GrooveTrainerCard,
    HelpScout,
    History,
    Instrumentation,
    Interview,
    LearningPathway,
    LearningPathwayCard,
    Lesson,
    LiveClass,
    Login,
    MentorSeminar,
    MentorSeminarsMain,
    MissionList,
    NewMemberHuddleCard,
    NotFound,
    NotificationPopup,
    Orientation,
    Password,
    PlayersPathLevel,
    PlayersPathMain,
    PlayersPathPiece,
    Podcast,
    Post,
    PrivacyPolicy,
    Profile,
    Quiz,
    ResetPassword,
    Resubscribe,
    SblTeamLarge,
    SblTeamNarrow,
    SetPassword,
    Show,
    Sidebar,
    Signup,
    SignupAddOn,
    SignupUpgrade,
    SiteFooter,
    SiteHeader,
    SiteWrapper,
    StudentFeedbackLarge,
    StudentFeedbackMedium,
    StudentQuotes,
    Survey,
    TermsOfService,
    ThankYouPage,
    TimelineLarge,
    TimelineNarrow,
    Toolkit,
    ToolkitLesson,
    Tutor,
    UpcomingEntry,
    Update,
    Upgrade,
    ValuesLarge,
    ValuesNarrow,
    VideoPlayer
  }
});

app.mount('#app');
