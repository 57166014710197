import {ColumnArea} from '../../../core/column-layout/types';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {ScreenWidth} from '../../../vue-composition/device-type/device-type';

export function titleAreaWithInfoButton(
  width: StandardPageAreaWidth,
  screen: ScreenWidth | undefined,
  center: boolean
): Readonly<ColumnArea> {
  if (screen === 'narrow' || screen === undefined) {
    return {start: 1, span: 3};
  }
  switch (width) {
    case 'wide':
      return center ? {start: 2, span: 10} : {start: 1, span: 11};
    case 'medium':
      switch (screen) {
        case 'sm':
        case 'md':
        case 'lg':
        case 'xl':
          return center ? {start: 2, span: 10} : {start: 1, span: 11};
        case '2xl':
          return center ? {start: 3, span: 8} : {start: 1, span: 10};
      }
    case 'normal':
      switch (screen) {
        case 'sm':
        case 'md':
        case 'lg':
          return center ? {start: 2, span: 10} : {start: 1, span: 11};
        case 'xl':
          return center ? {start: 3, span: 8} : {start: 1, span: 10};
        case '2xl':
          return center ? {start: 4, span: 6} : {start: 1, span: 8};
      }
    case 'narrow':
      switch (screen) {
        case 'sm':
        case 'md':
          return center ? {start: 2, span: 10} : {start: 1, span: 11};
        case 'lg':
          return center ? {start: 3, span: 8} : {start: 1, span: 10};
        case 'xl':
          return center ? {start: 4, span: 6} : {start: 1, span: 8};
        case '2xl':
          return center ? {start: 5, span: 4} : {start: 1, span: 6};
      }
    case 'text':
      return center ? {start: 2, span: 10} : {start: 1, span: 11};
  }
  return {start: 1, span: 3};
}

export function titleAreaNoInfoButton(
  width: StandardPageAreaWidth,
  screen: ScreenWidth | undefined,
  center: boolean
): Readonly<ColumnArea> {
  if (screen === 'narrow' || screen === undefined) {
    return {start: 1, span: 4};
  }
  switch (width) {
    case 'wide':
      return {start: 1, span: 12};
    case 'medium':
      switch (screen) {
        case 'sm':
        case 'md':
        case 'lg':
        case 'xl':
          return {start: 1, span: 12};
        case '2xl':
          return center ? {start: 2, span: 10} : {start: 1, span: 10};
      }
    case 'normal':
      switch (screen) {
        case 'sm':
        case 'md':
        case 'lg':
          return {start: 1, span: 12};
        case 'xl':
          return center ? {start: 2, span: 10} : {start: 1, span: 10};
        case '2xl':
          return center ? {start: 3, span: 8} : {start: 1, span: 8};
      }
    case 'narrow':
      switch (screen) {
        case 'sm':
        case 'md':
          return {start: 1, span: 12};
        case 'lg':
          return center ? {start: 2, span: 10} : {start: 1, span: 10};
        case 'xl':
          return center ? {start: 3, span: 8} : {start: 1, span: 8};
        case '2xl':
          return center ? {start: 4, span: 6} : {start: 1, span: 6};
      }
    case 'text':
      switch (screen) {
        case 'sm':
        case 'md':
          return {start: 1, span: 12};
        case 'lg':
          return center ? {start: 1, span: 8} : {start: 1, span: 8};
        case 'xl':
          return center ? {start: 2, span: 6} : {start: 1, span: 6};
        case '2xl':
          return center ? {start: 3, span: 6} : {start: 1, span: 6};
      }
  }
  return {start: 1, span: 4};
}
