<template>
  <content-column :width="width" :justify="justify">
    <div ref="trackContainerRef" class="relative w-full h-fit overflow-hidden">
      <div
        ref="trackRef"
        :class="`w-fit h-fit flex`"
        :style="`transform:translate(${-trackTranslation}px,0px);gap:${gap}px`"
      >
        <div
          v-for="i in Array.from(Array(numberOfSlides).keys())"
          :key="i"
          class="flex-none"
          :style="`width:${slideWidth}px`"
        >
          <slot :name="i" />
        </div>
      </div>
      <carousel-nav-button
        v-if="overflowLeft && !disabled"
        :on-click="onPrev"
        :tooltip="'Previous'"
        :class="'absolute left-0 top-0'"
        :style="navButtonHeightStyle"
        :orientation="'left'"
      />
      <carousel-nav-button
        v-if="overflowRight && !disabled"
        :on-click="onNext"
        :tooltip="'Next'"
        :class="'absolute right-0 top-0'"
        :style="navButtonHeightStyle"
        :orientation="'right'"
      />
    </div>
  </content-column>
</template>

<script lang="ts">
import {computed, defineComponent, ref, nextTick, PropType} from 'vue';
import {useAnimatedValue} from '../../vue-composition/animation/linear';
import CarouselNavButton from './partials/CarouselNavButton.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import {
  StandardPageAreaJustify,
  StandardPageAreaWidth,
  useStandardPageArea
} from '../../core/column-layout/utils';
import {CarouselCardSize} from './carousel';
import {COLUMNS_PER_SLIDE} from './implementation/carousel-utils';
import {useColumnLayout} from '../../vue-composition/column-layout/column-layout';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {CarouselCardAspectRatio} from './carousel';

export default defineComponent({
  components: {
    ContentColumn,
    CarouselNavButton
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    numberOfSlides: {type: Number, required: true},
    cardSize: {type: String as PropType<CarouselCardSize>, default: 'sm'},
    cardAspectRatio: {type: String as PropType<CarouselCardAspectRatio>, default: '16:9'},
    disabled: {type: Boolean, default: false}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const spa = useStandardPageArea(deviceType, props.width);
    const columnLayout = useColumnLayout(deviceType);

    const currentSlide = ref(0);

    const columnsPerSlide = computed(() => {
      const screen = deviceType.screen.value;
      if (screen === undefined) {
        return 4;
      }
      const columns = COLUMNS_PER_SLIDE[props.cardSize];
      return columns[screen];
    });

    const slideWidth = computed(() => {
      if (columnsPerSlide.value === undefined) {
        return 0;
      }
      const config = columnLayout.config.value;
      const result =
        columnsPerSlide.value * config.columnWidth +
        config.gutterWidth * (columnsPerSlide.value - 1);
      return result;
    });

    const gap = computed(() => {
      return columnLayout.config.value.gutterWidth;
    });

    const slideHeight = computed(() => {
      const wh = props.cardAspectRatio.split(':');
      const ratio = parseInt(wh[1]) / parseInt(wh[0]);
      return slideWidth.value * ratio;
    });

    const overflowLeft = computed(() => {
      const total = props.numberOfSlides * (slideWidth.value + gap.value);
      const wp = spa.area.value.span * (columnLayout.config.value.columnWidth + gap.value);
      return currentSlide.value !== 0 && total > wp;
    });

    const overflowRight = computed(() => {
      if (columnsPerSlide.value === undefined) {
        return false;
      }
      return (
        columnsPerSlide.value * (props.numberOfSlides - currentSlide.value) > spa.area.value.span
      );
    });

    const trackTranslation = computed(() => {
      const total = props.numberOfSlides * (slideWidth.value + gap.value);
      const wp =
        spa.area.value.span *
        (columnLayout.config.value.columnWidth + columnLayout.config.value.gutterWidth);
      if (total <= wp) {
        return 0;
      }
      return Math.min(childPos.value, total - wp);
    });

    const navButtonHeightStyle = computed(() => {
      return `height: ${slideHeight.value}px`;
    });

    const animation = useAnimatedValue(0.2, 0, 60);
    const childPos = computed(() => {
      return animation.value.value * (slideWidth.value + gap.value);
    });

    const onNext = async () => {
      if (currentSlide.value < props.numberOfSlides - 1) {
        currentSlide.value = currentSlide.value + 1;
        animation.set(currentSlide.value, async () => {
          await nextTick();
        });
      }
    };
    const onPrev = async () => {
      if (currentSlide.value > 0) {
        currentSlide.value = currentSlide.value - 1;
        animation.set(currentSlide.value, async () => {
          await nextTick();
        });
      }
    };

    return {
      onNext,
      onPrev,
      navButtonHeightStyle,
      trackTranslation,
      gap,
      slideWidth,
      overflowLeft,
      overflowRight
    };
  }
});
</script>
