<template>
  <margins>
    <content-area :area="area">
      <div :class="`grid ${cols}`" :style="style">
        <slot />
      </div>
    </content-area>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import {deviceTypeSwitch, useDeviceType} from '../../../vue-composition/device-type/device-type';
import {useColumnLayout} from '../../../vue-composition/column-layout/column-layout';
import {ColumnArea} from '../../../core/column-layout/types';

/**
 * CSS grid (for cards, primarily) that aligns with the design system's
 * 12 column layout. The number of cards per row switches dynamically
 * as appropriate.
 */
export default defineComponent({
  components: {
    Margins,
    ContentArea
  },
  setup() {
    const deviceType = useDeviceType();
    const columnLayout = useColumnLayout(deviceType);

    const area = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: 4},
      sm: {start: 1, span: 12},
      md: {start: 1, span: 12},
      lg: {start: 1, span: 12},
      xl: {start: 2, span: 11},
      '2xl': {start: 3, span: 10}
    });

    /*
      Set the number of columns (in the 12 column layout system) that each
      card should cover. For example, if we want the cards to cover 4 columns,
      we would use 'grid-cols-3' (12 columns / 4 columns per card = 3).
    */
    const cols = deviceTypeSwitch<string>({
      narrow: 'grid-cols-1',
      sm: 'grid-cols-2',
      md: 'grid-cols-2',
      lg: 'grid-cols-2',
      xl: 'grid-cols-3',
      '2xl': 'grid-cols-3'
    });

    /*
      Return the gutter between the cards; this is the current gutter width
      in the 12 column design system.
    */
    const style = computed(() => {
      const gw = columnLayout.config.value.gutterWidth;
      return `column-gap:${gw}px;row-gap:${gw * 4}px`;
    });
    return {
      area,
      cols,
      style
    };
  }
});
</script>
