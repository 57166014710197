<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-header>Change Payment Method</content-entry-header>
      </content-column>
    </page-section>

    <page-section v-if="accessPass !== undefined" :margin-top="'md'">
      <content-column>
        <p>
          You are updating the payment method for <b>{{ accessPass.title }}</b>
        </p>
      </content-column>
    </page-section>

    <payment
      v-if="amount !== undefined"
      :width="'narrow'"
      :error="error"
      :email="userEmail"
      :amount="amount"
      :purchase-label="'Update'"
      :on-cancel="onCancel"
      :on-purchase="onPurchase"
      :recaptcha-action="'payment_update'"
    />
    <backend-error :width="'narrow'" :error="error" />
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import Payment from '../../../../offer/payment/Payment.vue';
import BackendError from '../../../../generic/backend-error/BackendError.vue';
import {AccessPassStatus} from '../../../../../backend/access-pass/access-pass-types';
import {update} from '../../../../../backend/signup/checkout-query';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {getAccessPassesForCurrentUser} from '../../../../../backend/access-pass/access-pass-query';
import {redirectWithHistory} from '../../../../../utils/url';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import {BraintreeCustomer, BraintreeNonce} from '../../../../offer/payment/types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    Payment,
    BackendError
  },
  props: {
    accessPassId: {type: String, required: true},
    userEmail: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const error = useBackendError(true);
    const accessPass = ref<Readonly<AccessPassStatus> | undefined>(undefined);

    const amount = computed(() => {
      if (accessPass.value === undefined) {
        return undefined;
      }
      return accessPass.value.amount;
    });

    onMounted(async () => {
      // Load user's access passes and Braintree provider customer id.
      loader.setLoading(true, 'Loading subscription status');
      const userPasses = await getAccessPassesForCurrentUser();
      loader.setLoading(false);

      accessPass.value = userPasses.find(pass => pass.id === props.accessPassId);
      if (
        accessPass.value === undefined ||
        !accessPass.value.subscriptionId ||
        !accessPass.value.paymentProvider
      ) {
        error.setMessage('Customer does not own access pass.');
        return;
      }

      if (accessPass.value.status === 'cancelling') {
        error.setMessage('Cannot update payment method for a cancelled subscription.');
        return;
      }
      if (accessPass.value.status === 'pending') {
        error.setMessage('Cannot update payment method for a pending subscription.');
        return;
      }
    });

    const onPurchase = async (nonce: Readonly<BraintreeNonce>, _: Readonly<BraintreeCustomer>) => {
      if (accessPass.value === undefined) {
        return;
      }

      loader.setLoading(
        true,
        'Updating your subscription. Please do not navigate away from this page.'
      );
      await update(accessPass.value.id, nonce.nonce).catch(error.catcher);
      loader.setLoading(false);

      if (!error.error()) {
        loader.setLoading(true);
        redirectWithHistory('/account?result=updateSuccess');
      }
    };

    const onCancel = () => {
      redirectWithHistory('/account');
    };

    return {
      loader,
      amount,
      onPurchase,
      onCancel,
      error,
      accessPass
    };
  }
});
</script>
