<template>
  <img :src="attr.src" :alt="attr.alt" :srcset="attr.srcset" :sizes="attr.sizes" />
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import {getImageAttributes} from './implementation/imgix';
import {ImgixImageConfig} from './types';

/**
 * Image hosted on Imgix that adapts to screen width and device pixel density.
 *
 * For more info on responsive images, see
 * https://ericportis.com/posts/2014/srcset-sizes/
 * https://docs.imgix.com/tutorials/responsive-images-srcset-imgix
 */
export default defineComponent({
  props: {
    config: {type: Object as PropType<Readonly<ImgixImageConfig>>, required: true}
  },
  setup(props) {
    /*
      ??? Add lazy loading as an option in the ImgixImageConfig object!
      Be careful, though, since if you specify lazy loading the browser
      must know the width and height of the image (or it will be unable
      to load the appropriate image from the srcset). See

      https://web.dev/browser-level-image-lazy-loading/#images-should-include-dimension-attributes
      https://web.dev/lcp-lazy-loading/
    */
    const attr = computed(() => {
      return getImageAttributes(props.config);
    });
    return {attr};
  }
});
</script>
