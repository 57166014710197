<template>
  <!-- Content column -->
  <margins>
    <content-area :area="area">
      <slot />
    </content-area>
  </margins>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Margins from '../column-layout/Margins.vue';
import ContentArea from '../column-layout/ContentArea.vue';
import {
  useStandardPageArea,
  StandardPageAreaWidth,
  StandardPageAreaJustify
} from '../column-layout/utils';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

/**
 * This is a utility component that creates a centered cell inside of the
 * main page column grid (see the documentation for the <columns> component
 * for details on the column grid).
 *
 * For narrow viewports (where we have 4 layout columns), the cell covers
 * column 1 through 4.
 *
 * For other viewports (where we have 12 layout columns), the cell span
 * depends on the 'width' property:
 *
 * 'wide': 12 columns
 * 'medium': 10 columns
 * 'normal': 8 columns
 * 'narrow': 6 columns
 * 'extra-narrow': 4 columns
 *
 * The area extends to cover more columns as the width of the screen decreases.
 */
export default defineComponent({
  components: {
    Margins,
    ContentArea
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: 'center'}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const spa = useStandardPageArea(deviceType, props.width, props.justify);
    return {
      area: spa.area
    };
  }
});
</script>
