import {CraftSection} from '../../craft/section/section-types';
import {ContentEntry} from './content-entry-types';

export interface ContentEntrySection {
  section: CraftSection;
  contentEntries: Array<ContentEntry>;
}

export function collectBySection(
  contentEntries: ReadonlyArray<ContentEntry>,
  sections: ReadonlyArray<CraftSection>
): ReadonlyArray<ContentEntrySection> {
  const result: Array<ContentEntrySection> = [];
  sections.forEach(section => {
    const sectionContent = contentEntries.filter(ce => ce.sectionHandle === section.handle);
    result.push({
      contentEntries: sectionContent,
      section
    });
  });
  return result;
}
