<template>
  <cluster :wrap="false" :gap="gap">
    <icon-button
      :disabled="disabled"
      :on-click="onUp"
      :tooltip="tooltipUp"
      :icon="iconPlus"
      :color="color"
      :font-size="fontSize"
    />
    <icon-button
      :disabled="disabled"
      :on-click="onDown"
      :tooltip="tooltipDown"
      :icon="iconMinus"
      :color="color"
      :font-size="fontSize"
    />
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Cluster from '../../core/compositions/Cluster.vue';
import IconButton from './IconButton.vue';
import {faCirclePlus} from '@fortawesome/pro-solid-svg-icons/faCirclePlus';
import {faCircleMinus} from '@fortawesome/pro-solid-svg-icons/faCircleMinus';
import {ButtonColor} from './implementation/utils';

export default defineComponent({
  components: {
    Cluster,
    IconButton
  },
  props: {
    disabled: {type: Boolean, default: false},

    color: {type: String as PropType<ButtonColor>, default: 'mid'},
    tooltipUp: {type: String, required: true},
    tooltipDown: {type: String, required: true},
    onUp: {type: Function as PropType<() => void>, required: true},
    onDown: {type: Function as PropType<() => void>, required: true},

    gap: {type: String, default: 'gap-fl-md'},
    fontSize: {type: String, default: 'text-fl-5xl'}
  },
  setup() {
    const iconPlus = computed(() => faCirclePlus);
    const iconMinus = computed(() => faCircleMinus);
    return {
      iconPlus,
      iconMinus
    };
  }
});
</script>
