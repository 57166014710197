<template>
  <div class="relative w-full rounded-[0.5rem] overflow-hidden w-full">
    <div
      v-if="title !== undefined"
      :class="`w-full ${aspect} flex flex-col justify-center bg-darkest text-center text-sm text-light uppercase`"
    >
      {{ title }}
    </div>
    <img
      v-else
      src="https://scottsbasslessons.imgix.net/content/logo-fill.svg"
      class="w-full aspect-video p-fl-md bg-darkest"
    />
    <card-hero-inset-text :inset-text="insetText" />
    <card-hero-ribbon :ribbon-icon="ribbonIcon" />
  </div>
</template>
<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import CardHeroInsetText from './partials/CardHeroInsetText.vue';
import CardHeroRibbon from './partials/CardHeroRibbon.vue';

export default defineComponent({
  components: {
    CardHeroInsetText,
    CardHeroRibbon
  },
  props: {
    title: {type: String, default: undefined},
    insetText: {type: String, default: undefined},
    ribbonIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    aspect: {type: String, default: 'aspect-video'}
  },
  setup() {}
});
</script>
