<template>
  <div class="relative w-full rounded-[0.5rem] overflow-hidden w-full">
    <div :class="`${aspect} w-full h-full bg-darkest`">
      <div
        :class="`relative w-full h-full shimmer isolate overflow-hidden absolute inset-0 bg-gradient-to-r from-transparent via-dark/20 to-transparent`"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  props: {
    aspect: {type: String, default: 'aspect-video'}
  }
});
</script>

<style scoped>
/* We need to specify animation in a CSS class; no Tailwind mechanism for that. */
.shimmer {
  animation: shimmer 2s infinite;
  transform: translate(-100%, 0);
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
