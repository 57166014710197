<template>
  <div class="self-end flex gap-fl-md">
    <div v-for="(checkmark, index) in checkmarks" :key="index">
      <beginner-journey-song-checkmark
        :disabled="disabled"
        :number="index"
        :complete="checkmark.complete"
        :spot-color="spotColor"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../../../../core/color-config';
import BeginnerJourneySongCheckmark from './BeginnerJourneySongCheckmark.vue';

export default defineComponent({
  components: {
    BeginnerJourneySongCheckmark
  },
  props: {
    disabled: {type: Boolean, required: true},
    piecesCompleted: {type: Number, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true}
  },
  setup(props) {
    const checkmarks = computed(() => {
      return [1, 2, 3].map(n => {
        return {
          complete: n <= props.piecesCompleted
        };
      });
    });

    return {
      checkmarks
    };
  }
});
</script>
