<template>
  <div v-for="(ce, i) in contentEntries" :key="ce.id">
    <content-column :width="'wide'">
      <standard-divider v-if="i === 0" class="border-mid" />
    </content-column>
    <div class="py-fl-lg-xl">
      <event-promo-card-wide
        :id="`upcoming-seminar-${i}`"
        :guest="guest"
        :bookmark="bookmark"
        :content-entry="ce"
      />
    </div>
    <content-column :width="'wide'">
      <standard-divider class="border-mid" />
    </content-column>
  </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import EventPromoCardWide from '../../../content-entry/representations/event-promo/EventPromoCardWide.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';

export default {
  components: {
    ContentColumn,
    StandardDivider,
    EventPromoCardWide
  },
  props: {
    guest: {type: String, required: true},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup() {}
};
</script>
