<template>
  <div ref="menu" class="fixed flex flex-col gap-fl-sm p-fl-sm rounded bg-darkest" :style="style">
    <div v-for="(item, index) in items" :key="index">
      <standard-divider
        v-if="item.label === 'Delete'"
        :dashed="false"
        class="border-mid my-fl-sm"
      />
      <link-icon-button
        :id="index"
        :key="index"
        :label="item.label"
        :tooltip="item.tooltip"
        :icon="item.icon"
        :color="'light'"
        :on-click="onSelectedCallback"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, ref, onMounted, PropType, Ref, nextTick} from 'vue';
import {useElementBounds} from '../../../vue-composition/element-bounds/element-bounds';
import {PopupMenuItem} from '../types';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';

export default defineComponent({
  components: {
    LinkIconButton,
    StandardDivider
  },
  props: {
    updateDimensions: {
      type: Function as PropType<(width: number, height: number) => void>,
      required: true
    },
    position: {type: Object as PropType<Ref<string>>, required: true},
    items: {type: Array as PropType<ReadonlyArray<PopupMenuItem>>, required: true},
    onSelected: {
      type: Function as PropType<(item: Readonly<PopupMenuItem>) => void>,
      required: true
    }
  },
  setup(props) {
    const menu = ref<InstanceType<typeof HTMLDivElement> | null>(null);
    const menuBounds = useElementBounds(menu, ['resize', 'screen', 'scroll']);

    const style = computed(() => {
      return props.position.value;
    });

    // Let popup menu button component know the dimensions of the menu.
    onMounted(async () => {
      await nextTick();
      menuBounds.update();
      if (menuBounds.bounds.value !== undefined) {
        props.updateDimensions(menuBounds.bounds.value.width, menuBounds.bounds.value.height);
      }
    });

    // When an item is selected, let the popup menu component know (so it can manage the modal overlay).
    const onSelectedCallback = async (id: number | string | undefined) => {
      if (id !== undefined) {
        const item = props.items[id as number];
        props.onSelected(item);
      }
    };

    return {
      menu,
      style,
      onSelectedCallback
    };
  }
});
</script>
