import * as Joi from 'joi';
import {
  VALIDATION_NON_EMPTY_STRING,
  VALIDATION_OPTIONAL_STRING,
  VALIDATION_POSITIVE_INTEGER
} from '../validation';
import {CraftId, VALIDATION_CRAFT_ID, VALIDATION_OPTIONAL_CRAFT_ID} from '../craft/craft-types';

export interface Bookmark {
  // id is undefined/null when creating a new bookmark.
  id?: CraftId | null;

  // The id of the Craft entry that this bookmark points to.
  // It may be null if the bookmark is a parent of other bookmarks.
  entryId: CraftId | null;

  // This string can be used to store additional information,
  // e.g., the id of a chapter in a video.
  data: string | null;

  // id of the parent bookmark of this bookmark (if any).
  parentId: CraftId | null | undefined;

  // Title of this bookmark.
  title: string | null;

  // Use this when sorting sibling bookmarks.
  sequence: number;

  dateCreated?: string;
  dateUpdated?: string;
}

export const BOOKMARK_VALIDATION_SCHEMA = Joi.object({
  id: VALIDATION_CRAFT_ID,
  entryId: VALIDATION_OPTIONAL_CRAFT_ID,
  data: VALIDATION_OPTIONAL_STRING,
  parentId: VALIDATION_OPTIONAL_CRAFT_ID,
  title: VALIDATION_OPTIONAL_STRING,
  sequence: VALIDATION_POSITIVE_INTEGER,
  dateCreated: VALIDATION_NON_EMPTY_STRING,
  dateUpdated: VALIDATION_NON_EMPTY_STRING
});

export const BOOKMARK_LIST_VALIDATION_SCHEMA = Joi.array().items(BOOKMARK_VALIDATION_SCHEMA);
