<template>
  <!-- Margins -->
  <div class="grid" :style="templateColumns">
    <column-grid :row-gap="rowGap" :name="'margins'">
      <slot />
    </column-grid>
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent, provide} from 'vue';
import {gridTemplateColumnsCSS} from './internals/css';
import {getColumnLayoutConfig} from './config';
import {LayoutSystemColumns} from './types';
import ColumnGrid from './ColumnGrid.vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

/**
 * This component creates a central column (between a left and a right margin)
 * and adds a <column-grid> that spans the central column.
 *
 * All <column-grid> components assume that they sit inside a component hierarchy
 * that has a <margins> component at the top.
 *
 * If you do not specify the number of columns, the component will use 4 columns
 * for the 'narrow' screen width and 12 columns otherwise.
 */
export default defineComponent({
  components: {
    ColumnGrid
  },
  props: {
    columns: {type: Number as PropType<LayoutSystemColumns>, default: undefined},
    rowGap: {type: String, default: undefined}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const columns = computed(() => {
      if (props.columns !== undefined) {
        return props.columns;
      }
      return deviceType.largerThan('narrow') ? 12 : 4;
    });

    const config = computed(() => {
      return getColumnLayoutConfig(columns.value, deviceType.screen.value);
    });

    /* 
      Pass the current column, gutter, and margin settings to the children of this component.
      Using Vue's provide/inject mechanism avoids passing this information through the
      component hierarchy.

      See https://vuejs.org/guide/components/provide-inject.html
    */
    provide('layoutSystemConfig', config);

    /*
      Let the child components know that their parent is a <margins> component.
    */
    provide('parentType', 'margins');
    provide('parentColumns', undefined);

    /*
      We must use CSS style for the grid-template-column setting since Tailwind
      does not support dynamically generated class names. (Listing all start column
      and span combinations explicitly is not feasible.)
    */
    /*
      ??? Can we avoid having an "outer" grid? It seems like it should be possible to solve
      with something simpler, like a flex box or a 3-column grid.
    */
    const templateColumns = computed(() =>
      gridTemplateColumnsCSS(config.value, columns.value, true)
    );
    return {
      templateColumns
    };
  }
});
</script>
