<template>
  <member-orientation v-if="!guest" :guest="guest" :entries="entries" :user-id="userId" />
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import {getOrientation} from '../../../backend/orientation/orientation-query';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import MemberOrientation from './variants/MemberOrientation.vue';
import {OrientationProgressItem} from './types';

export default defineComponent({
  components: {
    MemberOrientation
  },
  props: {
    guest: {type: String, required: true},
    userId: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const entries = ref<ReadonlyArray<ContentEntry>>([]);
    const state = ref<ReadonlyArray<OrientationProgressItem>>([]);

    onMounted(async () => {
      if (!props.guest) {
        loader.setLoading(true);
        entries.value = await getOrientation();

        loader.setLoading(false);
      }
    });

    return {
      entries,
      state
    };
  }
});
</script>
