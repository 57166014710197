<template>
  <variants v-if="guest">
    <template #narrow>
      <site-header-top-narrow-guest :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #sm>
      <site-header-top-small-guest :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #md>
      <site-header-top-medium-guest :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #lg+>
      <site-header-top-large-guest :show-navigation-menu="showNavigationMenu" />
    </template>
  </variants>

  <variants v-else>
    <template #narrow>
      <site-header-top-narrow-member :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #sm>
      <site-header-top-small-member :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #md>
      <site-header-top-medium-member :show-navigation-menu="showNavigationMenu" />
    </template>
    <template #lg+>
      <site-header-top-large-member :show-navigation-menu="showNavigationMenu" />
    </template>
  </variants>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Variants from '../../core/column-layout/Variants.vue';
import SiteHeaderTopNarrowGuest from './variants/SiteHeaderTopNarrowGuest.vue';
import SiteHeaderTopSmallGuest from './variants/SiteHeaderTopSmallGuest.vue';
import SiteHeaderTopMediumGuest from './variants/SiteHeaderTopMediumGuest.vue';
import SiteHeaderTopLargeGuest from './variants/SiteHeaderTopLargeGuest.vue';
import SiteHeaderTopNarrowMember from './variants/SiteHeaderTopNarrowMember.vue';
import SiteHeaderTopSmallMember from './variants/SiteHeaderTopSmallMember.vue';
import SiteHeaderTopMediumMember from './variants/SiteHeaderTopMediumMember.vue';
import SiteHeaderTopLargeMember from './variants/SiteHeaderTopLargeMember.vue';
import BurgerMenu from './burger-menu/BurgerMenu.vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {useSidebar} from '../../vue-composition/sidebar/sidebar';
import {getBurgerMenuItems} from './burger-menu/burger-menu-items';

export default defineComponent({
  components: {
    Variants,
    SiteHeaderTopNarrowGuest,
    SiteHeaderTopSmallGuest,
    SiteHeaderTopMediumGuest,
    SiteHeaderTopLargeGuest,
    SiteHeaderTopNarrowMember,
    SiteHeaderTopSmallMember,
    SiteHeaderTopMediumMember,
    SiteHeaderTopLargeMember
  },
  props: {
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const sidebar = useSidebar();
    const burgerMenuItems = computed(() => getBurgerMenuItems());

    const ctaLabel = computed(() =>
      deviceType.largerThan('narrow') ? 'Join Us! <u>Free</u> Trial' : '<u>Free</u> Trial'
    );

    const burgerMenuProps = computed(() => {
      if (burgerMenuItems.value !== undefined) {
        return {
          guest: props.guest,
          menu: burgerMenuItems.value,
          ctaTitle: ctaLabel.value,
          onClose: () => {
            sidebar.hide();
          },
          title: 'Menu'
        };
      }
      return undefined;
    });
    const showNavigationMenu = () => {
      if (burgerMenuItems.value !== undefined) {
        sidebar.show(BurgerMenu, burgerMenuProps.value);
      }
    };

    return {
      showNavigationMenu,
      deviceType,
      ctaLabel
    };
  }
});
</script>
