<template>
  <div class="grid grid-cols-2">
    <div><!-- Intentionally empty --></div>
    <plan-price-cell :offer="offer" :force-borders="true" />

    <div class="px-fl-sm pt-fl-xl pb-fl-xs text-fl-lg text-primary font-medium">
      You're About to Unlock
    </div>
    <div class="border-x border-x-primary">
      <!-- Intentionally empty -->
    </div>

    <!-- Feature matrix -->
    <div
      v-for="(cell, i) in features"
      :key="`feature-${i}`"
      :class="cell.row % 2 === 0 ? 'bg-[#e9e9e9]' : 'bg-full-white'"
    >
      <feature-matrix-cell :cell="cell" :force-borders="true" />
    </div>
    <!-- End feature matrix -->

    <div><!-- Intentionally empty --></div>
    <plan-select-cell :offer="offer" :submit="submit" :force-borders="true" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Feature} from '../utils';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';
import PlanPriceCell from './PlanPriceCell.vue';
import PlanSelectCell from './PlanSelectCell.vue';
import FeatureMatrixCell from './FeatureMatrixCell.vue';

export default defineComponent({
  components: {
    PlanPriceCell,
    PlanSelectCell,
    FeatureMatrixCell
  },
  props: {
    offer: {
      type: Object as PropType<Readonly<SignupOffer> | Readonly<AddOnOffer>>,
      required: true
    },
    features: {
      type: Array as PropType<ReadonlyArray<Feature>>,
      required: true
    },
    onComplete: {
      type: Function as PropType<(offer: Readonly<SignupOffer> | Readonly<AddOnOffer>) => void>,
      required: true
    }
  },
  setup(props) {
    const submit = () => {
      props.onComplete(props.offer);
    };
    return {
      submit
    };
  }
});
</script>
