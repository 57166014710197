<template>
  <standard-button
    :label="'Unlock this Content'"
    :color="'cta'"
    :url="url"
    :size="size"
    :expand="expand"
  />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {ButtonSize} from '../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    size: {type: String as PropType<ButtonSize>, required: true},
    expand: {type: Boolean, default: false}
  },
  setup(props) {
    const url = props.guest ? '/sign-up' : '/account';
    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });
    return {
      url,
      preview
    };
  }
});
</script>
