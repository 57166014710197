<template>
  <page-section :margin-top="'xl'">
    <page-section-header :width="'wide'" :heading="'Current Offers'"></page-section-header>
  </page-section>
  <page-section :margin-top="'sm'">
    <card-grid class="p-4 rounded-lg bg-darkest">
      <member-discount-card
        v-for="(discount, i) in discounts"
        :id="`member-discount-card-${i}${preview ? '-preview' : ''}`"
        :key="discount.id"
        :preview="preview"
        :student-code="studentCode"
        :member-discount="discount"
        :image-width="imageWidth"
        class="pb-6"
      />
    </card-grid>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import CardGrid from '../../../core/compositions/CardGrid.vue';
import MemberDiscountCard from './MemberDiscountCard.vue';
import {MemberDiscount} from '../../../../backend/content/member-discounts/member-discount-types';
import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    CardGrid,
    MemberDiscountCard
  },
  props: {
    guest: {type: String, required: true},
    preview: {type: Boolean, required: true},
    discounts: {type: Array as PropType<ReadonlyArray<MemberDiscount>>, required: true},
    studentCode: {type: String, default: undefined}
  },
  setup() {
    const imageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 320,
      md: 290,
      lg: 360,
      xl: 330,
      '2xl': 400
    };
    return {
      imageWidth
    };
  }
});
</script>
