<template>
  <!-- eslint-disable vue/no-v-html -->
  <li
    v-if="listIcon === undefined && bodyLinkPrimary"
    class="[&_a]:text-primary [&_a]:underline [&_a:hover]:text-white"
    v-html="item"
  />
  <li
    v-if="listIcon === undefined && !bodyLinkPrimary"
    class="[&_a]:text-mid [&_a]:underline [&_a:hover]:text-white"
    v-html="item"
  />
  <li v-if="listIcon !== undefined && bodyLinkPrimary">
    <cluster :wrap="false">
      <font-awesome-icon :icon="listIcon"></font-awesome-icon>
      <div class="[&_a]:text-primary [&_a]:underline [&_a:hover]:text-white" v-html="item"></div>
    </cluster>
  </li>
  <li v-if="listIcon !== undefined && !bodyLinkPrimary">
    <cluster :wrap="false">
      <font-awesome-icon :icon="listIcon"></font-awesome-icon>
      <div class="[&_a]:text-mid [&_a]:underline [&_a:hover]:text-white" v-html="item"></div>
    </cluster>
  </li>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Cluster from '../../../../core/compositions/Cluster.vue';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    Cluster,
    FontAwesomeIcon
  },
  props: {
    item: {type: String, required: true},
    listIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    bodyLinkPrimary: {type: Boolean, default: false}
  },
  setup() {}
});
</script>
