<template>
  <div :class="`flex flex-col justify-start items-center content-center ${cfg}`">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent} from 'vue';

export default defineComponent({
  props: {
    gap: {type: Number, default: 4}
  },
  setup(props) {
    const cfg = computed(() => {
      // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
      const GAP = [
        'gap-1',
        'gap-2',
        'gap-3',
        'gap-4',
        'gap-5',
        'gap-6',
        'gap-7',
        'gap-8',
        'gap-9',
        'gap-10',
        'gap-11',
        'gap-12'
      ];
      const gap = GAP[props.gap - 1];
      if (gap === undefined) {
        throw new Error('Invalid gap');
      }
      return gap;
    });
    return {cfg};
  }
});
</script>
