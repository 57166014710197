<template>
  <button
    v-if="onClick !== undefined"
    :disabled="disabled"
    :class="`flex justify-center items-center flex-nowrap ${cfg} select-none`"
    :title="labelConfig.tooltipText.value"
    :aria-label="labelConfig.ariaLabel.value"
    @click.prevent="clicked"
  >
    <div>{{ labelConfig.labelText }}</div>
  </button>
  <a
    v-else
    :href="disabled ? undefined : url"
    :class="`flex justify-center items-center flex-nowrap ${cfg} select-none`"
    :title="labelConfig.tooltipText.value"
    :aria-label="labelConfig.ariaLabel.value"
    :target="target"
  >
    <div>{{ labelConfig.labelText }}</div>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {ColorConfig} from '../color-config';
import {
  getClickFunc,
  getStandardColor,
  getLabel,
  ButtonSize,
  getStandardDimensions,
  ButtonColor,
  ButtonClickCallback
} from './implementation/utils';

/**
 * A styled, basic button with a label.
 */

export default defineComponent({
  components: {},
  props: {
    disabled: {type: Boolean, default: false},

    size: {type: String as PropType<ButtonSize>, default: 'sm'},
    /** If true, set the width of the button to 100%. */
    expand: {type: Boolean, default: false},
    /** If defined, override the default padding between text and button border with the specified setting. */
    padding: {type: String, default: undefined},

    color: {type: String as PropType<ButtonColor>, default: 'white'},
    /** Optional spot color; overrides the 'color' prop. */
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined},

    label: {type: String, required: true},
    uppercase: {type: Boolean, default: false},
    tooltip: {type: String, default: undefined},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: [Number, String], default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, call this function (with the button id) when the button received input focus. */
    onFocus: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined},
    /** If true, open the URL in a new tab. */
    newTab: {type: Boolean, default: false}
  },
  setup(props) {
    const clicked = getClickFunc(props.onClick, props.id, props.url);
    const color = computed(() => {
      return getStandardColor(props.disabled, props.color, props.spotColor);
    });
    const labelConfig = computed(() => getLabel(props.label, props.tooltip));
    const dim = computed(() => getStandardDimensions(props.size, props.expand, props.padding));
    const pointer = computed(() => {
      return props.disabled ? '' : 'cursor-pointer';
    });
    const uppercase = computed(() => (props.uppercase ? 'uppercase' : ''));

    const cfg = computed(() => {
      return `${dim.value} ${color.value.textColor} ${color.value.bgColor} whitespace-nowrap border-2 rounded-[0.5rem] ${color.value.borderColor} ${pointer.value} ${uppercase.value}`;
    });

    const target = computed(() => {
      return props.newTab ? '_blank' : undefined;
    });

    return {
      cfg,
      labelConfig,
      clicked,
      focus,
      target
    };
  }
});
</script>
