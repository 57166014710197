<template>
  <div
    v-if="preview !== undefined && preview && !disable"
    class="w-full p-fl-xs flex justify-center items-center bg-primary text-black font-semibold"
  >
    <variants v-if="canUnlock && guest">
      <template #narrow>
        <div class="flex justify-center items-center gap-x-fl-xs">
          <font-awesome-icon :icon="lockIcon"></font-awesome-icon>
          <div><a :href="'/sign-up'" class="underline hover:text-white">Join now</a> to unlock</div>
        </div>
      </template>
      <template #sm+>
        <div class="flex justify-center items-center">
          <font-awesome-icon :icon="lockIcon" class="mr-fl-xs"></font-awesome-icon>
          <div class="mr-fl-md">Join FREE now to unlock this content and much more!</div>
          <a
            :class="`py-[5px] px-[10px] w-fit text-base font-semibold text-center whitespace-nowrap text-primary hover:text-black bg-black hover:bg-primary border-2 border-primary rounded-md flex flex-nowrap justify-center`"
            href="/sign-up"
          >
            Join Now
          </a>
        </div>
      </template>
    </variants>

    <variants v-else-if="canUnlock && !guest">
      <template #narrow>
        <div class="flex justify-center items-center gap-x-fl-xs">
          <font-awesome-icon :icon="lockIcon"></font-awesome-icon>
          <div>
            <a :href="'/account'" class="underline hover:text-white">Unlock</a> this content
          </div>
        </div>
      </template>
      <template #sm+>
        <div class="flex justify-center items-center">
          <font-awesome-icon :icon="lockIcon" class="mr-fl-xs"></font-awesome-icon>
          <div class="mr-fl-md">Unlock this content!</div>
          <a
            :class="`py-[5px] px-[10px] w-fit text-base font-semibold text-center whitespace-nowrap text-primary hover:text-black bg-black hover:bg-primary border-2 border-primary rounded-md flex flex-nowrap justify-center`"
            href="/account"
          >
            My Account
          </a>
        </div>
      </template>
    </variants>

    <div v-else class="flex justify-center items-center">
      <div>You do not have access to this content.</div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Variants from '../../core/column-layout/Variants.vue';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {useContentEntryPreview} from '../../vue-composition/content-entry-preview/content-entry-preview';

export default defineComponent({
  components: {
    Variants,
    FontAwesomeIcon
  },
  props: {
    guest: {type: String, required: true},
    segment: {type: String, default: ''},
    entryId: {type: String as PropType<string | undefined | null>, default: undefined}
  },
  setup(props) {
    const contentEntryPreview = useContentEntryPreview();
    const preview = computed(() => {
      if (props.entryId === undefined || props.entryId === null) {
        return false;
      }
      return contentEntryPreview.getStatus(props.entryId);
    });

    /*
      Check if there is an acquirable access pass that provides
      access to this entry.
    */
    const canUnlock = computed(() => {
      if (props.entryId === undefined || props.entryId === null) {
        return false;
      }

      const passes = contentEntryPreview.getAccessPasses(props.entryId);
      if (passes === undefined) {
        return false;
      }

      /*
        First, check if this entry is part of the Academy; if yes then the
        user can gain access to it by signing up for an Academy membership.
      */
      const academy = passes.find(p => {
        return p.slug.startsWith('ap-academy');
      });

      /*
        ??? This entry is not an Academy entry.

        At this point, we should fetch all signup offers and check if any
        of those has a destination access pass that matches one of the ones
        that gain access to this entry. If yes, we should direcct the user
        to the page where they can purchese the destination pass/es.

        For the time being, though, we assume that all non-Academy entries
        are bonuses that are assigned as a side-effect of signing up at a
        specific time (they cannot be acquired in any other way).
      */
      return academy !== undefined;
    });

    const lockIcon = computed(() => faLock);

    const disable = computed(() => {
      /*
        ??? Disable the banner on /blog pages; this is a bit of an ugly hack and
        should be replaced by a more generic system.
      */
      if (props.segment === 'blog') {
        return true;
      }
      return false;
    });

    return {
      canUnlock,
      lockIcon,
      disable,
      preview
    };
  }
});
</script>
