import {CraftQueryFieldHandle} from '../../craft/query/implementation/craft-query-types';
import * as QueryBuilder from '../../craft/query/craft-query-builder';
import {RESOURCES_FIELD} from '../../resource/resource-query';
import {TUTORS_FIELD} from '../../tutor/tutor-query';
import {VIDEOS_FIELD} from '../../video/video-query';
import {IMAGE_COLLECTION_FIELD} from '../../image-collection/image-collection-query';
import {ContentEntryDefaultField, CraftQueryBuilderContentEntries} from './content-entry-types';

function includeField(
  handle: ContentEntryDefaultField,
  incldueDefaultFields?: ReadonlyArray<ContentEntryDefaultField>
) {
  if (incldueDefaultFields === undefined) {
    return true;
  }
  if (incldueDefaultFields.includes(handle)) {
    return true;
  }
  return false;
}

/**
 * Create a content entries field query component.
 *
 * !!IMPORTANT!! If the content entries field includes child content entries
 * (e.g., in a 'childEntries' field) you must specify them explicitly (by
 * passing a `contentEntries` query component to the `fields()` method of this field).
 *
 * @param handle Handle of this field, if it is a child of another field.
 * If this is a top-level query, leave this argument undefined.
 * @param includeDefaultFields If provided, only include these fields (among the
 * content entry default fields). Use this parameter to reduce the size of the
 * response.
 */
export function contentEntries(
  handle?: CraftQueryFieldHandle,
  includeDefaultFields?: ReadonlyArray<ContentEntryDefaultField>
): Readonly<CraftQueryBuilderContentEntries> {
  // The following fields must always be present; they are necessary for the product system.
  const fields = [
    QueryBuilder.categories('parentProducts').required(),
    QueryBuilder.lightswitch('hideWhenNotOwned').required(),
    QueryBuilder.lightswitch('previewInTrial').required(),
    QueryBuilder.lightswitch('publicAccess').required()
  ];

  // Include/exclude the other content entry default fields as per the config.
  if (includeField('campusUrl', includeDefaultFields)) {
    fields.push(QueryBuilder.url('campusUrl'));
  }
  if (includeField('summary', includeDefaultFields)) {
    fields.push(QueryBuilder.plainText('summary'));
  }
  if (includeField('levels', includeDefaultFields)) {
    fields.push(QueryBuilder.categories('levels'));
  }
  if (includeField('subjects', includeDefaultFields)) {
    fields.push(QueryBuilder.categories('subjects'));
  }
  if (includeField('resources', includeDefaultFields)) {
    fields.push(RESOURCES_FIELD);
  }
  if (includeField('tutors', includeDefaultFields)) {
    fields.push(TUTORS_FIELD);
  }
  if (includeField('videos', includeDefaultFields)) {
    fields.push(VIDEOS_FIELD);
  }
  if (includeField('imageCollection', includeDefaultFields)) {
    fields.push(IMAGE_COLLECTION_FIELD);
  }
  if (includeField('recommendations', includeDefaultFields)) {
    /* 
      We cannot include the 'recommendations' field in recommendations, as this
      will create an infinite loop.
      Also, only include what's absolutely necessary to display a recommendation.
    */
    fields.push(
      contentEntries('recommendations', [
        'imageCollection',
        'tutors',
        'summary',
        'levels',
        'subjects'
      ])
    );
  }

  /*
    We don't include 'childEntries' as a default content entry field, as
    that would create an infinite loop when building queries (as the child
    entries are themselves content entries). Instead, the parent/child
    hierarchy has to be specified explicitly.
  */
  return QueryBuilder.entries(handle).fields(fields).customData('contentEntries');
}
