/*
  This file contains general settings, such as external URLs to use, non-secure API keys, etc.
  The source of the values is the .env file. They are passed to this file via the "site.twig"
  template (in the /templates/layout folder).
  
  DO NOT EXPOSE ANY SECRET DATA TO THE FRONTEND SOURCE CODE. 
  
  Anything you put here (or elsewhere in the frontend source code) will be 100% open and can
  be read by ANYONE. Most values in the .env are secret and should ONLY be read from the Craft
  backend (in a controller with the appropriate authorisation).
*/

type SBLSettings = {
  INSTRUMENTATION_URL: string;
  INSTRUMENTATION_API_KEY: string;
  ENABLE_DEVICE_TYPE_DEBUGGER: string;
  RECAPTCHA_LOADED: boolean; // Set to true when the reCAPTCHA script has loaded.
  RECAPTCHA_VERSION: 'recaptchaV2' | 'recaptchaV3';
  RECAPTCHA_SITE_KEY: string;
};
declare global {
  interface Window {
    sbl: undefined | SBLSettings;
  }
}

/** The URL for posting instrumentation events. */
export const INSTRUMENTATION_URL = window.sbl?.INSTRUMENTATION_URL;
/** The API key for the instrumentation event server. */
export const INSTRUMENTATION_API_KEY = window.sbl?.INSTRUMENTATION_API_KEY;

/**
 * Whether to display a device type / viewport breakpoint debug component
 * in the lower left corner of pages.
 */
export const ENABLE_DEVICE_TYPE_DEBUGGER = window.sbl?.ENABLE_DEVICE_TYPE_DEBUGGER;

/** The site key for reCAPTCHA. */
export const RECAPTCHA_SITE_KEY = window.sbl?.RECAPTCHA_SITE_KEY;
