<template>
  <div>
    <offer-component :card="card" />

    <payment
      v-if="amount !== undefined"
      :margin-top="'sm'"
      :width="'extra-narrow'"
      :error="error"
      :email="email"
      :amount="amount"
      :purchase-label="'Confirm Payment'"
      :on-cancel="onCancel"
      :on-purchase="chargePaymentMethod"
      :recaptcha-action="'payment_addon'"
    />
    <backend-error :width="'narrow'" :error="error" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import OfferComponent from '../../offer-card/Offer.vue';
import Payment from '../../payment/Payment.vue';
import BackendError from '../../../generic/backend-error/BackendError.vue';
import {AddOnOffer} from '../../../../backend/offer/addon-offer-types';
import {addOn} from '../../../../backend/signup/checkout-query';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {OfferCardData} from '../../offer-card/types';
import {useBackendError} from '../../../vue-composition/backend-error/backend-error';
import {BraintreeCustomer, BraintreeNonce} from '../../payment/types';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {getCustomerLifetimeEventData} from '../../../../utils/url';

export default defineComponent({
  components: {
    OfferComponent,
    Payment,
    BackendError
  },
  props: {
    email: {type: String, required: true},
    offer: {type: Object as PropType<Readonly<AddOnOffer>>, required: true},
    trialDuration: {type: Number, required: true},
    onComplete: {type: Function as PropType<ButtonClickCallback>, required: true},
    onCancel: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup(props) {
    const error = useBackendError();
    const loader = useFullScreenLoader();

    const accessPass = computed(() => {
      return props.offer.offerDestination[0];
    });
    const amount = computed(() => {
      if (accessPass.value === undefined) {
        return undefined;
      }
      return accessPass.value.accessPassAmount;
    });
    const bonusPassSlugs = computed(() => {
      return props.offer.offerBonuses.map(b => b.slug);
    });
    const card = computed<Readonly<OfferCardData>>(() => {
      return {
        offer: props.offer,
        accessPass: accessPass.value,
        trialDuration: props.trialDuration,
        disabled: false
      };
    });
    const chargePaymentMethod = async (
      nonce: Readonly<BraintreeNonce>,
      customer: Readonly<BraintreeCustomer>
    ) => {
      if (accessPass.value !== undefined) {
        loader.setLoading(
          true,
          'Charging your payment method. Please do not navigate away from this page.'
        );
        try {
          await addOn(
            accessPass.value.slug,
            bonusPassSlugs.value,
            nonce.nonce,
            customer,
            props.trialDuration,
            nonce.deviceData,
            getCustomerLifetimeEventData()
          );
          loader.setLoading(false);

          props.onComplete();
        } catch (e) {
          loader.setLoading(false);
          error.catcher(e);
        }
      }
    };

    return {
      error,
      accessPass,
      amount,
      card,
      chargePaymentMethod
    };
  }
});
</script>
