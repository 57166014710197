<template>
  <repel :gap="'gap-0'" :wrap="false" :class="color">
    <cluster :gap="'gap-fl-xs'" :wrap="false">
      <!-- ### This is a bass clef icon; it is not included in FontAwesome, so we have to hard-code it. -->
      <svg
        :class="`text-fl-3xl ${color}`"
        height="1em"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.304 17.375"
      >
        <path
          id="Path_1901"
          data-name="Path 1901"
          d="M6.025-17.706a5.489,5.489,0,0,0-2.07.382,5.131,5.131,0,0,0-1.641,1.042,4.794,4.794,0,0,0-1.081,1.529,4.472,4.472,0,0,0-.389,1.846,3.764,3.764,0,0,0,.218,1.338,2.7,2.7,0,0,0,.587.949,2.358,2.358,0,0,0,.857.56,2.881,2.881,0,0,0,1.028.185A2.607,2.607,0,0,0,5.372-10.5a2.275,2.275,0,0,0,.679-1.76,2.592,2.592,0,0,0-.178-.989,2.271,2.271,0,0,0-.475-.738,1.993,1.993,0,0,0-.692-.461,2.251,2.251,0,0,0-.844-.158,3.035,3.035,0,0,0-.679.066,2.545,2.545,0,0,0-.508.171,1.946,1.946,0,0,1,.277-.936,2.359,2.359,0,0,1,.633-.686,2.922,2.922,0,0,1,.844-.422,3.063,3.063,0,0,1,.923-.145,3.129,3.129,0,0,1,1.661.442,3.849,3.849,0,0,1,1.219,1.193A5.691,5.691,0,0,1,8.978-13.2a8.186,8.186,0,0,1,.25,2.043,10.814,10.814,0,0,1-.376,2.907A10.071,10.071,0,0,1,7.778-5.735,10.765,10.765,0,0,1,5.326-2.894,13.506,13.506,0,0,1,2-.91a1.306,1.306,0,0,0-.283.145.246.246,0,0,0-.112.211q0,.224.277.224a3.655,3.655,0,0,0,.936-.152A9.364,9.364,0,0,0,4.034-.916,15.191,15.191,0,0,0,5.405-1.6a15.351,15.351,0,0,0,1.411-.89A14.84,14.84,0,0,0,8.154-3.553,10.606,10.606,0,0,0,9.308-4.759a11.556,11.556,0,0,0,1-1.457,11.794,11.794,0,0,0,.778-1.608,10.038,10.038,0,0,0,.508-1.74,9.557,9.557,0,0,0,.178-1.852,7.512,7.512,0,0,0-.409-2.525,5.882,5.882,0,0,0-1.16-1.991,5.251,5.251,0,0,0-1.813-1.305A5.8,5.8,0,0,0,6.025-17.706Zm6.921,2.966a1.057,1.057,0,0,0,.316.778,1.057,1.057,0,0,0,.778.316,1.079,1.079,0,0,0,.784-.316,1.046,1.046,0,0,0,.323-.778,1.068,1.068,0,0,0-.323-.784,1.068,1.068,0,0,0-.784-.323,1.046,1.046,0,0,0-.778.323A1.079,1.079,0,0,0,12.946-14.739Zm0,5.155a1.057,1.057,0,0,0,.316.778,1.057,1.057,0,0,0,.778.316,1.079,1.079,0,0,0,.784-.316,1.046,1.046,0,0,0,.323-.778,1.068,1.068,0,0,0-.323-.784,1.068,1.068,0,0,0-.784-.323,1.046,1.046,0,0,0-.778.323A1.079,1.079,0,0,0,12.946-9.584Z"
          transform="translate(-0.844 17.705)"
          fill="currentColor"
        />
      </svg>

      <div class="text-fl-lg uppercase">Bass</div>
    </cluster>
    <link-button
      :disabled="disabled"
      :color="'lightest'"
      :on-click="variationConfig.onToggleStem"
      :tooltip="'Toggle Bass'"
      :label="variationConfig.stemDisplay.value"
    />
  </repel>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import {VariationConfig} from '../internals/utils';
import LinkButton from '../../../core/button/LinkButton.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faMusic} from '@fortawesome/pro-solid-svg-icons/faMusic';

export default {
  components: {
    LinkButton,
    Repel,
    Cluster
  },
  props: {
    variationConfig: {type: Object as PropType<Readonly<VariationConfig>>, required: true},
    disabled: {type: Boolean, default: false}
  },
  setup(props) {
    const iconMusic = computed(() => faMusic);
    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));

    return {
      color,
      iconMusic
    };
  }
};
</script>
