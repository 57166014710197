<template>
  <div
    v-if="show && !supported"
    class="w-full p-fl-xs flex justify-center items-center bg-sidebar text-white"
  >
    <div class="flex-1"></div>
    <div class="flex justify-center items-center gap-fl-sm">
      <div>Your browser is not officially supported.</div>
      <standard-button :size="'xs'" :label="'More Info'" :url="'/compatibility'" />
    </div>
    <div class="flex-1 flex justify-end">
      <icon-button
        :size="'lg'"
        class="px-fl-xs"
        :icon="iconXmark"
        :tooltip="'Dismiss'"
        :on-click="dismiss"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import StandardButton from '../../core/button/StandardButton.vue';
import IconButton from '../../core/button/IconButton.vue';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import {useLocalStorageTimestamp} from '../../vue-composition/local-storage-timestamp/local-storage-timestamp';

// See https://github.com/browserslist/browserslist-useragent-regexp
import supportedBrowsers from '../../../supportedBrowsers';

export default defineComponent({
  components: {
    StandardButton,
    IconButton
  },
  setup() {
    // Check if the user has dismissed the check; or if the dismissal is older than 30 days.
    const timestamp = useLocalStorageTimestamp('browser-compatibility-timestamp');
    const diff = timestamp.diff();
    const show = ref(false);
    if (diff === undefined) {
      show.value = true;
    } else {
      const MS_PER_DAY = 86400000;
      if (diff > MS_PER_DAY * 30) {
        show.value = true;
      }
    }

    // If the user dismisses the check, store the current timestamp in local storage.
    const dismiss = () => {
      timestamp.set();
      show.value = false;
    };

    // See https://github.com/browserslist/browserslist-useragent-regexp
    const supported = computed(() => {
      return supportedBrowsers.test(navigator.userAgent);
    });

    const iconXmark = computed(() => faXmark);

    return {
      supported,
      iconXmark,
      show,
      dismiss
    };
  }
});
</script>
