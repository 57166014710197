<template>
  <feature-card id="new-member-huddle-card">
    <imgix-image :config="imageConfig" class="w-full bg-dark" />
    <div class="p-fl-lg">
      <div class="mb-fl-md text-fl-2xl text-white text-center font-semibold">
        Join a New Member Huddle!
      </div>
      <div class="mb-fl-md text-fl-xl text-light text-center">
        Hang out with the team, ask questions, meet other new members, and get helpful practice and
        study tips. It's a great way for us to meet you, and to help you get the most out of your
        membership.
      </div>
      <div class="flex justify-center flex-wrap gap-x-fl-md gap-y-fl-xs">
        <div class="text-primary text-fl-lg font-semibold whitespace-nowrap">Mondays at 4pm UK</div>
        <link-button
          :url="'https://us02web.zoom.us/j/5476939953?pwd=UnlxWjc1K2tMaEZmMndFbkoyR0hmdz09'"
          :label="'Join'"
          :color="'lightest'"
          :wrap="false"
          :new-tab="true"
        />
      </div>
    </div>
  </feature-card>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import FeatureCard from '../../../generic/feature-card/FeatureCard.vue';
import {ImgixImageConfig} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    LinkButton,
    ImgixImage,
    FeatureCard
  },
  setup() {
    const imageConfig: Readonly<ImgixImageConfig> = {
      path: 'content/beginner-huddle.jpg',
      alt: 'New Member Huddle',
      width: {
        narrow: 470,
        sm: 650,
        md: 390,
        lg: 500,
        xl: 600,
        '2xl': 560
      }
    };
    return {
      imageConfig
    };
  }
});
</script>
