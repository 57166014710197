<template>
  <variants>
    <template #md->
      <login-narrow :remembered-user-name="rememberedUserName" />
    </template>
    <template #lg+>
      <login-large :remembered-user-name="rememberedUserName" />
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Variants from '../../core/column-layout/Variants.vue';
import LoginLarge from './variations/LoginLarge.vue';
import LoginNarrow from './variations/LoginNarrow.vue';

export default defineComponent({
  components: {
    Variants,
    LoginLarge,
    LoginNarrow
  },
  props: {
    rememberedUserName: {type: String, default: ''}
  }
});
</script>
