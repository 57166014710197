<template>
  <radio-buttons
    ref="radioButtonsRef"
    :buttons="buttons"
    :button-size="buttonSize"
    :on-select="onTabSelected"
    :width="width"
    :justify="justify"
    :radio-button-padding="radioButtonPadding"
    :items="items"
  >
    <slot name="extra" />
  </radio-buttons>
  <!-- 
      We need to use v-show here instead of v-if since the elements must be in the DOM
      in order for the <slot> functionality to work properly.
    -->
  <div>
    <div v-for="button in buttons" v-show="currentTab === button.id" :key="button.id">
      <slot :name="button.id" />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from 'vue';
import {CraftTitle} from '../../../backend/craft/craft-types';
import RadioButtons from '../radio-buttons/RadioButtons.vue';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';
import {ButtonSize} from '../../core/button/implementation/utils';
import {RadioButton, RadioButtonCallback} from '../radio-buttons/radio-button-types';

export default defineComponent({
  components: {
    RadioButtons
  },
  props: {
    tabs: {type: Array as PropType<ReadonlyArray<CraftTitle>>, required: true},
    buttonSize: {type: String as PropType<ButtonSize>, default: undefined},
    justify: {type: String, default: 'justify-start'},
    radioButtonPadding: {type: String, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'},
    items: {type: String, default: 'items-start'},
    icon: {type: Object as PropType<Readonly<IconDefinition>>, default: undefined},
    iconSelected: {type: Object as PropType<Readonly<IconDefinition>>, default: undefined}
  },
  setup(props) {
    const radioButtonsRef = ref(null as InstanceType<typeof RadioButtons> | null);
    const buttons = computed<ReadonlyArray<RadioButton>>(() => {
      return props.tabs.map((tab, index) => {
        const rb: RadioButton = {
          label: tab,
          id: index,
          icon: props.icon,
          iconSelected: props.iconSelected
        };
        return rb;
      });
    });
    const currentTab = ref(0);
    const currentTabName = ref('');
    const onTabSelected: RadioButtonCallback = (id?: number) => {
      if (id !== undefined) {
        currentTab.value = id;
        currentTabName.value = buttons.value[id].label;
      }
    };

    const selectTab = (index: number) => {
      if (index >= 0 && index < props.tabs.length) {
        if (radioButtonsRef.value !== null) {
          radioButtonsRef.value.selectButton(index);
        }
      }
    };

    return {
      radioButtonsRef,
      buttons,
      currentTab,
      onTabSelected,
      selectTab
    };
  }
});
</script>
