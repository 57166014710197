import {App} from 'vue';

export type SidebarStoreState = {
  status: boolean;
  app: Readonly<App> | undefined;
  location: 'left' | 'right';
};

export const SidebarStore = {
  namespaced: true,
  state: {
    status: false,
    app: undefined,
    location: 'left'
  } as SidebarStoreState,
  mutations: {
    activate(
      state: SidebarStoreState,
      payload: {location: 'left' | 'right'; app: Readonly<App> | undefined}
    ) {
      state.app = payload.app;
      state.location = payload.location;
      state.status = true;
    },
    deactivate(state: SidebarStoreState) {
      state.app = undefined;
      state.status = false;
    }
  },
  actions: {
    activate({commit}: any, payload: {app: Readonly<App> | undefined}) {
      commit('activate', payload);
    },
    deactivate({commit}: any) {
      commit('deactivate');
    }
  },
  getters: {
    status(state: Readonly<SidebarStoreState>) {
      return state.status;
    },
    app(state: Readonly<SidebarStoreState>) {
      return state.app;
    },
    location(state: Readonly<SidebarStoreState>) {
      return state.location;
    }
  }
};
