import {STORE} from '../../../store/store';
import {CraftId} from '../../../backend/craft/craft-types';
import {Category} from '../../../backend/craft/category/category-types';

/**
 * This composition provides access to a flag that specifies whether
 * the content entry currently on display (if any) is in preview mode
 * or not.
 */
export function useContentEntryPreview() {
  return {
    /**
     * Return the preview status of the specified content entry.
     * @param contentEntryId The Craft id of the content entry.
     */
    getStatus: (contentEntryId: CraftId): boolean | undefined => {
      const status = STORE.getters['preview/status'](contentEntryId);
      return status;
    },

    getAccessPasses: (contentEntryId: CraftId): ReadonlyArray<Category> | undefined => {
      const accessPasses = STORE.getters['preview/accessPasses'](contentEntryId);
      return accessPasses;
    },

    /**
     * Set the preview status of the specified content entry.
     * @param contentEntryId The Craft id of the content entry.
     */
    setStatus: (entryId: CraftId, status: boolean, accessPasses: ReadonlyArray<Category>) => {
      STORE.dispatch('preview/setStatus', {entryId, status, accessPasses});
    }
  };
}
