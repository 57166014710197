<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <standard-heading class="mb-12" :size="1">404<br />Page Not Found</standard-heading>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import StandardHeading from '../core/standard-heading/StandardHeading.vue';
import ContentColumn from '../core/compositions/ContentColumn.vue';

export default {
  components: {
    PageSection,
    PageContent,
    StandardHeading,
    ContentColumn
  },
  setup() {}
};
</script>
