<template>
  <div class="w-full">
    <div :id="'braintree-dropin-ui'"></div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, onMounted, ref} from 'vue';
import {Dropin} from 'braintree-web-drop-in';
import {BackendErrorComposition} from '../../../vue-composition/backend-error/backend-error';
import {createDropinUI} from '../../../../backend/signup/braintree-dropin-ui';
import {BraintreeCustomer} from '../types';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';

/**
 * This component allows the user to select a Braintree payment method.
 * If successful, a "nonce" is created that can be passed to the Braintree
 * payment system (via the Craft backend) to charge funds from the payment
 * method; either a single transaction or initiate a subscription.
 */
export default defineComponent({
  components: {},
  props: {
    error: {type: Object as PropType<BackendErrorComposition>, required: true},
    customer: {type: Object as PropType<BraintreeCustomer>, required: true},
    onReadyStatusChange: {
      type: Function as PropType<(dropin: Dropin, status: boolean) => void>,
      required: true
    }
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const dropin = ref<Dropin | undefined>(undefined);

    onMounted(async () => {
      try {
        loader.setLoading(true, 'Please wait');
        // Set up a new Dropin instance.
        dropin.value = await createDropinUI(
          props.customer.clientToken,
          `#braintree-dropin-ui`,
          () => {
            props.error.clear();
          },
          (status: boolean) => {
            props.error.clear();
            if (dropin.value !== undefined) {
              props.onReadyStatusChange(dropin.value, status);
            }
          }
        );
        loader.setLoading(false);
      } catch (err) {
        loader.setLoading(false);
        props.error.catcher(`Could not create payment method component: ${err}`);
        return undefined;
      }
    });
  }
});
</script>
