<template>
  <footer role="contentinfo" class="p-4">
    <flow class="flex flex-col justify-center">
      <site-footer-social />
      <site-footer-links />
      <cluster :justify="'justify-center'">
        <p class="text-mid">&copy; {{ year }} Scott's Bass Lessons</p>
      </cluster>
    </flow>
  </footer>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Cluster from '../core/compositions/Cluster.vue';
import Flow from '../core/compositions/Flow.vue';
import SiteFooterSocial from './partials/SiteFooterSocial.vue';
import SiteFooterLinks from './partials/SiteFooterLinks.vue';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook';
import {faTiktok} from '@fortawesome/free-brands-svg-icons/faTiktok';

export default defineComponent({
  components: {
    Cluster,
    Flow,
    SiteFooterLinks,
    SiteFooterSocial
  },
  setup() {
    const year = computed(() => {
      return new Date().getFullYear();
    });
    return {
      year,
      iconYoutube: computed(() => faYoutube),
      iconInstagram: computed(() => faInstagram),
      iconFacebook: computed(() => faFacebook),
      iconTiktok: computed(() => faTiktok)
    };
  }
});
</script>
