<template>
  <div>
    <!-- Subtitle -->
    <div v-if="item.id === undefined" class="py-fl-sm text-mid select-none">
      <content-column :width="'wide'">
        {{ item.title }}
      </content-column>
    </div>

    <!-- Item as button -->
    <div
      v-else-if="onClick !== undefined"
      :class="`${backgroundClass} group`"
      @click="onButtonClicked"
    >
      <standard-divider v-if="divider" class="border-dark" />
      <content-column :width="'wide'">
        <button
          :aria-label="`Go to ${item.title}`"
          class="py-fl-sm w-full h-full flex justify-start items-center flex-nowrap gap-fl-md"
        >
          <font-awesome-icon :icon="icon" :class="`text-fl-xl ${iconColor}`"></font-awesome-icon>
          <div :class="`${textColor} ${wordBreak} text-left whitespace-normal`">
            {{ item.title }}
          </div>
        </button>
      </content-column>
      <standard-divider class="border-dark" />
    </div>

    <!-- Item as link -->
    <div
      v-else-if="item.url !== undefined"
      :class="`${backgroundClass} group`"
      @click="onButtonClicked"
    >
      <standard-divider v-if="divider" class="border-dark" />
      <content-column :width="'wide'">
        <a
          :aria-label="`Go to ${item.title}`"
          :href="item.url"
          class="py-fl-sm w-full h-full flex justify-start items-center flex-nowrap gap-fl-md"
        >
          <font-awesome-icon :icon="icon" :class="`text-fl-xl ${iconColor}`"></font-awesome-icon>
          <div :class="`${textColor} text-left whitespace-normal`">{{ item.title }}</div>
        </a>
      </content-column>
      <standard-divider class="border-dark" />
    </div>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {MediaBrowserItem} from '../media-browser-types';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {faPlay} from '@fortawesome/pro-light-svg-icons/faPlay';
import {faCirclePlay} from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import {faPlay as faPlaySolid} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    StandardDivider,
    FontAwesomeIcon,
    ContentColumn
  },
  props: {
    onClick: {type: Function, default: undefined},
    item: {type: Object as PropType<Readonly<MediaBrowserItem>>, required: true},
    sidebarIconType: {
      type: String as PropType<'play' | 'check'>,
      default: 'check'
    },
    divider: {type: Boolean, default: true}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const onButtonClicked = () => {
      if (props.onClick) {
        props.onClick(props.item.id);
      }
    };
    const textColor = computed(() => {
      if (props.item.complete) {
        return 'text-progress group-hover:text-white';
      }
      return props.item.highlight ? 'text-white' : 'text-mid group-hover:text-white';
    });

    const iconColor = computed(() => {
      if (props.item.highlight) {
        return props.item.complete ? 'text-progress' : 'text-lightest';
      }
      return props.item.complete
        ? 'text-progress group-hover:text-white'
        : 'text-mid group-hover:text-white';
    });

    const backgroundClass = computed(() => {
      const bg = props.item.highlight ? 'bg-dark' : '';
      const cursor = props.item.id !== undefined ? 'cursor-pointer' : '';
      return `${bg} ${cursor}`;
    });

    const wordBreak = deviceType.largerThan('narrow') ? undefined : 'break-all';

    const icon = computed(() => {
      if (props.sidebarIconType === 'check') {
        return props.item.highlight ? faCirclePlay : faCheck;
      }
      return props.item.highlight ? faPlaySolid : faPlay;
    });

    return {
      onButtonClicked,
      backgroundClass,
      textColor,
      iconColor,
      icon,
      wordBreak
    };
  }
});
</script>
