<template>
  <button @click="onClick">
    <pill class="select-none text-mid hover:text-white border-mid hover:border-white">
      {{ property.property }}
      <FontAwesomeIcon :icon="icon" class="ml-1"></FontAwesomeIcon>
    </pill>
  </button>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Pill from '../pill/Pill.vue';
import {faXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
  FilterPropertyFromFilter,
  FilterSetComposition
} from '../../vue-composition/filter-set/types';

export default defineComponent({
  components: {
    Pill,
    FontAwesomeIcon
  },
  props: {
    filterSet: {type: Object as PropType<Readonly<FilterSetComposition>>, required: true},
    property: {type: Object as PropType<Readonly<FilterPropertyFromFilter>>, required: true}
  },
  setup(props) {
    const icon = computed(() => faXmark);
    const onClick = () => {
      props.filterSet.remove(props.property.name, props.property.property);
    };
    return {
      icon,
      onClick
    };
  }
});
</script>
