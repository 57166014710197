import {OfferConditionsVisitorStatus} from '../offer-conditions-types';

export function evaluateVisitorStatus(
  condition: Readonly<OfferConditionsVisitorStatus>,
  guest: boolean
) {
  if (condition.visitorStatus === 'guest') {
    return guest;
  }
  return !guest;
}
