import {PlayersPathLevel} from '../../../backend/content/players-path/players-path-types';
import {ColorConfig} from '../../core/color-config';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';

export type PlayersPathLevelGroup = {
  title: string;
  levels: Array<PlayersPathLevel>;
};

export function getPlayersPathLevelNumber(trails: Readonly<BreadcrumbTrailComposition>): number {
  if (trails.navigationPath === undefined) {
    throw new Error('Cannot get players path level: no navigation path');
  }
  const item = trails.navigationPath[1];
  if (item === undefined) {
    throw new Error('Cannot get players path level: level item is undefined');
  }
  const matches = /\d+$/.exec(item.title);
  if (matches === null) {
    throw new Error('Cannot get players path level: no number in title');
  }
  const level = parseInt(matches[0], 10);
  if (Number.isNaN(level)) {
    throw new Error('Cannot get players path level: cannot parse level number');
  }
  return level;
}

export function getPlayersPathLevelNumberFromSlug(slug: string) {
  const level = parseInt(slug.substring(19));
  if (Number.isNaN(level)) {
    throw new Error('Could not parse players path level slug');
  }
  return level;
}

export function playersPathLevelSpotColor(level: number): Readonly<ColorConfig> {
  if (level < 1 || level > 9) {
    throw new Error('Invalid players path level number');
  }
  // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  const BG_COLORS = [
    'bg-pp1',
    'bg-pp2',
    'bg-pp3',
    'bg-pp4',
    'bg-pp5',
    'bg-pp6',
    'bg-pp7',
    'bg-pp8',
    'bg-pp9'
  ];
  const BG_HOVER_COLORS = [
    'hover:bg-pp1',
    'hover:bg-pp2',
    'hover:bg-pp3',
    'hover:bg-pp4',
    'hover:bg-pp5',
    'hover:bg-pp6',
    'hover:bg-pp7',
    'hover:bg-pp8',
    'hover:bg-pp9'
  ];
  const TEXT_COLORS = [
    'text-pp1',
    'text-pp2',
    'text-pp3',
    'text-pp4',
    'text-pp5',
    'text-pp6',
    'text-pp7',
    'text-pp8',
    'text-pp9'
  ];
  const BORDER_COLORS = [
    'border-pp1',
    'border-pp2',
    'border-pp3',
    'border-pp4',
    'border-pp5',
    'border-pp6',
    'border-pp7',
    'border-pp8',
    'border-pp9'
  ];
  const TEXT_STROKE_COLORS = [
    'text-stroke-pp1',
    'text-stroke-pp2',
    'text-stroke-pp3',
    'text-stroke-pp4',
    'text-stroke-pp5',
    'text-stroke-pp6',
    'text-stroke-pp7',
    'text-stroke-pp8',
    'text-stroke-pp9'
  ];
  const STROKE_COLORS = [
    'stroke-pp1',
    'stroke-pp2',
    'stroke-pp3',
    'stroke-pp4',
    'stroke-pp5',
    'stroke-pp6',
    'stroke-pp7',
    'stroke-pp8',
    'stroke-pp9'
  ];
  return {
    text: TEXT_COLORS[level - 1],
    textHover: 'hover:text-black',

    bg: BG_COLORS[level - 1],
    bgHover: BG_HOVER_COLORS[level - 1],

    border: BORDER_COLORS[level - 1],
    borderHover: BG_HOVER_COLORS[level - 1],

    textStroke: TEXT_STROKE_COLORS[level - 1],
    stroke: STROKE_COLORS[level - 1]
  };
}
