<template>
  <div v-if="offers.length > 0">
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-heading :size="1">Resubscribe</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <div class="flex flex-col">
          <div v-for="(offer, index) in offers" :key="offer.id">
            <standard-divider v-if="index === 0" class="border-dark" />
            <resubscribe-offer-component :offer="offer" />
            <standard-divider class="border-dark" />
          </div>
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../../../core/standard-divider/StandardDivider.vue';
import ResubscribeOfferComponent from './ResubscribeOffer.vue';
import {ResubscribeOffer} from '../../../../../../backend/offer/resubscribe-offer-types';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    StandardDivider,
    ResubscribeOfferComponent
  },
  props: {
    offers: {type: Array as PropType<ReadonlyArray<ResubscribeOffer>>, required: true}
  },
  setup() {}
});
</script>
