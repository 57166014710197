import {App, nextTick} from 'vue';

export type ModalOverlayCallback = () => void;

export type ModalOverlayStoreState = {
  status: boolean;
  listeners: Array<ModalOverlayCallback>;
  app: Readonly<App> | undefined;
  blur: boolean;
};

export const ModalOverlayStore = {
  namespaced: true,
  state: {
    status: false,
    listeners: [],
    app: undefined,
    blur: false
  } as ModalOverlayStoreState,
  mutations: {
    activate(
      state: ModalOverlayStoreState,
      payload: {callback: ModalOverlayCallback; app: Readonly<App>; blur: boolean}
    ) {
      state.listeners.push(payload.callback);
      state.app = payload.app;
      state.blur = payload.blur;
      state.status = true;
    },
    deactivate(state: ModalOverlayStoreState) {
      state.app = undefined;
      state.blur = false;
      state.status = false;
      state.listeners.forEach(listener => listener());
    }
  },
  actions: {
    activate(
      {commit}: any,
      payload: {callback: ModalOverlayCallback; app: Readonly<App> | undefined}
    ) {
      commit('activate', payload);
    },
    async deactivate({commit}: any) {
      commit('deactivate');
      await nextTick();
    }
  },
  getters: {
    status(state: Readonly<ModalOverlayStoreState>) {
      return state.status;
    },
    app(state: Readonly<ModalOverlayStoreState>) {
      return state.app;
    },
    blur(state: Readonly<ModalOverlayStoreState>) {
      return state.blur;
    }
  }
};
