import {RICH_TEXT_FIELD} from '../../rich-text/rich-text-query';
import {fetchContentEntries} from '../content-entry/content-entry-query';
import {contentEntries} from '../content-entry/content-entry-query-builder';
import {Post} from './post-types';

export async function getPostBySlug(slug: string): Promise<Readonly<Post | undefined>> {
  try {
    const result = await fetchContentEntries<Post>(
      contentEntries().section(['blog']).slug([slug]).fields([RICH_TEXT_FIELD])
    );
    return result[0];
  } catch (error) {
    throw new Error(`Could not fetch post: ${error}`);
  }
}
