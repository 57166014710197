import {Image, ImageCollection} from './image-collection-types';

/**
 * Given a list of (prioritised) handles, return the first image
 * in the collection that matches one of the handles.
 */
export function findImage(
  imageCollection: Readonly<ImageCollection>,
  handles: ReadonlyArray<string>
): Readonly<Image> | undefined {
  const result = handles.reduce((accum: Readonly<Image> | undefined, handle) => {
    if (accum === undefined) {
      const img = imageCollection.find(img => img.imageHandle === handle);
      return img;
    }
    return accum;
  }, undefined);
  return result;
}

export function imageExistsInCollection(
  imageCollection: Readonly<ImageCollection>,
  handle: string
) {
  const img = imageCollection.find(img => img.imageHandle === handle);
  return img !== undefined;
}
