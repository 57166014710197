<template>
  <content-area v-if="upcomingMentorSeminars.length > 0">
    <div v-for="(ce, i) in upcomingMentorSeminars" :key="ce.id">
      <standard-divider v-if="i === 0" class="border-mid" />
      <div class="py-fl-md">
        <event-promo-card :guest="guest" :bookmark="bookmark" :content-entry="ce" />
      </div>
      <standard-divider class="border-mid" />
    </div>
  </content-area>
  <content-area v-else>
    <div class="mb-fl-md w-full h-fl-4xl bg-darkest rounded-lg"></div>
    <div
      class="my-fl-md px-fl-lg h-fl-4xl flex justify-center items-center bg-darkest rounded-lg text-fl-xl sm:text-fl-3xl font-semibold text-dark text-center"
    >
      There are currently no scheduled seminars
    </div>
    <div class="my-fl-md w-full h-fl-4xl bg-darkest rounded-lg"></div>
  </content-area>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import EventPromoCard from '../../../content-entry/representations/event-promo/EventPromoCard.vue';

export default defineComponent({
  components: {
    ContentArea,
    StandardDivider,
    EventPromoCard
  },
  props: {
    guest: {type: String, required: true},
    upcomingMentorSeminars: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<BookmarkComposition>, required: true}
  },
  setup() {}
});
</script>
