import {Ref, computed, ref, watch} from 'vue';
import {useSync} from '../../../vue-composition/sync/sync';
import {CraftUrl} from '../../../../backend/craft/craft-types';

export type AudioPlayerComposition = {
  isPlaying: Ref<boolean>;
  speedDisplay: Ref<string>;
  repeatLabel: Ref<string>;
  progressSeconds: Ref<number>;
  durationSeconds: Ref<number>;
  togglePlay: () => void;
  onReset: () => void;
  toggleRepeat: () => void;
  onSpeedUp: () => void;
  onSpeedDown: () => void;
  onScrub: (pos: number) => void;
  onBeforeUnmount: () => void;
};

export function useAudioPlayer(
  audioElement: Ref<InstanceType<typeof HTMLAudioElement> | null>,
  url: CraftUrl
): Readonly<AudioPlayerComposition> {
  const isPlaying = ref(false);
  const progressSeconds = ref(0);
  const durationSeconds = ref(0);
  const minPlaySpeed = 0.5;
  const maxPlaySpeed = 1.2;
  const playSpeedIncrement = 0.1;
  const currentPlaySpeed = ref(1);
  const isRepeating = ref(false);

  const speedDisplay = computed(() => {
    return `${Math.round(currentPlaySpeed.value * 100)}%`;
  });
  const repeatLabel = computed(() => {
    return isRepeating.value ? 'ON' : 'OFF';
  });

  watch(audioElement, elem => {
    if (elem !== null) {
      elem.addEventListener('play', () => {
        isPlaying.value = true;
      });
      elem.addEventListener('pause', () => {
        isPlaying.value = false;
      });
      elem.addEventListener('timeupdate', () => {
        progressSeconds.value = Math.floor(elem.currentTime);
      });
      elem.addEventListener('durationchange', () => {
        durationSeconds.value = Math.floor(elem.duration);
      });
      elem.addEventListener('ended', () => {
        if (isRepeating.value) {
          elem.currentTime = 0;
          elem.play();
        }
      });
    }
  });

  const sync = useSync('media', url, async () => {
    if (audioElement.value !== null) {
      audioElement.value.pause();
      isPlaying.value = false;
    }
  });

  const togglePlay = () => {
    sync.lock();
    if (audioElement.value !== null) {
      if (isPlaying.value) {
        audioElement.value.pause();
        isPlaying.value = false;
      } else {
        audioElement.value.play();
        isPlaying.value = true;
      }
    }
  };

  const onReset = () => {
    if (audioElement.value !== null) {
      audioElement.value.currentTime = 0;
    }
  };

  const toggleRepeat = () => {
    isRepeating.value = !isRepeating.value;
  };

  const onSpeedUp = () => {
    if (audioElement.value !== null) {
      let newSpeed = currentPlaySpeed.value;
      newSpeed += playSpeedIncrement;
      if (newSpeed > maxPlaySpeed) {
        newSpeed = maxPlaySpeed;
      }
      currentPlaySpeed.value = newSpeed;
      audioElement.value.playbackRate = currentPlaySpeed.value;
    }
  };
  const onSpeedDown = () => {
    if (audioElement.value !== null) {
      let newSpeed = currentPlaySpeed.value;
      newSpeed -= playSpeedIncrement;
      if (newSpeed < minPlaySpeed) {
        newSpeed = minPlaySpeed;
      }
      currentPlaySpeed.value = newSpeed;
      audioElement.value.playbackRate = currentPlaySpeed.value;
    }
  };

  const onScrub = (pos: number) => {
    if (audioElement.value !== null) {
      audioElement.value.currentTime = pos * durationSeconds.value;
    }
  };

  const onBeforeUnmount = () => {
    sync.onBeforeUnmount();
  };

  return {
    isPlaying,
    speedDisplay,
    repeatLabel,
    progressSeconds,
    durationSeconds,
    togglePlay,
    onReset,
    toggleRepeat,
    onSpeedUp,
    onSpeedDown,
    onScrub,
    onBeforeUnmount
  };
}
