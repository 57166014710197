import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {ControllerResponse} from '../craft-action';
import {fetchCategories} from '../craft/category/category-query';
import {categories} from '../craft/query/craft-query-builder';
import {ProductDefinition} from './product-definition-types';

const productDefinitionsCache = new ThreadSyncCache<
  ControllerResponse<ReadonlyArray<ProductDefinition>>
>();

export async function getAllProductDefinitions(): Promise<ReadonlyArray<ProductDefinition>> {
  const query = categories()
    .group('productDefinitions')
    .fields([categories('accessPasses')]);
  const result = await fetchCategories<ProductDefinition>(
    query,
    productDefinitionsCache,
    'productDefinitions'
  );
  return result;
}
