import {CraftSlug} from '../craft/craft-types';
import {Career} from './careers-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {CraftQueryBuilder} from '../craft/query/craft-query-builder-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';

const CAREER_FIELDS: Array<CraftQueryBuilder> = [
  QueryBuilder.url('careersInfoUrl'),
  QueryBuilder.plainText('summary'),
  IMAGE_COLLECTION_FIELD
];

export async function getAllCareers(): Promise<ReadonlyArray<Career>> {
  try {
    const careers = await fetchEntries<Career>(
      QueryBuilder.entries().section(['careers']).fields(CAREER_FIELDS)
    );
    return careers;
  } catch (error) {
    throw new Error(`Could not fetch careers: ${error}`);
  }
}

export async function getCareerBySlug(slug: CraftSlug): Promise<Career | undefined> {
  try {
    const careers = await fetchEntries<Career>(
      QueryBuilder.entries().section(['careers']).slug([slug]).fields(CAREER_FIELDS)
    );
    return careers[0];
  } catch (error) {
    throw new Error(`Could not fetch career: ${error}`);
  }
}
