<template>
  <button
    :class="`w-fl-xl flex flex-col justify-center group hover:bg-black/30`"
    :title="tooltip"
    @click="onClick"
  >
    <div class="w-full p-fl-2xs h-1/3 text-lightest group-hover:text-white">
      <font-awesome-icon :icon="icon" class="w-full h-full drop-shadow-sm"> </font-awesome-icon>
    </div>
  </button>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    orientation: {type: String as PropType<'left' | 'right'>, required: true},
    onClick: {type: Function as PropType<() => void>, required: true},
    tooltip: {type: String, required: true}
  },
  setup(props) {
    const gradient = computed(() => {
      return props.orientation === 'left'
        ? 'bg-gradient-to-r from-black'
        : 'bg-gradient-to-l from-black';
    });
    const icon = computed(() => {
      return props.orientation === 'left' ? faAngleLeft : faAngleRight;
    });
    return {
      icon,
      gradient
    };
  }
});
</script>
