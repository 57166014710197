<template>
  <h1 v-if="size === 1" :class="`text-fl-11xl ${wordBreak} ${textCenter} font-semibold ${color}`">
    <slot></slot>
  </h1>
  <h2
    v-else-if="size === 2"
    :class="`text-fl-7xl ${wordBreak} ${color} ${textCenter} font-semibold`"
  >
    <slot></slot>
  </h2>
  <h3
    v-else-if="size === 3"
    :class="`text-fl-3xl ${wordBreak} ${color} ${textCenter} font-semibold`"
  >
    <slot></slot>
  </h3>
  <h4
    v-else-if="size === 4"
    :class="`text-fl-lg ${wordBreak} ${color} ${textCenter} font-semibold`"
  >
    <slot></slot>
  </h4>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../color-config';

export default defineComponent({
  props: {
    size: {type: Number, default: 1},
    wordBreak: {type: String, default: 'break-normal'},
    center: {type: Boolean, default: false},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const color = computed(() => {
      return props.spotColor !== undefined ? props.spotColor.text : 'text-white';
    });
    const textCenter = computed(() => (props.center ? 'text-center' : 'text-left'));
    return {
      color,
      textCenter
    };
  }
});
</script>
