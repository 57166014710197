<template>
  <div class="mt-fl-lg-5xl bg-almost-black">
    <page-section :padding-top="'lg'">
      <content-column :width="'narrow'">
        <div class="flex flex-col">
          <div class="mb-fl-lg text-fl-3xl text-primary font-semibold text-center">Quick Guide</div>
          <div class="mb-fl-xl text-fl-9xl text-white font-semibold text-center">
            Get to know the SBL Academy
          </div>
          <p class="text-fl-3xl text-mid font-semibold text-center">
            The Academy offers all the tools you need to make real progress with your bass playing.
            Check out the main areas below to learn more!
          </p>
        </div>
      </content-column>
    </page-section>
    <page-section :margin-top="'md'" :margin-bottom="'sm'">
      <dashboard-section-promo-row :guest="guest" :cards="cards" />
    </page-section>
    <page-section :margin-top="'md'" :padding-bottom="'lg'">
      <div v-if="!guest && onHide !== undefined" class="flex flex-col justify-center items-center">
        <standard-button
          :size="'lg'"
          :label="'Academy Tour'"
          :url="'/orientation'"
          class="mb-fl-lg"
        />
        <link-button :size="'sm'" :on-click="onHide" :label="'Hide this Guide'" :underline="true" />
      </div>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import DashboardSectionPromoRow from './DashboardSectionPromoRow.vue';
import {SectionPromoConfig} from './types';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardButton,
    LinkButton,
    DashboardSectionPromoRow
  },
  props: {
    guest: {type: String, required: true},
    cards: {type: Array as PropType<ReadonlyArray<SectionPromoConfig>>, required: true},
    onHide: {type: Function as PropType<ButtonClickCallback>, default: undefined}
  },
  setup() {}
});
</script>
