<template>
  <card :url="contentEntry.url">
    <template #hero>
      <card-hero-image :config="coverImage" />
    </template>
    <template #center>
      <card-progress-bar v-if="progressVisible" :progress="progress" />
    </template>
    <template #body>
      <div :id="contentEntry.slug">
        <div
          v-if="title !== undefined"
          :class="
            progressVisible
              ? 'w-2/3 font-semibold text-light text-base'
              : 'w-2/3 font-semibold text-light text-base mt-4'
          "
          aria-label="title"
        >
          {{ title }}
        </div>
        <div v-if="tutors !== undefined" class="text-mid text-sm">
          {{ tutors }}
        </div>
      </div>
    </template>
  </card>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import CardProgressBar from '../../../generic/card/partials/CardProgressBar.vue';
import {
  getImageConfig,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ImgixImageWidth} from '../../../core/imgix-image/types';
export default defineComponent({
  components: {
    Card,
    CardHeroImage,
    CardProgressBar
  },
  props: {
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    completed: {type: Boolean, default: undefined},
    progress: {type: Number, default: undefined}, // [0,100]
    imageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true}
  },
  setup(props) {
    const coverImage = computed(() => {
      return getImageConfig(
        props.contentEntry.imageCollection,
        ['poster'],
        props.contentEntry.title,
        props.imageWidth
      );
    });

    const tutors = computed(() => {
      if (props.contentEntry === undefined) {
        return;
      }
      const tutors = tutorList(props.contentEntry);
      return tutors;
    });

    const title = computed(() => {
      if (props.contentEntry === undefined) {
        return;
      }
      return props.contentEntry.title;
    });

    const completedStatus = computed(() => {
      if (props.completed !== undefined) {
        return props.completed;
      }
      if (props.progress !== undefined) {
        return props.progress === 100;
      }
      return undefined;
    });

    const url = computed(() => {
      if (props.contentEntry === undefined) {
        return;
      }
      return props.contentEntry.url;
    });

    const progressVisible = computed(() => {
      if (props.progress !== undefined && !completedStatus.value && props.progress > 0) {
        return true;
      }
      return false;
    });

    return {
      url,
      tutors,
      coverImage,
      completedStatus,
      title,
      progressVisible
    };
  }
});
</script>
