import {cachedPostAction, ControllerResponse, postAction} from '../../craft-action';
import {CraftQueryCategoriesField} from '../query/implementation/craft-query-types';
import {buildValidationSchema} from '../query/implementation/craft-query-validation';
import {Category} from './category-types';
import {CraftQueryBuilderCategories} from '../query/craft-query-builder-types';
import {ThreadSyncCache} from '../../../utils/thread-sync-cache';

/**
 * Fetch categories from a specified Craft category group.
 */
export async function fetchCategories<T extends Category>(
  query: Readonly<CraftQueryBuilderCategories>,
  cache?: ThreadSyncCache<ControllerResponse<ReadonlyArray<T>>>,
  cacheKey?: string | number
): Promise<ReadonlyArray<T>> {
  /*
    ### This code is more or less a repeat of the code in `fetchEntries` and
    `fetchGlobalSet`, so it is possible to generalise them into a single
    `fetchElements` function.
  */
  const field = query._build() as CraftQueryCategoriesField;
  const validationSchema = buildValidationSchema(field);
  const url = '/actions/sbl-module/element-query/query';
  try {
    let result: ReadonlyArray<T> = [];
    if (cache !== undefined && cacheKey !== undefined) {
      result = await cachedPostAction<ReadonlyArray<T>>(
        url,
        field,
        validationSchema,
        cache,
        cacheKey
      );
    } else {
      result = await postAction<ReadonlyArray<T>>(url, field, validationSchema);
    }
    return result;
  } catch (error) {
    throw new Error(`Could not fetch categories: ${error}`);
  }
}
