<template>
  <page-content>
    <add-on-purchase
      v-if="offer !== undefined && trialDuration !== undefined"
      :offer="offer"
      :email="email"
      :trial-duration="trialDuration"
      :on-cancel="onCancel"
      :on-complete="onComplete"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import AddOnPurchase from './partials/AddOnPurchase.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {AddOnOffer} from '../../../backend/offer/addon-offer-types';
import {redirectWithHistory} from '../../../utils/url';
import {getAddOnOffersForCurrentUser} from '../../../backend/offer/addon-offer-utils';
import {AccessPassTrialDurations} from '../../../backend/offer/signup-offer-types';
import {signupStep} from '../../../backend/signup/checkout-query';

export default defineComponent({
  components: {
    PageContent,
    AddOnPurchase
  },
  props: {
    serverDate: {type: String, required: true},
    email: {type: String, required: true},
    offerId: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const offer = ref<Readonly<AddOnOffer> | undefined>(undefined);
    const trialDurations = ref<Readonly<AccessPassTrialDurations> | undefined>(undefined);
    const accessPass = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerDestination[0];
    });
    const trialDuration = computed(() => {
      if (accessPass.value === undefined) {
        return undefined;
      }
      if (trialDurations.value === undefined) {
        return undefined;
      }
      return trialDurations.value[accessPass.value.id];
    });

    const goToAccount = () => {
      loader.setLoading(true);
      redirectWithHistory('/account');
    };

    const onComplete = async () => {
      if (offer.value !== undefined) {
        if (offer.value.offerThankYouPage.length > 0) {
          const thankYouPage = offer.value.offerThankYouPage[0];
          if (thankYouPage !== undefined) {
            loader.setLoading(true);
            redirectWithHistory(thankYouPage.url);
            return;
          }
        }
      }
      goToAccount();
    };
    const onCancel = async () => {
      if (offer.value !== undefined) {
        if (offer.value.offerRejectUrl !== null) {
          loader.setLoading(true);
          redirectWithHistory(offer.value.offerRejectUrl);
          return;
        }
      }
      goToAccount();
    };

    onMounted(async () => {
      loader.setLoading(true);
      const allOffers = await getAddOnOffersForCurrentUser(
        false,
        ['account', 'paymentPage'],
        undefined,
        props.serverDate,
        [props.offerId]
      );
      loader.setLoading(false);

      if (allOffers.length !== 1) {
        goToAccount();
      }
      offer.value = allOffers[0];

      const destination = offer.value.offerDestination[0];
      // See if the addon offer overrides the default payment plan trial length.
      if (offer.value.offerTrialDuration !== null) {
        const result: AccessPassTrialDurations = {};
        result[destination.id] = offer.value.offerTrialDuration;
        trialDurations.value = result;
      } else {
        throw new Error('No trial duration');
      }

      /*
        ??? This component could be used for other add-ons; replace the
        hard-coded fields in Active Campaign with a system that can make
        use of access pass information. Better yet, don't let Craft be
        responsible for updating customers' status in AC.
      */
      loader.setLoading(true);
      signupStep('liveClass', props.email, 'payment');
      loader.setLoading(false);
    });

    return {
      offer,
      trialDuration,
      onComplete,
      onCancel
    };
  }
});
</script>
