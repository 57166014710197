import {ref} from 'vue';
import {DateTime, Duration} from 'luxon';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {
  postDateToLocale,
  postDateToLocaleDateAndTime
} from '../../../backend/craft/entry/entry-utils';
import {redirectWithHistory} from '../../../utils/url';

export function useCountdown(
  contentEntry: Readonly<ContentEntry>,
  redirectOnLive: boolean = false
) {
  const display = ref('');
  const time = ref('');
  const date = ref('');

  let timer: number | undefined = undefined;

  const targetUTC = DateTime.fromISO(contentEntry.postDate).valueOf();

  const updateDisplay = () => {
    const nowUTC = DateTime.now().valueOf();
    if (nowUTC > targetUTC) {
      window.clearInterval(timer);
      timer = undefined;
      display.value = 'NOW';
      time.value = '';
      date.value = 'NOW';
      if (redirectOnLive && contentEntry.url !== null) {
        redirectWithHistory(contentEntry.url);
      }
    } else {
      const duration = Duration.fromMillis(targetUTC - nowUTC);

      if (duration.as('hours') > 1) {
        display.value = postDateToLocale(contentEntry.postDate, 'long');
        const d = postDateToLocaleDateAndTime(contentEntry.postDate);
        date.value = d.date;
        time.value = d.time;
      } else {
        display.value = `Live in ${duration.toFormat('mm:ss')}`;
        date.value = `Live in ${duration.toFormat('mm:ss')}`;
        time.value = '';
      }
    }
  };

  updateDisplay();
  timer = window.setInterval(updateDisplay, 500);

  return {
    display,
    date,
    time
  };
}
