<template>
  <modal-dialog :config="config" :heading="heading">
    <div class="flex justify-center">
      <div class="w-full px-fl-3xs-md">
        <video-player
          :guest="''"
          :preview="false"
          :resume-at-last-timestamp="false"
          :video="video"
        />
      </div>
    </div>
  </modal-dialog>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ModalDialogConfig} from '../../vue-composition/modal-dialog/types';
import ModalDialog from '../modal-dialog/ModalDialog.vue';
import VideoPlayer from '../video-player/VideoPlayer.vue';
import {Video} from '../../../backend/video/video-types';

export default defineComponent({
  components: {
    ModalDialog,
    VideoPlayer
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true},
    heading: {type: String, default: undefined},
    video: {type: Object as PropType<Video>, required: true}
  },
  setup() {}
});
</script>
