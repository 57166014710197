import {App, Component} from 'vue';
import {ModalOverlayCallback} from '../../../store/modules/modal-overlay';
import {useStore} from '../../../store/store';
import {createVueApp} from '../../../utils/vue';

// ### Remove this component when the <dialog> HTML element becomes supported in all browsers?
/**
 * Return a Vue composition that implements the mechanism for activating or
 * deactivating an invisible modal overlay for capturing click and touch events
 * that sits on top of the page content. Optionally, also display a specified
 * Vue component on top of the modal overlay.
 *
 * The modal overlay component is normally used to implement modal components,
 * such as dialogs and page sidebars (e.g., navigation burger menu).
 *
 * Example usage - display an overlay with a component called 'Content' on top:
 *
 *   import Content from '...';
 *   export default defineComponent({
 *     ...
 *     setup() {
 *       const props = {...}; // The props to pass to Content when it is mounted.
 *       const overlay = useModalOverlay(Content, props);
 *       ...
 *       // Show the overlay (with 'Content' component on top)
 *       overlay.activate();
 *     }
 *   });
 *
 * NOTE: The actual component that implements the overlay is  defined in
 * /src/components/core/site-wrapper/modal-overlay.
 */
export function useModalOverlay(component?: Readonly<Component>) {
  const store = useStore();

  return {
    activate: (
      props: Readonly<any> | undefined,
      callback: ModalOverlayCallback,
      blur: boolean = false
    ) => {
      /*
        We need to create a new Vue app whenever the overlay is activated.
        The reason is that Vue does not allow us to re-mount an app that
        has already been mounted.
      */
      let app: Readonly<App> | undefined = undefined;
      if (component !== undefined) {
        app = createVueApp(component, props);
      }
      store.dispatch('modalOverlay/activate', {callback, app, blur});
    },
    deactivate: () => {
      store.dispatch('modalOverlay/deactivate');
    }
  };
}
