import {Mutex, MutexInterface} from 'async-mutex';

/**
 * Thread-synchronized cache. Usage:
 *
 *   const cache = new Cache();
 *   ...
 *   const release = await cache.lock();
 *   try {
 *     const value = cache.get(key);
 *     if (value !== undefined) {
 *       ...
 *     } else {
 *       const newValue = ...;
 *       cache.set(key, newValue);
 *     }
 *   } finally {
 *     release();
 *   }
 */
export class ThreadSyncCache<R> {
  private cache: {
    [key: string]: R | undefined;
    [index: number]: R | undefined;
  };

  private mutex: MutexInterface;

  constructor() {
    this.cache = {};
    this.mutex = new Mutex();
  }

  async lock(): Promise<MutexInterface.Releaser> {
    const releaser = await this.mutex.acquire();
    return releaser;
  }

  set(key: string | number, resource: Readonly<R>) {
    this.cache[key] = resource;
  }

  get(key: string | number): R | undefined {
    return this.cache[key];
  }

  clear() {
    this.cache = {};
  }

  clearKey(key: string | number) {
    if (this.cache[key] !== undefined) {
      this.cache[key] = undefined;
    }
  }

  getKeys(): ReadonlyArray<string> {
    return Object.keys(this.cache);
  }
}
