<template>
  <div v-if="!guest && !contentEntry.preview">
    <icon-label-button
      v-if="display === 'iconLabel'"
      :icon="icon"
      :label="'Bookmark'"
      :on-click="onClick"
      :tooltip="'Add to my bookmarks'"
      :color="color"
    />
    <link-icon-button
      v-else-if="display === 'linkIcon'"
      :icon="icon"
      :size="'sm'"
      :label="'Bookmark'"
      :on-click="onClick"
      :tooltip="'Add to my bookmarks'"
      :color="color"
    />
    <standard-button
      v-else-if="display === 'standard'"
      :size="'sm'"
      :label="'Bookmark'"
      :on-click="onClick"
      :tooltip="'Add to my bookmarks'"
      :color="color"
    />
    <icon-button v-else :icon="icon" :on-click="onClick" :tooltip="'Add to my bookmarks'" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import IconLabelButton from '../../../../core/button/IconLabelButton.vue';
import LinkIconButton from '../../../../core/button/LinkIconButton.vue';
import IconButton from '../../../../core/button/IconButton.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {faBookmark} from '@fortawesome/pro-light-svg-icons/faBookmark';
import {faBookmark as faSolidBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {BookmarkComposition} from '../../../../vue-composition/bookmark/types';
import {ButtonColor} from '../../../../core/button/implementation/utils';

export type ContentEntryHeaderBookmarkType = 'linkIcon' | 'icon' | 'iconLabel' | 'standard';

export default defineComponent({
  components: {
    IconLabelButton,
    LinkIconButton,
    IconButton,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    display: {type: String as PropType<ContentEntryHeaderBookmarkType>, default: 'iconLabel'},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    color: {type: String as PropType<ButtonColor>, default: undefined}
  },
  setup(props) {
    const onClick = () => {
      props.bookmark.showAddBookmarkDialog(props.contentEntry);
    };

    const isBookmarked = computed(() => {
      return props.bookmark.bookmarkedEntries.value.includes(props.contentEntry.id);
    });

    const icon = computed(() => {
      return isBookmarked.value ? faSolidBookmark : faBookmark;
    });

    return {
      icon,
      onClick
    };
  }
});
</script>
