<template>
  <margins>
    <content-area :area="area">
      <a :href="url" class="group">
        <cluster>
          <cluster :wrap="false" class="w-full">
            <font-awesome-icon
              :icon="titleIcon"
              :class="`${iconSize} ${titleIconColor}`"
            ></font-awesome-icon>
            <div :class="`${textSize} ${titleColor} font-semibold`">
              {{ contentEntry.title }}
            </div>
          </cluster>
        </cluster>
      </a>
    </content-area>
    <column-grid :area="buttonArea" class="flex flex-col justify-center">
      <slot name="buttons">
        <content-area class="flex justify-end items-center">
          <content-entry-header-bookmark
            v-if="displayConfig.bookmark"
            :guest="guest"
            :bookmark="bookmark"
            :content-entry="contentEntry"
            :display="buttonAsIcon ? 'icon' : 'linkIcon'"
          />
        </content-area>
      </slot>
    </column-grid>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {tutorList} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import ColumnGrid from '../../../core/column-layout/ColumnGrid.vue';
import ContentEntryHeaderBookmark from '../../partials/header/actions/ContentEntryHeaderBookmark.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {DEFAULT_DISPLAY_CONFIG_ITEM, DisplayConfig} from '../../collections/types';
import {ColorConfig} from '../../../core/color-config';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {
  split,
  StandardPageAreaJustify,
  StandardPageAreaWidth,
  useStandardPageArea
} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    Margins,
    ContentArea,
    ColumnGrid,
    FontAwesomeIcon,
    Cluster,
    ContentEntryHeaderBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    config: {
      type: Object as PropType<Readonly<DisplayConfig> | undefined>,
      default: undefined
    },
    duration: {type: Number, default: undefined}, // Seconds
    completed: {type: Boolean, default: undefined},
    progress: {type: Number, default: undefined}, // [0,100]
    unlocked: {type: Boolean, default: undefined},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    // ### TODO: Make use of the justify setting.
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    iconSize: {type: String, default: 'text-fl-3xl'},
    textSize: {type: String, default: 'text-fl-3xl'}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const spa = useStandardPageArea(deviceType, props.width);
    const area = computed(() => {
      return split(spa.area.value, '2/3')[0];
    });
    const buttonArea = computed(() => {
      return split(spa.area.value, '2/3')[1];
    });
    const buttonAsIcon = computed(() => deviceType.screen.value === 'narrow');

    const completedStatus = computed(() => {
      if (props.completed !== undefined) {
        return props.completed;
      }
      if (props.progress !== undefined) {
        return props.progress === 100;
      }
      return undefined;
    });

    const tutors = computed(() => {
      const tutors = tutorList(props.contentEntry);
      return tutors;
    });

    const url = computed(() => {
      return props.contentEntry.url;
    });

    const displayConfig = computed(() => {
      return props.config !== undefined ? props.config : DEFAULT_DISPLAY_CONFIG_ITEM;
    });

    const titleIcon = computed(() => faCheck);
    const titleIconColor = computed(() => {
      if (completedStatus.value !== undefined && completedStatus.value === true) {
        if (props.spotColor !== undefined) {
          return props.spotColor.text;
        }
        return 'text-progress';
      }
      return 'text-dark';
    });
    const titleColor = computed(() => {
      if (completedStatus.value !== undefined && completedStatus.value === true) {
        if (props.spotColor !== undefined) {
          return `${props.spotColor.text}`;
        }
        return 'text-progress';
      }
      return 'text-lightest group-hover:text-white';
    });

    return {
      deviceType,
      area,
      buttonArea,
      buttonAsIcon,
      titleIcon,
      titleIconColor,
      titleColor,
      url,
      tutors,
      completedStatus,
      displayConfig
    };
  }
});
</script>
