<template>
  <page-content>
    <!-- Curated topics ("what do you want to learn today") -->
    <dashboard-curation v-if="curation.length > 0" :curation="curation" />

    <!-- Promotions -->
    <promo-box :location="'dashboard'" :promotions="promotions" :margin-top="'lg'" />

    <!-- Recently released entries -->
    <dashboard-reel
      :margin-top="'xl'"
      :guest="guest"
      :heading="'Recently Released'"
      :content-entries="recentlyReleased"
      :bookmark="bookmark"
    />

    <!-- Section promotions -->
    <dashboard-section-promos :guest="guest" :cards="sectionPromoCards" />

    <!-- Popular courses -->
    <dashboard-reel
      v-if="popularCourses.length > 0"
      :margin-top="'xl'"
      :guest="guest"
      :heading="'Popular Courses'"
      :content-entries="popularCourses"
      :bookmark="bookmark"
    />

    <!-- Mentor seminars -->
    <dashboard-mentor-seminars
      :guest="guest"
      :bookmark="bookmark"
      :most-recent-mentor-seminar="mostRecentMentorSeminar"
      :upcoming-mentor-seminars="upcomingMentorSeminars"
      :margin-top="'xl'"
    />

    <!-- Student focus -->
    <dashboard-student-focus
      :guest="guest"
      :bookmark="bookmark"
      :most-recent-student-focus="mostRecentStudentFocus"
    />

    <!-- Tutors -->
    <dashboard-tutors :guest="guest" :tutors="tutors" />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageContent from '../../../core/page/PageContent.vue';
import PromoBox from '../../../promotions/components/promo-box/PromoBox.vue';
import DashboardSectionPromos from '../partials/DashboardSectionPromos.vue';
import DashboardReel from '../partials/DashboardReel.vue';
import DashboardMentorSeminars from '../partials/DashboardMentorSeminars.vue';
import DashboardStudentFocus from '../partials/DashboardStudentFocus.vue';
import DashboardCuration from '../partials/DashboardCuration.vue';
import DashboardTutors from '../partials/DashboardTutors.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {Tutor} from '../../../../backend/tutor/tutor-types';
import {Curation} from '../../../../backend/curation/curation-types';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {SectionPromoConfig} from '../partials/types';
import {PromotionComposition} from '../../../vue-composition/promotion/promotion';

export default defineComponent({
  components: {
    PageContent,
    PromoBox,
    DashboardReel,
    DashboardSectionPromos,
    DashboardMentorSeminars,
    DashboardStudentFocus,
    DashboardCuration,
    DashboardTutors
  },
  props: {
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true},
    activeWebcasts: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    playersPathContinueLevel: {
      type: Object as PropType<Readonly<PlayersPathLevel>>,
      default: undefined
    },
    recentlyReleased: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    popularCourses: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    mostRecentMentorSeminar: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    upcomingMentorSeminars: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    mostRecentStudentFocus: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    curation: {type: Array as PropType<ReadonlyArray<Curation>>, required: true},
    sectionPromoCards: {type: Array as PropType<ReadonlyArray<SectionPromoConfig>>, required: true},
    tutors: {type: Array as PropType<ReadonlyArray<Tutor>>, required: true},
    promotions: {type: Object as PropType<Readonly<PromotionComposition>>, required: true}
  },
  setup() {}
});
</script>
