<template>
  <page-content>
    <page-section :margin-top="'md'">
      <content-entry-section-header
        :width="'wide'"
        :center="false"
        :title="'All Tutors'"
        :title-width="'wide'"
      />
    </page-section>

    <page-section :margin-top="'md'">
      <page-section-header :width="'wide'" :heading="'Mentors'"> </page-section-header>
    </page-section>
    <page-section :margin-top="'xs'">
      <content-column :width="'wide'">
        <repel>
          <p class="text-fl-3xl text-mid">
            Interact live with our world-class Mentors from the comfort of your own home.
          </p>
          <cluster>
            <loading-icon />
            <link-button :size="'xl'" :url="'/mentors'" :border="true" label="To Mentors" />
          </cluster>
        </repel>
      </content-column>
    </page-section>
    <page-section :margin-top="'md'">
      <tutor-grid :tutors="featuredMentors" />
    </page-section>

    <page-section :margin-top="'xl'">
      <page-section-header :width="'wide'" :heading="'Other Contributors'">
        <loading-icon />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'md'">
      <tutor-grid :tutors="otherTutors" />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import PageSectionHeader from '../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import TutorGrid from '../mentor-seminars/partials/TutorGrid.vue';
import Repel from '../../core/compositions/Repel.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import ContentEntrySectionHeader from '../../content-entry/partials/section/ContentEntrySectionHeader.vue';
import {getAllTutorsPaginated} from '../../../backend/tutor/tutor-query';
import LinkButton from '../../core/button/LinkButton.vue';
import LoadingIcon from '../../generic/loader/LoadingIcon.vue';

export default {
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentColumn,
    ContentEntrySectionHeader,
    TutorGrid,
    Repel,
    Cluster,
    LinkButton,
    LoadingIcon
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {
    const paginatedFetch = getAllTutorsPaginated();
    const featuredMentors = computed(() => {
      return paginatedFetch.entries.value.filter(t => t.tutorFeaturedMentor);
    });
    const otherTutors = computed(() => {
      return paginatedFetch.entries.value.filter(t => !t.tutorFeaturedMentor);
    });
    return {
      featuredMentors,
      otherTutors
    };
  }
};
</script>
