<template>
  <!-- ??? We use the CSS aspect-ratio property here, which became available in 2021. Is that OK? -->
  <div :id="videoId" class="absolute top-0 left-0 w-full aspect-video border-none"></div>
</template>

<script lang="ts">
import {YouTubePlayer} from './youtube-api';
import {
  makePauseCallback,
  makeGetPlayPosCallback,
  makeGetVideoDurationCallback,
  makeCueVideoCallback
} from '../youtube/callbacks';

/**
 * This components wraps the YouTube Player API in a Vue component.
 * To interact with it, you need to use three "public methods":
 * loadVideo(), play(), and pause().
 *
 * To call these functions, use Vue component refs. For details, see
 * https://stackoverflow.com/questions/40957008/how-to-access-to-a-child-method-from-the-parent-in-vue-js
 *
 */
export default {
  props: {
    videoId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const config = {
      player: undefined as YouTubePlayer | undefined,
      timer: undefined as any
    };

    const pause = makePauseCallback(config);
    const getPlayPos = makeGetPlayPosCallback(config);
    const getVideoDuration = makeGetVideoDurationCallback(config);
    const cueVideo = makeCueVideoCallback(config, props.videoId);

    return {
      /*
        These functions are "public" - they can (and should) be called by other components.
       */
      pause,
      getPlayPos,
      getVideoDuration,
      cueVideo
    };
  }
};
</script>

<style scoped></style>
