<template>
  <pill v-if="progress === 100" :class="`${borderColor} ${bgColor} text-black`"> COMPLETE </pill>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Pill from '../../../../generic/pill/Pill.vue';
import {ColorConfig} from '../../../../core/color-config';

export default defineComponent({
  components: {
    Pill
  },
  props: {
    progress: {type: Number, required: true}, // [0,100]
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined}
  },
  setup(props) {
    const bgColor = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.bg;
      }
      return 'bg-progress';
    });
    const borderColor = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.border;
      }
      return 'border-progress';
    });
    return {
      bgColor,
      borderColor
    };
  }
});
</script>
