import {Component, createApp} from 'vue';
import {addStoreToApp} from '../store/store';

/**
 * Create a Vue app (= create a Vue component programmatically) and add the
 * SBL Vuex store to it.
 *
 * This function should be used whenever you need to create a Vue
 * component as a result of interactivity, and you want that component
 * to have access to the SBL Vuex store. Examples of use cases include
 * the SBL site sidebar and the hidden modal overlay (that captures
 * mouse clicks, e.g., when dialogs are displayed).
 *
 * NOTE: In Vue 3, `createApp()` is the officially recommended way to
 * create new components dynamically. While straightforward, this has
 * the obvious drawback that dynamically created components do not
 * automatically share any data with the "main" app that constructed them;
 * any such data must be passed to the new app manually. Hence the need
 * for this `createVueApp()` function.
 *
 * See
 * https://v3-migration.vuejs.org/breaking-changes/global-api.html#vue-extend-removed
 */
export function createVueApp(component: Readonly<Component>, props?: Readonly<any>) {
  const app = createApp(component, props);
  addStoreToApp(app);
  return app;
}
