<template>
  <page-content v-if="contentEntry !== undefined && pending">
    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-header-breadcrumb-trail
          v-if="trails !== undefined"
          :path="trails.navigationPath"
        />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header>
          {{ contentEntry.title }}
        </content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="contentEntry" :size="'md'" />
          <content-entry-header-post-date :content-entry="contentEntry" />
          <content-entry-header-levels-and-subjects :content-entry="contentEntry" />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="contentEntry"
            :bookmark="bookmark"
            :display="'linkIcon'"
          />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <div class="py-fl-xl bg-darkest">
        <content-column>
          <div class="flex justify-center">
            <countdown
              :content-entry="contentEntry"
              :redirect-on-live="true"
              class="text-fl-7xl text-white font-semibold"
            />
          </div>
        </content-column>
      </div>
    </page-section>

    <content-entry-summary :content-entry="contentEntry" />

    <content-entry-resource-list :guest="guest" :content-entry="contentEntry" />

    <content-entry-recommendations
      :guest="guest"
      :content-entry="contentEntry"
      :bookmark="bookmark"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderLevelsAndSubjects from '../../content-entry/partials/header/metadata/ContentEntryHeaderLevelsAndSubjects.vue';
import ContentEntryHeaderPostDate from '../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryResourceList from '../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntrySummary from '../../content-entry/partials/body/ContentEntrySummary.vue';
import Countdown from '../../generic/countdown/Countdown.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Cluster,
    ContentEntryHeader,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeaderLevelsAndSubjects,
    ContentEntryHeaderTutors,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderBookmark,
    ContentEntryResourceList,
    ContentEntryRecommendations,
    ContentEntrySummary,
    Countdown
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const contentEntry = ref(undefined as Readonly<ContentEntry> | undefined);
    const pending = ref(true);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);
      const result = await fetchContentEntries(
        contentEntries().slug([props.slug]).status(['pending'])
      );
      contentEntry.value = result[0];
      if (contentEntry.value !== undefined) {
        trails.value = await useBreadcrumbTrails(contentEntry.value, props.entryBreadcrumbTrails);
        await bookmark.init();
      }
      loader.setLoading(false);
    });
    return {
      contentEntry,
      pending,
      trails,
      bookmark
    };
  }
});
</script>
