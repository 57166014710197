<template>
  <page-content>
    <page-section>
      <content-column>
        <standard-heading :size="1">Browser Compatibility</standard-heading>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <p>
            SBL is a web-delivered service and in order to access it effectively, you will need to
            possess a suitable device with a modern browser installed and a fast Internet
            connection. We cannot support every version of every browser and therefore we encourage
            you to use your trial and money-back guarantee periods to help you evaluate SBL to your
            satisfaction.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <p>
            For further details on our terms of service, please visit
            <a href="/terms-of-service" class="text-lightest hover:text-white underline"
              >this page</a
            >.
          </p>
        </flow>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import PageContent from '../../core/page/PageContent.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    PageSection,
    StandardHeading,
    Flow,
    PageContent,
    ContentColumn
  },
  props: {},
  setup() {
    return {};
  }
});
</script>
