<template>
  <variants v-if="!preview && !guest && variationConfig.loaded">
    <template #narrow>
      <song-player-narrow
        :disabled="disabled"
        :player="player"
        :song="song"
        :variation-config="variationConfig"
      />
    </template>
    <template #sm>
      <song-player-small
        :disabled="disabled"
        :player="player"
        :song="song"
        :variation-config="variationConfig"
      />
    </template>
    <template #md>
      <song-player-medium
        :disabled="disabled"
        :player="player"
        :song="song"
        :variation-config="variationConfig"
      />
    </template>
    <template #lg>
      <song-player-small
        :disabled="disabled"
        :player="player"
        :song="song"
        :variation-config="variationConfig"
      />
    </template>
    <template #xl+>
      <song-player-extra-large
        :disabled="disabled"
        :player="player"
        :song="song"
        :variation-config="variationConfig"
      />
    </template>
  </variants>
</template>

<script lang="ts">
import {onMounted, PropType, computed} from 'vue';
import Variants from '../../core/column-layout/Variants.vue';
import SongPlayerNarrow from './variations/SongPlayerNarrow.vue';
import SongPlayerSmall from './variations/SongPlayerSmall.vue';
import SongPlayerMedium from './variations/SongPlayerMedium.vue';
import SongPlayerExtraLarge from './variations/SongPlayerExtraLarge.vue';
import {Song} from '../../../backend/song/song-types';
import {useLoader} from '../../vue-composition/loader/loader';
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock';
import {getVariationConfig} from './internals/utils';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {LoopPlayer} from '../../../utils/loop-player/loop-player';

export type PlayCallback = () => void;

export default {
  components: {
    Variants,
    SongPlayerNarrow,
    SongPlayerSmall,
    SongPlayerMedium,
    SongPlayerExtraLarge
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    song: {type: Object as PropType<Song>, required: true},
    onPlay: {type: Function as PropType<PlayCallback>, default: undefined}
  },
  setup(props) {
    const loader = useLoader();
    const deviceType = useDeviceType();
    const iconLock = computed(() => faLock);

    const player = new LoopPlayer(() => {
      if (props.onPlay !== undefined) {
        props.onPlay();
      }
    });

    const disabled = computed(() => {
      const disabled =
        player.mode.value === 'playing' ||
        player.mode.value === 'pre-count' ||
        player.mode.value === 'error' ||
        loader.loading.value;
      return disabled;
    });

    const variationConfig = getVariationConfig(props.song, player);
    onMounted(async () => {
      loader.setLoading(true);
      await variationConfig.load();
      loader.setLoading(false);
    });

    return {
      iconLock,
      variationConfig,
      disabled,
      player,
      deviceType
    };
  }
};
</script>
