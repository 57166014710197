<template>
  <div v-if="!disabled">
    <carousel
      :width="width"
      :number-of-slides="contentEntries.length"
      :card-size="cardSize"
      :justify="justify"
      :disabled="disabled"
    >
      <template v-for="(ce, i) in contentEntries" :key="ce.id" #[`${i}`]>
        <content-entry-card
          :guest="guest"
          :content-entry="ce"
          :completed="completed[ce.id]"
          :progress="progress[ce.id]"
          :duration="duration[ce.id]"
          :unlocked="unlocked[ce.id]"
          :spot-color="spotColor"
          :bookmark="bookmark"
          :card-image-width="imageWidth"
          :imgix-params="imgixParams"
        />
      </template>
    </carousel>
  </div>
  <div v-else>
    <carousel
      :width="width"
      :number-of-slides="disabledItems.length"
      :card-size="cardSize"
      :justify="justify"
      :disabled="disabled"
    >
      <template v-for="(ce, i) in disabledItems" :key="ce.id" #[`${i}`]>
        <content-entry-card
          :guest="guest"
          :bookmark="bookmark"
          :card-image-width="imageWidth"
          :imgix-params="imgixParams"
        />
      </template>
    </carousel>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, DurationMap, UnlockedMap} from '../types';
import ContentEntryCard from '../../representations/card/ContentEntryCard.vue';
import Carousel from '../../../generic/carousel/Carousel.vue';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {CarouselCardSize, getCarouselImageWidths} from '../../../generic/carousel/carousel';

export default defineComponent({
  components: {
    Carousel,
    ContentEntryCard
  },
  props: {
    guest: {type: String, required: true},
    cardSize: {type: String as PropType<CarouselCardSize>, default: 'sm'},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    imgixParams: {type: Object, default: () => ({ar: '16:9', fit: 'crop'})}
  },
  setup(props) {
    const disabledItems = computed(() => {
      return [...Array(10).keys()].map(i => {
        return {id: i};
      });
    });
    const disabled = computed(() => props.contentEntries.length === 0);
    const imageWidth = getCarouselImageWidths(props.cardSize);
    return {
      disabledItems,
      disabled,
      imageWidth
    };
  }
});
</script>
