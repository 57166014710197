<template>
  <div :id="accessPass.slug" class="py-fl-lg-2xl flex flex-col gap-fl-lg">
    <standard-heading :size="2">{{ accessPass.title }}</standard-heading>
    <div class="flex flex-col gap-fl-xs">
      <p v-if="text.status" :class="statusClass">{{ text.status }}</p>
      <p v-if="text.discount" class="text-fl-2xl text-light">{{ text.discount }}</p>
      <p v-if="text.instructions" class="text-fl-2xl text-light">{{ text.instructions }}</p>
      <p v-if="text.paymentMethod" class="text-fl-2xl text-light">{{ text.paymentMethod }}</p>
      <p v-if="text.billing" class="text-fl-2xl text-light">
        {{ text.billing }}
      </p>
    </div>
    <access-pass-actions :access-pass="accessPass" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import AccessPassActions from './AccessPassActions.vue';
import {getAccessPassStatusText} from '../../utils';
import {AccessPassStatus} from '../../../../../../backend/access-pass/access-pass-types';

export default defineComponent({
  components: {
    StandardHeading,
    AccessPassActions
  },
  props: {
    accessPass: {type: Object as PropType<Readonly<AccessPassStatus>>, required: true}
  },
  setup(props) {
    const text = computed(() => getAccessPassStatusText(props.accessPass));
    const statusClass = computed(() =>
      props.accessPass.status === 'pastDue'
        ? 'text-fl-2xl font-semibold text-primary'
        : 'text-fl-2xl font-semibold  text-light'
    );
    return {
      text,
      statusClass
    };
  }
});
</script>
