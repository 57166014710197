<template>
  <page-section :margin-top="'sm'">
    <content-column :width="width" :justify="justify">
      <video-player
        :video="video"
        :preview="false"
        :guest="''"
        :resume-at-last-timestamp="false"
        class="max-w-txt"
      />
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {RichTextBlock, RichTextVideo} from '../../../../backend/rich-text/rich-text-types';
import VideoPlayer from '../../video-player/VideoPlayer.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {Video} from '../../../../backend/video/video-types';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    VideoPlayer
  },
  props: {
    richTextVideo: {type: Object as PropType<RichTextBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const video = computed((): Video => {
      const rtv = props.richTextVideo as RichTextVideo;
      return {
        videoServiceProvider: rtv.richTextVideoServiceProvider,
        videoId: rtv.richTextVideoId,
        videoType: 'content',
        videoDuration: ''
      };
    });
    return {video};
  }
});
</script>
