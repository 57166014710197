import {computed} from 'vue';
import {useStore} from '../../../store/store';

/**
 * Return a Vue composition that implements the mechanism for
 * showing or hiding loading spinners. The composition contains a
 * status flag that can be used to hide or show spinner components.
 * It also contains a function, 'setLoading', that set the loading
 * status.
 *
 * To use this in your own components, do something similar to
 *
 * setup() {
 *   const loader = useLoader();
 *   onMounted(() => {
 *     loader.setLoading(true);
 *     const result = await ...;
 *     loader.setLoading(false);
 *   });
 * }
 *
 * It is perfectly fine to update the loading status from different
 * components on the same page, as long as each call to enable loading
 * also has a corresponding disable call. (The composition functionality
 * keeps track of each on/off status change and sets the combined status
 * to false only when the final off status change has been received.)
 */
export function useLoader() {
  const store = useStore();
  return {
    setLoading: (status: boolean) => {
      store.dispatch('loader/setLoading', status);
    },
    loading: computed(() => {
      return store.getters['loader/loading'];
    })
  };
}

/**
 * Like useLoader(), but also displays a full screen (blocking)
 * spinner as long as the loading status is true.
 *
 * NOTE: The actual component that implements the full-screen
 * spinner screen is defined in components/generic/loader/FullScreenLoader.vue.
 * It must be included at the top of the page for the loader to become
 * available. If you use the standard page header you won't have to do
 * this yourself, since that header includes the component by default.
 *
 */
export function useFullScreenLoader() {
  const store = useStore();
  return {
    setLoading: (status: boolean, message?: string) => {
      store.dispatch('fullScreenLoader/setLoading', {status, message});
    },
    loading: computed(() => {
      return store.getters['fullScreenLoader/loading'];
    })
  };
}
