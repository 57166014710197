<template>
  <div class="flex justify-center">
    <div :id="'recaptcha'" />
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, onMounted} from 'vue';

/**
 * This component presents a reCAPTCHA v2 or v3 challenge, depending
 * on a setting in the Craft control panel. If the challenge is successful
 * (which it always is for v3), a reCAPTCHA token is returned via the
 * `onSuccess` callback. For v2, the `onExpire` callback is called when/if
 * the challenge times out.
 *
 * The `action` property is passed to reCAPTCHA v3; it is used to in the
 * reCAPTCHA admin control panel to differentiate between different user
 * actions. Pass a value that describes the context in which the reCAPTCHA
 * challenge is performed, e.g., 'payment_offer'.
 *
 * NOTE: When the reCAPTCHA challenge expires, previous tokens received
 * via the `onSuccess` callback are no longer valid.
 */
export default defineComponent({
  components: {},
  props: {
    /** Action string to pass to reCAPTCHA v3. */
    action: {type: String, required: true},
    /** Called when the reCAPTCHA challenge is successful. */
    onSuccess: {type: Function as PropType<(token: any) => void>, required: true},
    /** Called when the reCAPTCHA challenge expires. */
    onExpire: {type: Function as PropType<() => void>, required: true}
  },
  setup(props) {
    onMounted(async () => {
      if (window.sbl === undefined) {
        throw new Error('No settings');
      }
      if (
        window.sbl.RECAPTCHA_SITE_KEY === undefined ||
        window.sbl.RECAPTCHA_SITE_KEY.length === 0
      ) {
        throw new Error('Undefined reCAPTCHA site key');
      }
      if (!window.sbl.RECAPTCHA_LOADED || !window.grecaptcha) {
        throw new Error('reCAPTCHA script unavailable');
      }

      switch (window.sbl.RECAPTCHA_VERSION) {
        case 'recaptchaV2':
          window.grecaptcha.render('recaptcha', {
            sitekey: window.sbl.RECAPTCHA_SITE_KEY,
            callback: async (token: any) => {
              props.onSuccess(token);
            },
            'expired-callback': () => {
              props.onExpire();
            }
          });
          return;
        default:
          const token = await window.grecaptcha.execute(window.sbl.RECAPTCHA_SITE_KEY, {
            action: props.action
          });
          props.onSuccess(token);
          return;
      }
    });
  }
});
</script>
