import {Course} from '../../../../backend/content/course/course-types';
import {Lesson} from '../../../../backend/content/lesson/lesson-types';
import {getLessonLengthSec} from '../../../../backend/content/lesson/lesson-utils';
import {DurationMap} from '../../../content-entry/collections/types';

export function lessonDurations(course: Readonly<Course>): Readonly<DurationMap> {
  const result: DurationMap = {};
  course.childEntries.forEach(lesson => {
    result[lesson.id] = getLessonLengthSec(lesson);
  });
  return result;
}

export type CourseModule = {
  title: string | undefined;
  lessons: Array<Lesson>;
};
export type CourseModules = Array<CourseModule>;

/**
 * Return the lesson modules that make up the specified course (in the
 * appropriate order). If the course has no lesson modules, return a single
 * module with undefined title.
 */
export function getModules(course: Readonly<Course>): Readonly<CourseModules> {
  const modules: {[module: string]: Array<Lesson>} = {};
  const generic: Array<Lesson> = [];
  const moduleNames: Array<string> = [];

  course.childEntries.forEach(child => {
    const lesson = child as Lesson;
    const module = lesson.lessonModule;
    if (module !== null && module !== undefined) {
      if (moduleNames.indexOf(module) === -1) {
        moduleNames.push(module);
      }
      if (modules[module] === undefined) {
        modules[module] = [];
      }
      modules[module].push(lesson);
    } else {
      generic.push(lesson);
    }
  });

  // If the course has modules, return them.
  if (moduleNames.length > 0) {
    return moduleNames.map(module => {
      return {
        title: module,
        lessons: modules[module]
      };
    });
  }

  // If not, return a single module (with undefined title) that contains all lessons.
  return [
    {
      title: undefined,
      lessons: generic
    }
  ];
}
