<template>
  <div :class="`w-[20px] h-[20px] flex flex-col justify-center items-center rounded-[100%]`">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  setup() {}
});
</script>
