import {fetchContentEntries} from '../content-entry/content-entry-query';
import {PlayersPathLevel, PlayersPathPiece} from './players-path-types';
import {QUIZ_FIELDS} from '../quiz/quiz-query';
import {SONG_FIELDS} from '../../song/song-query';
import {getPlayersPathLevelsUnlockedAndComplete} from './players-path-query-utils';
import * as ContentEntryQueryBuilder from '../content-entry/content-entry-query-builder';
import * as QueryBuilder from '../../craft/query/craft-query-builder';

const PLAYERS_PATH_PIECE_FIELDS = [
  QueryBuilder.url('playersPathPieceBackingTrackUrl').required(),
  ContentEntryQueryBuilder.contentEntries('quizzes', []).fields(QUIZ_FIELDS),
  QueryBuilder.entries('songs').fields(SONG_FIELDS)
];

const PLAYERS_PATH_LEVEL_FIELDS = [
  ContentEntryQueryBuilder.contentEntries('quizzes', []).fields(QUIZ_FIELDS),
  ContentEntryQueryBuilder.contentEntries('childEntries', []).fields(PLAYERS_PATH_PIECE_FIELDS)
];

export async function getPlayersPathLevelBySlug(
  slug: string
): Promise<Readonly<PlayersPathLevel | undefined>> {
  try {
    const contentEntries = await fetchContentEntries<PlayersPathLevel>(
      ContentEntryQueryBuilder.contentEntries()
        .section(['playersPathLevels'])
        .slug([slug])
        .fields(PLAYERS_PATH_LEVEL_FIELDS)
    );
    const level = contentEntries[0];
    if (level !== undefined) {
      return {
        ...level,
        playersPathPieces: level.childEntries as Array<PlayersPathPiece>
      };
    }
    return undefined;
  } catch (error) {
    throw new Error(`Could not fetch players path level: ${error}`);
  }
}

export async function getNextPlayersPathLevel(
  level: Readonly<PlayersPathLevel>
): Promise<Readonly<PlayersPathLevel> | undefined> {
  const levelNumber = parseInt(level.slug.substring(19));
  if (levelNumber === 9) {
    return undefined;
  }
  const nextLevelSlug = `${level.slug.substring(0, 19)}${levelNumber + 1}`;
  const result = await fetchContentEntries(
    ContentEntryQueryBuilder.contentEntries()
      .section(['playersPathLevels'])
      .slug([nextLevelSlug])
      .fields(PLAYERS_PATH_LEVEL_FIELDS)
  );
  return result[0] as PlayersPathLevel | undefined;
}

export async function getPlayersPathPieceBySlug(
  slug: string
): Promise<Readonly<PlayersPathPiece | undefined>> {
  try {
    const result = await fetchContentEntries<PlayersPathPiece>(
      ContentEntryQueryBuilder.contentEntries()
        .section(['playersPathPieces'])
        .slug([slug])
        .fields(PLAYERS_PATH_PIECE_FIELDS)
    );
    return result[0];
  } catch (error) {
    throw new Error(`Could not fetch players path piece: ${error}`);
  }
}

export async function getAllPlayersPathLevels(
  guest: string
): Promise<ReadonlyArray<PlayersPathLevel>> {
  try {
    const quizField = ContentEntryQueryBuilder.contentEntries('quizzes', []);
    // Fetch the players path levels that make up Players Path.
    const playersPathLevels = await fetchContentEntries<PlayersPathLevel>(
      ContentEntryQueryBuilder.contentEntries(undefined, ['summary'])
        .section(['playersPathLevels'])
        .fields([quizField])
    );

    // If the current user is a guest, return here.
    if (guest) {
      return playersPathLevels;
    }

    // Fetch unlocked/completed statuses for all levels.
    const status = await getPlayersPathLevelsUnlockedAndComplete(playersPathLevels);

    return playersPathLevels.map((level, index) => {
      return {
        ...level,
        unlocked: status[index].unlocked,
        completed: status[index].completed,
        numberOfCompletedPieces: status[index].numberOfCompletedPieces
      };
    });
  } catch (error) {
    throw new Error(`Could not fetch players path levels: ${error}`);
  }
}
