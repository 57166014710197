<template>
  <div v-if="contentEntry.summary && contentEntry.summary.length > 0">
    <page-section :margin-top="marginTop">
      <page-section-header :width="width" :justify="justify" :heading="heading" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column :width="width" :justify="justify">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="text-fl-xl" v-html="contentEntry.summary"></div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentColumn
  },
  props: {
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    marginTop: {type: String as PropType<PageSectionMargin>, default: undefined},
    heading: {type: String, default: 'Summary'}
  },
  setup() {}
});
</script>
