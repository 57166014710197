<template>
  <page-content v-if="tutors.length > 0">
    <content-entry-section-summary
      :landing-page="landingPage"
      :title-image-max-width="'max-w-[360px]'"
      :title-image-max-width-summary="'max-w-[536px]'"
    />
    <!-- Active webcast(s), next seminar, upcoming seminars -->
    <page-section v-if="activeWebcasts.length > 0" :margin-top="'md'">
      <active-webcasts-component
        :key="key"
        :guest="guest"
        :content-entries="activeWebcasts"
        :bookmark="bookmark"
      />
    </page-section>
    <page-section v-else-if="next !== undefined" :margin-top="'md'">
      <next-seminar :key="key" :guest="guest" :content-entry="next" :bookmark="bookmark" />
    </page-section>
    <page-section v-if="upcoming.length > 0" :margin-top="'xl'">
      <page-section-header :heading="'Upcoming Seminars'" :width="'wide'" />
      <div class="pt-fl-xl">
        <upcoming-seminars
          :key="key"
          :guest="guest"
          :content-entries="upcoming"
          :bookmark="bookmark"
        />
      </div>
    </page-section>

    <!-- Seminars that have happened in the past. -->
    <page-section :margin-top="'xl'">
      <page-section-header :heading="'Recent Seminars'" :width="'wide'">
        <link-button :url="'/mentors/seminars'" :label="'View All'" :color="'light'" />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'sm'">
      <recent-seminars :key="key" :guest="guest" :content-entries="past" :bookmark="bookmark" />
    </page-section>

    <page-section :margin-top="'xl'">
      <page-section-header :heading="'Your Mentors'" :width="'wide'">
        <link-button :url="'/tutors'" :label="'All SBL Contributors'" />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'sm'">
      <tutor-grid :tutors="tutors" />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {onMounted, PropType, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import PageSectionHeader from '../../core/page/PageSectionHeader.vue';
import ContentEntrySectionSummary from '../../content-entry/partials/section/ContentEntrySectionSummary.vue';
import TutorGrid from './partials/TutorGrid.vue';
import ActiveWebcastsComponent from './partials/ActiveWebcasts.vue';
import NextSeminar from './partials/NextSeminar.vue';
import UpcomingSeminars from './partials/UpcomingSeminars.vue';
import RecentSeminars from './partials/RecentSeminars.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {getAllFeaturedMentors} from '../../../backend/tutor/tutor-query';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {Tutor} from '../../../backend/tutor/tutor-types';
import {classifySeminars} from './utils';
import {fetchContentEntriesUntil} from '../../../backend/content/content-entry/content-entry-query-utils';
import {entryIsPast} from '../../../backend/craft/entry/entry-utils';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

function equal(a: ReadonlyArray<ContentEntry>, b: ReadonlyArray<ContentEntry>): boolean {
  const ja = JSON.stringify(a.map(a => a.id));
  const jb = JSON.stringify(b.map(b => b.id));
  return ja == jb;
}

export default {
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentEntrySectionSummary,
    LinkButton,
    TutorGrid,
    ActiveWebcastsComponent,
    NextSeminar,
    UpcomingSeminars,
    RecentSeminars
  },
  props: {
    guest: {type: String, required: true},
    landingPageSlug: {type: String as PropType<CraftSlug>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const key = ref(0);
    const upcoming = ref<ReadonlyArray<ContentEntry>>([]);
    const next = ref<Readonly<ContentEntry> | undefined>(undefined);
    const past = ref<ReadonlyArray<ContentEntry>>([]);
    const activeWebcasts = ref<ReadonlyArray<ContentEntry>>([]);
    const tutors = ref<ReadonlyArray<Tutor>>([]);

    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);

      /*
        ### Limit the number of results to shorten page load time; we should
        probably split this into several requiests so we get a consistent
        number of upcoming, live, and recent entries.
      */
      const loadEntries = async () => {
        let update = false;

        const allEntries = await fetchContentEntriesUntil(
          contentEntries().section(['mentorSeminars']).status(['live', 'pending']),
          (entries: ReadonlyArray<ContentEntry>) => {
            const live = entries.filter(entry => entryIsPast(entry));
            return live.length < 5;
          },
          50
        );
        const seminars = await classifySeminars(allEntries);

        if (!equal(upcoming.value, seminars.upcoming)) {
          upcoming.value = seminars.upcoming;
          update = true;
        }
        if (JSON.stringify(next.value) !== JSON.stringify(seminars.next)) {
          next.value = seminars.next;
          update = true;
        }
        if (!equal(past.value, seminars.past)) {
          past.value = seminars.past;
          update = true;
        }
        if (!equal(activeWebcasts.value, seminars.activeWebcasts)) {
          activeWebcasts.value = seminars.activeWebcasts;
          update = true;
        }

        // Force Vue to update the components.
        if (update) {
          key.value = key.value + 1;
        }
      };
      await loadEntries();
      window.setInterval(loadEntries, 3000);

      tutors.value = await getAllFeaturedMentors();

      await bookmark.init();

      loader.setLoading(false);
    });

    const landingPage = useLandingPage(props.landingPageSlug);

    return {
      upcoming,
      past,
      next,
      activeWebcasts,
      tutors,
      landingPage,
      bookmark,
      key
    };
  }
};
</script>
