import {fetchContentEntries} from '../content-entry/content-entry-query';
import {contentEntries} from '../content-entry/content-entry-query-builder';
import {ContentEntry} from '../content-entry/content-entry-types';

export async function getGrooveTrainer(): Promise<Readonly<ContentEntry | undefined>> {
  try {
    const entry = await fetchContentEntries(contentEntries().section(['grooveTrainer']));
    return entry[0];
  } catch (error) {
    throw new Error(`Could not fetch groove trainer: ${error}`);
  }
}
