<template>
  <div>
    <offer-component :card="card" :heading="'Confirm your upgrade'" />

    <payment
      v-if="amount !== undefined"
      :margin-top="'sm'"
      :width="'extra-narrow'"
      :error="error"
      :email="email"
      :amount="amount"
      :purchase-label="'Confirm Payment'"
      :on-cancel="onCancel"
      :on-purchase="chargePaymentMethod"
      :recaptcha-action="'payment_upgrade'"
    />
    <backend-error :width="'narrow'" :error="error" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import OfferComponent from '../../offer-card/Offer.vue';
import Payment from '../../payment/Payment.vue';
import BackendError from '../../../generic/backend-error/BackendError.vue';
import {UpgradeOffer} from '../../../../backend/offer/upgrade-offer-types';
import {upgrade} from '../../../../backend/signup/checkout-query';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {OfferCardData} from '../../offer-card/types';
import {useBackendError} from '../../../vue-composition/backend-error/backend-error';
import {BraintreeCustomer, BraintreeNonce} from '../../payment/types';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {getCustomerLifetimeEventData} from '../../../../utils/url';

/**
 * This component allows students to purchase upgrades during signup.
 * For example, a monthly or annual Academy member could be presented
 * with an upsell offer to upgrade to a Lifetime membership.
 */
/* 
  ??? This component is more or less equivalent to the Upgrade.vue component in 
  /user-pages/account/pages/upgrade. We should refactor the cart/offer engine to
  generalise; the current solution struggles to differentiate between the 
  purchasing mechanisms (signup, upgrade, resubscribe, add-on) and the way the
  offers are presented.
*/
export default defineComponent({
  components: {
    OfferComponent,
    Payment,
    BackendError
  },
  props: {
    email: {type: String, required: true},
    offer: {type: Object as PropType<Readonly<UpgradeOffer>>, required: true},
    onComplete: {type: Function as PropType<ButtonClickCallback>, required: true},
    onCancel: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup(props) {
    const error = useBackendError();
    const loader = useFullScreenLoader();

    const destination = computed(() => {
      return props.offer.offerDestination[0];
    });
    const source = computed(() => {
      return props.offer.offerSource[0];
    });
    const amount = computed(() => {
      if (destination.value === undefined) {
        return undefined;
      }
      return destination.value.accessPassAmount;
    });
    const bonusPassSlugs = computed(() => {
      return props.offer.offerBonuses.map(b => b.slug);
    });
    const card = computed<Readonly<OfferCardData>>(() => {
      return {
        offer: props.offer,
        accessPass: destination.value,
        trialDuration: undefined,
        disabled: false
      };
    });
    const chargePaymentMethod = async (
      nonce: Readonly<BraintreeNonce>,
      _: Readonly<BraintreeCustomer>
    ) => {
      if (destination.value !== undefined) {
        loader.setLoading(
          true,
          'Charging your payment method. Please do not navigate away from this page.'
        );
        try {
          await upgrade(
            props.offer.offerDeleteSourcePass ? source.value.slug : undefined,
            destination.value.slug,
            props.offer.offerEndOldSubscriptionImmediately,
            props.offer.offerStartNewSubscriptionImmediately,
            bonusPassSlugs.value,
            nonce.nonce,
            nonce.deviceData,
            getCustomerLifetimeEventData()
          );
          loader.setLoading(false);

          props.onComplete();
        } catch (e) {
          loader.setLoading(false);
          error.catcher(e);
        }
      }
    };

    return {
      error,
      accessPass: destination,
      amount,
      card,
      chargePaymentMethod
    };
  }
});
</script>
