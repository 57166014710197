import {App, Component, computed} from 'vue';
import {useStore} from '../../../store/store';
import {createVueApp} from '../../../utils/vue';
import {useDeviceType} from '../device-type/device-type';
import {useColumnLayout} from '../column-layout/column-layout';

export function useSidebar() {
  const store = useStore();

  const deviceType = useDeviceType();
  const columnLayout = useColumnLayout(deviceType);
  const gridTemplateColumns = computed(() => {
    const cfg = columnLayout.config.value;
    return `grid-template-columns: repeat(3, minmax(0,${cfg.columnWidth}px) minmax(0,${cfg.gutterWidth}px))`;
  });

  return {
    show: (
      component: Readonly<Component>,
      props?: Readonly<any>,
      location: 'left' | 'right' = 'left'
    ) => {
      /*
        We need to create a new Vue app whenever the overlay is activated.
        The reason is that Vue does not allow us to re-mount an app that
        has already been mounted.
      */
      let app: Readonly<App> | undefined = undefined;
      if (component !== undefined) {
        app = createVueApp(component, props);
      }
      store.dispatch('sidebar/activate', {app, location});
    },
    hide: () => {
      store.dispatch('sidebar/deactivate');
    },
    gridTemplateColumns
  };
}
