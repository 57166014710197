<template>
  <h1 :class="`${textSize} ${wordBreak} ${textCenter} font-semibold ${color} max-w-txt`">
    <slot></slot>
  </h1>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../../../core/color-config';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  props: {
    wordBreak: {type: String, default: 'break-normal'},
    center: {type: Boolean, default: false},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const color = computed(() => {
      return props.spotColor !== undefined ? props.spotColor.text : 'text-white';
    });
    const textSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-6xl',
      sm: 'text-fl-7xl',
      md: 'text-fl-8xl',
      lg: 'text-fl-9xl',
      xl: 'text-fl-11xl',
      '2xl': 'text-fl-13xl'
    });
    const textCenter = computed(() => (props.center ? 'text-center' : 'text-left'));
    return {
      color,
      textCenter,
      textSize
    };
  }
});
</script>
