import {ref} from 'vue';
import {CraftId} from '../../../backend/craft/craft-types';
import {Webcast} from '../../../backend/webcast/webcast-types';
import {getWebcastForEntry} from '../../../backend/webcast/webcast-utils';
import {WebcastComposition} from './types';

export function useWebcast(): Readonly<WebcastComposition> {
  let webcastEntryId: CraftId | undefined = undefined;
  const webcast = ref<Readonly<Webcast> | undefined>(undefined);
  const webcastOnAir = ref(false);
  /* 
    If the webcast is not on air when the page is first displayed, set a timer
    to check the on air flag at intervals of 3 seconds.
  */
  const pollWebcast = async () => {
    if (webcastEntryId !== undefined) {
      webcast.value = await getWebcastForEntry(webcastEntryId);
      if (webcast.value !== undefined) {
        if (webcast.value.webcastOnAir) {
          webcastOnAir.value = true;
        }
      }
    }
  };

  const init = async (entryId: CraftId) => {
    webcastEntryId = entryId;
    await pollWebcast();
    window.setInterval(pollWebcast, 3000);
  };

  return {
    init,
    webcast,
    webcastOnAir
  };
}
