<template>
  <flow v-for="ce in contentEntries" :key="ce.id">
    <active-webcast
      :guest="guest"
      :content-entry="ce"
      :bookmark="bookmark"
      :title-image="titleImage"
      :title-text="titleText"
    />
  </flow>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Flow from '../../../core/compositions/Flow.vue';
import ActiveWebcast from './ActiveWebcast.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {CraftUrl} from '../../../../backend/craft/craft-types';

export default {
  components: {
    Flow,
    ActiveWebcast
  },
  props: {
    guest: {type: String, required: true},
    contentEntries: {type: Object as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    titleImage: {type: String as PropType<CraftUrl>, default: undefined},
    titleText: {type: String, default: undefined}
  },
  setup() {}
};
</script>
