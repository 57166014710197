import Histogram from './histogram';
import {Video} from '../../../backend/video/video-types';
import {
  InstrumentationComposition,
  useInstrumentation
} from '../../vue-composition/instrumentation/instrumentation';

function videoWatched(histogram: Readonly<Histogram>, videoLength: number, totalTime: number) {
  const coveragePerc = histogram.getCoverage();

  // This is the time we've spent in 'play' mode (regardless
  // of playback speed), as a percentage of the video length.
  const timeInPlayPerc = totalTime / videoLength;

  // Send a "video watched" instrumentation event if
  // the coverage % is >75 or if the time spent in play
  // mode is >75% of the total video length.
  return coveragePerc >= 0.75 || timeInPlayPerc >= 0.75;
}

// For the purposes of collecting statistics, the video is divided into
// a number of histogram bins - as specified by this constant. The histogram
// is used to collect information about where the video playhead has "been"
// in the video.
const HISTOGRAM_BINS = 100;

export class VideoInstrumentation {
  private timer: undefined | any = undefined;
  private playEventSent = false;
  private watchedEventSent = false;
  private histogram: Histogram = new Histogram(HISTOGRAM_BINS);
  private videoDuration: number | undefined = undefined;
  private totalTime: number = 0;
  private events: Readonly<InstrumentationComposition> = useInstrumentation();
  private video: Readonly<Video> | undefined = undefined;

  init(video: Readonly<Video>) {
    this.stop();
    this.playEventSent = false;
    this.watchedEventSent = false;
    this.histogram = new Histogram(HISTOGRAM_BINS);
    this.videoDuration = undefined;
    this.totalTime = 0;
    this.video = video;
  }

  async start() {
    if (!this.playEventSent && this.video !== undefined) {
      await this.events.sendEvent({
        type: 'videoPlay',
        properties: {
          videoServiceProvider: this.video.videoServiceProvider,
          videoId: this.video.videoId,
          videoType: this.video.videoType
        }
      });
      this.playEventSent = true;
    }

    if (!this.watchedEventSent && this.timer === undefined) {
      const TIMER_INTERVAL_MS = 1000;
      this.timer = setInterval(async () => {
        this.totalTime += TIMER_INTERVAL_MS / 1000;
        if (this.videoDuration !== undefined) {
          if (videoWatched(this.histogram, this.videoDuration, this.totalTime)) {
            if (this.video !== undefined) {
              await this.events.sendEvent({
                type: 'videoWatch',
                properties: {
                  videoServiceProvider: this.video.videoServiceProvider,
                  videoId: this.video.videoId,
                  videoType: this.video.videoType
                }
              });
              this.watchedEventSent = true;
            }
            this.stop();
          }
        }
      }, TIMER_INTERVAL_MS);
    }
  }

  stop() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  addSample(playPos: number, videoDuration: number) {
    this.videoDuration = videoDuration;
    this.histogram.addSample(playPos / this.videoDuration);
  }
}
