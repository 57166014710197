<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <content-entry-header>My Subscriptions</content-entry-header>
      </content-column>
    </page-section>

    <page-section v-if="accessPasses.length > 0" :margin-top="'lg'">
      <content-column :width="'wide'">
        <div class="flex flex-col">
          <div v-for="(ap, index) in accessPasses" :key="ap.id">
            <standard-divider v-if="index === 0" class="border-dark" />
            <access-pass :access-pass="ap" />
            <standard-divider class="border-dark" />
          </div>
        </div>
      </content-column>
    </page-section>

    <page-section v-else :margin-top="'lg'">
      <content-column :width="'wide'">
        <div class="text-fl-xl">You do not have any active subscriptions.</div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageContent from '../../../../../core/page/PageContent.vue';
import PageSection from '../../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardDivider from '../../../../../core/standard-divider/StandardDivider.vue';
import AccessPass from './AccessPass.vue';
import {AccessPassStatus} from '../../../../../../backend/access-pass/access-pass-types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    StandardDivider,
    AccessPass
  },
  props: {
    accessPasses: {type: Array as PropType<ReadonlyArray<AccessPassStatus>>, required: true}
  },
  setup() {}
});
</script>
