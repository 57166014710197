import {
  Video,
  VideoId,
  VideoTimestamp,
  VideoType,
  VIDEO_TIMESTAMP_VALIDATION_SCHEMA
} from './video-types';
import {durationToHMS, HMSToSeconds} from '../../utils/duration';
import {validatedHttpGet, validatedHttpPost} from '../http';
import {craftUrl} from '../../utils/url';
import * as QueryBuilder from '../craft/query/craft-query-builder';

// ### Replace validatedHttpGet/Post() in functions here with get/postAction()

export const VIDEOS_FIELD = QueryBuilder.matrix('videos').blocks([
  QueryBuilder.block('video').fields([
    QueryBuilder.dropdown('videoServiceProvider', ['vimeo', 'youtube']).required(),
    QueryBuilder.plainText('videoId').required(),
    QueryBuilder.plainText('videoTitle'),
    QueryBuilder.dropdown('videoType', ['trailer', 'content']).required(),
    QueryBuilder.plainText('videoDuration')
  ])
]);

export function getVideoDuration(video: Readonly<Video>): number | undefined {
  const duration = video.videoDuration;
  const hms = durationToHMS(duration);
  if (hms !== undefined) {
    return HMSToSeconds(hms);
  }
  return undefined;
}

export function getFirstVideoOfType(
  videos: ReadonlyArray<Video>,
  type: VideoType
): Readonly<Video> | undefined {
  return videos.find(v => {
    return v.videoType === type;
  });
}

export function getAllVideosOfTypeDifferentThan(videos: ReadonlyArray<Video>, type: VideoType) {
  return videos.filter(video => {
    return video.videoType !== type;
  });
}

export async function getVideoTimestamp(videoId: VideoId): Promise<VideoTimestamp> {
  try {
    const url = craftUrl(`/actions/sbl-module/timestamp/get-timestamp?videoId=${videoId}`);
    const response = await validatedHttpGet<VideoTimestamp>(url, VIDEO_TIMESTAMP_VALIDATION_SCHEMA);
    return response;
  } catch (error) {
    throw new Error(`Could not get video timestamp: ${error}`);
  }
}

export async function setVideoTimestamp(
  videoId: VideoId,
  timestamp: VideoTimestamp
): Promise<VideoTimestamp> {
  try {
    const timestampInt = Math.round(timestamp);
    const url = craftUrl(
      `/actions/sbl-module/timestamp/set-timestamp?videoId=${videoId}&timestamp=${timestampInt}`
    );
    const result = await validatedHttpPost<VideoTimestamp>(
      url,
      {},
      VIDEO_TIMESTAMP_VALIDATION_SCHEMA
    );
    return result;
  } catch (error) {
    throw new Error(`Could not set video timestamp: ${error}`);
  }
}
