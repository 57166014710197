<template>
  <div class="fixed left-[10px] bottom-[10px] bg-[rgb(40,40,40)] text-white p-2 z-50">
    {{ deviceType.screen.value }}
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  setup() {
    const deviceType = useDeviceType();
    return {deviceType};
  }
});
</script>
