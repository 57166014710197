<template>
  <!-- ??? We use the CSS aspect-ratio property here, which became available in 2021. Is that OK? -->
  <iframe
    v-if="url"
    id="vimeo-frame"
    ref="iframe"
    :src="url"
    title="Video frame"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    class="w-full aspect-video"
  ></iframe>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import Player from '@vimeo/player';
import {
  Config,
  makeGetDurationCallback,
  makeGetPlayPosCallback,
  makeCueVideoCallback,
  makePauseCallback
} from './callbacks';
/**
 * This components wraps a Vimeo <iframe> in a Vue component.
 * To interact with it, you need to use "public methods" (see
 * method comments below). To call these functions, use Vue component
 * refs. For details, see
 * https://stackoverflow.com/questions/40957008/how-to-access-to-a-child-method-from-the-parent-in-vue-js
 *
 */
export default defineComponent({
  setup() {
    // The url is set by the loadVideo/cueVideo functions (see below).
    const url = ref<string | undefined>(undefined);

    // Reference to the <iframe> element in the template; see
    // https://v3.vuejs.org/guide/composition-api-template-refs.html
    const iframe = ref(null as any);

    // Internal config data; does not have to be reactive.
    const config: Config = {
      player: undefined as Player | undefined,
      videoId: undefined as string | undefined,
      videoLengthSec: undefined as number | undefined,
      videoHasPlayed: false
    };

    const pause = makePauseCallback(config);
    const getPlayPos = makeGetPlayPosCallback(config);
    const getVideoDuration = makeGetDurationCallback(config);
    const cueVideo = makeCueVideoCallback(config, url, iframe);

    return {
      iframe,
      url,

      /*
        These functions are "public" - they can (and should) be called by other components.
       */
      pause,
      getPlayPos,
      getVideoDuration,
      cueVideo
    };
  }
});
</script>
