import {getVideoDuration} from '../../video/video-query';
import {ContentEntry} from '../content-entry/content-entry-types';

export function getLessonLengthSec(lesson: Readonly<ContentEntry>): number {
  const lessonVideo = lesson.videos[0];
  if (!lessonVideo) {
    return 0;
  }
  const duration = getVideoDuration(lessonVideo);
  if (duration === undefined) {
    throw new Error('Could not comupte lesson length');
  }
  return duration;
}
