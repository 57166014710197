<template>
  <div class="w-full flex flex-col gap-fl-2xs">
    <div class="flex justify-between text-mid text-fl-sm">
      <div>{{ playPosDisplay }}</div>
      <div>{{ durationDisplay }}</div>
    </div>
    <timeline :position="playPos" :on-change="audioPlayer.onScrub" />
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import Timeline from '../../timeline/Timeline.vue';
import {HMSToString, secToHMS} from '../../../../utils/duration';
import {AudioPlayerComposition} from '../internals/utils';
export default defineComponent({
  components: {
    Timeline
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true}
  },
  setup(props) {
    const playPosDisplay = computed(() => {
      return HMSToString(secToHMS(props.audioPlayer.progressSeconds.value));
    });
    const durationDisplay = computed(() => {
      return HMSToString(secToHMS(props.audioPlayer.durationSeconds.value));
    });
    const playPos = computed(() => {
      if (props.audioPlayer.durationSeconds.value === 0) {
        return 0;
      }
      return props.audioPlayer.progressSeconds.value / props.audioPlayer.durationSeconds.value;
    });

    return {
      playPos,
      playPosDisplay,
      durationDisplay
    };
  }
});
</script>
