<template>
  <cluster :gap="'gap-fl-sm'" :wrap="false" :class="'text-lightest'">
    <div :class="`${fontSize} uppercase`">Speed:</div>
    <cluster :gap="'gap-fl-sm'" :wrap="false">
      <div :class="`${fontSize}`">
        {{ audioPlayer.speedDisplay.value }}
      </div>
      <up-down-button-combo
        :gap="'gap-fl-sm'"
        :color="'lightest'"
        :font-size="buttonSize"
        :on-up="audioPlayer.onSpeedUp"
        :on-down="audioPlayer.onSpeedDown"
        :tooltip-up="'Increase the playback speed'"
        :tooltip-down="'Decrease the playback speed'"
      />
    </cluster>
  </cluster>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import UpDownButtonCombo from '../../../core/button/UpDownButtonCombo.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faGaugeSimpleHigh} from '@fortawesome/pro-solid-svg-icons/faGaugeSimpleHigh';
import {AudioPlayerComposition} from '../internals/utils';

export default {
  components: {
    Cluster,
    UpDownButtonCombo
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true},
    fontSize: {type: String, default: 'text-fl-base'},
    buttonSize: {type: String, default: 'text-fl-3xl'}
  },
  setup() {
    const iconGauge = computed(() => faGaugeSimpleHigh);
    return {
      iconGauge
    };
  }
};
</script>
