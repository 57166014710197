<template>
  <icon-label-button
    v-if="!guest && !contentEntry.preview"
    :icon="icon"
    :label="label"
    :color="color"
    :color-label="color"
    :tooltip="'Toggle progress'"
    @click="toggle"
  />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import IconLabelButton from '../../../../core/button/IconLabelButton.vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {ProgressComposition} from '../../../../vue-composition/progress/types';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {faArrowRotateLeft} from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import {ButtonColor} from '../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    IconLabelButton
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    guest: {type: String, required: true},
    progress: {type: Object as PropType<ProgressComposition>, required: true},
    color: {type: String as PropType<ButtonColor>, default: 'dark'}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const toggle = async () => {
      if (!preview.value && !props.guest) {
        loader.setLoading(true);
        if (props.progress.progress.value === 100) {
          await props.progress.setProgress('clear');
        } else {
          await props.progress.setProgress('completed');
        }
        loader.setLoading(false);
      }
    };
    const label = computed(() => {
      return props.progress.progress.value === 100 ? 'Reset' : 'Complete';
    });

    const icon = computed(() => {
      return props.progress.progress.value === 100 ? faArrowRotateLeft : faCheck;
    });

    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });

    return {
      preview,
      toggle,
      label,
      icon
    };
  }
});
</script>
