<template>
  <page-section v-if="mostRecentMentorSeminar.length > 0" :margin-top="marginTop">
    <margins :row-gap="'gap-y-fl-xl'">
      <content-area :area="cardArea" :row-gap="'gap-y-fl-xl'">
        <column-grid :row-gap="'gap-y-fl-md-xl'">
          <content-area>
            <repel :gap="'gap-fl-xs'">
              <standard-heading :size="3">Latest Mentor Seminar</standard-heading>
              <link-button :size="'lg'" :url="'/mentors/seminars'" :label="'View All'" />
            </repel>
          </content-area>
          <content-area>
            <dashboard-single-entry
              :guest="guest"
              :content-entry="mostRecentMentorSeminar[0]"
              :bookmark="bookmark"
            />
          </content-area>
        </column-grid>
      </content-area>
      <content-area :area="listArea">
        <column-grid :row-gap="'gap-y-fl-md-xl'">
          <content-area>
            <repel :gap="'gap-fl-xs'">
              <standard-heading :size="3">Upcoming Live Seminars</standard-heading>
              <link-button :size="'lg'" :url="'/mentors'" :label="'View All'" />
            </repel>
          </content-area>
          <dashboard-upcoming-seminars
            :guest="guest"
            :bookmark="bookmark"
            :upcoming-mentor-seminars="upcomingMentorSeminars"
          />
        </column-grid>
      </content-area>
    </margins>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import ColumnGrid from '../../../core/column-layout/ColumnGrid.vue';
import Repel from '../../../core/compositions/Repel.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import DashboardUpcomingSeminars from './DashboardUpcomingSeminars.vue';
import DashboardSingleEntry from './DashboardSingleEntry.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {ColumnArea} from '../../../core/column-layout/types';
import {PageSectionMargin} from '../../../core/page/PageSection.vue';

export default defineComponent({
  components: {
    PageSection,
    Margins,
    ContentArea,
    ColumnGrid,
    Repel,
    StandardHeading,
    LinkButton,
    DashboardSingleEntry,
    DashboardUpcomingSeminars
  },
  props: {
    guest: {type: String, required: true},
    mostRecentMentorSeminar: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    upcomingMentorSeminars: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<BookmarkComposition>, required: true},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'md'}
  },
  setup() {
    const cardArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      md: {start: 1, span: 4}
    });
    const listArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      md: {start: 6, span: -1}
    });

    return {
      cardArea,
      listArea
    };
  }
});
</script>
