<template>
  <page-content v-if="accessPasses !== undefined">
    <page-section>
      <margins>
        <content-area :area="titleArea">
          <div class="flex justify-center">
            <imgix-image :config="titleImage" :class="`w-full max-w-[500px]`" />
          </div>
        </content-area>
      </margins>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <div class="text-fl-3xl font-semibold text-white text-center">
          The ultimate live-learning experience for bass players.
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <div class="flex justify-center">
          <imgix-image :config="coverImage" class="w-full" />
        </div>
      </content-column>
    </page-section>

    <page-section v-if="canResubscribe">
      <content-column>
        <div class="flex flex-col items-center border-2 border-primary rounded-2xl w-full">
          <div class="p-fl-lg">
            <div class="text-fl-3xl text-primary font-semibold text-center">
              Your LiveClass Membership has expired.
            </div>
            <div class="text-fl-3xl text-primary font-semibold text-center">
              Please visit your <a class="underline" href="/account">Account</a> page to
              re-subscribe.
            </div>
          </div>
        </div>
      </content-column>
    </page-section>

    <page-section v-if="guest || !canResubscribe">
      <content-column>
        <div class="flex flex-col gap-fl-xl text-fl-3xl">
          <div>
            SBL LiveClass transforms your SBL Membership from a standalone, self-paced learning
            platform…
          </div>
          <div>
            Into an interactive bass school that brings you access to an experience that has never
            before existed for bass players like us, including:
          </div>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>15 live classes every week</li>
            <li>
              All the topics we love: funk, gospel, rock, jazz, gear deep-dives, technique, and more
            </li>
            <li>World-class instructors right there with you</li>
            <li>Instant personalized feedback for more rapid improvement</li>
          </ul>
        </div>
      </content-column>
    </page-section>
    <page-section v-if="guest || !canResubscribe" :margin-top="'lg'">
      <content-column>
        <div class="flex flex-col items-center border-2 border-white rounded-2xl w-full">
          <div class="p-fl-lg">
            <div class="text-fl-3xl text-white font-semibold text-center">
              LiveClass enrollment is currently closed.
            </div>
          </div>
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {PropType, computed, defineComponent, onMounted, ref} from 'vue';
import {getCircleCommunity} from '../../backend/circle/circle-query';
import {getAccessPassesForCurrentUser} from '../../backend/access-pass/access-pass-query';
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import Margins from '../core/column-layout/Margins.vue';
import ContentArea from '../core/column-layout/ContentArea.vue';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import ImgixImage from '../core/imgix-image/ImgixImage.vue';
import {useFullScreenLoader} from '../vue-composition/loader/loader';
import {CraftSlug} from '../../backend/craft/craft-types';
import {useLandingPage} from '../vue-composition/landing-page/landing-page';
import {AccessPassStatus} from '../../backend/access-pass/access-pass-types';
import {CircleCommunity} from '../../backend/circle/circle-types';
import {useDeviceType} from '../vue-composition/device-type/device-type';
import {titleAreaNoInfoButton} from '../content-entry/partials/section/utils';
import {ImgixImageResponsiveWidth} from '../core/imgix-image/types';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {redirectWithHistory} from '../../utils/url';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    Margins,
    ContentArea,
    ImgixImage
  },
  props: {
    guest: {type: String, required: true},
    landingPageSlug: {type: String as PropType<CraftSlug>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const landingPage = useLandingPage(props.landingPageSlug);
    const member = ref<boolean | undefined>(undefined);
    const accessPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);
    const community = ref<Readonly<CircleCommunity> | undefined>(undefined);
    const deviceType = useDeviceType();

    const titleArea = computed(() => {
      const screen = deviceType.screen.value;
      return titleAreaNoInfoButton('normal', screen, true);
    });
    const titleImage = computed(() => {
      return {
        path: '/content/liveclass-logo.svg',
        alt: 'LiveClass',
        width: 800,
        imgixParams: {}
      };
    });

    const coverImage = computed(() => {
      const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
        narrow: 510,
        sm: 650,
        md: 900,
        lg: 1150,
        xl: 1150,
        '2xl': 1000
      };
      return {
        path: '/content/liveclass-landing.jpg',
        alt: 'LiveClass',
        width: cardImageWidth
      };
    });

    const liveClassPass = computed(() => {
      if (accessPasses.value === undefined) {
        return undefined;
      }
      const pass = accessPasses.value.find(ap => {
        return ap.slug.startsWith('ap-live-class');
      });
      return pass;
    });

    const canResubscribe = computed(() => {
      if (liveClassPass.value === undefined) {
        return false;
      }
      return liveClassPass.value.status === 'inactive';
    });

    const communityUrl = computed(() => {
      if (community.value === undefined) {
        return undefined;
      }
      return community.value.circleCommunityUrl;
    });

    const iconCheck = computed(() => faCheck);

    onMounted(async () => {
      loader.setLoading(true);
      community.value = await getCircleCommunity('circle-liveclass');
      loader.setLoading(false);
      if (community.value === undefined) {
        throw new Error('Unknown community');
      }

      // If the user has an active LiveClass access pass, redirect them straight to Circle.so.
      loader.setLoading(true);
      accessPasses.value = await getAccessPassesForCurrentUser();
      const activePass = accessPasses.value.find(ap => {
        return ap.slug.startsWith('ap-live-class') && ap.status !== 'inactive';
      });
      if (activePass !== undefined) {
        loader.setLoading(true);
        redirectWithHistory(community.value.circleCommunityUrl);
        return;
      }
      loader.setLoading(false);
    });

    return {
      accessPasses,
      titleArea,
      titleImage,
      coverImage,
      iconCheck,
      member,
      canResubscribe,
      landingPage,
      communityUrl
    };
  }
});
</script>
