import {DateTime} from 'luxon';
import {OfferConditionsSignupDateInterval} from '../offer-conditions-types';

export function evaluateSignupDate(
  condition: Readonly<OfferConditionsSignupDateInterval>,
  userSignupDateUTC: number | undefined
) {
  if (userSignupDateUTC === undefined) {
    return true;
  }
  if (condition.startDate !== null) {
    const date = DateTime.fromISO(condition.startDate).valueOf();
    if (userSignupDateUTC < date) {
      return false;
    }
  }
  if (condition.endDate !== null) {
    const date = DateTime.fromISO(condition.endDate).valueOf();
    if (userSignupDateUTC > date) {
      return false;
    }
  }
  return true;
}
