<template>
  <div class="py-fl-lg-2xl flex flex-col gap-fl-lg">
    <standard-heading :size="2">{{ offer.offerTitle }}</standard-heading>
    <div v-if="offer.summary" class="text-fl-2xl">{{ offer.summary }}</div>
    <standard-heading v-if="offer.offerBonuses.length > 0" :size="3">Free bonuses</standard-heading>
    <ul v-if="offer.offerBonuses.length > 0" class="list-disc list-inside">
      <li v-for="bonus in offer.offerBonuses" :key="bonus.id">{{ bonus.title }}</li>
    </ul>
    <div :id="offer.slug">
      <standard-button :on-click="onSelect" :label="label" />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../../../core/button/StandardButton.vue';
import {UpgradeOffer} from '../../../../../../backend/offer/upgrade-offer-types';
import {AddOnOffer} from '../../../../../../backend/offer/addon-offer-types';
import {ButtonClickCallback} from '../../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    StandardHeading,
    StandardButton
  },
  props: {
    offer: {type: Object as PropType<Readonly<UpgradeOffer | AddOnOffer>>, required: true},
    onSelect: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup(props) {
    const label = computed(() => {
      if (
        props.offer.offerSelectionLabel !== undefined &&
        props.offer.offerSelectionLabel !== null
      ) {
        return props.offer.offerSelectionLabel;
      }
      return 'Purchase';
    });
    return {
      label
    };
  }
});
</script>
