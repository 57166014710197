import {Dropin} from 'braintree-web-drop-in';
import {requestPaymentMethod} from '../../../backend/signup/braintree-dropin-ui';
import {getBraintreeVault} from '../../../backend/signup/braintree-query';
import {BraintreeCustomer, BraintreeNonce} from './types';

/**
 * Retrive a Braintree client token for the customer with the specified email.
 */
export async function getCustomer(
  email: string,
  recaptchaToken: string
): Promise<BraintreeCustomer> {
  const vault = await getBraintreeVault(email, recaptchaToken);
  return {
    email,
    customerId: vault.customerId,
    clientToken: vault.clientToken
  };
}

/**
 * Request a nonce for the payment method currently selected in the
 * specified Braintree Dropin UI.
 */
export async function getNonce(
  dropin: Dropin,
  email: string,
  amount: number
): Promise<BraintreeNonce> {
  const paymentMethod = await requestPaymentMethod(dropin, email, amount);
  if (!paymentMethod.success || paymentMethod.nonce === undefined) {
    dropin.clearSelectedPaymentMethod();
    const message = paymentMethod.error ? paymentMethod.error : 'Could not request payment method';
    throw new Error(message);
  }
  if (paymentMethod.deviceData === undefined) {
    throw new Error('No device data');
  }
  return {
    nonce: paymentMethod.nonce,
    deviceData: paymentMethod.deviceData
  };
}
