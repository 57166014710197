<template>
  <carousel
    :width="width"
    :number-of-slides="tutorList.length"
    :nav-button-height-ratio="4 / 3"
    :disabled="disabled"
    :card-size="cardSize"
    :card-aspect-ratio="'3:4'"
  >
    <template v-for="(t, i) in tutorList" :key="t.id" #[`${i}`]>
      <tutor-card
        :tutor="t.tutor"
        :display-bio="displayBio"
        :display-mentor-focus-of-study="displayMentorFocusOfStudy"
        :image-width="imageWidth"
      />
    </template>
  </carousel>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import Carousel from '../../../generic/carousel/Carousel.vue';
import TutorCard from '../../tutors/TutorCard.vue';
import {Tutor} from '../../../../backend/tutor/tutor-types';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {CarouselCardSize, getCarouselImageWidths} from '../../../generic/carousel/carousel';

export default {
  components: {
    Carousel,
    TutorCard
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'},
    cardSize: {type: String as PropType<CarouselCardSize>, default: 'xs'},
    tutors: {type: Array as PropType<ReadonlyArray<Tutor>>, required: true},
    displayBio: {type: Boolean, default: true},
    displayMentorFocusOfStudy: {type: Boolean, default: true}
  },
  setup(props) {
    const imageWidth = computed(() => {
      return getCarouselImageWidths(props.cardSize);
    });

    const tutorList = computed(() => {
      if (props.tutors.length === 0) {
        return [...Array(10).keys()].map(i => {
          return {
            id: i,
            tutor: undefined
          };
        });
      }
      return props.tutors.map(t => {
        return {id: t.id, tutor: t};
      });
    });

    const disabled = computed(() => props.tutors.length === 0);

    return {
      imageWidth,
      tutorList,
      disabled
    };
  }
};
</script>
