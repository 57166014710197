import {CraftSlug} from '../craft/craft-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {TeamDirectoryMember} from './team-directory-types';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export async function getTeamDirectory(): Promise<Array<TeamDirectoryMember>> {
  try {
    const teamDirectoryEntries = await fetchEntries(
      QueryBuilder.entries()
        .section(['teamDirectory'])
        .fields([
          QueryBuilder.plainText('teamDirectoryJobTitle').required(),
          IMAGE_COLLECTION_FIELD
        ])
    );
    return teamDirectoryEntries as unknown as Array<TeamDirectoryMember>;
  } catch (error) {
    throw new Error(`Could not fetch team directory: ${error}`);
  }
}

export async function getTeamDirectoryMemberBySlug(
  slug: CraftSlug
): Promise<TeamDirectoryMember | undefined> {
  try {
    const teamDirectoryEntries = await fetchEntries(
      QueryBuilder.entries()
        .section(['teamDirectory'])
        .slug([slug])
        .fields([
          QueryBuilder.plainText('teamDirectoryJobTitle').required(),
          IMAGE_COLLECTION_FIELD
        ])
    );
    const teamDirectory = teamDirectoryEntries as unknown as Array<TeamDirectoryMember>;
    return teamDirectory[0];
  } catch (error) {
    throw new Error(`Could not fetch team directory member: ${error}`);
  }
}
