<template>
  <div>
    <device-type-debugger v-if="showDebugger" />
    <!-- Page content -->
    <div :class="`${mt} ${mb}`">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import DeviceTypeDebugger from './DeviceTypeDebugger.vue';
import {ENABLE_DEVICE_TYPE_DEBUGGER} from '../../../settings';
/**
 * This component adds a default margin to the top and bottom of a page of content.
 */
export default defineComponent({
  components: {
    DeviceTypeDebugger
  },
  props: {
    mt: {type: String, default: ''},
    mb: {type: String, default: 'mb-fl-2xl-3xl'}
  },
  setup() {
    const showDebugger = ENABLE_DEVICE_TYPE_DEBUGGER;
    return {
      showDebugger
    };
  }
});
</script>
