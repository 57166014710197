<template>
  <flow v-if="question !== undefined">
    <div class="py-fl-lg px-fl-lg-2xl bg-darkest rounded-lg">
      <div class="text-fl-lg text-white">
        Question {{ currentQuestion + 1 }} of {{ numberOfQuestions }}
      </div>
      <standard-heading :size="2" class="mt-fl-xl">{{ quiz.title }}</standard-heading>
      <div class="text-fl-lg text-lightest mt-fl-md">{{ question.quizQuestionTitle }}</div>
      <cluster :justify="'justify-end'" class="mt-fl-2xl">
        <standard-button
          v-for="(answer, index) in answers"
          :id="index"
          :key="index"
          :on-click="answerSelected"
          :border="true"
          :label="(answer.quizAnswerTitle as string)"
        />
      </cluster>
    </div>
  </flow>
</template>

<script lang="ts">
import {ref, computed, PropType} from 'vue';
import {Quiz} from '../../../../backend/content/quiz/quiz-types';
import StandardButton from '../../../core/button/StandardButton.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Flow from '../../../core/compositions/Flow.vue';
import {
  getNumberOfQuestions,
  getQuestionWithAnswers
} from '../../../../backend/content/quiz/quiz-query';

export type QuizCompleteCallbackFunc = (success: boolean, correct: number) => void;

export default {
  components: {
    StandardHeading,
    Flow,
    Cluster,
    StandardButton
  },
  props: {
    quiz: {type: Object as PropType<Quiz>, required: true},
    onCompleted: {type: Function as PropType<QuizCompleteCallbackFunc>, required: true}
  },
  setup(props) {
    const currentQuestion = ref(0);
    const questionWithAnswers = computed(() => {
      return getQuestionWithAnswers(props.quiz, currentQuestion.value);
    });
    const question = computed(() => {
      if (questionWithAnswers.value !== undefined) {
        return questionWithAnswers.value[0];
      }
      return undefined;
    });
    const answers = computed(() => {
      if (questionWithAnswers.value !== undefined) {
        return questionWithAnswers.value.slice(1);
      }
      return [];
    });
    const numberOfQuestions = computed(() => {
      return getNumberOfQuestions(props.quiz);
    });

    let numberOfCorrectQuestions = 0;
    const answerSelected = (index?: number | string) => {
      if (index !== undefined) {
        const answer = answers.value[index as number];
        if (answer !== undefined && answer.quizAnswerIsCorrect !== undefined) {
          if (answer.quizAnswerIsCorrect) {
            numberOfCorrectQuestions += 1;
          }
          currentQuestion.value += 1;
          if (currentQuestion.value === numberOfQuestions.value) {
            const required = Number.parseInt(props.quiz.quizRequiredNumberOfCorrectAnswers, 10);
            props.onCompleted(numberOfCorrectQuestions >= required, numberOfCorrectQuestions);
          }
        }
      }
    };
    return {
      currentQuestion,
      question,
      answers,
      numberOfQuestions,
      answerSelected
    };
  }
};
</script>
