import {CraftUrl} from '../backend/craft/craft-types';

/**
 * Replace current browser URL without reloading page.
 * @param {string} title String of new page title. Note: most browsers don't support title.
 * @param {URL} url The new URL.
 */
export function replaceUrl(title: string, url: string) {
  window.history.pushState({}, title, url);
}

/**
 * Return the query params for the current browser URL.
 * See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/get#example
 */
export function getUrlQueryParams(): URLSearchParams {
  return new URLSearchParams(document.location.search);
}

/**
 * Examine the current URL to see if it has a customer lifetime event data
 * query parameter. If so, return it. Otherwise, return undefined.
 */
export function getCustomerLifetimeEventData(): string | undefined {
  const params = getUrlQueryParams();
  const data = params.get('lted');
  if (data !== null) {
    return data;
  }
  return undefined;
}

/**
 * Given a relative URL, e.g., '/actions/sbl-module/products/get-product-sections',
 * return the complete absolute URL, e.g., 'http://scottsbasslessons.com/actions/...'.
 */
export function craftUrl(relativeUrl: string): string {
  if (window.Craft === undefined || window.Craft.siteUrl === undefined) {
    throw new Error('Site URL is unknown');
  }

  // Remove any trailing '/' characters from the base URL.
  const base = window.Craft.siteUrl.replace(/\/$/, '');

  // Remove any starting '/' characters from the relative URL.
  const rel = relativeUrl.replace(/^\//, '');

  // Combine the two URL parts.
  return `${base}/${rel}`;
}

export function removeMultipleSlashesFromUrl(url: string): string {
  // https://stackoverflow.com/questions/24381480/remove-duplicate-forward-slashes-from-the-url
  return url.replace(/([^:]\/)\/+/g, '$1');
}

/**
 * Redirect the browser to the specified URL; add the current page
 * to the browser's history. (I.e., the "Back" button in the browser
 * will navigate back to the current page.)
 */
export function redirectWithHistory(url: CraftUrl) {
  window.location.assign(url);
}

/**
 * Redirect the browser to the specified URL; does NOT add the
 * current page to the browser's history. (I.e., the "Back" button
 * in the browser will skip the current page.)
 */
export function redirectNoHistory(url: CraftUrl) {
  window.location.replace(url);
}
