<template>
  <!-- Content column -->
  <margins>
    <content-area v-if="area !== undefined" :area="area.left">
      <slot name="left" />
    </content-area>
    <content-area v-if="area !== undefined" :area="area.right">
      <slot name="right" />
    </content-area>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Margins from '../column-layout/Margins.vue';
import ContentArea from '../column-layout/ContentArea.vue';
import {StandardPageAreaWidth} from '../column-layout/utils';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

/**
 * This is a utility component that creates a centered area inside of the
 * main page column grid (see the documentation for the <columns> component
 * for details on the column grid). That centered area is divided into two
 * columns.
 *
 * For narrow viewports (where we have 4 layout columns), the area covers
 * column 1 through 4.
 *
 * For other viewports (where we have 12 layout columns), the area span
 * depends on the 'width' property:
 *
 * 'wide': 12 columns
 * 'medium': 10 columns
 * 'normal': 8 columns
 * 'narrow': 6 columns
 *
 * The area extends to cover more columns as the width of the screen decreases.
 */
export default defineComponent({
  components: {
    Margins,
    ContentArea
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const area = computed(() => {
      const screen = deviceType.screen.value;
      if (screen === 'narrow' || screen === undefined) {
        return {
          left: {start: 1, span: 4},
          right: {start: 1, span: 4}
        };
      }
      switch (props.width) {
        case 'wide':
          return {
            left: {start: 1, span: 6},
            right: {start: 7, span: 6}
          };
        case 'medium':
          switch (screen) {
            case 'sm':
            case 'md':
            case 'lg':
            case 'xl':
              return {
                left: {start: 1, span: 6},
                right: {start: 7, span: 6}
              };
            case '2xl':
              return {
                left: {start: 2, span: 5},
                right: {start: 7, span: 5}
              };
          }
        case 'normal':
          switch (screen) {
            case 'sm':
            case 'md':
            case 'lg':
              return {
                left: {start: 1, span: 6},
                right: {start: 7, span: 6}
              };
            case 'xl':
              return {
                left: {start: 2, span: 5},
                right: {start: 7, span: 5}
              };
            case '2xl':
              return {
                left: {start: 3, span: 4},
                right: {start: 7, span: 4}
              };
          }
        case 'narrow':
          switch (screen) {
            case 'sm':
            case 'md':
              return {
                left: {start: 1, span: 6},
                right: {start: 7, span: 6}
              };
            case 'lg':
              return {
                left: {start: 2, span: 5},
                right: {start: 7, span: 5}
              };
            case 'xl':
              return {
                left: {start: 3, span: 4},
                right: {start: 7, span: 4}
              };
            case '2xl':
              return {
                left: {start: 4, span: 3},
                right: {start: 7, span: 3}
              };
          }
      }
      return undefined;
    });
    return {
      area
    };
  }
});
</script>
