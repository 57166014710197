import {postAction} from '../craft-action';
import {fetchCategories} from '../craft/category/category-query';
import {VALIDATION_BOOLEAN} from '../validation';
import {CircleCommunity} from './circle-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export async function getCircleCommunity(slug: string): Promise<CircleCommunity> {
  const query = QueryBuilder.categories()
    .group('circleCommunities')
    .slug([slug])
    .fields([
      QueryBuilder.number('circleCommunityId').required(),
      QueryBuilder.url('circleCommunityUrl').required()
    ])
    .required();
  const result = await fetchCategories<CircleCommunity>(query);
  return result[0];
}

export async function getMembershipStatusOfCurrentUser(communitySlug: string): Promise<boolean> {
  const url = '/actions/sbl-module/circle/get-membership-status-of-current-user';
  const result = await postAction<boolean>(
    url,
    {
      community: communitySlug
    },
    VALIDATION_BOOLEAN
  );
  return result;
}
