<template>
  <modal-dialog :config="config">
    <template #body>
      <video-player
        v-if="video !== undefined"
        :preview="videoType === 'trailer' ? false : preview"
        :guest="guest"
        :resume-at-last-timestamp="resumeAtLastTimestamp"
        :video="video"
        :on-play="playCallback"
        :on-end="onEnd"
        class="p-6"
      />
    </template>
  </modal-dialog>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import VideoPlayer, {
  EndCallback,
  PlayCallback
} from '../../../generic/video-player/VideoPlayer.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {getFirstVideoOfType} from '../../../../backend/video/video-query';
import {VideoType} from '../../../../backend/video/video-types';
import ModalDialog from '../../../generic/modal-dialog/ModalDialog.vue';
import {addOrUpdateHistory} from '../../../../backend/history/history-query';
import {ModalDialogConfig} from '../../../vue-composition/modal-dialog/types';

export default {
  components: {
    VideoPlayer,
    ModalDialog
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    videoType: {type: String as PropType<VideoType>, required: true},
    resumeAtLastTimestamp: {type: Boolean, default: false},
    onPlay: {type: Function as PropType<PlayCallback>, default: undefined},
    onEnd: {type: Function as PropType<EndCallback>, default: undefined},
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true}
  },
  setup(props) {
    const video = computed(() => {
      return getFirstVideoOfType(props.contentEntry.videos, props.videoType);
    });

    const playCallback = async () => {
      /*
        Call any onPlay callbacks provided to us first, before updating the user's
        content access history.
      */
      if (props.onPlay !== undefined) {
        props.onPlay();
      }
      if (props.videoType !== 'trailer' && !props.guest && !preview.value) {
        await addOrUpdateHistory(props.contentEntry.id);
      }
    };

    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });

    return {
      video,
      preview,
      playCallback
    };
  }
};
</script>
