<template>
  <page-section>
    <content-two-columns :width="'wide'">
      <template #left>
        <flow :gap="7">
          <p class="text-fl-3xl">
            We've partnered up with the best brands to bring you exclusive discounts on Basses,
            Amps, Accessories, and more.
          </p>

          <p
            v-if="!preview && studentCode !== undefined"
            class="p-fl-md bg-mid text-white text-fl-5xl"
          >
            Your Student Code is: <b>{{ studentCode }}</b>
          </p>

          <flow v-if="!preview">
            <standard-heading :size="4">How does this work?</standard-heading>
            <flow class="text-fl-base">
              <p>
                Take a look at the discounts offered by the partners. When you're ready to buy or
                would like to find out more, click the button below the partner and you'll be taken
                to their site. You'll then be able to use either your STUDENT CODE or PARTNER CODE.
                What's the difference?
              </p>
              <p>
                STUDENT CODE - You'll be asked to provide this code when ordering a custom item via
                email, contact form or phone.
              </p>
              <p>
                PARTNER CODE - Use this when shopping on a Partner's online store and they're able
                to accept a coupon code during checkout.
              </p>
            </flow>
          </flow>
        </flow>
      </template>
      <template #right>
        <video-player
          :video="trailer"
          :resume-at-last-timestamp="false"
          :preview="false"
          :guest="guest"
        />
      </template>
    </content-two-columns>
  </page-section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentTwoColumns from '../../../core/compositions/ContentTwoColumns.vue';
import Flow from '../../../core/compositions/Flow.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import VideoPlayer from '../../../generic/video-player/VideoPlayer.vue';
import {Video} from '../../../../backend/video/video-types';

export default defineComponent({
  components: {
    PageSection,
    ContentTwoColumns,
    Flow,
    StandardHeading,
    VideoPlayer
  },
  props: {
    guest: {type: String, required: true},
    preview: {type: Boolean, required: true},
    studentCode: {type: String, default: undefined}
  },
  setup() {
    const trailer: Readonly<Video> = {
      videoServiceProvider: 'vimeo',
      videoId: '850834055',
      videoType: 'trailer',
      videoDuration: ''
    };

    return {
      trailer
    };
  }
});
</script>
