<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <div class="flex justify-center gap-fl-xl">
          <link-button :label="'Profile'" :color="'mid'" :url="'/profile'" />
          <link-button :label="'Email'" :color="'mid'" :url="'/profile/email'" />
          <link-button :label="'Password'" :color="'white'" :url="'/profile/password'" />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <content-entry-header :center="true">Update my Password</content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <div class="flex flex-col gap-fl-sm">
          <label for="passwordCurrentPassword" class="text-bright text-fl-3xl"
            >Current Password</label
          >
          <div class="flex flex-col gap-fl-2xs">
            <input
              id="passwordCurrentPassword"
              v-model="currentPassword"
              class="w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
              type="password"
            />
            <link-button
              :url="'/reset-password'"
              :underline="true"
              :justify="'justify-start'"
              :label="'Forgot your password?'"
            />
          </div>
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <div class="flex flex-col gap-fl-sm">
          <label for="passwordNewPassword" class="text-bright text-fl-3xl">New Password</label>
          <input
            id="passwordNewPassword"
            v-model="newPassword"
            class="w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
            type="password"
          />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <standard-button
          :disabled="saveDisabled"
          :on-click="saveProfile"
          :border="true"
          :label="'Update Password'"
        />
      </content-column>
    </page-section>

    <page-section :margin-top="'xs'">
      <content-column :width="'narrow'">
        <div v-if="error !== undefined" class="text-primary">
          {{ error }}
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import LinkButton from '../../../../core/button/LinkButton.vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {
  useNotificationPopup,
  standardNotification
} from '../../../../vue-composition/notification-popup/notification-popup';
import {updateProfile} from '../../edit-profile-utils';
import {redirectNoHistory} from '../../../../../utils/url';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    StandardButton,
    LinkButton
  },
  props: {
    result: {type: String, required: true},
    email: {type: String, default: ''},
    userId: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const newPassword = ref('');
    const currentPassword = ref('');

    const error = ref<string | undefined>(undefined);

    const saveDisabled = computed(() => {
      return newPassword.value.length <= 6 && currentPassword.value.length <= 6;
    });

    const keyup = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        await saveProfile();
      }
    };

    onMounted(async () => {
      if (props.result === 'success') {
        notification.notify(standardNotification('Password updated.'));
      }
    });

    const saveProfile = async () => {
      const profile = {
        userId: props.userId,
        newPassword: newPassword.value,
        currentPassword: currentPassword.value
      };
      loader.setLoading(true, 'Updating your profile');
      const success = await updateProfile(profile).catch((_error: Readonly<Error>) => {
        loader.setLoading(false);
        error.value = _error.message;
        // Clear password fields (for security reasons); regardless of whether the
        // update was successful or not.
        currentPassword.value = '';
        newPassword.value = '';
        return;
      });

      if (success) {
        redirectNoHistory('/profile/password?result=success');
      }
    };

    return {
      saveDisabled,
      keyup,
      saveProfile,
      notification,
      currentPassword,
      newPassword,
      error
    };
  }
});
</script>
