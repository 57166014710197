<template>
  <page-content v-if="show !== undefined">
    <page-section v-if="trails !== undefined">
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header>{{ show.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="show" />
          <content-entry-header-post-date :content-entry="show" />
          <content-entry-header-bookmark
            :display="'linkIcon'"
            :guest="guest"
            :content-entry="show"
            :bookmark="bookmark"
          />
          <content-entry-header-campus :guest="guest" :content-entry="show" :display="'linkIcon'" />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-video :guest="guest" :content-entry="show" :video-type="'content'" />
      </content-column>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <div class="text-fl-3xl text-white">{{ show.summary }}</div>
      </content-column>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <content-entry-resource-list :heading="'Resources'" :guest="guest" :content-entry="show" />
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

import Cluster from '../../core/compositions/Cluster.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderPostDate from '../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryVideo from '../../content-entry/partials/body/ContentEntryVideo.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import {Video} from '../../../backend/video/video-types';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {getVideoDuration} from '../../../backend/video/video-query';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import ContentEntryResourceList from '../../content-entry/partials/body/ContentEntryResourceList.vue';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryVideo,
    ContentEntryResourceList,
    ContentEntryHeader,
    ContentEntryHeaderTutors,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    Cluster
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    sectionHandle: {type: String, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const show = ref(undefined as Readonly<ContentEntry> | undefined);
    const video = ref(undefined as Readonly<Video> | undefined);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);
      const shows = await fetchContentEntries(
        contentEntries().section([props.sectionHandle]).slug([props.slug])
      );
      show.value = shows[0];
      if (show.value !== undefined) {
        trails.value = await useBreadcrumbTrails(show.value, props.entryBreadcrumbTrails);
        video.value = show.value.videos[0];
        await bookmark.init();
      }
      loader.setLoading(false);
    });

    const videoDuration = computed(() => {
      if (video.value?.videoDuration !== undefined && video.value?.videoDuration !== null) {
        return getVideoDuration(video.value);
      }
      return undefined;
    });

    return {
      show,
      trails,
      video,
      videoDuration,
      bookmark
    };
  }
});
</script>
