<template>
  <div v-if="spotColor !== undefined">
    <page-section :margin-top="'lg'">
      <content-column>
        <div class="flex gap-fl-sm item-center">
          <font-awesome-icon
            v-if="!levelUnlocked"
            :icon="iconLock"
            class="text-fl-7xl text-mid"
          ></font-awesome-icon>
          <h2 :class="`text-fl-7xl ${levelUnlocked ? 'text-white' : 'text-mid'} font-semibold`">
            Study Pieces
          </h2>
        </div>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <standard-divider class="border-mid" />
      </content-column>
    </page-section>

    <page-section v-if="!guest && levelEntryQuiz !== undefined">
      <content-column>
        <div
          class="flex flex-wrap justify-center items-center rounded-lg bg-dark p-fl-lg gap-fl-sm"
        >
          <standard-heading :size="3" :center="true"
            >Unlock Study Pieces
            <font-awesome-icon :icon="iconArrowRight" class="pl-fl-xs"></font-awesome-icon>
          </standard-heading>
          <standard-button
            :url="levelEntryQuiz.url"
            :size="'lg'"
            :label="'Start Quiz'"
            :spot-color="spotColor"
          />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <div class="flex flex-wrap sm:flex-nowrap justify-between gap-fl-xs-3xl">
          <div class="flex flex-col gap-fl-sm">
            <div class="flex gap-fl-xs items-center">
              <players-path-logo :disabled="!levelUnlocked" />
              <div
                :class="`text-fl-3xl ${levelUnlocked ? 'text-white' : 'text-mid'} font-semibold`"
              >
                {{ levelTitle }}
              </div>
            </div>
            <p class="text-fl-xl text-mid">
              Learn and master at least 3 of the following song study pieces to complete this
              pathway.
            </p>
          </div>
          <beginner-journey-song-checkmarks
            :disabled="!levelUnlocked"
            :pieces-completed="piecesCompleted"
            :spot-color="spotColor"
          />
        </div>
      </content-column>
    </page-section>

    <page-section
      v-if="level !== undefined && level.playersPathPieces.length > 0"
      :margin-top="'sm'"
    >
      <players-path-piece-list
        :guest="guest"
        :preview="learningPathway.preview"
        :pieces="level.playersPathPieces"
        :quiz-statuses="quizStatuses"
        :spot-color="spotColor"
        :bookmark="bookmark"
      />
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed, onMounted, ref} from 'vue';

import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../../core/standard-divider/StandardDivider.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import PlayersPathPieceList from '../../../players-path/partials/PlayersPathPieceList.vue';
import PlayersPathLogo from '../../../players-path/partials/PlayersPathLogo.vue';
import BeginnerJourneySongCheckmarks from './BeginnerJourneySongCheckmarks.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons/faArrowRight';
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock';
import {LearningPathway} from '../../../../../backend/content/learning-pathways/learning-pathways-types';
import {
  getPlayersPathLevelNumberFromSlug,
  playersPathLevelSpotColor
} from '../../../players-path/utils';
import {PlayersPathLevel} from '../../../../../backend/content/players-path/players-path-types';
import {getPlayersPathLevelBySlug} from '../../../../../backend/content/players-path/players-path-query';
import {areQuizzesComplete} from '../../../../../backend/content/quiz/quiz-query';
import {Quiz, QuizCollectionStatus} from '../../../../../backend/content/quiz/quiz-types';
import {numberOfCompletedPieces} from '../../../../../backend/content/players-path/players-path-query-utils';
import {BookmarkComposition} from '../../../../vue-composition/bookmark/types';
import {getPlayersPathLevelForLearningPathway} from '../../utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    FontAwesomeIcon,
    StandardButton,
    StandardDivider,
    PlayersPathPieceList,
    PlayersPathLogo,
    BeginnerJourneySongCheckmarks
  },
  props: {
    guest: {type: String, required: true},
    learningPathway: {type: Object as PropType<Readonly<LearningPathway>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const iconArrowRight = computed(() => faArrowRight);
    const iconLock = computed(() => faLock);

    const levelSlug = computed(() => {
      return getPlayersPathLevelForLearningPathway(props.learningPathway.slug);
    });

    const levelNumber = computed(() => {
      if (levelSlug.value === undefined) {
        return undefined;
      }
      return getPlayersPathLevelNumberFromSlug(levelSlug.value);
    });

    const levelTitle = computed(() => {
      if (levelSlug.value === undefined) {
        return undefined;
      }
      return `Level 0${levelNumber.value}`;
    });

    const spotColor = computed(() => {
      if (levelNumber.value === undefined) {
        return undefined;
      }
      return playersPathLevelSpotColor(levelNumber.value);
    });

    const level = ref<PlayersPathLevel | undefined>(undefined);
    const piecesCompleted = ref(0);
    const quizStatuses = ref<QuizCollectionStatus>({});
    const levelComplete = ref(false);
    const levelUnlocked = ref(false);
    const levelEntryQuiz = ref<Readonly<Quiz> | undefined>(undefined);

    onMounted(async () => {
      if (levelSlug.value === undefined) {
        return;
      }
      level.value = await getPlayersPathLevelBySlug(levelSlug.value);
      if (level.value === undefined) {
        return;
      }
      quizStatuses.value = await areQuizzesComplete(level.value.quizzes);
      levelUnlocked.value = quizStatuses.value[level.value.quizzes[0].id];
      if (!levelUnlocked.value) {
        levelEntryQuiz.value = level.value.quizzes[0];
      }
      piecesCompleted.value = numberOfCompletedPieces(level.value, quizStatuses.value);
      levelComplete.value = piecesCompleted.value >= 3;
    });

    return {
      iconArrowRight,
      iconLock,
      spotColor,
      levelTitle,
      levelUnlocked,
      levelEntryQuiz,
      level,
      quizStatuses,
      piecesCompleted
    };
  }
});
</script>
