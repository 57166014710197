import {useStore} from '../../../store/store';

export type LockRevokedCallback = () => Promise<void>;
export interface Sync {
  onBeforeUnmount: () => void;
  lock: () => void;
}

/**
 * Return a Vue composition that implements a simple kind of
 * cross-component synchronisation.
 *
 * It is useful if you have multiple components on a page that
 * all have continouous behaviour and only want one of them to
 * be active at a time. For example, if you have multiple video
 * players on the same page, you probably want to disallow
 * playing several videos at one.
 *
 * The composition also supports having multiple independent
 * "groups" of sync:ed components on the same page. For example,
 * only show one dropdown menu at a time PLUS only play one video
 * at a time).
 *
 * Usage:
 *
 * setup() {
 *   // All components that we want to include in this synchronised behaviour must
 *   // register themselves with this group.
 *   const groupId = 'video-player';
 *
 *   // All components participating in the synchronised behaviour (in the 'video-player'
 *   // group) must provide a group-unique id. For example, a video player component
 *   // could pass the id of the video on Vimeo or YouTube as the id.
 *   const myUniqueId = ...;
 *
 *   let sync = undefined;
 *
 *   onMounted(() => {
 *     // Initialize sync behaviour when component is mounted.
 *     sync = useSync(groupId, myUniqueId, () => {
 *       // This callback is called when the lock is "grabbed" by another component
 *       // (in the 'video-player' group).
 *       stopContinuousBehavior();
 *     });
 *   });
 *
 *   const startContinuousBehavior() => {
 *     // Call sync.lock() to "grab" the lock from other components (in the 'video-player' group).
 *     if (sync) {
 *       sync.lock();
 *     }
 *   }
 *
 *   // IMPORTANT! Don't forget to de-register the sync behaviour when the component is unmounted.
 *   onBeforeUnmount(() => {
 *     if (sync) {
 *       sync.onBeforeUnmount();
 *     }
 *   });
 * }
 *
 */
export function useSync(
  groupId: string,
  actorId: string,
  onLockRevoked: LockRevokedCallback
): Readonly<Sync> {
  const store = useStore();

  const vuexUnwatchFunc = store.watch(
    state => state.sync.activeActorIds,
    async () => {
      if (store.state.sync.activeActorIds[groupId] !== actorId) {
        await onLockRevoked();
      }
    }
  );

  const onBeforeUnmount = () => {
    vuexUnwatchFunc();
  };

  const lock = () => {
    store.dispatch('sync/setActiveActorId', {groupId, actorId});
  };

  return {
    onBeforeUnmount,
    lock
  };
}
