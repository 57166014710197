<template>
  <cluster v-if="durationString.length > 0" :gap="'gap-1'" class="text-mid">
    <font-awesome-icon :icon="icon"></font-awesome-icon>
    <div>{{ durationString }}</div>
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Cluster from '../../../../core/compositions/Cluster.vue';
import {HMSToString, secToHMS} from '../../../../../utils/duration';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faClock} from '@fortawesome/pro-regular-svg-icons/faClock';

export default defineComponent({
  components: {
    Cluster,
    FontAwesomeIcon
  },
  props: {
    duration: {type: Number, required: true}
  },
  setup(props) {
    const durationString = computed(() => {
      if (props.duration !== undefined) {
        return HMSToString(secToHMS(props.duration));
      }
      return '';
    });
    const icon = computed(() => faClock);
    return {
      durationString,
      icon
    };
  }
});
</script>
<style scoped>
.content-entry-header-duration {
  color: var(--color-mid-deep);
  user-select: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
