<template>
  <hierarchical-menu-item
    :guest="guest"
    :item="menu"
    :expanded-item="expandedItem"
    :on-expand="onExpand"
  />
</template>

<script lang="ts">
import {PropType, ref} from 'vue';
import HierarchicalMenuItemComponent from './HierarchicalMenuItem.vue';
import {HierarchicalMenuItem} from './hierarchical-menu-types';

export default {
  components: {
    'hierarchical-menu-item': HierarchicalMenuItemComponent
  },
  props: {
    guest: {type: String, required: true},
    menu: {type: Object as PropType<HierarchicalMenuItem>, required: true}
  },
  setup() {
    // The sub-menu item that is currently expanded.
    let expandedItem = ref(undefined as HierarchicalMenuItem | undefined);

    // Set the specified item to be the currently expanded item (or none).
    const onExpand = (item: Readonly<HierarchicalMenuItem> | undefined) => {
      expandedItem.value = item;
    };

    return {onExpand, expandedItem};
  }
};
</script>
