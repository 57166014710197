<template>
  <!-- Column grid -->
  <div v-if="style !== undefined" :style="style" :class="`grid ${rowGapClass}`">
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, inject, ComputedRef, PropType, provide} from 'vue';
import {gridColumnCSS, gridTemplateColumnsCSS} from './internals/css';
import {ColumnLayoutConfig, ColumnArea} from './types';

/**
 * Column grid.
 *
 * This is the one of the Vue components that underpin the entire SBL frontend
 * design system. (The other can be found in this folder.)
 *
 * The design of the SBL website is largely based on a "column grid", popular layout
 * system used by many graphic desginers. See, e.g.,
 *
 * https://m2.material.io/design/layout/responsive-layout-grid.html
 * https://uxplanet.org/grids-in-graphic-design-a-quick-history-and-5-top-tips-29c8c0650d18
 *
 * Conceptually, the content area of an SBL page (which sits between a left and
 * right margin) is broken into a number of columns with gutters between them.
 *
 * We use 4 layout columns for narrow screens and 12 for all other screen sizes.
 *
 * To position a Vue component horizontally inside the content area, we specify the
 * layout column where the component should start; to set the width of the component,
 * we specify the number of layout columns that it should span.
 *
 * Use the <margins> component to add page margins, and provide a space for the
 * content area.
 *
 * Use the <content> component to create a space that covers a specified number of
 * columns in the layout.
 *
 * If you need to divide a space into sub-columns, use the <column-grid> component
 * recursively (with <content> components as "leaves" in the hierarchy). For example,
 * you might have a component that spans columns 3 through 7 in the main page layout,
 * but is further divided into two sub-components that span columns 1 through 2 and
 * columns 3 through 4 inside that 4-column sub-area.
 *
 * If you have a "sub-area" you would like to divide into a number of design system
 * columns, but for some reason you cannot build a complete hierarchy of <margin>
 * and <column-grid> components, you can use this component in a "stand-alone"
 * fashion: leave the start/span/end/area properties be undefined and instead set
 * the 'columns' to the desired number of layout columns. This creates a CSS grid
 * where you can position content using <content> components (or divide it further
 * with <column-grid> components) as usual. An example of where this could be useful
 * is if you may have two "content areas" that sit side by side on a page with
 * vertical content that must flow independently in each area.
 *
 * In general, the vertical position and size of elements is not necessarily tied
 * to a design system grid,  and therefore less strictly controlled. The reason is
 * that the content area must "work" in viewports of (at least in theory) any size.
 * Most components require more vertical space to fit inside their specified
 * layout area when the viewport shrinks. Conversely, most components need to
 * deal with excess empty space when the viewport grows. Therefore, the set of
 * tools we need to control the vertical aspects of layout is more varied. Some
 * components work well in a grid where we specify rows (in addition to the
 * core column layout grid), whereas other components do not.
 *
 * NOTE: Working with the CSS grid produced by this component should cover most
 * (horizontal) layout situations. But if you do need to position elements explicitly
 * (e.g., via the CSS 'position-left' and 'position-top' properties) there is a Vue
 * composition (in the 'src/vue-compositions' folder) called 'column-layout' that
 * might help.
 */
export default defineComponent({
  props: {
    /**
     * If not undefined, set this component to span the specified area in the
     * parent column grid.
     */
    area: {type: Object as PropType<ColumnArea>, default: undefined},
    /**
     * If specified, this is the first column of this component (in the parent
     * column grid.
     */
    start: {type: Number, default: 1},
    /**
     * If specified, this the number of columns that this component spans (in
     * the parent column grid. Set to -1 to cover all remaning columns (counting
     * from the column specified by the start prop).
     */
    span: {type: Number, default: -1},
    /**
     * If specified, this the last column (in the parent column grid) that
     * this component spans. Set to -1 to cover all remaning columns (counting
     * from the column specified by the start prop).
     */
    end: {type: Number, default: undefined},

    /**
     * If this component is used without a parent <margin> component, this property
     * specifies the number of layout columns to use.
     */
    columns: {type: Number, default: undefined},

    /** The gap between rows in this grid. If undefined, use current column gutter width. */
    rowGap: {type: String, default: undefined},

    // ??? This prop simplifies debugging; should be removed.
    name: {type: String, default: '<unnamed>'}
  },
  setup(props) {
    /*
      Get the type of the parent component; it is 'margins' for the <margins>
      component and 'columnGrid' for <column-grid> components.
    */
    const parentType = inject<string>('parentType');
    if (parentType === undefined) {
      throw new Error(
        'Parent type is undefined. Did you forget to wrap your column layout in a <margins> component?'
      );
    }
    provide('parentType', 'columnGrid');

    /*
      Get the current layout system config; it contains current settings
      for margin, column, and gutter widths, etc. It also contains the
      number of columns that will span the page (4 for the 'narrow' viewport,
      or 12 for all other viewports).
    */
    const config = inject<ComputedRef<Readonly<ColumnLayoutConfig>>>('layoutSystemConfig');
    if (config === undefined) {
      throw new Error(
        'No layout system config. Did you forget to wrap your column layout in a <margins> component?'
      );
    }

    /*
      Get the number of design system columns that the parent component spans.
    */
    const parentColumns = computed(() => {
      const parentColumnInject = inject<ComputedRef<number>>('parentColumns');
      if (parentColumnInject !== undefined && parentType !== 'margins') {
        return parentColumnInject.value;
      }
      return config.value.layoutSystemColumns;
    });

    /*
      Compute the number of desig system columns that this component spans. The value
      is passed on to the children of this component via the Vue provide/inject
      mechanism.
    */
    let childColumns = computed(() => {
      if (props.columns !== undefined) {
        return props.columns;
      } else if (props.area !== undefined) {
        if (props.area.span > 0) {
          return props.area.span;
        }
        return parentColumns.value;
      } else if (props.end !== undefined) {
        throw new Error('Unsupported');
      } else {
        if (props.span > 0) {
          return props.span;
        }
        return parentColumns.value;
      }
    });
    provide('parentColumns', childColumns);

    /*
      Get the 'grid-column' CSS property that positions this component
      in the parent's CSS grid (if such a parent CSS grid exists).
    */
    const gridColumn = computed(() => {
      /*
        See if a <margins> CSS grid is our parent. If so, we want to span the central
        column that it has provided for us. (The <margins> component has a two-level
        CSS grid hierarchy; The first CSS grid has additional columns for the page margins.
        Its child is a <column-grid> component, i.e. an instance of this component.)
      */
      if (parentType === 'margins' || parentType === undefined) {
        return config.value.layoutSystemColumns === 4
          ? 'grid-column: 2 / span 8;'
          : 'grid-column: 2 / span 24;';
      }

      /*
        See if we are creating a new column hierarchy (with no CSS grid in the parent).
        (This can be used to add design system columns inside, e.g., a flexbox component.)
        If so, we don't want to specify any setting at all for the 'grid-column' CSS property,
        since there is no parent CSS grid.
      */
      if (props.columns !== undefined) {
        return '';
      }

      /*
        Our parent component provides a CSS grid; use the specified start/span values
        from the props to position ourselves (or use defaults).
      */
      const start = props.area !== undefined ? props.area.start : props.start;
      const span = props.area !== undefined ? props.area.span : props.span;
      return gridColumnCSS(start, span, props.end);
    });

    /*
      Compute the 'grid-template-columns' CSS property that sets up our CSS grid
      so that our child components can position themselves.
    */
    const templateColumns = computed(() => {
      if (
        parentType === undefined ||
        config.value === undefined ||
        parentColumns.value === undefined
      ) {
        return undefined;
      }

      /*
        If we are creating a new design system column grid without a parent CSS grid,
        return the specified number of design system columns, without margins.
      */
      if (props.columns !== undefined) {
        return gridTemplateColumnsCSS(config.value, props.columns, false);
      }

      /*
        If we are the <column-grid> instance that sits directly beneath the <margins> component,
        create a CSS grid with the number of design system columns specified by the current
        configuration. (This is either 12 or 4, depending on the current viewport width.)
      */
      if (parentType === 'margins') {
        return gridTemplateColumnsCSS(config.value, config.value.layoutSystemColumns, false);
      }

      /*
        We are an "ordinary" column grid component in the design system hierarchy,
        so set the number of columns specified by the props.
      */
      let span = props.area !== undefined ? props.area.span : props.span;
      if (span < 0) {
        // We've been told to use the span from the start column through the remaining columns.
        span = parentColumns.value - props.start + 1;
      }
      return gridTemplateColumnsCSS(config.value, span, false);
    });

    /*
      Return the result as a HTML style. We cannot use Tailwind classes here,
      since Tailwind doesn't support dynamically generated class names (and
      specifying all possible combinations isn't feasible).
    */
    const style = computed(() => {
      if (templateColumns.value === undefined) {
        return undefined;
      }
      const rowGap = props.rowGap === undefined ? `row-gap:${config.value.maxGutterWidth}px` : '';
      return `${gridColumn.value};${templateColumns.value};${rowGap}`;
    });

    const rowGapClass = props.rowGap !== undefined ? props.rowGap : '';

    return {
      style,
      rowGapClass
    };
  }
});
</script>
