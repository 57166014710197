<template>
  <div
    class="flex flex-col items-center gap-fl-md bg-darkest rounded-lg select-none px-fl-md-xl py-fl-md-2xl"
  >
    <div class="flex flex-nowrap items-center">
      <!-- Pause/Play -->
      <div class="basis-1/3 flex flex-nowrap items-end mr-fl-lg">
        <audio-player-play :audio-player="audioPlayer" />
        <audio-player-reset :audio-player="audioPlayer" class="width-1/3" />
      </div>
      <!-- Timeline -->
      <div class="basis-2/3">
        <audio-player-timeline :audio-player="audioPlayer" />
      </div>
    </div>

    <!-- Options -->
    <div class="flex justify-center">
      <link-button
        :size="'sm'"
        :label="optionsLabel"
        :tooltip="'Toggle Options'"
        :color-enabled="'text-light'"
        :on-click="onToggleOptions"
      />
    </div>
    <div v-if="options" class="w-full flex flex-col flex-nowrap gap-fl-xs">
      <audio-player-playback-speed-narrow :audio-player="audioPlayer" />
      <standard-divider class="border-dark" />
      <audio-player-repeat-narrow :audio-player="audioPlayer" />
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import AudioPlayerPlay from '../partials/AudioPlayerPlay.vue';
import AudioPlayerReset from '../partials/AudioPlayerReset.vue';
import AudioPlayerTimeline from '../partials/AudioPlayerTimeline.vue';
import AudioPlayerPlaybackSpeedNarrow from '../partials/AudioPlayerPlaybackSpeedNarrow.vue';
import AudioPlayerRepeatNarrow from '../partials/AudioPlayerRepeatNarrow.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';
import {AudioPlayerComposition} from '../internals/utils';

export default defineComponent({
  components: {
    StandardDivider,
    AudioPlayerPlay,
    AudioPlayerReset,
    AudioPlayerTimeline,
    AudioPlayerPlaybackSpeedNarrow,
    AudioPlayerRepeatNarrow,
    LinkButton
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true}
  },
  setup() {
    const localStorage = useLocalStorageFlag('audio-player-options', true);
    const options = computed(() => {
      return localStorage.get();
    });
    const onToggleOptions = () => {
      localStorage.toggle();
    };
    const optionsLabel = computed(() => (options.value ? 'HIDE OPTIONS' : 'SHOW OPTIONS'));
    return {
      options,
      onToggleOptions,
      optionsLabel
    };
  }
});
</script>
