<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <content-entry-header>Member Discounts</content-entry-header>
      </content-column>
    </page-section>

    <member-discount-instructions :guest="guest" :preview="preview" :student-code="studentCode" />

    <member-discounts-grid
      :guest="guest"
      :preview="preview"
      :discounts="discounts"
      :student-code="studentCode"
    />
  </page-content>
</template>

<script lang="ts">
import {computed, onMounted, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import {getMemberDiscounts} from '../../../backend/content/member-discounts/member-discount-query';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import MemberDiscountInstructions from './partials/MemberDiscountInstructions.vue';
import MemberDiscountsGrid from './partials/MemberDiscountsGrid.vue';
import {MemberDiscount} from '../../../backend/content/member-discounts/member-discount-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

export default {
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    MemberDiscountInstructions,
    MemberDiscountsGrid
  },
  props: {
    guest: {type: String, required: true},
    userId: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const studentCode = computed(() => {
      if (props.userId.length > 0) {
        return `SBL${props.userId}`;
      } else {
        return undefined;
      }
    });

    const discounts = ref([] as ReadonlyArray<MemberDiscount>);
    onMounted(async () => {
      loader.setLoading(true);
      discounts.value = await getMemberDiscounts();
      loader.setLoading(false);
    });

    /*
      ### If all member discounts are in preview mode, then display the entire
      page in preview mode (i.e., do not display the SBL student code). In the
      future, we may want to do something more elaborate.
    */
    const preview = computed(() => {
      return discounts.value.every(discount => {
        return discount.preview;
      });
    });

    return {studentCode, discounts, preview};
  }
};
</script>
