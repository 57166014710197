<template>
  <div
    :class="`${fontSize} w-full font-semibold ${textColor} group-hover:text-white`"
    aria-label="title"
  >
    {{ title }}
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  props: {
    title: {type: String, required: true},
    fontSize: {type: String, default: 'text-fl-lg'},
    textColor: {type: String, default: 'text-light'}
  },
  setup() {
    return {};
  }
});
</script>
