import {Lesson} from '../../../backend/content/lesson/lesson-types';
import {MediaBrowserItem} from '../../generic/media-browser/media-browser-types';
import {ProgressComposition} from '../../vue-composition/progress/types';

export function lessonsToMediaBrowserItems(
  lessons: ReadonlyArray<Lesson>,
  currentLesson: Readonly<Lesson> | undefined,
  progress: Readonly<ProgressComposition>
) {
  let currentModule: string | undefined = undefined;

  const items = lessons.reduce((accum: Array<MediaBrowserItem>, lesson) => {
    if (lesson.lessonModule !== undefined && lesson.lessonModule !== null) {
      if (currentModule !== lesson.lessonModule) {
        accum.push({
          id: undefined,
          title: lesson.lessonModule,
          complete: undefined,
          highlight: false
        });
        currentModule = lesson.lessonModule;
      }
    }
    let highlight = false;
    if (currentLesson !== undefined) {
      highlight = lesson.id === currentLesson.id;
    }
    const complete = progress.siblingProgress.value[lesson.id] === 100;
    accum.push({
      url: lesson.url,
      id: lesson.id,
      title: lesson.title,
      complete,
      highlight
    });
    return accum;
  }, []);

  return items;
}
