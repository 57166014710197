<template>
  <div
    v-if="!preview && !guest"
    class="flex flex-row flex-wrap gap-fl-md justify-center items-center"
  >
    <div>This session is hosted outside SBL.</div>
    <standard-button
      :disabled="!webcastOnAir"
      :size="'sm'"
      :color="'primary'"
      :label="'Join here'"
      :url="hostUrl"
      :new-tab="true"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {WebcastComposition} from '../../vue-composition/webcast/types';

export default defineComponent({
  components: {
    StandardButton
  },
  props: {
    preview: {type: String, required: true},
    guest: {type: String, required: true},
    webcast: {type: Object as PropType<Readonly<WebcastComposition>>, required: true}
  },
  setup(props) {
    const hostUrl = computed(() => {
      if (props.webcast.webcast.value !== undefined) {
        const url = props.webcast.webcast.value.webcastExternalLink;
        if (url !== null && url.length > 0) {
          return url;
        }
      }
      return undefined;
    });
    const webcastOnAir = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return false;
      }
      return webcast.webcastOnAir;
    });

    return {
      webcastOnAir,
      hostUrl
    };
  }
});
</script>
