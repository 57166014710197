import {CraftSlug} from '../../craft/craft-types';
import {Quiz, QuizCollectionStatus, QuizCompletionDates, QuizQuestionOrAnswer} from './quiz-types';
import {httpPost, validatedHttpGet} from '../../http';
import {fetchContentEntries} from '../content-entry/content-entry-query';
import {craftUrl} from '../../../utils/url';
import {VALIDATION_BOOLEAN} from '../../validation';
import * as ContentEntryQueryBuilder from '../content-entry/content-entry-query-builder';
import * as QueryBuilder from '../../craft/query/craft-query-builder';

// ### Replace validatedHttpGet/Post() in functions here with get/PostAction()

const QUIZ_QUESTIONS_AND_ANSWERS_FIELD = QueryBuilder.matrix('quizQuestionsAndAnswers')
  .required()
  .blocks([
    QueryBuilder.block('quizQuestion').fields([
      QueryBuilder.plainText('quizQuestionTitle').required()
    ]),
    QueryBuilder.block('quizAnswer').fields([
      QueryBuilder.plainText('quizAnswerTitle').required(),
      QueryBuilder.lightswitch('quizAnswerIsCorrect').required()
    ])
  ]);

const QUIZ_OUTCOMES_FIELD = QueryBuilder.matrix('quizOutcomes')
  .required()
  .blocks([
    QueryBuilder.block('quizOutcome').fields([
      QueryBuilder.dropdown('quizOutcomeType', ['success', 'failure']).required(),
      QueryBuilder.plainText('quizOutcomeTitle').required(),
      QueryBuilder.plainText('quizOutcomeSummary').required(),
      ContentEntryQueryBuilder.contentEntries('quizOutcomeRedirect', []),
      QueryBuilder.plainText('quizOutcomeRedirectTitle')
    ])
  ]);

export const QUIZ_FIELDS = [
  ContentEntryQueryBuilder.contentEntries('quizPrerequisites', []),
  QueryBuilder.plainText('quizRequiredNumberOfCorrectAnswers').required(),
  QueryBuilder.plainText('quizNumberOfRequiredPrerequisites'),
  QUIZ_QUESTIONS_AND_ANSWERS_FIELD,
  QUIZ_OUTCOMES_FIELD
];

export async function getQuizBySlug(slug: CraftSlug): Promise<Readonly<Quiz> | undefined> {
  try {
    const quizzes = await fetchContentEntries<Quiz>(
      ContentEntryQueryBuilder.contentEntries()
        .section(['quizzes'])
        .slug([slug])
        .fields(QUIZ_FIELDS)
    );
    return quizzes[0];
  } catch (error) {
    throw new Error(`Could not fetch quiz: ${error}`);
  }
}

export function getNumberOfQuestions(quiz: Readonly<Quiz>): number {
  return quiz.quizQuestionsAndAnswers.filter(qa => {
    return qa.typeHandle === 'quizQuestion';
  }).length;
}

export function getQuestionWithAnswers(
  quiz: Readonly<Quiz>,
  index: number
): Array<QuizQuestionOrAnswer> | undefined {
  const questionIndicies = quiz.quizQuestionsAndAnswers
    .map((qa, index) => {
      return {qa, index};
    })
    .filter(qa => {
      return qa.qa.typeHandle === 'quizQuestion';
    })
    .map(qa => {
      return qa.index;
    });
  if (index >= questionIndicies.length) {
    return undefined;
  }
  const firstIndex = questionIndicies[index];
  let lastIndex = quiz.quizQuestionsAndAnswers.length;
  if (questionIndicies[index + 1] !== undefined) {
    lastIndex = questionIndicies[index + 1];
  }
  return quiz.quizQuestionsAndAnswers.slice(firstIndex, lastIndex);
}

export async function setQuizComplete(quiz: Readonly<Quiz>) {
  const url = craftUrl(`/actions/sbl-module/quiz/set-quiz-complete?entryId=${quiz.id}`);
  try {
    const {ok, status, bodyJson} = await httpPost(url, {});
    if (!ok) {
      throw new Error(`HTTP Post failed (${status}): ${bodyJson}`);
    }
  } catch (error) {
    throw new Error(`Set quiz complete error: ${error}`);
  }
}

export async function setQuizIncomplete(quiz: Readonly<Quiz>) {
  const url = craftUrl(`/actions/sbl-module/quiz/set-quiz-incomplete?entryId=${quiz.id}`);
  try {
    const {ok, status, bodyJson} = await httpPost(url, {});
    if (!ok) {
      throw new Error(`HTTP Post failed (${status}): ${bodyJson}`);
    }
  } catch (error) {
    throw new Error(`Set quiz incomplete error: ${error}`);
  }
}

export async function isQuizComplete(quiz: Readonly<Quiz>): Promise<boolean> {
  const url = craftUrl(`/actions/sbl-module/quiz/is-quiz-complete?entryId=${quiz.id}`);
  try {
    const response = await validatedHttpGet<boolean>(url, VALIDATION_BOOLEAN);
    return response;
  } catch (error) {
    throw new Error(`Get quiz status error: ${error}`);
  }
}

export async function areQuizzesComplete(
  quizzes: ReadonlyArray<Quiz>
): Promise<Readonly<QuizCollectionStatus>> {
  const url = craftUrl('/actions/sbl-module/quiz/are-quizzes-complete');
  try {
    // ??? Figure out a way to get Joi to validate the returned object correctly. Or return a plain array.
    const result = await httpPost(url, {entryIds: quizzes.map(q => q.id)});
    return result.bodyJson as Readonly<QuizCollectionStatus>;
  } catch (error) {
    throw new Error(`Get quiz statuses error: ${error}`);
  }
}

export async function getQuizCompletionDates(
  quizzes: ReadonlyArray<Quiz>
): Promise<Readonly<QuizCompletionDates>> {
  const url = craftUrl('/actions/sbl-module/quiz/get-quiz-completion-dates');
  try {
    // ??? Figure out a way to get Joi to validate the returned object correctly. Or return a plain array.
    const result = await httpPost(url, {entryIds: quizzes.map(q => q.id)});
    return result.bodyJson as Readonly<QuizCompletionDates>;
  } catch (error) {
    throw new Error(`Get quiz completion dates error: ${error}`);
  }
}
