<template>
  <progress-bar
    v-if="!guest"
    :progress="progressNumber"
    :label="barLabel"
    :spot-color="spotColor"
  />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import ProgressBar from '../../../../generic/progress-bar/ProgressBar.vue';
import {Progress} from '../../../../../backend/progress/progress-types';
import {ColorConfig} from '../../../../core/color-config';
import {ProgressComposition} from '../../../../vue-composition/progress/types';

export default defineComponent({
  components: {
    ProgressBar
  },
  props: {
    guest: {type: String, required: true},
    progress: {type: Object as PropType<ProgressComposition>, default: undefined},
    progressValue: {type: Number as PropType<Progress>, default: undefined},
    label: {type: String, default: 'Progress'},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const progressNumber = computed(() => {
      if (props.progressValue !== undefined) {
        return props.progressValue;
      }
      if (props.progress !== undefined) {
        return props.progress.progress.value;
      }
      throw new Error('No progress value provided');
    });

    const barLabel = computed(() => {
      return progressNumber.value === 100 ? 'Complete' : props.label;
    });
    return {
      barLabel,
      progressNumber
    };
  }
});
</script>
