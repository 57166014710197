<template>
  <page-content :mt="'mt-fl-2xl-3xl'">
    <page-section>
      <content-column>
        <standard-heading :size="1">Welcome to SBL!</standard-heading>
      </content-column>
    </page-section>

    <promo-box :location="'survey'" :promotions="promotions" :margin-top="'lg'" />

    <user-skill-level :selected-level="selectedLevel" :on-select="onSelect" />

    <page-section :margin-top="'lg'">
      <content-column>
        <div class="flex gap-fl-xl items-center">
          <standard-button :disabled="saveDisabled" :on-click="onSave" :label="'Continue'" />
          <link-button :on-click="onSkip" :label="'Skip'" :underline="true" :color="'mid'" />
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
('../../../core/standard-heading/StandardHeading.vue');
import StandardButton from '../../core/button/StandardButton.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import PromoBox from '../../promotions/components/promo-box/PromoBox.vue';
import UserSkillLevel from '../profile/pages/profile/user-skill-level/UserSkillLevel.vue';
import {usePromotions} from '../../vue-composition/promotion/promotion';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {
  clearSkillLevelForCurrentUser,
  setSkillLevelForCurrentUser
} from '../../../backend/user/user-query';
import {getUrlQueryParams, redirectWithHistory} from '../../../utils/url';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    UserSkillLevel,
    LinkButton,
    StandardButton,
    PromoBox
  },
  props: {
    guest: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);
    const loader = useFullScreenLoader();

    onMounted(async () => {
      loader.setLoading(true);
      await promotions.init();
      loader.setLoading(false);
    });

    const selectedLevel = ref<number | undefined>(undefined);
    const onSelect = (level: number) => {
      selectedLevel.value = level;
    };

    const redirect = () => {
      loader.setLoading(true);
      const redirectUrl = getUrlQueryParams().get('redirect');
      if (redirectUrl !== null) {
        redirectWithHistory(redirectUrl);
        return;
      }
      redirectWithHistory('/dashboard');
    };

    const onSave = async () => {
      loader.setLoading(true);
      if (selectedLevel.value === undefined) {
        await clearSkillLevelForCurrentUser('skippedSurvey');
      } else {
        await setSkillLevelForCurrentUser(selectedLevel.value + 1);
      }
      redirect();
    };
    const saveDisabled = computed(() => selectedLevel.value === undefined);

    const onSkip = async () => {
      loader.setLoading(true);
      await clearSkillLevelForCurrentUser('skippedSurvey');
      redirect();
    };

    return {
      promotions,
      selectedLevel,
      onSelect,
      onSave,
      saveDisabled,
      onSkip
    };
  }
});
</script>
