<template>
  <div class="p-fl-xs flex justify-center items-center">
    <font-awesome-icon :icon="icon" aria-hidden="true"></font-awesome-icon>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  setup() {
    const icon = computed(() => faCheck);
    return {icon};
  }
});
</script>
