<template>
  <centered-column class="p-6 rounded-[0.5rem] bg-darkest">
    <font-awesome-icon
      v-if="feature.icon !== undefined"
      :icon="feature.icon"
      class="text-3xl text-primary"
    ></font-awesome-icon>
    <div class="text-lg text-white font-semibold text-center">{{ feature.title }}</div>
    <div class="text-mid text-center">{{ feature.summary }}</div>
  </centered-column>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import CenteredColumn from '../../core/compositions/CenteredColumn.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
export default defineComponent({
  components: {
    CenteredColumn,
    FontAwesomeIcon
  },
  props: {
    feature: {type: Object, required: true}
  }
});
</script>
