<template>
  <div :id="offer.slug" :class="`py-fl-sm px-fl-sm flex justify-center ${borderBottom}`">
    <rainbow-button
      :id="offer.id"
      :class="`${highlight ? 'w-full' : 'w-fit'}`"
      :label="'Get Started'"
      :on-click="submit"
      :disabled="false"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';
import RainbowButton from '../../../partials/RainbowButton.vue';

export default defineComponent({
  components: {
    RainbowButton
  },
  props: {
    offer: {
      type: Object as PropType<Readonly<SignupOffer> | Readonly<AddOnOffer>>,
      required: true
    },
    submit: {type: Function as PropType<(id: string | undefined) => void>, required: true},
    forceBorders: {type: Boolean, default: false}
  },
  setup(props) {
    const highlight = computed(() => {
      return props.offer.offerHighlight;
    });
    const borderBottom = computed(() => {
      if (props.forceBorders || highlight.value) {
        return 'border-b border-x border-b-primary border-x-primary rounded-b-lg';
      }
      return '';
    });

    return {
      borderBottom,
      highlight
    };
  }
});
</script>
