<template>
  <div v-if="loading" :aria-hidden="true" @click.prevent="">
    <div class="flex gap-x-fl-2xs items-center">
      <div class="relative w-fl-md h-fl-md">
        <font-awesome-icon
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-fl-lg"
          :icon="icon"
          :transform="`rotate-${rotation}`"
        ></font-awesome-icon>
      </div>
      <div v-if="label" class="text-fl-lg">{{ label }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import {useLinearAnimation} from '../../vue-composition/animation/linear';
import {useStore} from '../../../store/store';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons/faCircleNotch';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    label: {type: String, default: undefined}
  },
  setup() {
    const anim = useLinearAnimation(1, true);
    const icon = computed(() => faCircleNotch);
    const rotation = computed(() => {
      return anim.phase.value * 360.0;
    });

    const store = useStore();
    const loading = computed(() => {
      const status = store.getters['loader/loading'];
      if (status) {
        anim.start();
      } else {
        anim.stop();
      }
      return status;
    });

    return {
      icon,
      rotation,
      loading
    };
  }
});
</script>
