import {areQuizzesComplete} from '../../../backend/content/quiz/quiz-query';
import {Quiz, QuizOutcome} from '../../../backend/content/quiz/quiz-types';

export async function prerequisitesCompleted(quiz: Readonly<Quiz>) {
  if (quiz.quizPrerequisites.length === 0) {
    return true;
  }

  const completionStatus = await areQuizzesComplete(quiz.quizPrerequisites as Array<Quiz>);
  const statuses = Object.values(completionStatus);
  const completed = statuses.reduce((accum: number, status) => {
    if (status === true) {
      return accum + 1;
    }
    return accum;
  }, 0);

  // By default, we require all prerequisites to be completed.
  let required = statuses.length;
  if (
    quiz.quizNumberOfRequiredPrerequisites !== null &&
    quiz.quizNumberOfRequiredPrerequisites !== undefined &&
    quiz.quizNumberOfRequiredPrerequisites.length > 0
  ) {
    required = Number.parseInt(quiz.quizNumberOfRequiredPrerequisites, 10);
  }
  return completed >= required;
}

export function getSuccessOutcome(quiz: Readonly<Quiz>): Readonly<QuizOutcome | undefined> {
  return quiz.quizOutcomes.find(outcome => {
    return outcome.quizOutcomeType === 'success';
  });
}

export function getFailureOutcome(quiz: Readonly<Quiz>): Readonly<QuizOutcome | undefined> {
  return quiz.quizOutcomes.find(outcome => {
    return outcome.quizOutcomeType === 'failure';
  });
}
