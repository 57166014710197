import {Ref, ref} from 'vue';

export type WizardComposition = {
  next: () => void;
  previous: () => void;
  reset: () => void;
  set: (step: number) => void;

  visible: (step: number) => boolean;
  disabled: (step: number) => boolean;

  _numberOfSteps: number;
  _currentStep: Ref<number>;
};

export function useWizard(numberOfSteps: number): Readonly<WizardComposition> {
  const currentStep = ref(0);

  return {
    next: () => {
      currentStep.value = Math.min(currentStep.value + 1, numberOfSteps - 1);
    },
    previous: () => {
      currentStep.value = Math.max(currentStep.value - 1, 0);
    },
    reset: () => {
      currentStep.value = 0;
    },
    set: (step: number) => {
      currentStep.value = step;
    },

    visible: (step: number) => {
      return currentStep.value === step;
    },
    disabled: (step: number) => {
      return currentStep.value !== step;
    },

    _numberOfSteps: numberOfSteps,
    _currentStep: currentStep
  };
}
