<template>
  <div class="flow flow-col gap-fl-lg">
    <div v-for="(ce, index) in items" :key="ce.id">
      <content-column :width="width" :justify="justify">
        <standard-divider v-if="index === 0" class="border-dark" />
      </content-column>
      <content-entry-item
        :guest="guest"
        :content-entry="ce"
        :config="config"
        :completed="completed[ce.id]"
        :progress="progress[ce.id]"
        :duration="duration[ce.id]"
        :unlocked="unlocked[ce.id]"
        :spot-color="spotColor"
        :bookmark="bookmark"
        :width="width"
        :justify="justify"
        :text-size="textSize"
        class="py-fl-lg"
      />
      <content-column :width="width" :justify="justify">
        <standard-divider class="border-dark" />
      </content-column>
    </div>
    <content-column :width="width" :justify="justify" class="py-fl-lg">
      <link-icon-button
        v-if="more"
        :on-click="onMore"
        :label="'Show More'"
        :icon="icon"
        :flip="true"
      />
    </content-column>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed, ref} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, DisplayConfig, DurationMap, UnlockedMap} from '../types';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentEntryItem from '../../representations/item/ContentEntryItem.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import {ColorConfig} from '../../../core/color-config';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {faAngleDown} from '@fortawesome/pro-light-svg-icons/faAngleDown';

export default defineComponent({
  components: {
    ContentColumn,
    StandardDivider,
    ContentEntryItem,
    LinkIconButton
  },
  props: {
    guest: {type: String, required: true},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    config: {type: Object as PropType<Readonly<DisplayConfig> | undefined>, default: undefined},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    textSize: {type: String, default: undefined},
    iconSize: {type: String, default: undefined},
    paginate: {type: Boolean, default: false}
  },
  setup(props) {
    const showAll = ref(!props.paginate);
    const more = computed(() => {
      return !showAll.value && props.contentEntries.length > 5;
    });
    const items = computed(() => {
      if (props.paginate && !showAll.value) {
        return props.contentEntries.slice(0, 5);
      }
      return props.contentEntries;
    });
    const icon = computed(() => faAngleDown);
    const onMore = () => {
      showAll.value = true;
    };
    return {
      items,
      icon,
      more,
      onMore
    };
  }
});
</script>
