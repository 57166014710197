<template>
  <variants>
    <template #md->
      <blockquote>
        <p class="mb-fl-3xs">
          {{ quoteOne }}
        </p>
        <cite
          ><strong>{{ citeOne }}</strong></cite
        >
      </blockquote>
      <blockquote>
        <p class="mb-fl-3xs">
          {{ quoteTwo }}
        </p>
        <cite
          ><strong>{{ citeTwo }}</strong></cite
        >
      </blockquote>
      <blockquote>
        <p class="mb-fl-3xs">
          {{ quoteThree }}
        </p>
        <cite
          ><strong>{{ citeThree }}</strong></cite
        >
      </blockquote>
      <blockquote>
        <p class="mb-fl-3xs">
          {{ quoteFour }}
        </p>
        <cite
          ><strong>{{ citeFour }}</strong></cite
        >
      </blockquote>
    </template>

    <template #lg+>
      <content-area class="mt-fl-lg" :start="5" :span="3">
        <blockquote class="mb-fl-xl">
          <p class="mb-fl-3xs">
            {{ quoteOne }}
          </p>
          <cite
            ><strong>{{ citeOne }}</strong></cite
          >
        </blockquote>
        <blockquote>
          <p class="mb-fl-3xs">
            {{ quoteTwo }}
          </p>
          <cite
            ><strong>{{ citeTwo }}</strong></cite
          >
        </blockquote>
      </content-area>
      <content-area class="mt-fl-lg" :start="8" :span="3">
        <blockquote class="mb-fl-xl">
          <p class="mb-fl-3xs">
            {{ quoteThree }}
          </p>
          <cite
            ><strong>{{ citeThree }}</strong></cite
          >
        </blockquote>
        <blockquote>
          <p class="mb-fl-3xs">
            {{ quoteFour }}
          </p>
          <cite
            ><strong>{{ citeFour }}</strong></cite
          >
        </blockquote>
      </content-area>
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import {
  quoteOne,
  citeOne,
  quoteTwo,
  citeTwo,
  quoteThree,
  citeThree,
  quoteFour,
  citeFour
} from '../utils/utils';

export default defineComponent({
  components: {
    Variants,
    ContentArea
  },
  setup() {
    return {quoteOne, citeOne, quoteTwo, citeTwo, quoteThree, citeThree, quoteFour, citeFour};
  }
});
</script>
