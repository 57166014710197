<template>
  <repel :gap="'gap-0'" :wrap="false" :class="'text-lightest'">
    <cluster :gap="'gap-fl-xs'" :wrap="false">
      <div class="text-fl-base uppercase">Repeat</div>
    </cluster>
    <link-button
      :color="'lightest'"
      :on-click="audioPlayer.toggleRepeat"
      :tooltip="'Toggle Repeat'"
      :label="audioPlayer.repeatLabel.value"
    />
  </repel>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import {faRepeat} from '@fortawesome/pro-solid-svg-icons/faRepeat';
import {AudioPlayerComposition} from '../internals/utils';

export default {
  components: {
    LinkButton,
    Cluster,
    Repel
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true}
  },
  setup() {
    const iconRepeat = computed(() => faRepeat);
    return {
      iconRepeat
    };
  }
};
</script>
