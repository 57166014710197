import * as QueryBuilder from '../craft/query/craft-query-builder';

export const OFFER_CONDITIONS_FIELD = QueryBuilder.matrix('offerConditions').blocks([
  QueryBuilder.block('signupDateInterval').fields([
    QueryBuilder.date('startDate'),
    QueryBuilder.date('endDate')
  ]),
  QueryBuilder.block('serverDateInterval').fields([
    QueryBuilder.date('startDate'),
    QueryBuilder.date('endDate')
  ]),
  QueryBuilder.block('accessPass').fields([
    QueryBuilder.dropdown('condition', ['true', 'false']).required(),
    QueryBuilder.categories('accessPasses').required(),
    QueryBuilder.dropdown('passStatus', [
      'active',
      'trial',
      'inactive',
      'pastDue',
      'any',
      'notInactive'
    ]).required()
  ]),
  QueryBuilder.block('visitorStatus').fields([
    QueryBuilder.dropdown('visitorStatus', ['guest', 'loggedIn']).required()
  ]),
  QueryBuilder.block('context').fields([
    QueryBuilder.dropdown('context', ['signup', 'account', 'paymentPage']).required()
  ])
]);
