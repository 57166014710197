<template>
  <variants>
    <template #sm->
      <content-column :width="'narrow'">
        <div class="flex flex-col gap-fl-lg">
          <div class="flex flex-col items-center gap-fl-sm">
            <standard-heading :wrap="true" :size="3">{{ piece.title }}</standard-heading>
            <cluster>
              <content-entry-header-tutors :content-entry="piece" :size="'sm'" />
              <content-entry-header-completed
                :progress="complete ? 100 : 0"
                :spot-color="spotColor"
              />
              <content-entry-header-beginner-journey :trails="trails" />
            </cluster>
          </div>
          <standard-divider class="border-dark" />
          <div class="flex flex-wrap gap-x-fl-lg gap-y-fl-sm justify-between items-center">
            <icon-label-button
              :icon="iconMusic"
              :label="'Tools'"
              :scroll-to="'practiceTools'"
              :tooltip="'Scroll to Study Piece Practice Tools'"
            />
            <icon-label-button
              :icon="iconInfoCircle"
              :label="'Info'"
              :scroll-to="'summary'"
              :tooltip="'Scroll to Study Piece Summary'"
            />
            <icon-label-button
              :icon="iconFiles"
              :label="'Files'"
              :scroll-to="'resources'"
              :tooltip="'Scroll to Study Piece Resources'"
            />
            <content-entry-header-campus :guest="guest" :content-entry="level" />
            <content-entry-header-bookmark
              :guest="guest"
              :content-entry="piece"
              :icon-only="true"
              :bookmark="bookmark"
            />
          </div>
        </div>
      </content-column>
    </template>
    <template #md+>
      <content-column :width="'wide'">
        <repel>
          <div class="flex flex-col gap-fl-sm">
            <standard-heading :size="3" :wrap="true">{{ piece.title }}</standard-heading>
            <cluster>
              <content-entry-header-tutors :content-entry="piece" :size="'sm'" />
              <content-entry-header-completed
                :progress="complete ? 100 : 0"
                :spot-color="spotColor"
              />
              <content-entry-header-beginner-journey :trails="trails" />
            </cluster>
          </div>
          <div class="flex justify-end items-center gap-x-fl-lg-xl">
            <icon-label-button
              :icon="iconMusic"
              :label="'Tools'"
              :scroll-to="'practiceTools'"
              :tooltip="'Scroll to Study Piece Practice Tools'"
            />
            <icon-label-button
              :icon="iconInfoCircle"
              :label="'Info'"
              :scroll-to="'summary'"
              :tooltip="'Scroll to Study Piece Summary'"
            />
            <icon-label-button
              :icon="iconFiles"
              :label="'Files'"
              :scroll-to="'resources'"
              :tooltip="'Scroll to Study Piece Resources'"
            />
            <content-entry-header-campus :guest="guest" :content-entry="level" />
            <content-entry-header-bookmark
              :guest="guest"
              :content-entry="piece"
              :icon-only="true"
              :bookmark="bookmark"
            />
          </div>
        </repel>
      </content-column>
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import IconLabelButton from '../../../core/button/IconLabelButton.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderCompleted from '../../../content-entry/partials/header/metadata/ContentEntryHeaderCompleted.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {
  PlayersPathLevel,
  PlayersPathPiece
} from '../../../../backend/content/players-path/players-path-types';
import {ColorConfig} from '../../../core/color-config';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {faFiles} from '@fortawesome/pro-solid-svg-icons/faFiles';
import {faComments} from '@fortawesome/pro-solid-svg-icons/faComments';
import {faMusic} from '@fortawesome/pro-light-svg-icons/faMusic';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';

export default defineComponent({
  components: {
    ContentColumn,
    Variants,
    Repel,
    Cluster,
    StandardHeading,
    StandardDivider,
    IconLabelButton,
    ContentEntryHeaderTutors,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    ContentEntryHeaderCompleted,
    ContentEntryHeaderBeginnerJourney
  },
  props: {
    guest: {type: String, required: true},
    piece: {type: Object as PropType<Readonly<PlayersPathPiece>>, required: true},
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    heading: {type: String, required: true},
    complete: {type: Boolean, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true}
  },
  setup() {
    const iconInfoCircle = computed(() => faInfoCircle);
    const iconFiles = computed(() => faFiles);
    const iconComments = computed(() => faComments);
    const iconMusic = computed(() => faMusic);

    return {
      iconInfoCircle,
      iconFiles,
      iconComments,
      iconMusic
    };
  }
});
</script>
