<template>
  <div :class="`${borders} h-full w-full flex flex-col justify-center`">
    <checkmark v-if="cell.check" :class="`${checkmarkColor} text-fl-6xl text-bold`" />
    <div
      v-else-if="cell.feature !== undefined"
      class="px-fl-sm py-fl-2xs text-fl-lg text-black leading-[1.4]"
    >
      {{ cell.feature }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import Checkmark from './Checkmark.vue';
import {Feature} from '../utils';

export default defineComponent({
  components: {
    Checkmark
  },
  props: {
    cell: {type: Object as PropType<Readonly<Feature>>, required: true},
    forceBorders: {type: Boolean, default: false}
  },
  setup(props) {
    const borders = computed(() => {
      if (props.cell.offer === undefined) {
        return '';
      }
      return props.forceBorders || props.cell.offer.offerHighlight
        ? 'border-x border-x-primary'
        : '';
    });
    const checkmarkColor = computed(() => {
      if (props.cell.offer !== undefined) {
        return props.cell.offer.offerHighlight ? 'text-primary' : 'text-black';
      }
      return '';
    });

    return {
      borders,
      checkmarkColor
    };
  }
});
</script>
