import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {CraftId} from '../../../backend/craft/craft-types';
import {CraftSection} from '../../../backend/craft/section/section-types';
import {BreadcrumbTrail, NavigationPath} from './types';

export function getNextSibling<TYPE extends ContentEntry>(
  siblings: ReadonlyArray<TYPE>,
  id: CraftId
): Readonly<TYPE> | undefined {
  return siblings.find((_, index, array) => {
    if (array[index - 1] !== undefined) {
      return array[index - 1].id === id;
    }
  });
}

export function getPreviousSibling<TYPE extends ContentEntry>(
  siblings: ReadonlyArray<TYPE>,
  id: CraftId
): Readonly<TYPE> | undefined {
  return siblings.find((_, index, array) => {
    if (array[index + 1] !== undefined) {
      return array[index + 1].id === id;
    }
  });
}

/**
 * Convert a list of breadcrumb trail entries into a list of items
 * (title + URL) that represents a navigation path to the first entry
 * in the trail.
 *
 * Example: Given the trail
 *
 *  ["Introduction" (lesson), "Jazz Primer" (course), "Walking Bass" (LP)]
 *
 * this function would return
 *
 *  ["Jazz Primer", "Walking Bass", "Learning Pathways"]
 *
 */
export function breadcrumbTrailToNavigationPath(
  trail: Readonly<BreadcrumbTrail> | undefined,
  productSections: ReadonlyArray<CraftSection>
): Readonly<NavigationPath> {
  if (trail === undefined) {
    return [];
  }
  const topEntry = trail[trail.length - 1];
  const topSection = productSections.find(s => s.handle === topEntry.sectionHandle);
  if (topSection !== undefined) {
    // Construct the trail items.
    const result: NavigationPath = trail.slice(1).map(e => {
      return {title: e.title, url: e.url};
    });
    result.push({title: topSection.title, url: topSection.url});
    return result.reverse();
  }
  return [];
}
