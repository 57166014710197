<template>
  <button
    v-if="onClick !== undefined"
    :disabled="disabled"
    :class="`flex ${justify} items-center ${cfg} cursor-pointer select-none`"
    :title="labelConfig.tooltipText.value"
    :aria-label="labelConfig.ariaLabel.value"
    @click.prevent="clicked"
  >
    <div :class="`${text}`">{{ labelConfig.labelText }}</div>
  </button>
  <a
    v-else
    :href="url"
    :target="target"
    :class="`flex ${justify} items-center ${cfg} ${text} cursor-pointer select-none`"
  >
    {{ labelConfig.labelText }}
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {
  getClickFunc,
  getLinkColor,
  getLabel,
  ButtonColor,
  ButtonSize,
  getLinkIconDimensions,
  ButtonClickCallback
} from './implementation/utils';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {},
  props: {
    disabled: {type: Boolean, default: false},

    /** If true, set the width of the button to 100%. */
    expand: {type: Boolean, default: false},

    size: {type: String as PropType<ButtonSize>, default: 'md'},
    /** Use this to override the button size with a specific font size. */
    fontSize: {type: String, default: undefined},

    color: {type: String as PropType<ButtonColor>, default: 'mid'},

    label: {type: String, required: true},
    tooltip: {type: String, default: undefined},
    wrap: {type: Boolean, default: false},
    justify: {type: String, default: 'justify-center'},
    textAlign: {type: String, default: 'text-center'},
    underline: {type: Boolean, default: false},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: Number, default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined},
    /** If true, open the URL in a new tab. */
    newTab: {type: Boolean, default: false}
  },
  setup(props) {
    const clicked = getClickFunc(props.onClick, props.id, props.url);
    const color = computed(() => getLinkColor(props.disabled, props.color));
    const labelConfig = computed(() => getLabel(props.label, props.tooltip));
    const wrap = computed(() => (props.wrap ? 'flex-wrap' : 'flex-nowrap'));
    const underline = computed(() => (props.underline ? 'underline' : ''));
    const textSize = computed(() => {
      if (props.fontSize === undefined) {
        return getLinkIconDimensions(props.size, props.expand);
      }
      const widthFull = props.expand ? 'w-full h-full' : 'w-fit h-fit';
      return `${widthFull} ${props.fontSize}`;
    });

    const cfg = computed(() => {
      return `${textSize.value} ${color.value.textColor} ${color.value.bgColor} ${wrap.value}`;
    });

    const text = computed(() => {
      return `${wrap.value ? 'whitespace-normal' : 'whitespace-nowrap'} ${props.textAlign} ${
        underline.value
      }`;
    });

    const target = computed(() => {
      return props.newTab ? '_blank' : undefined;
    });

    return {
      cfg,
      labelConfig,
      text,
      clicked,
      target
    };
  }
});
</script>
