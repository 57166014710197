import {Ref, ref} from 'vue';
import {useLoader} from '../loader/loader';
import {paginatedAction} from './paginated-action';
import {Entry} from '../../../backend/craft/entry/entry-types';
import {countEntries, fetchEntries} from '../../../backend/craft/entry/entry-query';
import {CraftQueryBuilderEntries} from '../../../backend/craft/query/craft-query-builder-types';

export type FetchEntriesPaginatedComposition<T extends Entry> = {
  entries: Ref<ReadonlyArray<T>>;
  start: (query: Readonly<CraftQueryBuilderEntries>, onFinished?: () => void) => void;
};

/**
 * This Vue composition allows you to fetch a large number of entries
 * in batches. The fetching happens seamlessly "behind the scenes"; a computed
 * Vue ref is returned that contains an array of the entries fetched thus far.
 * (The ref is updated as new entries arrive.) The entries are guaranteed to
 * be in the same order as they would have been if they had been fetched all
 * at once via fetchEntries().
 */
export function usePaginatedEntryFetch<T extends Entry>(
  itemsPerPage: number = 12
): Readonly<FetchEntriesPaginatedComposition<T>> {
  // https://github.com/vuejs/core/issues/2136#issuecomment-908269949
  const entries = ref<ReadonlyArray<T>>([]) as Ref<ReadonlyArray<T>>;
  const loader = useLoader();

  const start = async (query: Readonly<CraftQueryBuilderEntries>, onFinished?: () => void) => {
    loader.setLoading(true);

    const total = await countEntries(query);
    let result: ReadonlyArray<T> = [];
    paginatedAction(
      total,
      itemsPerPage,
      async (offset, limit) => {
        const batch = await fetchEntries<T>(query.offset(offset).limit(limit));
        result = result.concat(batch);
        entries.value = result;
      },
      () => {
        loader.setLoading(false);
        if (onFinished) {
          onFinished();
        }
      }
    );
  };

  return {
    entries,
    start
  };
}
