import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';
import {ScreenWidthSwitch} from '../../../vue-composition/device-type/device-type';
import {CarouselCardSize} from '../carousel';

export const COLUMNS_PER_SLIDE: {[carouselCardSize: string]: ScreenWidthSwitch<number>} = {
  '2xs': {
    narrow: 2,
    sm: 3.5,
    md: 3,
    lg: 2.5,
    xl: 2.5,
    '2xl': 2
  },
  xs: {
    narrow: 4,
    sm: 5,
    md: 4,
    lg: 3.5,
    xl: 3,
    '2xl': 2.5
  },
  sm: {
    narrow: 4,
    sm: 6,
    md: 5,
    lg: 4,
    xl: 3,
    '2xl': 3
  },
  lg: {
    narrow: 4,
    sm: 7,
    md: 6,
    lg: 6,
    xl: 5,
    '2xl': 4
  }
};

export function getImageSizes(cardSize: CarouselCardSize): Readonly<ImgixImageResponsiveWidth> {
  switch (cardSize) {
    case '2xs':
      return {
        narrow: 250,
        sm: 200,
        md: 220,
        lg: 230,
        xl: 280,
        '2xl': 260
      };
    case 'xs':
      return {
        narrow: 520,
        sm: 280,
        md: 300,
        lg: 320,
        xl: 335,
        '2xl': 330
      };
    case 'sm':
      return {
        narrow: 520,
        sm: 330,
        md: 375,
        lg: 375,
        xl: 340,
        '2xl': 400
      };
    case 'lg':
      return {
        narrow: 520,
        sm: 400,
        md: 450,
        lg: 570,
        xl: 575,
        '2xl': 535
      };
  }
}
