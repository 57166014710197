<template>
  <div v-if="video !== undefined">
    <page-section v-if="heading !== undefined" :margin-top="'xl'">
      <content-column v-if="deviceType.largerThan('narrow')">
        <standard-heading :size="3">{{ heading }}</standard-heading>
      </content-column>
    </page-section>
    <page-section :margin-top="heading !== undefined ? 'sm' : 'xl'">
      <content-column>
        <video-player
          :preview="false"
          :guest="guest"
          :resume-at-last-timestamp="false"
          :video="video"
        />
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import VideoPlayer from '../../../generic/video-player/VideoPlayer.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {getFirstVideoOfType} from '../../../../backend/video/video-query';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

/**
 * A trailer video player for content entries.
 *
 * Does not update the user's history. Can be viewed by all users, including guests.
 */
export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    VideoPlayer
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, required: true},
    heading: {type: String, default: undefined}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const video = computed(() => {
      return getFirstVideoOfType(props.contentEntry.videos, 'trailer');
    });
    return {
      deviceType,
      video
    };
  }
});
</script>
