<template>
  <pill v-if="progress === 100" class="border-progress bg-progress text-black"> COMPLETE </pill>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Pill from '../../../../generic/pill/Pill.vue';

export default defineComponent({
  components: {
    Pill
  },
  props: {
    progress: {type: Number, required: true} // [0,100]
  },
  setup() {}
});
</script>
