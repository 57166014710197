import {AccessPass} from '../access-pass/access-pass-types';
import {getTrialDuration} from '../signup/checkout-query';
import {getAllSignupOffers} from './signup-offer-query';
import {AccessPassTrialDurations, SignupOffer} from './signup-offer-types';

/**
 * Check the current page url for a query parameter containing offer id:s.
 */
export function getQueryParamOfferIds(param: string = 'offer'): ReadonlyArray<string> {
  if (window.location.search.length > 0) {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamPlans = urlParams.get(param);
    if (queryParamPlans !== null) {
      return queryParamPlans.split(',');
    }
  }
  return [];
}

/**
 * Return an map that contains the default trial duration for default
 * Braintree subscription plan of the specified access passes. If an access
 * pass is not a subscription, then it will not be added to the returned map.
 */
export async function getTrialDurations(
  accessPasses: ReadonlyArray<AccessPass>
): Promise<AccessPassTrialDurations> {
  const result: AccessPassTrialDurations = {};
  for (let i = 0; i < accessPasses.length; i += 1) {
    const pass = accessPasses[i];
    if (pass.accessPassType !== 'subscription') {
      result[pass.id] = 0;
    } else {
      const payment = pass.accessPassPayments.find(
        payment =>
          payment.accessPassPaymentDefault && payment.accessPassPaymentProvider === 'braintree'
      );
      if (payment !== undefined) {
        const duration = await getTrialDuration(payment.accessPassPaymentId);
        if (duration !== null) {
          result[pass.id] = duration;
        }
      }
    }
  }
  return result;
}

async function getTrialDurationsForOffers(
  offers: ReadonlyArray<SignupOffer>
): Promise<ReadonlyArray<SignupOffer>> {
  const trialDurations = await getTrialDurations(offers.map(o => o.offerDestination[0]));
  return offers.map(o => {
    const offerDur = o.offerTrialDuration;
    if (offerDur === null) {
      return {
        ...o,
        offerTrialDuration: trialDurations[o.offerDestination[0].id]
      };
    }
    return o;
  });
}

/**
 * Return an array of all signup offers that should be presented on the signup oage.
 *
 * By default, this function returns all offers in the "Signup Offers" section in
 * Craft that are active and have their "Offer default" field set to TRUE. (They will
 * be presented in the order in which they are listed in the Craft section.)
 *
 * You can override the default list of offers by passing query parameters to the
 * sign-up URL, e.g.,
 *
 *     /sign-up?offer=12345,67890
 *
 * where the numbers are the IDs of the Signup Offer entries to use. Note that disabled
 * offers will be excluded even if you specify them in the query param list. The passes
 * will be presented in the order in which they appear in the query param list.
 */
export async function getSignupOffersForCurrentUser(): Promise<ReadonlyArray<SignupOffer>> {
  const allOffers = await getAllSignupOffers();

  // If there is no query parameter override, return the active default offers.
  const passes = getQueryParamOfferIds();
  if (passes.length === 0) {
    const active = allOffers.filter(offer => {
      return offer.offerIsActive && offer.offerDefault;
    });
    const withTrialDurations = await getTrialDurationsForOffers(active);
    return withTrialDurations;
  }

  // We have a query param override, so return all matching offers.
  const active = allOffers
    .filter(offer => {
      return passes.includes(offer.id) && offer.offerIsActive;
    })
    .sort((a, b) => {
      // Return the offers in the order they were provided in the query param.
      return passes.indexOf(a.id) - passes.indexOf(b.id);
    });
  const withTrialDurations = await getTrialDurationsForOffers(active);
  return withTrialDurations;
}
