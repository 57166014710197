<template>
  <margins v-if="areas !== undefined">
    <content-area v-for="(card, index) in cards" :key="index" :area="areas[index]">
      <dashboard-section-promo :key="card.url" :guest="guest" :config="card" />
    </content-area>
  </margins>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import DashboardSectionPromo from './DashboardSectionPromo.vue';
import {SectionPromoConfig} from './types';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {ColumnArea} from '../../../core/column-layout/types';

export default defineComponent({
  components: {
    Margins,
    ContentArea,
    DashboardSectionPromo
  },
  props: {
    guest: {type: String, required: true},
    cards: {type: Array as PropType<ReadonlyArray<SectionPromoConfig>>, required: true}
  },
  setup() {
    const areas = deviceTypeSwitch<Array<ColumnArea>>({
      narrow: [
        {start: 1, span: -1},
        {start: 1, span: -1},
        {start: 1, span: -1},
        {start: 1, span: -1},
        {start: 1, span: -1},
        {start: 1, span: -1}
      ],
      md: [
        {start: 1, span: 6},
        {start: 7, span: 6},
        {start: 1, span: 6},
        {start: 7, span: 6},
        {start: 1, span: 6},
        {start: 7, span: 6}
      ],
      xl: [
        {start: 1, span: 4},
        {start: 5, span: 4},
        {start: 9, span: 4},
        {start: 1, span: 4},
        {start: 5, span: 4},
        {start: 9, span: 4}
      ]
    });
    return {
      areas
    };
  }
});
</script>
