<template>
  <page-content v-if="lessons.length > 0">
    <page-section :margin-top="'xl'">
      <content-entry-section-header :width="'wide'" :center="false" :title="'The SBL Toolkit'" />
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <video-player
          :guest="''"
          :preview="false"
          :resume-at-last-timestamp="false"
          :video="trailer"
        />
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <call-to-action-button />
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <page-section-header :heading="'Lessons'" :width="'wide'" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-entry-items
        :guest="''"
        :content-entries="lessons"
        :config="listConfig"
        :bookmark="bookmark"
        :layout="''"
        :width="'wide'"
        :pagination="pagination"
        :card-image-width="cardImageWidth"
      />
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <feature-grid />
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <call-to-action-button />
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import PageSectionHeader from '../core/page/PageSectionHeader.vue';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import VideoPlayer from '../generic/video-player/VideoPlayer.vue';
import FeatureGrid from './partials/FeatureGrid.vue';
import CallToActionButton from './partials/CallToActionButton.vue';
import ContentEntrySectionHeader from '../content-entry/partials/section/ContentEntrySectionHeader.vue';
import {useFullScreenLoader} from '../vue-composition/loader/loader';
import {DisplayConfig} from '../content-entry/collections/types';
import {getToolkit} from '../../backend/toolkit/toolkit-query';
import {ContentEntry} from '../../backend/content/content-entry/content-entry-types';
import {useBookmark} from '../vue-composition/bookmark/bookmark';
import {Video} from '../../backend/video/video-types';
import {ImgixImageResponsiveWidth} from '../core/imgix-image/types';
import {usePagination} from '../vue-composition/pagination/pagination';
import ContentEntryItems from '../content-entry/collections/variants/ContentEntryItems.vue';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentColumn,
    VideoPlayer,
    FeatureGrid,
    CallToActionButton,
    ContentEntrySectionHeader,
    ContentEntryItems
  },
  setup() {
    const loader = useFullScreenLoader();
    const lessons = ref<ReadonlyArray<ContentEntry>>([]);
    const bookmark = useBookmark('1');

    const pagination = usePagination<ContentEntry>(computed(() => lessons.value));

    const trailer: Readonly<Video> = {
      videoServiceProvider: 'vimeo',
      videoId: '850834321',
      videoType: 'trailer',
      videoDuration: ''
    };

    onMounted(async () => {
      loader.setLoading(true);
      lessons.value = await getToolkit();
      loader.setLoading(false);
    });

    const listConfig: Readonly<DisplayConfig> = {
      tutors: false,
      levels: false,
      subjects: false,
      summary: false,
      bookmark: false
    };

    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 690,
      md: 360,
      lg: 350,
      xl: 310,
      '2xl': 370
    };

    return {
      trailer,
      lessons,
      listConfig,
      bookmark,
      cardImageWidth,
      pagination
    };
  }
});
</script>
