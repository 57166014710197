<template>
  <page-section v-if="message.value !== undefined && message.value.length > 0" :margin-top="'sm'">
    <content-column :width="width">
      <flow>
        <div v-if="label" class="text-primary text-fl-lg font-semibold">
          {{ label }}
        </div>
        <div class="text-primary text-sm">
          {{ error.message.value }}
        </div>
        <div v-if="cta" class="text-white text-sm">
          {{ cta }}
        </div>
      </flow>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import Flow from '../../core/compositions/Flow.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import {BackendErrorComposition} from '../../vue-composition/backend-error/backend-error';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Flow
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'narrow'},
    label: {type: String, default: undefined},
    cta: {type: String, default: undefined},
    error: {type: Object as PropType<BackendErrorComposition>, required: true}
  },
  setup(props) {
    const message = computed(() => {
      return props.error.message;
    });
    return {
      message
    };
  }
});
</script>
