import {Bookmark} from '../../../backend/bookmark/bookmark-types';
import {CraftId} from '../../../backend/craft/craft-types';
import {BookmarkNode} from './types';
import {
  addBookmark,
  deleteBookmark,
  updateBookmark,
  updateBookmarks,
  findAllDescendants,
  getDefaultBookmarks
} from '../../../backend/bookmark/bookmark-query';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {moveArrayElement} from '../../../utils/array';
import {removeMultipleSlashesFromUrl} from '../../../utils/url';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export function groupsEqual(a: Readonly<BookmarkNode>, b: Readonly<BookmarkNode>) {
  if (a.bookmark === undefined || a.bookmark === null) {
    return b.bookmark === undefined || b.bookmark === null;
  }
  if (a.bookmark !== undefined && a.bookmark !== null) {
    if (b.bookmark === undefined || b.bookmark === null) {
      return false;
    }
  }
  if (a.bookmark !== undefined && a.bookmark !== null) {
    if (b.bookmark !== undefined && b.bookmark !== null) {
      return a.bookmark.id === b.bookmark.id;
    }
  }
  throw new Error('Internal error');
}

/**
 * Given a list of (linear) bookmark backend resources, construct
 * the corresponding tree representation of the bookmark collection.
 *
 * @param loadEntries If true, then load the corresponding content
 * entries for the bookmarks (and return them in the BookmarkNode:s).
 */
// ### Add a backend endpoint to delete all bookmarks to missing/deleted entries?
export async function buildBookmarkCollection(
  loadEntries: boolean,
  bookmarks: ReadonlyArray<Bookmark>,
  nonDefaultGroup: ReadonlyArray<Bookmark>,
  readOnly: Boolean = false,
  selectedGroup: CraftId | undefined = undefined
): Promise<ReadonlyArray<BookmarkNode>> {
  // For each bookmark that points to an entry, get that entry.
  const bookmarksWithEntries: Array<CraftId> = [];
  bookmarks.forEach(bm => {
    if (bm.entryId !== undefined && bm.entryId !== null) {
      bookmarksWithEntries.push(bm.entryId);
    }
  });

  let entries: ReadonlyArray<ContentEntry> = [];
  if (loadEntries) {
    entries = await fetchContentEntries(
      contentEntries().id(bookmarksWithEntries).status(['live', 'pending'])
    );
  }

  /**
   * ### This is the frontend data structure for bookmarks.
   * The frontend adds the default group, as well as a root node (that doesn't get drawn).
   * Bookmarks in the database with a NULL parentId are put in the default group.
   * Currently the 'parent' parameter isn't being populated/used – we need to think about
   * its use case, especially if we rework bookmarks to enable sub-groups.
   */
  const rootGroup: Array<BookmarkNode> = [
    {
      bookmark: {
        id: null,
        entryId: null,
        data: null,
        parentId: null,
        title: 'Root',
        sequence: 0
      },
      children: [
        {
          bookmark: {
            id: null,
            entryId: null,
            data: null,
            parentId: null,
            title: 'Default',
            sequence: 0
          },
          children: [],
          title: 'Default',
          parent: undefined,
          contentEntry: undefined
        }
      ],
      title: 'Root',
      parent: undefined,
      contentEntry: undefined
    }
  ];

  /**
   * Extract the non-default groups when in read-only mode. NOTE: This prevents the group
   * from being undefined when asigning children to groups when in read-only mode.
   * Read-only mode is defined as a user generated share link containing a bookmarkId of a
   * bookmark group or single bookmark. An eaxmple URL looks like this:
   * https://scottsbasslessons.com/bookmarks/560196
   * NOTE: When the normal bookmark URL (https://scottsbasslessons.local/bookmarks) is
   * accessed, it is NOT in read-only mode.
   */
  if (readOnly === true && nonDefaultGroup.length) {
    rootGroup[0].children.push({
      bookmark: nonDefaultGroup[0],
      children: [],
      title: nonDefaultGroup[0].title ? nonDefaultGroup[0].title : '',
      parent: undefined,
      contentEntry: undefined
    });
  } else {
    // Extract the non-default groups when NOT in read-only mode.
    bookmarks.forEach(async bookmark => {
      if (bookmark.entryId === null || bookmark.entryId === undefined) {
        rootGroup[0].children.push({
          bookmark,
          children: [],
          title: bookmark.title ? bookmark.title : '',
          parent: undefined,
          contentEntry: undefined,
          selected: bookmark.id === selectedGroup
        });
      }
    });
  }

  // Assign children to groups.
  bookmarks.forEach(bookmark => {
    if (bookmark.entryId !== null || bookmark.entryId !== undefined) {
      const contentEntry = entries.find(e => {
        return e.id === bookmark.entryId;
      });

      if (contentEntry !== undefined) {
        const group = rootGroup[0].children.find(g => {
          // Check if the bookmark is in the default group: it is if it doesn't have a parentId.
          if (g.parent === null) {
            return bookmark.parentId === undefined || bookmark.parentId === null;
          }
          if (g.bookmark !== undefined) {
            return g.bookmark.id === bookmark.parentId;
          }
        });

        if (group === undefined) {
          throw new Error('Unknown bookmark group');
        }

        group.children.push({
          bookmark,
          contentEntry,
          title: '',
          children: [],
          parent: undefined,
          selected: bookmark.id === selectedGroup
        });
      }
    }
  });

  // Sort the group items.
  rootGroup[0].children.forEach(g => {
    g.children.sort((a, b) => {
      return a.bookmark!.sequence - b.bookmark!.sequence;
    });
  });

  let filteredGroups = null;
  // Filter out empty groups – applies to shared group and single bookmark links only
  if (readOnly === true && nonDefaultGroup.length) {
    filteredGroups = rootGroup[0].children.filter(group => {
      return group.bookmark?.id === nonDefaultGroup[0]?.id;
    });
  }

  return filteredGroups === null ? rootGroup[0].children : filteredGroups;
}

export async function createNewBookmark(
  contentEntry: Readonly<ContentEntry>
): Promise<Readonly<Bookmark>> {
  const bookmark: Bookmark = {
    id: undefined,
    title: contentEntry.title,
    entryId: contentEntry.id,
    parentId: null,
    data: null,
    sequence: 0
  };
  return addBookmark(bookmark);
}

export async function createNewBookmarkInGroup(
  contentEntry: Readonly<ContentEntry>,
  group: Readonly<BookmarkNode> | undefined
): Promise<Readonly<Bookmark>> {
  const groupBookmark = group !== undefined ? group.bookmark : undefined;
  const parentId = groupBookmark !== undefined ? groupBookmark.id : undefined;

  const bookmark: Bookmark = {
    id: undefined,
    title: contentEntry.title,
    entryId: contentEntry.id,
    parentId,
    data: null,
    sequence: 0
  };
  return addBookmark(bookmark);
}

export function convertBookmarkNodeToBackend(bookmarkNode: Readonly<BookmarkNode>) {
  if (bookmarkNode.bookmark === undefined) {
    return {
      bookmark: {
        id: null,
        entryId: null,
        data: null,
        parentId: null,
        title: null,
        sequence: 0,
        dateCreated: '',
        dateUpdated: ''
      },
      title: undefined,
      children: [],
      parent: undefined,
      contentEntry: undefined,
      navigationPath: []
    };
  }

  const conversion = {
    bookmark: {
      id: bookmarkNode.bookmark.id === undefined ? null : bookmarkNode.bookmark.id,
      entryId: bookmarkNode.bookmark.entryId === undefined ? null : bookmarkNode.bookmark.entryId,
      data: bookmarkNode.bookmark.data === undefined ? null : bookmarkNode.bookmark.data,
      parentId:
        bookmarkNode.bookmark.parentId === undefined ? null : bookmarkNode.bookmark.parentId,
      title: bookmarkNode.bookmark.title === undefined ? null : bookmarkNode.bookmark.title,
      sequence: bookmarkNode.bookmark.sequence,
      dateCreated: bookmarkNode.bookmark.dateCreated,
      dateUpdated: bookmarkNode.bookmark.dateUpdated
    },
    title: undefined,
    children: [],
    parent: undefined,
    contentEntry: undefined,
    navigationPath: []
  };
  return conversion;
}

export async function updateBookmarkGroupOrder(group: Readonly<BookmarkNode>) {
  const bookmarks = group.children.map((child, index) => {
    const childConversion = convertBookmarkNodeToBackend(child);
    return {
      id: childConversion.bookmark.id,
      entryId: childConversion.bookmark.entryId,
      data: childConversion.bookmark.data,
      parentId: childConversion.bookmark.parentId,
      title: childConversion.bookmark.title,
      sequence: index,
      dateCreated: childConversion.bookmark.dateCreated,
      dateUpdated: childConversion.bookmark.dateUpdated
    };
  });
  await updateBookmarks(bookmarks);
}

export async function moveBookmarkItemUp(
  group: Readonly<BookmarkNode>,
  item: Readonly<BookmarkNode>
) {
  const index = group.children.findIndex(c => {
    if (c.contentEntry === undefined || item.contentEntry === undefined) {
      return;
    }
    return c.contentEntry.id === item.contentEntry.id;
  });
  if (index > 0) {
    moveArrayElement(group.children, index, index - 1);
    await updateBookmarkGroupOrder(group);
  }
}

export async function moveBookmarkItemDown(
  group: Readonly<BookmarkNode>,
  item: Readonly<BookmarkNode>
) {
  const index = group.children.findIndex(c => {
    if (c.contentEntry === undefined || item.contentEntry === undefined) {
      return;
    }
    return c.contentEntry.id === item.contentEntry.id;
  });
  if (index < group.children.length - 1) {
    moveArrayElement(group.children, index, index + 1);
    await updateBookmarkGroupOrder(group);
  }
}

export async function moveBookmarkItemToTop(
  group: Readonly<BookmarkNode>,
  item: Readonly<BookmarkNode>
) {
  const index = group.children.findIndex(c => {
    if (c.contentEntry === undefined || item.contentEntry === undefined) {
      return;
    }
    return c.contentEntry.id === item.contentEntry.id;
  });
  if (index > 0) {
    moveArrayElement(group.children, index, 0);
    await updateBookmarkGroupOrder(group);
  }
}

export async function moveBookmarkItemToBottom(
  group: Readonly<BookmarkNode>,
  item: Readonly<BookmarkNode>
) {
  const index = group.children.findIndex(c => {
    if (c.contentEntry === undefined || item.contentEntry === undefined) {
      return;
    }
    return c.contentEntry.id === item.contentEntry.id;
  });
  if (index < group.children.length - 1) {
    moveArrayElement(group.children, index, group.children.length - 1);
    await updateBookmarkGroupOrder(group);
  }
}

export async function deleteBookmarkItem(item: Readonly<BookmarkNode>): Promise<void> {
  if (item.bookmark!.id !== undefined && item.bookmark!.id !== null) {
    await deleteBookmark(item.bookmark!.id);
  }
}

export async function addBookmarkGroup(name: string): Promise<Bookmark> {
  return await addBookmark({
    id: undefined,
    entryId: null,
    data: null,
    parentId: null,
    title: name,
    sequence: 0
  });
}

export async function deleteBookmarkGroup(group: Readonly<BookmarkNode>): Promise<void> {
  if (group.bookmark !== undefined && group.bookmark !== null) {
    if (group.bookmark.id !== undefined && group.bookmark.id !== null) {
      await deleteBookmark(group.bookmark.id);
    }
  }
}

export async function renameBookmarkGroup(
  group: Readonly<BookmarkNode>,
  name: string
): Promise<void> {
  if (group.bookmark !== undefined && group.bookmark !== null) {
    const bm: Bookmark = {
      ...group.bookmark,
      title: name
    };
    await updateBookmark(bm);
  }
}

function setSequenceToArrayOrder(array: ReadonlyArray<Bookmark>): ReadonlyArray<Bookmark> {
  const resequencedArray = array.map((child, index) => {
    return {
      ...child,
      sequence: index
    };
  });
  return resequencedArray;
}

export async function moveBookmarkItem(
  item: Readonly<BookmarkNode>,
  group: Readonly<BookmarkNode>
) {
  const childConversion = convertBookmarkNodeToBackend(item);
  if (group.bookmark !== undefined && group.bookmark !== null) {
    const bm: Bookmark = {
      id: childConversion.bookmark.id,
      entryId: childConversion.bookmark.entryId,
      data: childConversion.bookmark.data,
      parentId: group.bookmark.id,
      title: childConversion.bookmark.title,
      sequence: childConversion.bookmark.sequence,
      dateCreated: childConversion.bookmark.dateCreated,
      dateUpdated: childConversion.bookmark.dateUpdated
    };
    await updateBookmark(bm);

    if (group.bookmark.id !== null && group.bookmark.id !== undefined) {
      // Non-default bookmark groups
      const getDescendants = await findAllDescendants(group.bookmark.id);
      const getDescendantsMutable = [...getDescendants];

      // move array index of moved bookmark to be first in new group array
      const index = getDescendantsMutable.findIndex(c => {
        return c.id === bm.id;
      });
      if (index > 0) {
        moveArrayElement(getDescendantsMutable, index, 0);
      }

      const resequencedBookmarks = setSequenceToArrayOrder(getDescendantsMutable);
      await updateBookmarks(resequencedBookmarks);
    } else {
      // Default bookmark groups
      const getDefaultGroup = await getDefaultBookmarks();
      const getDefaultGroupMutable = [...getDefaultGroup];

      // move array index of moved bookmark to be first in new group array
      const index = getDefaultGroupMutable.findIndex(c => {
        return c.id === bm.id;
      });
      if (index > 0) {
        moveArrayElement(getDefaultGroupMutable, index, 0);
      }
      const resequencedBookmarks = setSequenceToArrayOrder(getDefaultGroupMutable);
      await updateBookmarks(resequencedBookmarks);
    }
  } else {
    const bm: Bookmark = {
      id: childConversion.bookmark.id,
      entryId: childConversion.bookmark.entryId,
      data: childConversion.bookmark.data,
      parentId: null,
      title: childConversion.bookmark.title,
      sequence: childConversion.bookmark.sequence,
      dateCreated: childConversion.bookmark.dateCreated,
      dateUpdated: childConversion.bookmark.dateUpdated
    };
    await updateBookmark(bm);
  }
}

export async function shareBookmark(bookmark: Readonly<BookmarkNode>): Promise<boolean> {
  if (bookmark.bookmark === undefined) {
    return false;
  }

  if (bookmark.bookmark.id !== null && bookmark.bookmark.id !== undefined) {
    /**
     * ### Strip group ID from URL which has been previously shared from a bookmark group.
     * Example affected URL: https://scottsbasslessons.com/bookmarks/560193
     * Fixes Mantishub issue ID 0000013
     *  */
    const url = window.location.href;
    const lastUrlItem = url.substring(url.lastIndexOf('/') + 1);
    let removeGroupIdFromUrl = null;
    if (lastUrlItem !== '' && lastUrlItem !== 'bookmarks') {
      removeGroupIdFromUrl = url.replace(lastUrlItem, '');
    }
    const trimmedUrl = removeGroupIdFromUrl !== null ? removeGroupIdFromUrl : url;

    const cleanUrl = removeMultipleSlashesFromUrl(`${trimmedUrl}/${bookmark.bookmark.id}`);
    try {
      await navigator.clipboard.writeText(cleanUrl);
      return true;
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  return false;
}
