import {computed} from 'vue';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {redirectWithHistory} from '../../../../utils/url';
import {ColorConfig} from '../../color-config';

export type ButtonColor =
  | 'white'
  | 'lightest'
  | 'light'
  | 'mid'
  | 'dark'
  | 'progress'
  | 'primary'
  | 'invert'
  | 'invert-primary'
  | 'cta'
  | 'black-on-white';

export type ButtonClickCallback = (id?: number | string) => void;

export function getStandardColor(
  disabled: boolean,
  color: ButtonColor,
  spotColor: Readonly<ColorConfig> | undefined
) {
  if (disabled) {
    const textColor = spotColor !== undefined ? spotColor.text : 'text-dark';
    const bgColor = spotColor !== undefined ? spotColor.bg : 'bg-transparent';
    const borderColor = spotColor !== undefined ? spotColor.border : 'border-dark';
    return {textColor, bgColor, borderColor};
  }
  if (spotColor !== undefined) {
    const textColor = `${spotColor.text} ${spotColor.textHover}`;
    const bgColor = `bg-transparent ${spotColor.bgHover}`;
    const borderColor = `${spotColor.border} ${spotColor.borderHover}`;
    return {textColor, bgColor, borderColor};
  }

  switch (color) {
    case 'white':
      return {
        textColor: 'text-white hover:text-black',
        bgColor: 'bg-transparent hover:bg-white',
        borderColor: 'border-white hover:border-white'
      };
    case 'lightest':
      return {
        textColor: 'text-lightest hover:text-black',
        bgColor: 'bg-transparent hover:bg-white',
        borderColor: 'border-lightest hover:border-white'
      };
    case 'light':
      return {
        textColor: 'text-light hover:text-black',
        bgColor: 'bg-transparent hover:bg-white',
        borderColor: 'border-light hover:border-white'
      };
    case 'mid':
      return {
        textColor: 'text-mid hover:text-black',
        bgColor: 'bg-transparent hover:bg-white',
        borderColor: 'border-mid hover:border-white'
      };
    case 'dark':
      return {
        textColor: 'text-dark hover:text-black',
        bgColor: 'bg-transparent hover:bg-white',
        borderColor: 'border-dark hover:border-white'
      };
    case 'progress':
      return {
        textColor: 'text-progress hover:text-black',
        bgColor: 'bg-transparent hover:bg-progress',
        borderColor: 'border-progress hover:border-progress'
      };
    case 'primary':
      return {
        textColor: 'text-black hover:text-primary',
        bgColor: 'bg-primary hover:bg-transparent',
        borderColor: 'border-primary hover:border-primary'
      };
    case 'invert-primary':
      return {
        textColor: 'text-black',
        bgColor: 'bg-white hover:bg-primary',
        borderColor: 'border-white hover:border-primary'
      };
    case 'invert':
      return {
        textColor: 'text-black hover:text-white',
        bgColor: 'bg-white hover:bg-transparent',
        borderColor: 'border-white hover:border-white'
      };
    case 'cta':
      return {
        textColor: 'text-primary hover:text-black',
        bgColor: 'bg-transparent hover:bg-primary',
        borderColor: 'border-primary'
      };
    case 'black-on-white':
      return {
        textColor: 'text-black hover:text-mid',
        bgColor: 'bg-white hover:bg-transparent',
        borderColor: 'border-black hover:border-mid'
      };

    default:
      throw new Error('Unknown color');
  }
}

export function getLinkColor(disabled: boolean, color: ButtonColor) {
  let textColor = 'text-dark';
  if (!disabled) {
    switch (color) {
      case 'white':
        textColor = 'text-white';
        break;
      case 'lightest':
        textColor = 'text-lightest hover:text-white';
        break;
      case 'light':
        textColor = 'text-light hover:text-white';
        break;
      case 'mid':
        textColor = 'text-mid hover:text-white';
        break;
      case 'dark':
        textColor = 'text-dark hover:text-white';
        break;
      case 'progress':
        textColor = 'text-progress hover:text-white';
        break;
      case 'primary':
        textColor = 'text-primary hover:text-white';
        break;
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  }

  const bgColor = 'bg-transparent';

  return {
    textColor,
    bgColor
  };
}

export function getLinkColorGroupHover(disabled: boolean, color: ButtonColor) {
  let textColor = 'text-dark';
  if (!disabled) {
    switch (color) {
      case 'white':
        textColor = 'text-white';
        break;
      case 'lightest':
        textColor = 'text-lightest group-hover:text-white';
        break;
      case 'light':
        textColor = 'text-light group-hover:text-white';
        break;
      case 'mid':
        textColor = 'text-mid group-hover:text-white';
        break;
      case 'dark':
        textColor = 'text-dark group-hover:text-white';
        break;
      case 'progress':
        textColor = 'text-progress group-hover:text-white';
        break;
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  }

  const bgColor = 'bg-transparent';

  return {
    textColor,
    bgColor
  };
}

export function getClickFunc(
  onClick: ButtonClickCallback | undefined,
  id: number | string | undefined,
  url: CraftUrl | undefined | null
) {
  const clicked = () => {
    if (onClick !== undefined) {
      onClick(id);
    }
    if (url !== undefined && url !== null) {
      redirectWithHistory(url);
    }
  };

  return clicked;
}

export function getLabel(label: string | undefined, tooltip: string | undefined) {
  if (label === undefined && tooltip === undefined) {
    throw new Error('Button must have either label or tooltip');
  }
  const ariaLabel = computed(() => {
    return label ? undefined : tooltip;
  });
  const tooltipText = computed(() => {
    if (tooltip !== undefined) {
      return tooltip;
    }
    return label;
  });

  return {
    labelText: label,
    ariaLabel,
    tooltipText
  };
}

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type IconButtonSize =
  | '6xl'
  | '5xl'
  | '4xl'
  | '3xl'
  | '2xl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | '2xs';

export function getStandardDimensions(size: ButtonSize, expand: boolean, padding?: string) {
  const widthFull = expand ? 'w-full' : 'w-fit h-fit';

  const PADDING: {[size: string]: string} = {
    xs: 'px-fl-lg py-fl-2xs',
    sm: 'px-fl-lg-xl py-fl-2xs',
    md: 'px-fl-lg-3xl py-fl-sm',
    lg: 'px-fl-lg-4xl py-fl-sm',
    xl: 'px-fl-lg-5xl py-fl-md'
  };
  const p = padding ? padding : PADDING[size];

  switch (size) {
    case 'xs':
      return `${widthFull} ${p} text-fl-base font-semibold rounded`;
    case 'sm':
      return `${widthFull} ${p} text-fl-base font-semibold rounded`;
    case 'md':
      return `${widthFull} ${p} text-fl-lg font-semibold rounded-md`;
    case 'lg':
      return `${widthFull} ${p} text-fl-2xl font-semibold rounded-lg`;
    case 'xl':
      return `${widthFull} ${p} text-fl-3xl font-semibold rounded-lg`;
    default:
      throw new Error('Unknown button size');
  }
}

export function getLinkDimensions(size: ButtonSize, expand: boolean) {
  const widthFull = expand ? 'w-full h-full' : 'w-fit h-fit';
  switch (size) {
    case 'xs':
      return `${widthFull} text-fl-base`;
    case 'sm':
      return `${widthFull} text-fl-base`;
    case 'md':
      return `${widthFull} text-fl-lg`;
    case 'lg':
      return `${widthFull} text-fl-2xl`;
    default:
      throw new Error('Unknown button size');
  }
}

export function getLinkIconDimensions(size: ButtonSize, expand: boolean) {
  const widthFull = expand ? 'w-full h-full' : 'w-fit h-fit';
  switch (size) {
    case 'xs':
      return `${widthFull} text-fl-base`;
    case 'sm':
      return `${widthFull} text-fl-base`;
    case 'md':
      return `${widthFull} text-fl-lg`;
    case 'lg':
      return `${widthFull} text-fl-2xl`;
    case 'xl':
      return `${widthFull} text-fl-3xl`;
    default:
      throw new Error('Unknown button size');
  }
}

export function getIconDimensions(size: IconButtonSize, expand: boolean) {
  if (expand) {
    return 'w-full h-full';
  }
  switch (size) {
    case '2xs':
      return 'text-fl-xs';
    case 'xs':
      return 'text-fl-sm';
    case 'sm':
      return 'text-fl-base';
    case 'md':
    default:
      return 'text-fl-lg';
    case 'lg':
      return 'text-fl-2xl';
    case 'xl':
      return 'text-fl-5xl';

    case '2xl':
      return 'text-fl-8xl';
    case '3xl':
      return 'text-fl-11xl';
    case '4xl':
      return 'text-fl-13xl';
    case '5xl':
      return 'text-fl-15xl';
    case '6xl':
      return 'text-fl-17xl';
  }
}
