import {FilterProperty, FilterPropertyList, FilterPropertySet} from './types';

/**
 * Find all objects that match the properties in the specified filter config.
 *
 * Properties are logically OR:ed together. For example, if the `needle` is
 *
 *  ['gear', 'technique']
 *
 * then this function will return all objects in the `haystack` that
 * have either `gear` OR `technique` as subjects. (Or both.)
 *
 * @returns Indicies into the 'haystack' array: the objects that match the
 * 'needle'.
 */
export function applyFilter(
  needle: Readonly<FilterPropertySet>,
  haystack: ReadonlyArray<FilterPropertyList | undefined>
): ReadonlyArray<number> {
  const result: Array<number> = [];
  const needleProps = Array.from(needle.values());
  haystack.forEach((object, index) => {
    if (needle === undefined || needle.size === 0) {
      result.push(index); // If the filter doesn't have any properties, then all objects match.
    } else if (object !== undefined && object.length > 0) {
      const match = needleProps.find(needleProp => object.includes(needleProp));
      if (match !== undefined) {
        result.push(index);
      }
    }
  });
  return result;
}

/**
 * Given a list of 'haystack' objects and a 'needle' filter config, return
 * an object that has the set of properties that exist in the 'haystack'
 * but DO NOT exist in the 'needle'.
 */
export function collectRemainingProperties(
  needle: Readonly<FilterPropertySet>,
  haystack: ReadonlyArray<FilterPropertyList>
): Readonly<FilterPropertyList> {
  const result = new Set<FilterProperty>();
  haystack.forEach(object => {
    const notInNeedle = object.filter(prop => {
      return !needle.has(prop);
    });
    notInNeedle.forEach(prop => {
      result.add(prop);
    });
  });
  return Array.from(result);
}
