<template>
  <modal-dialog :config="config" :heading="'Are You Sure?'">
    <page-section :margin-top="'xs'">
      <standard-heading :center="true" :size="2">Are You Sure?</standard-heading>
    </page-section>

    <page-section>
      <p class="text-center text-fl-lg">
        This will reset your progress for all study pieces in this level.<br />
        You must also pass the entry assessment to unlock this level again.
      </p>
    </page-section>
  </modal-dialog>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import ModalDialog from '../../../generic/modal-dialog/ModalDialog.vue';
import {ModalDialogConfig} from '../../../vue-composition/modal-dialog/types';

export default defineComponent({
  components: {
    PageSection,
    ModalDialog,
    StandardHeading
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true}
  },
  setup() {}
});
</script>
