<template>
  <page-content v-if="grooveTrainer !== undefined">
    <content-column :width="'wide'">
      <iframe
        id="groove-trainer"
        src="https://thegroovetrainer.scottsbasslessons.com/"
        allow="microphone"
        class="w-full h-[1100px] bg-black border-0 border-none"
      ></iframe>
    </content-column>

    <page-section>
      <content-column>
        <content-entry-header
          >SBL Groove Trainer: Free Online Bass Practice Tool</content-entry-header
        >
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <p>
          The SBL Groove Trainer is a free online practice tool from Scott’s Bass Lessons that
          includes three incredible tools to enhance your bass practice—an online metronome, a
          tuner, and a drum machine.
        </p>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <standard-heading :size="2"> Online Bass Guitar Metronome </standard-heading>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <flow>
          <p>
            Our free online metronome helps you stay in rhythm and improve your timing. It's a
            valuable practice tool for developing a solid sense of internal timing and improving
            your overall musicianship.
          </p>
          <p>Here's how to use the online metronome:</p>
          <ul class="list-disc list-inside">
            <li>Set a desired tempo by adjusting the beats per minute (BPM)</li>
            <li>Adjust the meter (time signature) with Beats and Note Value settings</li>
            <li>Choose additional subdivisions like eighth notes, triplets, or swing rhythm</li>
          </ul>
          <p>As a tool for practicing speed and internal timing:</p>
          <ul class="list-disc list-inside">
            <li>
              <b>Speed Training feature:</b> Set an Initial BPM and Final BPM, then customize how
              quickly you'll move up to full speed.
            </li>
            <li>
              <b>Internal Rhythm Training feature:</b> Choose which bars to stop the click, and
              practice keeping a steady tempo. Use the red buttons to skip certain beats or
              subdivisions within each bar.
            </li>
          </ul>
        </flow>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <standard-heading :size="2"> Online Bass Guitar Tuner </standard-heading>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <flow>
          <p>
            Use the online bass guitar tuner to make sure your bass is always in perfect pitch. The
            tuner provides visual feedback to help you adjust each string accurately.
          </p>

          <p>How to use the free online bass tuner:</p>
          <ul class="list-disc list-inside">
            <li>
              Play a string and follow the indicator to tune up or down until you reach the correct
              pitch.
            </li>
            <li>
              For notes lower than F3 (174 Hz), connect your instrument through an audio interface
              or click the note name to hear the pitch and tune by ear
            </li>
            <li>If you're using a mobile device, make sure your audio is not muted</li>
          </ul>
        </flow>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <standard-heading :size="2"> Online Bass Guitar Drum Machine </standard-heading>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <flow>
          <p>
            Our online bass guitar drum machine provides dynamic and varied drum tracks for you to
            practice along with. Using the virtual drum machine helps you develop your timing,
            groove, and ability to lock in with a drummer, making your practice sessions more
            engaging and effective.
          </p>

          <p>
            <b>Star Loops:</b> Choose this tab to jam along with drum loops from popular songs,
            played by star drummers.
          </p>
          <p>
            <b>Drum Loops:</b> Preset drum patterns in different styles and tempos to match any
            practice goal.
          </p>

          <p>How to practice along with the drum machine:</p>

          <ul class="list-disc list-inside">
            <li>Select a BPM to fit the groove you’re working on</li>
            <li>Filter for genre to find a beat you like</li>
            <li>Filter for meter to choose simple, complex, or odd grooves</li>
          </ul>

          <p>
            You can use every one of these features on your phone to practice anywhere, anytime.
          </p>

          <p>
            Download it today from the
            <a
              class="underline"
              href="https://apps.apple.com/us/app/sbl-groove-trainer/id1571452765"
              target="_blank"
            >
              App Store
            </a>
            or
            <a
              class="underline"
              href="https://play.google.com/store/apps/details?id=com.scottsbasslessons.groovetrainer&hl=en_US"
              target="_blank"
              >Play Store</a
            >.
          </p>
        </flow>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {getGrooveTrainer} from '../../../backend/content/groove-trainer/groove-trainer-query';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import Flow from '../../core/compositions/Flow.vue';

export default defineComponent({
  components: {
    PageContent,
    ContentColumn,
    PageSection,
    ContentEntryHeader,
    StandardHeading,
    Flow
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {
    const loader = useFullScreenLoader();
    const grooveTrainer = ref(undefined as Readonly<ContentEntry> | undefined);
    onMounted(async () => {
      loader.setLoading(true);
      grooveTrainer.value = await getGrooveTrainer();
      loader.setLoading(false);
    });
    return {
      grooveTrainer
    };
  }
});
</script>

<style scoped>
.groove-trainer {
  padding: get-size('900') 0 0 0;
  background-color: #262626 !important;
}
</style>
