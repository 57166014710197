import {AddOnOffer} from '../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../backend/offer/signup-offer-types';
import {getIndexOfLongest} from '../../../../../../utils/array';

function getFeatures(
  offer: Readonly<SignupOffer> | Readonly<AddOnOffer>,
  trialDuration: number | null
): Array<string> {
  if (offer.offerFeatures === null) {
    return [];
  }

  const features = offer.offerFeatures.split('\n');

  // If this access pass does not have a trial, remove mentions of trial from feature list.
  if (trialDuration === null || trialDuration === 0) {
    return features.filter(feature => {
      return feature.indexOf('{{TRIAL_DURATION}}') === -1;
    });
  }

  // We do have a trial duration, so replace the trial duration slot/s in the feature list.
  return features.map(feature => {
    return feature.replace('{{TRIAL_DURATION}}', `${trialDuration}`);
  });
}

export type Feature = {
  row: number;
  offer: SignupOffer | AddOnOffer | undefined;
  feature: string | undefined;
  check: boolean;
};

/**
 * Create a feature matrix for the specified offers.
 *
 * This function returns array of objects that each represents one cell in the feature
 * matrix. The objects contain a `row` value that specifies to which row in the matrix
 * the cell belongs. Objects with the same `row` value are the columns for that row.
 * So, for example, with two offers, we'll get something like
 *
 *  [
 *    { row: 0, feature: 'Name of first feature', check: false, offer: undefined},
 *    { row: 0, feature: undefined, check: true, offer: (offer 1) },
 *    { row: 0, feature: undefined, check: true, offer: (offer 2) },
 *    { row: 1, feature: 'Name of second feature', check: false, offer: undefined},
 *    { row: 1, feature: undefined, check: true, offer: (offer 1) },
 *    { row: 1, feature: undefined, check: false, offer: (offer 2) },
 *    ...
 *  ]
 */
// ### Simplify this!
export function getFeatureMatrix(offers: ReadonlyArray<SignupOffer | AddOnOffer>): Array<Feature> {
  // Get list of features per offer...
  const offerFeatures = offers.map(offer => {
    return getFeatures(offer, offer.offerTrialDuration);
  });

  /*
    Go through the features of the offer with the longest feature list.
    For each of those features, find the same feature from the other
    offers (if it exists).
  */
  const indexOfLongest = getIndexOfLongest(offerFeatures);
  const longest = offerFeatures[indexOfLongest];
  const rows = longest.map(feature => {
    return offerFeatures.map((offerFeatures, i) => {
      if (offerFeatures.includes(feature)) {
        return {feature, offer: offers[i]};
      } else {
        return {undefined, offer: offers[i]};
      }
    });
  });

  // Collect and return as flat list of columns.
  const features = rows
    .map((row, rowIndex) => {
      const featureOfRow = row.find(r => r.feature !== undefined);
      if (featureOfRow === undefined) {
        throw new Error('Signup offer has no features');
      }
      const checkmarks: Array<Feature> = row.map(f => {
        return featureOfRow.feature === f.feature
          ? {row: rowIndex, feature: undefined, check: true, offer: f.offer}
          : {row: rowIndex, feature: undefined, check: false, offer: f.offer};
      });
      const col0: Feature = {
        row: rowIndex,
        feature: featureOfRow.feature,
        check: false,
        offer: undefined
      };
      return [col0].concat(checkmarks);
    })
    .reduce((result, row) => {
      return result.concat(row);
    }, []);

  return features;
}
