export interface LoaderStoreState {
  activeLoaders: number;
}

export const LoaderStore = {
  namespaced: true,
  state: {
    activeLoaders: 0
  } as LoaderStoreState,
  mutations: {
    setLoading(state: any, status: boolean) {
      if (status) {
        state.activeLoaders = state.activeLoaders + 1;
      } else {
        if (state.activeLoaders > 0) {
          state.activeLoaders = state.activeLoaders - 1;
        }
      }
    }
  },
  actions: {
    setLoading({commit}: any, status: boolean) {
      commit('setLoading', status);
    }
  },
  getters: {
    loading(state: any) {
      return state.activeLoaders > 0;
    }
  }
};
