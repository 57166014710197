<template>
  <page-section>
    <content-column>
      <box class="p-fl-sm-xl">
        <flow :gap="8">
          <cluster>
            <font-awesome-icon
              :icon="icon"
              :class="`${spotColor.text} text-fl-7xl`"
            ></font-awesome-icon>
            <standard-heading :size="2">{{ level.title }} Complete</standard-heading>
          </cluster>
          <div class="text-fl-3xl text-white">
            {{ text }}
          </div>
          <standard-button
            v-if="nextLevel !== undefined"
            :url="nextLevel.url"
            :border="true"
            :label="`To ${nextLevel.title}`"
          />
        </flow>
      </box>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Box from '../../../core/box/Box.vue';
import Flow from '../../../core/compositions/Flow.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faTrophy} from '@fortawesome/pro-solid-svg-icons/faTrophy';
import {ColorConfig} from '../../../core/color-config';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardButton,
    StandardHeading,
    Flow,
    Cluster,
    Box,
    FontAwesomeIcon
  },
  props: {
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    nextLevel: {
      type: Object as PropType<Readonly<PlayersPathLevel> | undefined>,
      default: undefined
    },
    levelNumber: {type: Number, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true}
  },
  setup(props) {
    const icon = computed(() => (props.levelNumber === 9 ? faTrophy : faCheckCircle));
    const text = computed(() => {
      return props.levelNumber === 9
        ? 'You have completed Players Path! You are AWESOME!'
        : 'Congratulations! You have completed this level. You are now free to learn and practice the remaining study pieces, or move on to the next level!';
    });
    return {
      icon,
      text
    };
  }
});
</script>
