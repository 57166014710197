<template>
  <div>
    <page-section>
      <content-column :width="'narrow'">
        <standard-heading
          v-if="titleImage === undefined"
          :size="3"
          :spot-color="spotColor"
          :class="'text-center'"
        >
          {{ card.offer.offerTitle }}
        </standard-heading>
        <div v-else class="flex justify-center">
          <imgix-image :config="titleImage" :class="`w-full max-w-[360px]`" />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <div :class="'text-fl-8xl text-white font-semibold text-center'">
          {{ heading }}
        </div>
      </content-column>
    </page-section>

    <!-- Monthly / Yearly -->
    <page-section>
      <content-column :width="'narrow'">
        <div class="flex flex-col items-center">
          <div :class="`pt-fl-md text-fl-6xl text-white text-center`">Just ${{ costPerMonth }}</div>
          <div v-if="perMonth" :class="'pt-fl-xs text-fl-xl text-white text-center'">per month</div>
          <div v-if="billedAs" :class="'pt-fl-2xs text-fl-xl text-light text-center'">
            {{ billedAs }}
          </div>
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {deviceTypeSwitch, useDeviceType} from '../../vue-composition/device-type/device-type';
import {OfferCardData} from './types';
import {ColorConfig} from '../../core/color-config';
import {getImageConfig} from '../../../backend/content/content-entry/content-entry-query-utils';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ImgixImage from '../../core/imgix-image/ImgixImage.vue';

export default defineComponent({
  components: {
    StandardHeading,
    ImgixImage,
    PageSection,
    ContentColumn
  },
  props: {
    heading: {type: String, default: 'Confirm your subscription'},
    card: {type: Object as PropType<Readonly<OfferCardData>>, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const widthCfg = deviceTypeSwitch<string>({
      narrow: 'max-w-[250px]',
      sm: 'max-w-[350px]'
    });

    const costPerMonth = computed(() => {
      return props.card.accessPass.accessPassRecurrence === 'annual'
        ? (props.card.accessPass.accessPassAmount / 12).toFixed(2)
        : props.card.accessPass.accessPassAmount;
    });
    const perMonth = computed(() => {
      return props.card.accessPass.accessPassRecurrence !== 'none';
    });
    const billedAs = computed(() => {
      return props.card.accessPass.accessPassRecurrence === 'annual'
        ? `(Conveniently billed as $${props.card.accessPass.accessPassAmount} annually)`
        : undefined;
    });
    const textColor = computed(() => {
      return props.card.accessPass.accessPassRecurrence === 'annual' ? 'text-pp2' : 'text-white';
    });
    const buttonColor = computed(() => {
      return props.card.accessPass.accessPassRecurrence === 'annual'
        ? {
            text: 'text-pp2',
            textHover: 'hover:text-black',
            bg: 'bg-transparent',
            bgHover: 'hover:bg-pp2',
            border: 'border-pp2',
            borderHover: 'hover:border-pp2'
          }
        : undefined;
    });

    const gapCfg = deviceTypeSwitch<string>({
      narrow: 'gap-fl-2xl'
    });
    const buttonSize = computed(() => {
      if (deviceType.screen.value === 'narrow') {
        return props.card.accessPass.accessPassRecurrence === 'annual' ? 'md' : 'sm';
      }
      return props.card.accessPass.accessPassRecurrence === 'annual' ? 'lg' : 'md';
    });

    const titleImage = getImageConfig(
      props.card.offer.imageCollection,
      ['header'],
      props.card.offer.offerTitle !== null ? props.card.offer.offerTitle : 'Offer',
      800,
      {}
    );

    const label = computed(() => {
      if (props.card.offer.offerSelectionLabel !== null) {
        return props.card.offer.offerSelectionLabel;
      }
      return 'Select';
    });

    return {
      gapCfg,
      costPerMonth,
      perMonth,
      billedAs,
      widthCfg,
      textColor,
      buttonColor,
      buttonSize,
      label,
      titleImage
    };
  }
});
</script>
