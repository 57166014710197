import ImgixClient from '@imgix/js-core';
import {completeScreenSwitch} from '../../../vue-composition/device-type/device-type';
import {ImgixImageConfig, ImgixImageFixedWidth, ImgixImageResponsiveWidth} from '../types';

/**
 * Attribute values for an <img> HTML tag.
 */
export type ImageAtttibutes = {
  src: string;
  srcset: string;
  alt: string;
  sizes?: string;
};

let _imgixClient: Readonly<ImgixClient> | undefined = undefined;

/**
 * Return a singleton Imgix API client class instance.
 */
function getImgixClient(): Readonly<ImgixClient> {
  if (_imgixClient === undefined) {
    _imgixClient = new ImgixClient({
      domain: 'scottsbasslessons.imgix.net'
    });
  }
  return _imgixClient;
}

/**
 * Return `<img>` attributes for an Imgix image that adapts to
 * the screen width and/or device pixel density.
 *
 * For more info, see
 * https://ericportis.com/posts/2014/srcset-sizes/
 * https://docs.imgix.com/tutorials/responsive-images-srcset-imgix
 * https://docs.imgix.com/libraries/js-core
 */
export function getImageAttributes(config: Readonly<ImgixImageConfig>): Readonly<ImageAtttibutes> {
  const params = config.imgixParams;
  const client = getImgixClient();
  const p: any = {
    ...params,
    auto: 'format',
    w: undefined
  };
  let sizesStr = undefined;
  if (typeof config.width === 'object') {
    const cfg = completeScreenSwitch<number>(config.width as ImgixImageResponsiveWidth);
    const queries = [
      `(max-width: 640px) ${cfg['narrow']}px`,
      `(max-width: 768px) ${cfg['sm']}px`,
      `(max-width: 1024px) ${cfg['md']}px`,
      `(max-width: 1280px) ${cfg['lg']}px`,
      `(max-width: 1536px) ${cfg['xl']}px`,
      `(min-width: 1536px) ${cfg['2xl']}px`
    ];
    sizesStr = queries.join(', ');
  } else {
    p.w = config.width as ImgixImageFixedWidth;
  }

  const src = client.buildURL(config.path, p, {disablePathEncoding: true});
  const srcset = client.buildSrcSet(config.path, p, {disablePathEncoding: true});

  return {
    src,
    srcset,
    alt: config.alt,
    sizes: sizesStr
  };
}
