<template>
  <div class="mb-fl-sm">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <flow :gap="3" :class="cfg" v-html="body.promotionCopyBodyParagraphs" />
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import Flow from '../../../core/compositions/Flow.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {PromotionCopyBlock, PromotionCopyBody} from '../../../../backend/promotion/promotion-types';

export default defineComponent({
  components: {
    Flow
  },
  props: {
    promotionCopyBody: {type: Object as PropType<PromotionCopyBlock>, required: true},
    bodyTextSize: {type: String, default: 'text-fl-base'},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const body = computed(() => {
      return props.promotionCopyBody as Readonly<PromotionCopyBody>;
    });
    const cfg = computed(() => {
      return `${props.bodyTextSize} [&_a]:underline [&_a:hover]:text-white`;
    });
    return {
      body,
      cfg
    };
  }
});
</script>
