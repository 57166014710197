import {compare} from './comparators';
import {SortConfig} from './types';

/**
 * Given a list of sortable objects and a sort config, return a new
 * sorted array (with the objects).
 *
 * @param objects The objects to be sorted.
 *
 * @param config The hierarchical sort will be carried out according
 * to this config. For example, if the config for a collection of
 * courses is
 *
 *  [
 *    { name: 'tutor', type: 'string', order: 'ascending', ... },
 *    { name: 'postDate', type: 'date', order: 'descending', ... },
 *  ]
 *
 * then the courses will be sorted by tutor first. Courses with the
 * same tutor will then be sorted according to their post date.
 */
export function hierarchicalSort(
  objects: ReadonlyArray<any>,
  config: Readonly<SortConfig>
): ReadonlyArray<any> {
  // Create a copy of the input array.
  const tmp = [...objects];

  /*
    Sort the temporary structure hierarchically according to the sort keys.
    The idea is to find the first sort key where the comparator returns a
    non-zero value. That is, if the values are equal for the first sort key,
    then check the second key. If they are still equal, check the third key,
    and so on until the values can be differentiated. If they are equal for
    *all* sort keys, then keep their original order.
  */
  tmp.sort((a, b) => {
    for (let i = 0; i < config.length; i += 1) {
      const key = config[i];
      const sortPropertyA = key.descriptor(a);
      const sortPropertyB = key.descriptor(b);
      const order = compare(sortPropertyA, sortPropertyB, config[i]);
      if (order !== 0) {
        return order;
      }
    }
    return 0;
  });

  return tmp;
}
