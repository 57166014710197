import {CraftUrl} from '../../../backend/craft/craft-types';
import {instrumentationEvent} from '../../../backend/instrumentation/instrumentation-query';
import {
  InstrumentationUser,
  InstrumentationEntry,
  InstrumentationEvent
} from '../../../backend/instrumentation/instrumentation-types';
import {useStore} from '../../../store/store';

export type InstrumentationComposition = {
  sendEvent: (event: Readonly<InstrumentationEvent>) => Promise<void>;
};

/**
 * Return a Vue composition that allows you to send instrumentation events
 * to our backend system/s.
 *
 * To send an event, simply call the `sendEvent` function with the appropriate
 * event data. (The instrumentation system will automatically figure out which
 * entry that is currently being viewed, as well as the currently logged in
 * user - if any, the current page URL, and the referrer page.)
 */
export function useInstrumentation(): Readonly<InstrumentationComposition> {
  const store = useStore();
  return {
    sendEvent: async (event: Readonly<InstrumentationEvent>) => {
      const user = store.getters['instrumentation/user'] as InstrumentationUser | undefined;
      const entry = store.getters['instrumentation/entry'] as InstrumentationEntry | undefined;
      const pageUrl = store.getters['instrumentation/pageUrl'] as CraftUrl;
      const referrer = store.getters['instrumentation/referrer'] as CraftUrl | undefined;
      await instrumentationEvent(user, entry, pageUrl, referrer, event);
    }
  };
}
