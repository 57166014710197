<template>
  <!-- SBL team -->
  <page-section :padding-top="'lg'" :margin-top="'none'" :margin-bottom="'none'" class="bg-white">
    <content-column>
      <standard-heading :size="1" :center="true" :spot-color="spotColor">
        {{ teamHeading }}</standard-heading
      >
    </content-column>
  </page-section>

  <page-section
    :padding-top="'sm'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="bg-white text-darkest text-fl-3xl"
  >
    <content-column :width="'narrow'">
      <p class="text-center w-2/3 xl:w-9/12 mx-auto">
        {{ teamSummary }}
      </p>
    </content-column>
  </page-section>

  <!-- SBL team panel -->
  <page-section
    :padding-top="'md'"
    :padding-bottom="'lg'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="bg-white"
  >
    <team-member-grid>
      <div v-for="(member, index) in pagination.items.value" :key="index">
        <card class="grayscale hover:grayscale-0">
          <template #hero>
            <card-hero-image :config="member.image" />
          </template>
          <template #body>
            <div class="pt-fl-2xs font-semibold text-dark text-fl-xl">
              {{ member.title }}
            </div>
            <div class="pb-fl-xs text-mid text-fl-lg">
              {{ member.jobTitle }}
            </div>
          </template>
        </card>
      </div>
    </team-member-grid>
    <content-column :width="'wide'" class="pt-fl-xl">
      <div class="flex justify-center">
        <div v-if="pagination.more.value">
          <standard-button
            :spot-color="showMoreSpotColor"
            :label="'Show More'"
            :on-click="pagination.onMore"
          />
        </div>
      </div>
      <standard-heading :size="3" :center="true" :spot-color="spotColor" class="mt-fl-xl">
        You could be here! <a href="/careers" class="underline">Join our team.</a>
      </standard-heading>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useLoader} from '../../../vue-composition/loader/loader';
import {usePagination} from '../../../vue-composition/pagination/pagination';
import {TeamDirectoryMember} from '../../../../backend/team-directory/team-directory-types';
import {getTeamDirectory} from '../../../../backend/team-directory/team-directory-query';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import TeamMemberGrid from '../partials/TeamMemberGrid.vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {teamHeading, teamSummary} from '../utils/utils';
import {getAvatarImage} from '../../../../backend/content/content-entry/content-entry-query-utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    TeamMemberGrid,
    Card,
    CardHeroImage,
    StandardButton
  },

  setup() {
    const loader = useLoader();

    const teamDirectory = ref<ReadonlyArray<TeamDirectoryMember>>([]);
    const team = computed(() => {
      return teamDirectory.value.map(member => {
        return {
          id: member.id,
          title: member.title,
          jobTitle: member.teamDirectoryJobTitle,
          image: getAvatarImage(member.imageCollection, member.title, {
            narrow: 300,
            sm: 350,
            md: 300,
            lg: 350,
            xl: 200,
            '2xl': 250
          })
        };
      });
    });

    const paginationItems = computed(() => {
      if (team.value === undefined) {
        return [];
      }
      return team.value;
    });
    const pagination = usePagination(paginationItems);

    const spotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-white',
      borderHover: 'hover:border-white'
    };

    const showMoreSpotColor = {
      bg: 'bg-white',
      bgHover: 'hover:bg-black',
      text: 'text-black',
      textHover: 'hover:text-white',
      border: 'border-black',
      borderHover: 'hover:border-black'
    };

    onMounted(async () => {
      loader.setLoading(true);
      teamDirectory.value = await getTeamDirectory();
      loader.setLoading(false);
    });

    return {
      team,
      pagination,
      spotColor,
      showMoreSpotColor,
      teamHeading,
      teamSummary
    };
  }
});
</script>
