<template>
  <div v-if="previewLevel" class="p-fl-lg flex flex-col justify-center items-center gap-fl-md">
    <div class="text-fl-lg text-center text-mid">Unlock {{ levelTitle }} to begin</div>
    <players-path-quiz-button :size="'sm'" :entry-quiz="levelQuiz" :spot-color="spotColor" />
  </div>
  <div v-else-if="!completed" class="p-fl-lg flex flex-col justify-center items-center gap-fl-md">
    <div class="text-fl-lg text-center text-mid">Ready to complete this study piece?</div>
    <players-path-quiz-button
      :size="'sm'"
      :entry-quiz="pieceQuiz"
      :spot-color="spotColor"
      :title="'Take Assessment'"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PlayersPathQuizButton from './PlayersPathQuizButton.vue';
import {Quiz} from '../../../../backend/content/quiz/quiz-types';
import {ColorConfig} from '../../../core/color-config';

export default defineComponent({
  components: {
    PlayersPathQuizButton
  },
  props: {
    pieceQuiz: {type: Object as PropType<Readonly<Quiz>>, required: true},
    levelQuiz: {type: Object as PropType<Readonly<Quiz>>, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true},
    completed: {type: Boolean, required: true},
    previewLevel: {type: Boolean, required: true},
    levelTitle: {type: String, required: true}
  },
  setup() {}
});
</script>
