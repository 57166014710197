<template>
  <div>
    <page-section :margin-top="'xs'">
      <content-column :width="'wide'">
        <div class="flex flex-col items-center gap-fl-sm">
          <standard-heading :size="3">{{ contentEntry.title }}</standard-heading>
          <cluster>
            <content-entry-header-tutors :content-entry="contentEntry" :size="'sm'" />
          </cluster>
        </div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'" :margin-bottom="'sm'">
      <standard-divider :dashed="false" class="border-dark" />
    </page-section>

    <page-section :margin-top="'xs'">
      <content-column>
        <repel :gap="'gap-fl-lg'">
          <content-entry-header-campus :guest="guest" :content-entry="contentEntry" />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="contentEntry"
            :icon-only="true"
            :bookmark="bookmark"
          />
        </repel>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../core/standard-divider/StandardDivider.vue';
import Repel from '../../core/compositions/Repel.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../vue-composition/bookmark/types';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardDivider,
    Repel,
    Cluster,
    StandardHeading,
    ContentEntryHeaderTutors,
    ContentEntryHeaderCampus,
    ContentEntryHeaderBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup() {}
});
</script>
