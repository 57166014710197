<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column>
        <content-entry-header> Contact SBL </content-entry-header>
      </content-column>
    </page-section>

    <div v-if="ready">
      <page-section :margin-top="'xl'">
        <page-section-header :heading="'Frequently Asked Questions'" />
      </page-section>

      <page-section :margin-top="'sm'">
        <content-column>
          <ul class="space-y-fl-lg">
            <li v-for="article in articles" :key="article.id">
              <link-button
                :id="article.number"
                :label="article.name"
                :wrap="true"
                :size="'lg'"
                :justify="'justify-start'"
                :text-align="'text-left'"
                :color="'light'"
                :on-click="onArticleClicked"
              />
            </li>
          </ul>
        </content-column>
      </page-section>

      <page-section>
        <page-section-header :heading="'Send us a Message'" />
      </page-section>
      <page-section :margin-top="'sm'">
        <content-column>
          <p>
            Still can't find the answer to your question? Send us a message and we'll be happy to
            help!
          </p>
        </content-column>
      </page-section>
      <page-section :margin-top="'sm'">
        <content-column>
          <standard-button :label="'Send us a message'" :on-click="onSendMessageClicked" />
        </content-column>
      </page-section>
    </div>
    <div v-else-if="showEmail">
      <page-section :margin-top="'xl'">
        <content-column>
          <p class="text-fl-xl">
            To contact our customer support, please email <b>support@scottsbasslessons.com</b>.
          </p>
        </content-column>
      </page-section>
    </div>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import PageSectionHeader from '../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import LinkButton from '../../core/button/LinkButton.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {getHelpScoutArticles} from '../../../backend/helpscout/helpscout-query';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {HelpScoutArticle} from '../../../backend/helpscout/helpscout-types';
import {useStore} from '../../../store/store';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentColumn,
    ContentEntryHeader,
    LinkButton,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    helpscoutUsername: {type: String, required: true},
    helpscoutPassword: {type: String, required: true},
    helpscoutArticleCollection: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const store = useStore();
    const articles = ref<ReadonlyArray<HelpScoutArticle>>([]);

    const emailTimeout = ref(false);
    const ready = computed(() => {
      return store.getters['helpScout/ready'];
    });
    const showEmail = computed(() => {
      return emailTimeout.value && !ready.value;
    });

    onMounted(async () => {
      loader.setLoading(true);
      const helpScoutArticles = await getHelpScoutArticles(
        props.helpscoutUsername,
        props.helpscoutPassword,
        props.helpscoutArticleCollection
      );
      articles.value = helpScoutArticles.filter(article => article.status === 'published');

      window.setTimeout(() => {
        emailTimeout.value = true;
        loader.setLoading(false);
      }, 1000);
    });

    const onArticleClicked = (id?: number | string) => {
      if (typeof Beacon !== 'undefined') {
        if (id !== undefined) {
          Beacon('close');
          Beacon('navigate', '/answers/');
          Beacon('article', `${id}`);
        }
      }
    };
    const onSendMessageClicked = () => {
      if (typeof Beacon !== 'undefined') {
        Beacon('open');
        Beacon('navigate', '/ask/message/');
      }
    };

    return {
      articles,
      onArticleClicked,
      onSendMessageClicked,
      ready,
      showEmail
    };
  }
});
</script>
