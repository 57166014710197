<template>
  <div class="flex justify-center">
    <div class="w-full max-w-[1920px] h-full flex">
      <div class="py-fl-5xl w-1/2 flex justify-center items-center">
        <div class="relative w-[70%]">
          <fitted-text
            :text-class="'fill-white stroke-0 font-semibold'"
            :text-spans="['All you', 'need', 'is bass.']"
            text-span-dy="1.0em"
            :text-x="0"
            :text-y="0"
            :viewbox-x="0"
            :viewbox-y="3.5"
            :viewbox-width="57.5"
            :viewbox-height="46"
          />
          <img
            class="absolute w-[25%] left-[85%] top-[57%] aspect-square"
            :src="'https://scottsbasslessons.imgix.net/content/SBL_Tag_Badge_OR.png'"
          />
        </div>
      </div>
      <div class="w-1/2 flex justify-center items-center">
        <login-form :remembered-user-name="rememberedUserName" class="w-[70%]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import FittedText from '../../../core/fitted-text/FittedText.vue';
import LoginForm from '../partials/LoginForm.vue';

export default defineComponent({
  components: {
    FittedText,
    LoginForm
  },
  props: {
    rememberedUserName: {type: String, default: ''}
  },
  setup() {}
});
</script>
