<template>
  <div :class="cfg">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';

export default defineComponent({
  props: {
    fluid: {type: String, default: undefined},
    gap: {type: Number, default: 5}
  },
  setup(props) {
    const cfg = computed(() => {
      if (props.fluid !== undefined) {
        return props.fluid;
      }
      // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
      const CONFIG = [
        '[&>*+*]:mt-fl-4xs',
        '[&>*+*]:mt-fl-3xs',
        '[&>*+*]:mt-fl-2xs',
        '[&>*+*]:mt-fl-xs',
        '[&>*+*]:mt-fl-sm',
        '[&>*+*]:mt-fl-md',
        '[&>*+*]:mt-fl-lg',
        '[&>*+*]:mt-fl-xl',
        '[&>*+*]:mt-fl-2xl',
        '[&>*+*]:mt-fl-3xl',
        '[&>*+*]:mt-fl-4xl'
      ];
      const gap = CONFIG[props.gap - 1];
      if (gap === undefined) {
        throw new Error('Invalid gap');
      }
      return gap;
    });
    return {cfg};
  }
});
</script>
