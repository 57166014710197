<template>
  <page-content v-if="learningPathway !== undefined && trails !== undefined">
    <page-section>
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <content-entry-header>{{ learningPathway.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'xs'">
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="learningPathway" />
          <content-entry-header-levels-and-subjects :content-entry="learningPathway" />
          <content-entry-header-beginner-journey :trails="trails" />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'xs'">
      <content-column>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="text-2xl lg:w-9/12" v-html="learningPathway.summary"></div>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-entry-header-actions
        :guest="guest"
        :content-entry="learningPathway"
        :label="'Pathway'"
        :show-groove-trainer-button="isBeginnerJourney"
        :show-new-member-huddle-button="isBeginnerJourney"
        :progress="progress"
        :bookmark="bookmark"
      />
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column>
        <content-entry-header-progress
          :guest="guest"
          :progress="progress"
          :label="'Pathway Progress'"
        />
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column>
        <standard-heading :size="2">Courses</standard-heading>
      </content-column>
    </page-section>
    <page-section>
      <learning-pathway-course-grid
        :guest="guest"
        :progress="progress.childProgress.value"
        :duration="learningPathway.duration"
        :bookmark="bookmark"
        :pagination="pagination"
        :width="'normal'"
      />
    </page-section>

    <!-- Beginner journey components -->
    <beginner-pathway-study-pieces
      v-if="isBeginnerJourney"
      :guest="guest"
      :bookmark="bookmark"
      :learning-pathway="learningPathway"
    />
    <additional-tools-cards
      v-if="isBeginnerJourney"
      :guest="guest"
      :margin-top="'lg'"
      :width="'normal'"
    />

    <content-entry-recommendations
      :margin-top="'lg'"
      :guest="guest"
      :content-entry="learningPathway"
      :bookmark="bookmark"
    />

    <content-entry-trailer
      :heading="'About this Pathway'"
      :content-entry="learningPathway"
      :guest="guest"
    />
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import LearningPathwayCourseGrid from '../partials/LearningPathwayCourseGrid.vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageContent from '../../../core/page/PageContent.vue';
import ContentEntryHeader from '../../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderActions from '../../../content-entry/partials/header/ContentEntryHeaderActions.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryTrailer from '../../../content-entry/partials/body/ContentEntryTrailer.vue';
import ContentEntryRecommendations from '../../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import ContentEntryHeaderLevelsAndSubjects from '../../../content-entry/partials/header/metadata/ContentEntryHeaderLevelsAndSubjects.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryHeaderProgress from '../../../content-entry/partials/header/metadata/ContentEntryHeaderProgress.vue';
import BeginnerPathwayStudyPieces from '../../beginner-journey/partials/learning-pathway-partials/BeginnerPathwayStudyPieces.vue';
import AdditionalToolsCards from '../../../content-pages/beginner-journey/partials/AdditionalToolsCards.vue';
import {LearningPathway} from '../../../../backend/content/learning-pathways/learning-pathways-types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';
import {PaginationComposition} from '../../../vue-composition/pagination/pagination';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {entryIsInBeginnerJourney} from '../../beginner-journey/utils';

export default defineComponent({
  components: {
    PageSection,
    PageContent,
    StandardHeading,
    LearningPathwayCourseGrid,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeader,
    ContentEntryHeaderActions,
    ContentEntryHeaderTutors,
    ContentEntryHeaderProgress,
    ContentEntryTrailer,
    ContentEntryRecommendations,
    ContentColumn,
    Cluster,
    ContentEntryHeaderLevelsAndSubjects,
    ContentEntryHeaderBeginnerJourney,
    BeginnerPathwayStudyPieces,
    AdditionalToolsCards
  },
  props: {
    guest: {type: String, required: true},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    learningPathway: {type: Object as PropType<Readonly<LearningPathway>>, required: true},
    pagination: {type: Object as PropType<Readonly<PaginationComposition>>, required: true}
  },
  setup(props) {
    const isBeginnerJourney = computed(() => {
      return entryIsInBeginnerJourney(props.learningPathway);
    });
    return {
      isBeginnerJourney
    };
  }
});
</script>
