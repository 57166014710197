<template>
  <div class="h-[20px] flex flex-column justify-center items-center" @click.prevent="onClick">
    <input
      ref="inputElement"
      type="range"
      min="0"
      max="1000"
      :value="percent"
      class="text-4xl font-sans font-semibold bg-darkest"
      @change.prevent="onInput"
    />
  </div>
</template>

<script lang="ts">
import {ref, PropType, defineComponent, watch, computed} from 'vue';

export default defineComponent({
  components: {},
  props: {
    position: {type: Number, required: true},
    onChange: {type: Function as PropType<(percent: number) => void>, required: true}
  },
  setup(props) {
    const inputElement = ref(null as InstanceType<typeof HTMLInputElement> | null);
    watch(
      () => props.position,
      value => {
        if (inputElement.value !== null) {
          inputElement.value.value = `${value * 1000}`;
        }
      }
    );
    const percent = computed(() => {
      return props.position * 1000;
    });
    const onClick = (event: Readonly<MouseEvent>) => {
      const target = event.target as HTMLDivElement;
      const rect = target.getBoundingClientRect();
      const pos = (event.pageX - rect.x) / rect.width;
      props.onChange(pos);
    };
    const onInput = (event: Readonly<Event>) => {
      const mouseEvent = event as MouseEvent;
      const target = mouseEvent.target as HTMLInputElement;
      const pos = parseInt(target.value) / 1000.0;
      props.onChange(pos);
    };
    return {
      inputElement,
      percent,
      onInput,
      onClick
    };
  }
});
</script>

<style scoped>
/*
  https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
*/

/*
  Clear default styling.
*/
input[type='range'] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/*
  Thumb
*/

/* WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  cursor: pointer;
  margin-top: -10px;
}
/* Firefox */
input[type='range']::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  cursor: pointer;
}
/* IE */
input[type='range']::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  cursor: pointer;
}

/*
  Track
*/

/* Webkit/Blink */
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #f1f1f1;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #f1f1f1;
}
/* Firefox */
input[type='range']::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #f1f1f1;
}
/* IE */
input[type='range']::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #f1f1f1;
}
input[type='range']:focus::-ms-fill-lower {
  background: #f1f1f1;
}
input[type='range']::-ms-fill-upper {
  background: #f1f1f1;
}
input[type='range']:focus::-ms-fill-upper {
  background: #f1f1f1;
}
</style>
