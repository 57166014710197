<template>
  <variants>
    <template #sm->
      <margins>
        <content-area>
          <div
            :class="`p-fl-lg flex flex-col gap-fl-lg justify-center items-center rounded-lg ${background}`"
          >
            <div class="flex flex-col justify-center items-center">
              <div class="mb-fl-md text-fl-5xl text-primary font-semibold text-center">
                Next Seminar
              </div>
              <div :class="`${dateTextSize} text-white font-semibold text-center`">
                {{ countdown.date.value }}
              </div>
            </div>

            <div :class="portraitSize">
              <portrait-button
                v-if="avatar !== undefined"
                :config="avatar"
                :tooltip="'To seminar'"
                :url="contentEntry.url"
              />
            </div>

            <div :class="`${seminarAreaPadding} flex flex-col gap-fl-lg-xl`">
              <a :href="contentEntry.url" class="flex flex-col gap-fl-sm">
                <div :class="`${timeTextSize} text-fl-3xl text-center text-white font-semibold`">
                  {{ countdown.time.value }}
                </div>
                <div :class="`${titleTextSize} text-center text-white font-semibold`">
                  {{ contentEntry.title }}
                </div>
                <div :class="`${tutorTextSize} text-center text-light font-semibold`">
                  {{ tutors }}
                </div>
              </a>
              <div class="flex flex-col flex-wrap gap-fl-lg justify-center items-center">
                <standard-button
                  :size="buttonSize"
                  :url="contentEntry.url"
                  :padding="buttonPadding"
                  :label="'Join Live Class'"
                />
                <content-entry-header-bookmark
                  :guest="guest"
                  :content-entry="contentEntry"
                  :bookmark="bookmark"
                />
              </div>
            </div>
          </div>
        </content-area>
      </margins>
    </template>
    <template #md+>
      <margins>
        <column-grid
          :area="boxArea"
          :class="`py-fl-lg-2xl ${background} rounded-lg`"
          :row-gap="'gap-y-fl-xl'"
        >
          <content-area :area="contentAreaValue">
            <div class="flex flex-col justify-center items-center">
              <div class="mb-fl-md text-fl-7xl text-primary font-semibold text-center">
                Next Seminar
              </div>
              <div :class="`px-fl-lg ${dateTextSize} text-white font-semibold text-center`">
                {{ countdown.date.value }}
              </div>
            </div>
          </content-area>
          <column-grid :area="contentAreaValue">
            <content-area>
              <div :class="`${contentAreaPadding} flex justify-center items-center`">
                <!-- Portrait area -->
                <div class="flex flex-col justify-center items-center">
                  <div :class="portraitSize">
                    <portrait-button
                      v-if="avatar !== undefined"
                      :config="avatar"
                      :tooltip="'To seminar'"
                      :url="contentEntry.url"
                    />
                  </div>
                </div>

                <!-- Text area -->
                <div :class="`${seminarAreaPadding} flex flex-col gap-fl-lg-xl`">
                  <a :href="contentEntry.url" class="flex flex-col gap-fl-sm">
                    <div :class="`${timeTextSize} text-fl-3xl text-white font-semibold`">
                      {{ countdown.time.value }}
                    </div>
                    <div :class="`${titleTextSize} text-white font-semibold`">
                      {{ contentEntry.title }}
                    </div>
                    <div :class="`${tutorTextSize} text-mid font-semibold`">{{ tutors }}</div>
                  </a>
                  <div class="flex gap-fl-lg items-center">
                    <standard-button
                      :size="buttonSize"
                      :url="contentEntry.url"
                      :padding="buttonPadding"
                      :color="'light'"
                      :label="'Go to Seminar'"
                    />
                    <content-entry-header-bookmark
                      :guest="guest"
                      :content-entry="contentEntry"
                      :bookmark="bookmark"
                    />
                  </div>
                </div>
              </div>
            </content-area>
          </column-grid>
        </column-grid>
      </margins>
    </template>
  </variants>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import ColumnGrid from '../../../core/column-layout/ColumnGrid.vue';
import PortraitButton from '../../../core/button/PortraitButton.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {useStandardPageArea} from '../../../core/column-layout/utils';
import {deviceTypeSwitch, useDeviceType} from '../../../vue-composition/device-type/device-type';
import {ColumnArea} from '../../../core/column-layout/types';
import {
  getAvatarImage,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ButtonSize} from '../../../core/button/implementation/utils';
import {useCountdown} from '../../../vue-composition/countdown/countdown';

export default {
  components: {
    Variants,
    Margins,
    ContentArea,
    ColumnGrid,
    PortraitButton,
    StandardButton,
    ContentEntryHeaderBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    titleImage: {type: String as PropType<CraftUrl>, default: undefined},
    titleText: {type: String, default: undefined}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const spa = useStandardPageArea(deviceType, 'wide');
    const boxArea = computed(() => spa.area.value);
    const contentAreaValue = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: 4},
      sm: {start: 1, span: 12},
      lg: {start: 2, span: 10},
      xl: {start: 2, span: 10},
      '2xl': {start: 4, span: 6}
    });
    const contentAreaPadding = deviceTypeSwitch<string>({
      narrow: 'px-fl-lg',
      md: 'px-fl-xl'
    });
    const seminarAreaPadding = deviceTypeSwitch<string>({
      narrow: '',
      md: 'pl-fl-lg',
      lg: 'pl-fl-xl'
    });

    const dateTextSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-7xl',
      sm: 'text-fl-8xl',
      md: 'text-fl-9xl',
      lg: 'text-fl-10xl',
      xl: 'text-fl-11xl',
      '2xl': 'text-fl-12xl'
    });
    const titleTextSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-2xl',
      sm: 'text-fl-2xl',
      md: 'text-fl-3xl',
      lg: 'text-fl-3xl',
      xl: 'text-fl-4xl',
      '2xl': 'text-fl-4xl'
    });
    const tutorTextSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-xl',
      sm: 'text-fl-xl',
      md: 'text-fl-2xl',
      lg: 'text-fl-2xl',
      xl: 'text-fl-3xl',
      '2xl': 'text-fl-3xl'
    });
    const timeTextSize = deviceTypeSwitch<string>({
      narrow: 'text-fl-xl',
      sm: 'text-fl-xl',
      md: 'text-fl-2xl',
      lg: 'text-fl-2xl',
      xl: 'text-fl-3xl',
      '2xl': 'text-fl-3xl'
    });

    const buttonSize = deviceTypeSwitch<ButtonSize>({
      narrow: 'lg'
    });
    const buttonPadding = deviceTypeSwitch<string>({
      narrow: 'px-fl-sm py-fl-xs',
      md: 'px-fl-md py-fl-sm',
      lg: 'px-fl-md py-fl-sm'
    });

    const portraitSize = deviceTypeSwitch<string>({
      narrow: 'w-[150px] h-[150px]',
      lg: 'w-[190px] h-[190px]',
      xl: 'w-[200px] h-[200px]',
      '2xl': 'w-[240px] h-[240px]'
    });
    const avatar = computed(() => {
      const tutor = props.contentEntry.tutors[0];
      if (tutor !== undefined) {
        return getAvatarImage(tutor.imageCollection, tutor.title, {
          narrow: 150,
          lg: 190,
          xl: 200,
          '2xl': 240
        });
      }
      return undefined;
    });

    const tutors = computed(() => {
      return `with ${tutorList(props.contentEntry)}`;
    });

    const background = computed(
      () =>
        "bg-[url('https://scottsbasslessons.imgix.net/content/livenow-bg.jpg')] bg-cover bg-no-repeat bg-center"
    );

    const countdown = useCountdown(props.contentEntry, false);

    return {
      boxArea,
      background,
      contentAreaValue,
      contentAreaPadding,
      seminarAreaPadding,
      portraitSize,
      dateTextSize,
      titleTextSize,
      tutorTextSize,
      timeTextSize,
      buttonSize,
      buttonPadding,
      avatar,
      tutors,
      countdown
    };
  }
};
</script>
