<template>
  <content-entry-reel
    :guest="guest"
    :content-entries="contentEntries"
    :bookmark="bookmark"
    :width="'wide'"
  />
</template>

<script lang="ts">
import {PropType} from 'vue';
import ContentEntryReel from '../../../content-entry/collections/variants/ContentEntryReel.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';

export default {
  components: {
    ContentEntryReel
  },
  props: {
    guest: {type: String, required: true},
    contentEntries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup() {}
};
</script>
