<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- Values -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'">
    <content-column>
      <standard-heading
        v-if="deviceType.screen.value === 'narrow'"
        :size="2"
        v-html="valuesHeading"
      ></standard-heading>
      <standard-heading v-else :size="1" v-html="valuesHeading"></standard-heading>
    </content-column>
  </page-section>

  <page-section :padding-top="'lg'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <content-column :class="'text-fl-3xl'">
      <flow>
        <img
          src="https://scottsbasslessons.imgix.net/content/ambient-bass-player-bw.jpg?auto=format"
          alt=""
          aria-hidden="true"
          loading="lazy"
          class="mb-fl-lg"
        />
        <p>
          {{ valuesText1 }}
        </p>
        <p>
          {{ valuesText2 }}
        </p>
      </flow>
    </content-column>
  </page-section>

  <!-- Vision & mission -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <content-column>
      <standard-heading :size="4" class="mb-fl-sm">{{ visionSubHeading }}</standard-heading>
      <standard-heading
        v-if="deviceType.screen.value === 'narrow'"
        :size="2"
        v-html="visionHeading"
      ></standard-heading>
      <standard-heading v-else :size="1" v-html="visionHeading"></standard-heading>
    </content-column>
  </page-section>
  <page-section :padding-top="'lg'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <content-column>
      <mission-list />
    </content-column>
  </page-section>

  <!-- Roots -->
  <page-section :padding-top="'xl'" :margin-top="'none'" :margin-bottom="'none'" class="text-white">
    <content-column>
      <standard-heading :size="4" class="mb-fl-sm">{{ rootsSubHeading }}</standard-heading>
      <standard-heading v-if="deviceType.screen.value === 'narrow'" :size="2">
        {{ rootsHeading }}
      </standard-heading>
      <standard-heading v-else :size="1"> {{ rootsHeading }}</standard-heading>
      <img
        src="https://scottsbasslessons.imgix.net/content/scott2010-2.jpg?auto=format"
        alt=""
        aria-hidden="true"
        loading="lazy"
        class="my-fl-lg"
      />
      <flow :class="'text-fl-3xl'">
        <p v-html="rootsText1"></p>
        <p>
          {{ rootsText2 }}
        </p>
        <p>
          {{ rootsText3 }}
        </p>
      </flow>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Flow from '../../../core/compositions/Flow.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import MissionList from '../partials/MissionList.vue';
import {
  valuesHeading,
  valuesText1,
  valuesText2,
  visionSubHeading,
  visionHeading,
  rootsSubHeading,
  rootsHeading,
  rootsText1,
  rootsText2,
  rootsText3
} from '../utils/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Flow,
    StandardHeading,
    MissionList
  },
  setup() {
    const deviceType = useDeviceType();
    return {
      deviceType,
      valuesHeading,
      valuesText1,
      valuesText2,
      visionSubHeading,
      visionHeading,
      rootsSubHeading,
      rootsHeading,
      rootsText1,
      rootsText2,
      rootsText3
    };
  }
});
</script>
