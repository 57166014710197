<template>
  <page-content>
    <page-section :margin-top="'none'" :padding-top="'md'">
      <centered-column>
        <div class="w-20 h-20">
          <logo :display-type="'solid'" />
        </div>
      </centered-column>
    </page-section>

    <add-on-purchase
      v-if="offer !== undefined && trialDuration !== undefined"
      :offer="offer"
      :email="email"
      :trial-duration="trialDuration"
      :on-cancel="onCancel"
      :on-complete="onComplete"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import CenteredColumn from '../../core/compositions/CenteredColumn.vue';
import Logo from '../../generic/logo/Logo.vue';
import AddOnPurchase from './partials/AddOnPurchase.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {AddOnOffer} from '../../../backend/offer/addon-offer-types';
import {redirectWithHistory} from '../../../utils/url';
import {getAddOnOffersForCurrentUser} from '../../../backend/offer/addon-offer-utils';
import {AccessPassTrialDurations} from '../../../backend/offer/signup-offer-types';
import {signupStep} from '../../../backend/signup/checkout-query';

/**
 * This component is used during signups; it presents the specified
 * add-on offer to the currently logged in student and allows them
 * to purchase the corresponding access pass.
 */
/*
  ??? This component is almost verbatim the same as SignupUpgrade.vue
  (in this folder), the only difference is the type of purchase being
  made (add-on vs upgrade). We should refactor the cart/purchase
  system so that the presentation of offers is independent from the
  mechanism required to purchase them. ALSO, the main difference
  between this component and the AccountAddOn.vue component
  in this folder is that this redirects to the next step in the signup
  flow, whereas that component redirects to the student's account page.
*/
export default defineComponent({
  components: {
    PageContent,
    PageSection,
    CenteredColumn,
    Logo,
    AddOnPurchase
  },
  props: {
    serverDate: {type: String, required: true},
    email: {type: String, required: true},
    offerId: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();

    const offer = ref<Readonly<AddOnOffer> | undefined>(undefined);
    const trialDurations = ref<Readonly<AccessPassTrialDurations> | undefined>(undefined);
    const accessPass = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerDestination[0];
    });
    const trialDuration = computed(() => {
      if (accessPass.value === undefined) {
        return undefined;
      }
      if (trialDurations.value === undefined) {
        return undefined;
      }
      return trialDurations.value[accessPass.value.id];
    });

    const goToSurvey = () => {
      loader.setLoading(true);
      redirectWithHistory('/sign-up/onboarding-survey');
    };

    const onComplete = async () => {
      if (offer.value !== undefined) {
        if (offer.value.offerThankYouPage.length > 0) {
          const thankYouPage = offer.value.offerThankYouPage[0];
          if (thankYouPage !== undefined) {
            loader.setLoading(true);
            redirectWithHistory(thankYouPage.url);
            return;
          }
        }
      }
      goToSurvey();
    };
    const onCancel = async () => {
      goToSurvey();
    };

    onMounted(async () => {
      loader.setLoading(true);
      const allOffers = await getAddOnOffersForCurrentUser(
        false, // Users are signed in on the signup upsell/add-on page
        ['signup'],
        undefined,
        props.serverDate,
        [props.offerId]
      );
      loader.setLoading(false);

      if (allOffers.length !== 1) {
        goToSurvey();
        return;
      }
      offer.value = allOffers[0];

      const destination = offer.value.offerDestination[0];
      // See if the addon offer overrides the default payment plan trial length.
      if (offer.value.offerTrialDuration !== null) {
        const result: AccessPassTrialDurations = {};
        result[destination.id] = offer.value.offerTrialDuration;
        trialDurations.value = result;
      } else {
        throw new Error('No trial duration');
      }

      loader.setLoading(true);
      /*
        ??? This component could be used for other add-ons; replace the
        hard-coded fields in Active Campaign with a system that can make
        use of access pass information. Better yet, don't let Craft be
        responsible for updating customers' status in AC.
      */
      signupStep('liveClass', props.email, 'payment');
      loader.setLoading(false);
    });

    return {
      offer,
      trialDuration,
      onComplete,
      onCancel
    };
  }
});
</script>
