<template>
  <div>
    <guest-dashboard
      v-if="guest"
      :guest="guest"
      :user-friendly-name="userFriendlyName"
      :active-webcasts="activeWebcasts"
      :bookmark="bookmark"
      :curation="curation"
      :most-recent-mentor-seminar="mostRecentMentorSeminar"
      :most-recent-student-focus="mostRecentStudentFocus"
      :popular-courses="popularCourses"
      :recently-released="recentlyReleased"
      :section-promo-cards="sectionPromoCards"
      :tutors="allTutors"
      :upcoming-mentor-seminars="upcomingMentorSeminars"
      :promotions="promotions"
    />
    <new-member-dashboard
      v-else-if="showWelcome"
      :on-hide-welcome="onHideWelcome"
      :show-quick-guide="showQuickGuide"
      :on-hide-quick-guide="onHideQuickGuide"
      :show-huddle="showHuddle"
      :on-hide-huddle="onHideHuddle"
      :guest="guest"
      :user-friendly-name="userFriendlyName"
      :active-webcasts="activeWebcasts"
      :bookmark="bookmark"
      :curation="curation"
      :most-recent-mentor-seminar="mostRecentMentorSeminar"
      :most-recent-student-focus="mostRecentStudentFocus"
      :popular-courses="popularCourses"
      :recently-released="recentlyReleased"
      :section-promo-cards="sectionPromoCards"
      :tutors="allTutors"
      :upcoming-mentor-seminars="upcomingMentorSeminars"
      :promotions="promotions"
    />
    <member-dashboard
      v-else
      :guest="guest"
      :show-quick-guide="showQuickGuide"
      :on-hide-quick-guide="onHideQuickGuide"
      :show-huddle="showHuddle"
      :on-hide-huddle="onHideHuddle"
      :user-friendly-name="userFriendlyName"
      :active-webcasts="activeWebcasts"
      :bookmark="bookmark"
      :curation="curation"
      :most-recent-mentor-seminar="mostRecentMentorSeminar"
      :most-recent-student-focus="mostRecentStudentFocus"
      :popular-courses="popularCourses"
      :recently-released="recentlyReleased"
      :section-promo-cards="sectionPromoCards"
      :tutors="allTutors"
      :upcoming-mentor-seminars="upcomingMentorSeminars"
      :promotions="promotions"
    />
  </div>
</template>

<script lang="ts">
import {computed, onMounted, ref} from 'vue';
import {DateTime} from 'luxon';
import MemberDashboard from './variations/MemberDashboard.vue';
import GuestDashboard from './variations/GuestDashboard.vue';
import NewMemberDashboard from './variations/NewMemberDashboard.vue';
import {getUserFlags, setUserFlags} from '../../../backend/user/user-query';
import {useFullScreenLoader, useLoader} from '../../vue-composition/loader/loader';
import {UserFlags} from '../../../backend/user/user-types';
import {
  fetchLatestEntries,
  fetchLatestEntriesInSection,
  fetchPendingContentEntriesInSection
} from '../../../backend/content/content-entry/content-entry-query-utils';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {getAllCurations} from '../../../backend/curation/curation-query';
import {Curation} from '../../../backend/curation/curation-types';
import {getAllFeaturedMentors} from '../../../backend/tutor/tutor-query';
import {Tutor} from '../../../backend/tutor/tutor-types';
import {getActiveWebcasts} from '../../../backend/webcast/webcast-utils';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {getPopularCourses, getSectionPromos} from './implementation/utils';
import {usePromotions} from '../../vue-composition/promotion/promotion';

export default {
  components: {
    MemberDashboard,
    NewMemberDashboard,
    GuestDashboard
  },
  props: {
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true},
    userId: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const loader = useLoader();
    const fullscreenLoader = useFullScreenLoader();

    const userFlags = ref<Readonly<UserFlags> | undefined>(undefined);

    const recentlyReleased = ref<ReadonlyArray<ContentEntry>>([]);
    const popularCourses = ref<ReadonlyArray<ContentEntry>>([]);

    const activeWebcasts = ref<ReadonlyArray<ContentEntry>>([]);
    const upcomingMentorSeminars = ref<ReadonlyArray<ContentEntry>>([]);
    const mostRecentMentorSeminar = ref<ReadonlyArray<ContentEntry>>([]);

    const mostRecentStudentFocus = ref<ReadonlyArray<ContentEntry>>([]);

    const allTutors = ref<ReadonlyArray<Tutor>>([]);

    const curation = ref<ReadonlyArray<Curation>>([]);

    const bookmark = useBookmark(props.guest);

    const promotions = usePromotions(props.guest, props.userDateCreated, props.serverDate);

    onMounted(async () => {
      loader.setLoading(true);

      await promotions.init();

      if (!props.guest) {
        userFlags.value = await getUserFlags();
      }

      curation.value = await getAllCurations();
      recentlyReleased.value = await fetchLatestEntries(10);
      popularCourses.value = await getPopularCourses(10);
      activeWebcasts.value = await getActiveWebcasts('mentorSeminars');
      mostRecentMentorSeminar.value = await fetchLatestEntriesInSection(1, 'mentorSeminars');
      upcomingMentorSeminars.value = await fetchPendingContentEntriesInSection(
        'mentorSeminars',
        true
      );
      mostRecentStudentFocus.value = await fetchLatestEntriesInSection(10, 'studentFocusShows');
      allTutors.value = await getAllFeaturedMentors();
      await bookmark.init();

      loader.setLoading(false);
    });

    const sectionPromoCards = getSectionPromos();

    /*
      ??? Remove this once a reasonable time has passed since the Craft 4 launch.
      Get rid of this check, as well as the BetaPopup component. (We don't want to
      rely on it to display other news, and it uses a browser local storage cookie
      for storing its show/hide state.)
    */
    const showBetaPopup = computed(() => {
      const accountDate = DateTime.fromISO(props.userDateCreated).valueOf();
      const cutoff = DateTime.fromISO('2023-08-09T00:00:00.000').valueOf();
      return accountDate < cutoff;
    });

    const showWelcome = computed(() => {
      if (userFlags.value === undefined) {
        return false;
      }
      return !userFlags.value.dashboardHideWelcome;
    });
    const onHideWelcome = async () => {
      if (userFlags.value !== undefined) {
        fullscreenLoader.setLoading(true);
        await setUserFlags({
          ...userFlags.value,
          dashboardHideWelcome: true
        });
        userFlags.value = await getUserFlags();
        fullscreenLoader.setLoading(false);
      }
    };
    const showQuickGuide = computed(() => {
      if (userFlags.value === undefined) {
        return false;
      }
      return !userFlags.value.dashboardHideQuickGuide;
    });
    const onHideQuickGuide = async () => {
      if (userFlags.value !== undefined) {
        fullscreenLoader.setLoading(true);
        await setUserFlags({
          ...userFlags.value,
          dashboardHideQuickGuide: true
        });
        userFlags.value = await getUserFlags();
        fullscreenLoader.setLoading(false);
      }
    };
    const showHuddle = computed(() => {
      if (userFlags.value === undefined) {
        return false;
      }
      return !userFlags.value.dashboardHideNewMemberHuddle;
    });
    const onHideHuddle = async () => {
      if (userFlags.value !== undefined) {
        fullscreenLoader.setLoading(true);
        await setUserFlags({
          ...userFlags.value,
          dashboardHideNewMemberHuddle: true
        });
        userFlags.value = await getUserFlags();
        fullscreenLoader.setLoading(false);
      }
    };

    return {
      showBetaPopup,
      showWelcome,
      onHideWelcome,
      showQuickGuide,
      onHideQuickGuide,
      showHuddle,
      onHideHuddle,
      userFlags,
      recentlyReleased,
      popularCourses,
      curation,
      bookmark,
      activeWebcasts,
      mostRecentMentorSeminar,
      upcomingMentorSeminars,
      mostRecentStudentFocus,
      allTutors,
      sectionPromoCards,
      promotions
    };
  }
};
</script>
