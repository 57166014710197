<template>
  <div v-if="accessPass.type === 'subscription'" class="max-w-[300px] flex flex-col gap-fl-md">
    <standard-button
      v-if="
        accessPass.status === 'active' ||
        accessPass.status === 'pastDue' ||
        accessPass.status === 'trial' ||
        accessPass.status === 'pending'
      "
      :expand="true"
      :label="'Change Payment Method'"
      :on-click="onUpdate"
    />
    <div :id="`cancel-${accessPass.slug}`">
      <standard-button
        v-if="
          accessPass.status === 'active' ||
          accessPass.status === 'pastDue' ||
          accessPass.status === 'trial' ||
          accessPass.status === 'pending'
        "
        :expand="true"
        :label="'Cancel Subscription'"
        :on-click="onCancel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import StandardButton from '../../../../../core/button/StandardButton.vue';
import {AccessPassStatus} from '../../../../../../backend/access-pass/access-pass-types';
import {redirectWithHistory} from '../../../../../../utils/url';

export default defineComponent({
  components: {
    StandardButton
  },
  props: {
    accessPass: {type: Object as PropType<Readonly<AccessPassStatus>>, required: true}
  },
  setup(props) {
    const onCancel = () => {
      redirectWithHistory(`/account/cancel?accessPassId=${props.accessPass.id}`);
    };
    const onUpdate = () => {
      redirectWithHistory(`/account/update?accessPassId=${props.accessPass.id}`);
    };

    return {
      onCancel,
      onUpdate
    };
  }
});
</script>
