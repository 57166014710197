<template>
  <div>
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-heading :size="2"
          >Ready to Take the Next Step? Your Membership Includes</standard-heading
        >
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'wide'">
        <div :class="`grid ${cols}`" :style="style">
          <feature-card v-for="feature in features" :key="feature.title" :feature="feature" />
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import FeatureCard from './FeatureCard.vue';
import {faGraduationCap} from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import {faComment} from '@fortawesome/pro-solid-svg-icons/faComment';
import {faPeopleGroup} from '@fortawesome/pro-solid-svg-icons/faPeopleGroup';
import {faComments} from '@fortawesome/pro-solid-svg-icons/faComments';
import {faMicrophone} from '@fortawesome/pro-solid-svg-icons/faMicrophone';
import {faCalendarDays} from '@fortawesome/pro-solid-svg-icons/faCalendarDays';
import {faFile} from '@fortawesome/pro-solid-svg-icons/faFile';
import {faClipboardQuestion} from '@fortawesome/pro-solid-svg-icons/faClipboardQuestion';
import {deviceTypeSwitch, useDeviceType} from '../../vue-composition/device-type/device-type';
import {useColumnLayout} from '../../vue-composition/column-layout/column-layout';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    FeatureCard
  },
  setup() {
    const features = [
      {
        title: 'WORLD RENOWNED COURSE LIBRARY',
        icon: faGraduationCap,
        summary:
          'Access hundreds of hours of amazing, in-depth bass guitar lessons including hours of step-by-step instruction, live bands and much more from the biggest names in bass education.'
      },
      {
        title: 'WEEKLY LIVE-STREAM SEMINARS',
        icon: faComment,
        summary:
          'Each week we host live-stream seminars with the likes of Gary Willis, Adam Neely, Ed Friedland, Rufus Philpot, Damian Erskine, Danny Mo Morris, Ariane Cap, Steve Lawson & Todd Johnson, plus many world-class educators.'
      },
      {
        title: 'AWESOME COMMUNITY',
        icon: faPeopleGroup,
        summary:
          'Connect with fellow bassists from around the world who are just like you in our community forum, The Campus. Our students are focused on getting their bass playing to the next level, but also on helping other students around them. They’ll be there for you 24/7.'
      },
      {
        title: 'DIRECT FEEDBACK AND DIRECTION',
        icon: faComments,
        summary:
          'Get direct feedback and answers to all your burning questions from Scott himself, our Community Success Team and your fellow students through our live shows, community forum or by contacting our support team directly.'
      },
      {
        title: 'THE BASS HANG',
        icon: faMicrophone,
        summary:
          'This is our regular live-stream seminar happening every other Thursday where you get to hang out with Scott and the rest of your fellow students.'
      },
      {
        title: 'CAMPUS CHALLENGES',
        icon: faCalendarDays,
        summary:
          'Throughout the year we roll out brand new challenges that are designed to make you a better bass player. The best thing about the challenges is that our whole community gets involved, which creates a fun learning environment and accountability when you need it.'
      },
      {
        title: 'COURSE RESOURCES',
        icon: faFile,
        summary:
          'Each course is accompanied by highly detailed workbooks including TAB and notation, and multiple backing tracks when needed.'
      },
      {
        title: 'EXCLUSIVE INTERVIEWS',
        icon: faClipboardQuestion,
        summary:
          'Sit back, relax and listen to hours of interviews and candid conversation with your favorite famous bass players from Hendrik Linder, Jonathan Maron and Adam Neely to Verdine White and even Billy Sheehan.'
      }
    ];

    // Compute number of columns based on screen width

    const deviceType = useDeviceType();
    const columnLayout = useColumnLayout(deviceType);

    /*
      Set the number of columns (in the 12 column layout system) that each
      card should cover. For example, if we want the cards to cover 4 columns,
      we would use 'grid-cols-3' (12 columns / 4 columns per card = 3).
    */
    const cols = deviceTypeSwitch<string>({
      narrow: 'grid-cols-1',
      sm: 'grid-cols-1',
      md: 'grid-cols-2',
      lg: 'grid-cols-2',
      xl: 'grid-cols-4',
      '2xl': 'grid-cols-4'
    });

    /*
      Return the gutter between the cards; this is the current gutter width
      in the 12 column design system.
    */
    const style = computed(() => {
      return `gap:${columnLayout.config.value.gutterWidth}px`;
    });
    return {
      cols,
      style,
      features
    };
  }
});
</script>
