type ActorGroups = {[group: string]: string | undefined};
export interface SyncStoreState {
  activeActorIds: ActorGroups;
}

export const SyncStore = {
  namespaced: true,
  state: {
    activeActorIds: {}
  } as SyncStoreState,
  mutations: {
    setActiveActorId(state: SyncStoreState, payload: {groupId: string; actorId: string}) {
      /*
        This is a hack to get around a limitation in Vuex: it cannot listen to "deep"
        changes in objects. Since this store module doesn't know what the sync actor group
        names are at startup, we cannot "normalize" the data. So we have to replace the
        object in the store with a new object that containst the desired change.
      */
      const newState: ActorGroups = {...state.activeActorIds};
      newState[payload.groupId] = payload.actorId;
      state.activeActorIds = newState;
    }
  },
  actions: {
    setActiveActorId({commit}: any, payload: {groupId: string; actorId: string}) {
      commit('setActiveActorId', payload);
    }
  }
};
