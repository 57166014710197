<template>
  <!-- Page section -->
  <div :class="`${mt} ${mb} ${pt} ${pb}`">
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';

/**
 * Page section margins:
 *
 *    TYPE     SIZE (at 1920 px)    UTOPIA
 *
 *    none     0 px                 -
 *    2xs      16 px                fl-xs-sm
 *    xs       32 px                fl-sm-md
 *    sm       48 px                fl-md-lg
 *    md       64 px                fl-lg-2xl
 *    lg       112 px               fl-lg-4xl
 *    xl       144 px               fl-lg-5xl
 *
 */
export type PageSectionMargin = 'none' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * This component represents a section on a page. It adds default top and
 * bottom margins for the section.
 */
export default defineComponent({
  props: {
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'md'},
    marginBottom: {type: String as PropType<PageSectionMargin>, default: 'none'},
    paddingTop: {type: String as PropType<PageSectionMargin>, default: 'none'},
    paddingBottom: {type: String as PropType<PageSectionMargin>, default: 'none'}
  },
  setup(props) {
    /* eslint-disable-next-line vue/return-in-computed-property */
    const mt = computed(() => {
      switch (props.marginTop) {
        case 'xl':
          return 'mt-fl-lg-5xl';
        case 'lg':
          return 'mt-fl-lg-4xl';
        case 'md':
          return 'mt-fl-lg-2xl';
        case 'sm':
          return 'mt-fl-md-lg';
        case 'xs':
          return 'mt-fl-sm-md';
        case '2xs':
          return 'mt-fl-xs-sm';
        case 'none':
          return '';
      }
    });
    /* eslint-disable-next-line vue/return-in-computed-property */
    const mb = computed(() => {
      switch (props.marginBottom) {
        case 'xl':
          return 'mb-fl-lg-5xl';
        case 'lg':
          return 'mb-fl-lg-4xl';
        case 'md':
          return 'mb-fl-lg-2xl';
        case 'sm':
          return 'mb-fl-md-lg';
        case 'xs':
          return 'mb-fl-sm-md';
        case '2xs':
          return 'mb-fl-xs-sm';
        case 'none':
          return '';
      }
    });
    /* eslint-disable-next-line vue/return-in-computed-property */
    const pt = computed(() => {
      switch (props.paddingTop) {
        case 'xl':
          return 'pt-fl-lg-5xl';
        case 'lg':
          return 'pt-fl-lg-4xl';
        case 'md':
          return 'pt-fl-lg-2xl';
        case 'sm':
          return 'pt-fl-md-lg';
        case 'xs':
          return 'pt-fl-sm-md';
        case '2xs':
          return 'pt-fl-xs-sm';
        case 'none':
          return '';
      }
    });
    /* eslint-disable-next-line vue/return-in-computed-property */
    const pb = computed(() => {
      switch (props.paddingBottom) {
        case 'xl':
          return 'pb-fl-lg-5xl';
        case 'lg':
          return 'pb-fl-lg-4xl';
        case 'md':
          return 'pb-fl-lg-2xl';
        case 'sm':
          return 'pb-fl-md-lg';
        case 'xs':
          return 'pb-fl-sm-md';
        case '2xs':
          return 'pb-fl-xs-sm';
        case 'none':
          return '';
      }
    });
    return {
      mb,
      mt,
      pt,
      pb
    };
  }
});
</script>
