<template>
  <div>
    <learning-pathway-course-card-grid v-if="pagination.items.value.length > 0">
      <learning-pathway-course-card
        v-for="(ce, index) in pagination.items.value"
        :key="ce.id"
        class="flex-1 min-w-card sm:min-w-card-sm"
        :guest="guest"
        :content-entry="ce"
        :course-number="index + 1"
        :completed="completed[ce.id]"
        :progress="progress[ce.id]"
        :duration="duration[ce.id]"
        :unlocked="unlocked[ce.id]"
        :bookmark="bookmark"
        :image-width="imageWidth"
      />
    </learning-pathway-course-card-grid>
    <content-column v-if="pagination.more.value" :width="'wide'">
      <standard-button :label="'Show More'" :on-click="pagination.onMore" />
    </content-column>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import LearningPathwayCourseCardGrid from './LearningPathwayCourseCardGrid.vue';
import LearningPathwayCourseCard from './LearningPathwayCourseCard.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {ProgressMap} from '../../../../backend/progress/progress-types';
import {CompletedMap, UnlockedMap, DurationMap} from '../../../content-entry/collections/types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PaginationComposition} from '../../../vue-composition/pagination/pagination';
import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    LearningPathwayCourseCard,
    ContentColumn,
    LearningPathwayCourseCardGrid,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    progress: {type: Object as PropType<Readonly<ProgressMap>>, default: () => ({})},
    completed: {type: Object as PropType<Readonly<CompletedMap>>, default: () => ({})},
    unlocked: {type: Object as PropType<Readonly<UnlockedMap>>, default: () => ({})},
    duration: {type: Object as PropType<Readonly<DurationMap>>, default: () => ({})},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    pagination: {type: Object as PropType<Readonly<PaginationComposition>>, required: true}
  },
  setup() {
    const imageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 340,
      md: 450,
      lg: 570,
      xl: 410,
      '2xl': 440
    };
    return {imageWidth};
  }
});
</script>
