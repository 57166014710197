export function shuffleArrayInPlace(array: Array<unknown>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

/**
 * Move an element in the specified array. NOTE: This mutates the array!
 */
export function moveArrayElement(array: Array<unknown>, fromIndex: number, toIndex: number) {
  // See https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
  if (fromIndex >= array.length || toIndex >= array.length) {
    throw new Error('Invalid array index');
  }
  return array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

/**
 * Push an item into an array, if the item doesn't already exist in the array.
 */
export function pushUnique(array: Array<any>, item: any) {
  if (!array.includes(item)) {
    array.push(item);
  }
}

/**
 * Given an array that contains arrays, return the index (in the parent array)
 * of the child array that is longest.
 */
export function getIndexOfLongest(arrayOfArrays: Readonly<Array<Array<any>>>): number {
  // See https://stackoverflow.com/questions/33577266/find-the-index-of-the-longest-array-in-an-array-of-arrays
  return arrayOfArrays.reduce((maxIndex, el, i, arr) => {
    return el.length > arr[maxIndex].length ? i : maxIndex;
  }, 0);
}

/**
 * Given an array that contains arrays, transpose the implied matrix and
 * return the result. Example:
 *
 *    [
 *      [1, 2, 3, 4],
 *      [5, 6, 7, 8],
 *    ]
 *
 * becomes
 *
 *    [
 *      [1, 5],
 *      [2, 6],
 *      [3, 7],
 *      [4, 8],
 *    ]
 */
export function transpose(arrayOfArrays: Readonly<Array<Array<any>>>) {
  return arrayOfArrays[0].map((_, c) => arrayOfArrays.map((_, r) => arrayOfArrays[r][c]));
}
