<template>
  <div v-if="landingPageEntry !== undefined">
    <page-section :margin-top="marginTop" :margin-bottom="'xs'">
      <content-entry-section-header
        :width="'wide'"
        :center="landingPage.visible.value ? centerSummary : center"
        :section-handle="sectionHandle"
        :title="title"
        :title-width="landingPage.visible.value ? titleWidthSummary : titleWidth"
        :title-image="headerImageSrc"
        :title-image-max-height="
          landingPage.visible.value ? titleImageMaxHeightSummary : titleImageMaxHeight
        "
        :title-image-max-width="
          landingPage.visible.value ? titleImageMaxWidthSummary : titleImageMaxWidth
        "
        :info-button="true"
        :on-info-button-clicked="landingPage.toggle"
        :info-button-icon="landingPage.visible.value ? iconXmark : iconInfoCircle"
        :info-button-tooltip="'Hide Information'"
        :spot-color="spotColor"
      />
    </page-section>

    <div v-if="landingPage.visible.value">
      <div class="bg-gradient-to-b from-landing-page-gradient-start to-landing-page-gradient-end">
        <page-section :margin-top="'none'">
          <content-column :width="titleWidth">
            <div
              v-if="landingPageEntry.landingPageTagline"
              class="text-center text-fl-xl sm:text-fl-3xl"
            >
              {{ landingPageEntry.landingPageTagline }}
            </div>
          </content-column>
        </page-section>

        <page-section v-if="trailer !== undefined" :margin-top="'lg'">
          <content-column>
            <video-player
              :video="trailer"
              :resume-at-last-timestamp="false"
              :preview="false"
              :guest="''"
            />
          </content-column>
        </page-section>
        <page-section v-if="coverImage !== undefined" :margin-top="'lg'">
          <content-column>
            <div class="flex justify-center">
              <imgix-image :config="coverImage" />
            </div>
          </content-column>
        </page-section>

        <page-section>
          <content-two-columns :row-gap="'gap-y-fl-lg-xl'">
            <template #left>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="text-white text-fl-2xl pr-fl-xl" v-html="landingPageEntry.summary"></div>
            </template>
            <template #right>
              <ul>
                <li v-for="feature in features" :key="feature" :class="'mb-fl-xl text-light'">
                  <div class="flex mb-fl-lg gap-fl-lg text-fl-lg">
                    <font-awesome-icon :icon="iconCheck" class="pt-1"></font-awesome-icon>
                    <div>{{ feature }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </content-two-columns>
        </page-section>

        <page-section :padding-bottom="'md'">
          <content-column>
            <link-button
              :wrap="true"
              :size="'md'"
              :expand="true"
              :label="'Hide this guide'"
              :underline="true"
              :tooltip="'Hide Summary'"
              :on-click="landingPage.toggle"
            />
          </content-column>
        </page-section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {ref, computed, defineComponent} from 'vue';
import ContentEntrySectionHeader from './ContentEntrySectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentTwoColumns from '../../../core/compositions/ContentTwoColumns.vue';
import PageSection from '../../../core/page/PageSection.vue';
import VideoPlayer from '../../../generic/video-player/VideoPlayer.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {onMounted, PropType} from '@vue/runtime-core';
import {getLandingPage} from '../../../../backend/content/landing-page/landing-page-query';
import {LandingPageEntry} from '../../../../backend/content/landing-page/landing-page-types';
import {getFirstVideoOfType} from '../../../../backend/video/video-query';
import {Video} from '../../../../backend/video/video-types';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {faXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import {LandingPageComposition} from '../../../vue-composition/landing-page/landing-page';
import {ColumnArea} from '../../../core/column-layout/types';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {ColorConfig} from '../../../core/color-config';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ImgixImageConfig, ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';
import {PageSectionMargin} from '../../../core/page/PageSection.vue';

/**
 * Content entry section summary.
 *
 * This component displays information about a content entry section.
 * It contains a video, together with a summary text and bullet point
 * list. The user can dismiss the section summary; the status is stored
 * in a local browser cookie (so that it remains dismissed when the user
 * visits the page again). The ContentEntrySectionHeader component has
 * an optional "info button" that, when clicked, shows the section summary
 * again.
 *
 * The information that is presented in the section summary is fetched
 * from the Craft backend; it is stored in a Craft Section called "Landing
 * Page". The information is fetched by a Vue composition
 * (in /vue-composition/landing-page); the composition is also responsible
 * for keeping track of the visible/hidden status flag.
 */
export default defineComponent({
  components: {
    ContentColumn,
    ContentTwoColumns,
    PageSection,
    ContentEntrySectionHeader,
    FontAwesomeIcon,
    LinkButton,
    VideoPlayer,
    ImgixImage
  },
  props: {
    // If true, then center the title.
    center: {type: Boolean, default: true},
    // If true, then center the title when displaying the summary.
    centerSummary: {type: Boolean, default: true},

    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'md'},

    // If provided, fetch the name of this section from Craft and use that as the title.
    sectionHandle: {type: String, default: undefined},
    // Section header title; can optionally be overridden by an image. (See below.)
    title: {type: String, default: undefined},

    // URL of optional image that replaces the title (e.g., learning pathways logotype)
    titleImage: {type: String as PropType<CraftUrl>, default: undefined},

    // Optional width of the header title.
    titleWidth: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    // Optional max height of title image
    titleImageMaxHeight: {type: String, default: ''},
    // Optional max height of title image
    titleImageMaxWidth: {type: String, default: ''},

    // Optional width of the header title when displaying the summary.
    titleWidthSummary: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    // Optional max height of title image when displaying the summary.
    titleImageMaxHeightSummary: {type: String, default: ''},
    // Optional max height of title image when displaying the summary.
    titleImageMaxWidthSummary: {type: String, default: ''},

    // Called when info button is clicked.
    onInfoButtonClicked: {type: Function as PropType<() => void>, default: undefined},

    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    landingPage: {type: Object as PropType<LandingPageComposition>, required: true}
  },
  setup(props) {
    const landingPageEntry = ref(undefined as Readonly<LandingPageEntry> | undefined);
    const trailer = ref(undefined as Readonly<Video> | undefined);
    const features = ref([] as Array<string>);
    const headerImageSrc = ref<Readonly<ImgixImageConfig> | undefined>(undefined);

    const coverImage = computed(() => {
      if (landingPageEntry.value === undefined) {
        return undefined;
      }
      const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
        narrow: 510,
        sm: 650,
        md: 900,
        lg: 1150,
        xl: 1150,
        '2xl': 1000
      };
      return getImageConfig(
        landingPageEntry.value.imageCollection,
        ['cover'],
        landingPageEntry.value.title,
        cardImageWidth,
        {ar: '16:9', fit: 'crop'}
      );
    });

    onMounted(async () => {
      landingPageEntry.value = await getLandingPage(props.landingPage.slug);
      if (landingPageEntry.value !== undefined) {
        trailer.value = getFirstVideoOfType(landingPageEntry.value.videos, 'trailer');
        if (landingPageEntry.value.landingPageFeatures) {
          features.value = landingPageEntry.value.landingPageFeatures.split('\n');
        }
        if (landingPageEntry.value.imageCollection.length > 0) {
          headerImageSrc.value = getImageConfig(
            landingPageEntry.value.imageCollection,
            ['header'],
            landingPageEntry.value.title,
            800,
            {}
          );
        }
      }
    });

    const leftArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      sm: {start: 3, span: 8},
      lg: {start: 3, span: 3}
    });
    const rightArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      sm: {start: 3, span: 8},
      lg: {start: 7, span: 4}
    });

    const iconCheck = computed(() => faCheck);
    const iconXmark = computed(() => faXmark);
    const iconInfoCircle = computed(() => faInfoCircle);

    return {
      trailer,
      features,
      landingPageEntry,
      headerImageSrc,
      leftArea,
      rightArea,
      iconCheck,
      iconXmark,
      iconInfoCircle,
      coverImage
    };
  }
});
</script>
