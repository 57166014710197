import {applyFilter, collectRemainingProperties} from './filter';
import {
  FilterConfig,
  FilterProperty,
  FilterPropertyList,
  FilterPropertySet,
  FilterSet,
  FilterSetFilter,
  FilterSetOutput
} from './types';

function getRemainingPropertiesForFilter(
  config: Readonly<FilterConfig>,
  selection: Readonly<FilterPropertySet>,
  objects: ReadonlyArray<any>
): FilterPropertyList {
  const remainingProperties = collectRemainingProperties(
    selection,
    objects.map(o => config.descriptor(o))
  );
  return [...remainingProperties].sort(config.comparator);
}

function getAllPropertiesForFilter(
  config: Readonly<FilterConfig>,
  objects: ReadonlyArray<any>
): FilterPropertyList {
  const result = new Set<FilterProperty>();
  objects.forEach(o => {
    const props = config.descriptor(o);
    props.forEach(prop => {
      result.add(prop);
    });
  });
  return Array.from(result).sort(config.comparator);
}

function runFilters(
  filterSet: Readonly<FilterSet>,
  objects: ReadonlyArray<any>
): ReadonlyArray<any> {
  return filterSet.reduce((result: ReadonlyArray<any>, filter: Readonly<FilterSetFilter>) => {
    const objectProperties = result.map(o => filter.config.descriptor(o));
    const outputIndicies = applyFilter(filter.selection, objectProperties);
    const filterOutput = outputIndicies.map(idx => result[idx]);
    return filterOutput;
  }, objects);
}

/**
 * Given a filter set (= an ordered list of filters), a set of selected properties
 * for each filter, and a list of objects, return the result of passing the objects
 * through the filters (in order), together with a list of all properties (per filter)
 * that were present on the objects, as well as the object properties that were not
 * included in the selection.
 */
export function applyFilterSet(
  filterSet: ReadonlyArray<FilterSetFilter>,
  objects: ReadonlyArray<any>
): Readonly<FilterSetOutput> {
  const filterOutput = runFilters(filterSet, objects);
  return {
    objects: filterOutput,
    allProperties: filterSet.map(f => getAllPropertiesForFilter(f.config, objects)),
    remainingProperties: filterSet.map(f =>
      getRemainingPropertiesForFilter(f.config, f.selection, filterOutput)
    )
  };
}
