<template>
  <div :class="borderTop">
    <div class="relative pt-fl-lg px-fl-sm overflow-hidden">
      <div
        v-if="highlight"
        class="absolute top-[-15%] right-[-30%] w-[70%] h-[45%] rotate-45 flex flex-col justify-end items-center bg-primary"
      >
        <svg class="m-[8px]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
            fill="#F1F1F1"
          ></path>
        </svg>
        <div class="mb-[7px] text-full-white text-[12px] font-bold uppercase text-center">
          Best value
        </div>
      </div>
      <h3 class="mb-fl-sm text-fl-xl text-center text-black font-semibold">{{ title }}</h3>
      <div class="mb-flmd h-[60px] flex justify-center">
        <div
          v-if="billedAs !== undefined"
          class="h-[40px] w-fit flex flex-col justify-center bg-[#e9e9e9] rounded-[40px]"
        >
          <div
            class="px-fl-md text-[clamp(12px,.72916667vw,14px)] text-primary text-center whitespace-nowrap text-nowrap uppercase font-semibold"
          >
            {{ billedAs }}
          </div>
        </div>
      </div>
      <div class="mb-fl-md">
        <div class="text-black text-fl-9xl text-center">{{ monthlyCost }}</div>
        <div class="text-black text-fl-lg text-center">USD per month</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';

export default defineComponent({
  components: {},
  props: {
    offer: {
      type: Object as PropType<Readonly<SignupOffer> | Readonly<AddOnOffer>>,
      required: true
    },
    forceBorders: {type: Boolean, default: false}
  },
  setup(props) {
    const dest = computed(() => {
      return props.offer.offerDestination[0];
    });
    const recurrence = computed(() => {
      return dest.value.accessPassRecurrence;
    });
    const title = computed(() => {
      if (props.offer.offerTitle !== null) {
        return props.offer.offerTitle;
      }
      return recurrence.value.charAt(0).toUpperCase() + recurrence.value.slice(1);
    });

    const billedAs = computed(() => {
      if (recurrence.value === 'annual') {
        return `Billed as just $${dest.value.accessPassAmount}`;
      }
      return undefined;
    });
    const monthlyCost = computed(() => {
      if (recurrence.value === 'annual') {
        const amt = (dest.value.accessPassAmount / 12).toFixed(2);
        return `$${amt}`;
      } else {
        return `$${dest.value.accessPassAmount.toFixed(2)}`;
      }
    });
    const highlight = computed(() => {
      return props.offer.offerHighlight;
    });
    const borderTop = computed(() => {
      if (props.forceBorders || highlight.value) {
        return 'border-t border-x border-t-primary border-x-primary rounded-t-lg';
      }
      return '';
    });

    return {
      borderTop,
      title,
      highlight,
      billedAs,
      monthlyCost
    };
  }
});
</script>
