export interface DurationHMS {
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Split a duration string in "HH:MM:SS" format into separate
 * hours, minutes, and seconds components.
 */
export function durationToHMS(duration: string): Readonly<DurationHMS> | undefined {
  try {
    const split = duration.split(':');
    if (split.length === 1) {
      const seconds = Number.parseInt(split[0], 10);
      if (seconds === undefined) {
        return undefined;
      }
      return {
        hours: 0,
        minutes: 0,
        seconds
      };
    }
    if (split.length === 2) {
      const minutes = Number.parseInt(split[0], 10);
      if (minutes === undefined) {
        return undefined;
      }
      const seconds = Number.parseInt(split[1], 10);
      if (seconds === undefined) {
        return undefined;
      }
      return {
        hours: 0,
        minutes,
        seconds
      };
    }
    if (split.length === 3) {
      const hours = Number.parseInt(split[0], 10);
      if (hours === undefined) {
        return undefined;
      }
      const minutes = Number.parseInt(split[1], 10);
      if (minutes === undefined) {
        return undefined;
      }
      const seconds = Number.parseInt(split[2], 10);
      if (seconds === undefined) {
        return undefined;
      }
      return {
        hours,
        minutes,
        seconds
      };
    }
  } catch (error) {
    return undefined;
  }
  return undefined;
}

/**
 * Convert an HMS duration to the corresponding number of seconds.
 */
export function HMSToSeconds(hms: Readonly<DurationHMS>): number {
  return hms.seconds + hms.minutes * 60 + hms.hours * 60 * 60;
}

/**
 * Convert seconds to HMS duration.
 */
export function secToHMS(seconds: number): Readonly<DurationHMS> {
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor(seconds / 60) % 60,
    seconds: Math.floor(seconds % 60)
  };
}

/**
 * Convert an HMS duration object to a string in "HH:MM:SS" format.
 */
export function HMSToString(hms: Readonly<DurationHMS>) {
  let result = '';
  if (hms.hours > 0) {
    result += `${hms.hours}`.padStart(2, '0');
    result += ':';
  }
  result += `${hms.minutes}`.padStart(2, '0');
  result += ':';
  result += `${hms.seconds}`.padStart(2, '0');
  return result;
}
