<template>
  <!-- Sidebar -->
  <div>
    <!-- Sidebar panel -->
    <div
      v-if="showSidebar"
      class="fixed top-0 overflow-x-hidden overflow-y-auto overscroll-contain h-screen bg-sidebar z-50"
      :style="sidebarStyle"
    >
      <div id="sidebar-attach" ref="sidebarAttach" :style="sidebarContentStyle"></div>
    </div>

    <!-- Main content area -->
    <!-- 
      w-screen + max-w-full is a combination that prevents content from slipping beneath
      the vertical scrollbar that appears when the page content extends beneath the bottom
      edge of the viewport. See
      https://stackoverflow.com/questions/23367345/100vw-causing-horizontal-overflow-but-only-if-more-than-one

      h-screen + grid is a combination that positions the site footer at the bottom of
      the page when the content of the page does not extend beyond the bottom edge of
      the viewport.
    -->
    <div :class="contentAreaClass" :style="contentStyle">
      <site-header
        :entry-id="entryId"
        :guest="guest"
        :user-friendly-name="userFriendlyName"
        :segment="segment"
      />

      <!-- Content -->
      <div id="page-content">
        <site-header-promo-banner
          :guest="guest"
          :segment="segment"
          :user-date-created="userDateCreated"
          :server-date="serverDate"
        />
        <slot></slot>
      </div>

      <full-screen-loader />

      <!-- Site footer -->
      <site-footer />

      <!-- Modal overlay (for capturing mouse clicks and hold dialogs) -->
      <modal-overlay />
    </div>
  </div>
</template>

<script lang="ts">
import {computed, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {useAnimatedToggle} from '../../../vue-composition/animation/linear';
import {useStore} from '../../../../store/store';
import {useModalOverlay} from '../../../vue-composition/modal-overlay/modal-overlay';
import ModalOverlay from '../modal-overlay/ModalOverlay.vue';
import SiteHeader from '../../../site-header/SiteHeader.vue';
import SiteFooter from '../../../site-footer/SiteFooter.vue';
import SiteHeaderPromoBanner from '../site-header-promo-banner/SiteHeaderPromoBanner.vue';
import FullScreenLoader from '../../../generic/loader/FullScreenLoader.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {lerp} from '../../../../utils/math';

export default {
  components: {
    ModalOverlay,
    SiteHeader,
    SiteFooter,
    SiteHeaderPromoBanner,
    FullScreenLoader
  },
  props: {
    entryId: {type: String, required: true},
    guest: {type: String, required: true},
    userId: {type: String, default: ''},
    userFriendlyName: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true},
    segment: {type: String, default: undefined}
  },
  setup(props) {
    const sidebarAttach = ref(null as InstanceType<typeof HTMLDivElement> | null);

    const animation = useAnimatedToggle(0.25, false, 60);
    const modalOverlay = useModalOverlay();

    const visible = ref(false);
    const showSidebar = computed(() => {
      if (visible.value) {
        return animation.easeInOut.value > 0.0;
      }
      return !(animation.easeInOut.value === 0.0);
    });

    // ??? This shouldn't be here: the sidebar shouldn't have to know which page it is on.
    /* eslint-disable-next-line vue/return-in-computed-property */
    const contentAreaClass = computed(() => {
      if (props.segment === undefined || props.segment !== 'login') {
        return 'absolute top-0 z-0 w-screen max-w-full min-h-screen grid grid-rows-[auto_1fr_auto]';
      } else if (props.segment === 'login') {
        return `absolute top-0 z-0 w-screen max-w-full min-h-screen grid grid-rows-[auto_1fr_auto] sm:bg-none 
          md:bg-[url('https://scottsbasslessons.imgix.net/content/login-background.png?w=1024&auto=format')]
          lg:bg-[url('https://scottsbasslessons.imgix.net/content/login-background.png?w=1280&auto=format')]
          xl:bg-[url('https://scottsbasslessons.imgix.net/content/login-background.png?w=1536&auto=format')]
          md:bg-cover md:bg-no-repeat md:bg-left-bottom`;
      }
    });

    /*
      Compute the width of the sidebar.
    */
    const deviceType = useDeviceType();
    /* eslint-disable-next-line vue/return-in-computed-property */
    const sidebarWidth = computed(() => {
      switch (deviceType.screen.value) {
        case undefined:
        case 'narrow':
          const factor = lerp(0.9, 0.5, deviceType.interpolations['narrow'].value);
          return deviceType.viewportWidth.value * factor;
        case 'sm':
          return deviceType.viewportWidth.value * 0.5;
        case 'md':
          return deviceType.viewportWidth.value * 0.4;
        case 'lg':
          return deviceType.viewportWidth.value * 0.3;
        case 'xl':
          return deviceType.viewportWidth.value * 0.3;
        case '2xl':
          return Math.min(1920, deviceType.viewportWidth.value) * 0.25;
      }
    });
    /*
      ### This is a hack to avoid the vertical scrollbar to shift the
      sidebar content around when it appears; we set a fixed with for
      the sidebar, and then set the width of the content div to be
      slightly smaller to make room for the scrollbar. (We essentially
      guess the width of the scrollbar - there's no way to know.)
      Once 'scrollbar-gutter' is supported in Safari we can get rid of
      this hack.
    */
    const sidebarContentWidth = computed(() => {
      return sidebarWidth.value - 25;
    });

    const sidebarLocation = computed(() => {
      return store.getters['sidebar/location'];
    });
    const sidebarPos = computed(() => {
      // Map [0, 1] to [-width, 0]
      return (1.0 - animation.easeInOut.value) * -sidebarWidth.value;
    });
    const contentPos = computed(() => {
      return animation.easeInOut.value * sidebarWidth.value;
    });
    const contentStyle = computed(() => {
      return sidebarLocation.value === 'left'
        ? `left:${contentPos.value}px`
        : `right:${contentPos.value}px`;
    });
    const sidebarStyle = computed(() => {
      return sidebarLocation.value === 'left'
        ? `width:${sidebarWidth.value}px;left:${sidebarPos.value}px`
        : `width:${sidebarWidth.value}px;right:${sidebarPos.value}px`;
    });
    const sidebarContentStyle = computed(() => {
      return `width:${sidebarContentWidth.value}px`;
    });

    const hide = () => {
      if (visible.value) {
        visible.value = false;
        animation.set(false, () => {
          modalOverlay.deactivate();
        });
      }
    };
    const show = () => {
      if (!visible.value) {
        visible.value = true;
        modalOverlay.activate(undefined, () => {
          store.dispatch('sidebar/deactivate');
        });
        animation.set(true, () => {});
      }
    };

    const store = useStore();
    const app = computed(() => {
      const app = store.getters['sidebar/app'];
      return app;
    });
    watch(
      () => {
        return store.getters['sidebar/status'];
      },
      status => {
        if (status === true) {
          show();
        } else {
          hide();
        }
      }
    );
    watch(sidebarAttach, status => {
      if (status !== null && app.value !== undefined) {
        app.value.mount('#sidebar-attach');
      }
    });

    // Hide the sidebar when Escape key is pressed.
    const handler = (event: any) => {
      if (event.key === 'Escape') {
        store.dispatch('sidebar/deactivate');
      }
    };
    onMounted(() => {
      document.addEventListener('keydown', handler);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', handler);
    });

    return {
      sidebarAttach,
      sidebarWidth,
      sidebarContentWidth,
      sidebarStyle,
      sidebarContentStyle,
      contentStyle,
      showSidebar,
      visible,
      contentAreaClass
    };
  }
};
</script>
