<template>
  <page-content>
    <page-section v-if="titleImage !== undefined || title !== undefined">
      <content-column :width="'narrow'">
        <standard-heading
          v-if="titleImage === undefined && title !== undefined"
          :size="3"
          :class="'text-center'"
        >
          {{ title }}
        </standard-heading>
        <div v-else-if="titleImage !== undefined" class="flex justify-center">
          <imgix-image :config="titleImage" :class="`w-full max-w-[360px]`" />
        </div>
      </content-column>
    </page-section>

    <page-section v-if="coverImage !== undefined">
      <content-column :width="'narrow'">
        <div class="flex justify-center">
          <imgix-image :config="coverImage" :class="`w-full`" />
        </div>
      </content-column>
    </page-section>

    <page-section v-if="richTextData !== undefined">
      <rich-text :guest="''" :rich-text="richTextData" :justify="'center'" />
    </page-section>

    <page-section
      v-if="
        buttonLabel !== undefined &&
        buttonUrl !== undefined &&
        buttonLabel !== null &&
        buttonUrl !== null
      "
    >
      <content-column :width="'narrow'">
        <div class="flex justify-center">
          <standard-button :size="'lg'" :color="'primary'" :label="buttonLabel" :url="buttonUrl" />
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import RichText from '../../generic/rich-text/RichText.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ImgixImage from '../../core/imgix-image/ImgixImage.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {getThankYouPageBySlug} from '../../../backend/offer/thank-you-page-query';
import {ThankYouPage} from '../../../backend/offer/thank-you-page-types';
import {getImageConfig} from '../../../backend/content/content-entry/content-entry-query-utils';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    RichText,
    ImgixImage,
    StandardButton
  },
  props: {
    slug: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const thankYouPage = ref<Readonly<ThankYouPage> | undefined>(undefined);

    const richTextData = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return thankYouPage.value.richText;
    });
    const titleImage = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return getImageConfig(
        thankYouPage.value.imageCollection,
        ['header'],
        thankYouPage.value.thankYouPageTitle !== null
          ? thankYouPage.value.thankYouPageTitle
          : thankYouPage.value.title,
        800,
        {}
      );
    });
    const coverImage = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return getImageConfig(
        thankYouPage.value.imageCollection,
        ['cover'],
        thankYouPage.value.title,
        800,
        {}
      );
    });
    const title = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return thankYouPage.value.thankYouPageTitle;
    });

    const buttonLabel = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return thankYouPage.value.thankYouPageContinueButtonLabel;
    });
    const buttonUrl = computed(() => {
      if (thankYouPage.value === undefined) {
        return undefined;
      }
      return thankYouPage.value.thankYouPageContinueUrl;
    });

    onMounted(async () => {
      loader.setLoading(true);
      thankYouPage.value = await getThankYouPageBySlug(props.slug);
      loader.setLoading(false);
    });

    return {
      richTextData,
      title,
      titleImage,
      coverImage,
      buttonLabel,
      buttonUrl
    };
  }
});
</script>
