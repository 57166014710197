import * as Joi from 'joi';
import {ThreadSyncCache} from '../../utils/thread-sync-cache';
import {
  VALIDATION_CRAFT_ID,
  VALIDATION_CRAFT_SLUG,
  VALIDATION_CRAFT_TITLE
} from '../craft/craft-types';
import {
  VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  VALIDATION_OPTIONAL_STRING,
  VALIDATION_POSITIVE_INTEGER
} from '../validation';
import {AccessPassStatus} from './access-pass-types';
import {ControllerResponse, cachedGetAction} from '../craft-action';
import {CraftQueryBuilder} from '../craft/query/craft-query-builder-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';

const ACCESS_PASS_PAYMENT_FIELDS: Array<CraftQueryBuilder> = [
  QueryBuilder.dropdown('accessPassPaymentProvider', ['stripe', 'braintree']).required(),
  QueryBuilder.plainText('accessPassPaymentId'),
  QueryBuilder.lightswitch('accessPassPaymentDefault').required()
];

export const ACCESS_PASS_FIELDS: Array<CraftQueryBuilder> = [
  QueryBuilder.dropdown('accessPassType', ['subscription', 'singlePurchase', 'free']).required(),
  QueryBuilder.number('accessPassAmount'),
  QueryBuilder.dropdown('accessPassRecurrence', ['none', 'monthly', 'annual']).required(),
  QueryBuilder.matrix('accessPassPayments').blocks([
    QueryBuilder.block('accessPassPayment').fields(ACCESS_PASS_PAYMENT_FIELDS)
  ])
];

export const ACCESS_PASS_STATUS_SCHEMA = Joi.object({
  id: VALIDATION_CRAFT_ID,
  slug: VALIDATION_CRAFT_SLUG,
  title: VALIDATION_CRAFT_TITLE,
  status: Joi.string().valid('active', 'trial', 'inactive', 'pastDue', 'pending', 'cancelling'),
  type: Joi.string().valid('subscription', 'singlePurchase', 'free'),
  recurrence: Joi.string().valid('none', 'monthly', 'annual'),
  paymentProvider: VALIDATION_OPTIONAL_STRING,
  subscriptionId: VALIDATION_OPTIONAL_STRING,
  paymentMethod: VALIDATION_OPTIONAL_STRING,
  createdAtDate: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  nextBillingDate: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  firstBillingDate: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  currentPeriodEnd: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  cancelDate: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  amount: VALIDATION_POSITIVE_INTEGER,
  discount: Joi.object({
    amountOff: VALIDATION_OPTIONAL_POSITIVE_INTEGER,
    percentOff: Joi.number().allow(null),
    duration: Joi.string().valid('forever', 'once', 'repeating')
  }).allow(null)
});
const ACCESS_PASS_STATUS_ARRAY_SCHEMA = Joi.array().items(ACCESS_PASS_STATUS_SCHEMA);

/**
 * Return the id, status, and other data for all access passes
 * that are owned by the currently logged in user.
 *
 * NOTE: The response is cached. (The cache is cleared on page loads.)
 */
const userAccessPassesCache = new ThreadSyncCache<
  ControllerResponse<ReadonlyArray<AccessPassStatus>>
>();
export async function getAccessPassesForCurrentUser(): Promise<ReadonlyArray<AccessPassStatus>> {
  const url = '/actions/sbl-module/access-pass/get-access-passes-for-current-user';
  const result = await cachedGetAction<ReadonlyArray<AccessPassStatus>>(
    url,
    ACCESS_PASS_STATUS_ARRAY_SCHEMA,
    userAccessPassesCache,
    'access-passes'
  );
  return result;
}
