import {LoopDefinition, LoopPlayerConfig} from '../loop-player-types';

export function getSecondsPerBeat(config: Readonly<LoopPlayerConfig>) {
  const RATE_TO_SCALE = {
    100: 1.0,
    90: 1.111,
    80: 1.2499,
    70: 1.4281,
    60: 1.6662
  };
  const scale = RATE_TO_SCALE[config.rate];
  return (60.0 / config.bpm) * scale;
}

/**
 * Return the audio file start timestamp (in ms) and duration (in ms) for a loop.
 *
 * NOTE: The resulting audio range is [startBar, endBar), i.e., the end bar
 * is NOT included in the loop. If, for example, you want to loop the second bar
 * of the song, you set startBar=0 and endBar=1.
 */
export function getLoopStartAndDuration(
  secondsPerBar: number,
  audioFilePrecountBars: number,
  startBar: number,
  endBar: number
): LoopDefinition {
  const startSec = secondsPerBar * (startBar + audioFilePrecountBars);
  const endSec = secondsPerBar * (endBar + audioFilePrecountBars);
  const durSec = endSec - startSec;
  return {
    startMs: startSec * 1000,
    durationMs: durSec * 1000
  };
}
