<template>
  <learning-pathway-not-started
    v-if="
      learningPathway !== undefined &&
      trails !== undefined &&
      pagination !== undefined &&
      progress.progress.value === 0
    "
    :guest="guest"
    :learning-pathway="learningPathway"
    :bookmark="bookmark"
    :pagination="pagination"
    :progress="progress"
    :trails="trails"
  />
  <learning-pathway-in-progress
    v-if="
      learningPathway !== undefined &&
      trails !== undefined &&
      pagination !== undefined &&
      progress.progress.value > 0
    "
    :guest="guest"
    :learning-pathway="learningPathway"
    :bookmark="bookmark"
    :pagination="pagination"
    :progress="progress"
    :trails="trails"
  />
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import LearningPathwayNotStarted from './variants/LearningPathwayNotStarted.vue';
import LearningPathwayInProgress from './variants/LearningPathwayInProgress.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {LearningPathway} from '../../../backend/content/learning-pathways/learning-pathways-types';
import {getLearningPathwayBySlug} from '../../../backend/content/learning-pathways/learning-pathways-query';
import {useProgress} from '../../vue-composition/progress/progress';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {usePagination} from '../../vue-composition/pagination/pagination';

export default defineComponent({
  components: {
    LearningPathwayNotStarted,
    LearningPathwayInProgress
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const progress = useProgress(props.guest, {childProgress: true});
    const bookmark = useBookmark(props.guest);
    const learningPathway = ref(undefined as Readonly<LearningPathway> | undefined);

    onMounted(async () => {
      loader.setLoading(true);

      learningPathway.value = await getLearningPathwayBySlug(props.slug);
      if (learningPathway.value !== undefined) {
        trails.value = await useBreadcrumbTrails(
          learningPathway.value,
          props.entryBreadcrumbTrails
        );
        await progress.init(learningPathway.value);
        await bookmark.init();
      }

      loader.setLoading(false);
    });

    const paginationItems = computed(() => {
      if (learningPathway.value === undefined) {
        return [];
      }
      return learningPathway.value.childEntries;
    });
    const pagination = usePagination(paginationItems);

    return {
      learningPathway,
      progress,
      bookmark,
      trails,
      pagination
    };
  }
});
</script>
