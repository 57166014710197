<template>
  <div class="flex justify-center">
    <div :class="`w-full max-w-[1920px]`">
      <div v-if="items !== undefined && contract && !showItems" class="py-fl-sm bg-dark">
        <content-column :width="'wide'">
          <button class="w-full h-full flex justify-between items-center" @click="onExpand">
            <div>View All Lessons</div>
            <font-awesome-icon :icon="icon"></font-awesome-icon>
          </button>
        </content-column>
      </div>
      <div v-else :class="`flex flex-col overflow-hidden`">
        <div v-if="items !== undefined" class="flex flex-col overflow-y-auto">
          <media-browser-navigator-item
            v-for="(item, i) in items"
            :key="item.id"
            :on-click="onItemSelected"
            :item="item"
            :sidebar-icon-type="sidebarIconType"
            :divider="i === 0"
          />
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from 'vue';
import {MediaBrowserItem, MediaBrowserItemSelectedCallback} from '../media-browser-types';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import MediaBrowserNavigatorItem from './MediaBrowserNavigatorItem.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {faAngleDown} from '@fortawesome/pro-light-svg-icons/faAngleDown';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    ContentColumn,
    MediaBrowserNavigatorItem,
    FontAwesomeIcon
  },
  props: {
    items: {type: Array as PropType<ReadonlyArray<MediaBrowserItem>>, required: true},
    onItemSelected: {type: Function as PropType<MediaBrowserItemSelectedCallback>, required: true},
    title: {type: String, default: undefined},
    sidebarIconType: {
      type: String as PropType<'play' | 'check'>,
      default: 'check'
    },
    contract: {type: Boolean, default: false}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const sidebarTitle = computed(() => (deviceType.largerThan('lg') ? props.title : undefined));

    const showItems = ref(!props.contract);
    const onExpand = () => {
      showItems.value = true;
    };
    const icon = computed(() => faAngleDown);

    return {
      deviceType,
      sidebarTitle,
      showItems,
      onExpand,
      icon
    };
  }
});
</script>
