<template>
  <page-content v-if="webcast.webcast.value !== undefined">
    <hr class="w-full border-dark" />

    <page-section :margin-top="'2xs'">
      <content-column :width="'wide'">
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <!-- Media browser + navbar beneath -->

    <page-section :margin-top="'2xs'">
      <webcast-player
        :preview="contentEntry.preview"
        :guest="guest"
        :webcast="webcast"
        :user-is-admin="userIsAdmin"
        :user-id="userId"
        :user-name="userName"
        :chatroll-id="chatrollId"
        :chatroll-secret-key="chatrollSecretKey"
      />
    </page-section>

    <variants>
      <template #md+>
        <webcast-title-bar-medium
          :guest="guest"
          :content-entry="contentEntry"
          :bookmark="bookmark"
        />
      </template>
      <template #sm->
        <webcast-title-bar-small
          :guest="guest"
          :content-entry="contentEntry"
          :bookmark="bookmark"
        />
      </template>
    </variants>

    <content-entry-summary :guest="guest" :content-entry="contentEntry" />

    <content-entry-resource-list :guest="guest" :content-entry="contentEntry" />
    <content-entry-recommendations
      :guest="guest"
      :content-entry="contentEntry"
      :bookmark="bookmark"
    />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {ContentEntry} from '../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../vue-composition/bookmark/types';
import {BreadcrumbTrailComposition} from '../vue-composition/breadcrumb-trail/types';
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import Variants from '../core/column-layout/Variants.vue';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import ContentEntryHeaderBreadcrumbTrail from '../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntrySummary from '../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryResourceList from '../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryRecommendations from '../content-entry/partials/body/ContentEntryRecommendations.vue';
import WebcastPlayer from './partials/WebcastPlayer.vue';
import WebcastTitleBarMedium from './partials/WebcastTitleBarMedium.vue';
import WebcastTitleBarSmall from './partials/WebcastTitleBarSmall.vue';

import {WebcastComposition} from '../vue-composition/webcast/types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    Variants,
    ContentColumn,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntrySummary,
    ContentEntryResourceList,
    ContentEntryRecommendations,
    WebcastPlayer,
    WebcastTitleBarMedium,
    WebcastTitleBarSmall
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    webcast: {type: Object as PropType<Readonly<WebcastComposition>>, required: true},
    userIsAdmin: {type: String, required: true},
    userId: {type: String, required: true},
    userName: {type: String, required: true},
    chatrollSecretKey: {type: String, required: true}
  },
  setup(props) {
    const chatrollId = computed(() => {
      if (props.webcast.webcast.value !== undefined) {
        const id = props.webcast.webcast.value.webcastChatrollId;
        if (id !== null && id !== undefined) {
          return id;
        }
      }
      return undefined;
    });

    return {chatrollId};
  }
});
</script>
