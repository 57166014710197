<template>
  <link-icon-button :icon="icon" :label="label" :tooltip="tooltip" :on-click="onSwitch" />
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';
import {faList} from '@fortawesome/pro-solid-svg-icons/faList';
import {faGrid} from '@fortawesome/pro-solid-svg-icons/faGrid';

export default defineComponent({
  components: {
    LinkIconButton
  },
  setup() {
    const localStorage = useLocalStorageFlag('content-entry-index-grid', true);
    const icon = computed(() => {
      const grid = localStorage.get();
      return grid ? faList : faGrid;
    });
    const label = computed(() => {
      const grid = localStorage.get();
      return grid ? 'List view' : 'Grid view';
    });
    const tooltip = computed(() => {
      const grid = localStorage.get();
      return grid ? 'Switch to list view' : 'Switch to grid view';
    });
    const onSwitch = () => {
      localStorage.toggle();
    };
    return {icon, label, tooltip, onSwitch};
  }
});
</script>

<style scoped></style>
