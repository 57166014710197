<template>
  <div :style="gridColumn">
    <slot />
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import {gridColumnCSS} from './internals/css';
import {ColumnArea} from './types';

/**
 * This component is used to specify a content area inside of the column grid.
 *
 * The start column and span settings are optional; you may omit one or both
 * of them. The defaults are: start at column 1 and span the available number
 * of columns.
 */
export default defineComponent({
  components: {},
  props: {
    area: {type: Object as PropType<ColumnArea>, default: undefined},
    start: {type: Number, default: 1},
    span: {type: Number, default: -1},
    end: {type: Number, default: undefined}
  },
  setup(props) {
    const gridColumn = computed(() => {
      const start = props.area !== undefined ? props.area.start : props.start;
      const span = props.area !== undefined ? props.area.span : props.span;
      return gridColumnCSS(start, span, props.end);
    });
    return {
      gridColumn
    };
  }
});
</script>
