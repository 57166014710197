<template>
  <cluster :gap="'gap-4'" :justify="'justify-center'">
    <icon-button
      :icon="iconYoutube"
      :color="'light'"
      :tooltip="'SBL on Youtube'"
      :url="'https://www.youtube.com/@devinebass'"
    />
    <icon-button
      :icon="iconInstagram"
      :color="'light'"
      :tooltip="'SBL on Instagram'"
      :url="'https://www.instagram.com/scottsbasslessons'"
    />
    <icon-button
      :icon="iconFacebook"
      :color="'light'"
      :tooltip="'SBL on Facebook'"
      :url="'https://www.facebook.com/ScottsBassLessons/'"
    />
    <icon-button
      :icon="iconTiktok"
      :color="'light'"
      :tooltip="'SBL on TikTok'"
      :url="'https://www.tiktok.com/tag/scottsbasslessons'"
    />
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import Cluster from '../../core/compositions/Cluster.vue';
import IconButton from '../../core/button/IconButton.vue';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook';
import {faTiktok} from '@fortawesome/free-brands-svg-icons/faTiktok';

export default defineComponent({
  components: {
    Cluster,
    IconButton
  },
  setup() {
    return {
      iconYoutube: computed(() => faYoutube),
      iconInstagram: computed(() => faInstagram),
      iconFacebook: computed(() => faFacebook),
      iconTiktok: computed(() => faTiktok)
    };
  }
});
</script>
