<template>
  <page-content>
    <page-section>
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header :center="false" :spot-color="spotColorConfig">
          {{ level.title }}
        </content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <cluster>
          <content-entry-header-tutors :content-entry="level" />
          <pill class="border-darkest bg-darkest text-mid">{{ pillTitle }}</pill>
          <content-entry-header-beginner-journey :trails="trails" />
        </cluster>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <content-entry-header-progress
          :guest="guest"
          :label="`${level.title} progress`"
          :progress-value="progress"
          :spot-color="spotColorConfig"
        />
      </content-column>
    </page-section>

    <page-section v-if="piecesCompleted >= 3">
      <players-path-level-complete
        :level="level"
        :next-level="nextLevel"
        :level-number="levelNumber"
        :spot-color="spotColorConfig"
      />
    </page-section>

    <page-section :margin-top="'lg'">
      <players-path-level-actions :guest="guest" :level="level" :bookmark="bookmark" />
    </page-section>

    <players-path-song-checkmarks :pieces-completed="piecesCompleted" :spot-color="spotColor" />

    <page-section v-if="level.playersPathPieces.length > 0" :margin-top="'sm'">
      <players-path-piece-list
        :guest="guest"
        :preview="level.preview"
        :pieces="level.playersPathPieces"
        :quiz-statuses="pieceQuizStatuses"
        :spot-color="spotColorConfig"
        :bookmark="bookmark"
      />
    </page-section>

    <page-section :margin-top="'xl'">
      <content-entry-recommendations
        :guest="guest"
        :content-entry="level"
        :bookmark="bookmark"
        :width="'normal'"
      />
    </page-section>

    <page-section>
      <content-column>
        <standard-divider :class="'border-dark'" />
      </content-column>
    </page-section>
    <page-section>
      <page-section-header :heading="'About This Level'" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="level.summary" />
      </content-column>
    </page-section>
    <page-section>
      <content-column>
        <content-entry-video :content-entry="level" :video-type="'trailer'" :guest="guest" />
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import PlayersPathPieceList from '../partials/PlayersPathPieceList.vue';
import PlayersPathResetLevelDialog from '../partials/PlayersPathResetLevelDialog.vue';
import PlayersPathSongCheckmarks from '../partials/PlayersPathSongCheckmarks.vue';
import PlayersPathLevelComplete from '../partials/PlayersPathLevelComplete.vue';
import PlayersPathLevelActions from '../partials/PlayersPathLevelActions.vue';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Pill from '../../../generic/pill/Pill.vue';
import ContentEntryHeader from '../../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryHeaderProgress from '../../../content-entry/partials/header/metadata/ContentEntryHeaderProgress.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryRecommendations from '../../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntryVideo from '../../../content-entry/partials/body/ContentEntryVideo.vue';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {faArrowRotateLeft} from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import {QuizCollectionStatus} from '../../../../backend/content/quiz/quiz-types';
import {getPlayersPathLevelNumberFromSlug, playersPathLevelSpotColor} from '../utils';
import {useModalDialog} from '../../../vue-composition/modal-dialog/modal-dialog';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {setQuizIncomplete} from '../../../../backend/content/quiz/quiz-query';
import {redirectWithHistory} from '../../../../utils/url';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';
import {ColorConfig} from '../../../core/color-config';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    PageContent,
    ContentColumn,
    Cluster,
    StandardDivider,
    Pill,
    PlayersPathPieceList,
    PlayersPathSongCheckmarks,
    PlayersPathLevelComplete,
    PlayersPathLevelActions,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeader,
    ContentEntryHeaderProgress,
    ContentEntryHeaderTutors,
    ContentEntryHeaderBeginnerJourney,
    ContentEntryRecommendations,
    ContentEntryVideo
  },
  props: {
    guest: {type: String, required: true},
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    levelNumber: {type: Number, required: true},
    levelComplete: {type: Boolean, required: true},
    piecesCompleted: {type: Number, required: true},
    pieceQuizStatuses: {type: Object as PropType<QuizCollectionStatus>, required: true},
    nextLevel: {
      type: Object as PropType<Readonly<PlayersPathLevel> | undefined>,
      default: undefined
    },
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const progress = computed(() => {
      return Math.min(props.piecesCompleted / 3, 1) * 100;
    });
    const levelNumber = computed(() => {
      return getPlayersPathLevelNumberFromSlug(props.level.slug);
    });
    const spotColorConfig = computed(() => {
      return playersPathLevelSpotColor(levelNumber.value);
    });

    const loader = useFullScreenLoader();

    const resetDialog = useModalDialog(PlayersPathResetLevelDialog);
    const resetLevel = () => {
      resetDialog.show(
        async () => {
          loader.setLoading(true);
          for (let i = 0; i < props.level.quizzes.length; i += 1) {
            await setQuizIncomplete(props.level.quizzes[i]);
          }
          redirectWithHistory('/players-path');
        },
        () => {}
      );
    };

    const piecesCompleteText = computed(() => {
      if (props.piecesCompleted === 1) {
        return 'You have completed 1 study piece complete, 2 more to go!';
      }
      return 'You have completed 2 study pieces complete, 1 more to go!';
    });

    const iconCheckCircle = computed(() => faCheckCircle);
    const iconCheck = computed(() => faCheck);
    const iconReset = computed(() => faArrowRotateLeft);

    const pillTitle = computed(() => {
      if (props.levelNumber >= 1 && props.levelNumber <= 3) {
        return 'Beginner';
      }
      if (props.levelNumber >= 4 && props.levelNumber <= 6) {
        return 'Intermediate';
      }
      return 'Advanced';
    });

    const checkmarks = computed(() => {
      return [1, 2, 3].map(n => {
        return n <= props.piecesCompleted ? props.spotColor.text : 'text-mid';
      });
    });

    const actionsClass = deviceTypeSwitch<string>({
      narrow: 'flex justify-between',
      sm: 'flex gap-x-fl-xl'
    });

    return {
      progress,
      spotColorConfig,
      resetDialog,
      resetLevel,
      piecesCompleteText,
      pillTitle,
      checkmarks,
      iconCheck,
      iconCheckCircle,
      iconReset,
      actionsClass
    };
  }
});
</script>
