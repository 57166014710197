<template>
  <margins>
    <content-entry-inline-card
      v-if="bookmarkNode.contentEntry !== undefined"
      :guest="guest"
      :content-entry="bookmarkNode.contentEntry"
      :card-image-width="cardImageWidth"
    >
      <template #buttons>
        <div class="flex flex-col h-full justify-center">
          <popup-menu-button :items="menuItems" />
        </div>
      </template>
    </content-entry-inline-card>
  </margins>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {tutorList} from '../../../../backend/content/content-entry/content-entry-query-utils';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentEntryInlineCard from '../inline-card/ContentEntryInlineCard.vue';
import PopupMenuButton from '../../../generic/popup-menu-button/PopupMenuButton.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {BookmarkNode} from '../../../user-pages/bookmarks/types';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {
  standardNotification,
  useNotificationPopup
} from '../../../vue-composition/notification-popup/notification-popup';
import {
  deleteBookmarkItem,
  moveBookmarkItemDown,
  moveBookmarkItemToBottom,
  moveBookmarkItemToTop,
  moveBookmarkItemUp,
  shareBookmark
} from '../../../user-pages/bookmarks/utils';
import {faShare} from '@fortawesome/pro-solid-svg-icons/faShare';
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faAnglesUp} from '@fortawesome/pro-regular-svg-icons/faAnglesUp';
import {faAnglesDown} from '@fortawesome/pro-regular-svg-icons/faAnglesDown';
import {faTrashCan} from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import {PopupMenuItem} from '../../../generic/popup-menu-button/types';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    ContentEntryInlineCard,
    PopupMenuButton,
    Margins
  },
  props: {
    guest: {type: String, required: true},
    bookmarkNode: {type: Object as PropType<Readonly<BookmarkNode>>, required: true},
    readOnly: {type: Boolean, required: true},
    group: {type: Object as PropType<Readonly<BookmarkNode>>, required: true},
    groups: {type: Array as PropType<ReadonlyArray<BookmarkNode>>, required: true},
    first: {type: Boolean, default: false},
    last: {type: Boolean, default: false},
    onMove: {type: Function, required: true},
    reload: {type: Function as PropType<() => Promise<void>>, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const tutors = computed(() => {
      if (props.bookmarkNode.contentEntry === undefined) {
        return;
      }
      const tutors = tutorList(props.bookmarkNode.contentEntry);
      return tutors;
    });

    const url = computed(() => {
      if (props.bookmarkNode.contentEntry === undefined) {
        return;
      }
      return props.bookmarkNode.contentEntry.url;
    });

    const onDelete = async () => {
      if (props.bookmarkNode.contentEntry === undefined) {
        return;
      }
      if (confirm(`Deleting bookmark "${props.bookmarkNode.contentEntry.title}"\nAre you sure?`)) {
        loader.setLoading(true);
        await deleteBookmarkItem(props.bookmarkNode);
        await props.reload();
        loader.setLoading(false);
      }
    };

    const onMoveUp = async () => {
      loader.setLoading(true);
      await moveBookmarkItemUp(props.group, props.bookmarkNode);
      loader.setLoading(false);
    };
    const onMoveDown = async () => {
      loader.setLoading(true);
      await moveBookmarkItemDown(props.group, props.bookmarkNode);
      loader.setLoading(false);
    };
    const onMoveTop = async () => {
      loader.setLoading(true);
      await moveBookmarkItemToTop(props.group, props.bookmarkNode);
      loader.setLoading(false);
    };
    const onMoveBottom = async () => {
      loader.setLoading(true);
      await moveBookmarkItemToBottom(props.group, props.bookmarkNode);
      loader.setLoading(false);
    };

    const onShare = async () => {
      if (props.bookmarkNode.bookmark === undefined) {
        return;
      }
      const success = await shareBookmark(props.bookmarkNode);
      if (success) {
        notification.notify(standardNotification('Link copied to clipboard.'));
      }
    };

    const summary = computed(() => {
      if (props.bookmarkNode.contentEntry === undefined) {
        return;
      }
      const summary = props.bookmarkNode.contentEntry.summary;
      if (summary === undefined || summary === null) {
        return undefined;
      }
      return summary;
    });

    const menuItems = computed(() => {
      const result: Array<PopupMenuItem> = [];
      result.push({icon: faShare, label: 'Share', onSelected: onShare});
      if (!props.first && !props.readOnly) {
        result.push({icon: faAnglesUp, label: 'Move To Top', onSelected: onMoveTop});
      }
      if (!props.first && !props.readOnly) {
        result.push({icon: faAngleUp, label: 'Move Up', onSelected: onMoveUp});
      }
      if (!props.last && !props.readOnly) {
        result.push({icon: faAngleDown, label: 'Move Down', onSelected: onMoveDown});
      }
      if (!props.last && !props.readOnly) {
        result.push({icon: faAnglesDown, label: 'Move To Bottom', onSelected: onMoveBottom});
      }
      if (props.groups.length > 1 && !props.readOnly) {
        result.push({
          icon: faAngleRight,
          label: 'Move To Another Group',
          onSelected: async () => {
            props.onMove(props.bookmarkNode);
          }
        });
      }
      result.push({icon: faTrashCan, label: 'Delete', onSelected: onDelete});
      return result;
    });

    return {
      notification,
      onDelete,
      onMoveUp,
      onMoveDown,
      onShare,
      deviceType,
      url,
      tutors,
      summary,
      menuItems
    };
  }
});
</script>
