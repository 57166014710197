import {NotificationPopupConfig} from '../../components/generic/notification-popup/types';

export interface NotificationPopupStoreState {
  activeNotifications: Map<number, NotificationPopupConfig>;
  idCounter: number;
}

export const NotificationPopupStore = {
  namespaced: true,
  state: {
    activeNotifications: new Map<number, NotificationPopupConfig>(),
    idCounter: 0
  } as NotificationPopupStoreState,
  mutations: {
    addNotification(state: any, cfg: NotificationPopupConfig) {
      state.idCounter += 1;
      state.activeNotifications.set(state.idCounter, cfg);
      return state.idCounter;
    },
    clearNotification(state: any, id: number) {
      if (state.activeNotifications.has(id)) {
        state.activeNotifications.delete(id);
      }
    }
  },
  actions: {
    addNotification({commit, state}: any, cfg: NotificationPopupConfig) {
      commit('addNotification', cfg);
      const id = state.idCounter;
      setTimeout(() => {
        commit('clearNotification', id);
      }, cfg.displayTimeSec * 1000);
    }
  },
  getters: {
    activeNotifications(state: any) {
      return state.activeNotifications;
    }
  }
};
