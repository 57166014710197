<template>
  <page-section :margin-top="'md'">
    <content-column :width="width">
      <div
        v-if="cancelUrl !== undefined || onCancel !== undefined"
        class="flex justify-around gap-fl-md flex-wrap"
      >
        <standard-button
          :size="'sm'"
          :on-click="onCancel"
          :url="cancelUrl"
          :color="'mid'"
          :label="'Cancel'"
        />
        <div :id="'purchase-button'">
          <standard-button
            :size="'sm'"
            :on-click="onPurchase"
            :label="purchaseLabel"
            :color="'primary'"
            :disabled="disabled"
          />
        </div>
      </div>
      <div v-else :id="'purchase-button'" class="flex justify-center">
        <standard-button
          :size="'md'"
          :on-click="onPurchase"
          :label="purchaseLabel"
          :color="'primary'"
          :disabled="disabled"
        />
      </div>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardButton
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'narrow'},
    disabled: {type: Boolean, required: true},
    cancelUrl: {type: String as PropType<CraftUrl>, default: undefined},
    onCancel: {type: Function as PropType<() => void>, default: undefined},
    purchaseLabel: {type: String, default: 'Purchase'},
    onPurchase: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup() {
    return {};
  }
});
</script>
