import {computed, Ref, ref} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {hierarchicalSort} from '../../../utils/sort/sort';
import {SortConfig} from '../../../utils/sort/types';
import {ContentEntrySortComposition, ContentEntrySortScheme} from './types';

function tutorDescriptor(object: Readonly<any>) {
  const entry = object as Readonly<ContentEntry>;
  return entry.tutors[0] ? entry.tutors[0].title : undefined;
}

function postDateDescriptor(object: Readonly<any>) {
  const entry = object as Readonly<ContentEntry>;
  return entry.postDate;
}

/*
    These are the ways in which users can sort content entries.
  */
// ### Add more, e.g., user's progress?
const SORT_SCHEME_CONFIGS: {[scheme: string]: {name: string; config: SortConfig}} = {
  default: {
    name: 'Default',
    config: []
  },
  newest: {
    name: 'Newest first',
    config: [
      {type: 'date', order: 'descending', descriptor: postDateDescriptor},
      {type: 'string', order: 'ascending', descriptor: tutorDescriptor}
    ]
  },
  oldest: {
    name: 'Oldest first',
    config: [
      {type: 'date', order: 'ascending', descriptor: postDateDescriptor},
      {type: 'string', order: 'ascending', descriptor: tutorDescriptor}
    ]
  }
};

/**
 * Return a Vue composition that sorts content entries.
 */
export function useSort(
  contentEntries: Ref<ReadonlyArray<ContentEntry>>,
  schemes: ReadonlyArray<ContentEntrySortScheme>,
  initScheme: ContentEntrySortScheme | undefined = undefined,
  onChange?: (scheme: ContentEntrySortScheme) => void
): Readonly<ContentEntrySortComposition> {
  if (schemes.length === 0) {
    throw new Error('Must provide at least one sort scheme');
  }
  const initIndex = schemes.indexOf(initScheme !== undefined ? initScheme : schemes[0]);
  const configIndex = ref(initIndex !== -1 ? initIndex : 0);

  const configLabel = computed(() => {
    const scheme = schemes[configIndex.value];
    if (scheme === undefined) {
      throw new Error('useSort: Internal error');
    }
    return SORT_SCHEME_CONFIGS[scheme].name;
  });

  // Function that switches to the next sort configuration (wrap if at end of array).
  const next = () => {
    const scheme = schemes[configIndex.value];
    if (scheme === undefined) {
      throw new Error('useSort: Internal error');
    }
    configIndex.value = (configIndex.value + 1) % schemes.length;
    if (onChange !== undefined) {
      onChange(schemes[configIndex.value]);
    }
  };

  const sortOutput = computed(() => {
    const scheme = schemes[configIndex.value];
    if (scheme === undefined) {
      throw new Error('useSort: Internal error');
    }
    const config = SORT_SCHEME_CONFIGS[scheme].config;
    const result = hierarchicalSort(contentEntries.value, config);
    return result;
  });

  return {
    configLabel,
    next,
    sortOutput
  };
}
