<template>
  <cluster v-if="levels.length > 0" :gap="'gap-fl-2xs'">
    <pill v-for="level in levels" :key="level" :class="`border-dark bg-dark text-light`">
      {{ level }}
    </pill>
    <div></div>
    <pill v-for="subject in subjects" :key="subject" class="border-dark bg-dark text-light">
      {{ subject }}
    </pill>
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import Pill from '../../../../generic/pill/Pill.vue';
import Cluster from '../../../../core/compositions/Cluster.vue';

export default defineComponent({
  components: {
    Pill,
    Cluster
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true}
  },
  setup(props) {
    const levels = computed(() => {
      return props.contentEntry.levels.map(t => t.title);
    });
    const subjects = computed(() => {
      return props.contentEntry.subjects.map(t => t.title);
    });
    return {
      levels,
      subjects
    };
  }
});
</script>
