<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <standard-heading :size="2">Reset Your Password</standard-heading>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <p class="text-fl-3xl text-white">
          Forgot your password? No problem, we can email you a link to reset it.
        </p>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <flow>
          <label :for="'loginName'">Username or email</label>
          <input
            id="loginName"
            v-model="loginName"
            class="mb-fl-xl w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
            type="text"
            placeholder="Username or Email"
            @keyup.enter="keyup"
          />
        </flow>

        <standard-button
          :size="'md'"
          :disabled="submitDisabled"
          :on-click="submit"
          :label="'Reset password'"
        />
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <div v-if="errorMessage !== undefined" class="text-primary">
          {{ errorMessage }}
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {httpPost} from '../../../backend/http';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import {
  standardNotification,
  useNotificationPopup
} from '../../vue-composition/notification-popup/notification-popup';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    Flow,
    StandardButton
  },
  props: {
    rememberedUserName: {type: String, default: ''},
    userId: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const errorMessage = ref(undefined as string | undefined);
    const loginName = ref(props.rememberedUserName);

    const submitDisabled = computed(() => {
      return loginName.value.length === 0;
    });

    const keyup = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        await submit();
      }
    };

    const submit = async () => {
      const url = 'actions/users/send-password-reset-email';
      const params = {
        loginName: loginName.value,
        userId: props.userId.length === 0 ? undefined : props.userId
      };
      try {
        loader.setLoading(true, 'Updating your profile');
        const {ok, bodyJson} = await httpPost(url, params);
        loader.setLoading(false);
        if (ok) {
          errorMessage.value = undefined;
          notification.notify(standardNotification('Password reset email sent.'));
        } else {
          interface ResetPasswordActionRequestResponse {
            message: string | unknown;
          }
          const response = bodyJson as ResetPasswordActionRequestResponse;
          if (response.message !== undefined) {
            errorMessage.value = `${response.message}`;
          } else {
            errorMessage.value = 'Could not reset password.';
          }
        }
      } catch (error) {
        loader.setLoading(false);

        // The HTTP request failed, or Craft didn't return JSON. Display a generic error messsage.
        console.error(error);
        errorMessage.value = 'Could not reset password.';
      }
    };
    return {
      notification,
      submit,
      keyup,
      submitDisabled,
      loginName,
      errorMessage
    };
  }
});
</script>
