<template>
  <div
    v-if="loading"
    id="fullscreen-loader"
    class="fixed w-full h-full top-0 left-0 bg-black opacity-90 z-[49]"
    :aria-hidden="true"
    @click.prevent=""
  >
    <div
      class="fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 flex flex-col gap-fl-md justify-center"
    >
      <font-awesome-icon :icon="icon" :transform="`rotate-${rotation}`"></font-awesome-icon>
      <div v-if="text.length > 0" class="text-light text-fl-xl text-center">{{ text }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, watch} from 'vue';
import {useLinearAnimation} from '../../vue-composition/animation/linear';
import {useStore} from '../../../store/store';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons/faCircleNotch';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  setup() {
    const anim = useLinearAnimation(1, true);

    const icon = computed(() => faCircleNotch);
    const rotation = computed(() => {
      return anim.phase.value * 360.0;
    });

    const store = useStore();
    const loading = computed(() => {
      const status = store.getters['fullScreenLoader/loading'] as boolean;
      return status;
    });

    watch(loading, status => {
      if (status === true) {
        anim.start();
      } else {
        anim.stop();
      }
    });

    const text = computed(() => {
      const message = store.getters['fullScreenLoader/message'] as string | undefined;
      return message !== undefined ? message : '';
    });

    return {
      loading,
      anim,
      icon,
      rotation,
      text
    };
  }
});
</script>
