import {AccessPassStatus} from '../../access-pass/access-pass-types';
import {AddOnOffer} from '../addon-offer-types';
import {
  OfferConditionsBlock,
  OfferConditionsServerDateInterval,
  OfferConditionsSignupDateInterval,
  OfferConditionsAccessPass,
  OfferConditionsVisitorStatus,
  OfferConditionsContext,
  OfferConditionsContextValue
} from '../offer-conditions-types';
import {ResubscribeOffer} from '../resubscribe-offer-types';
import {UpgradeOffer} from '../upgrade-offer-types';
import {evaluateAccessPass} from './access-pass-condition';
import {evaluateContext} from './context-condition';
import {evaluateServerDate} from './server-date-condition';
import {evaluateSignupDate} from './signup-date-condition';
import {evaluateVisitorStatus} from './visitor-status-condition';

/**
 * Evaluate upgrade/resubscribe offer conditions.
 * @returns true if ALL conditions evaluate to true, false otherwise.
 */
export function evaluateConditions<T extends UpgradeOffer | ResubscribeOffer | AddOnOffer>(
  offer: Readonly<T>,
  guest: boolean,
  context: OfferConditionsContextValue,
  userSignupDateUTC: number | undefined,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
): boolean {
  const conditions = offer.offerConditions.reduce(
    (status: boolean, condition: Readonly<OfferConditionsBlock>) => {
      if (!status) {
        return false;
      }
      switch (condition.typeHandle) {
        case 'serverDateInterval':
          return evaluateServerDate(
            condition as Readonly<OfferConditionsServerDateInterval>,
            serverDateUTC
          );
        case 'signupDateInterval':
          return evaluateSignupDate(
            condition as Readonly<OfferConditionsSignupDateInterval>,
            userSignupDateUTC
          );
        case 'accessPass':
          return evaluateAccessPass(
            condition as Readonly<OfferConditionsAccessPass>,
            userAccessPasses
          );
        case 'vistiorStatus':
          return evaluateVisitorStatus(condition as Readonly<OfferConditionsVisitorStatus>, guest);
        case 'context':
          return evaluateContext(condition as Readonly<OfferConditionsContext>, context);
        default:
          throw new Error('Unknown offer condition block type');
      }
    },
    true
  );
  return conditions;
}
