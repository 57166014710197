<template>
  <div class="flex flex-col gap-y-fl-sm">
    <search-box
      :id="'global-search-input'"
      :on-submit="onSearch"
      :placeholder="'Search titles and summaries'"
      :init-value="pageSearchTerm"
      :label="'Search SBL'"
      :tooltip="'Enter search term'"
    />
    <link-button :underline="true" :label="'Search Tips'" :on-click="showHelp" class="self-end" />
  </div>
</template>

<script lang="ts">
import {computed, PropType, defineComponent} from 'vue';
import LinkButton from '../../core/button/LinkButton.vue';
import SearchBox from '../../generic/search-box/SearchBox.vue';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons/faInfoCircle';

export default defineComponent({
  components: {
    LinkButton,
    SearchBox
  },
  props: {
    onSearch: {type: Function as PropType<(searchTerm: string) => void>, required: true},
    showHelp: {type: Function as PropType<() => void>, required: true},
    pageSearchTerm: {type: String, default: undefined}
  },
  setup() {
    const icon = computed(() => faInfoCircle);
    return {
      icon
    };
  }
});
</script>
