<template>
  <content-column :width="'wide'">
    <div
      class="relative w-full box-border h-20 grid grid-cols-[2fr_1fr_2fr] items-center select-none"
    >
      <cluster :gap="'gap-x-fl-lg'">
        <!-- Navigation menu -->
        <cluster :gap="'gap-[16px]'" class="cursor-pointer" @click="showNavigationMenu">
          <div class="relative w-8 h-8 flex justify-center items-center">
            <icon-button
              :icon="iconBars"
              :on-click="showNavigationMenu"
              :tooltip="'Show menu'"
              :expand="true"
              :color="'white'"
            />
          </div>
          <div class="text-white font-semibold">Menu</div>
        </cluster>

        <!-- Courses button -->
        <link-button :url="'/courses'" :label="'Courses'" class="ml-fl-sm" />

        <!-- Bass Toolkit -->
        <link-button
          :url="'https://play.scottsbasslessons.com/free-bass-toolkit'"
          :label="'FREE Bass Toolkit'"
        />
      </cluster>

      <!-- SBL logo -->
      <div class="flex justify-center items-center gap-x-1 flex-nowrap">
        <div class="relative w-14 h-14">
          <a href="/">
            <logo :display-type="'solid'" />
          </a>
        </div>
      </div>

      <cluster :gap="'gap-x-fl-xl'" class="justify-self-end">
        <!-- Login button -->
        <link-button :url="loginUrl" :label="'Login'" />

        <!-- Trial button -->
        <trial-button :font-size="'text-base'" />
      </cluster>
    </div>
  </content-column>
</template>

<script lang="ts">
import {PropType, computed, defineComponent} from 'vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import IconButton from '../../../core/button/IconButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Logo from '../../../generic/logo/Logo.vue';
import TrialButton from '../trial-button/TrialButton.vue';
import {faBars} from '@fortawesome/pro-thin-svg-icons/faBars';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    ContentColumn,
    Cluster,
    IconButton,
    LinkButton,
    Logo,
    TrialButton
  },
  props: {
    showNavigationMenu: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup() {
    const iconBars = computed(() => faBars);
    const iconArrow = computed(() => faArrowRight);
    const loginUrl = computed(() => `/login?redirect=${window.location.href}`);

    return {
      iconBars,
      iconArrow,
      loginUrl
    };
  }
});
</script>
