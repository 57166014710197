<template>
  <page-section :padding-top="'lg'" :margin-top="'none'" :margin-bottom="'none'">
    <content-column :width="contentColumnWidth">
      <standard-heading :size="1" :center="true">{{ timelineHeading }}</standard-heading>
    </content-column>
  </page-section>

  <page-section
    :padding-top="'md'"
    :padding-bottom="'xl'"
    :margin-top="'none'"
    :margin-bottom="'none'"
    class="text-white"
  >
    <margins :class="'text-fl-3xl'">
      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear1
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p>{{ timelineText1 }}</p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear2
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p class="text-light">{{ timelineText2 }}</p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear3
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p>
          {{ timelineText3 }}
        </p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear4
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p class="text-light">{{ timelineText4 }}</p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear5
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p>
          {{ timelineText5 }}
        </p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear6
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p class="text-light">
          {{ timelineText6 }}
        </p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear7
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p>{{ timelineText7 }}</p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-sm">{{
          timelineYear8
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4">
        <p class="text-light">
          {{ timelineText8 }}
        </p>
      </content-area>

      <content-area :start="4" :span="1">
        <standard-heading :size="3" :spot-color="yearSpotColor" class="mb-fl-lg">{{
          timelineYear9
        }}</standard-heading>
      </content-area>
      <content-area :start="6" :span="4" class="mb-fl-lg">
        <p>
          {{ timelineText9 }}
        </p>
      </content-area>
    </margins>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {
  timelineHeading,
  timelineYear1,
  timelineYear2,
  timelineYear3,
  timelineYear4,
  timelineYear5,
  timelineYear6,
  timelineYear7,
  timelineYear8,
  timelineYear9,
  timelineText1,
  timelineText2,
  timelineText3,
  timelineText4,
  timelineText5,
  timelineText6,
  timelineText7,
  timelineText8,
  timelineText9
} from '../utils/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Margins,
    ContentArea,
    StandardHeading
  },

  setup() {
    const deviceType = useDeviceType();
    const contentColumnWidth = computed(() =>
      deviceType.largerThanOrEqualTo('xl') ? 'normal' : 'medium'
    );
    const yearSpotColor = {
      bg: 'bg-black',
      bgHover: 'bg-black',
      text: 'text-light',
      textHover: 'hover:text-light',
      border: 'border-black',
      borderHover: 'hover:border-black'
    };

    return {
      yearSpotColor,
      contentColumnWidth,
      timelineHeading,
      timelineYear1,
      timelineYear2,
      timelineYear3,
      timelineYear4,
      timelineYear5,
      timelineYear6,
      timelineYear7,
      timelineYear8,
      timelineYear9,
      timelineText1,
      timelineText2,
      timelineText3,
      timelineText4,
      timelineText5,
      timelineText6,
      timelineText7,
      timelineText8,
      timelineText9
    };
  }
});
</script>
