/*
  ### Get rid of Howler.js. Its feature set doesn't align well with what we need.
  Also, the Howler project admins do not seem very interested in improving its API,
  nor fixing non-critical bugs.
*/
import {Howl} from 'howler';
import {LoopDefinition, LoopPlayerSource} from '../loop-player-types';

/**
 * Utility function that returns a Promise that creates a new Howler
 * instance and resolves after the audio file has been downloaded and
 * initialised. The function also adds a 1-second audio sprite to the
 * Howler instance.
 *
 * @param src The WebM/MP3 URLs to load (in preferred order).
 */
async function createHowl(src: Array<string>): Promise<Howl> {
  return new Promise((resolve, reject) => {
    const howl = new Howl({
      src,
      sprite: {loop: [0, 1000, true]}
    });
    howl.on('load', () => {
      resolve(howl);
    });
    howl.on('loaderror', () => {
      reject();
    });
  });
}

/**
 * This class manages loading and playback of audio loops.
 * Only one loop plays at the same time.
 */
export class LoopManager {
  /**
   * Cache of Howl instances. The reason we need the cache is that Howler
   * doesn't call the 'load' callback if you try to create a Howl instance for
   * a source that has been loaded previously. (It is unclear if this is the
   * intended behaviour or if it is a bug.)
   */
  private cachedHowls: {[key: number]: Howl} = {};

  /** The currently playing audio id (see Howler.js docs for details). */
  private audioId: number | undefined = undefined;
  /** The currently playing Howl. */
  private currentHowl: Readonly<Howl> | undefined = undefined;

  /**
   * Load the specified audio loop source and cache it under the specified key.
   * If the loop source has already been loaded, this function has no effect.
   *
   * NOTE: You must call setLoop() after loading to initialise the loop start
   * and end points!
   *
   * @param cacheKey The key under which to store this audio source. Must be unique for each source.
   * @param source The audio source to load.
   * @param onEnd This callback is called when playback reaches the end of the audio file.
   */
  public async load(
    cacheKey: number,
    source: Readonly<LoopPlayerSource>,
    onEnd: () => void
  ): Promise<void> {
    // If audio is currently playing, stop it.
    if (this.audioId !== undefined) {
      this.stop();
    }

    // If already have a Howler instance for the cache key, do nothing.
    if (this.cachedHowls[cacheKey] !== undefined) {
      this.currentHowl = this.cachedHowls[cacheKey];
      return;
    }

    // Tell Howler which URLs to use; prefer WebM to MP3 if possible (that's why it's pushed first).
    const src: Array<string> = [];
    if (source.urlWebm) {
      src.push(source.urlWebm);
    }
    if (source.urlMp3) {
      src.push(source.urlMp3);
    }

    try {
      const howl = await createHowl(src);
      howl.on('end', onEnd);
      this.cachedHowls[cacheKey] = howl;
      this.currentHowl = howl;
    } catch (error) {
      throw new Error('Could not load audio');
    }
  }

  /**
   * Return true if at least one audio source has been loaded.
   */
  public audioIsLoaded() {
    return this.currentHowl !== undefined;
  }

  /**
   * Set the start and end points for the currently loaded loop.
   */
  public setLoop(loopDefinition: Readonly<LoopDefinition>) {
    if (this.currentHowl === undefined) {
      return;
    }

    /* 
      ### Updating loops after the Howl instance has been created
      isn't officially supported, but a workaround is described in

        https://github.com/goldfire/howler.js/issues/535
      
      See also

        https://github.com/goldfire/howler.js/issues/630
      
      We should monitor the Howler repository and update this if/when
      the maintainers decide they want to support this.
    */
    // @ts-ignore: Work-around for missing API endpoints in Howler
    this.currentHowl._sprite.loop = [loopDefinition.startMs, loopDefinition.durationMs, true];
  }

  /**
   * Play the currently loaded loop.
   */
  public play() {
    if (this.currentHowl === undefined) {
      return;
    }
    if (this.audioId === undefined) {
      this.audioId = this.currentHowl.play('loop');
    } else {
      this.currentHowl.play(this.audioId);
    }
  }

  /**
   * Pause playback of the currently loaded loop.
   */
  public pause() {
    if (this.currentHowl === undefined) {
      return;
    }
    this.currentHowl.pause();
  }

  /**
   * Stop playback of the currently loaded loop; returns the playhead
   * the start point of the loop.
   */
  public stop() {
    if (this.currentHowl === undefined) {
      return;
    }
    this.currentHowl.stop();
    this.audioId = undefined;
  }

  /**
   * Return the current position of the audio playback position,
   * in seconds (from the start of the audio file), or undefined
   * if the audio is currently not playing.
   */
  public getPlayheadPos(): number | undefined {
    if (this.currentHowl === undefined) {
      return undefined;
    }
    if (this.audioId !== undefined) {
      const timeSec = this.currentHowl.seek(this.audioId);
      /*
        We need to check the return type here, since Howler's seek() method can return 
        both a Howl instance and a number.
      */
      if (typeof timeSec === 'number') {
        return timeSec;
      }
    }
    return undefined;
  }
}
