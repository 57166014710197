import {YouTubePlayer} from './youtube-api';

export interface Config {
  player: YouTubePlayer | undefined;
  timer: number | undefined;
}

function makeTimeUpdateTimer(
  player: YouTubePlayer,
  callback: (playpos: number, duration: number) => void,
  interval: number
) {
  return window.setInterval(() => {
    const playpos = player.getCurrentTime();
    const duration = player.getVideoDuration();
    callback(playpos, duration);
  }, interval);
}

/**
 * Return a function that pauses video playback.
 */
export function makePauseCallback(config: Readonly<Config>) {
  return async () => {
    if (config.player !== undefined) {
      config.player.pause();
    }
  };
}

/**
 * Return a function that returns the current play position.
 */
export function makeGetPlayPosCallback(config: Readonly<Config>) {
  return async () => {
    if (config.player !== undefined) {
      return config.player.getCurrentTime();
    }
    return 0;
  };
}

/**
 * Return a function that returns the video duration (in seconds).
 */
export function makeGetVideoDurationCallback(config: Readonly<Config>) {
  return async () => {
    if (config.player !== undefined) {
      return config.player.getVideoDuration();
    }
    return 0;
  };
}

/**
 * Return a function that cues a new video.
 */
export function makeCueVideoCallback(config: Config, _videoId: string) {
  return async (
    videoId: string,
    _: number | undefined,
    playCallback: (() => void) | undefined,
    pauseCallback: (() => void) | undefined,
    endCallback: (() => void) | undefined,
    timeUpdateCallback: ((playpos: number, duration: number) => void) | undefined
  ) => {
    config.player = new YouTubePlayer(videoId.toString());

    // Set up video player event callbacks.
    const play = () => {
      if (playCallback !== undefined) {
        playCallback();
      }
      // The YouTube API has no support for the 'timeUpdate' event, so
      // we have to implement it ourselves. A Javascript timer at .25 sec
      // intervals is accurate enough for what we want to do.
      if (timeUpdateCallback && config.timer === undefined && config.player !== undefined) {
        config.timer = makeTimeUpdateTimer(config.player, timeUpdateCallback, 250);
      }
    };

    const pause = () => {
      if (pauseCallback !== undefined) {
        pauseCallback();
      }
      // Disable the 'timeUpdate' timer when playback ends.
      if (config.timer !== undefined) {
        window.clearInterval(config.timer);
        config.timer = undefined;
      }
    };

    const end = () => {
      if (endCallback !== undefined) {
        endCallback();
      }
      // Disable the 'timeUpdate' timer when playback ends.
      if (config.timer !== undefined) {
        window.clearInterval(config.timer);
        config.timer = undefined;
      }
    };

    // Register the callbacks with the YouTube player.
    config.player.setCallbacks(play, pause, end);

    config.player.cue(videoId, undefined);
  };
}
