<template>
  <a :href="url" :target="target">
    <div
      :class="`w-full h-full ${bgColor} border border-dark rounded-lg select-none group transition ease-in-out duration-200 hover:bg-dark`"
    >
      <div class="h-full px-fl-xl-2xl py-fl-lg-xl flex flex-col justify-between">
        <div class="flex flex-col items-center">
          <div
            :class="`mb-fl-xs text-fl-3xl 2xl:text-fl-4xl text-center text-white font-[1000] italic uppercase`"
          >
            {{ config.title }}
          </div>
          <div class="text-fl-base text-light text-center">{{ config.summary }}</div>
        </div>
        <div :class="`pt-fl-lg flex flex-col justify-end items-center text-center ${textColor}`">
          <font-awesome-icon v-if="!disabled" class="text-fl-7xl" :icon="icon"></font-awesome-icon>
          <div v-else class="text-fl-xl">(Members Only)</div>
        </div>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {SectionPromoConfig} from './types';
import {faArrowCircleRight} from '@fortawesome/pro-solid-svg-icons/faArrowCircleRight';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    guest: {type: String, required: true},
    config: {type: Object as PropType<Readonly<SectionPromoConfig>>, required: true}
  },
  setup(props) {
    const bgColor = computed(() => (props.config.bgColor ? props.config.bgColor : 'bg-darkest'));
    const textColor = computed(() =>
      props.config.textColor ? props.config.textColor : 'text-light'
    );
    const disabled = computed(() => {
      if (props.config.membersOnly) {
        return props.guest;
      }
      return undefined;
    });
    const icon = computed(() => faArrowCircleRight);
    const url = computed(() => (disabled.value ? undefined : props.config.url));
    const target = computed(() => {
      return props.config.newTab ? '_blank' : undefined;
    });
    return {
      bgColor,
      textColor,
      disabled,
      icon,
      url,
      target
    };
  }
});
</script>
