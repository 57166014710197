import {DateTime} from 'luxon';
import {httpDelete, validatedHttpGet, validatedHttpPost} from '../http';
import {
  Bookmark,
  BOOKMARK_LIST_VALIDATION_SCHEMA,
  BOOKMARK_VALIDATION_SCHEMA
} from './bookmark-types';
import {CraftId, VALIDATION_CRAFT_ID, VALIDATION_CRAFT_ID_ARRAY} from '../craft/craft-types';
import {validate} from '../validation';
import {craftUrl} from '../../utils/url';

// ### Replace validatedHttpGet/Post() in functions here with get/postAction()

function idToString(bookmark: any) {
  // ??? It would be better to rewrite backend and frontend so all Craft id:s are numbers.
  const id = bookmark.id !== null ? `${bookmark.id}` : null;
  const entryId = bookmark.entryId !== null ? `${bookmark.entryId}` : null;
  const parentId = bookmark.parentId !== null ? `${bookmark.parentId}` : null;
  return {
    ...bookmark,
    id,
    entryId,
    parentId
  };
}

export async function getAllBookmarks(): Promise<ReadonlyArray<Bookmark>> {
  const url = craftUrl('/actions/sbl-module/bookmarks/get-bookmarks');
  try {
    const response = await validatedHttpGet<Array<Bookmark>>(url, BOOKMARK_LIST_VALIDATION_SCHEMA);
    const result = response.map(idToString);
    return result as ReadonlyArray<Bookmark>;
  } catch (error) {
    throw new Error(`Could not get all bookmarks: ${error}`);
  }
}

export async function findAllDescendants(id: CraftId): Promise<ReadonlyArray<Bookmark>> {
  const url = craftUrl(`/actions/sbl-module/bookmarks/find-all-descendants?id=${id}`);
  try {
    const response = await validatedHttpGet<Array<Bookmark>>(url, BOOKMARK_LIST_VALIDATION_SCHEMA);
    const result = response.map(idToString);
    return result as ReadonlyArray<Bookmark>;
  } catch (error) {
    throw new Error(`Could not get all bookmark desendants: ${error}`);
  }
}

export async function getDefaultBookmarks(): Promise<ReadonlyArray<Bookmark>> {
  const allBookmarks = await getAllBookmarks();
  const result = allBookmarks.filter(bm => {
    return bm.entryId !== null && bm.parentId === null;
  });
  return result;
}

export async function getBookmark(id: CraftId): Promise<ReadonlyArray<Bookmark>> {
  const url = craftUrl(`/actions/sbl-module/bookmarks/get-bookmark?id=${id}`);
  try {
    const response = await validatedHttpGet<Array<Bookmark>>(url, BOOKMARK_LIST_VALIDATION_SCHEMA);
    const result = response.map(idToString);
    return result as ReadonlyArray<Bookmark>;
  } catch (error) {
    throw new Error(`Could not get bookmark: ${error}`);
  }
}

export async function getSingleBookmark(id: CraftId): Promise<ReadonlyArray<Bookmark>> {
  const url = craftUrl(`/actions/sbl-module/bookmarks/get-single-bookmark?id=${id}`);
  try {
    const response = await validatedHttpGet<Array<Bookmark>>(url, BOOKMARK_LIST_VALIDATION_SCHEMA);
    const result = response.map(idToString);
    return result as ReadonlyArray<Bookmark>;
  } catch (error) {
    throw new Error(`Could not get single bookmark: ${error}`);
  }
}

/**
 * Return entry bookmarks, sorted by update date (latest first).
 */
export async function getLastUpdatedBookmarks(): Promise<ReadonlyArray<Bookmark>> {
  const allBookmarks = await getAllBookmarks();
  const result = allBookmarks
    .filter(bm => {
      return bm.entryId !== null;
    })
    .sort((a, b) => {
      if (a.dateUpdated === undefined || b.dateUpdated === undefined) {
        return 0;
      }
      const utcA = DateTime.fromISO(a.dateUpdated).valueOf();
      const utcB = DateTime.fromISO(b.dateUpdated).valueOf();
      return utcA - utcB;
    })
    .reverse();
  return result;
}

export async function addBookmark(bookmark: Readonly<Bookmark>): Promise<Readonly<Bookmark>> {
  if (bookmark.id !== undefined && bookmark.id !== null) {
    throw new Error('Bookmark id must be undefined/null');
  }
  const url = craftUrl('/actions/sbl-module/bookmarks/add-bookmark');
  try {
    const result = await validatedHttpPost(url, bookmark, BOOKMARK_VALIDATION_SCHEMA);
    return idToString(result) as Readonly<Bookmark>;
  } catch (error) {
    throw new Error(`Add bookmark error: ${error}`);
  }
}

export async function updateBookmark(bookmark: Readonly<Bookmark>): Promise<CraftId> {
  if (bookmark.id === undefined || bookmark.id === null) {
    throw new Error('Bookmark id must not be undefined/null');
  }
  const url = craftUrl('/actions/sbl-module/bookmarks/update-bookmark');
  try {
    const result = await validatedHttpPost<CraftId>(url, bookmark, VALIDATION_CRAFT_ID);
    return result;
  } catch (error) {
    throw new Error(`Update bookmark error: ${error}`);
  }
}

export async function updateBookmarks(
  bookmarks: ReadonlyArray<Bookmark>
): Promise<ReadonlyArray<CraftId>> {
  const url = craftUrl('/actions/sbl-module/bookmarks/update-bookmarks');
  try {
    const result = await validatedHttpPost<Array<CraftId>>(
      url,
      {bookmarks},
      VALIDATION_CRAFT_ID_ARRAY
    );
    return result;
  } catch (error) {
    throw new Error(`Update bookmark error: ${error}`);
  }
}

export async function deleteBookmark(bookmarkId: CraftId): Promise<ReadonlyArray<Bookmark>> {
  const url = craftUrl(`/actions/sbl-module/bookmarks/delete-bookmark?id=${bookmarkId}`);
  try {
    const response = await httpDelete(url);
    validate(response, BOOKMARK_LIST_VALIDATION_SCHEMA);
    return response as ReadonlyArray<Bookmark>;
  } catch (error) {
    throw new Error(`Delete bookmark error: ${error}`);
  }
}
