import {Entry} from '../../../../backend/craft/entry/entry-types';
import {BreadcrumbTrail, BreadcrumbTrails} from '../types';

export interface TrailQueryNode extends Entry {
  parents: Array<TrailQueryNode>;
}
export type TrailQueryTrails = {[entryId: string]: TrailQueryNode};

function flatten(trails: TrailQueryTrails): BreadcrumbTrails {
  const flattenNode = (node: Readonly<TrailQueryNode>): BreadcrumbTrail => {
    let result: BreadcrumbTrail = [];
    result.push(node);
    if (node.parents.length > 1) {
      /* 
        ??? For simplicity, we assume that all entries have a single parent. This is
        unlikely to be true in the future. The correct solution is to create a new
        trail for each parent we find here.
      */
      throw new Error('Unsupported');
    }
    node.parents.forEach(parent => {
      result = result.concat(flattenNode(parent));
    });
    return result;
  };

  const result: BreadcrumbTrails = {};
  Object.keys(trails).forEach(entryId => {
    // Check to see if the entry still exists (it may have been deleted).
    if (trails[entryId] !== null) {
      result[entryId] = flattenNode(trails[entryId]);
    }
  });
  return result;
}

export function parseBreadcrumbTrails(breadcrumbTrails: string): Readonly<BreadcrumbTrails> {
  const trails = JSON.parse(breadcrumbTrails) as Readonly<TrailQueryTrails>;
  const breadcrumbTrail = flatten(trails);
  return breadcrumbTrail;
}
