// ### This content might be better placed in the CMS at some point in time

// feedback section
export const feedbackHeading1 = 'Student Feedback';

export const feedbackHeading2 = 'Over 70K students trust SBL with their bass training.';

export const feedbackSummary =
  "Our students feel proud to be a part of SBL and are based all over the world. Whether they're total beginners or professionals, we meet students where they are and take them to where they want to be.";

export const quoteOne =
  'Joining the SBL Academy has helped me TREMENDOUSLY. I have never in my life felt this productive or focused as a musician.';

export const citeOne = 'Taylor Lee';

export const quoteTwo =
  'I was a bit stuck in terms of my progress when I first joined SBL, but the support I got from the community here has given me a huge boost!';

export const citeTwo = 'Daniel Wester';

export const quoteThree =
  "I love the more nuanced videos where Scott talks about letting the snare drum breathe. You can't really get that from an instructional book!";

export const citeThree = 'Maddie Jay';

export const quoteFour =
  'I really connect with the way that Scott teaches and the musical language he uses. I instantly knew that SBL would move my bass playing in the right direction.';

export const citeFour = 'Glen Whitney';

// team section
export const teamHeading = "We're driven to inspire and ignite the passions of all bass players.";

export const teamSummary =
  'We are a ferociously driven team of bass players, artists and visionaries, passionate about bringing world-class bass education to people of all ages, from all walks of life, all around the world.';

// timeline section
export const timelineHeading = 'The journey so far';

export const timelineYear1 = '2010';

export const timelineYear2 = '2011';

export const timelineYear3 = '2013';

export const timelineYear4 = '2014';

export const timelineYear5 = '2016';

export const timelineYear6 = '2017';

export const timelineYear7 = '2018';

export const timelineYear8 = '2020';

export const timelineYear9 = '2021';

export const timelineText1 =
  'Scott registered the URL scottsbasslessons.com and uploaded his first Youtube video.';

export const timelineText2 = 'First courses released.';

export const timelineText3 =
  "SBL moves into its first HQ aka 'The Towers'. Membership platform launched, live seminars streamed every week.";

export const timelineText4 = 'YouTube channel hits 5,475 subscribers.';

export const timelineText5 =
  "SBL podcast launched and placed into iTunes 'New and Noteworthy' section. SBL HQ moves to a larger facility aka 'The Loft'.";

export const timelineText6 =
  'Rapid enhancement of features and functionality of SBL, including a course library and expansion of tutor faculty. Youtube channel hits 475k subscribers.';

export const timelineText7 =
  'SBL is recognised as the largest bass education platform in the world.';

export const timelineText8 =
  "SBL nearly doubles in size, growing to 25k + members, and changes the game by rolling out Players Path, the world's first step-by-step online learning platform for bass players.";

export const timelineText9 =
  'SBL continues to raise the bar for online bass education, hiring educators like John Patitucci and Michael League, and launching Mentors, our live weekly coaching seminars, and Learning Pathways, which took the step-by-step learning of Players Path and applied it to multiple genres of music.';

// values section
export const valuesHeading =
  'We share the values and vision of our community. <span class="text-primary">Bass is in our blood.</span>';

export const valuesText1 =
  'From simple melodies to complex harmonies, steady beats to intricate rhythms, searing pain to soaring joy, music is the single global language that connects all of humanity.';

export const valuesText2 =
  'We open the minds of bass players around the world, help them overcome their challenges, enable them to build and refine their talent, and connect them with the global bass community.';

// vision & mission section
export const visionSubHeading = 'Vision and mission –';

export const visionHeading =
  'Our vision is to become the cultural centre of the bass universe. <span class="text-primary">Our mission, is to blow our students minds while doing it.</span>';

// roots section
export const rootsSubHeading = 'Roots –';

export const rootsHeading = 'It all started in a spare room, 2010.';

export const rootsText1 =
  'In 2010, after years of traveling the world as a bass guitarist for tour and studio work, and the occasional side trip to lecture at music schools, Scott Devine was inspired to offer lessons online. <strong>Real lessons.</strong>';

export const rootsText2 =
  'Lessons applicable to a range of existing skills. Lessons that can support a student-driven model. Lessons that combine the best qualities of one-on-one lessons and university education. Most importantly, lessons that would change the musical lives of the students.';

export const rootsText3 =
  "The combination of Scott's talent as a bass player, empathy as a teacher, and charisma as an on-camera personality attracted players and students from a global audience.";
