import * as Joi from 'joi';
import {
  VALIDATION_DATE,
  VALIDATION_NON_EMPTY_STRING,
  VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  VALIDATION_OPTIONAL_STRING,
  VALIDATION_POSITIVE_INTEGER
} from '../validation';

//
// TypeScript types that correspond to basic Craft data types.
//

/** Craft IDs are integers in the database, but the API returns them as strings. */
export type CraftId = string;
export type CraftTitle = string;
export type CraftUrl = string;
export type CraftOptionalUrl = string | null;
export type CraftSlug = string;
export type CraftPostDate = string;
export type CraftSectionHandle = string;
export type CraftSectionType = 'structure' | 'channel' | 'single';
export type CraftCategoryGroupHandle = string;
export type CraftEntryStatus = 'live' | 'pending';
export type CraftMimeType = string;

//
// Validation schemas for basic Craft data types.
//

/* Joi knows how to validate the content of a string as a number. */
export const VALIDATION_CRAFT_ID = VALIDATION_POSITIVE_INTEGER;
export const VALIDATION_CRAFT_TITLE = VALIDATION_NON_EMPTY_STRING;
export const VALIDATION_OPTIONAL_CRAFT_ID = VALIDATION_OPTIONAL_POSITIVE_INTEGER;
export const VALIDATION_CRAFT_ID_ARRAY = Joi.array().items(VALIDATION_OPTIONAL_POSITIVE_INTEGER);

/*
 * We don't use Joi's built-in URI validator here since
 * we have at least one entry with a slug that contains a non-ascii
 * character. (Joi won't accept URLs based on such slugs as valid.)
 */
export const VALIDATION_CRAFT_URL = VALIDATION_NON_EMPTY_STRING;
export const VALIDATION_CRAFT_OPTIONAL_URL = VALIDATION_OPTIONAL_STRING;
export const VALIDATION_CRAFT_SLUG = VALIDATION_NON_EMPTY_STRING;
export const VALIDATION_CRAFT_SECTION_HANDLE = VALIDATION_NON_EMPTY_STRING;
export const VALIDATION_CRAFT_POST_DATE = VALIDATION_DATE;
