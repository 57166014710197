<template>
  <variants>
    <template #narrow>
      <content-column>
        <div class="flex flex-col gap-fl-xl">
          <content-entry-header-resume
            v-if="!contentEntry.preview && !guest && progress !== undefined"
            :guest="guest"
            :content-entry="contentEntry"
            :progress="progress"
            :label="label"
            :size="'lg'"
            :expand="true"
          />
          <content-entry-header-unlock
            v-else-if="contentEntry.preview || guest"
            :guest="guest"
            :content-entry="contentEntry"
            :size="'lg'"
            :expand="true"
          />
          <div class="flex gap-fl-xl items-center">
            <content-entry-header-bookmark
              v-if="bookmark !== undefined"
              :guest="guest"
              :content-entry="contentEntry"
              :bookmark="bookmark"
            />
            <content-entry-header-campus :guest="guest" :content-entry="contentEntry" />
            <standard-divider
              v-if="!contentEntry.preview && !guest && progress !== undefined"
              :orientation="'vertical'"
              class="border-dark"
            />
            <content-entry-header-progress-toggle
              v-if="!guest && progress !== undefined"
              :guest="guest"
              :content-entry="contentEntry"
              :progress="progress"
            />
          </div>
        </div>
      </content-column>
    </template>
    <template #sm+>
      <content-column>
        <div class="flex gap-fl-xl items-center">
          <content-entry-header-resume
            v-if="!contentEntry.preview && !guest && progress !== undefined"
            :guest="guest"
            :content-entry="contentEntry"
            :progress="progress"
            :label="label"
            :expand="false"
            :size="'lg'"
          />
          <content-entry-header-unlock
            v-else-if="contentEntry.preview || guest"
            :guest="guest"
            :content-entry="contentEntry"
            :size="'md'"
          />
          <content-entry-header-bookmark
            v-if="bookmark !== undefined"
            :guest="guest"
            :content-entry="contentEntry"
            :bookmark="bookmark"
          />
          <content-entry-header-campus :guest="guest" :content-entry="contentEntry" />
          <content-entry-header-groove-trainer v-if="showGrooveTrainerButton" :guest="guest" />
          <content-entry-header-new-member-huddle
            v-if="showNewMemberHuddleButton"
            :preview="contentEntry.preview"
            :guest="guest"
          />
          <standard-divider
            v-if="!contentEntry.preview && !guest && progress !== undefined"
            :orientation="'vertical'"
            class="border-dark"
          />
          <content-entry-header-progress-toggle
            v-if="!contentEntry.preview && !guest && progress !== undefined"
            :guest="guest"
            :content-entry="contentEntry"
            :progress="progress"
          />
        </div>
      </content-column>
    </template>
  </variants>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import ContentEntryHeaderResume from './actions/ContentEntryHeaderResume.vue';
import ContentEntryHeaderUnlock from './actions/ContentEntryHeaderUnlock.vue';
import ContentEntryHeaderBookmark from './actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from './actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderGrooveTrainer from './actions/ContentEntryHeaderGrooveTrainer.vue';
import ContentEntryHeaderNewMemberHuddle from './actions/ContentEntryHeaderMemberHuddle.vue';
import ContentEntryHeaderProgressToggle from './actions/ContentEntryHeaderProgressToggle.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {ProgressComposition} from '../../../vue-composition/progress/types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';

export default defineComponent({
  components: {
    Variants,
    ContentColumn,
    StandardDivider,
    ContentEntryHeaderResume,
    ContentEntryHeaderUnlock,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus,
    ContentEntryHeaderGrooveTrainer,
    ContentEntryHeaderNewMemberHuddle,
    ContentEntryHeaderProgressToggle
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    label: {type: String, required: true},
    showGrooveTrainerButton: {type: Boolean, default: false},
    showNewMemberHuddleButton: {type: Boolean, default: false},
    progress: {type: Object as PropType<Readonly<ProgressComposition>>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, default: undefined}
  },
  setup() {}
});
</script>
