import * as Joi from 'joi';
import {VALIDATION_NON_EMPTY_STRING, VALIDATION_POSITIVE_INTEGER} from '../validation';
import {httpDelete, httpPost, validatedHttpGet, validatedHttpPost} from '../http';
import {CraftId, VALIDATION_CRAFT_ID, VALIDATION_CRAFT_ID_ARRAY} from '../craft/craft-types';
import {History} from './history-types';
import {validate} from '../validation';
import {ContentEntry} from '../content/content-entry/content-entry-types';
import {craftUrl} from '../../utils/url';
import {fetchContentEntries} from '../content/content-entry/content-entry-query';
import {contentEntries} from '../content/content-entry/content-entry-query-builder';

// ### Replace validatedHttpGet/Post() in functions here with get/postAction()

export const HISTORY_VALIDATION_SCHEMA = Joi.object({
  id: VALIDATION_CRAFT_ID,
  entryId: VALIDATION_CRAFT_ID,
  accesses: VALIDATION_POSITIVE_INTEGER,
  dateUpdated: VALIDATION_NON_EMPTY_STRING
});

export const HISTORY_LIST_VALIDATION_SCHEMA = Joi.array().items(HISTORY_VALIDATION_SCHEMA);

export async function getHistory(): Promise<ReadonlyArray<History>> {
  const url = craftUrl('/actions/sbl-module/history/get-history');
  try {
    const response = await validatedHttpGet<Array<History>>(url, HISTORY_LIST_VALIDATION_SCHEMA);
    const result = response.map((b: any) => {
      // ??? It would be better to rewrite backend and frontend so all Craft id:s are numbers.
      const id = b.id !== null ? `${b.id}` : null;
      const entryId = b.entryId !== null ? `${b.entryId}` : null;
      return {
        ...b,
        id,
        entryId
      };
    });
    return result;
  } catch (error) {
    throw new Error(`Could not get history: ${error}`);
  }
}

export async function getLimitedHistory(limit: number): Promise<ReadonlyArray<ContentEntry>> {
  const url = craftUrl(`/actions/sbl-module/history/get-limited-history?limit=${limit}`);
  try {
    const entryIds = await validatedHttpGet<Array<CraftId>>(url, VALIDATION_CRAFT_ID_ARRAY);
    const result = await fetchContentEntries(contentEntries().id(entryIds));
    return result;
  } catch (error) {
    throw new Error(`Could not get limited history: ${error}`);
  }
}

export async function addOrUpdateHistory(entryId: CraftId): Promise<Readonly<History>> {
  const url = craftUrl(`/actions/sbl-module/history/add-history?entryId=${entryId}`);
  try {
    const result = await validatedHttpPost<History>(url, {}, HISTORY_VALIDATION_SCHEMA);
    return result;
  } catch (error) {
    throw new Error(`Could not add/update history: ${error}`);
  }
}

export async function deleteHistory(id: CraftId): Promise<ReadonlyArray<History>> {
  const url = craftUrl(`/actions/sbl-module/history/delete-history?id=${id}`);
  try {
    const response = await httpDelete(url);
    validate(response, HISTORY_LIST_VALIDATION_SCHEMA);
    return response as ReadonlyArray<History>;
  } catch (error) {
    throw new Error(`Could not delete history: ${error}`);
  }
}

export async function clearHistory(): Promise<void> {
  const url = craftUrl('/actions/sbl-module/history/clear-history');
  try {
    const {ok, status, bodyJson} = await httpPost(url, {});
    if (!ok) {
      throw new Error(`HTTP Post failed (${status}): ${bodyJson}`);
    }
  } catch (error) {
    throw new Error(`Could not clear history: ${error}`);
  }
}
