import {CraftSlug} from '../../craft/craft-types';
import {VIDEOS_FIELD} from '../../video/video-query';
import {IMAGE_COLLECTION_FIELD} from '../../image-collection/image-collection-query';
import {LandingPageEntry} from './landing-page-types';
import {fetchEntries} from '../../craft/entry/entry-query';
import * as QueryBuilder from '../../craft/query/craft-query-builder';

export async function getLandingPage(
  slug: CraftSlug
): Promise<Readonly<LandingPageEntry> | undefined> {
  const query = QueryBuilder.entries()
    .section(['landingPages'])
    .slug([slug])
    .fields([
      QueryBuilder.plainText('landingPageFeatures'),
      QueryBuilder.plainText('landingPageTagline'),
      QueryBuilder.plainText('summary'),
      VIDEOS_FIELD,
      IMAGE_COLLECTION_FIELD
    ]);
  const result = await fetchEntries<LandingPageEntry>(query);
  const entry = result[0];
  if (entry === undefined) {
    return undefined;
  }
  return entry;
}
