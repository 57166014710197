import {AccessPassStatus} from '../../access-pass/access-pass-types';
import {Category} from '../../craft/category/category-types';
import {
  OfferConditionsAccessPass,
  OfferConditionsAccessPassStatus
} from '../offer-conditions-types';

function findPasses(
  userAccessPasses: ReadonlyArray<AccessPassStatus>,
  passes: ReadonlyArray<Category>,
  status: OfferConditionsAccessPassStatus
) {
  const result = userAccessPasses.filter((userPass: Readonly<AccessPassStatus>) => {
    const pass = passes.find(p => p.id === userPass.id);
    if (pass === undefined) {
      return false;
    }
    if (status === 'any') {
      return true;
    }
    if (status === 'active') {
      return userPass.status === 'active' || userPass.status === 'pending';
    }
    if (status === 'notInactive') {
      return userPass.status !== 'inactive';
    }
    return status === userPass.status;
  });
  return result;
}

export function evaluateAccessPass(
  condition: Readonly<OfferConditionsAccessPass>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const matches = findPasses(userAccessPasses, condition.accessPasses, condition.passStatus);
  const result = matches.length > 0;
  switch (condition.condition) {
    case 'true':
      return result;
    case 'false':
      return !result;
    default:
      throw new Error('Internal error');
  }
}
