<template>
  <div class="bg-gradient-to-b from-landing-page-gradient-start to-landing-page-gradient-end">
    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <div class="flex flex-col">
          <div class="text-fl-6xl sm:text-fl-11xl text-white font-semibold sm:text-center mb-fl-xs">
            Welcome!
          </div>
          <div class="text-fl-6xl sm:text-fl-11xl text-primary font-semibold sm:text-center">
            It's great to have you here.
          </div>
        </div>
      </content-column>
    </page-section>
    <page-section :margin-top="'xs'">
      <content-column :width="'narrow'">
        <div class="text-fl-xl sm:text-fl-3xl text-light sm:text-center font-semibold">
          Welcome to your dashboard, where you can continue where you left off, see new and upcoming
          content, and much more.
        </div>
      </content-column>
    </page-section>
    <page-section :margin-top="'md'">
      <content-column>
        <video-player
          :guest="''"
          :preview="false"
          :resume-at-last-timestamp="false"
          :video="video"
        />
      </content-column>
    </page-section>
    <page-section :padding-bottom="'lg'">
      <content-column>
        <div class="flex flex-col justify-center items-center gap-y-fl-xl">
          <standard-button :size="buttonSize" :label="'Academy Tour'" :url="'/orientation'" />
          <link-button :label="'Don\'t show me this again'" :on-click="onHide" />
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, ref} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import VideoPlayer from '../../../generic/video-player/VideoPlayer.vue';
import {Video} from '../../../../backend/video/video-types';
import {ButtonClickCallback, ButtonSize} from '../../../core/button/implementation/utils';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardButton,
    LinkButton,
    VideoPlayer
  },
  props: {
    userFriendlyName: {type: String, required: true},
    onHide: {type: Function as PropType<ButtonClickCallback>, required: true}
  },
  setup() {
    const hideWelcome = ref(false);

    const video: Readonly<Video> = {
      videoServiceProvider: 'vimeo',
      videoId: '850833939',
      videoType: 'content',
      videoDuration: ''
    };

    const buttonSize = deviceTypeSwitch<ButtonSize>({
      narrow: 'md',
      sm: 'lg'
    });

    return {
      hideWelcome,
      video,
      buttonSize
    };
  }
});
</script>
