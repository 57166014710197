import {httpGet} from '../http';
import {HelpScoutArticle} from './helpscout-types';

/**
 * Connect to the HelpScout API using the specified credentials
 * and download the specified article collection.
 */
export async function getHelpScoutArticles(
  username: string,
  password: string,
  collectionId: string
): Promise<Array<HelpScoutArticle>> {
  // ### Use something safer than Basic Auth?
  // ### Replace the FAQ system with a mechanism that doesn't involve a third-party software?
  const url =
    'https://docsapi.helpscout.net/v1/collections/' +
    collectionId +
    '/articles?sort=popularity&status=published';
  const authString = window.btoa(`${username}:${password}`);
  const headers = {Authorization: `Basic ${authString}`};

  // ??? The response should be validated with Joi.
  const response = (await httpGet(url, headers)) as any;

  if (response.articles !== undefined) {
    if (response.articles.items !== undefined) {
      return response.articles.items.filter((item: any) => {
        const article: HelpScoutArticle = {
          id: item.id,
          name: item.name,
          number: item.number,
          status: item.status,
          publicUrl: item.publicUrl
        };
        return article;
      });
    }
  }

  return [];
}
