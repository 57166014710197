export type CompletedMap = {[entryId: string]: boolean};
export type UnlockedMap = {[entryId: string]: boolean};
export type DurationMap = {[entryId: string]: number};

export interface DisplayConfig {
  tutors: boolean;
  levels: boolean;
  subjects: boolean;
  summary: boolean;
  bookmark: boolean;
}

export const DEFAULT_DISPLAY_CONFIG_INLINE_CARD: Readonly<DisplayConfig> = {
  tutors: true,
  levels: true,
  subjects: true,
  summary: true,
  bookmark: true
};

export const DEFAULT_DISPLAY_CONFIG_ITEM: Readonly<DisplayConfig> = {
  tutors: true,
  levels: false,
  subjects: false,
  summary: false,
  bookmark: true
};
