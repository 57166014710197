<template>
  <div v-if="contentEntries.length > 0">
    <page-section :margin-top="marginTop">
      <page-section-header :width="width" :justify="justify" :heading="heading" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-entry-reel
        :guest="guest"
        :content-entries="contentEntries"
        :bookmark="bookmark"
        :width="width"
        :justify="justify"
        :progress="progress.progressMap.value"
      />
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import ContentEntryReel from '../../collections/variants/ContentEntryReel.vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {useProgressMap} from '../../../vue-composition/progress/progress';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    ContentEntryReel
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'normal'},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    heading: {type: String, default: 'Recommended Courses'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: undefined}
  },
  setup(props) {
    const contentEntries = computed(() => {
      return props.contentEntry.recommendations;
    });
    const progress = useProgressMap(props.guest, contentEntries);

    return {
      contentEntries,
      progress
    };
  }
});
</script>
