<template>
  <img
    class="w-full h-full"
    :src="'https://scottsbasslessons.imgix.net/content/logo-fill.svg'"
    alt="Scott's Bass Lessons"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  setup() {}
});
</script>
