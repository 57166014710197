import {fetchContentEntries} from '../content-entry/content-entry-query';
import {contentEntries} from '../content-entry/content-entry-query-builder';
import {ContentEntry} from '../content-entry/content-entry-types';

export async function getAllInterviews(): Promise<Array<ContentEntry>> {
  try {
    const result = await fetchContentEntries<ContentEntry>(
      contentEntries().section(['interviews'])
    );
    return result;
  } catch (error) {
    throw new Error(`Could not fetch interviews: ${error}`);
  }
}

export async function getInterviewBySlug(
  slug: string
): Promise<Readonly<ContentEntry | undefined>> {
  try {
    const result = await fetchContentEntries<ContentEntry>(
      contentEntries().section(['interviews']).slug([slug])
    );
    return result[0];
  } catch (error) {
    throw new Error(`Could not fetch interview: ${error}`);
  }
}
