<template>
  <link-icon-button
    v-if="!guest"
    :size="'xl'"
    :icon="icon"
    :on-click="onClick"
    :tooltip="'Add to my bookmarks'"
    :color="color"
    class="pt-1"
  />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import LinkIconButton from '../../../../core/button/LinkIconButton.vue';
import {faBookmark} from '@fortawesome/pro-light-svg-icons/faBookmark';
import {faBookmark as faSolidBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../../vue-composition/bookmark/types';
import {ButtonColor} from '../../../../core/button/implementation/utils';

export default defineComponent({
  components: {
    LinkIconButton
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    color: {type: String as PropType<ButtonColor>, default: 'mid'}
  },
  setup(props) {
    const onClick = () => {
      props.bookmark.showAddBookmarkDialog(props.contentEntry);
    };

    const icon = computed(() => {
      const bookmarked = props.bookmark.bookmarkedEntries.value.includes(props.contentEntry.id);
      return bookmarked ? faSolidBookmark : faBookmark;
    });

    return {
      icon,
      onClick
    };
  }
});
</script>
