import {ComputedRef, Ref, computed, ref} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';

export type PaginationComposition<T = ContentEntry> = {
  items: Ref<ReadonlyArray<T>>;
  more: Ref<boolean>;
  onMore: () => void;
};

export function usePagination<T = ContentEntry>(
  items: ComputedRef<ReadonlyArray<T>>,
  pageSize: number = 12
): Readonly<PaginationComposition<T>> {
  // Pagination
  const page = ref(1);
  const itemsToDisplay = computed(() => {
    return items.value.slice(0, page.value * pageSize);
  });
  const more = computed(() => {
    return page.value * pageSize < items.value.length;
  });
  const onMore = () => {
    page.value = page.value + 1;
  };
  return {
    items: itemsToDisplay,
    more,
    onMore
  };
}
