<template>
  <page-content>
    <page-section>
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header :center="false" :spot-color="spotColor">
          {{ level.title }}
        </content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <cluster>
          <content-entry-header-tutors :content-entry="level" />
          <pill class="border-darkest bg-darkest text-mid">{{ pillTitle }}</pill>
          <content-entry-header-beginner-journey :trails="trails" />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="text-fl-3xl text-white" v-html="level.summary" />
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column>
        <cluster :gap="'gap-x-fl-xl gap-y-fl-md'">
          <players-path-quiz-button
            v-if="entryQuiz !== undefined"
            :entry-quiz="entryQuiz"
            :spot-color="spotColor"
          />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="level"
            :bookmark="bookmark"
          />
          <content-entry-header-campus :guest="guest" :content-entry="level" />
        </cluster>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <content-entry-video :content-entry="level" :video-type="'trailer'" :guest="guest" />
      </content-column>
    </page-section>

    <page-section v-if="entryQuiz !== undefined">
      <content-column>
        <repel>
          <standard-heading :size="2"
            >Take the Entry Quiz to begin
            <font-awesome-icon :icon="iconArrow" class="px-fl-xs"></font-awesome-icon>
          </standard-heading>
          <players-path-quiz-button
            v-if="entryQuiz !== undefined"
            :entry-quiz="entryQuiz"
            :spot-color="spotColor"
          />
        </repel>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <standard-divider :class="'border-dark'" />
      </content-column>
    </page-section>

    <page-section>
      <page-section-header :heading="'Preview Study Pieces'" />
    </page-section>
    <page-section :margin-top="'sm'">
      <content-column>
        <div v-if="!level.preview && !guest" class="text-fl-lg">
          <p>Listen to previews of the Study Pieces below.</p>
          <p>Pass the Entry Quiz to unlock the Lessons and learning tools.</p>
        </div>
        <p v-else>This level contains the Study Pieces below.</p>
      </content-column>
    </page-section>
    <page-section v-if="level.playersPathPieces.length > 0" :margin-top="'sm'">
      <players-path-piece-list
        :guest="guest"
        :preview="level.preview"
        :pieces="level.playersPathPieces"
        :spot-color="spotColor"
        :bookmark="bookmark"
      />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import PlayersPathPieceList from '../partials/PlayersPathPieceList.vue';
import PlayersPathQuizButton from '../partials/PlayersPathQuizButton.vue';
import ContentEntryHeader from '../../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderBookmark from '../../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderCampus from '../../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderBeginnerJourney from '../../../content-entry/partials/header/metadata/ContentEntryHeaderBeginnerJourney.vue';
import ContentEntryVideo from '../../../content-entry/partials/body/ContentEntryVideo.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Pill from '../../../generic/pill/Pill.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {Quiz} from '../../../../backend/content/quiz/quiz-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {BreadcrumbTrailComposition} from '../../../vue-composition/breadcrumb-trail/types';
import {ColorConfig} from '../../../core/color-config';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    PageSectionHeader,
    ContentColumn,
    Cluster,
    Repel,
    PlayersPathPieceList,
    PlayersPathQuizButton,
    StandardHeading,
    StandardDivider,
    Pill,
    FontAwesomeIcon,
    ContentEntryVideo,
    ContentEntryHeader,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeaderTutors,
    ContentEntryHeaderBeginnerJourney,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderCampus
  },
  props: {
    guest: {type: String, required: true},
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    levelNumber: {type: Number, required: true},
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true},
    entryQuiz: {type: Object as PropType<Readonly<Quiz> | undefined>, default: undefined},
    trails: {type: Object as PropType<Readonly<BreadcrumbTrailComposition>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const pillTitle = computed(() => {
      if (props.levelNumber >= 1 && props.levelNumber <= 3) {
        return 'Beginner';
      }
      if (props.levelNumber >= 4 && props.levelNumber <= 6) {
        return 'Intermediate';
      }
      return 'Advanced';
    });

    const iconArrow = computed(() => faArrowRight);

    return {
      iconArrow,
      pillTitle
    };
  }
});
</script>
