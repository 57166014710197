<template>
  <cluster :gap="'gap-1'" :wrap="false" class="text-fl-base">
    <font-awesome-icon class="mr-fl-2xs" :icon="icon"></font-awesome-icon>
    <div>{{ date }}</div>
  </cluster>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import Cluster from '../../../../core/compositions/Cluster.vue';
import {postDateToLocale} from '../../../../../backend/craft/entry/entry-utils';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons/faCalendar';

export default defineComponent({
  components: {
    Cluster,
    FontAwesomeIcon
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    format: {type: String as PropType<'short' | 'long'>, default: 'short'}
  },
  setup(props) {
    const date = postDateToLocale(props.contentEntry.postDate, props.format);
    const icon = computed(() => faCalendar);
    return {
      date,
      icon
    };
  }
});
</script>
