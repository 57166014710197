<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <content-entry-header>Search SBL</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'wide'">
        <global-search-input
          :on-search="onSearch"
          :show-help="showHelp"
          :page-search-term="searchTerm"
        />
      </content-column>
    </page-section>

    <div v-if="showResults">
      <page-section>
        <content-column :width="'wide'">
          <standard-heading :size="2">Search Results</standard-heading>
        </content-column>
      </page-section>

      <page-section
        :margin-top="'sm'"
        :margin-bottom="'xs'"
        :class="'sticky left-0 top-[80px] self-start z-10'"
      >
        <content-entry-collection-mode-bar :collection="collection" />
      </page-section>

      <content-entry-section-list
        :guest="guest"
        :bookmark="bookmark"
        :width="'wide'"
        :disabled="disabled"
        :sections="collection.searchSortFilterOutput.value"
        :layout="collection.layout.value"
        :display-post-date="false"
        :card-image-width="cardImageWidth"
        :imgix-params="{ar: '16:9', fit: 'crop'}"
      />
    </div>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import ContentEntryCollectionModeBar from '../content-entry/collections/utilities/ContentEntryCollectionModeBar.vue';
import ContentEntrySectionList from '../content-entry/collections/variants/ContentEntrySectionList.vue';
import ContentEntryHeader from '../content-entry/partials/header/ContentEntryHeader.vue';
import {useFullScreenLoader} from '../vue-composition/loader/loader';
import StandardHeading from '../core/standard-heading/StandardHeading.vue';
import PageContent from '../core/page/PageContent.vue';
import PageSection from '../core/page/PageSection.vue';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import {getAccessPassesForCurrentUser} from '../../backend/access-pass/access-pass-query';
import {AccessPassStatus} from '../../backend/access-pass/access-pass-types';
import {getSearchSections} from '../../backend/search/search-query';
import {craftUrl, getUrlQueryParams, redirectNoHistory} from '../../utils/url';
import {useCollection} from '../content-entry/collections/utilities/collection/collection';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import {ImgixImageResponsiveWidth} from '../core/imgix-image/types';
import {useBookmark} from '../vue-composition/bookmark/bookmark';
import {useModalDialog} from '../vue-composition/modal-dialog/modal-dialog';
import GlobalSearchHelpDialog from './partials/GlobalSearchHelpDialog.vue';
import GlobalSearchInput from './partials/GlobalSearchInput.vue';
import {ContentEntrySortScheme} from '../vue-composition/sort/types';
import {usePaginatedContentEntryFetchBySection} from '../vue-composition/paginated-fetch/paginated-content-entry-fetch';
import {contentEntries} from '../../backend/content/content-entry/content-entry-query-builder';

function getSearchTerm() {
  const params = getUrlQueryParams();
  const q = params.get('q');
  if (q !== null && q.length > 0) {
    return decodeURIComponent(q);
  }
  return undefined;
}

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    StandardHeading,
    ContentEntryHeader,
    GlobalSearchInput,
    ContentEntrySectionList,
    ContentEntryCollectionModeBar,
    ContentColumn
  },
  props: {
    guest: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const bookmark = useBookmark(props.guest);
    const accessPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);

    const paginatedFetch = usePaginatedContentEntryFetchBySection();
    const disabled = computed(() => paginatedFetch.sections.value.length === 0);
    const sortSchemes: ReadonlyArray<ContentEntrySortScheme> = ['default', 'newest', 'oldest'];
    const collection = useCollection(paginatedFetch.sections, undefined, accessPasses, sortSchemes);
    const searchTerm = getSearchTerm();

    onMounted(async () => {
      loader.setLoading(true);
      await bookmark.init();
      if (searchTerm !== undefined) {
        accessPasses.value = await getAccessPassesForCurrentUser();
        await collection.init();
        const searchSections = await getSearchSections();
        const correctedSearchTerm = searchTerm.replace(/\"/g, "'");
        paginatedFetch.start(
          searchSections,
          contentEntries()
            .section(searchSections.map(s => s.handle))
            .status(['live', 'pending'])
            .search(correctedSearchTerm)
        );
      }
      loader.setLoading(false);
    });

    const onSearch = (searchTerm: string) => {
      const url = craftUrl(`/search?q=${encodeURIComponent(searchTerm)}`);
      redirectNoHistory(url);
    };

    const modalDialog = useModalDialog(GlobalSearchHelpDialog);
    const showHelp = () => {
      modalDialog.show(
        () => {},
        () => {},
        {},
        false
      );
    };

    const icon = computed(() => faInfoCircle);

    const showResults = computed(() => {
      return searchTerm !== undefined && searchTerm.length > 0;
    });

    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 300,
      sm: 340,
      md: 350,
      lg: 300,
      xl: 330,
      '2xl': 400
    };

    return {
      bookmark,
      icon,
      onSearch,
      modalDialog,
      showHelp,
      searchTerm,
      collection,
      disabled,
      showResults,
      cardImageWidth
    };
  }
});
</script>
../vue-composition/paginated-fetch/paginated-content-entry-fetch
