import * as Joi from 'joi';

export const VALIDATION_NON_EMPTY_STRING = Joi.string().required();
export const VALIDATION_OPTIONAL_STRING = Joi.string().optional().allow(null, '');
export const VALIDATION_BOOLEAN = Joi.boolean().required();
export const VALIDATION_OPTIONAL_BOOLEAN = Joi.boolean().optional();
export const VALIDATION_POSITIVE_INTEGER = Joi.number().integer().min(0).required();
export const VALIDATION_OPTIONAL_POSITIVE_INTEGER = Joi.number().integer().min(0).allow(null);
export const VALIDATION_INTEGER = Joi.number().integer().required();
export const VALIDATION_NUMBER = Joi.number().required();
export const VALIDATION_URL = Joi.string().uri().required();
export const VALIDATION_DATE = Joi.string().isoDate().required();
export const VALIDATION_OPTIONAL_DATE = Joi.string().isoDate().optional().allow(null, '');
export const VALIDATION_EMAIL = Joi.string().email({
  tlds: {allow: false},
  multiple: false
});
export const VALIDATION_SQL_TIMESTAMP = Joi.date().timestamp().required();

export function validate(
  response: any,
  schema:
    | Joi.ObjectSchema
    | Joi.ArraySchema
    | Joi.NumberSchema
    | Joi.BooleanSchema
    | Joi.StringSchema
): void {
  const result = schema.validate(response);
  if (result.error !== undefined) {
    console.error(response);
    throw new Error(`Validation error: ${result.error.message}`);
  }
}
