<template>
  <div v-if="!preview && !guest && (songs.length > 0 || audioUrl !== undefined)">
    <margins>
      <content-area :area="playerArea">
        <div class="flex flex-col gap-fl-lg-2xl">
          <div v-if="songs.length > 0">
            <standard-heading class="mb-fl-md-lg" :size="3">Looper</standard-heading>
            <song-player
              v-for="song in songs"
              :key="song.id"
              :preview="preview"
              :guest="guest"
              :song="song"
            />
          </div>
          <div v-if="audioUrl !== undefined">
            <standard-heading class="mb-fl-md-lg" :size="3">Backing Track</standard-heading>
            <audio-player :guest="guest" :preview="preview" :url="audioUrl" />
          </div>
        </div>
      </content-area>
      <content-area v-if="slotArea !== null" :area="slotArea">
        <slot />
      </content-area>
    </margins>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Song} from '../../../../backend/song/song-types';
import Margins from '../../../core/column-layout/Margins.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import SongPlayer from '../../../generic/song-player/SongPlayer.vue';
import AudioPlayer from '../../../generic/audio-player/AudioPlayer.vue';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {ColumnArea} from '../../../core/column-layout/types';

export default defineComponent({
  components: {
    Margins,
    ContentArea,
    StandardHeading,
    SongPlayer,
    AudioPlayer
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    songs: {type: Array as PropType<ReadonlyArray<Song>>, required: true},
    audioUrl: {type: String as PropType<CraftUrl>, default: undefined}
  },
  setup() {
    const playerArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: 4},
      sm: {start: 1, span: 12},
      lg: {start: 1, span: 8}
    });
    const slotArea = deviceTypeSwitch<ColumnArea | null>({
      narrow: null,
      lg: {start: 10, span: 3}
    });
    return {
      playerArea,
      slotArea
    };
  }
});
</script>
