<template>
  <page-content>
    <!-- Career page header -->
    <div
      class="bg-[url('https://scottsbasslessons.imgix.net/header-backgrounds/team.png?auto=format')] bg-cover bg-no-repeat bg-center bg-black"
    >
      <page-section
        :margin-top="'none'"
        :padding-top="pageSectionPadding"
        :padding-bottom="pageSectionPadding"
      >
        <content-column>
          <flow :gap="8">
            <standard-heading :size="4">CAREERS –</standard-heading>
            <standard-heading :size="heading1Size">
              Through teamwork, community and collaboration, we believe we can make a difference in
              people's lives.
            </standard-heading>
            <standard-heading :size="heading2Size" :spot-color="subHeadingSpotColor">
              If you're passionate about music and helping people do more of what they love, you're
              in the right place.
            </standard-heading>
          </flow>
        </content-column>
      </page-section>
    </div>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Flow from '../../../core/compositions/Flow.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    Flow
  },
  setup() {
    const deviceType = useDeviceType();
    const pageSectionPadding = computed(() => {
      return deviceType.largerThanOrEqualTo('md') ? 'xl' : 'lg';
    });
    const heading1Size = computed(() => {
      return deviceType.largerThanOrEqualTo('md') ? 1 : 2;
    });
    const heading2Size = computed(() => {
      return deviceType.largerThanOrEqualTo('md') ? 2 : 3;
    });
    const subHeadingSpotColor = {
      bg: 'bg-black',
      bgHover: 'bg-black',
      text: 'text-primary',
      textHover: 'hover:text-primary',
      border: 'border-black',
      borderHover: 'hover:border-black'
    };

    return {
      subHeadingSpotColor,
      pageSectionPadding,
      heading1Size,
      heading2Size
    };
  }
});
</script>
