import {fetchGlobalSet} from '../craft/global-set/global-set-query';
import {Webcast, Webcasts} from './webcast-types';
import * as ContentEntryQueryBuilder from '../content/content-entry/content-entry-query-builder';
import * as QueryBuilder from '../craft/query/craft-query-builder';

const WEBCASTS_FIELD = QueryBuilder.matrix('webcasts').blocks([
  QueryBuilder.block('webcast').fields([
    ContentEntryQueryBuilder.contentEntries('webcastEntry').status(['live', 'pending']),
    QueryBuilder.lightswitch('webcastOnAir'),
    QueryBuilder.plainText('webcastVimeoId'),
    QueryBuilder.plainText('webcastChatrollId'),
    QueryBuilder.url('webcastExternalLink'),
    QueryBuilder.plainText('webcastExternalLinkInstructions'),
    QueryBuilder.plainText('webcastPendingText'),
    QueryBuilder.plainText('webcastPanicMessage'),
    QueryBuilder.plainText('webcastEmbedUrl')
  ])
]);

export async function getWebcasts(): Promise<ReadonlyArray<Webcast>> {
  const result = await fetchGlobalSet<Webcasts>(
    QueryBuilder.globalSet('webcasts').fields([WEBCASTS_FIELD])
  );
  return result.webcasts;
}
