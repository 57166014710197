<template>
  <div class="flex flex-col">
    <card :url="contentEntry.url">
      <template #hero>
        <card-hero-image :config="hero" />
      </template>
      <template #body>
        <div class="text-fl-base text-mid">
          <standard-heading :size="4" class="pt-fl-lg">{{ contentEntry.title }}</standard-heading>
          <cluster class="pt-fl-sm">
            <content-entry-header-tutors :size="'xs'" :content-entry="contentEntry" />
            <card-body-post-date :content-entry="contentEntry" />
          </cluster>
          <div v-if="contentEntry.summary !== undefined" class="pt-fl-md">
            {{ contentEntry.summary }}
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import Cluster from '../../../core/compositions/Cluster.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import ContentEntryHeaderTutors from '../../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import CardBodyPostDate from '../../../generic/card/partials/body/CardBodyPostDate.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';

export default defineComponent({
  components: {
    Cluster,
    StandardHeading,
    Card,
    CardHeroImage,
    ContentEntryHeaderTutors,
    CardBodyPostDate
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 690,
      md: 300,
      lg: 370,
      xl: 450,
      '2xl': 530
    };
    const hero = getImageConfig(
      props.contentEntry.imageCollection,
      ['cover'],
      props.contentEntry.title,
      cardImageWidth,
      {ar: '16:9', fit: 'crop'}
    );
    return {hero};
  }
});
</script>
