<template>
  <div :class="`border rounded-md ${color}`">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ColorConfig} from '../color-config';

export default defineComponent({
  props: {
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined}
  },
  setup(props) {
    const color = computed(() => {
      if (props.spotColor !== undefined) {
        return props.spotColor.border;
      }
      return 'border-dark';
    });
    return {color};
  }
});
</script>
