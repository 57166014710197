import {fetchContentEntries} from '../content/content-entry/content-entry-query';
import {contentEntries} from '../content/content-entry/content-entry-query-builder';
import {ContentEntry} from '../content/content-entry/content-entry-types';

export async function getOrientation(): Promise<Array<ContentEntry>> {
  try {
    const entries = await fetchContentEntries(contentEntries().section(['orientation']));
    return entries;
  } catch (error) {
    throw new Error(`Could not fetch orientation: ${error}`);
  }
}
