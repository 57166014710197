<template>
  <page-section v-if="contentEntries !== undefined" :margin-top="'sm'">
    <content-entry-reel
      :width="width"
      :guest="guest"
      :content-entries="contentEntries"
      :bookmark="bookmark"
      :justify="justify"
    />
  </page-section>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref} from 'vue';
import {RichTextBlock, RichTextEntries} from '../../../../backend/rich-text/rich-text-types';
import {useLoader} from '../../../vue-composition/loader/loader';
import PageSection from '../../../core/page/PageSection.vue';
import ContentEntryReel from '../../../content-entry/collections/variants/ContentEntryReel.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {useBookmark} from '../../../vue-composition/bookmark/bookmark';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentEntryReel
  },
  props: {
    guest: {type: String, required: true},
    richTextEntries: {type: Object as PropType<RichTextBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const loader = useLoader();
    const contentEntries = ref<ReadonlyArray<ContentEntry>>([]);
    const bookmark = useBookmark(props.guest);
    onMounted(async () => {
      loader.setLoading(true);
      const block = props.richTextEntries as Readonly<RichTextEntries>;
      contentEntries.value = block.richTextEntryReferences;
      await bookmark.init();
      loader.setLoading(false);
    });
    return {
      contentEntries,
      bookmark
    };
  }
});
</script>
