<template>
  <div
    v-for="item in itemsWithColor"
    :key="item.id"
    :wrap="false"
    class="flex justify-start items-center flex-nowrap gap-fl-sm"
  >
    <checkbox
      :checked="item.checked"
      :on-clicked="item.onClicked"
      :muted="item.muted"
      :tooltip="`Toggle ${item.title}`"
    />
    <div
      :class="`flex-1 text-fl-base overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer select-none ${item.color}`"
      @click="item.onClicked"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, computed} from 'vue';
import Checkbox from '../../core/button/Checkbox.vue';
import {CheckboxListItem} from './types';

export default {
  components: {
    Checkbox
  },
  props: {
    items: {type: Array as PropType<ReadonlyArray<CheckboxListItem>>, required: true}
  },
  setup(props) {
    const itemsWithColor = computed(() => {
      return props.items.map(item => {
        return {
          ...item,
          color: item.muted ? 'text-mid hover:text-white' : 'text-light hover:text-white'
        };
      });
    });
    return {
      itemsWithColor
    };
  }
};
</script>
