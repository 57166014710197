<template>
  <page-content v-if="cancelPage !== undefined">
    <page-section :margin-top="'md'">
      <rich-text :width="'narrow'" :guest="''" :rich-text="cancelPage.richText" />
    </page-section>

    <page-section>
      <content-column>
        <div class="flex flex-col items-center">
          <div
            v-if="cancelPage.cancelPageCtaHeading !== null"
            class="text-fl-3xl text-lightest font-semibold"
          >
            {{ cancelPage.cancelPageCtaHeading }}
          </div>
          <div :id="'cancel-button'" class="pt-fl-md">
            <standard-button
              :size="'lg'"
              :disabled="error.error()"
              :on-click="onCancel"
              :color="'primary'"
              :label="cancelPage.cancelPageCtaCancelButtonLabel"
            />
          </div>
          <backend-error :error="error" />
          <div class="pt-fl-sm text-fl-sm text-bright">{{ cancelAtText }}</div>
          <div class="pt-fl-lg">
            <standard-button
              v-if="cancelPage.cancelPageCtaContinueButtonLabel !== null"
              :size="'lg'"
              :url="continueUrl"
              :color="'primary'"
              :label="cancelPage.cancelPageCtaContinueButtonLabel"
            />
          </div>
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import BackendError from '../../../../generic/backend-error/BackendError.vue';
import RichText from '../../../../generic/rich-text/RichText.vue';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons/faArrowRight';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {getAccessPassesForCurrentUser} from '../../../../../backend/access-pass/access-pass-query';
import {AccessPassStatus} from '../../../../../backend/access-pass/access-pass-types';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import {cancel} from '../../../../../backend/signup/checkout-query';
import {redirectWithHistory} from '../../../../../utils/url';
import {useInstrumentation} from '../../../../vue-composition/instrumentation/instrumentation';
import {getAllCancelPages} from '../../../../../backend/cancel-page/cancel-page-query';
import {CancelPage} from '../../../../../backend/cancel-page/cancel-page-types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardButton,
    RichText,
    BackendError
  },
  props: {
    accessPassId: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const error = useBackendError(true);
    const instrumentation = useInstrumentation();
    const accessPass = ref<Readonly<AccessPassStatus> | undefined>(undefined);
    const cancelPage = ref<Readonly<CancelPage> | undefined>(undefined);

    onMounted(async () => {
      // Load user's access passes.
      loader.setLoading(true, 'Loading subscription status');
      const userPasses = await getAccessPassesForCurrentUser();
      loader.setLoading(false);

      accessPass.value = userPasses.find(pass => pass.id === props.accessPassId);
      if (
        accessPass.value === undefined ||
        !accessPass.value.subscriptionId ||
        !accessPass.value.paymentProvider
      ) {
        error.setMessage('Customer does not own access pass.');
        return;
      }
      if (accessPass.value.status === 'inactive' || accessPass.value.status === 'cancelling') {
        error.setMessage('Cannot cancel an inactive or about-to-be-cancelled access pass.');
        return;
      }

      loader.setLoading(true, 'Loading subscription status');
      const cancelPages = await getAllCancelPages();
      loader.setLoading(false);

      cancelPage.value = cancelPages.find(page => {
        const pass = page.accessPasses.find(ap => {
          if (accessPass.value === undefined) {
            return false;
          }
          return ap.id === accessPass.value.id;
        });
        return pass !== undefined;
      });
    });

    const arrowIcon = computed(() => faArrowRight);

    const cancelAtText = computed(() => {
      let message = 'Your Membership will continue until the end of the current billing period.';
      if (accessPass.value !== undefined) {
        if (accessPass.value.status === 'trial' || accessPass.value.status === 'pastDue') {
          message = 'Your Membership will end immediately.';
        }
      }
      return message;
    });

    const continueUrl = computed(() => {
      if (cancelPage.value === undefined) {
        return '/';
      }
      if (cancelPage.value.cancelPageCtaContinueButtonUrl === null) {
        return '/';
      }
      return cancelPage.value.cancelPageCtaContinueButtonUrl;
    });

    const cancelSubscription = async () => {
      if (accessPass.value !== undefined) {
        loader.setLoading(
          true,
          'Cancelling your subscription. Please do not navigate away from this page.'
        );
        await cancel(accessPass.value.slug).catch(error.catcher);
        loader.setLoading(false);

        if (!error.error()) {
          loader.setLoading(true);
          await instrumentation.sendEvent({
            type: 'user',
            properties: {
              action: 'cancel',
              accessPass: accessPass.value.slug
            }
          });
          redirectWithHistory('/account?result=cancelSuccess');
        }
      }
    };

    const onCancel = async () => {
      await cancelSubscription();
    };

    return {
      error,
      cancelPage,
      cancelAtText,
      continueUrl,
      arrowIcon,
      onCancel
    };
  }
});
</script>
