<template>
  <div class="grid grid-cols-3">
    <div><!-- Intentionally empty --></div>
    <plan-price-cell v-for="offer in offers" :key="offer.id" :offer="offer" />

    <div class="px-fl-sm pt-fl-xl pb-fl-xs text-fl-lg text-primary font-medium">
      You're About to Unlock
    </div>
    <div v-for="(offer, i) in offers" :key="offer.id" :class="border[i]">
      <!-- Intentionally empty -->
    </div>

    <!-- Feature matrix -->
    <div
      v-for="(cell, i) in features"
      :key="`feature-${i}`"
      :class="cell.row % 2 === 0 ? 'bg-[#e9e9e9]' : 'bg-full-white'"
    >
      <feature-matrix-cell :cell="cell" />
    </div>
    <!-- End feature matrix -->

    <div><!-- Intentionally empty --></div>
    <plan-select-cell v-for="offer in offers" :key="offer.id" :offer="offer" :submit="submit" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {Feature} from '../utils';
import {AddOnOffer} from '../../../../../../../backend/offer/addon-offer-types';
import {SignupOffer} from '../../../../../../../backend/offer/signup-offer-types';
import PlanPriceCell from './PlanPriceCell.vue';
import PlanSelectCell from './PlanSelectCell.vue';
import FeatureMatrixCell from './FeatureMatrixCell.vue';

export default defineComponent({
  components: {
    PlanPriceCell,
    PlanSelectCell,
    FeatureMatrixCell
  },
  props: {
    offers: {
      type: Array as PropType<ReadonlyArray<SignupOffer> | ReadonlyArray<AddOnOffer>>,
      required: true
    },
    features: {
      type: Array as PropType<ReadonlyArray<Feature>>,
      required: true
    },
    onComplete: {
      type: Function as PropType<(card: Readonly<SignupOffer> | Readonly<AddOnOffer>) => void>,
      required: true
    }
  },
  setup(props) {
    const border = computed(() => {
      return props.offers.map(offer => {
        if (offer.offerHighlight) {
          return 'border-x border-x-primary';
        }
        return '';
      });
    });
    const borders = computed(() => {
      return props.features.map(f =>
        f.offer !== undefined && f.offer.offerHighlight ? 'border-x border-x-primary' : ''
      );
    });

    const submit = (id: string | undefined) => {
      const offer = props.offers.find(offer => offer.id === id);
      if (offer === undefined) {
        throw new Error('Unknown offer');
      }
      props.onComplete(offer);
    };

    return {
      border,
      borders,
      submit
    };
  }
});
</script>
