<template>
  <div class="flex justify-center">
    <div class="w-full max-w-[600px]">
      <div class="mb-fl-lg">
        <div class="mb-fl-2xs text-primary text-fl-5xl leading-[1.25] font-bold text-center">
          Enter Post-Trial Payment Info
        </div>
        <div class="mb-fl-md text-fl-lg text-[#67707f] leading-[1.4] font-normal text-center">
          Rest assured — your information is secure and you won’t be charged until after your free
          trial. Easily cancel any time you choose…
        </div>
        <div class="mb-fl-2xs flex justify-end">
          <div class="text-black text-fl-lg">
            End-to-end encrypted
            <span class="text-[#ffb53f]">
              <font-awesome-icon :icon="lockIcon"></font-awesome-icon>
            </span>
          </div>
        </div>
        <recaptcha-challenge
          :on-success="onRecaptchaSuccess"
          :on-expire="onRecaptchaExpired"
          :action="recaptchaAction"
        />
        <braintree-dropin
          v-if="customer !== undefined"
          :error="error"
          :customer="customer"
          :on-ready-status-change="onReadyStatusChange"
        />
        <div :id="'purchaseButton'" class="pt-fl-md pb-fl-lg flex justify-center">
          <rainbow-button
            :label="'Start Trial'"
            class="min-w-[clamp(170px,13.02083333vw,250px)]"
            :on-click="onPurchaseClicked"
            :disabled="disabled"
          />
        </div>
        <div class="flex justify-center">
          <backend-error :error="error" />
        </div>
        <div class="mt-fl-md text-black text-fl-xs text-center">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            class="underline hover:text-mid"
            target="_blank"
            >Privacy Policy</a
          >
          and
          <a
            href="https://policies.google.com/terms"
            class="underline hover:text-mid"
            target="_blank"
            >Terms of Service</a
          >
          apply.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from 'vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {BackendErrorComposition} from '../../../../vue-composition/backend-error/backend-error';
import RainbowButton from '../partials/RainbowButton.vue';
import BackendError from '../partials/BackendError.vue';
import RecaptchaChallenge from '../../../../generic/recaptcha-challenge/RecaptchaChallenge.vue';
import BraintreeDropin from '../../../../offer/payment/partials/BraintreeDropin.vue';
import {BraintreeCustomer, BraintreeNonce} from '../../../../offer/payment/types';
import {faLockKeyhole} from '@fortawesome/pro-solid-svg-icons/faLockKeyhole';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Dropin} from 'braintree-web-drop-in';
import {getCustomer, getNonce} from '../../../../offer/payment/utils';

export default defineComponent({
  components: {
    RecaptchaChallenge,
    BraintreeDropin,
    RainbowButton,
    BackendError,
    FontAwesomeIcon
  },
  props: {
    error: {type: Object as PropType<BackendErrorComposition>, required: true},
    email: {type: String, required: true},
    amount: {type: Number, required: true},
    purchaseLabel: {type: String, default: 'Purchase'},
    onPurchase: {
      type: Function as PropType<
        (nonce: Readonly<BraintreeNonce>, customer: Readonly<BraintreeCustomer>) => Promise<void>
      >,
      required: true
    },
    onCancel: {type: Function as PropType<() => void>, default: undefined},
    recaptchaAction: {type: String, required: true}
  },
  setup(props) {
    const lockIcon = computed(() => faLockKeyhole);
    const recaptchaToken = ref<any | undefined>(undefined);
    const customer = ref<BraintreeCustomer | undefined>(undefined);
    const dropin = ref<Dropin | undefined>(undefined);
    const dropinReady = ref(false);
    const loader = useFullScreenLoader();
    let hasInitiatedPurchase = false;
    const purchaseDisabled = ref(false);

    const onRecaptchaSuccess = async (token: any) => {
      recaptchaToken.value = token;

      if (customer.value === undefined) {
        props.error.clear();
        loader.setLoading(true, 'Please wait');
        customer.value = await getCustomer(props.email, token).catch(props.error.catcher);
        loader.setLoading(false);
      }
    };
    const onRecaptchaExpired = () => {
      recaptchaToken.value = undefined;
    };

    const onReadyStatusChange = (_dropin: Dropin, status: boolean) => {
      dropin.value = _dropin;
      dropinReady.value = status;
    };

    const disabled = computed(() => {
      if (props.error.error()) {
        return true;
      }
      if (dropin.value === undefined) {
        return true;
      }
      if (purchaseDisabled.value) {
        return true;
      }
      return !dropinReady.value || recaptchaToken.value === undefined;
    });

    const onPurchaseClicked = async () => {
      if (dropin.value === undefined) {
        throw new Error('Not initialized');
      }

      // Avoid making multiple purchases if this function is called more than once.
      if (hasInitiatedPurchase) {
        return;
      }
      hasInitiatedPurchase = true;
      purchaseDisabled.value = true;

      props.error.clear();
      loader.setLoading(
        true,
        'Validating your payment method. Please do not navigate away from this page.'
      );
      const nonce = await getNonce(dropin.value, props.email, props.amount).catch(
        props.error.catcher
      );
      loader.setLoading(false);
      if (nonce !== undefined && customer.value !== undefined) {
        await props.onPurchase(nonce, customer.value);
      } else {
        hasInitiatedPurchase = false;
        purchaseDisabled.value = false;
      }
    };

    return {
      lockIcon,
      disabled,
      onRecaptchaSuccess,
      onRecaptchaExpired,
      onPurchaseClicked,
      customer,
      onReadyStatusChange
    };
  }
});
</script>
