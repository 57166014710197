<template>
  <hr v-if="orientation === 'horizontal'" :class="`${dashed ? 'border-dashed' : ''}`" />
  <div v-else :class="`border-l ${dashed ? 'border-dashed' : ''} self-stretch`"></div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
export default defineComponent({
  props: {
    orientation: {type: String as PropType<'horizontal' | 'vertical'>, default: 'horizontal'},
    dashed: {type: Boolean, default: true}
  },
  setup() {}
});
</script>
