<template>
  <fitted-text
    :text="title"
    :viewbox-x="0.2"
    :viewbox-y="-6"
    :viewbox-width="10.1"
    :viewbox-height="6.2"
    :text-x="0"
    :text-y="0"
    :text-class="`text-[8px] fill-current stroke-[0.1] ${spotColor.stroke} font-semibold`"
    :svg-class="`${fillColor}`"
  />
</template>
<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {playersPathLevelSpotColor} from '../utils';
import FittedText from '../../../core/fitted-text/FittedText.vue';

export default defineComponent({
  components: {
    FittedText
  },
  props: {
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    completed: {type: Boolean, default: false}
  },
  setup(props) {
    const levelNumber = computed(() => {
      return parseInt(props.level.slug.substring(19));
    });

    const spotColor = computed(() => {
      return playersPathLevelSpotColor(levelNumber.value);
    });

    const title = computed(() => {
      return `0${levelNumber.value}`;
    });

    const fillColor = computed(() => {
      return props.completed ? `text-pp${levelNumber.value}` : 'text-transparent';
    });

    return {
      title,
      spotColor,
      fillColor
    };
  }
});
</script>
