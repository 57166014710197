<template>
  <div>
    <page-section :margin-top="'xl'">
      <page-section-header :width="'wide'" :heading="'Featured Mentors'">
        <link-button :size="'lg'" :url="'/tutors'" :label="'All Contributors'" />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'sm'">
      <tutor-reel :card-size="'2xs'" :tutors="tutors" />
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import TutorReel from '../../../content-pages/mentor-seminars/partials/TutorReel.vue';
import {Tutor} from '../../../../backend/tutor/tutor-types';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    LinkButton,
    TutorReel
  },
  props: {
    guest: {type: String, required: true},
    tutors: {type: Array as PropType<ReadonlyArray<Tutor>>, required: true}
  },
  setup() {}
});
</script>
