<template>
  <page-content v-if="tutor !== undefined">
    <page-section>
      <content-column>
        <cluster :gap="'gap-fl-xs'" :wrap="false" class="overflow-x-hidden">
          <a :href="'/tutors'" :class="`block text-mid hover:text-white`">
            <span>
              <font-awesome-icon :icon="icon" class="pr-fl-xs"></font-awesome-icon>
            </span>
            All Tutors
          </a>
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header>{{ tutor.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section v-if="tutorImage !== undefined">
      <content-column v-if="tutorImage.banner !== undefined">
        <imgix-image :config="tutorImage.banner" class="w-full rounded-md" />
      </content-column>
      <content-column v-else-if="tutorImage.avatar !== undefined">
        <div class="flex max-w-[300px]">
          <imgix-image :config="tutorImage.avatar" class="rounded-md" />
        </div>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="text-fl-3xl" v-html="tutor.tutorLongBiography"></p>
      </content-column>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-divider class="border-dark" />
      </content-column>
    </page-section>
    <page-section :margin-top="'2xs'" :class="'sticky left-0 top-[80px] self-start z-10'">
      <content-entry-collection-mode-bar :collection="collection" />
    </page-section>
    <page-section>
      <content-entry-section-list
        :guest="guest"
        :bookmark="bookmark"
        :sections="collection.searchSortFilterOutput.value"
        :layout="collection.layout.value"
        :display-post-date="false"
        :width="'wide'"
        :disabled="disabled"
        :card-image-width="cardImageWidth"
        :imgix-params="{ar: '16:9', fit: 'crop'}"
      />
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import PageContent from '../../core/page/PageContent.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardDivider from '../../core/standard-divider/StandardDivider.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import {Tutor} from '../../../backend/tutor/tutor-types';
import {getTutor} from '../../../backend/tutor/tutor-query';
import ImgixImage from '../../core/imgix-image/ImgixImage.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import ContentEntryCollectionModeBar from '../../content-entry/collections/utilities/ContentEntryCollectionModeBar.vue';
import ContentEntrySectionList from '../../content-entry/collections/variants/ContentEntrySectionList.vue';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useCollection} from '../../content-entry/collections/utilities/collection/collection';
import {faArrowLeft} from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {getSearchSections} from '../../../backend/search/search-query';
import {AccessPassStatus} from '../../../backend/access-pass/access-pass-types';
import {getAccessPassesForCurrentUser} from '../../../backend/access-pass/access-pass-query';
import {ImgixImageConfig, ImgixImageResponsiveWidth} from '../../core/imgix-image/types';
import {findImage} from '../../../backend/image-collection/image-collection-utils';
import {ContentEntrySortScheme} from '../../vue-composition/sort/types';
import {usePaginatedContentEntryFetchBySection} from '../../vue-composition/paginated-fetch/paginated-content-entry-fetch';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    Cluster,
    StandardDivider,
    ContentEntryCollectionModeBar,
    ImgixImage,
    FontAwesomeIcon,
    ContentEntrySectionList
  },
  props: {
    id: {type: String, required: true},
    guest: {type: String, required: true}
  },
  setup(props) {
    const tutor = ref(undefined as Tutor | undefined);
    const loader = useFullScreenLoader();

    const bookmark = useBookmark(props.guest);
    const accessPasses = ref<ReadonlyArray<AccessPassStatus> | undefined>(undefined);

    const paginatedFetch = usePaginatedContentEntryFetchBySection();
    const sortSchemes: ReadonlyArray<ContentEntrySortScheme> = ['default', 'newest', 'oldest'];
    const collection = useCollection(
      paginatedFetch.sections,
      undefined,
      accessPasses,
      sortSchemes,
      `tutor-${props.id}`
    );
    const disabled = computed(() => paginatedFetch.sections.value.length === 0);

    onMounted(async () => {
      loader.setLoading(true);
      tutor.value = await getTutor(props.id);
      accessPasses.value = await getAccessPassesForCurrentUser();
      await collection.init();

      if (tutor.value !== undefined) {
        const searchSections = await getSearchSections();
        paginatedFetch.start(
          searchSections,
          contentEntries()
            .section(searchSections.map(s => s.handle))
            .relatedTo(tutor.value.id)
        );
      }

      loader.setLoading(false);
    });

    const icon = computed(() => faArrowLeft);

    const tutorImage = computed(() => {
      if (tutor.value === undefined) {
        return undefined;
      }
      const avatar = findImage(tutor.value.imageCollection, ['avatar']);
      const banner = findImage(tutor.value.imageCollection, ['banner']);
      if (banner !== undefined) {
        const cfg: Readonly<ImgixImageConfig> = {
          path: banner.imageFilename,
          alt: tutor.value.title,
          width: {
            narrow: 520,
            sm: 700,
            md: 900,
            lg: 1000,
            xl: 1150,
            '2xl': 1000
          }
        };
        return {
          banner: cfg
        };
      }
      if (avatar !== undefined) {
        const cfg: Readonly<ImgixImageConfig> = {
          path: avatar.imageFilename,
          alt: tutor.value.title,
          width: 300
        };
        return {
          avatar: cfg
        };
      }
      return undefined;
    });
    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 300,
      sm: 340,
      md: 350,
      lg: 300,
      xl: 330,
      '2xl': 400
    };

    return {
      tutor,
      bookmark,
      collection,
      icon,
      disabled,
      tutorImage,
      cardImageWidth
    };
  }
});
</script>
