import {AccessPassStatus} from '../access-pass/access-pass-types';
import {postAction} from '../craft-action';
import {CraftId} from '../craft/craft-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {UpgradeOffer} from './upgrade-offer-types';
import {ACCESS_PASS_FIELDS, ACCESS_PASS_STATUS_SCHEMA} from '../access-pass/access-pass-query';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {OFFER_CONDITIONS_FIELD} from './offer-conditions-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const UPGRADE_OFFER_FIELDS = [
  QueryBuilder.plainText('offerTitle').required(),
  QueryBuilder.lightswitch('offerIsActive').required(),
  QueryBuilder.categories('offerSource').required().fields(ACCESS_PASS_FIELDS),
  QueryBuilder.categories('offerDestination').required().fields(ACCESS_PASS_FIELDS),
  QueryBuilder.categories('offerBonuses').fields(ACCESS_PASS_FIELDS),
  QueryBuilder.lightswitch('offerDeleteSourcePass').required(),
  QueryBuilder.lightswitch('offerEndOldSubscriptionImmediately').required(),
  QueryBuilder.lightswitch('offerStartNewSubscriptionImmediately').required(),
  QueryBuilder.plainText('offerFeatures'),
  QueryBuilder.lightswitch('offerHighlight'),
  QueryBuilder.plainText('summary'),
  QueryBuilder.plainText('offerSelectionLabel'),
  OFFER_CONDITIONS_FIELD,
  IMAGE_COLLECTION_FIELD
];

export async function getAllUpgradeOffers(): Promise<ReadonlyArray<UpgradeOffer>> {
  const result = await fetchEntries<UpgradeOffer>(
    QueryBuilder.entries().section(['upgradeOffers']).fields(UPGRADE_OFFER_FIELDS)
  );
  return result;
}

export async function getUpgradeOfferById(
  id: CraftId
): Promise<Readonly<UpgradeOffer> | undefined> {
  const result = await fetchEntries<UpgradeOffer>(
    QueryBuilder.entries().section(['upgradeOffers']).id([id]).fields(UPGRADE_OFFER_FIELDS)
  );
  if (result.length !== 1) {
    return undefined;
  }
  return result[0];
}

/**
 * If the specified access pass is an upgrade-in-progress (for the
 * specified user), return the access pass that was upgraded from.
 *
 * More specifically, if the specified pass is a pending subscription,
 * find all upgrade offers where the specified pass is the destination.
 * Amongst those offers, find the first offer where the source pass
 * is a cancelling subscription (for the current user), and return it.
 * If no such source pass can be found, return null.
 */
export async function getUpgradeInProgressSourceForCurrentUser(
  accessPassSlug: string
): Promise<AccessPassStatus | null> {
  const url = `/actions/sbl-module/checkout/get-upgrade-in-progress-source-for-current-user`;
  const body = {
    accessPass: accessPassSlug
  };
  const result = await postAction<AccessPassStatus | null>(url, body, ACCESS_PASS_STATUS_SCHEMA);
  return result;
}
