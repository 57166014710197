<template>
  <page-content v-if="lesson !== undefined && trails !== undefined && lessonVideo !== undefined">
    <hr class="w-full border-dark" />

    <page-section :margin-top="'2xs'">
      <content-column :width="'wide'">
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section v-if="mediaBrowserItems.length > 0" :margin-top="'2xs'">
      <media-browser
        :guest="guest"
        :content-entry="lesson"
        :video="lessonVideo"
        :override-playback-pos="overridePlaybackPos"
        :items="mediaBrowserItems"
        :sidebar-title="'Lessons'"
        :sidebar-icon-type="'check'"
        :on-item-selected="onMediaBrowserItemSelected"
        :on-progress="onProgress"
      />
    </page-section>

    <variants>
      <template #lg+>
        <lesson-title-bar-large
          :guest="guest"
          :lesson="lesson"
          :trails="trails"
          :bookmark="bookmark"
          :progress="progress"
          :previous-url="prevUrl"
          :next-url="nextUrl"
        />
      </template>
      <template #md>
        <lesson-title-bar-medium
          :guest="guest"
          :lesson="lesson"
          :trails="trails"
          :bookmark="bookmark"
          :progress="progress"
          :previous-url="prevUrl"
          :next-url="nextUrl"
        />
      </template>
      <template #sm->
        <lesson-title-bar-small
          :guest="guest"
          :lesson="lesson"
          :trails="trails"
          :bookmark="bookmark"
          :progress="progress"
          :previous-url="prevUrl"
          :next-url="nextUrl"
        />
      </template>
    </variants>

    <variants v-if="mediaBrowserItems.length > 0">
      <template #lg->
        <page-section :margin-top="'xl'">
          <media-browser-navigator
            :items="mediaBrowserItems"
            :on-item-selected="onMediaBrowserItemSelected"
            :title="'Videos'"
            :sidebar-icon-type="'check'"
            :contract="true"
          />
        </page-section>
      </template>
    </variants>

    <page-section v-if="songs !== undefined && songs.length >= 1" :margin-top="'lg'">
      <content-entry-practice-tools
        id="practiceTools"
        class="scroll-my-20"
        :guest="guest"
        :preview="lesson.preview"
        :songs="songs"
      >
        <variants>
          <template #lg+>
            <standard-heading id="resources" :size="3" class="scroll-my-20"
              >Downloads</standard-heading
            >
            <content-entry-resource-list-standalone
              class="mt-fl-md-lg"
              :guest="guest"
              :content-entry="lesson"
            >
            </content-entry-resource-list-standalone>
          </template>
        </variants>
      </content-entry-practice-tools>
    </page-section>

    <content-entry-summary :content-entry="lesson" :margin-top="'xl'" />

    <content-entry-resource-list
      v-if="songs === undefined || songs.length === 0"
      :guest="guest"
      :content-entry="lesson"
      :margin-top="'xl'"
    />
    <variants v-else>
      <template #md->
        <content-entry-resource-list :guest="guest" :content-entry="lesson" :margin-top="'xl'" />
      </template>
    </variants>
  </page-content>
</template>

<script lang="ts">
import {ref, onMounted, computed, defineComponent} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import Variants from '../../core/column-layout/Variants.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryPracticeTools from '../../content-entry/partials/body/ContentEntryPracticeTools.vue';
import ContentEntrySummary from '../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryResourceList from '../../content-entry/partials/body/ContentEntryResourceList.vue';
import ContentEntryResourceListStandalone from '../../content-entry/partials/body/ContentEntryResourceListStandalone.vue';
import MediaBrowser from '../../generic/media-browser/MediaBrowser.vue';
import MediaBrowserNavigator from '../../generic/media-browser/navigator/MediaBrowserNavigator.vue';
import LessonTitleBarLarge from './partials/LessonTitleBarLarge.vue';
import LessonTitleBarMedium from './partials/LessonTitleBarMedium.vue';
import LessonTitleBarSmall from './partials/LessonTitleBarSmall.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import {Lesson} from '../../../backend/content/lesson/lesson-types';
import {getLessonBySlug, LESSON_FIELDS} from '../../../backend/content/lesson/lesson-query';
import {getLessonLengthSec} from '../../../backend/content/lesson/lesson-utils';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useProgress} from '../../vue-composition/progress/progress';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {getFirstVideoOfType} from '../../../backend/video/video-query';
import {lessonsToMediaBrowserItems} from './utils';
import {CraftId} from '../../../backend/craft/craft-types';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {getUrlQueryParams, redirectWithHistory} from '../../../utils/url';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {durationToHMS, HMSToSeconds} from '../../../utils/duration';

function getOverridePlaybackPos() {
  const ts = getUrlQueryParams().get('ts');
  if (ts === null) {
    return undefined;
  }
  const hms = durationToHMS(ts);
  if (hms === undefined) {
    return undefined;
  }
  const sec = HMSToSeconds(hms);
  return sec;
}

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    Variants,
    ContentColumn,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryPracticeTools,
    ContentEntrySummary,
    ContentEntryResourceList,
    ContentEntryResourceListStandalone,
    MediaBrowser,
    MediaBrowserNavigator,
    LessonTitleBarLarge,
    LessonTitleBarMedium,
    LessonTitleBarSmall,
    StandardHeading
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const deviceType = useDeviceType();

    const trails = ref<Readonly<BreadcrumbTrailComposition<Lesson>> | undefined>(undefined);
    const siblings = computed(() => {
      if (trails.value !== undefined) {
        if (trails.value.siblings !== undefined) {
          return trails.value.siblings;
        }
      }
      return undefined;
    });

    const progress = useProgress(props.guest, {siblings});

    const bookmark = useBookmark(props.guest);

    const lesson = ref(undefined as Lesson | undefined);
    const lessonDuration = computed(() => {
      if (lesson.value === undefined) {
        return undefined;
      }
      return getLessonLengthSec(lesson.value);
    });
    const lessonVideo = computed(() => {
      if (lesson.value !== undefined) {
        return getFirstVideoOfType(lesson.value.videos, 'content');
      }
      return undefined;
    });

    const songs = computed(() => {
      if (lesson.value === undefined) {
        return undefined;
      }
      return lesson.value.songs;
    });

    const onMediaBrowserItemSelected = (id: CraftId) => {
      if (siblings.value !== undefined) {
        const sibling = siblings.value.find(sibling => sibling.id === id);
        if (sibling !== undefined) {
          redirectWithHistory(sibling.url);
        }
      }
    };

    /*
      ### It's awkward that we cannot pass a list of content entries to
      the media player component to display as items. The reason is that in
      Practice Pieces (for Players Path), we need to display the piece videos,
      and they are not entries (they are in the video collection of the Piece).
      Also, on the lesson page, some media player items represent course module
      headers.

      Also, we have to explicitly say which of the items that is the higlighted
      one. The reason is, again, the Practice Piece page (where the content entry
      is the piece, and the *videos* are the media browser items).
    */
    const mediaBrowserItems = computed(() => {
      if (siblings.value !== undefined) {
        return lessonsToMediaBrowserItems(siblings.value, lesson.value, progress);
      }
      return [];
    });

    const nextUrl = computed(() => {
      if (siblings.value === undefined) {
        return undefined;
      }
      const i = siblings.value.findIndex(sibling => {
        if (lesson.value === undefined) {
          return false;
        }
        return sibling.id === lesson.value.id;
      });
      if (siblings.value[i + 1] === undefined) {
        return undefined;
      }
      return siblings.value[i + 1].url;
    });
    const prevUrl = computed(() => {
      if (siblings.value === undefined) {
        return undefined;
      }
      const i = siblings.value.findIndex(sibling => {
        if (lesson.value === undefined) {
          return false;
        }
        return sibling.id === lesson.value.id;
      });
      if (siblings.value[i - 1] === undefined) {
        return undefined;
      }
      return siblings.value[i - 1].url;
    });

    const endCallback = async () => {
      if (lesson.value !== undefined && !lesson.value.preview && !props.guest) {
        loader.setLoading(true);
        await progress.setProgress('completed');
        loader.setLoading(false);
      }
    };

    const overridePlaybackPos = ref<number | undefined>(undefined);

    onMounted(async () => {
      overridePlaybackPos.value = getOverridePlaybackPos();

      loader.setLoading(true);
      lesson.value = await getLessonBySlug(props.slug);
      if (lesson.value !== undefined) {
        trails.value = await useBreadcrumbTrails<Lesson>(
          lesson.value,
          props.entryBreadcrumbTrails,
          LESSON_FIELDS
        );
        await progress.init(lesson.value);
        await bookmark.init();
      }
      loader.setLoading(false);
    });

    const onProgress = async (playpos: number, duration: number) => {
      await progress.setProgressRatio(playpos / duration);
    };

    return {
      overridePlaybackPos,
      lesson,
      lessonDuration,
      lessonVideo,
      songs,
      deviceType,
      mediaBrowserItems,
      onMediaBrowserItemSelected,
      progress,
      bookmark,
      trails,
      siblings,
      endCallback,
      nextUrl,
      prevUrl,
      onProgress
    };
  }
});
</script>

<style></style>
