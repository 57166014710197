<template>
  <div v-for="(block, index) in richText" :key="index">
    <rich-text-heading
      v-if="block.typeHandle === 'richTextHeading'"
      :rich-text-heading="block"
      :width="width"
      :justify="justify"
    />
    <rich-text-body
      v-if="block.typeHandle === 'richTextBody'"
      :rich-text-body="block"
      :body-text-size="bodyTextSize"
      :body-text-color="bodyTextColor"
      :body-link-primary="bodyLinkPrimary"
      :width="width"
      :justify="justify"
    />
    <rich-text-video
      v-if="block.typeHandle === 'richTextVideo'"
      :rich-text-video="block"
      :width="width"
      :justify="justify"
    />
    <rich-text-quote
      v-if="block.typeHandle === 'richTextQuote'"
      :rich-text-quote="block"
      :width="width"
      :justify="justify"
    />
    <rich-text-list
      v-if="block.typeHandle === 'richTextList'"
      :rich-text-list="block"
      :list-text-size="listTextSize"
      :list-text-color="listTextColor"
      :list-icon="listIcon"
      :body-link-primary="bodyLinkPrimary"
      :width="width"
      :justify="justify"
    />
    <rich-text-image
      v-if="block.typeHandle === 'richTextImage'"
      :rich-text-image="block"
      :width="width"
      :justify="justify"
    />
    <rich-text-entries
      v-if="block.typeHandle === 'richTextEntries'"
      :rich-text-entries="block"
      :guest="guest"
      :width="width"
      :justify="justify"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import RichTextHeading from './blocks/RichTextHeading.vue';
import RichTextBody from './blocks/RichTextBody.vue';
import RichTextVideo from './blocks/RichTextVideo.vue';
import RichTextQuote from './blocks/RichTextQuote.vue';
import RichTextList from './blocks/RichTextList.vue';
import RichTextImage from './blocks/RichTextImage.vue';
import RichTextEntries from './blocks/RichTextEntries.vue';
import {RichTextBlock} from '../../../backend/rich-text/rich-text-types';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../core/column-layout/utils';

export default defineComponent({
  components: {
    RichTextHeading,
    RichTextBody,
    RichTextVideo,
    RichTextQuote,
    RichTextList,
    RichTextImage,
    RichTextEntries
  },
  props: {
    guest: {type: String, required: true},
    richText: {type: Array as PropType<ReadonlyArray<RichTextBlock>>, required: true},
    bodyTextSize: {type: String, default: undefined},
    bodyTextColor: {type: String, default: undefined},
    bodyLinkPrimary: {type: Boolean, default: false},
    listTextSize: {type: String, default: undefined},
    listTextColor: {type: String, default: undefined},
    listIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  }
});
</script>
