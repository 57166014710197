<template>
  <a :id="level.slug" :href="level.url">
    <flow :gap="6">
      <repel v-if="deviceType.largerThan('sm')">
        <standard-heading :size="3" :spot-color="spotColor">Level</standard-heading>
        <div :class="`text-fl-3xl ${spotColor.text}`">
          <font-awesome-icon
            v-if="level.completed"
            :icon="checkIcon"
            aria-label="completed"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="!level.unlocked"
            :icon="lockIcon"
            aria-label="locked"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="level.unlocked && !level.completed"
            :icon="unlockIcon"
            aria-label="locked"
          ></font-awesome-icon>
        </div>
      </repel>

      <!-- Level number ('01', '02', etc.) -->
      <players-path-level-number
        :level="level"
        :style="levelNumberSize"
        :completed="levelComplete"
      />

      <!-- Progress bar -->
      <players-path-level-progress-bar :level="level" />

      <div v-if="displaySummary && deviceType.largerThan('sm')" class="text-fl-base text-mid">
        {{ level.summary }}
      </div>

      <div v-if="displayProgressLevel && deviceType.largerThan('sm')" class="text-fl-base text-mid">
        {{ progressLabel }}
      </div>
    </flow>
  </a>
</template>
<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faCheck} from '@fortawesome/pro-light-svg-icons/faCheck';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import {faUnlock} from '@fortawesome/pro-light-svg-icons/faUnlock';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import Repel from '../../../core/compositions/Repel.vue';
import Flow from '../../../core/compositions/Flow.vue';
import {playersPathLevelSpotColor} from '../utils';
import PlayersPathLevelNumber from './PlayersPathLevelNumber.vue';
import PlayersPathLevelProgressBar from './PlayersPathLevelProgressBar.vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {lerp} from '../../../../utils/math';

export default defineComponent({
  components: {
    StandardHeading,
    Repel,
    Flow,
    FontAwesomeIcon,
    PlayersPathLevelNumber,
    PlayersPathLevelProgressBar
  },
  props: {
    level: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true},
    displaySummary: {type: Boolean, default: true},
    displayProgressLevel: {type: Boolean, default: false}
  },
  setup(props) {
    const levelNumber = computed(() => {
      return parseInt(props.level.slug.substring(19));
    });

    const spotColor = computed(() => {
      return playersPathLevelSpotColor(levelNumber.value);
    });

    const levelHeadingClass = computed(() => {
      return `font-semibold ${spotColor.value.text}`;
    });

    const progressWidth = computed(() => {
      const progress = 100 * (props.level.numberOfCompletedPieces / 3);
      return `width: ${progress}%`;
    });

    const checkIcon = computed(() => faCheck);
    const lockIcon = computed(() => faLock);
    const unlockIcon = computed(() => faUnlock);

    const progressLabel = computed(() => {
      const n = props.level.numberOfCompletedPieces;
      if (n > 0) {
        return `${n} of 3 Study Pieces Complete`;
      }
      return '';
    });

    const deviceType = useDeviceType();
    const levelNumberSize = computed(() => {
      if (deviceType.screen.value === 'narrow') {
        const size = lerp(50, 110, deviceType.interpolations.narrow.value);
        return `font-size:${size}px;`;
      }
      const size = lerp(110, 205, deviceType.interpolation.value);
      return `font-size:${size}px;`;
    });

    const levelComplete = computed(() => {
      return props.level.completed === true ? true : false;
    });

    return {
      deviceType,
      levelHeadingClass,
      spotColor,
      progressWidth,
      checkIcon,
      lockIcon,
      unlockIcon,
      progressLabel,
      levelNumberSize,
      levelComplete
    };
  }
});
</script>
