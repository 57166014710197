<template>
  <div>
    <content-column :width="'wide'">
      <hr class="border-dark" />
    </content-column>

    <!-- Group title + group-related buttons -->
    <content-column :width="'wide'">
      <repel>
        <standard-heading v-if="title !== undefined" :class="'mt-fl-xl'" :size="3">{{
          title
        }}</standard-heading>
        <cluster :class="'mt-fl-xl'" :gap="'gap-x-fl-lg gap-y-fl-md'">
          <link-icon-button
            v-if="group.bookmark?.title !== 'Default' && !readOnly"
            :label="'Share Group'"
            :icon="iconFolderShare"
            :border="true"
            :on-click="onShare"
          />
          <link-icon-button
            v-if="group.bookmark?.title !== 'Default' && !readOnly"
            :label="'Rename Group'"
            :icon="iconFolderGear"
            :border="true"
            :on-click="onRenameGroup"
          />
          <link-icon-button
            v-if="group.bookmark?.title !== 'Default' && !readOnly"
            :label="'Delete Group'"
            :icon="iconFolderXmark"
            :border="true"
            :on-click="onDeleteGroup"
          />
        </cluster>
      </repel>
    </content-column>

    <!-- Bookmark items (in group) -->
    <div :class="'mt-fl-xl'">
      <div v-for="(child, index) in group.children" :key="index">
        <content-column :width="'wide'">
          <standard-divider v-if="index === 0" class="border-dark" />
        </content-column>
        <bookmark-card
          :guest="guest"
          :read-only="readOnly"
          :group="group"
          :groups="groups"
          :bookmark-node="child"
          :first="index === 0"
          :last="index === group.children.length - 1"
          :reload="reload"
          :on-move="onMoveItem"
          :card-image-width="cardImageWidth"
          class="py-fl-xl"
        />

        <content-column :width="'wide'">
          <standard-divider class="border-dark" />
        </content-column>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import {BookmarkNode} from '../types';
import BookmarkCard from '../../../content-entry/representations/bookmark-card/BookmarkCard.vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import {deleteBookmarkGroup, renameBookmarkGroup, moveBookmarkItem, shareBookmark} from '../utils';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {
  standardNotification,
  useNotificationPopup
} from '../../../vue-composition/notification-popup/notification-popup';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {faShare} from '@fortawesome/pro-solid-svg-icons/faShare';
import {faPen} from '@fortawesome/pro-solid-svg-icons/faPen';
import {faTrashCan} from '@fortawesome/pro-solid-svg-icons/faTrashCan';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default {
  components: {
    ContentColumn,
    Cluster,
    Repel,
    StandardHeading,
    StandardDivider,
    BookmarkCard,
    LinkIconButton
  },
  props: {
    guest: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
    group: {type: Object as PropType<Readonly<BookmarkNode>>, required: true},
    groups: {type: Array as PropType<ReadonlyArray<BookmarkNode>>, required: true},
    reload: {
      type: Function as PropType<(selectDefaultPanel?: boolean) => Promise<void>>,
      required: true
    },
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const title = computed(() => {
      return props.group.bookmark ? props.group.bookmark.title : 'Default';
    });
    const onDeleteGroup = async () => {
      if (confirm(`Deleting group "${props.group.title}"\nAre you sure?`)) {
        loader.setLoading(true, 'Updating your bookmarks');
        await deleteBookmarkGroup(props.group);
        await props.reload(true);
        loader.setLoading(false);
      }
    };
    const onRenameGroup = async () => {
      const name = prompt('Please enter a new name for the bookmark group!', props.group.title);
      if (name !== null) {
        loader.setLoading(true, 'Updating your bookmarks');
        await renameBookmarkGroup(props.group, name);
        await props.reload();
        loader.setLoading(false);
      }
    };
    const executeMove = async (item: Readonly<BookmarkNode>, group: Readonly<BookmarkNode>) => {
      loader.setLoading(true, 'Updating your bookmarks');
      await moveBookmarkItem(item, group);
      await props.reload();
      loader.setLoading(false);
    };

    const onShare = async () => {
      const bookmark = props.group;
      if (bookmark !== undefined && bookmark !== null) {
        const success = await shareBookmark(bookmark);
        if (success) {
          notification.notify(standardNotification('Link copied to clipboard.'));
        }
      }
    };

    const onMoveItem = (item: Readonly<BookmarkNode>) => {
      props.bookmark.showMoveBookmarkDialog(item, props.group);
    };

    const iconFolderShare = computed(() => faShare);
    const iconFolderGear = computed(() => faPen);
    const iconFolderXmark = computed(() => faTrashCan);

    return {
      iconFolderShare,
      iconFolderGear,
      iconFolderXmark,
      notification,
      title,
      onDeleteGroup,
      onRenameGroup,
      onMoveItem,
      executeMove,
      onShare
    };
  }
};
</script>
