import {compressToUTF16, decompressFromUTF16} from 'lz-string';

/**
 * Compress the specified string and write it to local storage
 * under the specified key.
 *
 * @param key The local storage key where to store the string.
 * @param value The string to store.
 */
export function setItem(key: string, value: string) {
  const compressed = compressToUTF16(value);
  window.localStorage.setItem(key, compressed);
}

/**
 * Retrieve and deompress the string stored under the specified
 * key in local storage.
 *
 * @param key The local storage key where the string is stored.
 * @return The decompressed string.
 */
export function getItem(key: string): string | null {
  const value = window.localStorage.getItem(key);
  if (value !== null) {
    const decompressed = decompressFromUTF16(value);
    if (decompressed !== null) {
      return decompressed;
    }
  }
  return null;
}

/**
 * Compress the specified object and write it to local storage
 * under the specified key.
 *
 * @param key The local storage key where to store the object.
 * @param object The object to store.
 */
export function setObject<T>(key: string, object: Readonly<T>) {
  const value = JSON.stringify(object);
  setItem(key, value);
}

/**
 * Retrieve and deompress the object stored under the specified
 * key in local storage.
 *
 * @param key The local storage key where the object is stored.
 * @return The decompressed object.
 */
export function getObject<T>(key: string): T | undefined {
  const value = getItem(key);
  if (value !== null) {
    try {
      const object = JSON.parse(value) as T;
      return object;
    } catch {
      return undefined;
    }
  }
  return undefined;
}

/**
 * Shorthand for `window.localStorage.removeItem(key)`.
 */
export function removeObject(key: string) {
  window.localStorage.removeItem(key);
}
