<template>
  <page-content>
    <!-- Orientation / Welcome -->
    <dashboard-orientation :on-hide="onHideWelcome" :user-friendly-name="userFriendlyName" />

    <!-- Beginner Journey -->
    <beginner-journey-card :guest="guest" :margin-top="'lg'" />

    <!-- Promotions -->
    <promo-box :location="'dashboard'" :promotions="promotions" :margin-top="'lg'" />

    <!-- New courses (posters) -->
    <new-courses-reel :card-size="'2xs'" />

    <!-- New member huddle -->
    <dashboard-new-member-huddle v-if="showHuddle" :on-hide="onHideHuddle" />

    <!-- Section promotions -->
    <dashboard-section-promos
      v-if="showQuickGuide"
      :guest="guest"
      :cards="sectionPromoCards"
      :on-hide="onHideQuickGuide"
    />

    <!-- What do you want to learn today? -->
    <dashboard-curation v-if="curation.length > 0" :curation="curation" />

    <!-- Recently released entries -->
    <dashboard-reel
      :margin-top="'xl'"
      :guest="guest"
      :heading="'Recently Released'"
      :content-entries="recentlyReleased"
      :bookmark="bookmark"
    />

    <!-- Popular courses -->
    <dashboard-reel
      v-if="popularCourses.length > 0"
      :margin-top="'xl'"
      :guest="guest"
      :heading="'Popular Courses'"
      :content-entries="popularCourses"
      :bookmark="bookmark"
    />

    <!-- Mentor seminars -->
    <dashboard-mentor-seminars
      :guest="guest"
      :bookmark="bookmark"
      :most-recent-mentor-seminar="mostRecentMentorSeminar"
      :upcoming-mentor-seminars="upcomingMentorSeminars"
      :margin-top="'xl'"
    />

    <!-- Student focus -->
    <dashboard-student-focus
      :guest="guest"
      :bookmark="bookmark"
      :most-recent-student-focus="mostRecentStudentFocus"
    />

    <!-- Tutors -->
    <dashboard-tutors :guest="guest" :tutors="tutors" />
  </page-content>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';
import PageContent from '../../../core/page/PageContent.vue';
import PromoBox from '../../../promotions/components/promo-box/PromoBox.vue';
import BeginnerJourneyCard from '../../../content-pages/beginner-journey/partials/BeginnerJourneyCard.vue';
import DashboardNewMemberHuddle from '../partials/DashboardNewMemberHuddle.vue';
import DashboardSectionPromos from '../partials/DashboardSectionPromos.vue';
import DashboardReel from '../partials/DashboardReel.vue';
import DashboardMentorSeminars from '../partials/DashboardMentorSeminars.vue';
import DashboardStudentFocus from '../partials/DashboardStudentFocus.vue';
import DashboardTutors from '../partials/DashboardTutors.vue';
import DashboardCuration from '../partials/DashboardCuration.vue';
import DashboardOrientation from '../partials/DashboardOrientation.vue';
import NewCoursesReel from '../../../content-pages/courses/partials/NewCoursesReel.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {Curation} from '../../../../backend/curation/curation-types';
import {SectionPromoConfig} from '../partials/types';
import {Tutor} from '../../../../backend/tutor/tutor-types';
import {ButtonClickCallback} from '../../../core/button/implementation/utils';
import {PromotionComposition} from '../../../vue-composition/promotion/promotion';

export default defineComponent({
  components: {
    PageContent,
    PromoBox,
    DashboardReel,
    DashboardSectionPromos,
    DashboardMentorSeminars,
    DashboardStudentFocus,
    DashboardTutors,
    DashboardCuration,
    DashboardOrientation,
    DashboardNewMemberHuddle,
    BeginnerJourneyCard,
    NewCoursesReel
  },
  props: {
    guest: {type: String, required: true},
    userFriendlyName: {type: String, required: true},
    onHideWelcome: {type: Function as PropType<ButtonClickCallback>, required: true},
    showHuddle: {type: Boolean, required: true},
    onHideHuddle: {type: Function as PropType<ButtonClickCallback>, required: true},
    showQuickGuide: {type: Boolean, required: true},
    onHideQuickGuide: {type: Function as PropType<ButtonClickCallback>, required: true},
    activeWebcasts: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    playersPathContinueLevel: {
      type: Object as PropType<Readonly<PlayersPathLevel>>,
      default: undefined
    },
    recentlyReleased: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    popularCourses: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    mostRecentMentorSeminar: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    upcomingMentorSeminars: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    mostRecentStudentFocus: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    curation: {type: Array as PropType<ReadonlyArray<Curation>>, required: true},
    sectionPromoCards: {type: Array as PropType<ReadonlyArray<SectionPromoConfig>>, required: true},
    tutors: {type: Array as PropType<ReadonlyArray<Tutor>>, required: true},
    promotions: {type: Object as PropType<Readonly<PromotionComposition>>, required: true}
  },
  setup() {}
});
</script>
