<template>
  <div>
    <page-section :margin-top="'xl'">
      <content-column :width="'normal'">
        <standard-heading :size="2" :center="true"
          >What Do You Want To Learn Today?</standard-heading
        >
      </content-column>
    </page-section>
    <page-section :margin-top="'md'">
      <tabbed-panel
        :tabs="tabs"
        :button-size="'xl'"
        :justify="'justify-center'"
        :items="'items-center'"
      >
        <template v-for="(section, index) in curation" :key="index" #[`${index}`]>
          <content-column :width="'normal'" class="mt-fl-md-xl">
            <div class="flex flex-wrap justify-center gap-fl-xs">
              <a
                v-for="(topic, topicIndex) in section.curationSection"
                :key="topic.curationTopicTitle"
                :href="`${section.url}?topic=${topicIndex}`"
                class="py-fl-2xs px-fl-md text-fl-sm whitespace-nowrap text-center text-light hover:text-white border-light border rounded-full hover:border-white"
              >
                {{ topic.curationTopicTitle }}
              </a>
            </div>
          </content-column>
        </template>
      </tabbed-panel>
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {Curation} from '../../../../backend/curation/curation-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import TabbedPanel from '../../../generic/tabbed-panel/TabbedPanel.vue';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    TabbedPanel
  },
  props: {
    curation: {type: Array as PropType<ReadonlyArray<Curation>>, required: true}
  },
  setup(props) {
    const tabs = computed(() => {
      return props.curation.map(curation => curation.title);
    });
    return {
      tabs
    };
  }
});
</script>
