import {Ref, computed} from 'vue';
import {getObject, removeObject, setObject} from '../../../../../../utils/compressed-local-storage';
import {ContentEntry} from '../../../../../../backend/content/content-entry/content-entry-types';
import {ProductSectionMap} from '../../../../../../backend/product/product-query';
import {ProgressMap} from '../../../../../../backend/progress/progress-types';
import {FilterProperty} from '../../../../../../utils/filter/types';
import {stringComparator} from '../../../../../../utils/sort/comparators';
import {ContentEntrySection} from '../../../../../../backend/content/content-entry/content-entry-utils';
import {useFilterSet} from '../../../../../vue-composition/filter-set/filter-set';
import {AccessPassStatus} from '../../../../../../backend/access-pass/access-pass-types';
import {
  FilterSetComposition,
  FilterSetConfig,
  FilterSetSelection,
  OnFilterSetSelectionChangeCallback
} from '../../../../../vue-composition/filter-set/types';
import {
  deleteUrlQueryParam,
  getQueryParam,
  updateUrlQueryParam
} from '../../../../../../utils/compressed-query-param';

/**
 * @returns Filter config for content entry levels.
 */
function getLevelsFilterConfig(): Readonly<FilterSetConfig> {
  const comparator = (a: Readonly<FilterProperty>, b: Readonly<FilterProperty>) => {
    const order = ['Beginner', 'Intermediate', 'Advanced'];
    return order.indexOf(a) - order.indexOf(b);
  };
  const descriptor = (object: Readonly<any>) => {
    const contentEntry = object as Readonly<ContentEntry>;
    return contentEntry.levels.map(l => l.title);
  };
  return {
    name: 'Levels',
    config: {
      comparator,
      descriptor
    }
  };
}

/**
 * @returns Filter config for content entry subjects.
 */
function getSubjectsFilterConfig(): Readonly<FilterSetConfig> {
  const comparator = stringComparator;
  const descriptor = (object: Readonly<any>) => {
    const contentEntry = object as Readonly<ContentEntry>;
    return contentEntry.subjects.map(l => l.title);
  };
  return {
    name: 'Subjects',
    config: {
      comparator,
      descriptor
    }
  };
}

/**
 * @returns Filter config for content entry tutors.
 */
function getTutorsFilterConfig(): Readonly<FilterSetConfig> {
  const comparator = stringComparator;
  const descriptor = (object: Readonly<any>) => {
    const contentEntry = object as Readonly<ContentEntry>;
    return contentEntry.tutors.map(l => l.title);
  };
  return {
    name: 'Tutors',
    config: {
      comparator,
      descriptor
    }
  };
}

/**
 * @returns Filter config for content entry sections.
 */
function getSectionsFilterConfig(
  sections: Ref<ReadonlyArray<ContentEntrySection> | undefined>,
  productSectionMap: Ref<Readonly<ProductSectionMap> | undefined>
): Readonly<FilterSetConfig> {
  const comparator = stringComparator;
  const descriptor = (object: Readonly<any>) => {
    const contentEntry = object as Readonly<ContentEntry>;
    if (
      sections.value !== undefined &&
      sections.value.length > 1 &&
      productSectionMap.value !== undefined
    ) {
      return [productSectionMap.value[contentEntry.sectionHandle].title];
    }
    return [];
  };
  return {
    name: 'Sections',
    config: {
      comparator,
      descriptor
    }
  };
}

/**
 * @returns Filter config for progress status.
 */
function getStatusFilterConfig(
  progressMap: Ref<Readonly<ProgressMap> | undefined>
): Readonly<FilterSetConfig> {
  const comparator = stringComparator;
  const descriptor = (object: Readonly<any>) => {
    if (progressMap.value !== undefined) {
      const contentEntry = object as Readonly<ContentEntry>;
      const progress = progressMap.value[contentEntry.id];
      if (progress === undefined || progress === 0) {
        return ['Not started'];
      }
      if (progress < 100) {
        return ['In progress'];
      }
      return ['Completed'];
    }
    return [];
  };
  return {
    name: 'Status',
    config: {
      comparator,
      descriptor
    }
  };
}

/**
 * @returns Filter config for access passes.
 */
function getAccessPassFilterConfig(
  userAccessPasses: Ref<ReadonlyArray<AccessPassStatus> | undefined>
): Readonly<FilterSetConfig> {
  const comparator = stringComparator;
  const descriptor = (object: Readonly<any>) => {
    if (userAccessPasses.value === undefined) {
      return [];
    }
    const activeUserPasses = userAccessPasses.value.filter(pass => pass.status !== 'inactive');
    const contentEntry = object as Readonly<ContentEntry>;
    return contentEntry.accessPasses
      .filter(pass => {
        const userPass = activeUserPasses.find(userPass => userPass.id === pass.id);
        return userPass !== undefined;
      })
      .map(pass => pass.title);
  };
  return {
    name: 'Subscriptions',
    config: {
      comparator,
      descriptor
    }
  };
}

function getFilterConfigs(
  sections: Ref<ReadonlyArray<ContentEntrySection> | undefined>,
  productSectionMap: Ref<Readonly<ProductSectionMap> | undefined>,
  progressMap: Ref<Readonly<ProgressMap> | undefined>,
  accessPasses: Ref<ReadonlyArray<AccessPassStatus> | undefined>
): ReadonlyArray<FilterSetConfig> {
  const result: Array<FilterSetConfig> = [];
  result.push(getSectionsFilterConfig(sections, productSectionMap));
  result.push(getLevelsFilterConfig());
  result.push(getSubjectsFilterConfig());
  result.push(getStatusFilterConfig(progressMap));
  result.push(getTutorsFilterConfig());
  result.push(getAccessPassFilterConfig(accessPasses));
  return result;
}

/**
 * Return a filter set composition for content entry collections.
 */
export function getCollectionFilterSet(
  sections: Ref<ReadonlyArray<ContentEntrySection> | undefined>,
  productSectionMap: Ref<Readonly<ProductSectionMap> | undefined>,
  progressMap: Ref<Readonly<ProgressMap> | undefined>,
  accessPasses: Ref<ReadonlyArray<AccessPassStatus> | undefined>,
  localStorageKey?: string
): Readonly<FilterSetComposition> {
  // Collect all entries from the sections into one array.
  const contentEntries = computed(() => {
    if (sections.value === undefined) {
      return [];
    }
    return sections.value.reduce((accum: Array<ContentEntry>, section) => {
      return accum.concat(section.contentEntries);
    }, []);
  });

  //
  // Local storage of filter selection.
  //
  let init: FilterSetSelection = {};
  if (localStorageKey !== undefined) {
    const value = getObject<FilterSetSelection>(`sbl-fs-${localStorageKey}`);
    if (value !== undefined) {
      init = value;
    }
  }
  const onSelectionChange: OnFilterSetSelectionChangeCallback = selection => {
    if (Object.keys(selection).length > 0) {
      setObject<FilterSetSelection>(`sbl-fs-${localStorageKey}`, selection);
      updateUrlQueryParam<FilterSetSelection>('filter', selection);
    } else {
      removeObject(`sbl-fs-${localStorageKey}`);
      deleteUrlQueryParam('filter');
    }
  };

  // Override filter selection via query params
  const queryParam = getQueryParam<FilterSetSelection>('filter');
  if (queryParam !== undefined) {
    init = queryParam;
  }

  const cfgs = getFilterConfigs(sections, productSectionMap, progressMap, accessPasses);
  const filterSet = useFilterSet(
    cfgs,
    contentEntries,
    init,
    localStorageKey !== undefined ? onSelectionChange : undefined
  );
  return filterSet;
}
