<template>
  <page-section :margin-top="'lg'">
    <players-path-resume
      :continue-level="continueLevel"
      :guest="guest"
      :heading="'Players Path Progress'"
    />
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import PageSection from '../../../core/page/PageSection.vue';
import PlayersPathResume from '../../../content-pages/players-path/partials/PlayersPathResume.vue';

export default defineComponent({
  components: {
    PageSection,
    PlayersPathResume
  },
  props: {
    guest: {type: String, required: true},
    continueLevel: {type: Object as PropType<Readonly<PlayersPathLevel>>, required: true}
  }
});
</script>
