<template>
  <page-section :margin-top="'xs'">
    <content-column :width="'wide'">
      <repel
        :gap="'gap-x-fl-2xl'"
        :items="'items-start'"
        :wrap="false"
        class="w-full max-w-[1640px]"
      >
        <div class="flex flex-col gap-fl-xs items-start">
          <standard-heading :size="3">{{ contentEntry.title }}</standard-heading>
          <cluster :gap="'gap-y-fl-xs'">
            <content-entry-header-tutors
              :content-entry="contentEntry"
              :size="'sm'"
              class="mr-fl-lg"
            />
            <content-entry-header-levels :content-entry="contentEntry" />
            <content-entry-header-subjects :content-entry="contentEntry" />
          </cluster>
        </div>

        <cluster
          :wrap="false"
          :justify="'justify-end'"
          :items="'items-start'"
          :gap="'gap-fl-lg'"
          class="pt-fl-xs"
        >
          <content-entry-header-campus :guest="guest" :content-entry="contentEntry" />
          <content-entry-header-bookmark
            :guest="guest"
            :content-entry="contentEntry"
            :icon-only="true"
            :bookmark="bookmark"
          />
        </cluster>
      </repel>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import Repel from '../../core/compositions/Repel.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeaderLevels from '../../content-entry/partials/header/metadata/ContentEntryHeaderLevels.vue';
import ContentEntryHeaderSubjects from '../../content-entry/partials/header/metadata/ContentEntryHeaderSubjects.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../vue-composition/bookmark/types';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Repel,
    Cluster,
    StandardHeading,
    ContentEntryHeaderTutors,
    ContentEntryHeaderLevels,
    ContentEntryHeaderSubjects,
    ContentEntryHeaderCampus,
    ContentEntryHeaderBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup() {}
});
</script>
