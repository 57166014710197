<template>
  <course-not-started
    v-if="course !== undefined && trails !== undefined && progress.progress.value === 0"
    :guest="guest"
    :course="course"
    :duration="courseDuration"
    :bookmark="bookmark"
    :progress="progress"
    :trails="trails"
  />
  <course-in-progress
    v-else-if="course !== undefined && trails !== undefined"
    :guest="guest"
    :course="course"
    :duration="courseDuration"
    :bookmark="bookmark"
    :progress="progress"
    :trails="trails"
  />
</template>

<script lang="ts">
import {ref, onMounted, computed} from 'vue';
import CourseNotStarted from './variations/CourseNotStarted.vue';
import CourseInProgress from './variations/CourseInProgress.vue';
import {getCourseBySlug, getCourseLengthSec} from '../../../backend/content/course/course-query';
import {Course} from '../../../backend/content/course/course-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useProgress} from '../../vue-composition/progress/progress';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import {lessonDurations, getModules} from './utils/utils';

export default {
  components: {
    CourseNotStarted,
    CourseInProgress
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String, required: true},
    section: {type: String, default: 'courses'},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const deviceType = useDeviceType();

    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const progress = useProgress(props.guest, {
      childProgress: true
    });

    const course = ref(undefined as Course | undefined);

    const courseDuration = computed(() => {
      if (course.value !== undefined) {
        return getCourseLengthSec(course.value);
      }
      return undefined;
    });
    const lessonDuration = computed(() => {
      if (course.value !== undefined) {
        return lessonDurations(course.value);
      }
      return {};
    });

    const courseModules = computed(() => {
      if (course.value !== undefined) {
        return getModules(course.value);
      }
      return undefined;
    });

    const bookmark = useBookmark(props.guest);

    onMounted(async () => {
      loader.setLoading(true);
      course.value = await getCourseBySlug(props.slug, props.section);
      if (course.value !== undefined) {
        trails.value = await useBreadcrumbTrails(course.value, props.entryBreadcrumbTrails);
        await progress.init(course.value);
        await bookmark.init();
      }
      loader.setLoading(false);
    });

    return {
      deviceType,
      course,
      courseDuration,
      lessonDuration,
      courseModules,
      progress,
      bookmark,
      trails
    };
  }
};
</script>
