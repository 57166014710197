import {Component, nextTick} from 'vue';
import {useModalOverlay} from '../modal-overlay/modal-overlay';
import {ModalDialogComposition, ModalDialogConfig} from './types';

export function useModalDialog(component: Readonly<Component>): Readonly<ModalDialogComposition> {
  // This variable holds the return data from the dialog.
  let dialogResult: Readonly<any> | undefined = undefined;
  const setData = (data: Readonly<any> | undefined) => {
    dialogResult = data;
  };

  const hide = () => {
    modalOverlay.deactivate();
  };

  const modalOverlay = useModalOverlay(component);

  let config: ModalDialogConfig | undefined = undefined;

  const show = async (
    onOk: (data?: any) => void,
    onCancel: (data?: any) => void,
    props?: Readonly<any>,
    showCancel: boolean = true,
    okLabel: string = 'OK',
    cancelLabel: string = 'Cancel',
    callOnCancelOnDismiss: boolean = true
  ) => {
    if (props !== undefined && Object.keys(props).includes('config')) {
      throw new Error('Passing a component to dialog with a prop named "config" is not allowed');
    }

    /* 
      If another modal dialog is already shown, we must hide it before proceeding.
      The nextTick() is necessary as Vue must be given time to unmount the previous
      app that is mounted in the DOM.
    */
    hide();
    await nextTick();

    config = {
      setData,
      _showCancel: showCancel,
      _okLabel: okLabel,
      _cancelLabel: cancelLabel,
      _onOk: () => {
        hide();
        onOk(dialogResult);
      },
      _onCancel: () => {
        hide();
        onCancel(dialogResult);
      }
    };

    const allProps = {
      ...props,
      config
    };
    modalOverlay.activate(
      allProps,
      () => {
        if (callOnCancelOnDismiss) {
          onCancel(dialogResult);
        }
      },
      true
    );
  };

  return {
    show
  };
}
