<template>
  <cluster :gap="'gap-fl-2xs'" :wrap="false" :class="color">
    <div :class="`${fontSize} ${color} uppercase`">Repeat:</div>
    <link-button
      :disabled="disabled"
      :color="'lightest'"
      :size="buttonSize"
      :font-size="fontSize"
      :on-click="onLoopToggle"
      :tooltip="'Toggle Repeat'"
      :label="value"
    />
  </cluster>
</template>

<script lang="ts">
import {PropType, computed, ref} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {faRepeat} from '@fortawesome/pro-solid-svg-icons/faRepeat';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {ButtonSize} from '../../../core/button/implementation/utils';

export default {
  components: {
    LinkButton,
    Cluster
  },
  props: {
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    disabled: {type: Boolean, default: false},
    fontSize: {type: String, default: 'text-fl-base'},
    buttonSize: {type: String as PropType<ButtonSize>, default: 'sm'}
  },
  setup(props) {
    const iconRepeat = computed(() => faRepeat);
    const deviceType = useDeviceType();

    const loop = ref(true);
    const value = computed(() => {
      return loop.value ? 'ON' : 'OFF';
    });
    const onLoopToggle = () => {
      const status = !props.player.getLoopState();
      props.player.setLoopState(status);
      loop.value = status;
    };

    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));

    return {
      color,
      iconRepeat,
      deviceType,
      value,
      onLoopToggle
    };
  }
};
</script>
