<template>
  <div v-if="teamDirectoryMember !== undefined">
    <standard-heading :size="1">{{ teamDirectoryMember.title }}</standard-heading>
    <div>
      <standard-heading :size="2">Job title</standard-heading>
      <div>{{ teamDirectoryMember.teamDirectoryJobTitle }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {onMounted, ref} from 'vue';
import {TeamDirectoryMember} from '../../../backend/team-directory/team-directory-types';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {getTeamDirectoryMemberBySlug} from '../../../backend/team-directory/team-directory-query';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';

export default {
  components: {
    StandardHeading
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const teamDirectoryMember = ref(undefined as TeamDirectoryMember | undefined);
    onMounted(async () => {
      loader.setLoading(true);
      teamDirectoryMember.value = await getTeamDirectoryMemberBySlug(props.slug);
      loader.setLoading(false);
    });
    return {
      teamDirectoryMember
    };
  }
};
</script>

<style scoped></style>
