<template>
  <div>
    <div
      v-if="message !== undefined && timeout"
      class="fixed left-0 bottom-0 p-fl-sm z-50 bg-primary text-fl-xs text-white"
    >
      {{ message }}
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {ref, defineComponent, onErrorCaptured, nextTick} from 'vue';

/**
 * Error reporter
 *
 * This component is assumed to be at the top level of the Vue component hierarchy.
 * It captures errors from components beneath it and displays them to the user.
 *
 * See
 * https://vuejs.org/api/composition-api-lifecycle#onerrorcaptured
 */
export default defineComponent({
  setup() {
    const message = ref<string | undefined>(undefined);
    const timeout = ref(false);

    onErrorCaptured(error => {
      if (error instanceof Error) {
        message.value = error.message;

        /*
          Display the error message after 5 seconds. This avoids displaying errors
          for cancelled requests when navigating away from a page.
        */
        window.setTimeout(() => {
          timeout.value = true;
        }, 5000);

        /* 
          Re-throw once Vue has re-rendered the component hiearchy.
          As this is assumed to be the top-level component in the
          Vue app, the error will be propagated to the global error
          handler in Vue.
        */
        nextTick().then(() => {
          throw error;
        });

        /*
          Let Vue know we've handled the error (so it can continue rendering).
          This will effectively "swallow" the error, so it's important that
          we re-throw it after the next tick (see above). Otherwise, the
          Javascript console may not contain any error trace.
        */
        return false;
      }

      /*
        If something other than an Error instance was thrown, pass it through
        to Vue's global error handler.
      */
      return true;
    });

    return {
      message,
      timeout
    };
  }
});
</script>
