<template>
  <div v-if="items.length > 1" :class="`flex flex-col mb-fl-sm`">
    <!-- Title -->
    <button class="w-full select-none" @click="toggleExpanded">
      <div class="flex justify-between">
        <div class="flex flex-nowrap items-center">
          <div class="text-fl-2xl text-white font-semibold">{{ filter.name }}</div>
          <count v-if="numberOfSelections > 0" class="ml-fl-2xs bg-primary">
            <div class="text-fl-xs text-black font-semibold">{{ numberOfSelections }}</div>
          </count>
        </div>
        <font-awesome-icon :icon="icon" class="text-fl-xl text-white"></font-awesome-icon>
      </div>
    </button>

    <!-- Checkboxes -->
    <collapser :status="expanded" :init="false">
      <template #visible>
        <div class="pt-fl-lg mb-fl-sm flex flex-col gap-fl-sm">
          <checkbox-list :items="items" />
        </div>
      </template>
    </collapser>
  </div>
</template>

<script lang="ts">
import {PropType, computed, ComputedRef, ref} from 'vue';

import CheckboxList from '../checkbox-list/CheckboxList.vue';
import Count from '../../generic/count/Count.vue';
import Collapser from '../collapser/Collapser.vue';
import {FilterSetComposition, FilterSetFilter} from '../../vue-composition/filter-set/types';
import {CheckboxListItem} from '../checkbox-list/types';
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  components: {
    Count,
    Collapser,
    CheckboxList,
    FontAwesomeIcon
  },
  props: {
    filterSet: {type: Object as PropType<Readonly<FilterSetComposition>>, required: true},
    filter: {type: Object as PropType<Readonly<FilterSetFilter>>, required: true}
  },
  setup(props) {
    const expanded = ref(false);
    const toggleExpanded = () => {
      expanded.value = !expanded.value;
    };

    const icon = computed(() => {
      return expanded.value ? faAngleUp : faAngleDown;
    });
    const tooltip = computed(() => {
      return expanded.value ? `Contract ${props.filter.name}` : `Expand: ${props.filter.name}`;
    });

    // ### Do we really need this?
    const numberOfSelections = computed(() => {
      return props.filterSet.selection(props.filter.name).value.length;
    });

    const clear = () => {
      props.filterSet.clear(props.filter.name);
    };

    const items: ComputedRef<ReadonlyArray<CheckboxListItem>> = computed(() => {
      const selection = props.filterSet.selection(props.filter.name).value;
      const remainderProperties = props.filterSet.remainingProperties(props.filter.name).value;
      const allProperties = props.filterSet.allProperties(props.filter.name).value;
      const result = allProperties.map((prop, index) => {
        const isSelected = selection.includes(prop);
        const isEmphasized = remainderProperties.includes(prop);
        const item: CheckboxListItem = {
          id: `${props.filter.name}-${prop}-${index}-${allProperties.length}`,
          title: prop,
          checked: isSelected,
          muted: !isSelected && !isEmphasized,
          onClicked: () => {
            if (!isSelected) {
              props.filterSet.add(props.filter.name, prop);
            } else {
              props.filterSet.remove(props.filter.name, prop);
            }
          }
        };
        return item;
      });
      return result;
    });
    return {
      expanded,
      icon,
      tooltip,
      numberOfSelections,
      clear,
      toggleExpanded,
      items
    };
  }
};
</script>
