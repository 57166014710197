<template>
  <p v-if="summary !== undefined" class="text-light text-fl-base">
    {{ summary }}
  </p>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default defineComponent({
  props: {
    summary: {type: String, default: undefined}
  },
  setup() {
    const deviceType = useDeviceType();
    return {deviceType};
  }
});
</script>
