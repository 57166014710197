import * as Joi from 'joi';
import {
  cachedValidatedHttpGet,
  cachedValidatedHttpPost,
  validatedHttpGet,
  validatedHttpPost
} from './http';
import {VALIDATION_BOOLEAN, VALIDATION_OPTIONAL_STRING} from './validation';
import {ThreadSyncCache} from '../utils/thread-sync-cache';

export type ControllerResponse<T> = {
  success: boolean;
  error?: string;
  trace?: string;
  data?: T;
};

/**
 * Execute an HTTP GET query against a Craft controller action, and cache the response.
 */
export async function cachedGetAction<T>(
  url: string,
  schema: Joi.ObjectSchema | Joi.ArraySchema | Joi.NumberSchema | Joi.BooleanSchema,
  cache: ThreadSyncCache<ControllerResponse<T>>,
  cacheKey: string
): Promise<T> {
  const responseSchema = Joi.object({
    success: VALIDATION_BOOLEAN,
    error: VALIDATION_OPTIONAL_STRING,
    trace: VALIDATION_OPTIONAL_STRING,
    data: schema.optional().allow(null)
  });

  const response = await cachedValidatedHttpGet<ControllerResponse<T>>(
    url,
    responseSchema,
    cache,
    cacheKey
  );
  // If calling the action was successful, return the data.
  if (response.success) {
    if (response.data !== undefined) {
      return response.data;
    }
    throw new Error('Successful response with undefined data');
  }

  // If not, throw with the provided error message (if any)
  let message = 'Cached get action: Unknown error';
  if (response.error !== undefined && response.error !== null) {
    message = response.error;
  }
  if (response.trace !== undefined && response.trace !== null) {
    message = `${message}: ${response.trace}`;
  }
  throw new Error(message);
}

/**
 * Execute an HTTP GET query against a Craft controller action.
 */
export async function getAction<T>(
  url: string,
  schema:
    | Joi.ObjectSchema
    | Joi.ArraySchema
    | Joi.NumberSchema
    | Joi.BooleanSchema
    | Joi.StringSchema
): Promise<T> {
  const responseSchema = Joi.object({
    success: VALIDATION_BOOLEAN,
    error: VALIDATION_OPTIONAL_STRING,
    trace: VALIDATION_OPTIONAL_STRING,
    data: schema.optional().allow(null)
  });

  const response = await validatedHttpGet<ControllerResponse<T>>(url, responseSchema);
  // If calling the action was successful, return the data.
  if (response.success) {
    if (response.data !== undefined) {
      return response.data;
    }
    throw new Error('Successful response with undefined data');
  }

  // If not, throw with the provided error message (if any)
  let message = 'Get action: Unknown error';
  if (response.error !== undefined && response.error !== null) {
    message = response.error;
  }
  if (response.trace !== undefined && response.trace !== null) {
    message = `${message}: ${response.trace}`;
  }
  throw new Error(message);
}

/**
 * Execute an HTTP POST query against a Craft controller action.
 */
export async function postAction<T>(
  url: string,
  resource: any,
  schema:
    | Joi.ObjectSchema
    | Joi.ArraySchema
    | Joi.NumberSchema
    | Joi.BooleanSchema
    | Joi.StringSchema
): Promise<T> {
  const responseSchema = Joi.object({
    success: VALIDATION_BOOLEAN,
    error: VALIDATION_OPTIONAL_STRING,
    trace: VALIDATION_OPTIONAL_STRING,
    data: schema.optional().allow(null)
  });

  const response = await validatedHttpPost<ControllerResponse<T>>(url, resource, responseSchema);
  // If calling the action was successful, return the data.
  if (response.success) {
    if (response.data !== undefined) {
      return response.data;
    }
    throw new Error('Successful response with undefined data');
  }

  // If not, throw with the provided error message (if any)
  let message = 'Post action: Unknown error';
  if (response.error !== undefined && response.error !== null) {
    message = response.error;
  }
  if (response.trace !== undefined && response.trace !== null) {
    message = `${message}: ${response.trace}`;
  }
  throw new Error(message);
}

/**
 * Execute an HTTP POST query against a Craft controller action and cache
 * the result.
 */
export async function cachedPostAction<T>(
  url: string,
  resource: any,
  schema:
    | Joi.ObjectSchema
    | Joi.ArraySchema
    | Joi.NumberSchema
    | Joi.BooleanSchema
    | Joi.StringSchema,
  cache: ThreadSyncCache<ControllerResponse<T>>,
  cacheKey: string | number
): Promise<T> {
  const responseSchema = Joi.object({
    success: VALIDATION_BOOLEAN,
    error: VALIDATION_OPTIONAL_STRING,
    trace: VALIDATION_OPTIONAL_STRING,
    data: schema.optional().allow(null)
  });

  const response = await cachedValidatedHttpPost<ControllerResponse<T>>(
    url,
    resource,
    responseSchema,
    cache,
    cacheKey
  );
  // If calling the action was successful, return the data.
  if (response.success) {
    if (response.data !== undefined) {
      return response.data;
    }
    throw new Error('Successful response with undefined data');
  }

  // If not, throw with the provided error message (if any)
  let message = 'Post action: Unknown error';
  if (response.error !== undefined && response.error !== null) {
    message = response.error;
  }
  if (response.trace !== undefined && response.trace !== null) {
    message = `${message}: ${response.trace}`;
  }
  throw new Error(message);
}
