<template>
  <div>
    <page-section :margin-top="'xl'">
      <content-column :width="'wide'">
        <standard-heading :size="1">Current Offers</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <div class="flex flex-col">
          <div v-for="(offer, index) in offers" :key="offer.offer.id">
            <standard-divider v-if="index === 0" class="border-dark" />
            <current-offer :offer="offer.offer" :on-select="offer.onSelect" />
            <standard-divider class="border-dark" />
          </div>
        </div>
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import PageSection from '../../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../../../core/standard-heading/StandardHeading.vue';
import StandardDivider from '../../../../../core/standard-divider/StandardDivider.vue';
import CurrentOffer from './CurrentOffer.vue';
import {UpgradeOffer} from '../../../../../../backend/offer/upgrade-offer-types';
import {AddOnOffer} from '../../../../../../backend/offer/addon-offer-types';
import {redirectWithHistory} from '../../../../../../utils/url';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    StandardDivider,
    CurrentOffer
  },
  props: {
    upgradeOffers: {type: Array as PropType<ReadonlyArray<UpgradeOffer>>, required: true},
    addOnOffers: {type: Array as PropType<ReadonlyArray<AddOnOffer>>, required: true}
  },
  setup(props) {
    const upgrades = computed(() => {
      return props.upgradeOffers.map(o => {
        return {
          offer: o,
          onSelect: () => {
            redirectWithHistory(`/account/upgrade?offer=${o.id}`);
          }
        };
      });
    });
    const addOns = computed(() => {
      return props.addOnOffers.map(o => {
        return {
          offer: o,
          onSelect: () => {
            redirectWithHistory(`/account/add-on?offer=${o.id}`);
          }
        };
      });
    });

    // See https://stackoverflow.com/questions/68814508/typescript-concat-two-data-types-array-in-one
    const offers = computed(() => {
      return [...upgrades.value, ...addOns.value];
    });

    return {
      offers
    };
  }
});
</script>
