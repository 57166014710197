<template>
  <div v-if="!preview && !guest && targetUrl">
    <icon-label-button
      v-if="display === 'iconLabel'"
      :url="targetUrl"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :tooltip="'Discuss in the Campus'"
      :color="color"
    />
    <link-icon-button
      v-else-if="display === 'linkIcon'"
      :url="targetUrl"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :size="'sm'"
      :tooltip="'Discuss in the Campus'"
      :color="color"
    />
    <standard-button
      v-else-if="display === 'standard'"
      :url="targetUrl"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :size="'sm'"
      :tooltip="'Discuss in the Campus'"
      :color="color"
    />
    <icon-button v-else :icon="icon" :tooltip="'Discuss in the Campus'" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import IconLabelButton from '../../../../core/button/IconLabelButton.vue';
import LinkIconButton from '../../../../core/button/LinkIconButton.vue';
import IconButton from '../../../../core/button/IconButton.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import {faComments} from '@fortawesome/pro-solid-svg-icons/faComments';
import {ButtonColor} from '../../../../core/button/implementation/utils';

export type ContentEntryHeaderCampusType = 'linkIcon' | 'icon' | 'iconLabel' | 'standard';

export default defineComponent({
  components: {
    IconLabelButton,
    LinkIconButton,
    IconButton,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    url: {type: String, default: undefined},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, default: undefined},
    display: {type: String as PropType<ContentEntryHeaderCampusType>, default: 'iconLabel'},
    color: {type: String as PropType<ButtonColor>, default: undefined},
    label: {type: String, default: 'Discuss'}
  },
  setup(props) {
    const icon = computed(() => faComments);
    const targetUrl = computed(() => {
      if (props.url !== undefined) {
        return props.url;
      }
      if (props.contentEntry !== undefined) {
        return props.contentEntry.campusUrl;
      }
      return undefined;
    });
    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });
    return {
      icon,
      targetUrl,
      preview
    };
  }
});
</script>
