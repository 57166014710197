<template>
  <modal-dialog :config="config">
    <page-section :margin-top="'xs'">
      <standard-heading :center="true" :size="2">How to Search</standard-heading>
    </page-section>

    <page-section>
      <flow>
        <p>Enter one or several search terms.</p>
        <p>You can combine terms like this:</p>
        <p>
          Searching for <em class="text-white">bass gear</em> returns content with both
          <em class="text-white">bass</em> and <em class="text-white">gear</em>.
        </p>
        <p>
          Searching for <em class="text-white">"bass gear"</em> returns content with the exact
          phrase <em class="text-white">bass gear</em>.
        </p>
        <p>
          Searching for <em class="text-white">bass OR gear</em> returns content with either
          <em class="text-white">bass</em> or <em class="text-white">gear</em> (or both).
        </p>
        <p>
          Searching for <em class="text-white">bass -gear</em> returns content with
          <em class="text-white">bass</em> but not <em class="text-white">gear</em>.
        </p>
      </flow>
    </page-section>
  </modal-dialog>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import ModalDialog from '../../generic/modal-dialog/ModalDialog.vue';
import {ModalDialogConfig} from '../../vue-composition/modal-dialog/types';

export default defineComponent({
  components: {
    PageSection,
    Flow,
    StandardHeading,
    ModalDialog
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true}
  },
  setup() {}
});
</script>
