import {Tutor} from './tutor-types';
import {CraftId, CraftSlug} from '../craft/craft-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';
import {IMAGE_COLLECTION_FIELD} from '../image-collection/image-collection-query';
import {fetchEntries} from '../craft/entry/entry-query';
import {
  FetchEntriesPaginatedComposition,
  usePaginatedEntryFetch
} from '../../components/vue-composition/paginated-fetch/paginated-entry-fetch';
import {CraftQueryBuilder} from '../craft/query/craft-query-builder-types';

export const TUTOR_FIELDS: Array<CraftQueryBuilder> = [
  QueryBuilder.plainText('tutorFocusOfStudy'),
  QueryBuilder.plainText('tutorShortBiography'),
  QueryBuilder.plainText('tutorLongBiography'),
  QueryBuilder.plainText('tutorTwitterHandle'),
  QueryBuilder.lightswitch('tutorFeaturedMentor'),
  IMAGE_COLLECTION_FIELD
];

export const TUTORS_FIELD = QueryBuilder.entries('tutors').fields(TUTOR_FIELDS);

export async function getAllFeaturedMentors(): Promise<ReadonlyArray<Tutor>> {
  try {
    const query = QueryBuilder.entries()
      .section(['tutors'])
      .status(['live'])
      .filter({tutorFeaturedMentor: true})
      .fields(TUTOR_FIELDS);
    const tutors = await fetchEntries<Tutor>(query);
    return [...tutors].sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  } catch (error) {
    throw new Error(`Could not fetch featured mentors: ${error}`);
  }
}

export async function getAllTutors(): Promise<Array<Tutor>> {
  try {
    const query = QueryBuilder.entries().section(['tutors']).status(['live']).fields(TUTOR_FIELDS);
    const entries = await fetchEntries(query);
    const tutors = entries as unknown as Array<Tutor>;
    return tutors;
  } catch (error) {
    throw new Error(`Could not fetch tutors: ${error}`);
  }
}

export function getAllTutorsPaginated(): FetchEntriesPaginatedComposition<Tutor> {
  const query = QueryBuilder.entries().section(['tutors']).status(['live']).fields(TUTOR_FIELDS);
  const fetch = usePaginatedEntryFetch<Tutor>();
  fetch.start(query);
  return fetch;
}

export async function getTutor(id: CraftId): Promise<Tutor | undefined> {
  try {
    const query = QueryBuilder.entries()
      .section(['tutors'])
      .status(['live'])
      .id([id])
      .fields(TUTOR_FIELDS);
    const tutors = await fetchEntries<Tutor>(query);
    return tutors[0];
  } catch (error) {
    throw new Error(`Could not fetch tutor: ${error}`);
  }
}

export async function getTutorsBySlug(slugs: Array<CraftSlug>): Promise<ReadonlyArray<Tutor>> {
  try {
    const query = QueryBuilder.entries()
      .section(['tutors'])
      .status(['live'])
      .slug(slugs)
      .fields(TUTOR_FIELDS);
    const tutors = await fetchEntries<Tutor>(query);
    return tutors;
  } catch (error) {
    throw new Error(`Could not fetch tutors: ${error}`);
  }
}
