/**
 * A very simple statistics histogram.
 * It is used to collect video playhead location samples,
 * but could potentially be used for other things as well.
 */
export default class Histogram {
  private numberOfBins: number;
  private bins: {[bin: number]: number};

  constructor(numberOfBins: number) {
    this.numberOfBins = numberOfBins;
    this.bins = {};
    this.clear();
  }

  clear() {
    this.bins = {};
    for (let i = 0; i < this.numberOfBins; i += 1) {
      this.bins[i] = 0;
    }
  }

  addSample(intervalPerc: number) {
    if (intervalPerc < 0 || intervalPerc > 1) {
      return;
    }
    const bin = Math.floor(intervalPerc * this.numberOfBins);
    this.bins[bin] += 1;
  }

  getCoverage() {
    let nonEmptyBins = 0;
    for (let i = 0; i < this.numberOfBins; i += 1) {
      const bin = this.bins[i];
      if (bin > 0) {
        ++nonEmptyBins;
      }
    }
    return nonEmptyBins / this.numberOfBins;
  }

  getTotalSamples() {
    let count = 0;
    for (let i = 0; i < this.numberOfBins; i += 1) {
      const bin = this.bins[i];
      count += bin;
    }
    return count;
  }
}
