<template>
  <div v-if="notifications.length > 0" class="fixed top-24 left-1/2 -translate-x-1/2 z-50">
    <div v-for="(notification, index) in notifications" :key="index">
      <div class="px-fl-lg-xl py-fl-md-lg bg-dark rounded-md">
        <div class="flex items-center">
          <font-awesome-icon
            v-if="notification.icon"
            :icon="notification.icon"
            :class="`mr-fl-xs sm:mr-fl-sm text-fl-3xl sm:text-fl-6xl ${notification.iconColor}`"
          ></font-awesome-icon>
          <div class="mr-fl-md sm:mr-fl-lg text-fl-lg sm:text-fl-3xl font-semibold text-white">
            {{ notification.title }}
          </div>
          <link-button
            v-if="notification.url && notification.urlText"
            :size="buttonSize"
            :url="notification.url"
            :label="notification.urlText"
            :color="'light'"
            :underline="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import {useStore} from '../../../store/store';
import LinkButton from '../../core/button/LinkButton.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {NotificationPopupConfig} from './types';
import {deviceTypeSwitch} from '../../vue-composition/device-type/device-type';
import {ButtonSize} from '../../core/button/implementation/utils';

export default defineComponent({
  components: {
    LinkButton,
    FontAwesomeIcon
  },
  props: {},
  setup() {
    const store = useStore();
    const notifications = computed(() => {
      const active = store.getters['notificationPopup/activeNotifications'] as Map<
        number,
        NotificationPopupConfig
      >;
      return Array.from(active.values());
    });
    const buttonSize = deviceTypeSwitch<ButtonSize>({
      narrow: 'xs',
      sm: 'sm',
      md: 'md'
    });

    return {
      buttonSize,
      notifications
    };
  }
});
</script>
