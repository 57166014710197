<template>
  <!-- ??? We use the CSS aspect-ratio property here, which became available in 2021. Is that OK? -->
  <video-poster
    v-if="preview || guest"
    :guest="guest"
    :preview="preview"
    class="w-full aspect-video"
  />
  <div
    v-else-if="!webcastOnAir"
    class="w-full p-fl-md aspect-video flex flex-col justify-center items-center text-center text-white text-fl-3xl"
  >
    {{ pendingText }}
  </div>
  <iframe
    v-else-if="webcastUrl !== undefined"
    ref="iframe"
    :src="webcastUrl"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    class="w-full aspect-video"
  ></iframe>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import VideoPoster from '../../generic/video-player/video-poster/VideoPoster.vue';
import {WebcastComposition} from '../../vue-composition/webcast/types';

export default defineComponent({
  components: {
    VideoPoster
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    webcast: {type: Object as PropType<Readonly<WebcastComposition>>, required: true}
  },
  setup(props) {
    const webcastOnAir = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return false;
      }
      return webcast.webcastOnAir;
    });
    const pendingText = computed(() => {
      const DEFAULT_MESSAGE = 'We are about to go on air. Please hang on.';
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return DEFAULT_MESSAGE;
      }
      const pendingText = webcast.webcastPendingText;
      if (pendingText !== null && pendingText.length > 0) {
        return pendingText;
      }
      return DEFAULT_MESSAGE;
    });
    const webcastUrl = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast === undefined) {
        return undefined;
      }
      if (webcast.webcastEmbedUrl !== null) {
        return webcast.webcastEmbedUrl;
      }
      if (webcast.webcastVimeoId !== null) {
        return `https://vimeo.com/event/${webcast.webcastVimeoId}/embed`;
      }
      return undefined;
    });
    return {
      webcastUrl,
      webcastOnAir,
      pendingText
    };
  }
});
</script>
