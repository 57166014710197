import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {
  entryIsPast,
  entryIsPending,
  webcastIsActive
} from '../../../backend/craft/entry/entry-utils';
import {getWebcasts} from '../../../backend/webcast/webcast-query';

/**
 * Given a list of entries, check their webcasts (if any) and divide
 * the entries into two groups:
 *
 * Active: These are entries that have a post date in the past AND are
 * among the currently active webcasts (in the "Webcasts" Global in
 * Craft).
 *
 * Upcoming: Entries that have a post date in the future but are NOT
 * among the currently active webcasts.
 *
 * Past: Entries that have a post date in the past and are NOT among
 * the currently active webcasts.
 *
 *
 *                 Add entry      Entry          Remove entry
 *      Create     to Webcasts    Post           from Webcasts
 *      entry      Global         date           Global
 *   ---|----------|--------------|--------------|--------------->
 *
 *      |-------  Upcoming  ------|--  Active  --|----  Past  ----
 *
 *
 * In addition, also return a "next" entry if applicable; if there
 * are no active webcasts, the next entry is the one that has a post
 * date closest to now. If any webcast is active, however, "next"
 * entry is undefined.
 *
 * NOTE: An active webcast has an additional "on air" status that can
 * be true or false. This function does not return any information
 * about whether entries are on air or not, it only distinguishes
 * between entries that have an active webcast from those that do not.
 */
export async function classifySeminars(entries: ReadonlyArray<ContentEntry>) {
  const webcasts = await getWebcasts();

  /*
    Get upcoming entries: these have a post date in the future (i.e.,
    they have the "pending" Craft status.) Return them in reverse order
    (i.e., closest to now first).
  */
  const allUpcoming = entries.filter(entry => entryIsPending(entry)).reverse();

  /*
    Get the entries that have active webcasts: they have a post date in the past
    and are among the current webcasts. (This does not mean that they are on air,
    though. We don't care about that here.)
  */
  const activeWebcasts = entries.filter(
    entry => webcastIsActive(entry, webcasts) && entryIsPast(entry)
  );

  /*
    Get past entries: these have a post date in the past (i.e., they have the
    "live" Craft status) and are not among the active webcasts. Return them
    in reverse order (i.e., closest to now first).
  */
  const past = entries.filter(entry => !webcastIsActive(entry, webcasts) && entryIsPast(entry));

  /*
    Figure out if we have a 'next' entry: we do if there are no active
    webcasts. If there is at least one active webcast, then there is no
    'next' entry; in that case we want to display the active webcasts.
  */
  let next: Readonly<ContentEntry> | undefined = undefined;
  let upcoming: ReadonlyArray<ContentEntry> = [];
  if (activeWebcasts.length === 0) {
    next = allUpcoming[0];
    upcoming = allUpcoming.slice(1);
  } else {
    upcoming = allUpcoming;
  }

  return {
    activeWebcasts,
    next,
    upcoming,
    past
  };
}
