<template>
  <a :href="contentEntry.url">
    <repel class="w-full">
      <cluster :gap="'gap-fl-lg'" class="group">
        <div class="w-[75px] h-[75px]">
          <portrait-button
            v-if="avatar !== undefined"
            :config="avatar"
            :tooltip="'To seminar'"
            :on-click="goToSeminar"
          />
        </div>
        <div class="flex flex-col">
          <div
            v-if="live !== undefined && live"
            class="text-fl-base text-primary font-semibold group-hover:text-white"
          >
            LIVE NOW
          </div>
          <div v-else class="text-fl-base text-primary font-semibold">{{ postDate }}</div>
          <div class="text-fl-lg font-semibold text-light group-hover:text-white">
            {{ contentEntry.title }}
          </div>
          <div class="text-fl-base text-mid group-hover:text-white">{{ tutors }}</div>
        </div>
      </cluster>
      <content-entry-header-bookmark
        :guest="guest"
        :bookmark="bookmark"
        :content-entry="contentEntry"
      />
    </repel>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import PortraitButton from '../../../core/button/PortraitButton.vue';
import ContentEntryHeaderBookmark from '../../partials/header/actions/ContentEntryHeaderBookmark.vue';
import {
  getAvatarImage,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {redirectWithHistory} from '../../../../utils/url';
import {postDateToLocale} from '../../../../backend/craft/entry/entry-utils';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';

export default defineComponent({
  components: {
    Cluster,
    Repel,
    PortraitButton,
    ContentEntryHeaderBookmark
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true},
    live: {type: Boolean, default: undefined}
  },
  setup(props) {
    const avatar = computed(() => {
      const tutor = props.contentEntry.tutors[0];
      if (tutor !== undefined) {
        return getAvatarImage(tutor.imageCollection, tutor.title, {narrow: 75});
      }
      return undefined;
    });

    const tutors = computed(() => {
      return tutorList(props.contentEntry);
    });

    const goToSeminar = () => {
      if (props.contentEntry.url !== null) {
        redirectWithHistory(props.contentEntry.url);
      }
    };

    const postDate = computed(() => postDateToLocale(props.contentEntry.postDate, 'long'));

    return {avatar, tutors, goToSeminar, postDate};
  }
});
</script>
