import {App, InjectionKey} from 'vue';
import {createStore, Store, useStore as baseUseStore} from 'vuex';
import {SyncStoreState, SyncStore} from './modules/sync';
import {LoaderStoreState, LoaderStore} from './modules/loader';
import {FullScreenLoaderStoreState, FullScreenLoaderStore} from './modules/full-screen-loader';
import {InstrumentationStoreState, InstrumentationStore} from './modules/instrumentation';
import {ModalOverlayStoreState, ModalOverlayStore} from './modules/modal-overlay';
import {SidebarStoreState, SidebarStore} from './modules/sidebar';
import {NotificationPopupStoreState, NotificationPopupStore} from './modules/notification-popup';
import {LocalStorageFlagStore, LocalStorageFlagStoreState} from './modules/localStorageFlag';
import {HelpScoutStore, HelpScoutStoreState} from './modules/help-scout';
import {PreviewStore, PreviewStoreState} from './modules/preview';

export interface StoreState {
  sync: SyncStoreState;
  loader: LoaderStoreState;
  fullScreenLoader: FullScreenLoaderStoreState;
  instrumentation: InstrumentationStoreState;
  modalOverlay: ModalOverlayStoreState;
  sidebar: SidebarStoreState;
  notificationPopup: NotificationPopupStoreState;
  localStorageFlag: LocalStorageFlagStoreState;
  helpScout: HelpScoutStoreState;
  preview: PreviewStoreState;
}

// See https://next.vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function
const STORE_INJECTION_KEY: InjectionKey<Store<StoreState>> = Symbol();

/**
 * The Vuex store instance. Don't use it directly from setup() component functions;
 * use the useStore() function in this file instead.
 */
export const STORE = createStore<StoreState>({
  modules: {
    sync: SyncStore,
    loader: LoaderStore,
    fullScreenLoader: FullScreenLoaderStore,
    instrumentation: InstrumentationStore,
    modalOverlay: ModalOverlayStore,
    sidebar: SidebarStore,
    notificationPopup: NotificationPopupStore,
    localStorageFlag: LocalStorageFlagStore,
    helpScout: HelpScoutStore,
    preview: PreviewStore
  }
});

export function addStoreToApp(app: App<Element>) {
  app.use(STORE, STORE_INJECTION_KEY);
}

/**
 * Use this function instead of the useStore() exposed in Vuex!
 * See https://next.vuex.vuejs.org/guide/typescript-support.html#simplifying-usestore-usage
 *
 * @returns The Vuex store API.
 */
export function useStore() {
  return baseUseStore(STORE_INJECTION_KEY);
}
