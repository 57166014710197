<template>
  <icon-button v-if="type === 'angle'" :size="size" :url="url" :icon="icon" :tooltip="tooltip" />
  <a
    v-else
    :href="url"
    :class="`px-fl-sm-md py-fl-2xs-sm w-full flex ${justify} border-2 border-mid hover:border-white rounded-[0.5rem] text-mid hover:text-white cursor-pointer select-none`"
  >
    <font-awesome-icon :icon="icon" aria-hidden="true" class="text-fl-7xl"></font-awesome-icon>
  </a>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import IconButton from '../../../core/button/IconButton.vue';
import {faAngleLeft} from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import {faAngleRight} from '@fortawesome/pro-light-svg-icons/faAngleRight';
import {faArrowLeft} from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons/faArrowRight';
import {CraftUrl} from '../../../../backend/craft/craft-types';
import {IconButtonSize} from '../../../core/button/implementation/utils';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: {
    IconButton,
    FontAwesomeIcon
  },
  props: {
    url: {type: String as PropType<CraftUrl>, required: true},
    direction: {type: String as PropType<'prev' | 'next'>, required: true},
    type: {type: String as PropType<'angle' | 'button'>, required: true},
    size: {type: String as PropType<IconButtonSize>, required: true}
  },
  setup(props) {
    const icon = computed(() => {
      if (props.type === 'angle') {
        return props.direction === 'next' ? faAngleRight : faAngleLeft;
      }
      return props.direction === 'next' ? faArrowRight : faArrowLeft;
    });
    const tooltip = computed(() =>
      props.direction === 'next' ? 'Go to Next Lesson' : 'Go to Previous Lesson'
    );
    const label = computed(() => (props.direction === 'next' ? 'Next' : 'Prev'));
    const justify = computed(() => (props.direction === 'next' ? 'justify-end' : 'justify-start'));
    return {
      icon,
      tooltip,
      label,
      justify
    };
  }
});
</script>
