import * as Joi from 'joi';

import {ThreadSyncCache} from '../utils/thread-sync-cache';
import {validate} from './validation';

/**
 * Let TypeScript know that we have added a Craft property to the
 * global Window object. This property is where we store the base URL
 * of the site, as well as the CSRF token we need to authenticate POST
 * requests to Craft. The property is set in the main page template,
 * see /templates/layouts/site.twig.
 */
interface SBLCraftData {
  csrfTokenName: string | undefined;
  csrfTokenValue: string | undefined;
  siteUrl: string | undefined;
  instrumentationApiKey: string | undefined;
  throttlingToken: string | undefined;
}
declare global {
  interface Window {
    Craft: undefined | SBLCraftData;
  }
}

export async function httpGet(
  url: string,
  additionalHeaders?: {[key: string]: string}
): Promise<unknown> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...additionalHeaders
  };
  const method = 'GET';
  const fetchOptions = {method, headers};
  const response = await fetch(url, fetchOptions);
  const status = response.status;
  if (status < 200 && status > 299) {
    try {
      const json = await response.json();
      const msg = `${json.error}; ${json.file} line ${json.line}`;
      throw new Error(`HTTP GET JSON error: ${status}; ${url}; ${msg}`);
    } catch (error) {
      throw new Error(`HTTP GET parse error: ${error}; ${url}`);
    }
  }
  try {
    return await response.json();
  } catch (error) {
    throw new Error(`HTTP GET json parse error: ${error}; ${url}`);
  }
}

/**
 * Carry out an HTTP GET request and validate the response against the provided schema.
 * @throw Error if HTTP request fails, or if the validation fails.
 */
export async function validatedHttpGet<T>(
  url: string,
  schema:
    | Joi.ObjectSchema
    | Joi.ArraySchema
    | Joi.NumberSchema
    | Joi.BooleanSchema
    | Joi.StringSchema
): Promise<T> {
  try {
    const response = await httpGet(url);
    validate(response, schema);
    return response as T;
  } catch (error) {
    throw new Error(`Validated HTTP GET error: ${error}`);
  }
}

/**
 * Carry out an HTTP GET request, validate the response against the provided schema,
 * and cache the result under the specified cache key. If this function is called
 * again with the same cache key, return the cached result instead of carrying out
 * a new HTTP query.
 *
 * @throw Error if HTTP request fails, or if validation fails.
 */
export async function cachedValidatedHttpGet<T>(
  url: string,
  schema: Joi.ObjectSchema | Joi.ArraySchema | Joi.NumberSchema,
  cache: ThreadSyncCache<T>,
  cacheKey: string
): Promise<T> {
  const release = await cache.lock();
  try {
    const entity = cache.get(cacheKey);
    if (entity !== undefined) {
      return entity;
    }
    const response: T = await validatedHttpGet(url, schema);
    cache.set(cacheKey, response);
    return response;
  } catch (error) {
    throw new Error(`Cached validated HTTP GET error: ${error}`);
  } finally {
    release();
  }
}

export interface HttpPostResponse {
  ok: boolean;
  status: number;
  bodyJson: unknown;
}

export async function httpPost(url: string, resource: any): Promise<HttpPostResponse> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  const method = 'POST';

  // Add the CSRF token we've received from Craft. This
  // authenticates the POST request.
  const data: any = {...resource};
  if (
    window.Craft === undefined ||
    window.Craft.csrfTokenName === undefined ||
    window.Craft.csrfTokenValue === undefined
  ) {
    throw new Error('HTTP POST error: No CSRF token');
  }
  data[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;

  const body = JSON.stringify(data);
  const fetchOptions = {method, headers, body};
  const response = await fetch(url, fetchOptions);
  const ok = response.ok;
  const status = response.status;
  try {
    const bodyJson = await response.json();
    return {
      ok,
      status,
      bodyJson
    };
  } catch (error) {
    throw new Error(`JSON parse error: ${error}; ${url}`);
  }
}

export async function validatedHttpPost<T>(
  url: string,
  resource: unknown,
  schema: Joi.ObjectSchema | Joi.ArraySchema | Joi.NumberSchema
): Promise<T> {
  try {
    const {ok, status, bodyJson} = await httpPost(url, resource);
    if (!ok) {
      throw new Error(`Validated HTTP POST failed (${status}): ${JSON.stringify(bodyJson)}`);
    }
    validate(bodyJson, schema);
    return bodyJson as T;
  } catch (error) {
    throw error;
  }
}

/**
 * Carry out an HTTP POST request, validate the response against the provided schema,
 * and cache the result under the specified cache key. If this function is called
 * again with the same cache key, return the cached result instead of carrying out
 * a new HTTP query.
 *
 * @throw Error if HTTP request fails, or if validation fails.
 */
export async function cachedValidatedHttpPost<T>(
  url: string,
  resource: unknown,
  schema: Joi.ObjectSchema | Joi.ArraySchema | Joi.NumberSchema,
  cache: ThreadSyncCache<T>,
  cacheKey: string | number
): Promise<T> {
  const release = await cache.lock();
  try {
    const entity = cache.get(cacheKey);
    if (entity !== undefined) {
      return entity;
    }
    const {ok, status, bodyJson} = await httpPost(url, resource);
    if (!ok) {
      throw new Error(`Validated HTTP POST failed (${status}): ${JSON.stringify(bodyJson)}`);
    }
    validate(bodyJson, schema);
    const response = bodyJson as T;
    cache.set(cacheKey, response);
    return response;
  } catch (error) {
    throw new Error(`Cached validated HTTP GET error: ${error}`);
  } finally {
    release();
  }
}

export async function httpDelete(url: string): Promise<unknown> {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  const method = 'DELETE';

  // Add the CSRF token we've received from Craft. This
  // authenticates the DELETE request.
  const data: any = {};
  if (
    window.Craft === undefined ||
    window.Craft.csrfTokenName === undefined ||
    window.Craft.csrfTokenValue === undefined
  ) {
    throw new Error('HTTP DELETE error: No CSRF token');
  }
  data[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;

  const body = JSON.stringify(data);
  const fetchOptions = {method, headers, body};
  const response = await fetch(url, fetchOptions);
  const status = response.status;
  if (status < 200 && status > 299) {
    throw new Error(`HTTP DELETE error: ${status}; ${url}`);
  }
  try {
    return await response.json();
  } catch (error) {
    throw new Error(`JSON parse error: ${error}; ${url}`);
  }
}
