import {validatedHttpGet, validatedHttpPost} from '../http';
import {CraftId, VALIDATION_CRAFT_ID_ARRAY} from '../craft/craft-types';
import {
  Progress,
  PROGRESS_VALIDATION_SCHEMA,
  PROGRESS_ARRAY_VALIDATION_SCHEMA,
  EntryProgress,
  ENTRY_PROGRESS_ARRAY_VALIDATION_SCHEMA
} from './progress-types';
import {ContentEntry} from '../content/content-entry/content-entry-types';
import {craftUrl} from '../../utils/url';

// ### Replace validatedHttpGet/Post() in functions here with get/postAction()

export async function getProgress(entryId: CraftId): Promise<Readonly<Progress>> {
  const url = craftUrl(`/actions/sbl-module/progress/get-progress?entryId=${entryId}`);
  try {
    const response = await validatedHttpGet<string>(url, PROGRESS_VALIDATION_SCHEMA);
    // ### Why does Craft return database DECIMAL values as strings?
    return Number.parseFloat(response);
  } catch (error) {
    throw new Error(`Validation error: ${error}`);
  }
}

export async function getProgressForEntries(
  entryIds: ReadonlyArray<CraftId>
): Promise<ReadonlyArray<Progress>> {
  const url = craftUrl('/actions/sbl-module/progress/get-progress-for-entries');
  try {
    const response = await validatedHttpPost<Array<string>>(
      url,
      {entryIds},
      PROGRESS_ARRAY_VALIDATION_SCHEMA
    );
    // ### Why does Craft return database DECIMAL values as strings?
    const result = response.map((progress: any) => {
      return Number.parseFloat(progress);
    });
    return result as ReadonlyArray<Progress>;
  } catch (error) {
    throw new Error(`Could not get progress for entries: ${error}`);
  }
}

export async function getProgressForChildren(
  contentEntry: Readonly<ContentEntry>
): Promise<ReadonlyArray<Progress>> {
  if (contentEntry.childEntries.length > 0) {
    const result = await getProgressForEntries(contentEntry.childEntries.map(e => e.id));
    return result;
  }
  return [];
}

export async function setProgress(
  entryId: CraftId,
  progress: Progress
): Promise<ReadonlyArray<CraftId>> {
  const url = craftUrl('/actions/sbl-module/progress/set-progress');
  try {
    const result = await validatedHttpPost<Array<CraftId>>(
      url,
      {entryId, progress},
      VALIDATION_CRAFT_ID_ARRAY
    );
    return result;
  } catch (error) {
    throw new Error(`Could not set progress: ${error}`);
  }
}

export async function setProgressForEntries(
  entryIds: ReadonlyArray<CraftId>,
  progress: Progress
): Promise<ReadonlyArray<Progress>> {
  const url = craftUrl('/actions/sbl-module/progress/set-progress-for-entries');
  try {
    const response = await validatedHttpPost<Array<string>>(
      url,
      {entryIds, progress},
      PROGRESS_ARRAY_VALIDATION_SCHEMA
    );
    // ### Why does Craft return database DECIMAL values as strings?
    const result = response.map((progress: any) => {
      return Number.parseFloat(progress);
    });
    return result as ReadonlyArray<Progress>;
  } catch (error) {
    throw new Error(`Could not set progress for entries: ${error}`);
  }
}

/**
 * Return the id:s of all entries where the currently logged in user's progress is > 0.
 */
export async function getEntriesInProgress(): Promise<ReadonlyArray<EntryProgress>> {
  const url = craftUrl('/actions/sbl-module/progress/get-entries-in-progress');
  try {
    const result = await validatedHttpGet<Array<EntryProgress>>(
      url,
      ENTRY_PROGRESS_ARRAY_VALIDATION_SCHEMA
    );
    return result;
  } catch (error) {
    throw new Error(`Could not get entries in progress: ${error}`);
  }
}

/**
 * Convert a progress value - assumed to be in range [0,1] - to an integer
 * percentage in range [0, 100]. Progress values larger than 0% but smaller
 * than 1% are rounded up to 1%.
 */
export function progressToPercent(progress: Progress | undefined) {
  if (progress === undefined) {
    return 0;
  }
  if (progress === 0) {
    return 0;
  }
  return Math.max(1, Math.round(progress * 100));
}
