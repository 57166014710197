<template>
  <page-content>
    <page-section :margin-top="'xl'">
      <content-column :width="'narrow'">
        <standard-heading :size="2">Set Your Password</standard-heading>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <p class="text-fl-3xl text-white">Please choose a password.</p>
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <flow>
          <label :for="'password'">Password</label>
          <input
            id="password"
            v-model="password"
            class="mb-fl-xl w-full p-fl-md bg-transparent text-fl-5xl font-semibold text-white placeholder:text-mid border border-lightest rounded-[0.5rem]"
            type="password"
            placeholder="Password"
            @keyup.enter="keyup"
          />
        </flow>

        <standard-button
          :size="'md'"
          :disabled="submitDisabled"
          :on-click="submit"
          :label="'Set password'"
        />
      </content-column>
    </page-section>

    <page-section>
      <content-column :width="'narrow'">
        <div v-if="errorMessage !== undefined" class="text-primary">
          {{ errorMessage }}
        </div>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {httpPost} from '../../../backend/http';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import {
  standardNotification,
  useNotificationPopup
} from '../../vue-composition/notification-popup/notification-popup';
import {redirectWithHistory} from '../../../utils/url';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    Flow,
    StandardButton
  },
  props: {
    code: {type: String, default: ''},
    id: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const notification = useNotificationPopup();

    const errorMessage = ref(undefined as string | undefined);
    const password = ref('');

    const submitDisabled = computed(() => {
      return password.value.length === 0;
    });

    const keyup = async (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        await submit();
      }
    };

    const submit = async () => {
      const url = 'actions/users/set-password';
      const params = {
        newPassword: password.value,
        id: props.id,
        code: props.code
      };
      try {
        loader.setLoading(true, 'Updating your profile');
        const {ok, bodyJson} = await httpPost(url, params);
        loader.setLoading(false);
        if (ok) {
          errorMessage.value = undefined;
          notification.notify(standardNotification('Password updated successfully.'));
          redirectWithHistory('/login');
        } else {
          interface SetPasswordActionRequestResponse {
            message: string | unknown;
          }
          const response = bodyJson as SetPasswordActionRequestResponse;
          if (response.message !== undefined) {
            errorMessage.value = `${response.message}`;
          } else {
            errorMessage.value = 'Could not set password.';
          }
        }
      } catch (error) {
        loader.setLoading(false);
        // The HTTP request failed, or Craft didn't return JSON. Display a generic error messsage.
        console.error(error);
        errorMessage.value = 'Could not set password.';
      }
    };

    return {
      notification,
      keyup,
      submit,
      submitDisabled,
      password,
      errorMessage
    };
  }
});
</script>
