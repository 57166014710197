import {useStore} from '../../../store/store';

/**
 * This composition is a utility for storing boolean flags in the browser's local storage.
 * @param key The local storage key - must be unique!
 * @param initValue Value to set the flag to if it does not already exist in storage.
 * @param watch Optional callback; called whenever the flag changes value.
 * @returns Getter and setter.
 */
export function useLocalStorageFlag(
  key: string,
  initStatus: boolean,
  watch?: (status: boolean) => void
) {
  const store = useStore();
  store.dispatch('localStorageFlag/init', {key, initStatus});

  const set = (status: boolean) => {
    store.dispatch('localStorageFlag/setFlag', {key, status});
  };
  const toggle = () => {
    const status = store.getters['localStorageFlag/flag'](key) as boolean;
    set(!status);
  };
  const get = () => {
    return store.getters['localStorageFlag/flag'](key) as boolean;
  };
  if (watch) {
    store.watch(
      state => {
        return state.localStorageFlag.flags[key];
      },
      () => {
        watch(get());
      }
    );
  }

  return {
    set,
    get,
    toggle
  };
}
