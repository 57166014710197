<template>
  <ul v-if="!guest && !contentEntry.preview && items.length > 0">
    <li v-for="(item, i) in items" :key="item.id">
      <standard-divider v-if="i === 0" class="border-dark" />
      <link-icon-button
        :size="'md'"
        :icon="item.icon"
        :url="item.url"
        :new-tab="true"
        :label="item.title"
        :gap="'gap-4'"
        :wrap="true"
        :expand="true"
        :color="'lightest'"
        :justify="'justify-start'"
        class="py-fl-lg"
      />
      <standard-divider class="border-dark" />
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import LinkIconButton from '../../../core/button/LinkIconButton.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {faLink} from '@fortawesome/pro-regular-svg-icons/faLink';
import {faBook} from '@fortawesome/pro-light-svg-icons/faBook';
import {faFileZipper} from '@fortawesome/pro-light-svg-icons/faFileZipper';
import {faFileAudio} from '@fortawesome/pro-light-svg-icons/faFileAudio';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export default defineComponent({
  components: {
    LinkIconButton,
    StandardDivider
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true}
  },
  setup(props) {
    const items = props.contentEntry.resources.map(resource => {
      let icon: IconDefinition = faLink;
      const url = resource.resourceUrl;
      if (url !== null) {
        const ext = url.substring(url.length - 4).toLowerCase();
        if (ext === '.pdf') {
          icon = faBook;
        }
        if (ext === '.zip') {
          icon = faFileZipper;
        }
        if (ext === '.mp3') {
          icon = faFileAudio;
        }
      }
      return {
        id: resource.resourceTitle,
        title: resource.resourceTitle,
        url,
        icon
      };
    });
    return {items};
  }
});
</script>
