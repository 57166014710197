<template>
  <page-content v-if="offer !== undefined">
    <page-section>
      <content-column :width="'narrow'">
        <div>Upgrade to</div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'narrow'">
        <content-entry-header>{{ offer.offerTitle }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'narrow'">
        <div class="text-fl-3xl text-pp1 font-semibold">{{ price }}</div>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column :width="'narrow'">
        <ul class="list-disc list-inside">
          <li v-for="feature in features" :key="feature">{{ feature }}</li>
        </ul>
      </content-column>
    </page-section>

    <page-section :margin-top="'md'">
      <content-column :width="'narrow'">
        <div class="text-mid">All prices are in USD.</div>
      </content-column>
    </page-section>

    <div v-if="offer.offerBonuses.length > 0">
      <page-section>
        <content-column :width="'narrow'">
          <standard-heading :size="2">Free bonuses</standard-heading>
        </content-column>
      </page-section>
      <page-section :margin-top="'md'">
        <content-column :width="'narrow'">
          <ul class="list-disc list-inside text-fl-xl">
            <li v-for="bonus in offer.offerBonuses" :key="bonus.id">{{ bonus.title }}</li>
          </ul>
        </content-column>
      </page-section>
    </div>

    <payment
      v-if="amount !== undefined"
      :width="'narrow'"
      :error="error"
      :email="userEmail"
      :amount="amount"
      :purchase-label="'Upgrade'"
      :on-cancel="onCancel"
      :on-purchase="onPurchase"
      :recaptcha-action="'payment_upgrade'"
    />
    <backend-error :width="'narrow'" :error="error" />
  </page-content>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import PageContent from '../../../../core/page/PageContent.vue';
import PageSection from '../../../../core/page/PageSection.vue';
import ContentColumn from '../../../../core/compositions/ContentColumn.vue';
import ContentEntryHeader from '../../../../content-entry/partials/header/ContentEntryHeader.vue';
import StandardHeading from '../../../../core/standard-heading/StandardHeading.vue';
import Payment from '../../../../offer/payment/Payment.vue';
import BackendError from '../../../../generic/backend-error/BackendError.vue';
import {useFullScreenLoader} from '../../../../vue-composition/loader/loader';
import {
  getCustomerLifetimeEventData,
  redirectNoHistory,
  redirectWithHistory
} from '../../../../../utils/url';
import {UpgradeOffer} from '../../../../../backend/offer/upgrade-offer-types';
import {upgrade} from '../../../../../backend/signup/checkout-query';
import {useBackendError} from '../../../../vue-composition/backend-error/backend-error';
import {useInstrumentation} from '../../../../vue-composition/instrumentation/instrumentation';
import {getUpgradeOffersForCurrentUser} from '../../../../../backend/offer/upgrade-offer-utils';
import {BraintreeCustomer, BraintreeNonce} from '../../../../offer/payment/types';

/* 
  ??? This component is more or less equivalent to the UpgradePurchase.vue component in 
  /offer/add-on/partials. We should refactor the cart/offer engine to generalise; the 
  current solution struggles to differentiate between the purchasing mechanisms (signup,
  upgrade, resubscribe, add-on) and the way the offers are presented.
*/
export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    ContentEntryHeader,
    StandardHeading,
    Payment,
    BackendError
  },
  props: {
    offerId: {type: String, required: true},
    userEmail: {type: String, required: true},
    userDateCreated: {type: String, required: true},
    serverDate: {type: String, required: true}
  },
  setup(props) {
    const error = useBackendError(true);
    const loader = useFullScreenLoader();
    const instrumentation = useInstrumentation();

    const offer = ref<Readonly<UpgradeOffer> | undefined>(undefined);
    const source = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerSource[0];
    });
    const destination = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerDestination[0];
    });
    const bonuses = computed(() => {
      if (offer.value === undefined) {
        return undefined;
      }
      return offer.value.offerBonuses;
    });
    const features = computed(() => {
      if (offer.value === undefined) {
        return [];
      }
      return offer.value.offerFeatures.split('\n');
    });
    const price = computed(() => {
      if (destination.value === undefined) {
        return undefined;
      }
      const amt = destination.value.accessPassAmount;
      if (destination.value.accessPassType === 'subscription') {
        if (destination.value.accessPassRecurrence === 'annual') {
          const m = (amt / 12).toFixed(2);
          return `$${m} per month (billed as $${amt})`;
        }
        return `$${amt} per month`;
      }
      if (destination.value.accessPassType === 'singlePurchase') {
        return `$${amt}`;
      }
      return 'Free!';
    });
    const amount = computed(() => {
      if (destination.value === undefined) {
        return undefined;
      }
      return destination.value.accessPassAmount;
    });

    onMounted(async () => {
      // Check if the offer is valid (it may not be if the user copy-pasted an upgrade URL).
      loader.setLoading(true, 'Loading your subscription');
      const upgradeOffersForCurrentUser = await getUpgradeOffersForCurrentUser(
        ['account', 'paymentPage'],
        props.userDateCreated,
        props.serverDate
      );
      loader.setLoading(false);
      offer.value = upgradeOffersForCurrentUser.find(userOffer => {
        return userOffer.id === props.offerId;
      });
      if (offer.value === undefined) {
        loader.setLoading(true);
        redirectNoHistory('/account');
        return;
      }
    });

    const onPurchase = async (nonce: Readonly<BraintreeNonce>, _: Readonly<BraintreeCustomer>) => {
      if (
        offer.value === undefined ||
        source.value === undefined ||
        destination.value === undefined ||
        bonuses.value === undefined
      ) {
        return;
      }

      // Carry out the upgrade.
      loader.setLoading(true, 'Updating your account. Please do not navigate away from this page.');
      await upgrade(
        offer.value.offerDeleteSourcePass ? source.value.slug : undefined,
        destination.value.slug,
        offer.value.offerEndOldSubscriptionImmediately,
        offer.value.offerStartNewSubscriptionImmediately,
        bonuses.value.map(p => p.slug),
        nonce.nonce,
        nonce.deviceData,
        getCustomerLifetimeEventData()
      ).catch(error.catcher);
      loader.setLoading(false);

      if (!error.error()) {
        loader.setLoading(true);
        await instrumentation.sendEvent({
          type: 'user',
          properties: {
            action: 'upgrade',
            fromAccessPass: source.value.slug,
            toAccessPass: destination.value.slug
          }
        });
        redirectWithHistory('/account?result=upgradeSuccess');
      }
    };

    const onCancel = () => {
      redirectWithHistory('/account');
    };

    return {
      error,
      loader,
      amount,
      onPurchase,
      onCancel,
      offer,
      features,
      price
    };
  }
});
</script>
