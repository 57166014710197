import {DateTime} from 'luxon';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {CraftId} from '../../../backend/craft/craft-types';
import {History} from '../../../backend/history/history-types';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export interface HistoryItem {
  contentEntry: ContentEntry;
  historyId: CraftId;
  accesses: number;
  timestamp: string;
}

export async function historyToHistoryItems(
  history: ReadonlyArray<History>
): Promise<ReadonlyArray<HistoryItem>> {
  const result: Array<HistoryItem> = [];

  const entryIds = history.map(h => h.entryId);
  const entries = await fetchContentEntries(contentEntries().id(entryIds));

  for (let i = 0; i < history.length; i += 1) {
    const historyEntry = history[i];
    const contentEntry = entries.find(e => e.id === historyEntry.entryId);

    if (contentEntry !== undefined) {
      // Format the timestamp according the the user's current locale and time zone.
      const timestamp = DateTime.fromISO(historyEntry.dateUpdated).toLocaleString(
        DateTime.DATETIME_SHORT
      );
      result.push({
        contentEntry,
        historyId: historyEntry.id,
        timestamp,
        accesses: historyEntry.accesses
      });
    }
  }
  return result;
}
