<template>
  <div v-if="!guest">
    <icon-label-button
      v-if="display === 'iconLabel'"
      :url="'/groove-trainer'"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :tooltip="'Go to the Groove Trainer'"
      :color="color"
    />
    <link-icon-button
      v-else-if="display === 'linkIcon'"
      :url="'/groove-trainer'"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :size="'sm'"
      :tooltip="'Go to the Groove Trainer'"
      :color="color"
    />
    <standard-button
      v-else-if="display === 'standard'"
      :url="'/groove-trainer'"
      :new-tab="true"
      :icon="icon"
      :label="label"
      :size="'sm'"
      :tooltip="'Go to the Groove Trainer'"
      :color="color"
    />
    <icon-button
      v-else
      :icon="icon"
      :tooltip="'Go to the Groove Trainer'"
      :url="'/groove-trainer'"
    />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import IconLabelButton from '../../../../core/button/IconLabelButton.vue';
import LinkIconButton from '../../../../core/button/LinkIconButton.vue';
import IconButton from '../../../../core/button/IconButton.vue';
import StandardButton from '../../../../core/button/StandardButton.vue';
import {faTriangleInstrument} from '@fortawesome/pro-solid-svg-icons';
import {ButtonColor} from '../../../../core/button/implementation/utils';

export type ContentEntryHeaderGroovetrainerType = 'linkIcon' | 'icon' | 'iconLabel' | 'standard';

export default defineComponent({
  components: {
    IconLabelButton,
    LinkIconButton,
    IconButton,
    StandardButton
  },
  props: {
    guest: {type: String, required: true},
    display: {type: String as PropType<ContentEntryHeaderGroovetrainerType>, default: 'iconLabel'},
    color: {type: String as PropType<ButtonColor>, default: undefined},
    label: {type: String, default: 'Groove Trainer'}
  },
  setup() {
    const icon = computed(() => faTriangleInstrument);
    return {
      icon
    };
  }
});
</script>
