<template>
  <div>
    <page-section :margin-top="'xl'">
      <content-column>
        <standard-heading :size="3">Course Tutors</standard-heading>
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <tutor-reel
        :tutors="course.tutors"
        :width="'normal'"
        :display-mentor-focus-of-study="false"
      />
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Course} from '../../../../backend/content/course/course-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import TutorReel from '../../mentor-seminars/partials/TutorReel.vue';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading,
    TutorReel
  },
  props: {
    course: {type: Object as PropType<Readonly<Course>>, required: true}
  },
  setup() {}
});
</script>
