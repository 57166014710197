<template>
  <modal-dialog :config="config">
    <page-section :margin-top="'xs'">
      <standard-heading :center="true" :size="2">Move Bookmark</standard-heading>
    </page-section>

    <page-section :margin-top="'2xs'">
      <centered-column>
        <div class="text-light text-center mb-4">Select destination group:</div>
        <dropdown-menu
          class="w-full py-fl-sm px-fl-md bg-darkest border border-white rounded text-white text-fl-lg"
          :items="destinationGroupMenuItems"
          :on-change="onDestinationGroupSelected"
          title="Move Bookmark group"
        />
      </centered-column>
    </page-section>
  </modal-dialog>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref, watch} from 'vue';
import DropdownMenu from '../../../generic/dropdown-menu/DropdownMenu.vue';
import {DropdownMenuItem} from '../../../generic/dropdown-menu/types';
import PageSection from '../../../core/page/PageSection.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import CenteredColumn from '../../../core/compositions/CenteredColumn.vue';
import ModalDialog from '../../../generic/modal-dialog/ModalDialog.vue';
import {BookmarkNode} from '../types';
import {groupsEqual} from '../utils';
import {ModalDialogConfig} from '../../../vue-composition/modal-dialog/types';
import {MoveBookmarkDialogData} from '../../../vue-composition/bookmark/types';
import {faBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';

export default defineComponent({
  components: {
    PageSection,
    DropdownMenu,
    ModalDialog,
    StandardHeading,
    CenteredColumn
  },
  props: {
    config: {type: Object as PropType<Readonly<ModalDialogConfig>>, required: true},
    bookmarkNode: {type: Object as PropType<Readonly<BookmarkNode>>, required: true},
    group: {type: Object as PropType<Readonly<BookmarkNode>>, required: true},
    groups: {type: Array as PropType<ReadonlyArray<BookmarkNode>>, required: true}
  },
  setup(props) {
    const validDestinationGroups = computed(() => {
      return props.groups.filter(g => {
        return !groupsEqual(g, props.group);
      });
    });
    const destinationGroupMenuItems = computed(() => {
      return validDestinationGroups.value.map(g => {
        const id = g.bookmark ? g.bookmark.id : undefined;
        return {
          value: id,
          text: g.title === undefined ? 'Default' : g.title
        };
      });
    });
    const destinationGroup = ref(validDestinationGroups.value[0] as Readonly<BookmarkNode>);
    const onDestinationGroupSelected = (item: Readonly<DropdownMenuItem>) => {
      const group = props.groups.find(g => {
        if (g.bookmark === undefined) {
          return item.value === undefined;
        }
        return g.bookmark.id === item.value;
      });
      if (group !== undefined) {
        destinationGroup.value = group;
      }
    };

    const updateReturnData = () => {
      const data: MoveBookmarkDialogData = {
        bookmarkNode: props.bookmarkNode,
        group: destinationGroup.value
      };
      props.config.setData(data);
    };

    // Update the return data from the dialog when the group changes.
    watch(destinationGroup, _ => {
      updateReturnData();
    });
    updateReturnData();

    const iconBookmark = computed(() => faBookmark);

    return {
      iconBookmark,
      destinationGroup,
      destinationGroupMenuItems,
      onDestinationGroupSelected
    };
  }
});
</script>
