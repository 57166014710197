<template>
  <page-content v-if="podcast !== undefined">
    <page-section v-if="trails">
      <content-column>
        <content-entry-header-breadcrumb-trail :path="trails.navigationPath" />
      </content-column>
    </page-section>

    <page-section :margin-top="'sm'">
      <content-column>
        <content-entry-header :id="'podcast-title'">{{ podcast.title }}</content-entry-header>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <cluster :gap="'gap-x-fl-lg gap-y-fl-lg'">
          <content-entry-header-tutors :content-entry="podcast" />
          <content-entry-header-post-date :content-entry="podcast" />
          <content-entry-header-bookmark
            :display="'linkIcon'"
            :guest="guest"
            :content-entry="podcast"
            :bookmark="bookmark"
          />
          <content-entry-header-campus
            :guest="guest"
            :content-entry="podcast"
            :display="'linkIcon'"
          />
        </cluster>
      </content-column>
    </page-section>

    <page-section :margin-top="'lg'">
      <content-column>
        <content-entry-video :guest="guest" :content-entry="podcast" :video-type="'content'" />
      </content-column>
    </page-section>

    <page-section v-if="audioUrl !== undefined" :margin-top="'md'">
      <content-column>
        <iframe
          :src="audioUrl"
          loading="lazy"
          width="100%"
          height="200"
          frameborder="0"
          scrolling="no"
          :title="podcast.title"
        ></iframe>
      </content-column>
    </page-section>

    <page-section v-if="topics.length > 0">
      <podcast-topics :topics="topics" />
    </page-section>

    <content-entry-summary :width="'narrow'" :content-entry="podcast" />

    <content-entry-recommendations
      :width="'narrow'"
      :margin-top="'xl'"
      :heading="'Recommended Content'"
      :guest="guest"
      :content-entry="podcast"
      :bookmark="bookmark"
    />
  </page-content>
</template>

<script lang="ts">
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {defineComponent, ref, onMounted, PropType, computed} from 'vue';
import {getPodcastBySlug} from '../../../backend/content/podcast/podcast-query';
import {Podcast} from '../../../backend/content/podcast/podcast-types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {Video} from '../../../backend/video/video-types';
import PodcastTopics from './partials/PodcastTopics.vue';
import Cluster from '../../core/compositions/Cluster.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import ContentEntryHeaderBookmark from '../../content-entry/partials/header/actions/ContentEntryHeaderBookmark.vue';
import ContentEntryHeaderBreadcrumbTrail from '../../content-entry/partials/header/ContentEntryHeaderBreadcrumbTrail.vue';
import ContentEntryHeaderCampus from '../../content-entry/partials/header/actions/ContentEntryHeaderCampus.vue';
import ContentEntryHeaderPostDate from '../../content-entry/partials/header/metadata/ContentEntryHeaderPostDate.vue';
import ContentEntryHeaderTutors from '../../content-entry/partials/header/metadata/ContentEntryHeaderTutors.vue';
import ContentEntryHeader from '../../content-entry/partials/header/ContentEntryHeader.vue';
import ContentEntrySummary from '../../content-entry/partials/body/ContentEntrySummary.vue';
import ContentEntryRecommendations from '../../content-entry/partials/body/ContentEntryRecommendations.vue';
import ContentEntryVideo from '../../content-entry/partials/body/ContentEntryVideo.vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';

export default defineComponent({
  components: {
    PodcastTopics,
    Cluster,
    ContentColumn,
    ContentEntryHeaderBookmark,
    ContentEntryHeaderBreadcrumbTrail,
    ContentEntryHeaderCampus,
    ContentEntryHeaderPostDate,
    ContentEntryHeaderTutors,
    ContentEntryHeader,
    ContentEntrySummary,
    ContentEntryRecommendations,
    ContentEntryVideo,
    PageContent,
    PageSection
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const podcast = ref(undefined as Readonly<Podcast> | undefined);
    const video = ref(undefined as Readonly<Video> | undefined);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);

    const topics = computed(() => {
      if (podcast.value === undefined) {
        return [];
      }
      if (podcast.value.podcastTopics === undefined || podcast.value.podcastTopics === null) {
        return [];
      }
      return podcast.value.podcastTopics.split('\n');
    });

    const bookmark = useBookmark(props.guest);

    const audioUrl = computed(() => {
      if (podcast.value === undefined) {
        return undefined;
      }
      const url = podcast.value.resources[0].resourceUrl;
      const dropExtension = url.replace(/\.[^/.]+$/, '');
      return `${dropExtension}?client_source=small_player&iframe=true`;
    });

    onMounted(async () => {
      loader.setLoading(true);
      podcast.value = await getPodcastBySlug(props.slug);
      if (podcast.value !== undefined) {
        trails.value = await useBreadcrumbTrails(podcast.value, props.entryBreadcrumbTrails);
        video.value = podcast.value.videos[0];
        await bookmark.init();
      }
      loader.setLoading(false);
    });

    return {
      bookmark,
      podcast,
      video,
      audioUrl,
      trails,
      topics
    };
  }
});
</script>
