<template>
  <page-content>
    <content-entry-section-summary
      :width="'wide'"
      :center="false"
      :center-summary="true"
      :title="heading"
      :title-width="'wide'"
      :landing-page="landingPage"
    />

    <page-section>
      <tabbed-panel
        ref="tabbedPanelRef"
        :tabs="tabs"
        :icon="iconFolder"
        :icon-selected="iconFolderOpen"
        :radio-button-padding="'pb-fl-sm'"
      >
        <template #extra>
          <link-icon-button
            v-if="!readOnly"
            :label="'New Group'"
            :icon="iconFolderPlus"
            :border="true"
            :color="'dark'"
            :on-click="onAddGroup"
          />
        </template>
        <template v-for="(group, i) in bookmark.groups.value" :key="i" #[`${i}`]>
          <bookmark-group
            :guest="guest"
            :read-only="readOnly"
            :group="group"
            :groups="bookmark.groups.value"
            :reload="load"
            :bookmark="bookmark"
            :card-image-width="cardImageWidth"
          />
        </template>
      </tabbed-panel>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {ref, computed, onMounted, nextTick, defineComponent} from 'vue';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentEntrySectionSummary from '../../content-entry/partials/section/ContentEntrySectionSummary.vue';
import LinkIconButton from '../../core/button/LinkIconButton.vue';
import TabbedPanel from '../../generic/tabbed-panel/TabbedPanel.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import BookmarkGroupComponent from './partials/BookmarkGroup.vue';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {faFolder} from '@fortawesome/pro-solid-svg-icons/faFolder';
import {faFolderOpen} from '@fortawesome/pro-solid-svg-icons/faFolderOpen';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons/faFolderPlus';
import {useLandingPage} from '../../vue-composition/landing-page/landing-page';
import {addBookmarkGroup} from './utils';
import {ImgixImageResponsiveWidth} from '../../core/imgix-image/types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentEntrySectionSummary,
    TabbedPanel,
    LinkIconButton,
    'bookmark-group': BookmarkGroupComponent
  },
  props: {
    guest: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const landingPage = useLandingPage('bookmarks-landing');

    const tabbedPanelRef = ref(null as InstanceType<typeof TabbedPanel> | null);

    const iconFolder = computed(() => faFolder);
    const iconFolderOpen = computed(() => faFolderOpen);
    const iconFolderPlus = computed(() => faFolderPlus);

    // If we are presenting a specific bookmark, its id is in the URL.
    const bookmarkId = computed(() => {
      const s = window.location.href.split('/');
      const id = s[s.length - 1];
      if (!Number.isNaN(parseInt(id, 10))) {
        return `${id}`;
      }
      return undefined;
    });

    const bookmark = useBookmark(props.guest, bookmarkId.value, true);

    const tabs = computed(() => {
      return bookmark.groups.value.map(g => {
        return g.title === undefined ? 'Default' : g.title;
      });
    });

    const readOnly = computed(() => {
      return bookmarkId.value !== undefined;
    });

    const load = async (selectDefaultGroup: boolean = false) => {
      loader.setLoading(true, 'Loading bookmarks...');

      // Reset selection to the default group, if required.
      await bookmark.init(bookmarkId.value);

      // Allow tabbed panel to update itself, then select the default tab (if specified).
      await nextTick();
      if (selectDefaultGroup) {
        if (tabbedPanelRef.value !== null) {
          tabbedPanelRef.value.selectTab(0);
        }
      }

      loader.setLoading(false);
    };

    onMounted(async () => {
      await load();
    });

    const heading = computed(() => {
      return readOnly.value ? 'Shared Bookmarks' : 'My Bookmarks';
    });

    const onAddGroup = async () => {
      const name = prompt('Please enter a name for the new bookmark group!', 'Untitled');
      if (name !== null) {
        loader.setLoading(true, 'Updating your bookmarks');
        await addBookmarkGroup(name);
        loader.setLoading(false);
        await load();
      }
    };

    const cardImageWidth: Readonly<ImgixImageResponsiveWidth> = {
      narrow: 520,
      sm: 690,
      md: 350,
      lg: 350,
      xl: 310,
      '2xl': 370
    };

    return {
      landingPage,
      heading,
      tabbedPanelRef,
      iconFolder,
      iconFolderOpen,
      iconFolderPlus,
      tabs,
      readOnly,
      load,
      onAddGroup,
      bookmark,
      cardImageWidth
    };
  }
});
</script>
