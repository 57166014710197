import {computed, Ref, ref, watch} from 'vue';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {
  getProgress,
  getProgressForChildren,
  getProgressForEntries,
  progressToPercent,
  setProgress
} from '../../../backend/progress/progress-query';
import {ProgressMap} from '../../../backend/progress/progress-types';
import {UseProgressConfig, ProgressComposition, ProgressStatus, CompletedMap} from './types';
import {Mutex} from '../../../utils/synchronise';

const gProgressMutex = new Mutex<void>();

/**
 * Return a composition that holds information about the current user's educational progress
 * with respect to one or more content entries.
 */
export function useProgress(
  guest: string,
  config: Readonly<UseProgressConfig>
): ProgressComposition {
  const progress = ref(0);
  const childProgress = ref({} as Readonly<ProgressMap>);
  const siblingProgress = ref({} as Readonly<ProgressMap>);
  const contentEntry = ref(undefined as Readonly<ContentEntry> | undefined);

  const reload = async () => {
    if (guest || contentEntry.value === undefined || contentEntry.value.preview) {
      return;
    }

    const p = await getProgress(contentEntry.value.id);
    progress.value = progressToPercent(p);

    if (config.childProgress) {
      const c = await getProgressForChildren(contentEntry.value);
      const result: ProgressMap = {};
      contentEntry.value.childEntries.forEach((entry, index) => {
        result[entry.id] = progressToPercent(c[index]);
      });
      childProgress.value = result;
    }

    if (config.siblings !== undefined && config.siblings.value !== undefined) {
      const s = await getProgressForEntries(config.siblings.value.map(e => e.id));
      const result: ProgressMap = {};
      config.siblings.value.forEach((entry, index) => {
        result[entry.id] = progressToPercent(s[index]);
      });
      siblingProgress.value = result;
    }
  };

  const init = async (ce: Readonly<ContentEntry>) => {
    if (guest) {
      return;
    }
    contentEntry.value = ce;
    await reload();
  };

  const set = async (value: ProgressStatus) => {
    await gProgressMutex.dispatch(async () => {
      if (guest || contentEntry.value === undefined || contentEntry.value.preview) {
        return;
      }
      await setProgress(contentEntry.value.id, value === 'completed' ? 1 : 0);
      await reload();
    });
  };

  const setProgressRatio = async (ratio: number) => {
    if (ratio < 0 || ratio > 1) {
      throw new Error('Progress must be in range [0,1]');
    }
    await gProgressMutex.dispatch(async () => {
      if (guest || contentEntry.value === undefined || contentEntry.value.preview) {
        return;
      }
      await setProgress(contentEntry.value.id, ratio);
      await reload();
    });
  };

  return {
    progress,
    childProgress,
    siblingProgress,
    init,
    setProgress: set,
    setProgressRatio
  };
}

export function useProgressMap(guest: string, contentEntries: Ref<ReadonlyArray<ContentEntry>>) {
  const progressMap = ref({} as Readonly<ProgressMap>);
  const completedMap = ref({} as Readonly<CompletedMap>);

  const reload = async () => {
    if (guest || contentEntries.value.length === 0) {
      return;
    }
    const entriesToFetch = contentEntries.value.filter(ce => {
      if (ce.preview) {
        return false;
      }
      return progressMap.value[ce.id] === undefined;
    });
    if (entriesToFetch.length > 0) {
      const progressForEntries = await getProgressForEntries(entriesToFetch.map(e => e.id));

      const progressResult: ProgressMap = {...progressMap.value};
      const completedResult: CompletedMap = {...completedMap.value};
      entriesToFetch.forEach((entry, index) => {
        const p = progressToPercent(progressForEntries[index]);
        progressResult[entry.id] = p;
        completedResult[entry.id] = p === 100;
      });
      progressMap.value = progressResult;
      completedMap.value = completedResult;
    }
  };

  watch(contentEntries, async () => {
    await reload();
  });

  const init = async () => {
    await reload();
  };

  return {
    progressMap,
    completedMap,
    init,
    reload
  };
}

export function useResume(
  progress: Readonly<ProgressComposition>,
  contentEntry: Readonly<ContentEntry>,
  entryTypeLabel: string
) {
  // ### Move this into progress composition? That way, we don't have to take the content entry as argument.
  const started = computed(() => {
    return progress.progress.value > 0;
  });

  const label = computed(() => {
    if (!started.value) {
      return `Start ${entryTypeLabel}`;
    }
    return 'Resume';
  });

  const url = computed(() => {
    if (started.value) {
      const entryId = Object.keys(progress.childProgress.value).find(id => {
        return progress.childProgress.value[id] < 100;
      });
      if (entryId !== undefined) {
        const child = contentEntry.childEntries.find(child => child.id === entryId);
        if (child !== undefined) {
          return child.url;
        }
      }
    }
    return contentEntry.childEntries[0].url;
  });

  return {
    started,
    label,
    url
  };
}
