import {ColumnLayoutConfig} from '../types';

/**
 * Return a "grid-template-columns" style that matches the current screen width.
 *
 * Since CSS grid only allows for having one gap width across the entire grid, we
 * use grid columns to represent the gutters between the sub-columns. Zero-width
 * columns have been inserted between the margin columns and the central column,
 * to simplify layout computations.
 */
export function gridTemplateColumnsCSS(
  config: Readonly<ColumnLayoutConfig>,
  columns: number,
  includeMargin: boolean
) {
  const margin = `minmax(${config.minMarginWidth}px,1fr)`;
  const col = `minmax(0,${config.maxColumnWidth}px)`;
  const gutter = `minmax(0,${config.maxGutterWidth}px)`;
  if (includeMargin) {
    return `grid-template-columns: ${margin} 0 ${`${col} ${gutter} `.repeat(
      columns - 1
    )}${col} 0 ${margin}`;
  }
  return `grid-template-columns: ${`${col} ${gutter} `.repeat(columns - 1)} ${col}`;
}

/**
 * Return a string containing a CSS 'grid-column' property that positions
 * an element inside a column layout.
 *
 * The reason why we need this utility is that the column layout system
 * uses CSS grid columns to represent the gutters between the layout
 * columns (and the reason for this is that the CSS grid gap property must
 * be the same throughout a grid). So, for example, to have an element
 * begin in the 3rd layout column and span 2 layout columns, we need the
 * 'grid-column' property to start at column 3*2=6 and span 2*2=4 CSS
 * columns.
 *
 * @param start Layout column where the element starts.
 * @param span Number of layout columns to cover.
 * @param end Optional: layout column where the element ends. If this is provided, span is ignored.
 * @returns String containting the appropriate CSS 'grid-column' setting.
 */
export function gridColumnCSS(start: number, span: number, end?: number) {
  /*
    The CSS grid-column property specifies the grid line where the area
    starts and the grid line where the area ends; the "span" variant
    specifies the number of grid lines to span. Counting begins at 1.

    DS col             1           2           3           4
                      -4          -3          -2          -1
                   +-------+---+-------+---+-------+---+-------+
                   |       |   |       |   |       |   |       |
                   +-------+---+-------+---+-------+---+-------+
    CSS line       1       2   3       4   5       6   7       8
                  -8      -7  -6      -5  -4      -3  -2      -1

    So, for example, an area that starts at design system (DS) column 2
    and spans 3 columns starts at CSS line 3 and ends at line 8. An area
    that starts at DS column -3 and spans 2 columns starts at CSS line 3
    and ends at CSS line 6.
  */
  const st = start > 0 ? start * 2 - 1 : start * 2;
  if (end !== undefined) {
    const ed = end > 0 ? end * 2 : end * 2 + 1;
    return `grid-column:${st} / ${ed}`;
  }
  if (span === -1) {
    return `grid-column:${st} / -1`;
  }
  return `grid-column:${st}/span ${span * 2 - 1}`;
}
