<template>
  <variants>
    <template #md+>
      <div class="flex flex-col gap-fl-sm">
        <!-- Panic message -->
        <page-section v-if="panicMessage.length > 0" :margin-top="'none'">
          <content-column :width="'wide'">
            <div class="border-2 border-primary rounded-[0.5rem] bg-darkest">
              <div class="p-fl-sm text-fl-xl text-primary font-semibold">{{ panicMessage }}</div>
            </div>
          </content-column>
        </page-section>

        <div class="flex justify-center">
          <div
            :class="`w-full max-w-[1920px] grid overflow-hidden bg-[rgb(20,20,20)]`"
            :style="style"
          >
            <!-- Video panel -->
            <external-link-player
              v-if="externallyHosted"
              :preview="preview"
              :guest="guest"
              :webcast="webcast"
            />
            <vimeo-live-player v-else :preview="preview" :guest="guest" :webcast="webcast" />

            <!-- Chatroll panel -->
            <div class="w-full flex flex-col justify-between flex-nowrap bg-darkest">
              <chatroll
                v-if="chatrollId !== undefined"
                :preview="preview"
                :guest="guest"
                :user-is-admin="userIsAdmin"
                :user-id="userId"
                :user-name="userName"
                :chatroll-id="chatrollId"
                :chatroll-secret-key="chatrollSecretKey"
                class="w-full h-full"
              />
              <div v-else class="w-full h-full bg-darkest"></div>
            </div>
          </div>
        </div>

        <external-link-box v-if="dualHosted" :preview="preview" :guest="guest" :webcast="webcast" />
      </div>
    </template>
    <template #sm->
      <div class="flex flex-col gap-fl-xs">
        <!-- Panic message -->
        <page-section v-if="panicMessage.length > 0" :margin-top="'xs'" :margin-bottom="'xs'">
          <content-column :width="'wide'">
            <div class="border-2 border-primary rounded-[0.5rem] bg-darkest">
              <div class="p-fl-sm text-fl-xl text-primary font-semibold">{{ panicMessage }}</div>
            </div>
          </content-column>
        </page-section>

        <!-- Video panel -->
        <external-link-player
          v-if="externallyHosted"
          :preview="preview"
          :guest="guest"
          :webcast="webcast"
        />
        <vimeo-live-player v-else :preview="preview" :guest="guest" :webcast="webcast" />

        <external-link-box v-if="dualHosted" :preview="preview" :guest="guest" :webcast="webcast" />

        <!-- Chatroll panel (beneath) -->
        <chatroll
          v-if="chatrollId !== undefined"
          :preview="preview"
          :guest="guest"
          :user-is-admin="userIsAdmin"
          :user-id="userId"
          :user-name="userName"
          :chatroll-id="chatrollId"
          :chatroll-secret-key="chatrollSecretKey"
        />
      </div>
    </template>
  </variants>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';
import Variants from '../../core/column-layout/Variants.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import VimeoLivePlayer from './VimeoLivePlayer.vue';
import ExternalLinkPlayer from './ExternalLinkPlayer.vue';
import ExternalLinkBox from './ExternalLinkBox.vue';
import Chatroll from './Chatroll.vue';
import {WebcastComposition} from '../../vue-composition/webcast/types';

export default defineComponent({
  components: {
    Variants,
    PageSection,
    ContentColumn,
    VimeoLivePlayer,
    ExternalLinkPlayer,
    ExternalLinkBox,
    Chatroll
  },
  props: {
    preview: {type: Boolean, required: true},
    guest: {type: String, required: true},
    webcast: {type: Object as PropType<Readonly<WebcastComposition>>, required: true},
    userIsAdmin: {type: String, required: true},
    userId: {type: String, required: true},
    userName: {type: String, required: true},
    chatrollId: {type: String, default: undefined},
    chatrollSecretKey: {type: String, required: true}
  },
  setup(props) {
    const deviceType = useDeviceType();

    /*
      Compute the width of the sidebar and the height of the video component.
      The logic and settings here are based on the original XD design document
      for SBL, where the media browser is shown in a viewport that is 1920
      pixels wide. The sidebar, in that configuration, is 550 pixels wide,
      which gives us an aspect ration between video and sidebar of about 3.5.
      The sidebar and video should have the same height, and the height should
      be set to maximise the area of the video. Because we can assume that
      all videos have the same aspect ratio (16:9), we can compute the height
      in pixels.

      As the viewport width is reduced, however, the forced 3.5 aspect ratio
      between video and sidebar will cause the video (and sidebar) to become
      "too small". At that point, the sidebar is hidden.
    */
    const style = computed(() => {
      if (deviceType.largerThan('md')) {
        const viewportWidth = Math.min(1920, deviceType.viewportWidth.value);
        const sidebarWidth = (550 / 1920) * viewportWidth;
        const videoWidth = viewportWidth - sidebarWidth;
        const height = videoWidth * (9 / 16);
        return `grid-template-columns: 1fr minmax(0, ${sidebarWidth}px); grid-template-rows:${height}px`;
      }
      return 'grid-template-columns: 1fr';
    });

    const panicMessage = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast !== undefined) {
        const message = webcast.webcastPanicMessage;
        if (message !== null && message.length > 0) {
          return message;
        }
      }
      return '';
    });

    const externallyHosted = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast !== undefined) {
        const haveExternalLink =
          webcast.webcastExternalLink !== null && webcast.webcastExternalLink.length > 0;
        const haveVimeoId = webcast.webcastVimeoId !== null && webcast.webcastVimeoId.length > 0;
        const haveEmbedUrl = webcast.webcastEmbedUrl !== null && webcast.webcastEmbedUrl.length > 0;
        if (haveExternalLink && !haveVimeoId && !haveEmbedUrl) {
          return true;
        }
      }
      return false;
    });

    const dualHosted = computed(() => {
      const webcast = props.webcast.webcast.value;
      if (webcast !== undefined) {
        const haveExternalLink =
          webcast.webcastExternalLink !== null && webcast.webcastExternalLink.length > 0;
        const haveVimeoId = webcast.webcastVimeoId !== null && webcast.webcastVimeoId.length > 0;
        const haveEmbedUrl = webcast.webcastEmbedUrl !== null && webcast.webcastEmbedUrl.length > 0;
        if (haveExternalLink && (haveVimeoId || haveEmbedUrl)) {
          return true;
        }
      }
      return false;
    });

    return {
      panicMessage,
      externallyHosted,
      dualHosted,
      style
    };
  }
});
</script>
