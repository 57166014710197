<template>
  <page-section :margin-top="'lg'">
    <content-column :width="width" :justify="justify">
      <standard-heading :size="size" :center="heading.richTextHeadingCenter">
        {{ heading.richTextHeadingText }}
      </standard-heading>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {RichTextBlock, RichTextHeading} from '../../../../backend/rich-text/rich-text-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardHeading
  },
  props: {
    richTextHeading: {type: Object as PropType<RichTextBlock>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const heading = computed(() => {
      return props.richTextHeading as RichTextHeading;
    });
    const size = computed(() => {
      return parseInt(heading.value.richTextHeadingSize);
    });
    return {
      heading,
      size
    };
  }
});
</script>
