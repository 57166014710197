<template>
  <content-entry-index-page
    :guest="guest"
    :section-handle="'mentorSeminars'"
    :sort-schemes="['newest', 'oldest']"
    :center-title="false"
    :display-post-date="true"
  >
    <page-section :margin-top="'sm'">
      <content-column :width="'wide'">
        <standard-button :url="'/mentors'" :label="'Back to Mentors Live'" />
      </content-column>
    </page-section>
  </content-entry-index-page>
</template>

<script lang="ts">
import ContentEntryIndexPage from '../../content-entry/pages/ContentEntryIndexPage.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardButton from '../../core/button/StandardButton.vue';

export default {
  components: {
    ContentEntryIndexPage,
    ContentColumn,
    PageSection,
    StandardButton
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {}
};
</script>
