<template>
  <select
    :id="id"
    :disabled="disabled"
    class="w-full appearance-none bg-transparent"
    @change.prevent="onOptionSelected($event.target as HTMLSelectElement)"
  >
    <option
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      :selected="item.selected"
      class="text-darkest"
    >
      {{ item.text }}
    </option>
  </select>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import {DropdownMenuItem, DropdownMenuItemSelectedCallback} from './types';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown';

export default {
  components: {},
  props: {
    items: {type: Array as PropType<Array<DropdownMenuItem>>, required: true},
    onChange: {type: Function as PropType<DropdownMenuItemSelectedCallback>, required: true},
    disabled: {type: Boolean, default: false},
    id: {type: String, default: undefined}
  },
  setup(props) {
    const onOptionSelected = (target: Readonly<HTMLSelectElement> | null) => {
      if (target !== null) {
        const value = target.value;
        const item = props.items.find((it: DropdownMenuItem) => {
          // We use '==' instead of '===' since the value is allowed to be of any type.
          return it.value === value;
        });
        if (item !== undefined) {
          props.onChange(item);
        }
      }
    };
    const icon = computed(() => faChevronDown);
    return {
      icon,
      onOptionSelected
    };
  }
};
</script>
