import {DateTime, Interval} from 'luxon';
import {AccessPassStatus} from '../../../../backend/access-pass/access-pass-types';
import {CraftId} from '../../../../backend/craft/craft-types';
import {getUpgradeInProgressSourceForCurrentUser} from '../../../../backend/offer/upgrade-offer-query';

export type UpgradePair = {sourceId: CraftId; destinationId: CraftId};

export async function getUpgradesInProgress(accessPassStatuses: ReadonlyArray<AccessPassStatus>) {
  const upgrades: Array<UpgradePair> = [];

  const pending = accessPassStatuses.filter(p => p.status === 'pending');
  if (pending.length > 0) {
    for (let i = 0; i < pending.length; i += 1) {
      const source = await getUpgradeInProgressSourceForCurrentUser(pending[i].slug);
      if (source !== null && source !== undefined) {
        upgrades.push({
          sourceId: source.id,
          destinationId: pending[i].id
        });
      }
    }
  }

  return upgrades;
}

export type AccessPassStatusText = {
  status?: string;
  paymentMethod?: string;
  billing?: string;
  instructions?: string;
  discount?: string;
};

/**
 * Return the number of days remaining on a trial subscription.
 *
 * Braintree customers are charged on the billing date, starting from 9am UTC.
 * Charging is carried out in batches, so the actual time when the payment
 * method is charged is unspecified.
 *
 * The subscription creation date/time is returned as a UTC timestamp; it
 * represents the actual date and time the subscription was created (i.e.,
 * the time is not "rounded down" to the start of the day).
 *
 * The trial length is specified in days. Braintree counts the day the subscription
 * was created as the first day of the trial. So if, for example, a subscription
 * was created on July 13, then the first billing date will be July 27:
 *
 * +--------+--------+     +--------+--------+
 * | Jul 13 | Jul 14 | ... | Jul 26 | Jul 27 |
 * +--------+--------+     +--------+--------+
 *    Day 1    Day 2         Day 14    Bill
 *
 * So if the current date is July 20 then the remaining days - including the
 * current day of July 20, up to and including July 26 - is
 *
 *    27 - 20 = 7
 *
 * But because the start date and the billing date are returned by Braintree as
 * Unix Epoch timestamps, we get can get a delta that is larger or smaller
 * than 7, depending on the time the subscription was created and the time of the
 * billing date. To avoid this problem, we use the set() method on the Luxon
 * DateTime object to "round down" to the start of the day. Note that we still
 * need to use Math.round() on the result since even with the "rounding down"
 * to whole days, Luxon may still return a non-integer result.
 *
 * See
 * https://developer.paypal.com/braintree/articles/guides/recurring-billing/billing-cycles
 */
export function getTrialDaysRemaining(nextBillingDateUTC: number) {
  const now = DateTime.now().set({hour: 0, minute: 0, millisecond: 0});
  const billing = DateTime.fromSeconds(nextBillingDateUTC).set({
    hour: 0,
    minute: 0,
    millisecond: 0
  });
  const daysLeft = Math.round(Interval.fromDateTimes(now, billing).length('days'));
  return daysLeft;
}

export function getAccessPassStatusText(
  accessPass: Readonly<AccessPassStatus>
): Readonly<AccessPassStatusText> {
  // Free passes
  if (accessPass.type === 'free') {
    const endOfTermDate = accessPass.cancelDate;
    if (endOfTermDate !== undefined && endOfTermDate !== null) {
      const dateText = DateTime.fromSeconds(endOfTermDate).toLocaleString(DateTime.DATE_FULL);
      if (accessPass.status === 'inactive') {
        return {
          status: 'Expired',
          billing: `Your free access expired on ${dateText}.`
        };
      }
      return {
        status: 'Active',
        billing: `Your free access expires on ${dateText}.`
      };
    }
    return {
      status: 'Active',
      billing: 'Never expires.'
    };
  }

  // Single purchases
  if (accessPass.type === 'singlePurchase') {
    return {
      status: 'Active',
      billing: 'Never expires.'
    };
  }

  // Subscriptions
  let paymentMethodText = undefined;
  const paymentMethod = accessPass.paymentMethod;
  if (paymentMethod !== null && paymentMethod.length > 0) {
    paymentMethodText = `Charged to ${accessPass.paymentMethod}`;
  }

  const nextBillingDate = accessPass.nextBillingDate;
  let dateText = undefined;
  if (nextBillingDate !== undefined && nextBillingDate !== null) {
    dateText = DateTime.fromSeconds(nextBillingDate).toLocaleString(DateTime.DATE_FULL);
  }

  const cancelDate = accessPass.cancelDate;
  let cancelDateText = undefined;
  if (cancelDate !== undefined && cancelDate !== null) {
    cancelDateText = DateTime.fromSeconds(cancelDate).toLocaleString(DateTime.DATE_FULL);
  }

  let discountText = undefined;
  const discount = accessPass.discount;
  if (discount !== null) {
    if (discount.duration === 'forever') {
      const off =
        discount.amountOff !== null ? `$${discount.amountOff} off` : `${discount.percentOff}% off`;
      discountText = `Your subscription has a discount: ${off}`;
    }
  }

  if (accessPass.status === 'inactive') {
    return {
      paymentMethod: '',
      status: 'Expired or Cancelled'
    };
  }

  if (accessPass.status === 'cancelling') {
    return {
      status: 'Cancelled',
      paymentMethod: paymentMethodText,
      discount: discountText,
      billing: `Your access ends on ${cancelDateText}`
    };
  }

  if (accessPass.status === 'active') {
    const billing = dateText !== undefined ? `Your next billing date is ${dateText}` : undefined;
    return {
      status: 'Active',
      paymentMethod: paymentMethodText,
      discount: discountText,
      billing
    };
  }

  if (accessPass.status === 'pending') {
    const billing = dateText !== undefined ? `The first billing date is ${dateText}` : undefined;
    return {
      status: 'Active',
      paymentMethod: paymentMethodText,
      discount: discountText,
      billing
    };
  }

  if (accessPass.status === 'pastDue') {
    return {
      status: 'Your subscription is about to get cancelled!',
      paymentMethod: paymentMethodText,
      discount: discountText,
      billing:
        dateText !== undefined
          ? `We will attempt to charge your selected payment method again on ${dateText}`
          : undefined,
      instructions:
        'We are unable to charge your payment method. Please check that it has not expired and that it has sufficient funds.'
    };
  }
  if (accessPass.status === 'trial') {
    let daysLeft: number | undefined = undefined;
    if (nextBillingDate !== null) {
      daysLeft = getTrialDaysRemaining(nextBillingDate);
    }
    return {
      status: 'Trial',
      paymentMethod: paymentMethodText,
      discount: discountText,
      billing: daysLeft !== undefined ? `You have ${daysLeft} days left on your trial` : undefined
    };
  }

  return {
    status: undefined,
    paymentMethod: undefined,
    billing: undefined
  };
}
