<template>
  <div
    v-if="insetText !== undefined && insetText.length > 0"
    class="inline-block absolute bottom-2 right-2 p-2 rounded bg-dark text-white"
  >
    {{ insetText }}
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  props: {
    insetText: {type: String, default: undefined}
  }
});
</script>
