<template>
  <div class="text-mid text-sm" aria-label="title">
    <div v-if="historyItem.accesses > 1">Accessed {{ historyItem.accesses }} times.</div>
    <div>Last accessed at {{ historyItem.timestamp }}.</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {HistoryItem} from '../../../user-pages/history/history-utils';

export default defineComponent({
  components: {},
  props: {
    historyItem: {type: Object as PropType<Readonly<HistoryItem>>, required: true}
  },
  setup() {
    return {};
  }
});
</script>
