<template>
  <a
    v-if="deviceType.viewportWidth.value > 320"
    id="trinity-trial-button"
    :class="`py-[5px] px-[10px] w-fit text-base font-semibold text-center whitespace-nowrap text-primary hover:text-black bg-black hover:bg-primary border-2 border-primary rounded-md flex flex-nowrap justify-center`"
    href="/sign-up"
  >
    Join us! FREE Trial
  </a>
  <a
    v-else
    :class="`min-w-[90px] py-[5px] px-[10px] text-sm font-semibold text-center text-primary hover:text-black bg-black hover:bg-primary border-2 border-primary rounded-md flex flex-nowrap justify-center`"
    href="/sign-up"
  >
    <div id="trinity-trial-button" class="flex flex-col">
      <div class="whitespace-nowrap">Join us!</div>
      <div class="whitespace-nowrap">FREE Trial</div>
    </div>
  </a>
</template>

<script lang="ts">
import {useDeviceType} from '../../../vue-composition/device-type/device-type';

export default {
  components: {},
  props: {
    fontSize: {type: String, default: 'text-fl-base'}
  },
  setup() {
    const deviceType = useDeviceType();
    return {
      deviceType
    };
  }
};
</script>
