import {compressToBase64, decompressFromBase64} from 'lz-string';
import {getUrlQueryParams} from './url';

export function getQueryParam<T>(key: string): T | undefined {
  const params = getUrlQueryParams();
  const compressed = params.get(key);
  if (compressed === null) {
    return undefined;
  }
  const decompressed = decompressFromBase64(compressed);
  try {
    const object = JSON.parse(decompressed) as Readonly<T>;
    if (Object.keys(object).length === 0) {
      return undefined;
    }
    return object;
  } catch {
    return undefined;
  }
}

export function updateUrlQueryParam<T>(key: string, object: Readonly<T>) {
  const base64 = compressToBase64(JSON.stringify(object));
  const url = new URL(window.location.toString());
  url.searchParams.set(key, base64);
  history.pushState(null, '', url);
}

export function deleteUrlQueryParam(key: string) {
  const url = new URL(window.location.toString());
  url.searchParams.delete(key);
  history.pushState(null, '', url);
  return;
}
