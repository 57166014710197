import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {CraftQueryBuilder} from '../../../backend/craft/query/craft-query-builder-types';
import {getProductSections} from '../../../backend/product/product-query';
import {parseBreadcrumbTrails} from './implementation/implementation';
import {BreadcrumbTrailComposition, NavigationPath} from './types';
import {breadcrumbTrailToNavigationPath, getNextSibling, getPreviousSibling} from './utils';

/**
 * Given a content entry, return the breadcrumb trail that leads to it,
 * as well as its siblings.
 *
 * @param contentEntry The content entry for which to fetch data.
 * @param siblingSectionHandle The section in which the siblings (and this entry) live.
 * @param additionalFields Additional fields to use when fetching the siblings.
 */
export async function useBreadcrumbTrails<TYPE extends ContentEntry = ContentEntry>(
  contentEntry: Readonly<ContentEntry>,
  breadcrumbTrails: string,
  /** Additional content entry fields to fetch for siblings. */
  additionalFields?: ReadonlyArray<CraftQueryBuilder>
): Promise<BreadcrumbTrailComposition<TYPE>> {
  /* 
    ??? We're assuming that all our entries have one parent only.
    We also assume that the siblings of this entry are all in the same section.
        
    The reason is the current UI design where, for example, the lesson page
    presents siblings in a media browser. We can probably assume that a lesson
    will have a single parent for the foreseeable future, but other entries
    will likely not, e.g., players path practice pieces.
    
    Rewrite this Vue composition + the rest of the breadcrumb trail stuff
    so that it becomes more efficient, generic, and easier to use.
  */
  const productSections = await getProductSections();
  const trails = parseBreadcrumbTrails(breadcrumbTrails);
  const trail = trails[contentEntry.id];
  let siblings: ReadonlyArray<TYPE> | undefined = undefined;
  let nextSibling: Readonly<TYPE> | undefined = undefined;
  let previousSibling: Readonly<TYPE> | undefined = undefined;

  // Siblings
  if (trail !== undefined) {
    const parentEntry = trail[1];
    if (parentEntry !== undefined) {
      /*
        We want to fetch the parent as a content entry, and include its
        children - with additional fields (for the children) if requested.
        Begin by constructing the query component for the `childEntries`
        field (on the parent)...
      */
      const childEntriesField = contentEntries('childEntries', []);
      if (additionalFields) {
        childEntriesField.fields(additionalFields);
      }

      // ...and then fetch the parent content entry with the child field included.
      const ces = await fetchContentEntries(
        contentEntries().id([parentEntry.id]).fields([childEntriesField])
      );

      // We now have the siblings (the entries in the `childEntries` field of the parent).
      const parentContentEntry = ces[0];
      if (parentContentEntry !== undefined) {
        siblings = parentContentEntry.childEntries as Array<TYPE>;
        if (siblings !== undefined) {
          nextSibling = getNextSibling(siblings, contentEntry.id);
          previousSibling = getPreviousSibling(siblings, contentEntry.id);
        }
      }
    }
  }

  let navigationPath: Readonly<NavigationPath> = [];
  if (trail !== undefined && productSections !== undefined) {
    navigationPath = breadcrumbTrailToNavigationPath(trail, productSections);
  }

  return {
    siblings,
    nextSibling,
    previousSibling,
    trail,
    navigationPath
  };
}
