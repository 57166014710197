import {useStore} from '../../../store/store';
import {NotificationPopupConfig} from '../../generic/notification-popup/types';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';

export const DEFAULT_NOTIFICATION_POPUP_DISPLAY_TIME = 4;

export function standardNotification(message: string): Readonly<NotificationPopupConfig> {
  return {
    displayTimeSec: DEFAULT_NOTIFICATION_POPUP_DISPLAY_TIME,
    title: message,
    icon: faCheckCircle,
    iconColor: 'text-pp2'
  };
}

export function useNotificationPopup() {
  const store = useStore();
  return {
    notify: (config: Readonly<NotificationPopupConfig>) => {
      store.dispatch('notificationPopup/addNotification', config);
    }
  };
}
