import {Ref, ref} from 'vue';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';
import {Song} from '../../../../backend/song/song-types';

export type RangeSelectorComposition = {
  min: number;
  max: number;
  start: Ref<string>;
  end: Ref<string>;
  onStartChanged: () => void;
  onEndChanged: () => void;
};

export function useRangeSelection(
  song: Readonly<Song>,
  player: Readonly<LoopPlayer>
): Readonly<RangeSelectorComposition> {
  const minBar = 1;
  const maxBar = Number.parseInt(song.songBars, 10);

  const loopStart = ref(minBar);
  const loopEnd = ref(maxBar);

  /*
      The v-models for the <input> elements we use for the bar range selector component
      are strings (since the <input>:s have type=text). It does mean that we have to
      convert them to numbers (and back again) since the loop player expects numbers.
    */
  const start = ref(`${loopStart.value}`);
  const end = ref(`${loopEnd.value}`);

  const onLoopRangeChanged = (start: number, end: number) => {
    loopStart.value = start;
    loopEnd.value = end;
    // Bar count is zero-based; the audio playback range will be [start, end).
    player.setLoop(loopStart.value - 1, loopEnd.value);
  };

  const onStartChanged = () => {
    let s = parseInt(start.value);
    let e = parseInt(end.value);
    if (Number.isNaN(s)) {
      s = minBar;
    }
    if (s < minBar) {
      s = minBar;
    }
    if (s > maxBar) {
      s = maxBar;
    }
    if (s > e) {
      e = s;
    }
    start.value = `${s}`;
    end.value = `${e}`;
    onLoopRangeChanged(s, e);
  };

  const onEndChanged = () => {
    let s = parseInt(start.value);
    let e = parseInt(end.value);
    if (Number.isNaN(e)) {
      e = maxBar;
    }
    if (e < minBar) {
      e = minBar;
    }
    if (e > maxBar) {
      e = maxBar;
    }
    if (e < s) {
      s = e;
    }
    start.value = `${s}`;
    end.value = `${e}`;
    onLoopRangeChanged(s, e);
  };

  return {
    min: minBar,
    max: maxBar,
    start,
    end,
    onStartChanged,
    onEndChanged
  };
}
