<template>
  <div class="w-full flex justify-center">
    <div
      class="w-full max-w-[600px] h-[40px] grid grid-cols-[1fr_minmax(5px,20px)_1fr_minmax(5px,20px)_1fr] border border-black rounded-md"
    >
      <div class="w-full h-full flex flex-col justify-center">
        <div :class="textStyles[0]">Step 1</div>
      </div>
      <div class="w-full max-w-[20px] h-[38px] flex justify-center">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 30 60"
          preserveAspectRatio="none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="black"
            d="M0 0.0598145C0.664577 0.0617757 1.32147 0.194889 1.92913 0.450736C2.53679 0.706584 3.08197 1.07958 3.53014 1.54613L27.7368 26.7849C28.5491 27.624 29 28.7227 29 29.863C29 31.0034 28.5491 32.1021 27.7368 32.9412L3.53014 57.6813C3.08227 58.1378 2.54046 58.5018 1.9384 58.7509C1.33633 59 0.686858 59.1288 0.0302571 59.1292"
          />
        </svg>
      </div>
      <div class="w-full h-full flex flex-col justify-center">
        <div :class="textStyles[1]">Step 2</div>
      </div>
      <div class="w-full max-w-[20px] h-[38px] flex justify-center">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 30 60"
          preserveAspectRatio="none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="black"
            d="M0 0.0598145C0.664577 0.0617757 1.32147 0.194889 1.92913 0.450736C2.53679 0.706584 3.08197 1.07958 3.53014 1.54613L27.7368 26.7849C28.5491 27.624 29 28.7227 29 29.863C29 31.0034 28.5491 32.1021 27.7368 32.9412L3.53014 57.6813C3.08227 58.1378 2.54046 58.5018 1.9384 58.7509C1.33633 59 0.686858 59.1288 0.0302571 59.1292"
          />
        </svg>
      </div>
      <div class="w-full h-full flex flex-col justify-center">
        <div :class="textStyles[2]">Step 3</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';

export default defineComponent({
  props: {
    step: {type: Number, default: 0}
  },
  setup(props) {
    const textStyles = computed(() => {
      const common = 'px-fl-2xs text-[11px] text-center uppercase';
      switch (props.step) {
        case 0:
          return [
            `${common} text-primary font-bold`,
            `${common} text-black font-medium`,
            `${common} text-black font-medium`
          ];
        case 1:
          return [
            `${common} text-black font-medium`,
            `${common} text-primary font-bold`,
            `${common} text-black font-medium`
          ];
        case 2:
          return [
            `${common} text-black font-medium`,
            `${common} text-black font-medium`,
            `${common} text-primary font-bold`
          ];
        default:
          throw new Error('Invalid step');
      }
    });
    return {
      textStyles
    };
  }
});
</script>
