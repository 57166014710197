<template>
  <page-content>
    <page-section>
      <content-column :width="'narrow'">
        <standard-heading :center="true">Academy Tour</standard-heading>
      </content-column>
    </page-section>
    <page-section :margin-top="'sm'" :margin-bottom="'md'">
      <content-column :width="'narrow'">
        <p class="text-fl-3xl text-light text-center">
          Welcome to The Academy! Make sure you watch your welcome video and complete each of the
          steps below.
        </p>
      </content-column>
    </page-section>

    <member-orientation-step
      v-for="(entry, i) in entries"
      :key="entry.id"
      :guest="guest"
      :entry="entry"
      :counter="i"
      :show-member-huddle-box="i === 0"
      :user-id="userId"
    />

    <page-section :margin-top="'xl'">
      <content-column :width="'medium'">
        <standard-button :label="'Back to My Dashboard'" :url="'/'" />
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageContent from '../../../core/page/PageContent.vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import MemberOrientationStep from '../partials/MemberOrientationStep.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';

export default defineComponent({
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    StandardButton,
    MemberOrientationStep
  },
  props: {
    guest: {type: String, required: true},
    entries: {type: Array as PropType<ReadonlyArray<ContentEntry>>, required: true},
    userId: {type: String, required: true}
  },
  setup() {}
});
</script>
