<template>
  <div>
    <content-column :width="'medium'">
      <page-section>
        <standard-divider class="border-mid" />
      </page-section>

      <page-section>
        <div class="flex flex-wrap md:flex-nowrap gap-x-fl-4xl gap-y-fl-lg">
          <div class="md:w-1/2 w-full">
            <content-entry-video :guest="guest" :content-entry="entry" :video-type="'content'" />
          </div>
          <div class="md:w-1/2 w-full">
            <div class="flex flex-row items-center flex-wrap gap-x-fl-sm gap-y-fl-xs">
              <standard-heading :spot-color="stepColor" :size="2">{{ step }}</standard-heading>
              <pill v-if="completed" :class="`border-progress bg-progress text-black`"
                >STEP COMPLETE</pill
              >
            </div>
            <standard-heading :size="2">{{ entry.title }}</standard-heading>

            <flow :class="'mt-fl-xs'" :gap="8">
              <p class="text-fl-lg text-light">{{ entry.summary }}</p>
              <standard-button :size="'xs'" :label="label" :on-click="complete" />
            </flow>
          </div>
        </div>
      </page-section>
    </content-column>
    <page-section v-if="showMemberHuddleBox" :margin-top="'none'" :margin-bottom="'none'">
      <dashboard-new-member-huddle :width="'medium'" :margin-top="'md'" />
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, PropType, ref} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import StandardHeading from '../../../core/standard-heading/StandardHeading.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import Pill from '../../../generic/pill/Pill.vue';
import ContentEntryVideo from '../../../content-entry/partials/body/ContentEntryVideo.vue';
import ContentEntryVideoDialog from '../../../content-entry/partials/dialogs/ContentEntryVideoDialog.vue';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {useModalDialog} from '../../../vue-composition/modal-dialog/modal-dialog';
import {redirectWithHistory} from '../../../../utils/url';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';
import Flow from '../../../core/compositions/Flow.vue';
import DashboardNewMemberHuddle from '../../../user-pages/dashboard/partials/DashboardNewMemberHuddle.vue';
import {UserFlags} from '../../../../backend/user/user-types';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    StandardDivider,
    StandardHeading,
    StandardButton,
    Pill,
    ContentEntryVideo,
    Flow,
    DashboardNewMemberHuddle
  },
  props: {
    guest: {type: String, required: true},
    entry: {type: Object as PropType<Readonly<ContentEntry>>, required: true},
    counter: {type: Number, required: true},
    showMemberHuddleBox: {type: Boolean, required: true},
    userId: {type: String, required: true}
  },
  setup(props) {
    const userFlags = ref<Readonly<UserFlags> | undefined>(undefined);

    const modalDialog = useModalDialog(ContentEntryVideoDialog);
    const onClick = () => {
      modalDialog.show(
        () => {
          const url = props.entry.resources[0].resourceUrl;
          if (url !== '/orientation') {
            redirectWithHistory(url);
          }
        },
        () => {},
        {
          preview: props.entry.preview,
          guest: props.guest,
          contentEntry: props.entry,
          videoType: 'content',
          resumeAtLastTimestamp: false
        },
        false,
        props.entry.resources[0].resourceTitle
      );
    };

    const completed = ref(false);
    const completedFlag = useLocalStorageFlag(`orientation-${props.entry.slug}`, false, status => {
      completed.value = status;
    });
    completed.value = completedFlag.get();

    const complete = () => {
      completedFlag.toggle();
    };

    const label = computed(() => {
      return completed.value ? 'Reset' : 'Mark Complete';
    });

    const step = computed(() => {
      const stepNumber = props.counter + 1;
      return `Step 0${stepNumber}`;
    });

    const stepColor = computed(() => {
      return {
        text: completed.value ? 'text-pp2' : 'text-primary',
        textHover: completed.value ? 'text-pp2' : 'text-primary',
        bg: 'bg-transparent',
        bgHover: 'bg-transparent',
        border: 'border-transparent',
        borderHover: 'border-transparent'
      };
    });

    return {
      userFlags,
      onClick,
      complete,
      completed,
      label,
      step,
      stepColor
    };
  }
});
</script>
