<template>
  <slot v-if="deviceType.screen.value === 'narrow'" name="narrow" />
  <slot v-if="deviceType.screen.value === 'sm'" name="sm" />
  <slot v-if="deviceType.screen.value === 'md'" name="md" />
  <slot v-if="deviceType.screen.value === 'lg'" name="lg" />
  <slot v-if="deviceType.screen.value === 'xl'" name="xl" />
  <slot v-if="deviceType.screen.value === '2xl'" name="2xl" />

  <slot v-if="deviceType.largerThanOrEqualTo('sm')" name="sm+" />
  <slot v-if="deviceType.largerThanOrEqualTo('md')" name="md+" />
  <slot v-if="deviceType.largerThanOrEqualTo('lg')" name="lg+" />
  <slot v-if="deviceType.largerThanOrEqualTo('xl')" name="xl+" />

  <slot v-if="deviceType.smallerThanOrEqualTo('sm')" name="sm-" />
  <slot v-if="deviceType.smallerThanOrEqualTo('md')" name="md-" />
  <slot v-if="deviceType.smallerThanOrEqualTo('lg')" name="lg-" />
  <slot v-if="deviceType.smallerThanOrEqualTo('xl')" name="xl-" />

  <slot
    v-if="deviceType.screen.value === 'narrow' || deviceType.screen.value === 'sm'"
    name="narrow-sm"
  />
  <slot v-if="deviceType.screen.value === 'sm' || deviceType.screen.value === 'md'" name="sm-md" />
  <slot v-if="deviceType.screen.value === 'md' || deviceType.screen.value === 'lg'" name="md-lg" />
  <slot v-if="deviceType.screen.value === 'lg' || deviceType.screen.value === 'xl'" name="lg-xl" />
  <slot
    v-if="deviceType.screen.value === 'xl' || deviceType.screen.value === '2xl'"
    name="xl-2xl"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useDeviceType} from '../../vue-composition/device-type/device-type';

export default defineComponent({
  components: {},
  setup() {
    const deviceType = useDeviceType();
    return {
      deviceType
    };
  }
});
</script>
