import {ImgixImageConfig, ImgixImageWidth} from '../../../components/core/imgix-image/types';
import {CraftId, CraftSectionHandle} from '../../craft/craft-types';
import {CraftQueryBuilder} from '../../craft/query/craft-query-builder-types';
import {ImageCollection} from '../../image-collection/image-collection-types';
import {findImage} from '../../image-collection/image-collection-utils';
import {getSearchSections} from '../../search/search-query';
import {fetchContentEntries} from './content-entry-query';
import {contentEntries} from './content-entry-query-builder';
import {ContentEntry, CraftQueryBuilderContentEntries} from './content-entry-types';

function findNextSiblingId(
  contentEntry: Readonly<ContentEntry>,
  parent: Readonly<ContentEntry>
): CraftId | undefined {
  const i = parent.childEntries.findIndex(c => c.id === contentEntry.id);
  if (i === -1) {
    return undefined;
  }
  const nextSibling = parent.childEntries[i + 1];
  if (nextSibling !== undefined) {
    return nextSibling.id;
  }
  return undefined;
}

/**
 * Given a content entry C and a list of its parent content entries,
 * return the next logical sibling of C for each parent. The sibling
 * entries are fetched from the same section as C.
 *
 * Example:
 *
 * We have an entry L in the 'lessons' section. L has two parent courses:
 * A is in the 'courses' section and B is in the 'learningPathwaysCourses'
 * section.
 *
 * Given A and B as the two parent content entries, this function would
 * return the next lesson in the A course, as well as the next lesson in
 * the B course.
 */
export async function fetchNextSiblings<TYPE extends ContentEntry>(
  contentEntry: Readonly<ContentEntry>,
  parents: ReadonlyArray<ContentEntry>,
  additionalFields?: ReadonlyArray<CraftQueryBuilder>
): Promise<ReadonlyArray<TYPE | undefined>> {
  // For each parent, get the next sibling id (it may be undefined).
  const siblingIds = parents.map(parent => findNextSiblingId(contentEntry, parent));

  // Get the id:s of the siblings we need to fetch and fetch them.
  const definedSiblingIds = siblingIds.filter(id => id !== undefined) as Array<CraftId>;

  let query = contentEntries().section([contentEntry.sectionHandle]).id(definedSiblingIds);
  if (additionalFields !== undefined) {
    query = query.fields(additionalFields);
  }
  const siblings = await fetchContentEntries<TYPE>(query);

  // Collect the siblings.
  const result: Array<TYPE | undefined> = [];
  siblingIds.forEach(siblingId => {
    if (siblingId === undefined) {
      result.push(undefined);
    } else {
      const siblingEntry = siblings.find(sibling => sibling.id === siblingId);
      result.push(siblingEntry);
    }
  });
  return result;
}

/**
 * Return all pending content entries in the specified section.
 */
export async function fetchPendingContentEntriesInSection(
  sectionHandle: CraftSectionHandle,
  reverse: boolean = false
): Promise<Array<ContentEntry>> {
  try {
    const result = await fetchContentEntries(
      contentEntries().section([sectionHandle]).status(['pending'])
    );
    if (reverse) {
      result.reverse();
    }
    return result;
  } catch (error) {
    throw new Error(
      `Could not fetch pending content entries in section ${sectionHandle}: ${error}`
    );
  }
}

/**
 * Return the N last content entries to be published live (in all search sections).
 */
export async function fetchLatestEntries(n: number): Promise<Array<ContentEntry>> {
  try {
    const sections = await getSearchSections();
    // ??? Use 'fetchContentEntriesUntil' to ensure we always have N entries in the result!
    const result = await fetchContentEntries(
      contentEntries()
        .section(sections.map(s => s.handle))
        .orderBy('postDate DESC')
        .limit(n)
    );
    return result;
  } catch (error) {
    throw new Error(`Could not fetch latest content entries: ${error}`);
  }
}

/**
 * Fetch content entries in pages until they meet a given condition.
 *
 * NOTE: This will omit entries in the section that the current user does not
 * have access to.
 *
 * @param query Content entry query. (NOTE: its limit and offset values will be ignored.)
 * @param moreEntriesAreNeeded Given the entries fetched so far, return true if more
 * entries should be fetched, false otherwise.
 * @param stopAfter Stop fetching after this number of entries (or more) have been fetched.
 * @param entriesPerPage The number of entries to fetch per page.
 */
export async function fetchContentEntriesUntil<T extends ContentEntry>(
  query: Readonly<CraftQueryBuilderContentEntries>,
  moreEntriesAreNeeded: (entries: ReadonlyArray<T>) => boolean,
  stopAfter: number,
  entriesPerPage: number = 10
): Promise<Array<T>> {
  const limit = entriesPerPage;
  let offset = 0;
  let more = true;
  let result: Array<T> = [];
  do {
    /*
      NOTE: The number of entries returned per fetch (= length of the 'page' array 
      below) may be smaller than `limit`, as the current user may not have access 
      to some (or all) of the entries in that batch.
    */
    const page = await fetchContentEntries<T>(query.limit(limit).offset(offset));
    result = result.concat(page);
    offset += limit;
    more = moreEntriesAreNeeded(result) && result.length < stopAfter;
  } while (more);
  return result;
}

/**
 * Return the N last content entries to be published live (in the specified section).
 */
export async function fetchLatestEntriesInSection(
  n: number,
  sectionHandle: CraftSectionHandle
): Promise<Array<ContentEntry>> {
  try {
    // ??? Use 'fetchContentEntriesUntil' to ensure we always have N entries in the result!
    const result = await fetchContentEntries(
      contentEntries().section([sectionHandle]).orderBy('postDate DESC').limit(n)
    );
    return result;
  } catch (error) {
    throw new Error(`Could not fetch latest content entries in section ${sectionHandle}: ${error}`);
  }
}

/**
 * Return the tutors for the specified content entry as a human-readable list.
 */
export function tutorList(entry: Readonly<ContentEntry>): string {
  const tutors = entry.tutors.map(tutor => {
    return tutor.title;
  });
  if (tutors.length === 0) {
    return '';
  }
  if (tutors.length === 1) {
    return tutors[0];
  }
  return tutors.slice(0, 1).join(', ') + ' and ' + tutors.slice(-1);
}

/**
 * Return the levels for the specified content entry as a human-readable list.
 */
export function levelList(entry: Readonly<ContentEntry>): string {
  const levels = entry.levels.map(level => {
    return level.title;
  });
  if (levels !== undefined) {
    return levels.join(', ');
  }
  return '(None)';
}

/**
 * Return the subjects for the specified content entry as a human-readable list.
 */
export function subjectList(entry: Readonly<ContentEntry>): string {
  const subjects = entry.subjects.map(subject => {
    return subject.title;
  });
  if (subjects !== undefined) {
    return subjects.join(', ');
  }
  return '(None)';
}

/**
 * Return a config for the first image in the specified collection
 * that matches one of the specified handles. If no matching image
 * was found, return undefined.
 */
// ??? MOVE
export function getImageConfig(
  imageCollection: Readonly<ImageCollection>,
  handles: ReadonlyArray<string>,
  alt: string,
  width: Readonly<ImgixImageWidth>,
  imgixParams?: {}
): Readonly<ImgixImageConfig> | undefined {
  const img = findImage(imageCollection, handles);
  if (img === undefined) {
    return undefined;
  }
  return {
    path: img.imageFilename,
    alt: img.imageAlternateText ? img.imageAlternateText : alt,
    width,
    imgixParams
  };
}

/**
 * Return a variable-size image suitable for presenting the specified Tutor.
 */
// ??? MOVE
export function getAvatarImage(
  imageCollection: Readonly<ImageCollection>,
  altText: string,
  width: Readonly<ImgixImageWidth>
): Readonly<ImgixImageConfig> | undefined {
  const imgixParams = {fit: 'facearea', facePad: 5, aspectRatio: '1:1'};
  return getImageConfig(imageCollection, ['avatar'], altText, width, imgixParams);
}
