<template>
  <pill
    v-for="(level, i) in contentEntry.levels"
    :key="level.id"
    :class="`${
      i === contentEntry.levels.length - 1 ? 'mr-fl-lg' : 'mr-fl-2xs'
    } border-darkest bg-darkest text-mid`"
  >
    {{ level.title }}
  </pill>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import Pill from '../../../../generic/pill/Pill.vue';

export default defineComponent({
  components: {
    Pill
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true}
  },
  setup() {}
});
</script>
