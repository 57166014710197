import {DateTime} from 'luxon';
import {getAccessPassesForCurrentUser} from '../access-pass/access-pass-query';
import {AccessPass, AccessPassStatus} from '../access-pass/access-pass-types';
import {evaluateConditions} from './conditions/offer-conditions';
import {getAllUpgradeOffers} from './upgrade-offer-query';
import {UpgradeOffer} from './upgrade-offer-types';
import {OfferConditionsContextValue} from './offer-conditions-types';
import {CraftId} from '../craft/craft-types';

function sourceIsValid(
  sourcePass: Readonly<AccessPass>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  const userSource = userAccessPasses.find(p => p.id === sourcePass.id);
  // Cannot upgrade from a pass that isn't owned.
  if (userSource === undefined) {
    return false;
  }
  // Can only upgrade from a pass that is active, pending, or in trial
  if (
    userSource.status !== 'active' &&
    userSource.status !== 'trial' &&
    userSource.status !== 'pending'
  ) {
    return false;
  }
  return true;
}

function getSourcePass(
  offer: Readonly<UpgradeOffer>,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
): Readonly<AccessPass> | undefined {
  const validSources = offer.offerSource.filter(p => sourceIsValid(p, userAccessPasses));
  if (validSources.length === 0) {
    return undefined;
  }
  if (validSources.length > 1) {
    throw new Error('More than one valid source pass');
  }
  return validSources[0];
}

export function upgradeOfferIsActive(
  offer: Readonly<UpgradeOffer>,
  context: OfferConditionsContextValue,
  userSignupDateUTC: number,
  serverDateUTC: number,
  userAccessPasses: ReadonlyArray<AccessPassStatus>
) {
  if (!offer.offerIsActive) {
    return false;
  }

  // Cannot upgrade to a pass already among the ones already owned, even if it's inactive.
  const destinationPass = offer.offerDestination[0];
  if (destinationPass === undefined) {
    return false;
  }
  if (userAccessPasses.find(p => p.id === destinationPass.id) !== undefined) {
    return false;
  }

  // If the user doesn't have a valid source pass, the offer is inactive.
  if (getSourcePass(offer, userAccessPasses) === undefined) {
    return false;
  }

  return evaluateConditions<UpgradeOffer>(
    offer,
    false,
    context,
    userSignupDateUTC,
    serverDateUTC,
    userAccessPasses
  );
}

/**
 * Return a list of available upgrade offers for the current user.
 */
export async function getUpgradeOffersForCurrentUser(
  contexts: ReadonlyArray<OfferConditionsContextValue>,
  userDateCreated: string,
  serverDate: string,
  offerIds: ReadonlyArray<CraftId> = []
): Promise<ReadonlyArray<UpgradeOffer>> {
  const userDateUTC = DateTime.fromISO(userDateCreated).valueOf();
  const serverDateUTC = DateTime.fromISO(serverDate).valueOf();
  const userAccessPasses = await getAccessPassesForCurrentUser();
  const allOffers = await getAllUpgradeOffers();

  const activeOffers = allOffers.filter(offer => {
    const active = contexts.find(context => {
      const activeInContext = upgradeOfferIsActive(
        offer,
        context,
        userDateUTC,
        serverDateUTC,
        userAccessPasses
      );
      return activeInContext;
    });
    const included = offerIds.length === 0 ? true : offerIds.includes(offer.id);
    return active !== undefined && included;
  });

  // Return an offer with a single source pass (the one that is valid for this student).
  const activeOffersWithSource = activeOffers.map(offer => {
    const sourcePass = getSourcePass(offer, userAccessPasses);
    if (sourcePass === undefined) {
      throw new Error('Internal error: no source access pass');
    }
    return {
      ...offer,
      offerSource: [sourcePass]
    };
  });
  return activeOffersWithSource;
}
