<template>
  <mentor-seminar-recording
    v-if="contentEntry !== undefined && trails !== undefined && webcast.webcast.value === undefined"
    :guest="guest"
    :content-entry="contentEntry"
    :bookmark="bookmark"
    :trails="trails"
  />
  <webcast-page
    v-if="contentEntry !== undefined && trails !== undefined && webcast.webcast.value !== undefined"
    :guest="guest"
    :content-entry="contentEntry"
    :bookmark="bookmark"
    :trails="trails"
    :webcast="webcast"
    :user-is-admin="userIsAdmin"
    :user-id="userId"
    :user-name="userName"
    :chatroll-secret-key="chatrollSecretKey"
  />
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, PropType} from 'vue';
import MentorSeminarRecording from './variants/MentorSeminarRecording.vue';
import WebcastPage from '../../webcast-page/WebcastPage.vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {useBreadcrumbTrails} from '../../vue-composition/breadcrumb-trail/breadcrumb-trail';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';
import {useBookmark} from '../../vue-composition/bookmark/bookmark';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {useWebcast} from '../../vue-composition/webcast/webcast';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';

export default defineComponent({
  components: {
    MentorSeminarRecording,
    WebcastPage
  },
  props: {
    guest: {type: String, required: true},
    slug: {type: String as PropType<string>, required: true},
    entryBreadcrumbTrails: {type: String, default: ''},
    userIsAdmin: {type: String, required: true},
    userId: {type: String, required: true},
    userName: {type: String, required: true},
    chatrollSecretKey: {type: String, required: true}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const contentEntry = ref<Readonly<ContentEntry> | undefined>(undefined);
    const trails = ref<Readonly<BreadcrumbTrailComposition> | undefined>(undefined);
    const bookmark = useBookmark(props.guest);
    const webcast = useWebcast();

    onMounted(async () => {
      loader.setLoading(true);
      const result = await fetchContentEntries(
        contentEntries().section(['mentorSeminars']).slug([props.slug]).orderBy('postDate')
      );
      contentEntry.value = result[0];
      if (contentEntry.value !== undefined) {
        trails.value = await useBreadcrumbTrails(contentEntry.value, props.entryBreadcrumbTrails);
        await bookmark.init();
        await webcast.init(contentEntry.value.id);
      }
      loader.setLoading(false);
    });
    return {
      contentEntry,
      trails,
      bookmark,
      webcast
    };
  }
});
</script>
