import {Mutex} from '../../../../utils/synchronise';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

// We need a mutex to avoid multiple YoutubeFrame:s from loading
// the YouTube API simultaneously.
// See https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
const gMutex = new Mutex();

/**
 * Initialize the YouTube Player API.
 * See https://developers.google.com/youtube/iframe_api_reference
 */
async function InitializeAPI() {
  // Use a mutex to avoid several YoutubeFrame:s from initialising the
  // API at the same time.
  await gMutex.dispatch(async () => {
    if (!window.onYouTubeIframeAPIReady) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      if (firstScriptTag === undefined || firstScriptTag === null) {
        throw new Error('No script tags in document');
      }
      const parent = firstScriptTag.parentNode;
      if (parent === undefined || parent === null) {
        throw new Error('First script tag in document has no parent');
      }
      parent.insertBefore(tag, firstScriptTag);

      await new Promise<void>(resolve => {
        window.onYouTubeIframeAPIReady = () => {
          resolve();
        };
      });
    }
  });
}

type Callback = (() => void) | undefined;

async function makeYoutubePlayer(
  elementId: string,
  playCallback: Callback,
  pauseCallback: Callback,
  endCallback: Callback
): Promise<YT.Player> {
  const promise: Promise<YT.Player> = new Promise(resolve => {
    new YT.Player(elementId, {
      events: {
        onReady: event => {
          resolve(event.target);
        },
        onStateChange: event => {
          switch (event.data) {
            case YT.PlayerState.PLAYING:
              if (playCallback) {
                playCallback();
              }
              break;
            case YT.PlayerState.PAUSED:
              if (pauseCallback) {
                pauseCallback();
              }
              break;
            case YT.PlayerState.ENDED:
              if (endCallback) {
                endCallback();
              }
              break;
          }
        }
      }
    });
  });
  return promise;
}

/**
 * This class represents an instance of a YouTube Player.
 */
export class YouTubePlayer {
  private elementId: string;
  private player: any | undefined;
  private playCallback: Callback;
  private pauseCallback: Callback;
  private endCallback: Callback;

  /**
   * Construct a new YouTube player.
   * @param elementId The id of the DOM element that YouTube should replace with its <iframe>.
   */
  constructor(elementId: string) {
    this.elementId = elementId;
    if (this.elementId === undefined) {
      throw new Error('Must provide an element id');
    }

    this.player = undefined;

    this.playCallback = undefined;
    this.pauseCallback = undefined;
    this.endCallback = undefined;
  }

  public async init(): Promise<void> {
    if (this.player !== undefined) {
      return;
    }

    this.player = await new Promise(async resolve => {
      await InitializeAPI();
      const player = await makeYoutubePlayer(
        this.elementId,
        this.playCallback,
        this.pauseCallback,
        this.endCallback
      );
      resolve(player);
    });
  }

  /**
   * Specify which callbacks to use for play, pause, and end video events.
   * Don't forget to call this method before playing the video, or you won't
   * get any callbacks!
   *
   * @param playCallback Function to be called on the 'play' event.
   * @param pauseCallback Function to be called on the 'pause' event.
   * @param endCallback Function to be called on the 'end' event.
   */
  public setCallbacks(playCallback: Callback, pauseCallback: Callback, endCallback: Callback) {
    this.playCallback = playCallback;
    this.pauseCallback = pauseCallback;
    this.endCallback = endCallback;
  }

  /**
   * @returns Current play position, in seconds.
   */
  public getCurrentTime() {
    if (this.player !== undefined) {
      return this.player.getCurrentTime();
    }
    return undefined;
  }

  /**
   * Load a video and begin playback.
   * @param videoId The YouTube id of the video to load.
   * @param timestamp The start play position. (Optional)
   */
  public async loadAndPlay(videoId: string, timestamp: number | undefined) {
    await this.init();
    if (this.player === undefined) {
      throw new Error('No YouTube player');
    }
    this.player.loadVideoById(videoId, timestamp);
  }

  /**
   * Cue a video; do not begin playback.
   * @param videoId The YouTube id of the video to load.
   * @param timestamp The start play position. (Optional)
   */
  public async cue(videoId: string, timestamp: number | undefined) {
    await this.init();
    if (this.player === undefined) {
      throw new Error('No YouTube player');
    }
    this.player.cueVideoById(videoId, timestamp);
  }

  /**
   * Pause the video playback.
   */
  public pause() {
    if (this.player !== undefined) {
      this.player.pauseVideo();
    }
  }

  /**
   * @returns The duration of the currently loaded video, in seconds.
   */
  public getVideoDuration() {
    if (this.player !== undefined) {
      return this.player.getDuration();
    }
    return undefined;
  }
}
