import {fetchEntries} from '../craft/entry/entry-query';
import * as QueryBuilder from '../craft/query/craft-query-builder';
import {RICH_TEXT_FIELD} from '../rich-text/rich-text-query';
import {CancelPage} from './cancel-page-types';

export async function getAllCancelPages(): Promise<ReadonlyArray<CancelPage>> {
  const result = await fetchEntries<CancelPage>(
    QueryBuilder.entries()
      .section(['cancelPages'])
      .fields([
        QueryBuilder.categories('accessPasses'),
        QueryBuilder.plainText('cancelPageCtaHeading'),
        QueryBuilder.plainText('cancelPageCtaCancelButtonLabel').required(),
        QueryBuilder.plainText('cancelPageCtaContinueButtonLabel'),
        QueryBuilder.url('cancelPageCtaContinueButtonUrl'),
        RICH_TEXT_FIELD
      ])
  );
  return result;
}
