<template>
  <svg
    :class="`w-full text-base ${svgClass}`"
    :viewBox="`${viewboxX} ${viewboxY} ${viewboxWidth} ${viewboxHeight}`"
  >
    <text v-if="textSpans === undefined" :x="textX" :y="textY" :class="textClass">{{ text }}</text>
    <text v-else :x="textX" :y="textY" :class="textClass">
      <tspan v-for="(span, index) in textSpans" :key="index" :x="textX" :dy="textSpanDy">
        {{ span }}
      </tspan>
    </text>
  </svg>
</template>

<script lang="ts">
import {PropType, defineComponent} from 'vue';

/**
 * Fitted text component.
 *
 * This component allows you to fit text into a box (and have the text resize with the box).
 * It is based around the idea of rendering text into an SVG canvas, and fit the SVG view
 * box to the text. By keeping the SVG view box constant, the text will resize as the SVG
 * element itself resizes.
 *
 * https://css-tricks.com/fitting-text-to-a-container/#aa-just-use-svg
 *
 * The properties are:
 *
 * - text: A single text string to render. Use this if you have 1 line of text.
 * - text-spans: A number of text rows/spans to render.
 * - text-span-dy: The distance between the text rows/spans (e.g., '1.0em').
 * - text-class: The styling to apply to the text font (e.g., "fill-white stroke-0 font-semibold").
 * - viewbox-x, viewbox-y, viewbox-width, viewbox-height: The SVG viewbox, in SVG coordinates.
 *    The appropriate viewbox settings must be found manually by trial and error.
 * - text-x, text-y: The position (in SVG coordinates) where the text should be drawn.
 *    The appropriate text coordinates must be found manually by trial and error.
 */
export default defineComponent({
  props: {
    text: {type: String, default: undefined},
    textSpans: {type: Array as PropType<ReadonlyArray<string>>, default: undefined},
    textSpanDy: {type: String, default: '1.2em'},
    textClass: {type: String, default: ''},
    /**
     * ### NOTE: The 'svgClass' prop was added to allow a 'text-{color}' Tailwind class to be passed to the <svg> tag.
     * 'text-{color}' is set on the <svg> parent in conjunction with passing 'fill-current', via the 'textClass' prop, on the child <text> tag. This is how the fill color is set.
     * With Tailwind, this should be as simple as setting 'fill-{color}' on the <svg> or <text> tags. As of Tailwind 3.3.2 this doesn't work. If this bug is resolved, this should be refactored.
     */
    svgClass: {type: String, default: ''},
    viewboxX: {type: Number, default: 0},
    viewboxY: {type: Number, default: 0},
    viewboxWidth: {type: Number, required: true},
    viewboxHeight: {type: Number, required: true},
    textX: {type: Number, default: 0},
    textY: {type: Number, default: 0}
  },
  setup() {}
});
</script>
