import * as Joi from 'joi';
import {validatedHttpGet, validatedHttpPost} from '../http';
import {
  VALIDATION_BOOLEAN,
  VALIDATION_OPTIONAL_BOOLEAN,
  VALIDATION_POSITIVE_INTEGER
} from '../validation';
import {UserFlags} from './user-types';
import {getAction, postAction} from '../craft-action';

// ### Replace validatedHttpGet/Post() in functions here with get/postAction()

const USER_FLAGS_VALIDATION_SCHEMA = Joi.object({
  version: VALIDATION_POSITIVE_INTEGER,
  loginSurvey: VALIDATION_OPTIONAL_BOOLEAN,
  dashboardHideWelcome: VALIDATION_OPTIONAL_BOOLEAN,
  dashboardHideQuickGuide: VALIDATION_OPTIONAL_BOOLEAN,
  dashboardHideNewMemberHuddle: VALIDATION_OPTIONAL_BOOLEAN
});

const SET_USER_FLAGS_RESULT_VALIDATION_SCHEMA = Joi.object({
  success: VALIDATION_BOOLEAN
});

const USER_FLAGS_VERSION = 0;

/**
 * Return the set of application flags for the currently logged in user.
 *
 * The flags are used to store state that pertains to the user account,
 * e.g., if the user has logged in for the first time.
 */
export async function getUserFlags(): Promise<Readonly<UserFlags>> {
  const url = `/actions/sbl-module/user/get-user-flags`;
  const result = await validatedHttpGet<UserFlags>(url, USER_FLAGS_VALIDATION_SCHEMA);
  if (result.version !== USER_FLAGS_VERSION) {
    throw new Error('Invalid user flags version');
  }
  return result;
}

/**
 * Set the application flags for the currently logged in user.
 *
 * The flags are used to store state that pertains to the user account,
 * e.g., if the user has logged in for the first time.
 */
export async function setUserFlags(userFlags: Readonly<UserFlags>): Promise<boolean> {
  const url = `/actions/sbl-module/user/set-user-flags`;
  const result = await validatedHttpPost<{success: boolean}>(
    url,
    {
      userFlags: userFlags
    },
    SET_USER_FLAGS_RESULT_VALIDATION_SCHEMA
  );
  return result.success;
}

export async function setSkillLevelForCurrentUser(level: number): Promise<boolean> {
  const url = `/actions/sbl-module/user/set-skill-level-for-current-user`;
  const result = await postAction<boolean>(url, {level}, VALIDATION_BOOLEAN);
  return result;
}

export async function clearSkillLevelForCurrentUser(
  reason: 'noChoice' | 'skippedSurvey'
): Promise<boolean> {
  const url = `/actions/sbl-module/user/clear-skill-level-for-current-user`;
  const result = await postAction<boolean>(url, {reason}, VALIDATION_BOOLEAN);
  return result;
}

export type UserSkillLevel =
  | 'noChoice'
  | 'skippedSurvey'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9';
export async function getSkillLevelOfCurrentUser(): Promise<UserSkillLevel | null> {
  const url = `/actions/sbl-module/user/get-skill-level-of-current-user`;
  const result = await getAction<UserSkillLevel | null>(
    url,
    Joi.string()
      .valid('noChoice', 'skippedSurvey', '1', '2', '3', '4', '5', '6', '7', '8', '9')
      .optional()
      .allow(null, '')
  );
  return result;
}

export function userIsBeginner(skillLevel: UserSkillLevel | null) {
  switch (skillLevel) {
    case '1':
    case '2':
    case '3':
      return true;
    default:
      return false;
  }
}
