<template>
  <page-section :margin-top="'sm'">
    <content-column :width="width" :justify="justify">
      <div :class="container">
        <!-- eslint-disable vue/no-v-html -->
        <flow
          v-if="bodyLinkPrimary"
          :gap="7"
          class="[&_a]:text-primary [&_a]:underline [&_a:hover]:text-white"
          v-html="body.richTextBodyParagraphs"
        />
        <flow
          v-else
          :gap="7"
          class="[&_a]:text-mid [&_a]:underline [&_a:hover]:text-white"
          v-html="body.richTextBodyParagraphs"
        />
      </div>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {RichTextBlock, RichTextBody} from '../../../../backend/rich-text/rich-text-types';
import PageSection from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import Flow from '../../../core/compositions/Flow.vue';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    Flow
  },
  props: {
    richTextBody: {type: Object as PropType<RichTextBlock>, required: true},
    bodyTextSize: {type: String, default: 'text-fl-lg'},
    bodyTextColor: {type: String, default: 'text-mid'},
    bodyLinkPrimary: {type: Boolean, default: false},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup(props) {
    const body = computed(() => {
      return props.richTextBody as RichTextBody;
    });
    const container = computed(() => {
      const bd = body.value.richTextBodyEmphasis
        ? 'border border-primary border-dashed rounded-[0.5em] p-fl-md'
        : '';
      const ctr = body.value.richTextBodyCenter ? 'flex justify-center text-center' : '';
      const em = body.value.richTextBodyEmphasis ? 'text-primary' : props.bodyTextColor;
      return `${bd} ${ctr} ${props.bodyTextSize} ${em}`;
    });
    return {
      body,
      container
    };
  }
});
</script>
