<template>
  <div>
    <audio
      ref="audioElement"
      :preload="preloadFlag"
      :src="url"
      crossorigin="use-credentials"
      hidden
    >
      <source :src="url" />
    </audio>

    <variants>
      <template #narrow>
        <audio-player-narrow :audio-player="audioPlayer" />
      </template>
      <template #sm>
        <audio-player-small :audio-player="audioPlayer" />
      </template>
      <template #md>
        <audio-player-medium :audio-player="audioPlayer" />
      </template>
      <template #lg>
        <audio-player-small :audio-player="audioPlayer" />
      </template>
      <template #xl+>
        <audio-player-small :audio-player="audioPlayer" />
      </template>
    </variants>
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent, onBeforeUnmount, ref} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import Variants from '../../core/column-layout/Variants.vue';
import AudioPlayerNarrow from './variations/AudioPlayerNarrow.vue';
import AudioPlayerSmall from './variations/AudioPlayerSmall.vue';
import AudioPlayerMedium from './variations/AudioPlayerMedium.vue';
import {useAudioPlayer} from './internals/utils';

export type AudioPlayerOnPlayCallback = () => void;

export default defineComponent({
  components: {
    Variants,
    AudioPlayerNarrow,
    AudioPlayerSmall,
    AudioPlayerMedium
  },
  props: {
    url: {type: String as PropType<CraftUrl>, required: true},
    onPlay: {type: Function as PropType<AudioPlayerOnPlayCallback>, default: undefined},
    /**  Set to true to load the audio element directly (do not wait for play to be pressed). */
    preload: {type: Boolean, default: true}
  },
  setup(props) {
    // Reference to the audioElement element in the template; see
    // https://v3.vuejs.org/guide/composition-api-template-refs.html
    // https://v3.vuejs.org/guide/typescript-support.html#typing-template-refs
    const audioElement = ref(null as InstanceType<typeof HTMLAudioElement> | null);

    const audioPlayer = useAudioPlayer(audioElement, props.url);

    const preloadFlag = computed(() => {
      return props.preload ? 'auto' : 'none';
    });

    onBeforeUnmount(() => {
      audioPlayer.onBeforeUnmount();
    });
    return {
      audioElement,
      preloadFlag,
      audioPlayer
    };
  }
});
</script>
