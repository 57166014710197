<template>
  <page-section v-if="showBeginnerJourney" :margin-top="marginTop">
    <content-column :width="width">
      <div class="p-fl-lg flex flex-row border rounded-xl border-[rgb(124,255,174)]">
        <div class="flex flex-1 flex-col justify-center">
          <!-- "NEW" pill -->
          <pill class="my-fl-md bg-white text-darkest">NEW</pill>

          <!-- Heading -->
          <div class="text-white text-fl-4xl font-semibold mb-fl-md">Beginner Journey</div>

          <!-- Tutor portraits -->
          <cluster v-if="config.length > 0" class="mb-fl-lg" :gap="gap">
            <cluster :wrap="false" :gap="gap">
              <div v-for="(tutor, index) in config" :key="index" :class="portraitSize">
                <portrait-button
                  :url="tutor.url"
                  :config="tutor.img"
                  :tooltip="tutor.title"
                  tabindex="-1"
                />
              </div>
            </cluster>
            <cluster :wrap="false" :gap="gap">
              <link-button
                v-for="tutor in config"
                :key="tutor.id"
                :size="buttonSize"
                :url="tutor.url"
                :tooltip="tutor.title"
                :label="tutor.title"
              />
            </cluster>
          </cluster>

          <!-- Body text -->
          <div class="text-fl-lg mb-fl-xl">
            <p>
              The Beginner Journey is the place to get started on the bass! Whether you’re brand new
              to the instrument or need to refine essential skills, you’ll find all the lessons,
              songs, and tools right here without having to search.
            </p>
          </div>

          <!-- Button group -->
          <div class="flex flex-col">
            <standard-button
              :size="'sm'"
              :label="'Go to Beginner Journey'"
              :url="'/beginner-journey'"
              class="mb-fl-sm"
            />
            <link-button
              :size="'sm'"
              :on-click="onHideBeginnerJourney"
              :label="'Don\'t show me this again'"
              :underline="true"
            />
          </div>
        </div>

        <!-- Image on right-hand side of card -->
        <div class="hidden sm:flex justify-end pl-fl-sm-4xl">
          <imgix-image :config="imageConfig" class="rounded-[0.5rem] object-cover" />
        </div>
      </div>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {ButtonSize} from '../../../core/button/implementation/utils';
import {computed, defineComponent, onMounted, PropType, ref} from 'vue';
import {ImgixImageConfig} from '../../../core/imgix-image/types';
import {PageSectionMargin} from '../../../core/page/PageSection.vue';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import PageSection from '../../../core/page/PageSection.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Pill from '../../../generic/pill/Pill.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import PortraitButton from '../../../core/button/PortraitButton.vue';
import {deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {useFullScreenLoader} from '../../../vue-composition/loader/loader';
import {Tutor} from '../../../../backend/tutor/tutor-types';
import {getTutorsBySlug} from '../../../../backend/tutor/tutor-query';
import {getAvatarImage} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';
import {
  getSkillLevelOfCurrentUser,
  userIsBeginner,
  UserSkillLevel
} from '../../../../backend/user/user-query';

export default defineComponent({
  components: {
    PageSection,
    Cluster,
    ContentColumn,
    Pill,
    ImgixImage,
    StandardButton,
    LinkButton,
    PortraitButton
  },
  props: {
    guest: {type: String, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'medium'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'xl'}
  },
  setup(props) {
    const loader = useFullScreenLoader();
    const userSkillLevel = ref<UserSkillLevel | null>(null);

    const showBeginnerJourneyFlag = useLocalStorageFlag('beginner-journey-card', true);
    const showBeginnerJourney = computed(() => {
      if (userIsBeginner(userSkillLevel.value)) {
        return showBeginnerJourneyFlag.get();
      }
      return false;
    });
    const onHideBeginnerJourney = () => {
      showBeginnerJourneyFlag.set(false);
    };

    const imageConfig: Readonly<ImgixImageConfig> = {
      path: 'content/LP-Thumb-Beg-new.jpg',
      alt: 'Beginner Journey',
      width: {
        '2xl': 306,
        lg: 306,
        md: 306,
        narrow: 280,
        sm: 280,
        xl: 306
      },
      imgixParams: {fit: 'crop', aspectRatio: '306:415'}
    };

    const gap = deviceTypeSwitch({
      narrow: 'gap-fl-xs',
      sm: 'gap-fl-sm'
    });
    const portraitSize = deviceTypeSwitch({
      narrow: 'w-[26px] h-[26px]',
      md: 'w-[46px] h-[46px]'
    });
    const buttonSize = deviceTypeSwitch<ButtonSize>({
      narrow: 'xs',
      md: 'md'
    });

    const tutors = ref<ReadonlyArray<Tutor>>([]);

    onMounted(async () => {
      loader.setLoading(true);
      if (!props.guest) {
        userSkillLevel.value = await getSkillLevelOfCurrentUser();
      }
      tutors.value = await getTutorsBySlug(['scott-devine', 'ian-allison']);
      loader.setLoading(false);
    });

    const config = computed(() => {
      return tutors.value.map(tutor => {
        const img = getAvatarImage(tutor.imageCollection, tutor.title, {
          narrow: 26,
          md: 46
        });
        if (img === undefined) {
          throw new Error('No avatar image');
        }
        return {
          ...tutor,
          img
        };
      });
    });

    return {
      imageConfig,
      gap,
      portraitSize,
      buttonSize,
      config,
      showBeginnerJourney,
      onHideBeginnerJourney,
      userSkillLevel
    };
  }
});
</script>
