import {computed, Ref} from 'vue';
import {DeviceTypeComposition} from '../device-type/device-type';
import {getColumnLayoutConfig} from '../../core/column-layout/config';

export type ColumnLayoutConfiguration = {
  marginWidth: number;
  columnWidth: number;
  gutterWidth: number;
};

export type ColumnLayoutComposition = {
  config: Ref<Readonly<ColumnLayoutConfiguration>>;
};

/**
 * Return a Vue composition that calculates the current margin,
 * column, and gutter widths in our column layout system.
 *
 * This composition is intended as a utility for situations where
 * the CSS grid produced by the <columns> component cannot
 * be used. Use it with care! It is usually a lot simpler to work
 * with CSS grid than it is to position elements explicitly with
 * this composition.
 */
export function useColumnLayout(
  deviceType: Readonly<DeviceTypeComposition>
): Readonly<ColumnLayoutComposition> {
  const settings = computed(() => {
    const columns = deviceType.screen.value === 'narrow' ? 4 : 12;
    const config = getColumnLayoutConfig(columns, deviceType.screen.value);

    const s = deviceType.viewportWidth.value;
    const m = config.minMarginWidth;
    const c = config.maxColumnWidth;
    const g = config.maxGutterWidth;
    const n = config.layoutSystemColumns;

    /*
      Compute margin, column and gutter widths; this calculation is based on observed
      behaviour of the CSS grid layout on Chrome. For our column layout, it will
      extend the margins if there is room. Otherwise, it will shrink the columns
      until everything fits. The reason that it's this simple is that we always
      switch breakpoints before the column widths become smaller than the gutter;
      if that were to happen Chrome will probably start reducing both columns AND gutters.
    */

    // Get the width maxed-out columns and gutters, plus the margins at their minimum width.
    const content = c * n + g * (n - 1);
    const max = content + m * 2;

    if (max < s) {
      // If the content is smaller than the width of the page, stretch the margins.
      return {
        columnWidth: c,
        gutterWidth: g,
        /*
          1920 here because that is the maximum width of any page on the SBL website.
          (Though some elements do extend to the edge of the viewport,
          regardless of how wide it is.)
        */
        marginWidth: (Math.min(s, 1920) - content) / 2
      };
    } else {
      // ...otherwise, reduce the width of the columns until everything fits.
      return {
        columnWidth: c - (max - s) / n,
        gutterWidth: g,
        marginWidth: m
      };
    }
  });

  return {
    config: settings
  };
}
