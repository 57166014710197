<template>
  <feature-card id="campus-card">
    <imgix-image :config="imageConfig" class="w-full bg-dark" />
    <div class="p-fl-lg">
      <div class="mb-fl-md text-fl-2xl text-white text-center font-semibold">
        Join us in the Campus!
      </div>
      <div class="mb-fl-md text-fl-xl text-light text-center">
        The heart of the SBL community, it's a safe space to help you feel less alone on your
        journey. Ask questions, share your playing, get feedback, join group challenges, try
        member-made lessons, transcriptions and more!
      </div>
      <div class="flex justify-center">
        <link-button
          :url="'https://campus.scottsbasslessons.com/forum/205-beginner-journey/'"
          :label="'Go to the Campus'"
          :new-tab="true"
          :color="'lightest'"
        />
      </div>
    </div>
  </feature-card>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import FeatureCard from '../../../generic/feature-card/FeatureCard.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {ImgixImageConfig} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    FeatureCard,
    LinkButton,
    ImgixImage
  },
  setup() {
    const imageConfig: Readonly<ImgixImageConfig> = {
      path: 'content/beginner-campus.jpg',
      alt: 'SBL Campus',
      width: {
        narrow: 470,
        sm: 650,
        md: 390,
        lg: 500,
        xl: 600,
        '2xl': 560
      }
    };
    return {
      imageConfig
    };
  }
});
</script>
