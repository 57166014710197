<template>
  <div>
    <audio ref="audioElement" :src="url" crossorigin="anonymous" hidden>
      <source :src="url" />
    </audio>
    <icon-button
      v-if="iconOnly"
      :on-click="togglePlay"
      :label="label"
      :icon="playPauseButtonIcon"
      :tooltip="playPauseButtonTooltip"
    />
    <link-icon-button
      v-else
      :size="'sm'"
      :on-click="togglePlay"
      :label="label"
      :icon="playPauseButtonIcon"
      :tooltip="playPauseButtonTooltip"
    />
  </div>
</template>

<script lang="ts">
import {ref, PropType, defineComponent, watch, computed} from 'vue';
import {useSync} from '../../vue-composition/sync/sync';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {HMSToString, secToHMS} from '../../../utils/duration';
import LinkIconButton from '../../core/button/LinkIconButton.vue';
import IconButton from '../../core/button/IconButton.vue';
import {faPauseCircle} from '@fortawesome/pro-solid-svg-icons/faPauseCircle';
import {faPlayCircle} from '@fortawesome/pro-solid-svg-icons/faPlayCircle';

export type AudioButtonOnPlayCallback = () => void;

export default defineComponent({
  components: {
    IconButton,
    LinkIconButton
  },
  props: {
    url: {type: String as PropType<CraftUrl>, required: true},
    label: {type: String, required: true},
    iconOnly: {type: Boolean, default: false},
    onPlay: {type: Function as PropType<AudioButtonOnPlayCallback>, default: undefined}
  },
  setup(props) {
    // Reference to the audioElement element in the template; see
    // https://v3.vuejs.org/guide/composition-api-template-refs.html
    // https://v3.vuejs.org/guide/typescript-support.html#typing-template-refs
    const audioElement = ref(null as InstanceType<typeof HTMLAudioElement> | null);

    const isPlaying = ref(false);
    const progressSeconds = ref(0);
    const totalDuration = ref(0);
    const minPlaySpeed = 0.5;
    const maxPlaySpeed = 1.2;
    const playSpeedIncrement = 0.1;
    const currentPlaySpeed = ref(1);
    const isRepeating = ref(false);

    const playPos = computed(() => {
      return progressSeconds.value / totalDuration.value;
    });
    const playPosDisplay = computed(() => {
      return HMSToString(secToHMS(progressSeconds.value));
    });
    const durationDisplay = computed(() => {
      return HMSToString(secToHMS(totalDuration.value));
    });
    const speedDisplay = computed(() => {
      return `${Math.round(currentPlaySpeed.value * 100)}%`;
    });
    const repeatLabel = computed(() => {
      return isRepeating.value ? 'REPEAT: ON' : 'REPEAT: OFF';
    });

    const playPauseButtonIcon = computed(() => {
      return isPlaying.value ? faPauseCircle : faPlayCircle;
    });
    const playPauseButtonTooltip = computed(() => {
      return isPlaying.value ? 'Pause' : 'Play';
    });

    // See https://stackoverflow.com/questions/59125857/how-to-watch-props-change-with-vue-composition-api-vue-3
    watch(
      () => props.url,
      _ => {
        isPlaying.value = false;
        currentPlaySpeed.value = 1;
      }
    );

    watch(audioElement, elem => {
      if (elem !== null) {
        elem.addEventListener('play', () => {
          isPlaying.value = true;
        });
        elem.addEventListener('pause', () => {
          isPlaying.value = false;
        });
        elem.addEventListener('timeupdate', () => {
          progressSeconds.value = Math.floor(elem.currentTime);
        });
        elem.addEventListener('durationchange', () => {
          totalDuration.value = Math.floor(elem.duration);
        });
        elem.addEventListener('ended', () => {
          if (isRepeating.value) {
            elem.currentTime = 0;
            elem.play();
          }
        });
      }
    });

    const sync = useSync('media', props.url, async () => {
      if (audioElement.value !== null) {
        audioElement.value.pause();
        isPlaying.value = false;
      }
    });

    const togglePlay = () => {
      sync.lock();
      if (audioElement.value !== null) {
        if (isPlaying.value) {
          audioElement.value.pause();
          isPlaying.value = false;
        } else {
          audioElement.value.play();
          isPlaying.value = true;
        }
      }
    };

    const toggleRepeat = () => {
      isRepeating.value = !isRepeating.value;
    };

    const onSpeedUp = () => {
      if (audioElement.value !== null) {
        let newSpeed = currentPlaySpeed.value;
        newSpeed += playSpeedIncrement;
        if (newSpeed > maxPlaySpeed) {
          newSpeed = maxPlaySpeed;
        }
        currentPlaySpeed.value = newSpeed;
        audioElement.value.playbackRate = currentPlaySpeed.value;
      }
    };
    const onSpeedDown = () => {
      if (audioElement.value !== null) {
        let newSpeed = currentPlaySpeed.value;
        newSpeed -= playSpeedIncrement;
        if (newSpeed < minPlaySpeed) {
          newSpeed = minPlaySpeed;
        }
        currentPlaySpeed.value = newSpeed;
        audioElement.value.playbackRate = currentPlaySpeed.value;
      }
    };

    const onScrub = (pos: number) => {
      if (audioElement.value !== null) {
        audioElement.value.currentTime = pos * totalDuration.value;
      }
    };

    return {
      audioElement,
      sync,
      playPos,
      playPosDisplay,
      onScrub,
      durationDisplay,
      speedDisplay,
      playPauseButtonIcon,
      playPauseButtonTooltip,
      togglePlay,
      toggleRepeat,
      repeatLabel,
      onSpeedUp,
      onSpeedDown
    };
  }
});
</script>
