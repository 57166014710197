<template>
  <div v-if="ribbonIcon !== undefined" class="ribbon absolute top-0 right-0 bg-progress text-black">
    <font-awesome-icon
      :icon="ribbonIcon"
      class="absolute top-[10%] right-[10%] w-[38%] h-[38%]"
    ></font-awesome-icon>
  </div>
</template>
<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    ribbonIcon: {type: Object as PropType<IconDefinition>, default: undefined}
  }
});
</script>

<style scoped>
/* CSS class (rather than Tailwind) since Tailwind does not support clip-path */
.ribbon {
  /*
    We want the ribbon width to be 20% of the card width. Then, we compute
    the ribbon height: the cards are assumed to be in 16/9 aspect ratio, so
    the height becomes: 20 * (16/9) = 35.5%. (We round it down.)
  */
  width: 20%;
  height: 35%;

  clip-path: polygon(0 0, 100% 100%, 100% 0);
}
</style>
