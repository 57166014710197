<template>
  <repel :gap="'gap-0'" :wrap="false" :class="color">
    <cluster :gap="'gap-fl-xs'" :wrap="false">
      <font-awesome-icon class="text-fl-3xl" :icon="iconRepeat"></font-awesome-icon>
      <div class="text-fl-lg uppercase">Repeat</div>
    </cluster>
    <link-button
      :disabled="disabled"
      :color="'lightest'"
      :on-click="onLoopToggle"
      :tooltip="'Toggle Repeat'"
      :label="value"
    />
  </repel>
</template>

<script lang="ts">
import {PropType, computed, ref} from 'vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import Repel from '../../../core/compositions/Repel.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faRepeat} from '@fortawesome/pro-solid-svg-icons/faRepeat';
import {LoopPlayer} from '../../../../utils/loop-player/loop-player';

export default {
  components: {
    LinkButton,
    Cluster,
    Repel,
    FontAwesomeIcon
  },
  props: {
    player: {type: Object as PropType<Readonly<LoopPlayer>>, required: true},
    disabled: {type: Boolean, default: false}
  },
  setup(props) {
    const iconRepeat = computed(() => faRepeat);

    const loop = ref(true);
    const value = computed(() => {
      return loop.value ? 'ON' : 'OFF';
    });
    const onLoopToggle = () => {
      const status = !props.player.getLoopState();
      props.player.setLoopState(status);
      loop.value = status;
    };

    const color = computed(() => (props.disabled ? 'text-dark' : 'text-lightest'));

    return {
      color,
      iconRepeat,
      value,
      onLoopToggle
    };
  }
};
</script>
