<template>
  <page-content>
    <page-section>
      <content-column>
        <standard-heading :size="1">Privacy Policy</standard-heading>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Last updated June 2020</standard-heading>
          <p>
            Welcome to Scott's Bass Lessons! We're excited you've chosen us to take you on your
            learning journey. Our sole aim is to support you to become the best bass player you can
            be and we want you to love the experience. The following document is designed to help
            you by explaining in clear English our responsibilities in relation to privacy. We don't
            want anything to get in the way of an amazing experience!
          </p>
          <p>
            Your privacy is very important to us. Accordingly, we have developed this policy in
            order for you to understand how we collect, use, communicate and make use of personal
            information. The following outlines our privacy policy.
          </p>
          <p>
            This privacy notice provides you with details of how we collect and process your
            personal data through your use of our site:
          </p>
          <p>https://www.scottsbasslessons.com</p>
          <p class="text-lightest font-semibold">
            By providing us with your data, you warrant to us that you are over 13 years of age.
          </p>
          <p>
            Devine Music Limited trading as Scott's Bass Lessons is the Data Controller and we are
            responsible for your personal data (referred to as “we”, “us” or “our” in this privacy
            notice).
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Contact Details</standard-heading>
          <p>
            Full name of legal entity: <strong>Devine Music Limited</strong><br />
            Email address: <strong>support@scottsbasslessons.com</strong><br />
            Postal address:
            <b> Unit 12 Ratcliffe Mills, 280 Tong Road, Leeds, LS12 3BG, United Kingdom </b>
          </p>
          <p>
            It is very important that the information we hold about you is accurate and up to date.
            Please let us know if at any time your personal information changes by emailing us at:
            <strong> support@scottsbasslessons.com </strong>
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">What data do we collect about you and why?</standard-heading>
          <p>
            Personal data means any information capable of identifying an individual. It does not
            include anonymised data. We may process the following categories of personal data about
            you:
          </p>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>
              <b>Communication Data</b> that includes any communication that you send to us whether
              that be through the contact form on our website, through email, text, social media
              messaging, social media posting or any other communication that you send us. We
              process this data for the purposes of communicating with you, for record keeping and
              for the establishment, pursuance or defence of legal claims. Our lawful ground for
              this processing is our legitimate interests which in this case are to reply to
              communications sent to us, to keep records and to establish, pursue or defend legal
              claims.
            </li>
            <li>
              <b>Customer Data</b> that includes data relating to any purchases of goods and/or
              services such as your name, title, billing address, delivery address, email address,
              phone number, contact details, purchase details and your card details. We process this
              data to supply the goods and/or services you have purchased and to keep records of
              such transactions. Our lawful ground for this processing is the performance of a
              contract between you and us and/or taking steps at your request to enter into such a
              contract.
            </li>
            <li>
              <b>User Data</b> that includes data about how you use our website and any online
              services together with any data that you post for publication on our website or
              through other online services. We process this data to operate our website and ensure
              relevant content is provided to you, to ensure the security of our website, to
              maintain backups of our website and/or databases and to enable publication and
              administration of our website, other online services and business. Our lawful ground
              for this processing is our legitimate interests which in this case are to enable us to
              properly administer our website and our business.
            </li>
            <li>
              <b>Technical Data</b> that includes data about your use of our website and online
              services such as your IP address, your login data, details about your browser, length
              of visit to pages on our website, page views and navigation paths, details about the
              number of times you use our website, time zone settings and other technology on the
              devices you use to access our website. The source of this data is from our analytics
              tracking system. We process this data to analyse your use of our website and other
              online services, to administer and protect our business and website, to deliver
              relevant website content and advertisements to you and to understand the effectiveness
              of our advertising. Our lawful ground for this processing is our legitimate interests
              which in this case are to enable us to properly administer our website and our
              business and to grow our business and to decide our marketing strategy.
            </li>
            <li>
              <b>Marketing Data</b> that includes data about your preferences in receiving marketing
              from us and our third parties and your communication preferences. We process this data
              to enable you to partake in our promotions such as competitions, prize draws and free
              give-aways, to deliver relevant website content and advertisements to you and measure
              or understand the effectiveness of this advertising. Our lawful ground for this
              processing is our legitimate interests which in this case are to study how customers
              use our products/services, to develop them, to grow our business and to inform our
              marketing decisions.
            </li>
          </ul>
          <p>
            We may use Customer Data, User Data, Technical Data and Marketing Data to deliver
            relevant website content and advertisements to you (including Facebook adverts or other
            display advertisements) and to measure or understand the effectiveness of the
            advertising we serve you. Our lawful ground for this processing is legitimate interests
            which is to grow our business. We may also use such data to send other marketing
            communications to you. Our lawful ground for this processing is either consent or
            legitimate interests (namely to grow our business).
          </p>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>
              <b>Sensitive Data:</b> We do not collect any Sensitive Data about you. Sensitive data
              refers to data that includes details about your race or ethnicity, religious or
              philosophical beliefs, sex life, sexual orientation, political opinions, trade union
              membership, information about your health and genetic and biometric data.
            </li>
          </ul>
          <p>We do not collect any information about criminal convictions and offences.</p>
          <p>
            Where we are required to collect personal data by law, or under the terms of the
            contract between us and you do not provide us with that data when requested, we may not
            be able to perform the contract (for example, to deliver goods or services to you). If
            you don't provide us with the requested data, we may have to cancel a product or service
            you have ordered but if we do, we will notify you at the time.
          </p>
          <p>
            We will only use your personal data for a purpose it was collected for or a reasonably
            compatible purpose if necessary. For more information on this please email us at
            support@scottsbasslessons.com. In case we need to use your details for an unrelated new
            purpose we will let you know and explain the legal grounds for processing.
          </p>
          <p>
            We may process your personal data without your knowledge or consent where this is
            required or permitted by law.
          </p>
          <p>We do not carry out automated decision making or any type of automated profiling.</p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">How we collect your personal data</standard-heading>
          <p>
            We may collect data about you by you providing the data directly to us (for example by
            filling in forms on our site or by sending us emails). We may automatically collect
            certain data from you as you use our website by using cookies and similar technologies.
            Please see our cookie policy below for more details about this.
          </p>
          <p>
            We may receive data from third parties such as analytics providers such as Google based
            outside the EU, advertising networks such as Facebook based outside the EU, such as
            search information providers such as Google based outside the EU, providers of
            technical, payment and delivery services, such as data brokers or aggregators.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Marketing communications</standard-heading>
          <p>
            Our lawful ground of processing your personal data to send you marketing communications
            is either your consent or our legitimate interests (namely to grow our business).
          </p>
          <p>
            Under the Privacy and Electronic Communications Regulations, we may send you marketing
            communications from us if (i) you made a purchase or asked for information from us about
            our goods or services or (ii) you agreed to receive marketing communications and in each
            case you have not opted out of receiving such communications since. Under these
            regulations, if you are a limited company, we may send you marketing emails without your
            consent. However you can still opt out of receiving marketing emails from us at any
            time.
          </p>
          <p>
            Before we share your personal data with any third party for their own marketing purposes
            we will get your express consent. However please note we do not and never have shared
            our email list with third parties.
          </p>
          <p>
            You can ask us or third parties to stop sending you marketing messages at any time by
            following the opt-out links at the bottom of any marketing message sent to you OR by
            emailing us at <strong>support@scottsbasslessons.com.</strong>
          </p>
          <p>
            If you opt out of receiving marketing communications this opt-out does not apply to
            personal data provided as a result of other transactions, such as purchases, warranty
            registrations etc.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Disclosures of your personal data</standard-heading>
          <p>We may have to share your personal data with the parties set out below:</p>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>Professional advisers including lawyers, bankers, auditors and insurers</li>
            <li>Service providers who provide IT and system administration services</li>
          </ul>
          <p>
            We require all third parties to whom we transfer your data to respect the security of
            your personal data and to treat it in accordance with the law. We only allow such third
            parties to process your personal data for specified purposes and in accordance with our
            instructions.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">International transfers</standard-heading>
          <p>
            Countries outside of the European Economic Area (EEA) do not always offer the same
            levels of protection to your personal data, so European law has prohibited transfers of
            personal data outside of the EEA unless the transfer meets certain criteria.
          </p>
          <p>
            Many of our third parties service providers are based outside the European Economic Area
            (EEA) so their processing of your personal data will involve a transfer of data outside
            the EEA.
          </p>
          <p>
            Whenever we transfer your personal data out of the EEA, we do our best to ensure a
            similar degree of security of data by ensuring at least one of the following safeguards
            is in place:
          </p>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>
              We will only transfer your personal data to countries that the European Commission
              have approved as providing an adequate level of protection for personal data by; or
            </li>
            <li>
              Where we use certain service providers, we may use specific contracts or codes of
              conduct or certification mechanisms approved by the European Commission which give
              personal data the same protection it has in Europe; or
            </li>
            <li>
              If we use US-based providers that are part of EU-US Privacy Shield, we may transfer
              data to them, as they have equivalent safeguards in place.
            </li>
          </ul>
          <p>
            If none of the above safeguards is available, we may request your explicit consent to
            the specific transfer. You will have the right to withdraw this consent at any time.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Data security</standard-heading>
          <p>
            We have put in place security measures to prevent your personal data from being
            accidentally lost, used, altered, disclosed, or accessed without authorisation. We also
            allow access to your personal data only to those employees and partners who have a
            business need to know such data. They will only process your personal data on our
            instructions and they must keep it confidential.
          </p>
          <p>
            We have procedures in place to deal with any suspected personal data breach and will
            notify you and any applicable regulator of a breach if we are legally required to.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Data retention</standard-heading>
          <p>
            We will only retain your personal data for as long as necessary to fulfil the purposes
            we collected it for, including for the purposes of satisfying any legal, accounting, or
            reporting requirements.
          </p>
          <p>
            When deciding what the correct time is to keep the data for we look at its amount,
            nature and sensitivity, potential risk of harm from unauthorised use or disclosure, the
            processing purposes, if these can be achieved by other means and legal requirements.
          </p>
          <p>
            For tax purposes the law requires us to keep basic information about our customers
            (including Contact, Identity, Financial and Transaction Data) for six years after they
            stop being customers.
          </p>
          <p>
            In some circumstances we may anonymise your personal data for research or statistical
            purposes in which case we may use this information indefinitely without further notice
            to you.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Your legal rights</standard-heading>
          <p>
            Under data protection laws you have rights in relation to your personal data that
            include the right to request access, correction, erasure, restriction, transfer, to
            object to processing, to portability of data and (where the lawful ground of processing
            is consent) to withdraw consent.
          </p>
          <p>
            You can read more about these rights
            <a
              href="http://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
              class="text-lightest hover:text-white underline"
            >
              here
            </a>
            .
          </p>
          <p>
            If you wish to exercise any of the rights set out above, please email us at
            <b>support@scottsbasslessons.com</b>
          </p>
          <p>
            You will not have to pay a fee to access your personal data (or to exercise any of the
            other rights). However, we may charge a reasonable fee if your request is clearly
            unfounded, repetitive or excessive or refuse to comply with your request in these
            circumstances.
          </p>
          <p>
            We may need to request specific information from you to help us confirm your identity
            and ensure your right to access your personal data (or to exercise any of your other
            rights). This is a security measure to ensure that personal data is not disclosed to any
            person who has no right to receive it. We may also contact you to ask you for further
            information in relation to your request to speed up our response.
          </p>
          <p>
            We try to respond to all legitimate requests within one month. Occasionally it may take
            us longer than a month if your request is particularly complex or you have made a number
            of requests. In this case, we will notify you.
          </p>
          <p>
            If you are not happy with any aspect of how we collect and use your data, you have the
            right to complain to the Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (<a href="www.ico.org.uk"> www.ico.org.uk </a>). We
            should be grateful if you would contact us first if you do have a complaint so that we
            can try to resolve it for you at <b>support@scottsbasslessons.com</b>.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Third party links</standard-heading>
          <p>
            This website may include links to third-party websites, plug-ins and applications.
            Clicking on those links or enabling those connections may allow third parties to collect
            or share data about you. We do not control these third-party websites and are not
            responsible for their privacy statements. When you leave our website, we encourage you
            to read the privacy notice of every website you visit.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3">Cookies and similar technologies</standard-heading>
          <p>
            When you visit or interact with our sites, services, applications, tools or messaging,
            we or our authorised service providers may use cookies, web beacons, and other similar
            technologies for storing information to help provide you with a better, faster and safer
            experience.
          </p>
          <p>
            This page is designed to help you understand more about these technologies and our use
            of them on our sites and in our products, services and tools. Below is a summary of a
            few key things you should know about our use of such technologies. You can review the
            contents of our full User Cookie Notice below.
          </p>
          <p>
            Our cookies and similar technologies have different functions. They are either necessary
            for the functioning of our services, help us improve our performance, give you extra
            functionalities, or help us to serve you relevant and targeted information or
            advertisements. We use cookies and similar technologies that only remain on your device
            for as long as you keep your browser active (session) and cookies and similar
            technologies that remain on your device for a longer period (persistent). You are free
            to block, delete, or disable these technologies if your device permits so. You can
            manage your cookies and your cookie preferences in your browser or device settings.
          </p>
          <p>
            Where possible, security measures are set in place to prevent unauthorised access to our
            cookies and similar technologies. A unique identifier ensures that only we and/or our
            authorised service providers have access to cookie data.
          </p>
          <p>
            Service providers are companies that help us with various aspects of our business, such
            as site operations, services, applications, advertisements and tools. We use some
            authorised service providers to help us to serve you relevant information or
            advertisements on our services and other places on the Internet. These service providers
            may also place cookies on your device via our services (third party cookies). They may
            also collect information that helps them identify your device, such as IP-address or
            other unique or device identifiers.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3"
            >What are cookies, web beacons, and other technologies?</standard-heading
          >
          <p>
            Like most sites, we use technologies that are essentially small data files placed on
            your computer, tablet, mobile phone, or other devices (referred to collectively as a
            “device”) that allow us to record certain pieces of information whenever you visit or
            interact with our sites, services, applications, messaging, and tools.
          </p>
          <p>
            The specific names and types of the cookies, web beacons, and other similar technologies
            we use may change from time to time. In order to help you better understand this Policy
            and our use of such technologies we have provided the following limited terminology and
            definitions:
          </p>
          <p>
            Cookies are small text files (typically made up of letters and numbers) placed in the
            memory of your browser or device when you visit a website or view a message. Cookies
            allow a website to recognise a particular device or browser. There are several types of
            cookies:
          </p>
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>
              <b>Session cookies</b> - Expire at the end of your browser session and allow us to
              link your actions during that particular browser session
            </li>
            <li>
              <b>Persistent cookies</b> - Are stored on your device in between browser sessions,
              allowing us to remember your preferences or actions across multiple sites
            </li>
            <li><b>First-party cookies</b> - Are set by the site you are visiting</li>
            <li>
              <b>Third-party cookies</b> - Are set by a third party site separate from the site you
              are visiting
            </li>
            <li>
              <b>Web beacons</b> - Small graphic images (also known as “pixel tags” or “clear GIFs”)
              that may be included on our sites, services, applications, messaging, and tools, that
              typically work in conjunction with cookies to identify our users and user behavior.
            </li>
            <li>
              <b>Similar technologies</b> - Technologies that store information in your browser or
              device utilising local shared objects or local storage, such as flash cookies, HTML5
              cookies, and other web application software methods. These technologies can operate
              across all of your browsers, and in some instances may not be fully managed by your
              browser and may require management directly through your installed applications or
              device. We do not use these technologies for storing information to target advertising
              to you on or off our sites.
            </li>
          </ul>
          <p>
            Cookies can be disabled or removed by tools that are available in most commercial
            browsers. The preferences for each browser you use will need to be set separately and
            different browsers offer different functionality and options.
          </p>
          <p>
            We may use the terms “cookies” or “similar technologies” interchangeably in our policies
            to refer to all technologies that we may use to store data in your browser or device or
            that collect information or help us identify you in the manner described above.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3"
            >Your choice and our use of cookies, web beacons, and other
            technologies</standard-heading
          >
          <p>
            We offer certain site features, services, applications, and tools that are available
            only through the use of these technologies. You are always free to block, delete, or
            disable these technologies if your browser, installed application, or device so permits.
            However, if you decline cookies or other similar technologies, you may not be able to
            take advantage of certain site features, services, applications, or tools. You may also
            be required to re-enter your password more frequently during your browsing session. For
            more information on how you can block, delete, or disable these technologies, please
            review your browser or device settings.
          </p>
          <p>
            Generally, these technologies allow our sites, services, applications, and tools to
            store relevant information in your browser or device and later read that information in
            order to identify you to our servers or internal systems. Where applicable, we protect
            our cookies and other similar technologies to help ensure that only we and/or our
            authorised service providers can interpret them by assigning them a unique identifier
            that is designed for interpretation only by us. We do not store any of your personal
            information on any of our cookies or other similar technologies.
          </p>
          <p>
            Any personal information that we collect and store through use of these technologies is
            first obtained through notice and consent: We obtain your consent by providing you with
            transparent notice of use of the technologies and providing you with the opportunity to
            make a choice to disable these technologies as set forth above.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3"
            >Our uses of such technologies fall into the following categories</standard-heading
          >
          <ul class="list-disc list-inside space-y-4 max-w-txt">
            <li>
              <b>Operationally Necessary</b> - We may use cookies, web beacons, or other similar
              technologies that are necessary to the operation of our sites, services, applications,
              and tools. This includes technologies that allow you access to our sites, services,
              applications, and tools; that are required to identify irregular site behavior,
              prevent fraudulent activity and improve security; or that allow you to make use of our
              functions such as shopping-carts, saved search, or similar functions;
            </li>
            <li>
              <b>Performance Related</b> - We may use cookies, web beacons, or other similar
              technologies to assess the performance of our websites, applications, services, and
              tools, including as part of our analytic practices to help us understand how our
              visitors use our websites, determine if you have interacted with our messaging,
              determine whether you have viewed an item or link, or to improve our website content,
              applications, services, or tools;
            </li>
            <li>
              <b>Functionality Related</b> - We may use cookies, web beacons, or other similar
              technologies that allow us to offer you enhanced functionality when accessing or using
              our sites, services, applications, or tools. This may include identifying you when you
              sign into our sites or keeping track of your preferences, interests, or past items
              viewed so that we may enhance the presentation of content on our sites.
            </li>
            <li>
              <b>Advertising or Targeting Related</b> - We may use first-party or third-party
              cookies and web beacons to deliver content, including ads relevant to your interests,
              on our sites or on third party sites. This includes using technologies to understand
              the usefulness to you of the advertisements and content that have been delivered to
              you, such as whether you have clicked on an advertisement.
            </li>
          </ul>
          <p>
            If you would like to opt-out of all other types of technologies we employ on our sites,
            services, applications, or tools, you may do so by blocking, deleting, or disabling them
            as your browser or device permits.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3"
            >Use of these technologies by authorised third-party service providers</standard-heading
          >
          <p>
            We may work with third-party companies, commonly known as service providers, who are
            authorised to place third-party cookies, web beacons, or similar technologies for
            storing information on our sites or in our services, applications, and tools with our
            permission. These service providers help us to provide you with a better, faster, and
            safer experience.
          </p>
          <p>
            These service providers may use these technologies to help us deliver our own content
            and advertising, and compile anonymous site metrics and analytics. We do not permit any
            of these service providers to collect any of your personal information on our sites or
            in our services, applications, or tools for their own purposes. These service providers
            are subject to confidentiality agreements with us and other legal restrictions on their
            use or collection of any personal information. Third party cookies are covered by the
            third-parties' privacy policy.
          </p>
          <p>
            With the exception of the use of such technologies by our service providers or other
            authorised third-parties, we do not permit any third-party content on sites (such as
            item listings, member-to-member communications, classified listings, comments, reviews,
            etc.) to include or utilise any cookies, web beacons, local storage, or similar
            technologies for tracking purposes or to collect your personal information. If you
            believe a listing or other third-party content might be collecting personal information
            or using tracking technologies on one of our sites, please report it to
            <strong>support@scottsbasslessons.com</strong>.
          </p>
        </flow>
      </content-column>
    </page-section>

    <page-section>
      <content-column>
        <flow>
          <standard-heading :size="3"
            >Advertising networks and exchanges operated by authorised third
            parties</standard-heading
          >
          <p>
            We may use third parties, such as advertising networks and exchanges, to allow us to
            serve you advertisements. These third-party ad networks and exchange providers may use
            third-party cookies, web beacons, or similar technologies to collect information. They
            may also collect your device identifier, IP address, or identifier for advertising
            (IDFA). The information that these third parties collect may be used to assist us in
            providing you with more relevant advertising that we serve on our sites or elsewhere on
            the web. Third party cookies are covered by the third-parties' privacy policy.
          </p>
        </flow>
      </content-column>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import Flow from '../../core/compositions/Flow.vue';
import PageContent from '../../core/page/PageContent.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';

export default defineComponent({
  components: {
    PageContent,
    ContentColumn,
    PageSection,
    StandardHeading,
    Flow
  },
  props: {},
  setup() {
    return {};
  }
});
</script>
