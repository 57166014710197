import {Category} from '../../backend/craft/category/category-types';
import {CraftId} from '../../backend/craft/craft-types';

export interface PreviewStoreState {
  status: {[entryId: string]: boolean};
  accessPasses: {[entryId: string]: ReadonlyArray<Category>};
}

export const PreviewStore = {
  namespaced: true,
  state: {
    status: {},
    accessPasses: {}
  } as PreviewStoreState,
  mutations: {
    setStatus(
      state: any,
      payload: {entryId: CraftId; status: boolean; accessPasses: ReadonlyArray<Category>}
    ) {
      state.status[payload.entryId] = payload.status;
      state.accessPasses[payload.entryId] = payload.accessPasses;
    }
  },
  actions: {
    setStatus(
      {commit}: any,
      payload: {entryId: CraftId; status: boolean; accessPasses: ReadonlyArray<Category>}
    ) {
      commit('setStatus', payload);
    }
  },
  getters: {
    status: (state: Readonly<PreviewStoreState>) => (entryId: string) => {
      return state.status[entryId];
    },
    accessPasses: (state: Readonly<PreviewStoreState>) => (entryId: string) => {
      return state.accessPasses[entryId];
    }
  }
};
