<template>
  <div class="w-full aspect-video bg-almost-black">
    <video-poster v-if="preview" class="relative w-full h-full" />
    <vimeo-frame
      v-else-if="video.videoServiceProvider === 'vimeo'"
      ref="videoFrame"
      class="relative w-full h-full"
    ></vimeo-frame>
    <youtube-frame
      v-else
      ref="videoFrame"
      :video-id="video.videoId"
      class="relative w-full h-full"
    ></youtube-frame>
  </div>
</template>

<script lang="ts">
import {ref, onMounted, onBeforeUnmount, PropType, nextTick, watch} from 'vue';
import VimeoFrame from './vimeo/VimeoFrame.vue';
import YoutubeFrame from './youtube/YoutubeFrame.vue';
import VideoPoster from './video-poster/VideoPoster.vue';
import {
  setVideoTimestamp,
  getVideoTimestamp,
  getVideoDuration
} from '../../../backend/video/video-query';
import {VideoInstrumentation} from './instrumentation';
import {Video} from '../../../backend/video/video-types';
import {throttle} from '../../../utils/throttle';
import {useSync} from '../../vue-composition/sync/sync';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';

export type PlayCallback = () => void;
export type EndCallback = () => void;
export type ProgressCallback = (playpos: number, duration: number) => void;

export default {
  components: {
    VimeoFrame,
    YoutubeFrame,
    VideoPoster
  },

  props: {
    guest: {type: String, required: true},
    preview: {type: Boolean, required: true},
    video: {type: Object as PropType<Video>, required: true},

    resumeAtLastTimestamp: {type: Boolean, default: true},
    overridePlaybackPos: {type: Number as PropType<number | undefined>, default: undefined},
    onPlay: {type: Function as PropType<PlayCallback>, default: undefined},
    onEnd: {type: Function as PropType<EndCallback>, default: undefined},

    /*
     * If this function is defined, it will be called at intervals appropriate
     * for recording progress.
     */
    onProgress: {
      type: Function as PropType<ProgressCallback>,
      default: undefined
    }
  },

  setup(props) {
    /*
      Reference to the videoFrame element in the template; see
      https://v3.vuejs.org/guide/composition-api-template-refs.html
      https://v3.vuejs.org/guide/typescript-support.html#typing-template-refs
    */
    const videoFrame = ref(
      null as InstanceType<typeof VimeoFrame> | InstanceType<typeof YoutubeFrame> | null
    );

    const playbackPos = ref<number | undefined>(undefined);

    const instrumentation = new VideoInstrumentation();

    const loader = useFullScreenLoader();

    const sync = useSync('media', props.video.videoId, async () => {
      if (videoFrame.value !== null) {
        await videoFrame.value.pause();
      }
    });

    const playCallback = () => {
      sync.lock();
      instrumentation.start();
      if (props.onPlay) {
        props.onPlay();
      }
    };

    const pauseCallback = () => {
      instrumentation.stop();
    };

    const endCallback = async () => {
      instrumentation.stop();

      // Reset playback timestamp to zero if user watches video through to the end.
      if (props.resumeAtLastTimestamp && !props.guest && !props.preview) {
        await setVideoTimestamp(props.video.videoId, 0);
      }

      if (props.onEnd) {
        props.onEnd();
      }
    };

    const PROGRESS_UPDATE_INTERVAL_MS = 2000;
    let progressCallback = throttle(async (playpos: number, duration: number) => {
      if (props.resumeAtLastTimestamp && !props.guest && !props.preview) {
        await setVideoTimestamp(props.video.videoId, playpos);
      }
      if (props.onProgress !== undefined) {
        props.onProgress(playpos, duration);
      }
    }, PROGRESS_UPDATE_INTERVAL_MS);

    const onTimeUpdate = async (playpos: number, duration: number) => {
      instrumentation.addSample(playpos, duration);
      progressCallback(playpos, duration);
    };

    const init = async () => {
      /* 
        Set the playback position.
        
        If we've been told to override the playback position, do so. Otherwise,
        check if we have a logged-in user and they have access to this entry.
        If yes, load the position in the video where they left off. Otherwise,
        start playback at zero.
      */
      const videoLength = getVideoDuration(props.video);
      if (
        props.overridePlaybackPos !== undefined &&
        videoLength !== undefined &&
        props.overridePlaybackPos < videoLength
      ) {
        playbackPos.value = props.overridePlaybackPos;
      } else if (props.resumeAtLastTimestamp && !props.guest && !props.preview) {
        loader.setLoading(true);
        playbackPos.value = await getVideoTimestamp(props.video.videoId);
        loader.setLoading(false);
      } else {
        playbackPos.value = 0;
      }

      if (!props.preview) {
        await nextTick();
        if (videoFrame.value !== null) {
          await videoFrame.value.cueVideo(
            props.video.videoId,
            playbackPos.value,
            playCallback,
            pauseCallback,
            endCallback,
            onTimeUpdate
          );
          await nextTick();
        }
        // Initialize instrumentation mechanism.
        instrumentation.init(props.video);
      }
    };

    watch(
      () => {
        return props.video.videoId;
      },
      async () => {
        await init();
      }
    );

    onMounted(async () => {
      await init();
    });
    onBeforeUnmount(() => {
      sync.onBeforeUnmount();
      if (instrumentation !== undefined) {
        instrumentation.stop();
      }
    });

    return {
      videoFrame,
      playbackPos
    };
  }
};
</script>
