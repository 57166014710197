<template>
  <div v-if="!guest && (contentEntries.length > 0 || ppContinueLevel !== undefined)">
    <page-section :margin-top="'lg'">
      <page-section-header :width="'wide'" :heading="'Continue Where You Left Off'" />
    </page-section>
    <page-section v-if="contentEntries.length > 0" :margin-top="'sm'">
      <content-entry-reel
        :guest="guest"
        :width="'wide'"
        :content-entries="contentEntries"
        :bookmark="bookmark"
        :card-size="'lg'"
      />
    </page-section>

    <dashboard-players-path-progress
      v-if="ppContinueLevel !== undefined"
      :guest="guest"
      :continue-level="ppContinueLevel"
    />

    <page-section :margin-top="'lg'">
      <content-column :width="'wide'">
        <standard-divider class="border-mid" />
      </content-column>
    </page-section>
  </div>
</template>

<script lang="ts">
import {ref, onMounted, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import ContentEntryReel from '../../../content-entry/collections/variants/ContentEntryReel.vue';
import DashboardPlayersPathProgress from './DashboardPlayersPathProgress.vue';
import {useLoader} from '../../../vue-composition/loader/loader';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {PlayersPathLevel} from '../../../../backend/content/players-path/players-path-types';
import {getPlayersPathContinueLevel} from '../../../../backend/content/players-path/players-path-query-utils';
import {getEntriesInProgress} from '../../../../backend/progress/progress-query';
import {getCoursesById} from '../../../../backend/content/course/course-query';

/**
 * Get the currently logged in user's progress and return all courses
 * (learning pathway courses and regular courses) where the progress
 * is larger than zero and less than 100%.
 */
async function getCoursesInProgress(): Promise<ReadonlyArray<ContentEntry>> {
  const inProgress = await getEntriesInProgress();
  const entryIds = inProgress.filter(e => e.progress > 0 && e.progress < 1).map(e => e.entryId);

  const courses = await getCoursesById(entryIds, ['courses', 'learningPathwaysCourses']);

  // Sort entries by completion date (= same order as returned by getEntriesInProgress).
  let sorted = [...courses];
  sorted.sort((a, b) => {
    const indexA = inProgress.findIndex(progress => progress.entryId === a.id);
    const indexB = inProgress.findIndex(progress => progress.entryId === b.id);
    return indexA - indexB;
  });
  return sorted;
}

export default {
  components: {
    PageSection,
    PageSectionHeader,
    ContentColumn,
    StandardDivider,
    ContentEntryReel,
    DashboardPlayersPathProgress
  },
  props: {
    guest: {type: String, required: true},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, required: true}
  },
  setup(props) {
    const loader = useLoader();
    const contentEntries = ref<ReadonlyArray<ContentEntry>>([]);
    const ppContinueLevel = ref<Readonly<PlayersPathLevel> | undefined>(undefined);

    onMounted(async () => {
      if (!props.guest) {
        loader.setLoading(true);

        /*
          ??? We should redesign this component so that it doesn't rely on
          special-purpose code for different sections. This probably means
          adding Players Path Levels and Study Pieces to the progress system.
          It also means we have to decide the hierarchy level to present in
          this component: it is likely to be the next-to-lowest level (i.e.,
          PP level, course).
        */
        ppContinueLevel.value = await getPlayersPathContinueLevel(props.guest);
        contentEntries.value = await getCoursesInProgress();

        loader.setLoading(false);
      }
    });

    return {
      contentEntries,
      ppContinueLevel
    };
  }
};
</script>
