import * as ContentEntryQueryBuilder from '../content/content-entry/content-entry-query-builder';
import * as QueryBuilder from '../craft/query/craft-query-builder';

const RICH_TEXT_HEADING_BLOCK = QueryBuilder.block('richTextHeading').fields([
  QueryBuilder.dropdown('richTextHeadingSize', ['1', '2', '3', '4', '5']).required(),
  QueryBuilder.plainText('richTextHeadingText').required(),
  QueryBuilder.lightswitch('richTextHeadingCenter')
]);

const RICH_TEXT_BODY_BLOCK = QueryBuilder.block('richTextBody').fields([
  QueryBuilder.plainText('richTextBodyParagraphs'), // ??? Redactor plugin is no longer supported
  QueryBuilder.lightswitch('richTextBodyCenter'),
  QueryBuilder.lightswitch('richTextBodyEmphasis')
]);

const RICH_TEXT_VIDEO_BLOCK = QueryBuilder.block('richTextVideo').fields([
  QueryBuilder.dropdown('richTextVideoServiceProvider', ['vimeo', 'youtube']).required(),
  QueryBuilder.plainText('richTextVideoId').required()
]);

const RICH_TEXT_IMAGE_BLOCK = QueryBuilder.block('richTextImage').fields([
  QueryBuilder.plainText('richTextImageFilename').required(),
  QueryBuilder.plainText('richTextImageCaption'),
  QueryBuilder.dropdown('richTextImageWidth', ['full', 'image']),
  QueryBuilder.number('richTextImageMaxWidth'),
  QueryBuilder.lightswitch('richTextImageCenter')
]);

const RICH_TEXT_LIST_BLOCK = QueryBuilder.block('richTextList').fields([
  QueryBuilder.plainText('richTextListItems').required()
]);

const RICH_TEXT_QUOTE_BLOCK = QueryBuilder.block('richTextQuote').fields([
  QueryBuilder.plainText('richTextQuotePrimary'),
  QueryBuilder.plainText('richTextQuoteSecondary')
]);

const RICH_TEXT_ENTRIES_BLOCK = QueryBuilder.block('richTextEntries').fields([
  ContentEntryQueryBuilder.contentEntries('richTextEntryReferences').required()
]);

export const RICH_TEXT_FIELD = QueryBuilder.matrix('richText').blocks([
  RICH_TEXT_HEADING_BLOCK,
  RICH_TEXT_BODY_BLOCK,
  RICH_TEXT_VIDEO_BLOCK,
  RICH_TEXT_IMAGE_BLOCK,
  RICH_TEXT_LIST_BLOCK,
  RICH_TEXT_QUOTE_BLOCK,
  RICH_TEXT_ENTRIES_BLOCK
]);
