import {Ref, ref} from 'vue';
import {useStore} from '../../../store/store';

export type BackendErrorComposition = {
  message: Ref<string | undefined>;
  set: (error: Readonly<Error>, openHelpScoutBeacon?: boolean) => void;
  setMessage: (message: string, openHelpScoutBeacon?: boolean) => void;
  clear: () => void;
  error: () => boolean;
  catcher: (erorr: unknown) => undefined;
};

export function useBackendError(showHelpScoutOnError?: boolean): Readonly<BackendErrorComposition> {
  const store = useStore();
  const message = ref<string | undefined>(undefined);

  const set = (error: Readonly<Error>, openHelpScoutBeacon?: boolean) => {
    message.value = error.message ? error.message : 'Unknown error';
    if (showHelpScoutOnError || openHelpScoutBeacon) {
      store.dispatch('helpScout/show');
    }
  };
  const clear = () => {
    message.value = undefined;
  };
  const setMessage = (msg: string, openHelpScoutBeacon?: boolean) => {
    message.value = msg;
    if (showHelpScoutOnError || openHelpScoutBeacon) {
      store.dispatch('helpScout/show');
    }
  };
  const error = () => {
    return message.value !== undefined;
  };

  const catcher = (error: unknown) => {
    if (error instanceof Error) {
      set(error);
    } else {
      setMessage('Unknown error');
    }
    return undefined;
  };

  return {
    message,
    set,
    setMessage,
    clear,
    error,
    catcher
  };
}
