<template>
  <div class="flex gap-fl-lg bg-darkest rounded-xl select-none px-fl-md-xl py-fl-sm-lg">
    <div class="basis-2/12 pr-fl-sm flex flex-nowrap items-end">
      <audio-player-play :audio-player="audioPlayer" class="basis-4/5" />
      <audio-player-reset :audio-player="audioPlayer" class="basis-1/5" />
    </div>

    <div class="basis-10/12 flex flex-col justify-center gap-fl-md">
      <div class="flex flex-col justify-center">
        <audio-player-timeline :audio-player="audioPlayer" />
      </div>

      <standard-divider class="border-dark" />

      <div class="flex flex-nowrap gap-fl-lg-2xl">
        <audio-player-playback-speed
          :audio-player="audioPlayer"
          :font-size="'text-fl-xl'"
          :button-size="'text-fl-5xl'"
        />
        <audio-player-repeat :audio-player="audioPlayer" :font-size="'text-fl-xl'" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import StandardDivider from '../../../core/standard-divider/StandardDivider.vue';
import AudioPlayerPlay from '../partials/AudioPlayerPlay.vue';
import AudioPlayerTimeline from '../partials/AudioPlayerTimeline.vue';
import AudioPlayerPlaybackSpeed from '../partials/AudioPlayerPlaybackSpeed.vue';
import AudioPlayerRepeat from '../partials/AudioPlayerRepeat.vue';
import AudioPlayerReset from '../partials/AudioPlayerReset.vue';
import {useLocalStorageFlag} from '../../../vue-composition/local-storage-flag/local-storage-flag';
import {AudioPlayerComposition} from '../internals/utils';

export default defineComponent({
  components: {
    StandardDivider,
    AudioPlayerPlay,
    AudioPlayerReset,
    AudioPlayerTimeline,
    AudioPlayerPlaybackSpeed,
    AudioPlayerRepeat
  },
  props: {
    audioPlayer: {type: Object as PropType<AudioPlayerComposition>, required: true}
  },
  setup() {
    const localStorage = useLocalStorageFlag('audio-player-options', true);
    const options = computed(() => {
      return localStorage.get();
    });
    const onToggleOptions = () => {
      localStorage.toggle();
    };
    const optionsLabel = computed(() => (options.value ? 'HIDE OPTIONS' : 'SHOW OPTIONS'));
    return {
      options,
      onToggleOptions,
      optionsLabel
    };
  }
});
</script>
