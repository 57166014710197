<template>
  <page-content>
    <!-- Career page header -->
    <careers-header />

    <page-section
      :class="'bg-white text-darkest'"
      :margin-top="'xl'"
      :padding-top="'lg'"
      :padding-bottom="'lg'"
    >
      <content-column>
        <standard-heading :size="4" :spot-color="jobHeadingSpotColor" :class="'mb-fl-lg-xl'">
          Open roles –</standard-heading
        >
      </content-column>
      <div v-for="career in careers" :key="career.id" class="mb-fl-lg-4xl">
        <content-column>
          <page-section :class="'bg-white text-darkest'" :margin-top="'sm'">
            <standard-heading :size="2" :spot-color="jobHeadingSpotColor">
              {{ career.title }}
            </standard-heading>
          </page-section>
          <page-section :class="'bg-white text-darkest'" :margin-top="'sm'">
            <p>{{ career.summary }}</p>
          </page-section>
          <page-section :class="'bg-white text-darkest'" :margin-top="'sm'">
            <standard-button
              :url="career.careersInfoUrl"
              :spot-color="moreInfoSpotColor"
              :uppercase="true"
              :label="'More info'"
              :new-tab="true"
            />
          </page-section>
        </content-column>
      </div>
    </page-section>

    <page-section :margin-top="'xl'" :class="'text-white'">
      <content-two-columns>
        <template #left>
          <standard-heading :size="4" :class="'mb-fl-lg'"
            >Benefits of working with SBL –</standard-heading
          >
        </template>
        <template #right>
          <flow :gap="10">
            <article>
              <flow :gap="7">
                <standard-heading :size="2">A 'Passion' job</standard-heading>
                <p>
                  We didn't go into bass education purely for the money. We did it because we're
                  passionate about enlarging and enriching the global community of bassists. That's
                  why we put our community members first, no matter what we do.
                </p>
              </flow>
            </article>
            <article>
              <flow :gap="7">
                <standard-heading :size="2">We're a small agile team</standard-heading>
                <p>
                  If you thrive on writing your own script, wearing multiple hats, and thinking
                  outside the box to help move the team forward, SBL may be for you. We're small,
                  scrappy, and full of opportunity for anyone, no matter their position, to
                  contribute.
                </p>
              </flow>
            </article>
            <article>
              <flow :gap="7">
                <standard-heading :size="2">100% remote</standard-heading>
                <p>
                  We were remote "before it was cool"! That means we thrive on providing flexibility
                  and the ability to work from anywhere. True - there are certain timezones in which
                  you're going to find it easier to attend meetings. But we've got "remote" in our
                  DNA.
                </p>
              </flow>
            </article>
          </flow>
        </template>
      </content-two-columns>
    </page-section>

    <page-section :margin-top="'xl'">
      <content-two-columns>
        <template #left>
          <standard-heading :size="4" :class="'mb-fl-lg'">Our values –</standard-heading>
        </template>
        <template #right>
          <flow :gap="8">
            <standard-heading :size="2">01. Authenticity</standard-heading>
            <standard-heading :size="2">02. Innovation</standard-heading>
            <standard-heading :size="2">03. Passion</standard-heading>
            <standard-heading :size="2">04. Expertise</standard-heading>
            <standard-heading :size="2">05. Confidence</standard-heading>
            <standard-heading :size="2">06. Ambition</standard-heading>
            <standard-heading :size="2">07. Joy</standard-heading>
            <standard-heading :size="2">08. Inclusion</standard-heading>
          </flow>
        </template>
      </content-two-columns>
    </page-section>

    <page-section :margin-top="'xl'" :padding-bottom="'lg'">
      <ContentColumn :width="'narrow'">
        <div class="flex justify-center">
          <standard-button :url="'/about'" :color="'primary'" :label="'Learn More About Us'" />
        </div>
      </ContentColumn>
    </page-section>
  </page-content>
</template>

<script lang="ts">
import {ref, onMounted} from 'vue';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {Career} from '../../../backend/careers/careers-types';
import {getAllCareers} from '../../../backend/careers/careers-query';
import PageContent from '../../core/page/PageContent.vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import StandardHeading from '../../core/standard-heading/StandardHeading.vue';
import ContentTwoColumns from '../../core/compositions/ContentTwoColumns.vue';
import Flow from '../../core/compositions/Flow.vue';
import StandardButton from '../../core/button/StandardButton.vue';
import CareersHeader from './partials/CareersHeader.vue';

export default {
  components: {
    PageContent,
    PageSection,
    ContentColumn,
    StandardHeading,
    ContentTwoColumns,
    Flow,
    StandardButton,
    CareersHeader
  },
  props: {
    guest: {type: String, required: true}
  },
  setup() {
    const loader = useFullScreenLoader();

    const careers = ref<ReadonlyArray<Career>>([]);

    const jobHeadingSpotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-black',
      textHover: 'hover:text-black',
      border: 'border-white',
      borderHover: 'hover:border-white'
    };

    const moreInfoSpotColor = {
      bg: 'bg-white',
      bgHover: 'bg-white',
      text: 'text-darkest',
      textHover: 'hover:text-darkest',
      border: 'border-darkest',
      borderHover: 'hover:border-darkest'
    };

    const subHeadingSpotColor = {
      bg: 'bg-black',
      bgHover: 'bg-black',
      text: 'text-primary',
      textHover: 'hover:text-primary',
      border: 'border-black',
      borderHover: 'hover:border-black'
    };

    onMounted(async () => {
      loader.setLoading(true);
      careers.value = await getAllCareers();
      loader.setLoading(false);
    });

    return {
      careers,
      jobHeadingSpotColor,
      moreInfoSpotColor,
      subHeadingSpotColor
    };
  }
};
</script>
