<template>
  <div v-if="richText.length > 0">
    <page-section v-if="heading !== undefined">
      <page-section-header :heading="heading" />
    </page-section>
    <page-section :margin-top="'sm'">
      <rich-text-component
        :guest="guest"
        :rich-text="richText"
        :body-text-size="bodyTextSize"
        :list-text-size="listTextSize"
        :list-icon="listIcon"
        :width="width"
        :justify="justify"
      />
    </page-section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import PageSection from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import RichTextComponent from '../../../generic/rich-text/RichText.vue';
import {RichTextBlock} from '../../../../backend/rich-text/rich-text-types';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {StandardPageAreaJustify, StandardPageAreaWidth} from '../../../core/column-layout/utils';

export default defineComponent({
  components: {
    PageSection,
    PageSectionHeader,
    RichTextComponent
  },
  props: {
    guest: {type: String, required: true},
    heading: {type: String, default: undefined},
    richText: {type: Array as PropType<ReadonlyArray<RichTextBlock>>, required: true},
    bodyTextSize: {type: String, default: 'text-fl-base'},
    listTextSize: {type: String, default: 'text-fl-base'},
    listIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    width: {type: String as PropType<StandardPageAreaWidth>, default: undefined},
    justify: {type: String as PropType<StandardPageAreaJustify>, default: undefined}
  },
  setup() {
    return {};
  }
});
</script>
