import {Ref, ref} from 'vue';
import {getAllPromotions, getPromotionGroups} from '../../../backend/promotion/promotion-query';
import {Promotion, PromotionLocation} from '../../../backend/promotion/promotion-types';
import {getActivePromotions} from '../../../backend/promotion/promotion-utils';
import {getAccessPassesForCurrentUser} from '../../../backend/access-pass/access-pass-query';

export type PromotionMap = {
  [location: string]: Promotion;
};

export type PromotionComposition = {
  active: Ref<PromotionMap>;
  init: () => Promise<void>;
  get: (location: PromotionLocation) => Promotion | undefined;
};

export function usePromotions(
  guest: string,
  userDateCreated: string,
  serverDate: string
): Readonly<PromotionComposition> {
  const active = ref<PromotionMap>({});
  const init = async () => {
    const userAccessPasses = await getAccessPassesForCurrentUser();
    const allPromos = await getAllPromotions();

    const promotionGroups = await getPromotionGroups();

    const activeList = getActivePromotions(
      allPromos,
      promotionGroups,
      guest,
      userDateCreated,
      serverDate,
      userAccessPasses
    );
    const result: PromotionMap = {};
    activeList.forEach(p => {
      p.promotionLocations.forEach(location => {
        // ### We only allow one promotion to be active for each location; do we want to generalise?
        if (result[location] === undefined) {
          result[location] = p;
        }
      });
    });
    active.value = result;
  };
  const get = (location: PromotionLocation) => {
    return active.value[location];
  };
  return {
    active,
    init,
    get
  };
}
